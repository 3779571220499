import { RestRequest } from '@Utils';
import { IPreviewVoiceCall, ISharedVoiceCall, ISharedPatchVoiceCall } from 'atlas-shared';

export const fetchVoiceCall = async (organization_id: IPreviewVoiceCall['organization_id'], id: IPreviewVoiceCall['id']): Promise<ISharedVoiceCall> => {
  return RestRequest.get<ISharedVoiceCall>(`voice_call/p/${organization_id}/${id}`, { socket_id: global.socketId });
};

export const patchVoiceCall = (organization_id: IPreviewVoiceCall['organization_id'], id: IPreviewVoiceCall['id'], payload: ISharedPatchVoiceCall) => {
  return RestRequest.patch<any, ISharedVoiceCall>(`voice_call/p/${organization_id}/${id}`, payload, { socket_id: global.socketId });
};

export const getVoiceCallUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_call/update/schema');
};
