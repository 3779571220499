import { RestRequest } from '@Utils';
import { IPreviewTeam, ISharedTeam, ISharedCreateTeam, ISharedPatchTeam } from 'atlas-shared';

export const fetchTeams = async (): Promise<Array<IPreviewTeam>> => {
  return RestRequest.get<Array<IPreviewTeam>>('team', { socket_id: global.socketId });
};

export const fetchTeam = async (id: IPreviewTeam['id']): Promise<ISharedTeam> => {
  return RestRequest.get<ISharedTeam>(`team/${id}`, { socket_id: global.socketId });
};

export const createTeam = (payload: ISharedCreateTeam) => {
  return RestRequest.post<any, ISharedTeam>('team', payload, { socket_id: global.socketId });
};

export const patchTeam = (id: IPreviewTeam['id'], payload: ISharedPatchTeam) => {
  return RestRequest.patch<any, ISharedTeam>(`team/${id}`, payload, { socket_id: global.socketId });
};

export const deleteTeam = (id: IPreviewTeam['id']) => {
  return RestRequest.delete(`team/${id}`, { socket_id: global.socketId });
};

export const getTeamUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('team/update/schema');
};

export const getTeamCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('team/create/schema');
};

export const getTeamPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('team/patch/schema');
};
