import { TariffParentActions } from '@Store';
import { IPreviewTariffParent } from 'atlas-shared';
import { fetchTariffParents } from '@Api';

export const actionFetchTariffParents = () => {
  return async (dispatch) => {
    dispatch(actionLoadingTariffParents());
    const tariff_parents = await fetchTariffParents();

    dispatch(actionSetTariffParents(tariff_parents));
    return tariff_parents;
  };
};

export const actionLoadingTariffParents = () => ({
  type: TariffParentActions.LOADING,
  payload: []
});

export const actionSetTariffParents = (payload: Array<IPreviewTariffParent>) => ({
  type: TariffParentActions.SET_STATE,
  payload: payload
});

export const actionAddTariffParent = (payload: IPreviewTariffParent) => ({
  type: TariffParentActions.ADD_STATE,
  payload: payload
});

export const actionUpdateTariffParent = (payload: IPreviewTariffParent) => ({
  type: TariffParentActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveTariffParent = (id: IPreviewTariffParent['id']) => ({
  type: TariffParentActions.REMOVE_STATE,
  payload: id
});
