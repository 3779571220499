import { journeyAction } from './base';

import { JourneyActionRestRequest as JourneyActionRestRequestBase } from 'atlas-shared';
import { FormElementTypes, FormLayoutTypes } from 'atlas-form';

export const JourneyActionRestRequest = journeyAction({
  ...JourneyActionRestRequestBase,
  additionalParams: {
    properties_journey_variable_id: { help: 'REST_REQUEST_VARIABLE_HELP' },
    properties_field_id: { help: 'REST_REQUEST_FIELD_HELP' },
    properties_status_ports: { ui_type: FormElementTypes.LIST, addText: 'ADD_PORT' },
    properties_body: { ui_layout: FormLayoutTypes.VERTICAL, noPadding: true, addText: 'ADD_PROPERTY' },
    properties_body_key: { hideLabel: true },
    properties_body_type: { hideLabel: true },
    properties_body_value: { hideLabel: true },
    properties_body_variable_alias: { hideLabel: true },
    properties_body_data_key: { hideLabel: true },
    properties_headers: { ui_layout: FormLayoutTypes.VERTICAL, noPadding: true, addText: 'ADD_PROPERTY' },
    properties_headers_key: { hideLabel: true },
    properties_headers_type: { hideLabel: true },
    properties_headers_value: { hideLabel: true },
    properties_headers_variable_alias: { hideLabel: true },
    properties_headers_data_key: { hideLabel: true },
    properties_query_params: { ui_layout: FormLayoutTypes.VERTICAL, noPadding: true, addText: 'ADD_PROPERTY' },
    properties_query_params_key: { hideLabel: true },
    properties_query_params_type: { hideLabel: true },
    properties_query_params_value: { hideLabel: true },
    properties_query_params_variable_alias: { hideLabel: true },
    properties_query_params_data_key: { hideLabel: true },
    properties_journey_variables_json_path: { help: 'JSON_PATH_HELP', hideHelp: true },
    properties_fields_json_path: { help: 'JSON_PATH_HELP', hideHelp: true }
  },
  icon: 'Api'
});
