import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const PlusIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props} stroke={2}>
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </SvgIcon>;
};
