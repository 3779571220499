import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateDisposition, ISharedUpdateDisposition, ISharedDisposition, IPreviewOrganization, IPreviewDisposition, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IDispositionFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  dispositions: Array<IPreviewDisposition>;
}

export const DispositionFormOptions = (disposition: ISharedCreateDisposition | ISharedDisposition, props: IDispositionFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Disposition, {})), 'organization_id', false), 'organization'),
  parents: (it: ISharedDisposition) => filterRelated<IPreviewDisposition>(it.organization_id || disposition.organization_id, 'disposition', 'disposition', props.dispositions, true, it => !it.global && it.id !== (disposition as ISharedDisposition)?.id),
  
});

export const DispositionComponents: IForm['components'] = {};
export const DispositionAdditionalParams: (auth: IAuth, props: IDispositionFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IDispositionFormOptionsProps) => ({
  parents: {
    onModalAdd: {
      key: 'queue-parents-add',
      path: '@Components/page-views/disposition',
      component: 'DispositionFormAddView',
      item_text: 'DISPOSITION'
    }
  }
});

export const DispositionEditForm = (disposition: ISharedDisposition, props: IDispositionFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `disposition_edit_${disposition.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === disposition.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'parents' },
    { ref: 'global' }
  ]
});

export const DispositionAddForm = (disposition: ISharedCreateDisposition, props: IDispositionFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'disposition_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'parents' },
    { ref: 'global' }
  ]
});

export const DispositionAddInitialValues: ISharedCreateDisposition = {} as ISharedCreateDisposition;

export const DispositionEditInitialValues = (disposition: ISharedDisposition): ISharedUpdateDisposition => ({
  title: disposition.title,
  parents: disposition.parents,
  global: disposition.global
});

export const onDispositionFormSaved = (t: TFunction, action: 'edit' | 'add', disposition: ISharedDisposition, navigate: NavigateFunction): void => {
  navigate(onFormSaved('dispositions'));
};
