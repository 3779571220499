import { IForm, IFormOptions } from 'atlas-form';
import {
  IPreviewUser,
  ISharedAgentprofile,
  ISharedCreateTeam,
  ISharedTeam
} from 'atlas-shared';
import { TFunction } from 'i18next';
import { ITeamFormOptionsProps, TeamFormOptions as TeamFormOptionsBase, TeamAdditionalParams as TeamAdditionalParamsBase } from './abstract/team.form.abstract';
import { filterRelated } from '../relation';
import { IAuth } from '@Store';

export * from './abstract/team.form.abstract';
export const TeamAdditionalParams: (auth: IAuth, props: ITeamFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITeamFormOptionsProps) => ({
  ...TeamAdditionalParamsBase(auth, props),
  __users: { label: 'AGENTS' }
});

export const TeamFormOptions = (team: ISharedCreateTeam | ISharedTeam, props: ITeamFormOptionsProps, t: TFunction): IFormOptions => ({
  ...TeamFormOptionsBase(team, props, t),
  __users: (it: ISharedAgentprofile) => filterRelated<IPreviewUser>(team.organization_id || it.organization_id, 'user', 'team', props.users, true, user => user.__agent_organization_ids.includes(team.organization_id || it.organization_id)),
  // __users: (base: ISharedCreateTeam) => entitiesToOptions<IPreviewUser>(props.users.filter(user => {
  //   const organization = props.organizations.find(o => o.id === team.organization_id || base.organization_id);
  //
  //   if (!organization)
  //     return false;
  //
  //   const user_organization = user.__organizations.find(o => [organization.id, ...organization.children]?.includes(o.organization_id));
  //
  //   return user_organization && (user_organization.role & RoleEnum.Agent) === RoleEnum.Agent;
  // }), 'user')
});
