import { RestRequest } from '@Utils';
import { IPreviewJourneyVariable, ISharedJourneyVariable, ISharedCreateJourneyVariable, ISharedPatchJourneyVariable } from 'atlas-shared';

export const fetchJourneyVariables = async (): Promise<Array<IPreviewJourneyVariable>> => {
  return RestRequest.get<Array<IPreviewJourneyVariable>>('journey_variable', { socket_id: global.socketId });
};

export const fetchJourneyVariable = async (id: IPreviewJourneyVariable['id']): Promise<ISharedJourneyVariable> => {
  return RestRequest.get<ISharedJourneyVariable>(`journey_variable/${id}`, { socket_id: global.socketId });
};

export const createJourneyVariable = (payload: ISharedCreateJourneyVariable) => {
  return RestRequest.post<any, ISharedJourneyVariable>('journey_variable', payload, { socket_id: global.socketId });
};

export const patchJourneyVariable = (id: IPreviewJourneyVariable['id'], payload: ISharedPatchJourneyVariable) => {
  return RestRequest.patch<any, ISharedJourneyVariable>(`journey_variable/${id}`, payload, { socket_id: global.socketId });
};

export const deleteJourneyVariable = (id: IPreviewJourneyVariable['id']) => {
  return RestRequest.delete(`journey_variable/${id}`, { socket_id: global.socketId });
};

export const getJourneyVariableUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey_variable/update/schema');
};

export const getJourneyVariableCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey_variable/create/schema');
};

export const getJourneyVariablePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('journey_variable/patch/schema');
};
