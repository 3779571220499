import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchForms, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useForms = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const forms = useSelector((store: IReducerStore) => store.forms);

  useEffect(() => {
    if (!initiated && autoload && !forms.loaded && !forms.loading) {
      initiated = true;
      dispatch(actionFetchForms());
    }
  }, [autoload, forms.loaded, forms.loading, dispatch]);

  return forms;
};
