import { useTranslation } from 'react-i18next';
import { Badge, Button, Popover } from 'antd';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAgentprofiles,
  useClients,
  useDispositions,
  useOrganizations,
  useQueues,
  useSkills,
  useTags,
  useUsers
} from '@Hooks';
import { createConversationFilter, deleteConversationFilter, patchConversationFilter, patchUserStatus } from '@Api';
import {
  ConversationFlagEnum,
  ConversationPriorityEnum,
  ConversationStatusEnum,
  EnumToArray,
  IPreviewAgentprofile,
  IPreviewConversationFilter,
  ISharedConversationFilter,
  ISharedPatchConversationFilter,
  ISharedUserStatus,
  Undefinable,
  ChannelEnum,
  ISharedCreateConversationFilter,
  DirectionEnum,
  ISharedQueue
} from 'atlas-shared';
import { AtlasForm, FormElementTypes, IForm } from 'atlas-form';
import { clone, entitiesToOptions, filterRelated, isAgent, isSuperAdmin, nestedArray, uniq } from '@Utils';
import { CheckIcon, FilterIcon, ListIcon, TrashIcon } from '@Assets/icons';
import { IAuth } from '@Store';
import Joi from 'joi';
import { Icon, IconText } from '@Components';

interface IProps {
  auth: IAuth;
  selected: Undefinable<ISharedConversationFilter>;
  default_filters: Array<ISharedCreateConversationFilter>;
  chosenFilters: number;
  conversation_filters: Array<IPreviewConversationFilter>;
  schema: Undefinable<Joi.Schema>;
  shortened: boolean;
}
export const ConversationFilter = React.memo(({ selected, chosenFilters, conversation_filters, auth, schema, shortened, default_filters }: IProps) => {

  const { t } = useTranslation();
  const [selectFilterVisible, setSelectFilterVisible] = useState<boolean>(false);
  const [editFilterVisible, setEditFilterVisible] = useState<boolean>(false);
  const switchConversationFilter = (conversation_filter_id: ISharedUserStatus['conversation_filter_id']) => patchUserStatus(auth.user_status.id, { conversation_filter_id });
  const [form, setForm] = useState<ReactElement>();
  const [agentprofile, setAgentprofile] = useState<IPreviewAgentprofile>();
  const [agentAgentprofiles, setAgentAgentprofiles] = useState<Array<IPreviewAgentprofile>>();
  const [allProfileQueues, setAllProfileQueues] = useState<Array<ISharedQueue['id']>>([]);

  const organizations = useOrganizations();
  const agentprofiles = useAgentprofiles();
  const queues = useQueues();
  const clients = useClients();
  const skills = useSkills();
  const tags = useTags();
  const dispositions = useDispositions();
  const users = useUsers();

  useEffect(() => {
    setAgentAgentprofiles(agentprofiles.agentprofiles.filter(agentprofile => auth.user.__agentprofiles.includes(agentprofile.id)));
  }, [auth.user.__agentprofiles, agentprofiles]);

  useEffect(() => {
    setAllProfileQueues(uniq(agentAgentprofiles?.map(agentAgentprofile => agentAgentprofile.__queues).flat() || []));
  }, [agentAgentprofiles]);

  useEffect(() => {
    if (!auth.user_status?.agentprofile_id || !agentprofiles.loaded)
      return;

    setAgentprofile(agentprofiles.dict[auth.user_status.agentprofile_id]);
  }, [agentprofiles, auth.user_status.agentprofile_id]);

  const formLayout: IForm = useMemo(() => {
    const layout = {
      id: 'filter_form',
      compact: true,
      elements: [
        { ref: 'title' },
        {
          ref: 'filters.0',
          hideLabel: true,
          noPadding: true,
          compact: true,
          elements: [
            { ref: 'queues' },
            { ref: 'additional_queues' },
            { ref: 'skills' },
            { ref: 'users' },
            { ref: 'statuses' },
            { ref: 'flags' },
            { ref: 'channels' },
            { ref: 'directions' },
            { ref: 'priorities' },
            { ref: 'clients' },
            { ref: 'dispositions', ui_type: FormElementTypes.TREE, multiple: true },
            // { ref: 'tags' },
            { ref: 'contacts' },
            { ref: 'created_at' },
            { ref: 'sla_response' },
            { ref: 'sla_resolution' },
            { ref: 'is_waiting' },
            { ref: 'is_ongoing' }
          ]
        }
      ]
    };

    if (isSuperAdmin(auth.user) || (selected?.filters[0].organizations?.length || 0) > 0)
      layout.elements[1].elements = [
        { ref: 'organizations' },
        // @ts-ignore
        ...layout.elements[1].elements
      ];

    return layout;
  }, [auth, selected]);

  useEffect(() => {
    if (!schema || !selected)
      return;

    const organization_ids = agentprofile ? [agentprofile.id] : [...auth.user.__agent_organization_ids, ...auth.user.__admin_organization_ids];

    setForm(<div className='conversation-filter-popover-form' >
      <AtlasForm
        // key={`form-${Math.random()}`}
        form={schema}
        formLayout={formLayout}
        initialValues={{
          title: selected.title,
          filters: selected.filters
        }}
        additionalParams={{
          filters_contacts: { hidden: true },
          title: { hideLabel: true },
          filters: { compact: true, noPadding: true, labelWidth: '120px' },
          filters_additional_queues: {
            help: 'HELP_ADDITIONAL_QUEUES',
            hideHelp: true
          },
          filters_created_at: {
            label: 'CREATED_AFTER'
          }
        }}
        options={{
          filters_organizations: entitiesToOptions(organizations.organizations, 'organization'),
          filters_tags: entitiesToOptions(tags.tags, 'tag', it => organization_ids.includes(it.organization_id)),
          filters_priorities: EnumToArray(ConversationPriorityEnum).map(([v, k]) => ({ key: v, title: t(`PRIORITY_${k.toUpperCase()}`) })),
          filters_statuses: EnumToArray(ConversationStatusEnum).map(([v, k]) => ({ key: v, title: t(k.toUpperCase()) })),
          filters_flags: [{ key: 0, title: t('NO_FLAG') }, ...EnumToArray(ConversationFlagEnum).map(([v, k]) => ({ key: v, title: t(k.toUpperCase()) }))],
          filters_directions: [DirectionEnum.InBound, DirectionEnum.Outbound].map(key => ({ key, title: t(`DIRECTION_${key.toUpperCase()}`) })),
          filters_channels: [ChannelEnum.Mail, ChannelEnum.Form, ChannelEnum.Voice].map(key => ({ key, title: t(`CHANNEL_${key.toUpperCase()}`) })),
          filters_dispositions: [{ key: 0, title: t('NOT_DISPOSED') }, ...entitiesToOptions(nestedArray<any>(agentprofile ? filterRelated(agentprofile.organization_id, 'disposition', 'conversation_filter', dispositions.dispositions) : dispositions.dispositions, 'disposition_id'))],
          filters_queues: [{ key: 0, title: t('NO_QUEUE') }, ...(entitiesToOptions(agentprofile ? queues.queues.filter(queue => agentprofile.__queues.includes(queue.id)) : queues.queues))],
          filters_additional_queues: entitiesToOptions(queues.queues.filter(queue => !(agentprofile?.__queues || []).includes(queue.id) )).map(option => ({
            ...option,
            deleted: !allProfileQueues.includes(option.key as number)
          })),
          filters_skills: [{ key: 0, title: t('NO_SKILL') }, ...(agentprofile ? filterRelated(agentprofile.organization_id, 'skill', 'conversation_filter', skills.skills, true) : entitiesToOptions(skills.skills, 'skill'))],
          filters_clients: [{ key: 0, title: t('NO_CLIENT') }, ...(agentprofile ? filterRelated(agentprofile.organization_id, 'client', 'conversation_filter', clients.clients, true) : entitiesToOptions(clients.clients, 'client'))],
          filters_users: [{ key: 0, title: t('UNASSIGNED') }, ...(agentprofile ? filterRelated(agentprofile.organization_id, 'user_reverse', 'conversation_filter', users.users.filter(user => isAgent(user)), true) : entitiesToOptions(users.users, 'user', it => isAgent(it)))],
        }}
        onFinish={(values: ISharedPatchConversationFilter) => {
          setEditFilterVisible(false);
          return selected.id > 0 ? patchConversationFilter(selected.id, values) : createNewConversationFilter(selected.id, values);
        }}
      />
    </div>);
  }, [selected, schema, agentprofile, auth.user, dispositions, queues, t, tags, users, formLayout]);

  const createNewConversationFilter = useCallback(async (filter_id, values: ISharedPatchConversationFilter): Promise<IPreviewConversationFilter | undefined> => {
    // @ts-ignore
    const { id, created_at, updated_at, ..._filter } = filter_id < 0 ? default_filters.find(f => (f as any).id === filter_id) : conversation_filters.find(cf => cf.id === filter_id);

    const filter = clone(_filter);

    if (values?.filters?.[0])
      filter.filters = values.filters;

    if (filter) {
      const conversation_filter = await createConversationFilter({ ...filter, title: `${filter.title} (${id > 0 ? t('CLONE') : t('CUSTOM')})` });

      if (conversation_filter)
        switchConversationFilter(conversation_filter.id);

      return conversation_filter;
    }

    return undefined;
  }, [auth, default_filters]);

  if (!schema)
    return <></>;

  const style: React.CSSProperties = {};

  if (shortened)
    style.flexBasis = '100%';

  return <div className={'conversation-filter'} style={style} title={selected?.title}>

    <Popover
      content={<div className='conversation-filter-select-popover'>
        <ul>
          {!conversation_filters.length && <li>{t('NO_CUSTOM_FILTERS_CREATED')}</li>}
          {[...default_filters, ...conversation_filters].flatMap(_conversation_filter => {
            const conversation_filter = _conversation_filter as unknown as ISharedConversationFilter;
            const isSelected = conversation_filter.id === (selected?.id || -1);

            return <li key={`conversation_filter-${conversation_filter.id}`} value={conversation_filter.id} title={conversation_filter.title}>
              <Icon icon={CheckIcon} circle={true} className={isSelected ? 'selected' : ''} />
              <span className='text' onClick={e => switchConversationFilter(conversation_filter.id)}>{conversation_filter.title}</span>
              {conversation_filter.id > 0 && !isSelected && <Icon icon={TrashIcon} className={'delete'} onClick={() => deleteConversationFilter(conversation_filter.id)} />}
            </li>;
          })}

        </ul>
        {selected && <Button
          onClick={e => createNewConversationFilter(selected.id, {}).then(conversation_filter => conversation_filter && switchConversationFilter(conversation_filter.id))}
        >
          {t('CREATE_NEW_FILTER')}
        </Button>}
      </div>}
      title={t('MY_FILTERS')}
      trigger="click"
      open={selectFilterVisible}
      onOpenChange={visible => setSelectFilterVisible(visible)}
      placement='bottomRight'
      overlayStyle={{ minWidth: 'auto' }}
    >
      <IconText icon={ListIcon} text={selected?.title} className={'select-conversation-filter'} />
    </Popover>

    {
      /*
      selected && Object.entries(selected.filters[0])
        .filter(([key, section]) => {
          return section.length;
        })
        .map(([key, section]) => {
          return <div>{key}: {section}</div>;
        })
       */
    }
    {selected && form && <Popover
      content={form}
      title={selected.title}
      trigger="click"
      open={editFilterVisible}
      onOpenChange={visible => setEditFilterVisible(visible)}
      placement='bottomRight'
      key={`conversation_filter-popover-${selected?.id}`}
    >
      <Badge count={chosenFilters} showZero>
        <Icon icon={FilterIcon} iconProps={{ style: { color: 'var(--COLOR-FFFFFF)' } }} className={'edit-conversation-filter'} />
      </Badge>
    </Popover>}

  </div>;

});
