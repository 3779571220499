import { RestRequest } from '@Utils';
import { IPreviewApiKey, ISharedApiKey, ISharedCreateApiKey, ISharedPatchApiKey } from 'atlas-shared';

export const fetchApiKeys = async (): Promise<Array<IPreviewApiKey>> => {
  return RestRequest.get<Array<IPreviewApiKey>>('api_key', { socket_id: global.socketId });
};

export const fetchApiKey = async (id: IPreviewApiKey['id']): Promise<ISharedApiKey> => {
  return RestRequest.get<ISharedApiKey>(`api_key/${id}`, { socket_id: global.socketId });
};

export const createApiKey = (payload: ISharedCreateApiKey) => {
  return RestRequest.post<any, ISharedApiKey>('api_key', payload, { socket_id: global.socketId });
};

export const patchApiKey = (id: IPreviewApiKey['id'], payload: ISharedPatchApiKey) => {
  return RestRequest.patch<any, ISharedApiKey>(`api_key/${id}`, payload, { socket_id: global.socketId });
};

export const deleteApiKey = (id: IPreviewApiKey['id']) => {
  return RestRequest.delete(`api_key/${id}`, { socket_id: global.socketId });
};

export const getApiKeyUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('api_key/update/schema');
};

export const getApiKeyCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('api_key/create/schema');
};

export const getApiKeyPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('api_key/patch/schema');
};
