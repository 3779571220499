import { IPreviewVoiceCall } from 'atlas-shared';
import { IVoiceCallStore, VoiceCallActions, TVoiceCallActionTypes } from '@Store';

export const VoiceCallReducer = (state: IVoiceCallStore = {
  loaded: false,
  loading: false,
  voice_calls: [],
  dict: {}
}, action: TVoiceCallActionTypes): IVoiceCallStore => {
  switch (action.type) {
  case VoiceCallActions.LOADING:
    return { ...state, loading: true };
  case VoiceCallActions.SET_STATE:
    const dict: IVoiceCallStore['dict'] = {};

    action.payload.forEach((voice_call: IPreviewVoiceCall) => dict[voice_call.id] = voice_call);

    return { ...state, loaded: true, loading: false, voice_calls: action.payload, dict };
  case VoiceCallActions.ADD_STATE:
    return { ...state, voice_calls: [...state.voice_calls, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceCallActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_calls: state.voice_calls.filter((item: IPreviewVoiceCall) => item.id !== action.payload), dict };
  }
  case VoiceCallActions.UPDATE_STATE:
    const voice_calls = [...state.voice_calls];
    const index = state.voice_calls.findIndex((voice_call: IPreviewVoiceCall) => voice_call.id === action.payload.id);

    if (!~index)
      voice_calls.push(action.payload);
    else
      voice_calls[index] = action.payload;

    return { ...state, voice_calls, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
