import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  FormWebsiteEditForm,
  FormWebsiteEditInitialValues,
  FormWebsiteComponents,
  FormWebsiteAdditionalParams,
  FormWebsiteFormOptions,
  onFormWebsiteFormSaved,
  IFormWebsiteFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedFormWebsite, ISharedPatchFormWebsite, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchFormWebsite, patchFormWebsite, getFormWebsiteUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useJourneies, useForms } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedFormWebsite> {}

const FormWebsiteFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const journeies = useJourneies();
  const forms = useForms();

  useEffect(() => {
    fetchFormWebsite(id).then(form_website => setValue(form_website));
  }, [id, setValue]);

  const param_props: IFormWebsiteFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    journeies: journeies.journeies,
    forms: forms.forms
  };
  const formLayout: IForm | undefined = useMemo(() => value && FormWebsiteEditForm(value, param_props, t), [value, organizations, journeies, forms, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && FormWebsiteFormOptions(value, param_props, t), [value, organizations, journeies, forms, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchFormWebsite, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchFormWebsite) => value && save(() => patchFormWebsite(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onFormWebsiteFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !journeies.loaded || !forms.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.FormWebsite, value)}
    >
      <AtlasForm<ISharedPatchFormWebsite>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={FormWebsiteComponents}
        additionalParams={FormWebsiteAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={FormWebsiteEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('FORM_WEBSITE')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const FormWebsiteFormEditView = React.memo(withEdit(FormWebsiteFormEdit, getFormWebsiteUpdateSchema));
