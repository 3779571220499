import { TFunction } from 'i18next';
import { ITagStore } from '@Store';
import React, { useEffect, useState } from 'react';
import { TagIcon } from '@Assets/icons';
import { IPreviewConversation } from 'atlas-shared';
import { Icon } from '@Components';

interface IProps {
  conversation: IPreviewConversation;
  shortened: boolean;
  t: TFunction;
  tags: ITagStore;
}

export const ConversationTags = React.memo(({ conversation, t, tags: _tags }: IProps) => {
  const [tags, setTags] = useState<Array<string>>([]);

  useEffect(() => {
    if (!conversation.__tags?.length || !_tags.loaded)
      return;

    setTags(conversation.__tags?.map(tag => _tags.tags.find(t => t.id === tag)?.title).filter(Boolean) as Array<string>);
  }, [_tags, conversation.__tags]);

  return tags.length ? <Icon icon={TagIcon} tooltip={{
    title: <span style={{ fontSize: 12 }}>{t('TAGS')}: {tags.join(', ')}</span>,
    placement: 'bottom'
  }} /> : <></>;
});
