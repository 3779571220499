import { RestRequest } from '@Utils';
import {
  IPreviewAgentprofile,
  IPreviewStatsReport,
  IPreviewStatsReportWidget, ISharedStatsReport, ISharedUser,
  ISharedUserStatus,
  IStatsWidgetDataPayload,
  Nullable
} from 'atlas-shared/dist';
import { actionUpdateStatsWidgetData } from '@Store';

export * from './abstract/stats_report.api.abstract';

export const setStatsReportActive = (id: IPreviewStatsReportWidget['id'], agentprofile_id: Nullable<IPreviewAgentprofile['id']>, is_preview: boolean) => {
  return RestRequest.post<any, Array<IStatsWidgetDataPayload>>(`stats_report/${id}/active`, { agentprofile_id, is_preview }, { socket_id: global.socketId });
};

export const setStatsReportInactive = () => {
  return RestRequest.post<any, Array<IStatsWidgetDataPayload>>('stats_report/inactive', { }, { socket_id: global.socketId });
};

export const statsWidgetLoadWidgets = (report_id: IPreviewStatsReport['id'], agentprofile_id: ISharedUserStatus['agentprofile_id'], dispatch, is_preview: boolean) => {
  setTimeout(() => {
    setStatsReportActive(report_id, agentprofile_id || null, is_preview).then(widgets => {
      widgets.forEach(widget => {
        dispatch(actionUpdateStatsWidgetData(widget));
      });
    });
  }, 1000);
};

export const transferStatsReport = (id: IPreviewStatsReport['id'], to: ISharedUser['id']) => {
  return RestRequest.patch<any, ISharedStatsReport>(`stats_report/${id}/transfer/${to}`, {}, { socket_id: global.socketId });
};
