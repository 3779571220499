import { NavigateFunction } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { ISharedCreateTariff, ISharedUpdateTariff, ISharedTariff, IPreviewTariffParent, IPreviewOrganization, EnumOptions, VoiceTariffRoundupStrategy } from 'atlas-shared';
import { TFunction } from 'i18next';
import { entitiesToOptions, onFormSaved } from '@Utils';

export interface ITariffFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  tariff_parents: Array<IPreviewTariffParent>;
  organizations: Array<IPreviewOrganization>;
}

export const TariffFormOptions = (tariff: ISharedCreateTariff | ISharedTariff, props: ITariffFormOptionsProps, t: TFunction): IFormOptions => ({
  tariff_parent_id: entitiesToOptions<IPreviewTariffParent>(props.tariff_parents, 'tariff_parent'),
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  voice_tariff_roundup_strategy: EnumOptions('VOICE_TARIFF_ROUNDUP_STRATEGY', VoiceTariffRoundupStrategy, t),
  voice_usage_roundup_strategy: EnumOptions('VOICE_TARIFF_ROUNDUP_STRATEGY', VoiceTariffRoundupStrategy, t),
});

export const TariffComponents: IForm['components'] = {};
export const TariffAdditionalParams: (auth: IAuth, props: ITariffFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITariffFormOptionsProps) => ({
});

export const TariffEditForm = (tariff: ISharedTariff, props: ITariffFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `tariff_edit_${tariff.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'tariff_parent_id' },
    { ref: 'title' },
    { ref: 'voice_tariff_list' },
    { ref: 'voice_recording' },
    { ref: 'voice_recording_storage' },
    { ref: 'voice_recording_transcript' },
    { ref: 'voice_tariff_roundup_strategy' },
    { ref: 'voice_outbound_usage' },
    { ref: 'voice_usage_roundup_strategy' },
    { ref: 'voice_inbound_usage' },
    { ref: 'voice_internal_usage' },
    { ref: 'attachments_storage' },
    { ref: 'email_inbound_usage' },
    { ref: 'email_outbound_usage' },
    { ref: 'form_inbound_usage' }
  ]
});

export const TariffAddForm = (tariff: ISharedCreateTariff, props: ITariffFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'tariff_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'tariff_parent_id' },
    { ref: 'title' },
    { ref: 'voice_tariff_list' },
    { ref: 'voice_recording' },
    { ref: 'voice_recording_storage' },
    { ref: 'voice_recording_transcript' },
    { ref: 'voice_tariff_roundup_strategy' },
    { ref: 'voice_outbound_usage' },
    { ref: 'voice_usage_roundup_strategy' },
    { ref: 'voice_inbound_usage' },
    { ref: 'voice_internal_usage' },
    { ref: 'attachments_storage' },
    { ref: 'email_inbound_usage' },
    { ref: 'email_outbound_usage' },
    { ref: 'form_inbound_usage' }
  ]
});

export const TariffAddInitialValues: ISharedCreateTariff = {} as ISharedCreateTariff;

export const TariffEditInitialValues = (tariff: ISharedTariff): ISharedUpdateTariff => ({
  tariff_parent_id: tariff.tariff_parent_id,
  title: tariff.title,
  voice_tariff_list: tariff.voice_tariff_list,
  voice_recording: tariff.voice_recording,
  voice_recording_storage: tariff.voice_recording_storage,
  voice_recording_transcript: tariff.voice_recording_transcript,
  voice_tariff_roundup_strategy: tariff.voice_tariff_roundup_strategy,
  voice_outbound_usage: tariff.voice_outbound_usage,
  voice_usage_roundup_strategy: tariff.voice_usage_roundup_strategy,
  voice_inbound_usage: tariff.voice_inbound_usage,
  voice_internal_usage: tariff.voice_internal_usage,
  attachments_storage: tariff.attachments_storage,
  email_inbound_usage: tariff.email_inbound_usage,
  email_outbound_usage: tariff.email_outbound_usage,
  form_inbound_usage: tariff.form_inbound_usage
});

export const onTariffFormSaved = (t: TFunction, action: 'edit' | 'add', tariff: ISharedTariff, navigate: NavigateFunction): void => {
  navigate(onFormSaved('tariffs'));
};
