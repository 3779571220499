import './conversation.disposition_note.scss';
import React, { useEffect, useState } from 'react';
import { ISharedConversation, IPreviewUser } from 'atlas-shared';
import { getFullName, timeSince } from '@Utils';
import { useTranslation } from 'react-i18next';
import { useUsers } from '@Hooks';
import { Avatar, IconText } from '@Components/misc';
import { ConversationDisposition } from '@Components/conversation';
import { EditIcon } from '@Assets';

interface IProps {
  conversation: ISharedConversation;
  mini?: boolean;
}
export const ConversationDispositionNote = React.memo(({ conversation, mini }: IProps) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<IPreviewUser>();
  const users = useUsers();

  useEffect(() => {
    if (conversation?.user_id)
      setUser(users.dict[conversation.user_id]);
  }, [conversation.user_id]);
  
  return (
    <div className={'conversation-message conversation-disposition-note'}>
      <div className='conversation-message-content'>
        {!mini && conversation.status_at && <div className='head'>
          <div className={'info'}>
            <div className={'time'}>
              {timeSince(t, new Date(conversation.status_at), true)}
            </div>
          </div>
          {user && <div className={'user'}>
            <Avatar user={user} size={24}/>
            <div className={'user-info'}>
              <span>
                {getFullName(user)}
              </span>
            </div>
          </div>}
        </div>}
        <ConversationDisposition conversation={conversation} long={!mini} />
        {conversation.disposition_note && <IconText icon={EditIcon} text={<span className={'disposition-note'} dangerouslySetInnerHTML={{ __html: conversation.disposition_note }}/>} iconProps={{ size: 10 }} />}
      </div>
    </div>
  );
});
