import { journeyAction, settingsFormSchema } from './base';

import { JourneyActionVoiceMenu as JourneyActionVoiceMenuBase } from 'atlas-shared';

export const JourneyActionVoiceMenu = journeyAction({
  ...JourneyActionVoiceMenuBase,
  settings_form: settingsFormSchema({
    elements: [
      { ref: 'asterisk_sound_id' },
      { ref: 'journey_variable_id' },
      { ref: 'options' },
      { ref: 'retries' },
      { ref: 'timeout' }
    ]
  }),
  additionalParams: {
    properties_timeout: { postFixText: 'MILLISECONDS' },
    properties_retries: { postFixText: 'TIMES_TO_RETRY' }
  },
  icon: 'Menu'
});
