import { fetchRecentlyViewedConversations } from '@Api';
import {
  actionLoadingRecentlyViewedConversations, actionRemoveRecentlyViewedConversation,
  actionSetRecentlyViewedConversations
} from './abstract/recently_viewed_conversation.actions.abstract';
import { ISharedAgentprofile, Nullable } from 'atlas-shared';
import { IRecentlyViewedConversationStore } from '@Store';

export * from './abstract/recently_viewed_conversation.actions.abstract';

export const actionFetchRecentlyViewedConversations = (agentprofile_id?: Nullable<ISharedAgentprofile['id']>) => {
  return async (dispatch) => {
    dispatch(actionLoadingRecentlyViewedConversations());
    const recently_viewed_conversations = await fetchRecentlyViewedConversations(agentprofile_id);

    dispatch(actionSetRecentlyViewedConversations(recently_viewed_conversations));
    return recently_viewed_conversations;
  };
};

export const actionClearAllRecentlyViewed = () => {
  return (dispatch, getState) => {
    (getState().recently_viewed_conversations as IRecentlyViewedConversationStore).recently_viewed_conversations.forEach((search) => {
      dispatch(actionRemoveRecentlyViewedConversation(search.id));
    });
  };
};
