import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateList, ISharedUpdateList, ISharedList, IPreviewOrganization, IPreviewForm, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IListFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  forms: Array<IPreviewForm>;
}

export const ListFormOptions = (list: ISharedCreateList | ISharedList, props: IListFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.List, {})), 'organization_id', false), 'organization'),
  form_id: (it: ISharedList) => filterRelated<IPreviewForm>(it.organization_id || list.organization_id, 'form', 'list', props.forms, true),
  
});

export const ListComponents: IForm['components'] = {};
export const ListAdditionalParams: (auth: IAuth, props: IListFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IListFormOptionsProps) => ({
  form_id: {
    onModalAdd: {
      key: 'queue-form_id-add',
      path: '@Components/page-views/form',
      component: 'FormFormAddView',
      item_text: 'FORM'
    }
  }
});

export const ListEditForm = (list: ISharedList, props: IListFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `list_edit_${list.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === list.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'form_id' },
    { ref: 'description' }
  ]
});

export const ListAddForm = (list: ISharedCreateList, props: IListFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'list_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'form_id' },
    { ref: 'description' }
  ]
});

export const ListAddInitialValues: ISharedCreateList = {} as ISharedCreateList;

export const ListEditInitialValues = (list: ISharedList): ISharedUpdateList => ({
  title: list.title,
  form_id: list.form_id,
  description: list.description
});

export const onListFormSaved = (t: TFunction, action: 'edit' | 'add', list: ISharedList, navigate: NavigateFunction): void => {
  navigate(onFormSaved('lists'));
};
