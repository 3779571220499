import React, { useEffect, useMemo, useState } from 'react';
import { Popover } from 'antd';
import './conversation.title.box.icons.scss';
import { ArchiveIcon, CallAddIcon, EditIcon, MailIcon, MergeIcon, MoreHorizontalIcon, TagIcon } from '@Assets/icons';
import { ISharedFullConversation, ConversationStatusOptions, ConversationPriorityOptions, IPreviewQueue, Nullable, hasVoiceLicense, DraftChannelEnum, canEmail } from 'atlas-shared';

import { useTranslation } from 'react-i18next';
import { IWithFullConversationProps } from '@Hocs/with.full.conversation';
import { FormSelect } from 'atlas-form';
import { useClients, useQueues } from '@Hooks';
import { conversationMessageReply, entitiesToOptions, IOption } from '@Utils';
import { patchConversation } from '@Api';
import { IAuth } from '@Store';
import {
  VoiceSuggestions,
  Icon,
  ConversationDispositionPopover,
  ConversationMergePopover,
  ConversationTagPopover,
  IconText
} from '@Components';

interface IProps {
  auth: IAuth;
  conversation: ISharedFullConversation;
  className?: string;
  patch_conversation: IWithFullConversationProps['patch_conversation'];
  short?: boolean;
}
export const ConversationTitleBoxIcons = React.memo(({ conversation, className, patch_conversation, auth, short = false }: IProps) => {

  const { t } = useTranslation();
  const [queueOptions, setQueueOptions] = useState<Array<IOption>>([]);
  const [clientOptions, setClientOptions] = useState<Array<IOption>>([]);
  const queues = useQueues();
  const clients = useClients();
  const [queue, setQueue] = useState<Nullable<IPreviewQueue>>(null);

  useEffect(() => {
    setQueue(queues.loaded && conversation.queue_id ? queues.dict[conversation.queue_id] || null : null);
  }, [queues, conversation.queue_id]);

  useEffect(() => {
    setQueueOptions(entitiesToOptions(queues.queues).map(o => ({ ...o, key: o.key })));
  }, [queues]);

  useEffect(() => {
    setClientOptions(queue?.clients?.length ? entitiesToOptions(clients.clients.filter(client => queue.clients.includes(client.id))).map(o => ({ ...o, key: o.key })) : []);
  }, [clients, queue]);

  const conversationMerge = useMemo(() => <ConversationMergePopover patch_conversation={patch_conversation} conversation={conversation}>
    {!short ? <Icon tooltip={{ title: t('MERGE_CONVERSATION') }} icon={MergeIcon}/> : <IconText icon={MergeIcon} text={t('MERGE_CONVERSATION')} />}
  </ConversationMergePopover>, [short]);

  const createInternalNote = useMemo(() => !short
    ? <Icon icon={EditIcon} tooltip={{ title: t('CREATE_INTERNAL_NOTE') }} onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.InternalNote, conversation, queue)} />
    : <IconText icon={EditIcon} text={t('CREATE_INTERNAL_NOTE')} onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.InternalNote, conversation, queue)} />
  , [short, auth, conversation, queue]);

  const createMail = useMemo(() => conversation.queue_id && !short
    ? <Icon icon={MailIcon} tooltip={{ title: t('NEW_MAIL_MESSAGE') }} onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.Mail, conversation, queue)}/>
    : <IconText icon={MailIcon} text={t('NEW_MAIL_MESSAGE') } onClick={_ => conversationMessageReply(t, auth.user, DraftChannelEnum.Mail, conversation, queue)}/>
  , [short, auth, queue, conversation]);

  const addCall = useMemo(() => auth.user.main && hasVoiceLicense(auth.user) && <Popover
    trigger='click'
    placement='bottom'
    content={<><VoiceSuggestions conversation={conversation} /></>}
  >
    {!short
      ? <Icon icon={CallAddIcon} tooltip={{ title: t('ADD_CALL_TO_CONVERSATION') }} />
      : <IconText icon={CallAddIcon} text={t('ADD_CALL_TO_CONVERSATION')} />
    }
  </Popover>, [auth, short, conversation]);

  return (
    <div className={'conversation-title-view-icons ' + className}>
      <div className='left'>
        <FormSelect value={conversation.status} options={ConversationStatusOptions(t)} onChange={(status) => patch_conversation({ status })} showSearch={false} />
        <FormSelect value={conversation.priority} options={ConversationPriorityOptions(t)} onChange={(priority) => patchConversation(conversation.organization_id, conversation.id, { priority })} showSearch={false} />
        <FormSelect value={conversation.queue_id} options={queueOptions} onChange={(queue_id) => patchConversation(conversation.organization_id, conversation.id, { queue_id })} placeholder={t('SELECT_QUEUE')} />
        {!!clientOptions?.length && <FormSelect value={conversation.client_id} options={clientOptions}
          onChange={(client_id) => patchConversation(conversation.organization_id, conversation.id, { client_id })}
          placeholder={t('SELECT_CLIENT')}/>}
      </div>
      <div className='right'>
        {queue && <ConversationDispositionPopover patch_conversation={patch_conversation} conversation={conversation}>
          <Icon tooltip={{ title: t('DISPOSE') }} icon={ArchiveIcon}/>
        </ConversationDispositionPopover>}
        {!short && conversationMerge}
        {queue && <ConversationTagPopover queue={queue} patch_conversation={patch_conversation} conversation={conversation}>
          <Icon tooltip={{ title: t('EDIT_TAGS') }} icon={TagIcon} />
        </ConversationTagPopover>}
        {!short && addCall}
        {!short && createInternalNote}
        {!short && createMail}
        {short && <Popover
          trigger='click'
          placement='bottom'
          overlayClassName={'conversation-more-actions-popover-container'}
          content={<ul>
            <li>{conversationMerge}</li>
            <li>{createInternalNote}</li>
            {addCall && <li>{addCall}</li>}
            {canEmail(auth.user) && <li>{createMail}</li>}
          </ul>}
        >
          <Icon icon={MoreHorizontalIcon} className={'clickable'}/>
        </Popover>}

      </div>
    </div>
  );
});
