import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const ConversationListIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M16.1726 1H2.51726C2.11486 1 1.72894 1.15985 1.4444 1.4444C1.15985 1.72894 1 2.11486 1 2.51726V11.6208C1 12.0233 1.15985 12.4092 1.4444 12.6937C1.72894 12.9783 2.11486 13.1381 2.51726 13.1381H4.79316V16.1726L7.82769 13.1381H16.1726C16.575 13.1381 16.961 12.9783 17.2455 12.6937C17.5301 12.4092 17.6899 12.0233 17.6899 11.6208V2.51726C17.6899 2.11486 17.5301 1.72894 17.2455 1.4444C16.961 1.15985 16.575 1 16.1726 1Z" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.89228 18.051C9.2469 19.2614 9.90108 20.3756 10.8091 21.2834L8.58633 23.5062H15.414C17.084 23.5061 18.7072 23.2079 20.0321 22.1912C21.3569 21.1746 22.3093 19.7493 22.7415 18.1362C23.1737 16.5232 23.0616 14.8126 22.4226 13.2697C22.168 12.6551 21.8355 12.0807 21.4361 11.5593" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 7H13.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 4.5H13.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 9.5H13.5" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>;
};
