import { ISharedVoiceRecordingTranscriptTranscriptSentenceProperty } from 'atlas-shared';
import React from 'react';
import { secondsToTime } from '@Utils';
import { ConversationMessageVoiceCallVoiceRecordingTranscriptSentenceWord } from './conversation.message.voice_call.voice_recording_transcript.sentence.word';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
  sentence: ISharedVoiceRecordingTranscriptTranscriptSentenceProperty;
  currentTime: number;
}

export const ConversationMessageVoiceCallVoiceRecordingTranscriptSentence = React.memo(({ t, sentence, currentTime }: IProps) => {

  return <div className={`sentence speaker-${sentence.speaker}${currentTime > 0 ? ' playing' : ''}`}>
    <div className='time'>
      <span className='start'>{secondsToTime(sentence.start, true, false, 1)}</span>
      <span className='demiliter'>-</span>
      <span className='end'>{secondsToTime(sentence.end, true, false, 1)}</span>
    </div>
    <span className='text'>{sentence.words.map(word => <ConversationMessageVoiceCallVoiceRecordingTranscriptSentenceWord
      t={t}
      word={word}
      playing={word.start <= currentTime && word.end >= currentTime}
    />)}</span>
  </div>;

});
