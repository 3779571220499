import { IPreviewConversationFilter } from 'atlas-shared';
import { IConversationFilterStore, ConversationFilterActions, TConversationFilterActionTypes } from '@Store';

export const ConversationFilterReducer = (state: IConversationFilterStore = {
  loaded: false,
  loading: false,
  conversation_filters: [],
  dict: {}
}, action: TConversationFilterActionTypes): IConversationFilterStore => {
  switch (action.type) {
  case ConversationFilterActions.LOADING:
    return { ...state, loading: true };
  case ConversationFilterActions.SET_STATE:
    const dict: IConversationFilterStore['dict'] = {};

    action.payload.forEach((conversation_filter: IPreviewConversationFilter) => dict[conversation_filter.id] = conversation_filter);

    return { ...state, loaded: true, loading: false, conversation_filters: action.payload, dict };
  case ConversationFilterActions.ADD_STATE:
    return { ...state, conversation_filters: [...state.conversation_filters, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case ConversationFilterActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, conversation_filters: state.conversation_filters.filter((item: IPreviewConversationFilter) => item.id !== action.payload), dict };
  }
  case ConversationFilterActions.UPDATE_STATE:
    const conversation_filters = [...state.conversation_filters];
    const index = state.conversation_filters.findIndex((conversation_filter: IPreviewConversationFilter) => conversation_filter.id === action.payload.id);

    if (!~index)
      conversation_filters.push(action.payload);
    else
      conversation_filters[index] = action.payload;

    return { ...state, conversation_filters, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
