import { CustomFormItemProps, IFormComponent } from 'atlas-form';
import { IPreviewContact, IPreviewQueue, TRequired } from 'atlas-shared';
import { useQueues } from '@Hooks';
import { useEffect, useState } from 'react';
import { fetchContact } from '@Api';
import { entityToOption } from '../../options';
import { ConversationContactMergeForm } from '@Components';

export const FormComponentContact: IFormComponent = ({ onChange, value, data }: TRequired<CustomFormItemProps>) => {

  const [base] = data;
  const queues = useQueues();
  const [Queue, setQueue] = useState<IPreviewQueue>();
  const [contact, setContact] = useState<IPreviewContact>();
  const [contactLoaded, setContactLoaded] = useState<boolean>(!value);

  useEffect(() => {
    if (value && !contact)
      fetchContact(0, value)
        .then(contact => setContact(contact))
        .finally(() => setContactLoaded(true))
      ;
  }, [contact, value]);
  
  useEffect(() => {
    if (!queues.loaded)
      return;

    setQueue(base.queue_id ? queues.queues.find(queue => queue.id === base.queue_id) : undefined);
  }, [base.queue_id, queues]);

  if (!contactLoaded)
    return <>Loading contact...</>;

  if (!Queue)
    return <>Loading queue...</>;

  return <ConversationContactMergeForm
    organization_id={Queue.organization_id}
    contact_id={value || null}
    create_list_id={Queue.create_list_id}
    onChange={onChange}
    options={contact ? [entityToOption(contact, 'contact')] : []}
  />;
};
