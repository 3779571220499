import { IPreviewNotificationUser } from 'atlas-shared';

export enum NotificationUserActions {
  SET_STATE = 'NOTIFICATION_USER_SET_STATE',
  ADD_STATE = 'NOTIFICATION_USER_ADD_STATE',
  REMOVE_STATE = 'NOTIFICATION_USER_REMOVE_STATE',
  GET_STATE = 'NOTIFICATION_USER_GET_STATE',
  UPDATE_STATE = 'NOTIFICATION_USER_UPDATE_STATE',
  LOADING = 'NOTIFICATION_USER_LOADING',
}

export interface INotificationUserLoading {
  type: NotificationUserActions.LOADING;
}

export interface INotificationUserSetAction {
  type: NotificationUserActions.SET_STATE;
  payload: Array<IPreviewNotificationUser>;
}

export interface INotificationUserUpdateAction {
  type: NotificationUserActions.UPDATE_STATE;
  payload: IPreviewNotificationUser;
}

export interface INotificationUserAddAction {
  type: NotificationUserActions.ADD_STATE;
  payload: IPreviewNotificationUser;
}

export interface INotificationUserRemoveAction {
  type: NotificationUserActions.REMOVE_STATE;
  payload: IPreviewNotificationUser['id'];
}

export interface INotificationUserStore {
  loaded: boolean;
  loading: boolean;
  notification_users: Array<IPreviewNotificationUser>;
  dict: Record<IPreviewNotificationUser['id'], IPreviewNotificationUser>;
}

export type TNotificationUserActionTypes = INotificationUserSetAction | INotificationUserUpdateAction | INotificationUserAddAction | INotificationUserRemoveAction | INotificationUserLoading;
