import React, { useEffect, useState } from 'react';
import './voice-call-modal.scss';
import {
  actionSetGlobalCall,
  actionSetGlobalVoiceQueueRouteOptions,
  actionSetGlobalVoiceQueueRouteSelected,
  IVoiceQueueRouteOption,
  useAppDispatch
} from '@Store';
import { Modal, Select } from 'antd';
import { ISharedContact, Undefinable, ISharedVoiceAsterisk, ISharedUserStatusCurrentVoiceAsteriskProperty, getQueueRouteOutboundExtension, isEmergencyNumber } from 'atlas-shared';
import { getSipUsersWrapper } from 'SIP';
import {
  useAuth, useCall,
  useQueues,
  useOrganizations,
  useSipUsers,
  useVoiceQueueRouteOptions,
  useVoiceQueueRouteSelected,
  useVoiceRoutes, useVoiceAsterisks
} from '@Hooks';
import { useTranslation } from 'react-i18next';
import { URI } from 'sip.js';
import { QueueRouteSelection } from '@Utils/queue-route';
import { IconText } from '@Components/misc';
import { PhoneIcon } from '@Assets';
import { DisplayPhoneNumber, getOutboundAsterisk, translatePhoneNumber } from '@Utils';
import { useStableNavigate } from '../../../context/StableNavigateContext';

export const VoiceCallModal = React.memo(() => {
  const navigate = useStableNavigate();
  const call = useCall();
  const voice_asterisks = useVoiceAsterisks();
  const voice_queue_route_options = useVoiceQueueRouteOptions();
  const voice_queue_route_selected = useVoiceQueueRouteSelected();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const auth = useAuth();
  const sip_users = useSipUsers();
  const organizations = useOrganizations();
  const sip_users_wrapper = getSipUsersWrapper();
  const contact_id: Undefinable<ISharedContact['id']> = undefined;
  const queues = useQueues();
  const voice_routes = useVoiceRoutes();
  const [outboundAsterisk, setOutboundAsterisk] = useState<ISharedUserStatusCurrentVoiceAsteriskProperty>();

  const [number, setNumber] = useState<Undefinable<string>>();
  const [numbers, setNumbers] = useState<Array<string>>([]);

  useEffect(() => {
    setOutboundAsterisk(getOutboundAsterisk(auth.user_status));
  }, [auth.user_status.__current_voice_asterisks]);

  useEffect(() => {
    setNumbers(call?.numbers || []);
    if (call?.numbers.length === 1)
      setNumber(call?.numbers[0]);
    else if (number && !call?.numbers.includes(number))
      setNumber(undefined);
  }, [call?.numbers, number]);

  useEffect(() => {
    const queue_routes: Array<IVoiceQueueRouteOption> = queues.queues
      .filter(queue => !!queue.voice_settings && auth.user_status.__current_queues.includes(queue.id))
      .map(queue => voice_routes.voice_routes
        .filter(voice_route => queue.__outbound_voice_routes.includes(voice_route.id))
        .map(route => ({
          queue,
          route
        }))
      ).flat();

    dispatch(actionSetGlobalVoiceQueueRouteOptions(queue_routes));

    if (queue_routes.length === 1) {
      dispatch(actionSetGlobalVoiceQueueRouteSelected({
        queue_id: queue_routes[0].queue.id,
        route_id: queue_routes[0].route.id,
      }));
    }

  }, [
    auth.user_status.__current_queues,
    queues,
    voice_routes,
    dispatch
  ]);

  useEffect(() => {
    if (
      (voice_queue_route_selected?.route_id && !voice_queue_route_options.some(queue_route => queue_route.route.id === voice_queue_route_selected.route_id))
      || (voice_queue_route_selected?.queue_id && !voice_queue_route_options.some(queue_route => queue_route.queue.id === voice_queue_route_selected.queue_id))
    )
      dispatch(actionSetGlobalVoiceQueueRouteSelected(null));

  }, [
    voice_queue_route_options,
    dispatch,
    voice_queue_route_selected
  ]);

  if (!call)
    return <></>;

  const clear = () => dispatch(actionSetGlobalCall(null));
  const makeCall = () => {

    if (!call || !number)
      return;

    let voice_asterisk_id: Undefinable<ISharedVoiceAsterisk['id']> = call.voice_asterisk_id;

    if (voice_asterisk_id === -1)
      voice_asterisk_id = outboundAsterisk?.vaid;

    if (!voice_asterisk_id)
      return clear();

    const voice_asterisk = voice_asterisks.dict[voice_asterisk_id];
    const sip_user_wrapper = sip_users_wrapper?.getSipUser(voice_asterisk_id);
    const sip_user = sip_user_wrapper?.getSipUser();

    if (sip_user_wrapper && sip_user) {
      const extraHeaders: Array<string> = [
        ...(call.headers || []),
        `X-User-ID: ${auth.user.id}`
      ];

      if (call.conversation_id)
        extraHeaders.push(`X-Conversation-ID: ${call.conversation_id}`);

      // if (!conversation_id)
      //   extraHeaders.push('X-Conversation-ID: 1000366');

      if (contact_id)
        extraHeaders.push(`X-Contact-ID: ${contact_id}`);

      // blind transfer
      if (call.transfer) {

        if (call.requires_route && (!voice_queue_route_selected?.queue_id || !voice_queue_route_selected?.route_id))
          return;

        const uri = new URI('sip', `${call.requires_route ? getQueueRouteOutboundExtension(voice_queue_route_selected!.queue_id, voice_queue_route_selected!.route_id) : ''}${number.replace('+', '')}`, sip_user.options.userAgentOptions?.uri?.host as string, 5060, {});

        if (uri)
          sip_user.getSessionBySharedVoiceCall(call.transfer)?.session.refer(uri, {
            requestOptions: {
              extraHeaders
            }
          });
      }
      // new call
      else {
        
        let sip_endpoint: string;

        if (isEmergencyNumber(number, sip_user_wrapper?.voice_asterisk.iso2)) {
          sip_endpoint = `sip:${getQueueRouteOutboundExtension(voice_queue_route_selected!.queue_id, voice_queue_route_selected!.route_id)}${voice_asterisk.iso2}-emergency-${number.replace('+', '')}@${sip_user.options.userAgentOptions?.uri?.host}:5060`;
        }
        else {
          if (call.requires_route && (!voice_queue_route_selected?.queue_id || !voice_queue_route_selected?.route_id))
            return;

          sip_endpoint = `sip:${call.requires_route ? getQueueRouteOutboundExtension(voice_queue_route_selected!.queue_id, voice_queue_route_selected!.route_id) : ''}${number.replace('+', '')}@${sip_user.options.userAgentOptions?.uri?.host}:5060`;
        }

        sip_user.call(sip_endpoint, {
          extraHeaders
        });

        if (window.location.pathname.startsWith('/admin') || window.location.pathname.startsWith('/stats'))
          navigate('/dashboard');
      }

      clear();
    }

  };

  if (
    !call.confirm
    && (call.requires_route && voice_queue_route_selected?.queue_id && voice_queue_route_selected?.route_id && number)
    || (!call.requires_route && number)
  ) {
    makeCall();
    return <></>;
  }

  return <Modal
    visible={!!call}
    onOk={() => {
      makeCall();
    }}
    onCancel={() => {
      clear();
    }}
    width={320}
    className={'voice-call-modal'}
    okText={<IconText icon={PhoneIcon} text={t('CALL')} iconProps={{ size: 18, style: { strokeWidth: 2, opacity: .7 } }} />}
    okButtonProps={{ disabled: !(number && voice_queue_route_selected?.queue_id && voice_queue_route_selected?.route_id) }}
  >
    <Select
      value={number}
      onChange={n => setNumber(n)}
      placeholder={t('SELECT_NUMBER')}
    >
      {
        numbers.map(number => <Select.Option value={number}>
          <DisplayPhoneNumber t={t} phonenumber={translatePhoneNumber(number)} />
        </Select.Option>)
      }
    </Select>

    <QueueRouteSelection t={t} />
  </Modal>;
});
