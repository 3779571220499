import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchTeams, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useTeams = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const teams = useSelector((store: IReducerStore) => store.teams);

  useEffect(() => {
    if (!initiated && autoload && !teams.loaded && !teams.loading) {
      initiated = true;
      dispatch(actionFetchTeams());
    }
  }, [autoload, teams.loaded, teams.loading, dispatch]);

  return teams;
};
