import React from 'react';
import { ISharedInternalNote, ISharedFullConversation, IPreviewTab } from 'atlas-shared';
import { TFunction } from 'i18next';
import { Icon } from '@Components/misc';
import { EditIconCircled } from '@Assets/icons/channels';
import { ConversationMessageInternalNoteBody } from './conversation.message.internal_note.body';
import { THighlightedMessage } from '@Hooks';
import { ConversationMessageReplies } from '@Components/conversation';
import { highlightClassNames } from '@Utils';

interface IProps {
  t: TFunction;
  message: ISharedInternalNote;
  conversation: ISharedFullConversation;
  highlightedMessage: THighlightedMessage;
  tab?: IPreviewTab;
}

export const ConversationMessageInternalNoteMini = React.memo(({ message, conversation, highlightedMessage, t, tab }: IProps) => {
  return <div className={`conversation-message conversation-message-internal-note${highlightClassNames(message, highlightedMessage)}`}>
    <div className='conversation-message-content'>
      <Icon icon={EditIconCircled} iconProps={{ size: 10, padding: 3 }} className='channel-icon' tooltip={{ title: t('INTERNAL_NOTE') }} />
      <ConversationMessageInternalNoteBody t={t} message={message} tab={tab} conversation={conversation} />
    </div>
    <ConversationMessageReplies message={message} conversation={conversation} highlightedMessage={highlightedMessage} />
  </div>;
});
