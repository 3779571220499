import { IPreviewPhonenumberMonthlyCost } from 'atlas-shared';
import { IPhonenumberMonthlyCostStore, PhonenumberMonthlyCostActions, TPhonenumberMonthlyCostActionTypes } from '@Store';

export const PhonenumberMonthlyCostReducer = (state: IPhonenumberMonthlyCostStore = {
  loaded: false,
  loading: false,
  phonenumber_monthly_costs: [],
  dict: {}
}, action: TPhonenumberMonthlyCostActionTypes): IPhonenumberMonthlyCostStore => {
  switch (action.type) {
  case PhonenumberMonthlyCostActions.LOADING:
    return { ...state, loading: true };
  case PhonenumberMonthlyCostActions.SET_STATE:
    const dict: IPhonenumberMonthlyCostStore['dict'] = {};

    action.payload.forEach((phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => dict[phonenumber_monthly_cost.id] = phonenumber_monthly_cost);

    return { ...state, loaded: true, loading: false, phonenumber_monthly_costs: action.payload, dict };
  case PhonenumberMonthlyCostActions.ADD_STATE:
    return { ...state, phonenumber_monthly_costs: [...state.phonenumber_monthly_costs, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case PhonenumberMonthlyCostActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, phonenumber_monthly_costs: state.phonenumber_monthly_costs.filter((item: IPreviewPhonenumberMonthlyCost) => item.id !== action.payload), dict };
  }
  case PhonenumberMonthlyCostActions.UPDATE_STATE:
    const phonenumber_monthly_costs = [...state.phonenumber_monthly_costs];
    const index = state.phonenumber_monthly_costs.findIndex((phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => phonenumber_monthly_cost.id === action.payload.id);

    if (!~index)
      phonenumber_monthly_costs.push(action.payload);
    else
      phonenumber_monthly_costs[index] = action.payload;

    return { ...state, phonenumber_monthly_costs, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
