import React from 'react';
import { ISvgIconProps } from './interface';

export const SvgIcon = (
  { size = 14, stroke = 1, strokeColor = 'currentColor', className = 'icon', style = {}, children, circle, title, viewBox = '0 0 24 24', width, height }: ISvgIconProps
) => {
  const Icon = <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || size}
    height={height || size}
    viewBox={viewBox}
    fill="none"
    stroke={strokeColor}
    strokeWidth={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    style={style}
  >
    {children}
  </svg>;

  if (circle) {
    return <div className={className} title={title} style={{ padding: Math.floor(size / 2), borderRadius: '50%', width: size, height: size, boxSizing: 'initial', ...circle.style }}>{Icon}</div>;
  }

  return Icon;
};
