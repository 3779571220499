import { IPreviewPause } from 'atlas-shared';
import { IPauseStore, PauseActions, TPauseActionTypes } from '@Store';

export const PauseReducer = (state: IPauseStore = {
  loaded: false,
  loading: false,
  pauses: [],
  dict: {}
}, action: TPauseActionTypes): IPauseStore => {
  switch (action.type) {
  case PauseActions.LOADING:
    return { ...state, loading: true };
  case PauseActions.SET_STATE:
    const dict: IPauseStore['dict'] = {};

    action.payload.forEach((pause: IPreviewPause) => dict[pause.id] = pause);

    return { ...state, loaded: true, loading: false, pauses: action.payload, dict };
  case PauseActions.ADD_STATE:
    return { ...state, pauses: [...state.pauses, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case PauseActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, pauses: state.pauses.filter((item: IPreviewPause) => item.id !== action.payload), dict };
  }
  case PauseActions.UPDATE_STATE:
    const pauses = [...state.pauses];
    const index = state.pauses.findIndex((pause: IPreviewPause) => pause.id === action.payload.id);

    if (!~index)
      pauses.push(action.payload);
    else
      pauses[index] = action.payload;

    return { ...state, pauses, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
