import { IPreviewClient } from 'atlas-shared';
import { IClientStore, ClientActions, TClientActionTypes } from '@Store';

export const ClientReducer = (state: IClientStore = {
  loaded: false,
  loading: false,
  clients: [],
  dict: {}
}, action: TClientActionTypes): IClientStore => {
  switch (action.type) {
  case ClientActions.LOADING:
    return { ...state, loading: true };
  case ClientActions.SET_STATE:
    const dict: IClientStore['dict'] = {};

    action.payload.forEach((client: IPreviewClient) => dict[client.id] = client);

    return { ...state, loaded: true, loading: false, clients: action.payload, dict };
  case ClientActions.ADD_STATE:
    return { ...state, clients: [...state.clients, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case ClientActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, clients: state.clients.filter((item: IPreviewClient) => item.id !== action.payload), dict };
  }
  case ClientActions.UPDATE_STATE:
    const clients = [...state.clients];
    const index = state.clients.findIndex((client: IPreviewClient) => client.id === action.payload.id);

    if (!~index)
      clients.push(action.payload);
    else
      clients[index] = action.payload;

    return { ...state, clients, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
