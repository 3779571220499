import { IPreviewStatsReportPreviewWidget } from 'atlas-shared';

export enum StatsReportPreviewWidgetActions {
  SET_STATE = 'STATS_REPORT_PREVIEW_WIDGET_SET_STATE',
  ADD_STATE = 'STATS_REPORT_PREVIEW_WIDGET_ADD_STATE',
  REMOVE_STATE = 'STATS_REPORT_PREVIEW_WIDGET_REMOVE_STATE',
  GET_STATE = 'STATS_REPORT_PREVIEW_WIDGET_GET_STATE',
  UPDATE_STATE = 'STATS_REPORT_PREVIEW_WIDGET_UPDATE_STATE',
  LOADING = 'STATS_REPORT_PREVIEW_WIDGET_LOADING',
}

export interface IStatsReportPreviewWidgetLoading {
  type: StatsReportPreviewWidgetActions.LOADING;
}

export interface IStatsReportPreviewWidgetSetAction {
  type: StatsReportPreviewWidgetActions.SET_STATE;
  payload: Array<IPreviewStatsReportPreviewWidget>;
}

export interface IStatsReportPreviewWidgetUpdateAction {
  type: StatsReportPreviewWidgetActions.UPDATE_STATE;
  payload: IPreviewStatsReportPreviewWidget;
}

export interface IStatsReportPreviewWidgetAddAction {
  type: StatsReportPreviewWidgetActions.ADD_STATE;
  payload: IPreviewStatsReportPreviewWidget;
}

export interface IStatsReportPreviewWidgetRemoveAction {
  type: StatsReportPreviewWidgetActions.REMOVE_STATE;
  payload: IPreviewStatsReportPreviewWidget['id'];
}

export interface IStatsReportPreviewWidgetStore {
  loaded: boolean;
  loading: boolean;
  stats_report_preview_widgets: Array<IPreviewStatsReportPreviewWidget>;
  dict: Record<IPreviewStatsReportPreviewWidget['id'], IPreviewStatsReportPreviewWidget>;
}

export type TStatsReportPreviewWidgetActionTypes = IStatsReportPreviewWidgetSetAction | IStatsReportPreviewWidgetUpdateAction | IStatsReportPreviewWidgetAddAction | IStatsReportPreviewWidgetRemoveAction | IStatsReportPreviewWidgetLoading;
