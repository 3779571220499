import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateSignature, ISharedUpdateSignature, ISharedSignature, IPreviewOrganization, EnumOptions, CannedAnswerChannelEnum, SignatureTypeEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface ISignatureFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const SignatureFormOptions = (signature: ISharedCreateSignature | ISharedSignature, props: ISignatureFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Signature, {})), 'organization_id', false), 'organization'),
  channel: EnumOptions('CANNED_ANSWER_CHANNEL', CannedAnswerChannelEnum, t),
  type: EnumOptions('SIGNATURE_TYPE', SignatureTypeEnum, t),
});

export const SignatureComponents: IForm['components'] = {};
export const SignatureAdditionalParams: (auth: IAuth, props: ISignatureFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISignatureFormOptionsProps) => ({
});

export const SignatureEditForm = (signature: ISharedSignature, props: ISignatureFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `signature_edit_${signature.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === signature.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'channel' },
    { ref: 'type' },
    { ref: 'body' },
    { ref: 'global' },
    { ref: 'description' }
  ]
});

export const SignatureAddForm = (signature: ISharedCreateSignature, props: ISignatureFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'signature_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'channel' },
    { ref: 'type' },
    { ref: 'body' },
    { ref: 'global' },
    { ref: 'description' }
  ]
});

export const SignatureAddInitialValues: ISharedCreateSignature = {} as ISharedCreateSignature;

export const SignatureEditInitialValues = (signature: ISharedSignature): ISharedUpdateSignature => ({
  title: signature.title,
  channel: signature.channel,
  type: signature.type,
  body: signature.body,
  global: signature.global,
  description: signature.description
});

export const onSignatureFormSaved = (t: TFunction, action: 'edit' | 'add', signature: ISharedSignature, navigate: NavigateFunction): void => {
  navigate(onFormSaved('signatures'));
};
