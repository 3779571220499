import { IPreviewVoiceTrunk } from 'atlas-shared';
import { IVoiceTrunkStore, VoiceTrunkActions, TVoiceTrunkActionTypes } from '@Store';

export const VoiceTrunkReducer = (state: IVoiceTrunkStore = {
  loaded: false,
  loading: false,
  voice_trunks: [],
  dict: {}
}, action: TVoiceTrunkActionTypes): IVoiceTrunkStore => {
  switch (action.type) {
  case VoiceTrunkActions.LOADING:
    return { ...state, loading: true };
  case VoiceTrunkActions.SET_STATE:
    const dict: IVoiceTrunkStore['dict'] = {};

    action.payload.forEach((voice_trunk: IPreviewVoiceTrunk) => dict[voice_trunk.id] = voice_trunk);

    return { ...state, loaded: true, loading: false, voice_trunks: action.payload, dict };
  case VoiceTrunkActions.ADD_STATE:
    return { ...state, voice_trunks: [...state.voice_trunks, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceTrunkActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_trunks: state.voice_trunks.filter((item: IPreviewVoiceTrunk) => item.id !== action.payload), dict };
  }
  case VoiceTrunkActions.UPDATE_STATE:
    const voice_trunks = [...state.voice_trunks];
    const index = state.voice_trunks.findIndex((voice_trunk: IPreviewVoiceTrunk) => voice_trunk.id === action.payload.id);

    if (!~index)
      voice_trunks.push(action.payload);
    else
      voice_trunks[index] = action.payload;

    return { ...state, voice_trunks, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
