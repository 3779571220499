import { IPreviewQueue, IPreviewUser, IPreviewUserStatus } from 'atlas-shared';
import { getFullName } from '@Utils';
import { TGlobalCallsInterface } from '@Store';

export type TVoiceSuggestionOnSelect = (calls: Array<Partial<TGlobalCallsInterface> & {numbers: TGlobalCallsInterface['numbers']; title: TGlobalCallsInterface['title']}>, clicked: boolean) => void;

export type IAgentCallSuggestion = {
  user_status: IPreviewUserStatus;
  user: IPreviewUser;
};

export type TQueueCallSuggestion = IPreviewQueue & {selectable: boolean; users?: Array<IAgentCallSuggestion>};

export const userSearchString = (user: IPreviewUser) => {
  return `u-${user.id} ${getFullName(user)}`.toLowerCase();
};

export const queueSearchString = (queue: IPreviewQueue) => {
  return `q-${queue.id} ${queue.title}`.toLowerCase();
};
