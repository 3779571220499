import React, { useCallback, useState } from 'react';
import { Undefinable } from 'atlas-shared';
import * as Joi from 'joi';
import { useSchema } from '@Hooks';
import { NavigateFunction } from 'react-router';
import { JSONForm } from '@Modules/atlas-form-core';

export interface IWithEditProps<T>{
  id: number;
  saving: boolean;
  schemaLoading: boolean;
  formSchema: Joi.Schema;
  save: (cb: ()=> Promise<void>)=> void;
  value: T | undefined;
  setValue: (values: T | undefined) => void;
  title?: string;
  hasAdvanced?: boolean;
  onSaved?: (res: Record<string, any>, navigate: NavigateFunction) => void;
  onCancel?: () => void;
  onChange?: (values: Record<string, any>, changedValue: any, form?: JSONForm) => void;
}

export function withEdit <T>(Component: React.JSXElementConstructor<any>, schemaFetcher: () => Promise<any>) {
  return function (props: any) {
    const [value, setValue] = useState<Undefinable<T>>();
    const [formSchema, schemaLoading] = useSchema(schemaFetcher);
    const [saving, setSaving] = useState<boolean>(false);

    const save = useCallback(async (cb: ()=> Promise<void>) => {
      setSaving(true);
      try {
        await cb();
      } catch (e) {
        console.error(e);
      } finally {
        setSaving(false);
      }
    }, [setSaving]);

    return <div className='entity-form-edit'>
      <Component value={value} setValue={setValue} save={save} saving={saving} formSchema={formSchema} schemaLoading={schemaLoading} {...props} />
    </div>;
  };
}
