import { RestRequest } from '@Utils';
import { IPreviewSignature, ISharedSignature, ISharedCreateSignature, ISharedPatchSignature } from 'atlas-shared';

export const fetchSignatures = async (): Promise<Array<IPreviewSignature>> => {
  return RestRequest.get<Array<IPreviewSignature>>('signature', { socket_id: global.socketId });
};

export const fetchSignature = async (id: IPreviewSignature['id']): Promise<ISharedSignature> => {
  return RestRequest.get<ISharedSignature>(`signature/${id}`, { socket_id: global.socketId });
};

export const createSignature = (payload: ISharedCreateSignature) => {
  return RestRequest.post<any, ISharedSignature>('signature', payload, { socket_id: global.socketId });
};

export const patchSignature = (id: IPreviewSignature['id'], payload: ISharedPatchSignature) => {
  return RestRequest.patch<any, ISharedSignature>(`signature/${id}`, payload, { socket_id: global.socketId });
};

export const deleteSignature = (id: IPreviewSignature['id']) => {
  return RestRequest.delete(`signature/${id}`, { socket_id: global.socketId });
};

export const getSignatureUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('signature/update/schema');
};

export const getSignatureCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('signature/create/schema');
};

export const getSignaturePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('signature/patch/schema');
};
