import { IPreviewVoiceVoicemail } from 'atlas-shared';
import { IVoiceVoicemailStore, VoiceVoicemailActions, TVoiceVoicemailActionTypes } from '@Store';

export const VoiceVoicemailReducer = (state: IVoiceVoicemailStore = {
  loaded: false,
  loading: false,
  voice_voicemails: [],
  dict: {}
}, action: TVoiceVoicemailActionTypes): IVoiceVoicemailStore => {
  switch (action.type) {
  case VoiceVoicemailActions.LOADING:
    return { ...state, loading: true };
  case VoiceVoicemailActions.SET_STATE:
    const dict: IVoiceVoicemailStore['dict'] = {};

    action.payload.forEach((voice_voicemail: IPreviewVoiceVoicemail) => dict[voice_voicemail.id] = voice_voicemail);

    return { ...state, loaded: true, loading: false, voice_voicemails: action.payload, dict };
  case VoiceVoicemailActions.ADD_STATE:
    return { ...state, voice_voicemails: [...state.voice_voicemails, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceVoicemailActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_voicemails: state.voice_voicemails.filter((item: IPreviewVoiceVoicemail) => item.id !== action.payload), dict };
  }
  case VoiceVoicemailActions.UPDATE_STATE:
    const voice_voicemails = [...state.voice_voicemails];
    const index = state.voice_voicemails.findIndex((voice_voicemail: IPreviewVoiceVoicemail) => voice_voicemail.id === action.payload.id);

    if (!~index)
      voice_voicemails.push(action.payload);
    else
      voice_voicemails[index] = action.payload;

    return { ...state, voice_voicemails, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
