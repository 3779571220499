import React from 'react';
import './header.notifications.scss';
import { HeaderNotificationsPopover } from './header.notifications.popover';

export const HeaderNotifications = React.memo(() => {

  return (
    <div className={'header-notifications'} >
      <HeaderNotificationsPopover />
    </div>
  );
});
