import { JourneyView } from '@Components';
import { IForm, IFormOptions } from 'atlas-form';
import {
  ISharedCreateJourneyDraft,
  ISharedJourneyDraft
} from 'atlas-shared';
import { TFunction } from 'i18next';
import { entitiesToOptions } from '@Utils';

import { 
  IJourneyDraftFormOptionsProps, 
  JourneyDraftAdditionalParams as JourneyDraftAdditionalParamsBase,
  JourneyDraftFormOptions as JourneyDraftFormOptionsBase
} from './abstract/journey_draft.form.abstract';
import { IAuth } from '@Store';

export * from './abstract/journey_draft.form.abstract';

export const JourneyDraftComponents: IForm['components'] = {
  payload: JourneyView
};

export const JourneyDraftFormOptions = (journey_draft: ISharedCreateJourneyDraft | ISharedJourneyDraft, props: IJourneyDraftFormOptionsProps, t: TFunction): IFormOptions => ({
  ...JourneyDraftFormOptionsBase(journey_draft, props, t),
  journey_id: (it: ISharedJourneyDraft) => entitiesToOptions(props.journeies.filter(mj => mj.organization_id === (it.organization_id || journey_draft.organization_id))),

});

export const JourneyDraftAdditionalParams: (auth: IAuth, props: IJourneyDraftFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IJourneyDraftFormOptionsProps) => ({
  ...JourneyDraftAdditionalParamsBase(auth, props),
  payload: { label: 'JOURNEY' }
});
