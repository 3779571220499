import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IJourneyStore, IOrganizationStore, IUserStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';
import { Tooltip } from 'antd';

export * from './abstract/voice_route.list.column.abstract';
import { VoiceRouteListColumns as VoiceRouteListColumnsBase } from './abstract/voice_route.list.column.abstract';
import {
  CrudEnum,
  IPreviewVoiceRoute, PermissionEnum,
  TVoiceRouteSortableListCol
} from 'atlas-shared/dist';
import { Icon } from '@Components';
import { TrashIcon } from '@Assets';
import { terminateVoiceRoute } from '@Api';
import { humanReadableDateTime } from '@Utils/time';
import { getFullName } from '@Utils/user';
import { AlertError } from '@Utils/popup';

export const VoiceRouteListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, journeies: IJourneyStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceRoute) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceRoute, TVoiceRouteSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceRoute, TVoiceRouteSortableListCol>['sorter']): ColumnsType<IPreviewVoiceRoute> => {
  const columns = VoiceRouteListColumnsBase(t, navigate, showDeleted, organizations, journeies, users, abilities, auth, [
    (row: IPreviewVoiceRoute) => !row.terminated_at && abilities.can(null, CrudEnum.Delete, PermissionEnum.VoiceRoute, row) ? <Icon icon={TrashIcon} tooltip={{ title: t('TERMINATE') }} iconProps={{ size: 14 }} onClick={() => terminateVoiceRoute(row.id).catch(err => AlertError(t, { content: err.toString() || 'TERMINATE_WENT_WRONG' }))} /> : <></>,
    ...buttons
  ], filters, sorter);

  columns[4] = {
    title: t('TERMINATION'),
    dataIndex: 'terminated_at',
    key: 'terminated_at',
    render: (terminated_at: IPreviewVoiceRoute['terminated_at'], voice_route: IPreviewVoiceRoute) => <div className='date-column'>{voice_route.terminates_at ? <Tooltip title={<ul style={{ listStyleType: 'none', padding: 0 }}>
      {voice_route.terminated_at && <li>{t('TERMINATED_AT')}: {humanReadableDateTime(new Date(voice_route.terminated_at))}</li>}
      {voice_route.terminated_by && <li>{t('TERMINATED_BY')}: {voice_route.terminated_by && users.dict?.[voice_route.terminated_by] ? getFullName(users.dict?.[voice_route.terminated_by]) : t('USER_NOT_FOUND', { replace: { user_id: voice_route.terminated_by } })}</li>}
      {voice_route.terminates_at && <li>{t('TERMINATES_AT')}: {humanReadableDateTime(new Date(voice_route.terminates_at))}</li>}
    </ul>}>{humanReadableDateTime(new Date(voice_route.terminates_at))}</Tooltip> : ''}</div>,
    className: 'table-cell-terminated_at',
  };

  return columns;
};
