import React, { useMemo } from 'react';
import './recharts.scss';
import { Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { getStatsWidgetChartMetricStyles, getStatsWidgetMetricData, getStatsWidgetMetricDataFormatted } from '@Utils';
import { WidgetChartLegend } from '../../widget.chart.legend';
import { WidgetChartTooltip } from '../../widget.chart.tooltip';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import { WIDGET_COLOR_PALETTE } from '@Components/stats/widget-types/widget.color.palette';
import { StatsWidgetMetricFormatEnum } from 'atlas-shared/dist';

export const WidgetTypeLine = withStatsWidget(({ data: _data, stats_widget, t: _t, metric_data_dicts, stats_custom_metrics, innerHeight, innerWidth, organization }: IWithStatsWidgetProps) => {

  if (!_data?.r[0])
    return <></>;

  const legendPlacement = useMemo(() => stats_widget.settings.ui.legend?.placement || 'bottom', [stats_widget.settings.ui.legend?.placement]);
  const legendVertical = useMemo(() => ['left', 'right'].includes(legendPlacement), [legendPlacement]);
  const has_trend = !!stats_widget.settings.trend;
  const group_length = stats_widget.settings.group_by.length;
  const data = useMemo(() => {
    return (_data.g === 'split' ? _data.r[0].c!.r : _data.r).map(row => {
      const d = {
        group: stats_widget.settings.group_by.map((m, i) => getStatsWidgetMetricData(_t, m, row.d[i], metric_data_dicts, organization)).join(' ')
      };

      stats_widget.settings.metrics.forEach((m, i) => {
        d[m.value] = +row.d[group_length + i];

        if (has_trend && row.t)
          d[m.value + '_trend'] = +row.t[i];

        if (has_trend && row.tc)
          d[m.value + '_trend_comparison'] = +row.tc?.[i];
      });

      return d;
    });
  }, [_data, stats_widget]);
  const YMetric = useMemo(() => stats_widget.settings.metrics.find(m => m.format) || stats_widget.settings.metrics[0], [stats_widget.settings.metrics]);
  const YMetricChars = useMemo(() => {
    if (YMetric) {
      switch (YMetric.format) {
      case StatsWidgetMetricFormatEnum.D_SLASH_M:
        return 3;
      case StatsWidgetMetricFormatEnum.HH_mm:
      case StatsWidgetMetricFormatEnum.mm_ss:
      case StatsWidgetMetricFormatEnum.VALUE_PERCENT:
      case StatsWidgetMetricFormatEnum.VALUE_DECIMAL:
        return 5;
      case StatsWidgetMetricFormatEnum.VALUE_PCS:
        return 5;
      case StatsWidgetMetricFormatEnum.HH_mm_ss:
      case StatsWidgetMetricFormatEnum.YYYYMMDD:
        return 8;
      case StatsWidgetMetricFormatEnum.YYYY_MM_DD:
        return 10;
      case StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm:
        return 16;
      case StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm_ss:
        return 19;
      }
    }

    return 3;
  }, [YMetric]);
  const YAxisWidth = useMemo(() => YMetricChars * 8, [YMetricChars]);

  const ln = data.length;
  const chunkPercent = 100 / ln;
  const chunkPercentHalf = chunkPercent / 3;
  const stops = new Array(ln).fill(0).map((_, i) => (i * chunkPercent) + (chunkPercentHalf / 3));

  return <div className={`widget-type-${stats_widget.type}`}>
    <AreaChart
      width={innerWidth}
      height={innerHeight}
      data={data}
      margin={{
        top: legendPlacement === 'top' ? 10 : 0,
        right: legendPlacement === 'right' ? 15 : 0,
        left: legendPlacement === 'left' ? 15 : 0,
        bottom: legendPlacement === 'bottom' ? 5 : 0,
      }}
      className={`legend-${legendVertical ? 'vertical' : 'horizontal'} legend-${legendPlacement}`}
    >
      <CartesianGrid strokeDasharray="1 3" horizontal={false} stroke={'var(--COLOR-000533)'} opacity={.25} />
      <XAxis
        dataKey="group"
        fontSize={11}
        strokeWidth={0}
        tick={{ style: { transform: 'translateY(2px)' }, fill: 'var(--COLOR-000533)' }}
        opacity={.7}
        mirror={true}
      />
      <YAxis
        tickFormatter={value => YMetric ? getStatsWidgetMetricDataFormatted(_t, YMetric, value, organization) : value}
        width={YAxisWidth}
        strokeWidth={0}
        //tick={{ style: { transform: 'translateX(4px)' }, fill: 'var(--COLOR-000533)' }}
        tick={{ style: { transform: 'translateX(-4px)' }, fill: 'var(--COLOR-000533)' }}
        fontSize={11}
        opacity={.5}
        mirror={true}
        padding={{ top: 16 }}
      />
      <Tooltip animationDuration={0} content={<WidgetChartTooltip t={_t} organization={organization} metrics={stats_widget.settings.metrics} stats_custom_metrics={stats_custom_metrics} />} />
      {!!stats_widget.settings.ui.legend && <Legend
        content={<WidgetChartLegend stats_widget={stats_widget} data={data} t={_t} metrics={stats_widget.settings.metrics} stats_custom_metrics={stats_custom_metrics}/>}
        align={legendVertical ? stats_widget.settings.ui.legend!.placement as any : undefined}
        layout={legendVertical ? 'vertical' : 'horizontal'}
        verticalAlign={legendVertical ? 'middle' : legendPlacement as any}
      />}

      {stats_widget.settings.metrics.map((metric, i) => {
        const strokeId = `line-${metric.value}`;
        const fillId = `fill-${metric.value}`;
        const styles = stops.map((p, si) => getStatsWidgetChartMetricStyles(_t, data[si][metric.value], data[si][`${metric.value}_trend`] || 0, data[si][`${metric.value}_trend_comparison`], metric, i, stats_widget, stats_custom_metrics.stats_custom_metrics));
        const styles_trend = has_trend ? stops.map((p, si) => getStatsWidgetChartMetricStyles(_t, data[si][`${metric.value}_trend`] || 0, 0, undefined, metric, i, stats_widget, stats_custom_metrics.stats_custom_metrics)) : [];
        const hasThresholds = !!metric.thresholds.length;

        return <React.Fragment key={metric.value}>
          {hasThresholds && <defs>
            <linearGradient id={strokeId} x1="0%" y1="0%" x2="100%" y2="0%">
              {stops.map((p, si) => <React.Fragment key={si}>
                <stop offset={`${p}%`} stopColor={styles[si].borderColor}/>
                <stop offset={`${p + chunkPercentHalf}%`} stopColor={styles[si].borderColor}/>
                <stop offset={`${p + chunkPercentHalf + chunkPercentHalf}%`} stopColor={styles[si].borderColor}/>
              </React.Fragment>)}
            </linearGradient>
            <linearGradient id={fillId} x1="0%" y1="0%" x2="100%" y2="0%">
              {stops.map((p, si) => <React.Fragment key={si}>
                <stop offset={`${p}%`} stopColor={styles[si].backgroundColor}/>
                <stop offset={`${p + chunkPercentHalf}%`} stopColor={styles[si].backgroundColor}/>
                <stop offset={`${p + chunkPercentHalf + chunkPercentHalf}%`} stopColor={styles[si].backgroundColor}/>
              </React.Fragment>)}
            </linearGradient>
          </defs>}
          <Area
            type="linear"
            label={stats_widget.settings.ui.display_values && <CustomizedLabel t={_t} organization={organization} metric={metric} styles={styles} innerWidth={innerWidth} />}
            activeDot={<ActiveDot fill={metric.border?.color || WIDGET_COLOR_PALETTE[i]} />}
            fillOpacity={.7}
            isAnimationActive={false}
            dataKey={metric.value}
            fill={hasThresholds ? `url(#${fillId})` : (metric.bg_color || 'transparent')}
            stroke={hasThresholds ? `url(#${strokeId})` : (metric.border?.color || WIDGET_COLOR_PALETTE[i])}
            strokeWidth={metric.border?.width || 2}
          />
          {has_trend && <>
            {hasThresholds && <defs>
              <linearGradient id={strokeId + '_trend'} x1="0%" y1="0%" x2="100%" y2="0%">
                {stops.map((p, si) => <React.Fragment key={si}>
                  <stop offset={`${p}%`} stopColor={styles_trend[si].borderColor}/>
                  <stop offset={`${p + chunkPercentHalf}%`} stopColor={styles_trend[si].borderColor}/>
                  <stop offset={`${p + chunkPercentHalf + chunkPercentHalf}%`} stopColor={styles_trend[si].borderColor}/>
                </React.Fragment>)}
              </linearGradient>
              <linearGradient id={fillId + '_trend'} x1="0%" y1="0%" x2="100%" y2="0%">
                {stops.map((p, si) => <React.Fragment key={si}>
                  <stop offset={`${p}%`} stopColor={styles_trend[si].backgroundColor}/>
                  <stop offset={`${p + chunkPercentHalf}%`} stopColor={styles_trend[si].backgroundColor}/>
                  <stop offset={`${p + chunkPercentHalf + chunkPercentHalf}%`} stopColor={styles_trend[si].backgroundColor}/>
                </React.Fragment>)}
              </linearGradient>
            </defs>}
            <Area
              type="linear"
              label={stats_widget.settings.ui.display_values && <CustomizedLabel t={_t} metric={metric} styles={styles_trend} trend={true} innerWidth={innerWidth} />}
              activeDot={<ActiveDot fill={metric.border?.color || WIDGET_COLOR_PALETTE[i]} />}
              fillOpacity={.4}
              opacity={.25}
              isAnimationActive={false}
              dataKey={metric.value + '_trend'}
              fill={hasThresholds ? `url(#${fillId + '_trend'})` : (metric.bg_color || 'transparent')}
              stroke={hasThresholds ? `url(#${strokeId + '_trend'})` : (metric.border?.color || WIDGET_COLOR_PALETTE[i])}
              strokeWidth={metric.border?.width || 2}
            />
          </>}
        </React.Fragment>;
      })}
    </AreaChart>
  </div>;
});

const ActiveDot = (props) => <circle {...props} r={4} strokeWidth={2} stroke={'var(--BG-FFFFFF)'} />;
const CustomizedLabel = (props) => {
  const { x, y, t, innerWidth, value, index, styles, trend, metric, organization } = props;
  const display_value = getStatsWidgetMetricDataFormatted(t, metric, value, organization);
  const charLength = ((display_value + '').length * 6) + 6;
  const hasBoxColor = !!styles[index].boxColor;
  const offsetX = index ? (x > (innerWidth - 10) ? (charLength / -2) - 1 : 0) : (charLength / 2) + 1;

  if (!value)
    return <></>;

  return (
    <g transform={`translate(${x},${y - (hasBoxColor ? 3 : 1)})`}>
      {hasBoxColor && <rect width={charLength} height={15} x={0 + offsetX - (charLength / 2)} y={0 - 13} fill={styles[index].boxColor} rx={3} opacity={trend ? .5 : .85} />}
      <text x={0} y={0} dy={-2} dx={offsetX + ((charLength - 6) / 2)} textAnchor="end" fill={styles[index].color} fontSize={10} opacity={trend ? .6 : .9}>
        {display_value}
      </text>
    </g>
  );
};
