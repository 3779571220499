import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchJourneies, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useJourneies = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const journeies = useSelector((store: IReducerStore) => store.journeies);

  useEffect(() => {
    if (!initiated && autoload && !journeies.loaded && !journeies.loading) {
      initiated = true;
      dispatch(actionFetchJourneies());
    }
  }, [autoload, journeies.loaded, journeies.loading, dispatch]);

  return journeies;
};
