import { RestRequest, download } from '@Utils';
import { IPreviewListFile, ISharedListFile, ISharedCreateListFile, ISharedPatchListFile } from 'atlas-shared';

export const fetchListFiles = async (): Promise<Array<IPreviewListFile>> => {
  return RestRequest.get<Array<IPreviewListFile>>('list_file', { socket_id: global.socketId });
};

export const fetchListFile = async (id: IPreviewListFile['id']): Promise<ISharedListFile> => {
  return RestRequest.get<ISharedListFile>(`list_file/${id}`, { socket_id: global.socketId });
};

export const createListFile = (payload: ISharedCreateListFile) => {
  return RestRequest.post<any, ISharedListFile>('list_file', payload, { socket_id: global.socketId });
};

export const patchListFile = (id: IPreviewListFile['id'], payload: ISharedPatchListFile) => {
  return RestRequest.patch<any, ISharedListFile>(`list_file/${id}`, payload, { socket_id: global.socketId });
};

export const deleteListFile = (id: IPreviewListFile['id']) => {
  return RestRequest.delete(`list_file/${id}`, { socket_id: global.socketId });
};

export const getListFileUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('list_file/update/schema');
};

export const getListFileCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('list_file/create/schema');
};

export const getListFilePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('list_file/patch/schema');
};

export const downloadListFileFilename = (id: IPreviewListFile['id'], filename: string, onError?: (error) => void) => {
  download(`list_file/${id}/filename`, filename, onError);
};
