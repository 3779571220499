import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth, useStatsReports, useStatsReportWidgets, useStatsWidgets, useTheme } from '@Hooks';
import {
  IPreviewStatsReport,
  IPreviewStatsReportWidget, ISharedStatsReportWidget
} from 'atlas-shared/dist';
import {
  setStatsReportInactive,
  statsWidgetLoadWidgets
} from '@Api';
import { IAuth, IStatsReportStore, IStatsReportWidgetStore } from '@Store';
import { Icon, IconText } from '@Components';
import { AlertError, csv, queryParam, screenshot, stringToAlias } from '@Utils';
import { useDispatch } from 'react-redux';
import { FileTextIcon, ImageIcon, PdfIcon, PlusIcon } from '@Assets';
import { useTranslation } from 'react-i18next';
import { StatsWidgetFormSider } from '@Components/stats/stats_widget.form';

export interface IWithStatsReportProps {
  auth: IAuth;
  stats_reports: IStatsReportStore;
  stats_report_widgets: IStatsReportWidgetStore;
  report: IPreviewStatsReport;
  widgets: Array<IPreviewStatsReportWidget>;
  setPosition: (stats_report_widget: IPreviewStatsReportWidget, position: IPreviewStatsReportWidget['settings']['position']) => void;
  movePosition: (index: number, up: boolean) => void;
  form: React.ReactElement;
  addBtn: React.ReactElement;
  widgetForm: number;
  setWidgetForm: Dispatch<SetStateAction<number>>;
  setWidgetClone: Dispatch<SetStateAction<number>>;
  screenshotBtn: (type: 'pdf' | 'png' | 'csv') => React.ReactElement;
  screenshotWidgetBtn: (type: 'pdf' | 'png' | 'csv', stats_report_widget_id: ISharedStatsReportWidget['id'], filename: string) => React.ReactElement;
}

export interface IStatsReportChildProps {
  report_id: IPreviewStatsReport['id'];
}

export const withStatsReport = (Component: React.JSXElementConstructor<any>, iconSize: number = 21) => {

  return (props: IStatsReportChildProps) => {
    const { report_id } = props;
    const dispatch = useDispatch();
    const auth = useAuth();
    const { t } = useTranslation();
    const stats_reports = useStatsReports();
    const stats_widgets = useStatsWidgets();
    const stats_report_widgets = useStatsReportWidgets();
    const widgetId = queryParam('widget_id');
    const [report, setReport] = useState<IPreviewStatsReport>();
    const [widgets, setWidgets] = useState<Array<IPreviewStatsReportWidget>>([]);
    const [widgetForm, setWidgetForm] = useState<number>(0);
    const [widgetClone, setWidgetClone] = useState<number>(0);
    const theme = useTheme();

    const form = useMemo(() => {
      return (!!widgetForm || !!widgetClone) && report && <StatsWidgetFormSider
        t={t}
        auth={auth}
        report={report}
        setWidgetForm={setWidgetForm}
        setWidgetClone={setWidgetClone}
        stats_widgets={stats_widgets}
        widgets={widgets}
        widgetForm={widgetForm}
        widgetClone={widgetClone}
      />;
    }, [report, widgets, widgetForm, widgetClone]);
    const addBtn = useMemo(() => !widgetForm && report?.user_id === auth.user.id && <IconText text={t('ADD_WIDGET')} icon={PlusIcon} onClick={() => setWidgetForm(-1)} className='add' tooltip={{ title: t('ADD_WIDGET'), placement: 'bottom' }} iconProps={{ size: iconSize }} />, [widgetForm, report]);
    const screenshotBtn = useCallback((type: 'png' | 'pdf') => {
      if (!report)
        return;

      const isPdf = type === 'pdf';

      return <Icon
        icon={!isPdf ? ImageIcon : PdfIcon}
        tooltip={{
          title: `${t('DOWNLOAD')} ${t(isPdf ? 'PDF' : 'IMAGE')}`,
          placement: 'leftBottom'
        }}
        iconProps={{ size: iconSize }}
        onClick={() => screenshot(document.URL, `${stringToAlias(report.title)}.${type}`, report.is_dashboard, theme, null, err => AlertError(t, { title: 'Something went wrong while generating screenshot!' }))
        } />;
    }, [report, theme]);
    const screenshotWidgetBtn = useCallback((type: 'csv' | 'png' | 'pdf', stats_report_widget_id: ISharedStatsReportWidget['id'], filename: string) => {
      if (!report)
        return;

      const isPng = type === 'png';

      return {
        key: type,
        text: `${t('DOWNLOAD')} ${t(isPng ? 'IMAGE' : type.toUpperCase())}`,
        icon: isPng ? ImageIcon : type === 'pdf' ? PdfIcon : FileTextIcon,
        onClick: () => (type === 'csv' ? csv : screenshot)(document.URL, `${filename}.${type}`, report.is_dashboard, theme, stats_report_widget_id, err => AlertError(t, { title: `Something went wrong while generating ${type === 'png' ? 'screenshot' : type}!` }))
      };
    }, [report, theme]);

    useEffect(() => {
      if (!report)
        setReport(stats_reports.dict[report_id]);
    }, [stats_reports]);

    useEffect(() => {
      if (report?.is_dashboard) {
        statsWidgetLoadWidgets(report.id, 0, dispatch, false);
        return () => {
          setStatsReportInactive();
        };
      }
    }, [report]);

    useEffect(() => {
      if (stats_widgets.loaded && stats_report_widgets.loaded) {
        let w = stats_report_widgets.stats_report_widgets
          .filter(stats_report_widget => stats_report_widget.stats_report_id === report_id && (!widgetId || +widgetId === stats_report_widget.id));

        if (!report?.is_dashboard)
          w = w.sort((a, b) => (a.settings.position || 0) - (b.settings.position || 0));

        setWidgets(w);
      }
    }, [stats_widgets, stats_report_widgets, report]);

    if (!report)
      return <div>report not found!</div>;

    return <Component
      {...props}
      auth={auth}
      stats_reports={stats_reports}
      stats_report_widgets={stats_report_widgets}
      report={report}
      widgets={widgets}
      form={form}
      addBtn={addBtn}
      widgetForm={widgetForm}
      widgetClone={widgetClone}
      setWidgetForm={setWidgetForm}
      setWidgetClone={setWidgetClone}
      screenshotBtn={screenshotBtn}
      screenshotWidgetBtn={screenshotWidgetBtn}
    />;
  };

};
