import { RecentlySearchedActions } from '@Store';
import { IPreviewRecentlySearched } from 'atlas-shared';
import { fetchRecentlySearcheds } from '@Api';

export const actionFetchRecentlySearcheds = () => {
  return async (dispatch) => {
    dispatch(actionLoadingRecentlySearcheds());
    const recently_searcheds = await fetchRecentlySearcheds();

    dispatch(actionSetRecentlySearcheds(recently_searcheds));
    return recently_searcheds;
  };
};

export const actionLoadingRecentlySearcheds = () => ({
  type: RecentlySearchedActions.LOADING,
  payload: []
});

export const actionSetRecentlySearcheds = (payload: Array<IPreviewRecentlySearched>) => ({
  type: RecentlySearchedActions.SET_STATE,
  payload: payload
});

export const actionAddRecentlySearched = (payload: IPreviewRecentlySearched) => ({
  type: RecentlySearchedActions.ADD_STATE,
  payload: payload
});

export const actionUpdateRecentlySearched = (payload: IPreviewRecentlySearched) => ({
  type: RecentlySearchedActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveRecentlySearched = (id: IPreviewRecentlySearched['id']) => ({
  type: RecentlySearchedActions.REMOVE_STATE,
  payload: id
});
