import { IPreviewVoiceAsteriskDowngrade } from 'atlas-shared';

export enum VoiceAsteriskDowngradeActions {
  SET_STATE = 'VOICE_ASTERISK_DOWNGRADE_SET_STATE',
  ADD_STATE = 'VOICE_ASTERISK_DOWNGRADE_ADD_STATE',
  REMOVE_STATE = 'VOICE_ASTERISK_DOWNGRADE_REMOVE_STATE',
  GET_STATE = 'VOICE_ASTERISK_DOWNGRADE_GET_STATE',
  UPDATE_STATE = 'VOICE_ASTERISK_DOWNGRADE_UPDATE_STATE',
  LOADING = 'VOICE_ASTERISK_DOWNGRADE_LOADING',
}

export interface IVoiceAsteriskDowngradeLoading {
  type: VoiceAsteriskDowngradeActions.LOADING;
}

export interface IVoiceAsteriskDowngradeSetAction {
  type: VoiceAsteriskDowngradeActions.SET_STATE;
  payload: Array<IPreviewVoiceAsteriskDowngrade>;
}

export interface IVoiceAsteriskDowngradeUpdateAction {
  type: VoiceAsteriskDowngradeActions.UPDATE_STATE;
  payload: IPreviewVoiceAsteriskDowngrade;
}

export interface IVoiceAsteriskDowngradeAddAction {
  type: VoiceAsteriskDowngradeActions.ADD_STATE;
  payload: IPreviewVoiceAsteriskDowngrade;
}

export interface IVoiceAsteriskDowngradeRemoveAction {
  type: VoiceAsteriskDowngradeActions.REMOVE_STATE;
  payload: IPreviewVoiceAsteriskDowngrade['id'];
}

export interface IVoiceAsteriskDowngradeStore {
  loaded: boolean;
  loading: boolean;
  voice_asterisk_downgrades: Array<IPreviewVoiceAsteriskDowngrade>;
  dict: Record<IPreviewVoiceAsteriskDowngrade['id'], IPreviewVoiceAsteriskDowngrade>;
}

export type TVoiceAsteriskDowngradeActionTypes = IVoiceAsteriskDowngradeSetAction | IVoiceAsteriskDowngradeUpdateAction | IVoiceAsteriskDowngradeAddAction | IVoiceAsteriskDowngradeRemoveAction | IVoiceAsteriskDowngradeLoading;
