import { VoiceAsteriskDowngradeActions } from '@Store';
import { IPreviewVoiceAsteriskDowngrade } from 'atlas-shared';
import { fetchVoiceAsteriskDowngrades } from '@Api';

export const actionFetchVoiceAsteriskDowngrades = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceAsteriskDowngrades());
    const voice_asterisk_downgrades = await fetchVoiceAsteriskDowngrades();

    dispatch(actionSetVoiceAsteriskDowngrades(voice_asterisk_downgrades));
    return voice_asterisk_downgrades;
  };
};

export const actionLoadingVoiceAsteriskDowngrades = () => ({
  type: VoiceAsteriskDowngradeActions.LOADING,
  payload: []
});

export const actionSetVoiceAsteriskDowngrades = (payload: Array<IPreviewVoiceAsteriskDowngrade>) => ({
  type: VoiceAsteriskDowngradeActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceAsteriskDowngrade = (payload: IPreviewVoiceAsteriskDowngrade) => ({
  type: VoiceAsteriskDowngradeActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceAsteriskDowngrade = (payload: IPreviewVoiceAsteriskDowngrade) => ({
  type: VoiceAsteriskDowngradeActions.UPDATE_STATE,
  payload: payload
});
