import {
  IJourneyPayloadNodesKeyProperty,
  JourneyActionEnum,
  IJourneyDraftPayloadNodesKeyProperty
} from 'atlas-shared';
import { useTranslation } from 'react-i18next';
import { actions, getActionPanelIcon } from '@Utils';
import React, { useMemo } from 'react';
import './styles.scss';
import { Collapse } from 'antd';
import { Button } from '@Components';

const { Panel } = Collapse;

interface IProps {
  reload:()=> void;
  reset:()=> void;
  onAdd: (node: IJourneyDraftPayloadNodesKeyProperty)=> void;
}
const JourneysSideList = (props: IProps) => {
  const { onAdd, reload, reset } = props;
  const { t } = useTranslation();

  const panels = useMemo<Array<{
    key: string;
    actions: Array<JourneyActionEnum>;
  }>>(() => ([
    {
      key: 'ENTRY',
      actions: [
        JourneyActionEnum.StartVoiceInbound,
        JourneyActionEnum.StartVoiceOutbound,
        JourneyActionEnum.StartMailInbound,
        JourneyActionEnum.StartMailOutbound,
        JourneyActionEnum.StartForm,
        JourneyActionEnum.When
      ]
    },
    {
      key: 'VOICE_MANAGEMENT',
      actions: [
        JourneyActionEnum.VoiceSetLanguage,
        JourneyActionEnum.VoiceVariableSet,
        JourneyActionEnum.VoiceQueue,
        JourneyActionEnum.VoiceInternalDial,
        JourneyActionEnum.VoiceAnswer,
        JourneyActionEnum.VoiceExternalDial,
        JourneyActionEnum.VoiceGetDigits,
        JourneyActionEnum.VoiceHangup,
        JourneyActionEnum.VoiceMenu,
        JourneyActionEnum.VoicePlayback,
        JourneyActionEnum.VoiceSayDigits,
        JourneyActionEnum.VoiceSayNumber,
        JourneyActionEnum.VoiceStartRecording,
        JourneyActionEnum.VoiceStopRecording,
        JourneyActionEnum.VoiceVoicemail
      ]
    },
    {
      key: 'ASSIGNMENT',
      actions: [
        JourneyActionEnum.VoiceQueue,
        JourneyActionEnum.VoiceInternalDial,
        JourneyActionEnum.Queue,
        JourneyActionEnum.Agent,
        JourneyActionEnum.UnAssign,
      ]
    },
    {
      key: 'CONVERSATION',
      actions: [
        JourneyActionEnum.EditConversation,
        JourneyActionEnum.CloseConversation,
        JourneyActionEnum.AddInternalNote,
        JourneyActionEnum.CloseTabs,
        JourneyActionEnum.ReplyLatestInboundMessage
      ]
    },
    {
      key: 'MAIL',
      actions: [
        JourneyActionEnum.SendMail,
        JourneyActionEnum.MailAutoResponse,
        JourneyActionEnum.ForwardMail,
        JourneyActionEnum.ReplyLatestInboundMessage
      ]
    },
    {
      key: 'CONTACT',
      actions: [
        JourneyActionEnum.FetchContactData,
        JourneyActionEnum.RuleContact
      ]
    },
    {
      key: 'GENERAL',
      actions: [
        JourneyActionEnum.RestRequest,
        JourneyActionEnum.FetchContactData,
        JourneyActionEnum.Rule,
        JourneyActionEnum.RuleContact,
        JourneyActionEnum.SetField,
        JourneyActionEnum.SendMail,
        JourneyActionEnum.Timeout,
        //JourneyActionEnum.NoOp,
        JourneyActionEnum.OperationalHours,
        JourneyActionEnum.When,
        JourneyActionEnum.Journey
      ]
    }
  ]), []);

  return (
    <div className={'side-div'}>
      <div className={'side-list'}>
        <Collapse defaultActiveKey='ENTRY'>
          {panels.map(panel => {
            return <Panel header={t(panel.key)} key={panel.key}>
              {
                panel.actions.map(actionName => {
                  const action = actions[actionName];

                  return <React.Fragment key={actionName}>{getActionPanelIcon(action, t, () => {
                    const ports = action.getPorts({ ports: {}, properties: { status_ports: [], conditions: [] } } as any);

                    onAdd({
                      id: `${actionName}_${Date.now()}`,
                      title: null,
                      description: null,
                      type: action.direction,
                      position: { x: 10, y: 10 },
                      action: actionName,
                      ports,
                      properties: action.initialValues
                    } as IJourneyPayloadNodesKeyProperty);
                  })}</React.Fragment>;
                })
              }
            </Panel>;
          })}

        </Collapse>
        <div className='side-buttons'>
          <Button onClick={reset}>{t('RESET')}</Button>
          <Button onClick={reload}>{t('RELOAD_FROM_JOURNEY')}</Button>
          {/*
        {!document.fullscreenElement && <Button onClick={() => {
          document?.querySelector('#journey-nodes-container')?.requestFullscreen();
        }}>{t('ENTER_FULLSCREEN')}</Button>}
        */}
        </div>

      </div>
      {/*<button className={'side-button'} onClick={save}>Save</button>*/}
    </div>
  );
};

export default React.memo(JourneysSideList);
