import { IPreviewTimeInterval } from 'atlas-shared';

export enum TimeIntervalActions {
  SET_STATE = 'TIME_INTERVAL_SET_STATE',
  ADD_STATE = 'TIME_INTERVAL_ADD_STATE',
  REMOVE_STATE = 'TIME_INTERVAL_REMOVE_STATE',
  GET_STATE = 'TIME_INTERVAL_GET_STATE',
  UPDATE_STATE = 'TIME_INTERVAL_UPDATE_STATE',
  LOADING = 'TIME_INTERVAL_LOADING',
}

export interface ITimeIntervalLoading {
  type: TimeIntervalActions.LOADING;
}

export interface ITimeIntervalSetAction {
  type: TimeIntervalActions.SET_STATE;
  payload: Array<IPreviewTimeInterval>;
}

export interface ITimeIntervalUpdateAction {
  type: TimeIntervalActions.UPDATE_STATE;
  payload: IPreviewTimeInterval;
}

export interface ITimeIntervalAddAction {
  type: TimeIntervalActions.ADD_STATE;
  payload: IPreviewTimeInterval;
}

export interface ITimeIntervalRemoveAction {
  type: TimeIntervalActions.REMOVE_STATE;
  payload: IPreviewTimeInterval['id'];
}

export interface ITimeIntervalStore {
  loaded: boolean;
  loading: boolean;
  time_intervals: Array<IPreviewTimeInterval>;
  dict: Record<IPreviewTimeInterval['id'], IPreviewTimeInterval>;
}

export type TTimeIntervalActionTypes = ITimeIntervalSetAction | ITimeIntervalUpdateAction | ITimeIntervalAddAction | ITimeIntervalRemoveAction | ITimeIntervalLoading;
