import { RecentlyViewedConversationActions } from '@Store';
import { IPreviewRecentlyViewedConversation } from 'atlas-shared';
import { fetchRecentlyViewedConversations } from '@Api';

export const actionFetchRecentlyViewedConversations = () => {
  return async (dispatch) => {
    dispatch(actionLoadingRecentlyViewedConversations());
    const recently_viewed_conversations = await fetchRecentlyViewedConversations();

    dispatch(actionSetRecentlyViewedConversations(recently_viewed_conversations));
    return recently_viewed_conversations;
  };
};

export const actionLoadingRecentlyViewedConversations = () => ({
  type: RecentlyViewedConversationActions.LOADING,
  payload: []
});

export const actionSetRecentlyViewedConversations = (payload: Array<IPreviewRecentlyViewedConversation>) => ({
  type: RecentlyViewedConversationActions.SET_STATE,
  payload: payload
});

export const actionAddRecentlyViewedConversation = (payload: IPreviewRecentlyViewedConversation) => ({
  type: RecentlyViewedConversationActions.ADD_STATE,
  payload: payload
});

export const actionUpdateRecentlyViewedConversation = (payload: IPreviewRecentlyViewedConversation) => ({
  type: RecentlyViewedConversationActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveRecentlyViewedConversation = (id: IPreviewRecentlyViewedConversation['id']) => ({
  type: RecentlyViewedConversationActions.REMOVE_STATE,
  payload: id
});
