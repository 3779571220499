import './conversation.message.internal_note.scss';
import React from 'react';
import { ISharedInternalNote, ISharedFullConversation, IPreviewTab } from 'atlas-shared';
import { highlightClassNames, timeSince } from '@Utils';
import { ConversationMessageReply } from '../conversation.message.reply';
import { TFunction } from 'i18next';
import { IAuth } from '@Store';
import { ConversationMessageReplies } from '@Components/conversation';
import { ConversationMessageInternalNoteBody } from './conversation.message.internal_note.body';
import { THighlightedMessage } from '@Hooks';

interface IProps {
  t: TFunction;
  auth: IAuth;
  message: ISharedInternalNote;
  conversation: ISharedFullConversation;
  avatar: React.ReactNode;
  highlightedMessage: THighlightedMessage;
  tab?: IPreviewTab;
}

export const ConversationMessageInternalNote = React.memo(({ message, conversation, t, auth, avatar, highlightedMessage, tab }: IProps) => {
  
  return (
    <div className={`conversation-message conversation-message-internal-note internal${highlightClassNames(message, highlightedMessage)}`}>
      <ConversationMessageReply t={t} auth={auth} message={message} conversation={conversation} />
      <div className='conversation-message-content'>
        <div className='head'>
          <div className={'info'}>
            <div className={'time'} >
              {timeSince(t, new Date(message.created_at), true)}
            </div>
          </div>
          {avatar}
        </div>
        <ConversationMessageInternalNoteBody t={t} message={message} tab={tab} conversation={conversation} />
      </div>
      <ConversationMessageReplies message={message} conversation={conversation} highlightedMessage={highlightedMessage} />
    </div>
  );
  
});
