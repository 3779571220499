import { Timeline } from 'antd';
import { EditWithLineIcon } from '@Assets';
import { ConversationMessageDraft } from '@Components';
import React from 'react';
import { ISharedDraft, ISharedFullConversation } from 'atlas-shared/dist';
import { THighlightedMessage } from '@Hooks';

interface IProps {
  conversation: ISharedFullConversation;
  draft: ISharedDraft;
  editable: boolean;
  highlightedMessage: THighlightedMessage;
}

export const ConversationMessageDraftTimeline = React.memo(({ conversation, draft, editable, highlightedMessage }: IProps) => {
  return !conversation ? <></> : <Timeline.Item dot={<div className={'reply-edit-icon'} ><EditWithLineIcon size={13} /></div>} className={`reply-timeline-custom ${!editable ? 'reply-not-editable' : ''}`} >
    <ConversationMessageDraft conversation={conversation} draft={draft} editable={editable} highlightedMessage={highlightedMessage} />
  </Timeline.Item>;
});
