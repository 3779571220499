import { RestRequest } from '@Utils/rest';
import { IPreviewContact, ISharedContact, ISharedList, ISharedConversation, IPreviewConversation, IConversationTimeline } from 'atlas-shared';

export * from './abstract/contact.api.abstract';

export const patchContactByList = (organization_id: IPreviewContact['organization_id'], id: ISharedContact['id'], list_id: ISharedList['id'], payload: Record<string, any>) => {
  return RestRequest.patch<any, ISharedContact>(`contact/p/${organization_id}/${id}/${list_id}`, payload);
};

export const createContactByList = (list_id: ISharedList['id'], payload: Record<string, any>) => {
  return RestRequest.post<any, IPreviewContact>(`contact/${list_id}`, payload);
};

export const mergeContacts = async (organization_id: IPreviewContact['organization_id'], contact_id: IPreviewContact['id'], primary_id: IPreviewContact['id']): Promise<boolean> => {
  return RestRequest.put<{primary_id: IPreviewContact['id']}, boolean>(`contact/p/${organization_id}/${contact_id}/merge`, { primary_id });
};

export const fetchConversationContactSuggestions = async (organization_id: IPreviewConversation['organization_id'], id: ISharedConversation['id']): Promise<Array<IPreviewContact>> => {
  return RestRequest.get<Array<IPreviewContact>>(`conversation/p/${organization_id}/${id}/suggest/contacts`);
};

export const fetchContactTimeline = async (organization_id: IPreviewConversation['organization_id'], id: ISharedContact['id']) => {
  return RestRequest.get<{ events: Array<IConversationTimeline> }>(`contact/p/${organization_id}/${id}/timeline`);
};
