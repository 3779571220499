import { AbilitiesActions, IAbilities } from '@Store';

export const actionSetAbilities = (payload: IAbilities) => ({
  type: AbilitiesActions.SET_STATE,
  payload: payload
});

export const abilitiesRemoveState = () => ({
  type: AbilitiesActions.REMOVE_STATE,
});
