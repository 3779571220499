import { RestRequest } from '@Utils';
import { IPreviewPhonenumberSetupCostParent, ISharedPhonenumberSetupCostParent, ISharedCreatePhonenumberSetupCostParent, ISharedPatchPhonenumberSetupCostParent } from 'atlas-shared';

export const fetchPhonenumberSetupCostParents = async (): Promise<Array<IPreviewPhonenumberSetupCostParent>> => {
  return RestRequest.get<Array<IPreviewPhonenumberSetupCostParent>>('phonenumber_setup_cost_parent', { socket_id: global.socketId });
};

export const fetchPhonenumberSetupCostParent = async (id: IPreviewPhonenumberSetupCostParent['id']): Promise<ISharedPhonenumberSetupCostParent> => {
  return RestRequest.get<ISharedPhonenumberSetupCostParent>(`phonenumber_setup_cost_parent/${id}`, { socket_id: global.socketId });
};

export const createPhonenumberSetupCostParent = (payload: ISharedCreatePhonenumberSetupCostParent) => {
  return RestRequest.post<any, ISharedPhonenumberSetupCostParent>('phonenumber_setup_cost_parent', payload, { socket_id: global.socketId });
};

export const patchPhonenumberSetupCostParent = (id: IPreviewPhonenumberSetupCostParent['id'], payload: ISharedPatchPhonenumberSetupCostParent) => {
  return RestRequest.patch<any, ISharedPhonenumberSetupCostParent>(`phonenumber_setup_cost_parent/${id}`, payload, { socket_id: global.socketId });
};

export const deletePhonenumberSetupCostParent = (id: IPreviewPhonenumberSetupCostParent['id']) => {
  return RestRequest.delete(`phonenumber_setup_cost_parent/${id}`, { socket_id: global.socketId });
};

export const getPhonenumberSetupCostParentUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_setup_cost_parent/update/schema');
};

export const getPhonenumberSetupCostParentCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_setup_cost_parent/create/schema');
};

export const getPhonenumberSetupCostParentPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_setup_cost_parent/patch/schema');
};
