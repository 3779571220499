import { RestRequest } from '@Utils';
import { IPreviewStatsCustomMetric, ISharedStatsCustomMetric, ISharedCreateStatsCustomMetric, ISharedPatchStatsCustomMetric } from 'atlas-shared';

export const fetchStatsCustomMetrics = async (): Promise<Array<IPreviewStatsCustomMetric>> => {
  return RestRequest.get<Array<IPreviewStatsCustomMetric>>('stats_custom_metric', { socket_id: global.socketId });
};

export const fetchStatsCustomMetric = async (id: IPreviewStatsCustomMetric['id']): Promise<ISharedStatsCustomMetric> => {
  return RestRequest.get<ISharedStatsCustomMetric>(`stats_custom_metric/${id}`, { socket_id: global.socketId });
};

export const createStatsCustomMetric = (payload: ISharedCreateStatsCustomMetric) => {
  return RestRequest.post<any, ISharedStatsCustomMetric>('stats_custom_metric', payload, { socket_id: global.socketId });
};

export const patchStatsCustomMetric = (id: IPreviewStatsCustomMetric['id'], payload: ISharedPatchStatsCustomMetric) => {
  return RestRequest.patch<any, ISharedStatsCustomMetric>(`stats_custom_metric/${id}`, payload, { socket_id: global.socketId });
};

export const deleteStatsCustomMetric = (id: IPreviewStatsCustomMetric['id']) => {
  return RestRequest.delete(`stats_custom_metric/${id}`, { socket_id: global.socketId });
};

export const getStatsCustomMetricUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_custom_metric/update/schema');
};

export const getStatsCustomMetricCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_custom_metric/create/schema');
};

export const getStatsCustomMetricPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('stats_custom_metric/patch/schema');
};
