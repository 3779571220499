import { TFunction } from 'i18next';
import { FormElementTypes, FormLayoutTypes, IForm, IFormAdditionalParams, IFormOptions, Nullable } from 'atlas-form';
import {
  IStatsCustomMetricFormOptionsProps, 
  StatsCustomMetricFormOptions as StatsCustomMetricFormOptionsBase,
  StatsCustomMetricAdditionalParams as StatsCustomMetricAdditionalParamsBase
} from './abstract/stats_custom_metric.form.abstract';
import { 
  ISharedCreateStatsCustomMetric, 
  ISharedStatsCustomMetric, 
  Metrics, 
  filterMap,
  findMetricByAlias,
  ISharedCreateStatsCustomMetricMetricProperty,
  MetricQueryTypeEnum,
  
  AnsweredAbandonedWithinArray,
  MessageAnsweredAbandonedWithinArray,
  ThreadAnsweredAbandonedWithinArray,
  MetricUnitTypeEnum
} from 'atlas-shared';

import {
  customMetricToOption,
  metricToOption,
  stats_channels_options,
  stats_custom_metric_query_type,
  stats_direction_options,
  stats_message_channels_options,
  stats_message_direction_options
} from '@Utils/stats';
import { IAuth } from '@Store';

export * from './abstract/stats_custom_metric.form.abstract';

const metric_options = (t: TFunction, stats_custom_metric: ISharedCreateStatsCustomMetric | ISharedStatsCustomMetric) => {

  const first_metric = stats_custom_metric.metrics?.[0];
  const unit_type = first_metric && Metrics.find(m => m.alias === first_metric.alias)?.unit_type;

  if (unit_type)
    return filterMap(Metrics, (m) => m.unit_type === unit_type && metricToOption(m, t));

  return filterMap(Metrics, (m) => [
    MetricUnitTypeEnum.Number,
    MetricUnitTypeEnum.Decimal,
    MetricUnitTypeEnum.Percent,
    MetricUnitTypeEnum.Second,
    MetricUnitTypeEnum.Count,
  ].includes(m.unit_type) && metricToOption(m, t));
};

export const StatsCustomMetricFormOptions = (stats_custom_metric: ISharedCreateStatsCustomMetric | ISharedStatsCustomMetric, props: IStatsCustomMetricFormOptionsProps, t: TFunction): IFormOptions => ({
  metrics_alias: metric_options(t, stats_custom_metric),
  metrics_stats_custom_metric_id: props.stats_custom_metrics.filter(m => m.id !== (stats_custom_metric as ISharedStatsCustomMetric).id).map(m => customMetricToOption(m, t)),
  ...StatsCustomMetricFormOptionsBase(stats_custom_metric, props, t),
  metrics_channels: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return !metric?.channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.channels?.includes(o.key as any));
  },
  metrics_direction: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return !metric?.directions ? stats_direction_options(t) : stats_direction_options(t).filter(o => metric.directions?.includes(o.key as any));
  },
  metrics_thread_channels: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return !metric?.thread_channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.thread_channels?.includes(o.key as any));
  },
  metrics_thread_direction: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return !metric?.thread_directions ? stats_direction_options(t) : stats_direction_options(t).filter(o => metric.thread_directions?.includes(o.key as any));
  },
  metrics_message_channels: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return !metric?.message_channels ? stats_message_channels_options(t) : stats_message_channels_options(t).filter(o => metric.message_channels?.includes(o.key as any));
  },
  metrics_message_direction: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return !metric?.message_directions ? stats_message_direction_options(t) : stats_message_direction_options(t).filter(o => metric.message_directions?.includes(o.key as any));
  },
  query_type: stats_custom_metric_query_type(t),
  metrics_seconds_threshold: seconds_threshold_options(t, AnsweredAbandonedWithinArray),
  metrics_thread_seconds_threshold: seconds_threshold_options(t, ThreadAnsweredAbandonedWithinArray),
  metrics_message_seconds_threshold: seconds_threshold_options(t, MessageAnsweredAbandonedWithinArray),
});

const QueryTypeToDelimiter = (query_type: ISharedStatsCustomMetric['query_type']): Nullable<IFormAdditionalParams['list_delimiter']> => {
  switch (query_type) {
  case MetricQueryTypeEnum.Sum:
    return { delimiter: '+', title: 'ADDITION' };
  case MetricQueryTypeEnum.Diff:
    return { delimiter: '-', title: 'SUBTRACTION' };
  case MetricQueryTypeEnum.Add:
    return { delimiter: '+', title: 'ADDITION' };
  case MetricQueryTypeEnum.Percent:
  case MetricQueryTypeEnum.Avg:
    return { delimiter: '/', title: 'DIVISION' };
  default:
    return null;
  }
};

export const StatsCustomMetricAdditionalParams: (auth: IAuth, props: IStatsCustomMetricFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsCustomMetricFormOptionsProps) => ({
  ...StatsCustomMetricAdditionalParamsBase(auth, props),
  metrics: (it: ISharedStatsCustomMetric) => {
    const delimiter = QueryTypeToDelimiter(it.query_type);

    return {
      ui_type: FormElementTypes.LIST,
      ui_layout: FormLayoutTypes.VERTICAL,
      list_delimiter: delimiter ? { ...delimiter, placement: 'left' } : undefined,
      element_style: {
        minWidth: 250,
      },
      child_params: {
        ui_type: FormElementTypes.LIST,
        ui_layout: FormLayoutTypes.VERTICAL,
        //width: 180
      }
    };
  },
  metrics_channels: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: metric?.channels && metric.channels.length <= 1 };
  },
  metrics_alias: { label: 'METRIC' },
  metrics_channels_settings: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.channels && metric.channels.length <= 1) };
  },
  metrics_direction_settings: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.directions && metric.directions.length <= 1) };
  },
  metrics_direction: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.directions && metric.directions.length <= 1) };
  },
  metrics_thread_channels_settings: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.thread_channels && metric.thread_channels.length <= 1) };
  },
  metrics_thread_channels: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.thread_channels && metric.thread_channels.length <= 1) };
  },
  metrics_message_channels_settings: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.message_channels && metric.message_channels.length <= 1) };
  },
  metrics_message_channels: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.message_channels && metric.message_channels.length <= 1) };
  },
  metrics_thread_direction_settings: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.thread_directions && metric.thread_directions.length <= 1) };
  },
  metrics_thread_direction: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.thread_directions && metric.thread_directions.length <= 1) };
  },
  metrics_message_direction_settings: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.message_directions && metric.message_directions.length <= 1) };
  },
  metrics_message_direction: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
    const metric = findMetricByAlias(settings.alias);

    return { hidden: !metric || (metric?.message_directions && metric.message_directions.length <= 1) };
  },
  metrics_seconds_threshold: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => seconds_threshold_settings('seconds_threshold', it, settings),
  metrics_thread_seconds_threshold: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => seconds_threshold_settings('thread_seconds_threshold', it, settings),
  metrics_message_seconds_threshold: (it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => seconds_threshold_settings('message_seconds_threshold', it, settings),
});

const seconds_threshold_settings = (settings_key: string, it: ISharedStatsCustomMetric, settings: ISharedCreateStatsCustomMetricMetricProperty) => {
  const metric = settings.alias ? findMetricByAlias(settings.alias) : undefined;
  const after_seconds = !!metric?.after_seconds;
  const within_seconds = !!metric?.within_seconds;
  let hidden = !after_seconds && !within_seconds;

  if (!hidden && metric) {
    const message_alias = metric.alias.startsWith('message_');
    const message_settings = settings_key === 'message_seconds_threshold';
    const thread_alias = metric.alias.startsWith('thread_');
    const thread_settings = settings_key === 'thread_seconds_threshold';
    const conversation_settings = settings_key === 'seconds_threshold';

    if (message_alias && !message_settings)
      hidden = true;
    else if (thread_alias && !thread_settings)
      hidden = true;
    else if (!message_alias && !thread_alias && !conversation_settings)
      hidden = true;
  }

  return { label: 'TIME_THRESHOLD', hidden, preFixText: after_seconds ? 'AFTER' : 'WITHIN' };
};

const seconds_threshold_options = (t: TFunction, options: Array<number>) => {
  const minute = 60;
  const hour = minute * 60;

  return options.map(v => {
    let title = `${v} ${t(`SECOND${v > 1 ? 'S' : ''}`)}`;

    if (v >= hour)
      title = `${v / hour} ${t(`HOUR${v > 1 ? 'S' : ''}`)}`;
    else if (v >= minute)
      title = `${v / minute} ${t(`MINUTE${v > 1 ? 'S' : ''}`)}`;

    return {
      key: v,
      title
    };
  });
};
