import { ChannelEnum, Undefinable } from 'atlas-shared/dist';
import { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useState } from 'react';

export interface IHighlightedMessage {
  channel: ChannelEnum;
  id: number;
}

export type THighlightedMessage = [
  Undefinable<IHighlightedMessage>,
  Dispatch<SetStateAction<Undefinable<IHighlightedMessage>>>,
  Undefinable<IHighlightedMessage>,
  Dispatch<SetStateAction<Undefinable<IHighlightedMessage>>>,
  string
];

export const useHighlightedMessage = (scrollRef: RefObject<HTMLDivElement>): THighlightedMessage => {
  const [highlightedMessage, setHighlightedMessage] = useState<Undefinable<IHighlightedMessage>>();
  const [clickedHighlightedMessage, setClickedHighlightedMessage] = useState<Undefinable<IHighlightedMessage>>();
  const id = useMemo(() => (Math.random() + '').split('.')[1], []);

  useEffect(() => {

    if (!clickedHighlightedMessage)
      return;

    const element = document.getElementById(`conversation-message-${id}-${clickedHighlightedMessage.channel}-${clickedHighlightedMessage.id}`);

    if (!element)
      return;

    if (clickedHighlightedMessage?.id && scrollRef.current)
      element.scrollIntoView({ behavior: 'smooth' });
    
  }, [clickedHighlightedMessage, scrollRef]);

  return [highlightedMessage, setHighlightedMessage, clickedHighlightedMessage, setClickedHighlightedMessage, id];
};
