import { IconText } from '@Components';
import { CheckIcon, XIcon } from '@Assets';
import React from 'react';
import { TFunction } from 'i18next';
import { Nullable } from 'atlas-shared/dist';

export const tableColumnBooleanRender = (t: TFunction, active: Nullable<boolean>) => <div className='boolean-column'>{active === null ? '-' : active ? <IconText icon={CheckIcon} text={t('YES')} iconProps={{ size: 18, style: { color: 'var(--green)' } }} /> : <IconText icon={XIcon} text={t('NO')} iconProps={{ size: 18, style: { color: 'var(--red)' } }} />}</div>;

export const tableColumnDatetimeSorter = (a: Nullable<string>, b: Nullable<string>) => ((a ? new Date(a).getTime() : 0) - (b ? new Date(b).getTime() : 0)) > 0 ? 1 : -1;

export const tableColumnStringSorter = (a: Nullable<string>, b: Nullable<string>) => (a || '').localeCompare(b || '');
