import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Slider, Tooltip } from 'antd';
import './header.call.bar.scss';
import { BellIcon, BellOffIcon, SettingsIcon, Volume1Icon, Volume2Icon, VolumeXIcon } from '@Assets/icons';

import { Icon, VoiceSuggestions } from '@Components';
import { useDevices, useDevicesAvailable, useNotificationUsers, useSipUsers, useVoiceMic, useVoiceVolume } from '@Hooks';
import { ChannelEnum, NotificationSubjectEnum, Nullable } from 'atlas-shared';
import {
  actionDisplayAudioSettings, actionSetDevicesAvailable,
  actionSetGlobalVoiceVolume,
  IGlobalStore,
  ISipStoreSip,
  useAppDispatch
} from '@Store';
import { RegistererState } from 'sip.js';
import { useTranslation } from 'react-i18next';
import { deviceSetErrorCatch } from '@Utils';
import { AudioSettingsModal } from './audio.settings.modal';

export const HeaderCallBar = React.memo(() => {

  const { t } = useTranslation();
  const sip_users = useSipUsers();
  const ringtoneExternalVoiceAudioRef = useRef<Nullable<HTMLAudioElement>>(null);
  const ringtoneInternalVoiceAudioRef = useRef<Nullable<HTMLAudioElement>>(null);
  const ringtoneNoneVoiceAudioRef = useRef<Nullable<HTMLAudioElement>>(null);
  const notification_users = useNotificationUsers(false);
  const [externalVoiceRingtone, setExternalVoiceRingtone] = useState<boolean>(false);
  const [internalVoiceRingtone, setInternalVoiceRingtone] = useState<boolean>(false);
  const [none_voice_ringtone, set_none_voice_ringtone] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<ISipStoreSip>>([]);
  const [play_tone, set_play_tone] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const voice_volume = useVoiceVolume();
  const voice_mic = useVoiceMic();
  const devices = useDevices();
  const devices_available = useDevicesAvailable();
  const [didMount, setDidMount] = useState<boolean>(false);
  const [speakerDevices, setSpeakerDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [microphoneDevices, setMicrophoneDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [deviceErrors, setDeviceErrors] = useState<Array<string>>([]);
  const [deviceWarnings, setDeviceWarnings] = useState<Array<string>>([]);
  const [alerted, setAlerted] = useState<boolean>(false);
  const defaultDevices: Array<MediaDeviceInfo> = useMemo(() => {
    return [
      {
        deviceId: 'default',
        label: t('DEFAULT_MICROPHONE'),
        kind: 'audioinput'
      } as MediaDeviceInfo,
      {
        deviceId: 'default',
        label: t('DEFAULT_SPEAKER'),
        kind: 'audiooutput'
      } as MediaDeviceInfo
    ];
  }, []);

  const setDevicesAvailable = useCallback((partial: Partial<IGlobalStore['devices_available']>) => {
    // @ts-ignore
    dispatch(actionSetDevicesAvailable({
      ...partial
    }));
  }, []);

  const updateDeviceList = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        navigator.mediaDevices.enumerateDevices().then(_devices => {
          const devices = _devices.filter(device => device.deviceId !== 'default');

          setSpeakerDevices([...defaultDevices, ...devices].filter(device => device.kind === 'audiooutput'));
          setMicrophoneDevices([...defaultDevices, ...devices].filter(device => device.kind === 'audioinput'));
        });
        stream!.getTracks()
          .forEach(track => track.stop());
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    updateDeviceList();
    navigator.mediaDevices.ondevicechange = (event) => {
      setTimeout(() => {
        updateDeviceList();
      }, 1000);
    };
  }, []);

  useLayoutEffect(() => {
    if (!didMount && ringtoneExternalVoiceAudioRef.current && ringtoneInternalVoiceAudioRef.current && ringtoneNoneVoiceAudioRef.current)
      setDidMount(true);
  });

  useEffect(() => {
    if (!didMount || !devices.ringtone || !ringtoneExternalVoiceAudioRef.current || !ringtoneInternalVoiceAudioRef.current || !ringtoneNoneVoiceAudioRef.current)
      return;

    // @ts-ignore
    if (/*!alerted && */!ringtoneExternalVoiceAudioRef.current.setSinkId) {
      setDeviceWarnings([t('BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET')]);
      // deviceSetErrorCatch('', new Error(t('BROWSER_DOES_NOT_SUPPORT_SPEAKER_SET')), t);
      // setAlerted(true);
    }
    else if (deviceWarnings.length)
      setDeviceWarnings([]);

    (ringtoneExternalVoiceAudioRef.current as any)?.setSinkId?.(devices_available.ringtone ? devices.ringtone : 'default').catch(err => deviceSetErrorCatch(t('ERROR_SETTING_RINGTONE_SPEAKER'), err, t));
    (ringtoneInternalVoiceAudioRef.current as any)?.setSinkId?.(devices_available.ringtone ? devices.ringtone : 'default').catch(err => deviceSetErrorCatch(t('ERROR_SETTING_RINGTONE_SPEAKER'), err, t));
    (ringtoneNoneVoiceAudioRef.current as any)?.setSinkId?.(devices_available.ringtone ? devices.ringtone : 'default').catch(err => deviceSetErrorCatch(t('ERROR_SETTING_RINGTONE_SPEAKER'), err, t));
  }, [devices.ringtone, devices_available.ringtone, didMount, alerted]);

  useEffect(() => {

    // @ts-ignore
    const hasSpeakerSupport = !!document.createElement('audio').setSinkId;
    const errors: Array<string> = [];
    const devices_available: Partial<IGlobalStore['devices_available']> = {
      ringtone: false,
      speaker: false,
      microphone: false,
    };

    if (hasSpeakerSupport) {
      if (speakerDevices.length <= 1)
        errors.push(t('NO_SPEAKERS_FOUND'));
      else {
        if (devices.ringtone && !speakerDevices.some(d => d.deviceId === devices.ringtone))
          errors.push(t('SELECTED_RINGTONE_SPEAKER_NOT_FOUND'));
        else
          devices_available.ringtone = true;

        if (devices.speaker && !speakerDevices.some(d => d.deviceId === devices.speaker))
          errors.push(t('SELECTED_SPEAKER_NOT_FOUND'));
        else
          devices_available.speaker = true;
      }
    }

    if (microphoneDevices.length <= 1)
      errors.push(t('NO_MICROPHONES_FOUND'));
    else {
      if (devices.microphone && !microphoneDevices.some(d => d.deviceId === devices.microphone))
        errors.push(t('SELECTED_MICROPHONE_NOT_FOUND'));
      else
        devices_available.microphone = true;
    }

    setDevicesAvailable(devices_available);

    /*
    try {
      navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          ...(devices.microphone ? { deviceId: devices.microphone } : {})
        }
      }).then(audioStream => {
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(audioStream);
        const analyser = audioContext.createAnalyser();

        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        const checks = 10;

        const volumeCallback = () => {
          analyser.getByteFrequencyData(volumes);
          let volumeSum = 0;

          for (const volume of volumes)
            volumeSum += volume;
          return volumeSum / volumes.length;

        };

        Promise.all(new Array(checks).fill(0).map((_, i) => new Promise(resolve => {
          setTimeout(() => {
            resolve(volumeCallback());
          }, (i + 1) * 150);
        })) as Array<Promise<number>>).then(avgs => {
          const avg = avgs.reduce((p, a) => p + a, 0) / avgs.length;

          if (avg < 1)
            errors.push(t('WE_COULD_NOT_DETECT_SOUND_FROM_MICROPHONE'));

          setDeviceErrors(errors);
        });

      });

    }
    catch (e) {
      errors.push('COULD_NOT_ACCESS_MICROPHONE');
      setDeviceErrors(errors);
    }
     */

    setDeviceErrors(errors);

  }, [speakerDevices, microphoneDevices, devices]);

  useEffect(( ) => {
    ringtoneExternalVoiceAudioRef.current?.[externalVoiceRingtone ? 'play' : 'pause']?.();
  }, [externalVoiceRingtone]);

  useEffect(( ) => {
    ringtoneInternalVoiceAudioRef.current?.[internalVoiceRingtone ? 'play' : 'pause']?.();
  }, [internalVoiceRingtone]);

  useEffect(( ) => {
    ringtoneNoneVoiceAudioRef.current?.[none_voice_ringtone ? 'play' : 'pause']?.();
  }, [none_voice_ringtone]);

  useEffect(() => {
    const notifications_channels = play_tone ? notification_users.notification_users
      .filter(notification_user => !notification_user.deleted_at && !notification_user.notification?.deleted_at && notification_user.notification && [NotificationSubjectEnum.NewAssignment, NotificationSubjectEnum.NewInternal].includes(notification_user.notification?.subject)) : [];

    setExternalVoiceRingtone(notifications_channels.some(n => n.notification?.channel === ChannelEnum.Voice && n.notification?.subject === NotificationSubjectEnum.NewAssignment));
    setInternalVoiceRingtone(notifications_channels.some(n => n.notification?.channel === ChannelEnum.Voice && n.notification?.subject === NotificationSubjectEnum.NewInternal));
    set_none_voice_ringtone(notifications_channels.some(n => n.notification?.channel !== ChannelEnum.Voice));

  }, [notification_users, play_tone]);

  useEffect(() => {
    setErrors(sip_users.sip_users.filter(sip_user => sip_user.state && [RegistererState.Unregistered, RegistererState.Terminated].includes(sip_user.state)));
  }, [sip_users]);

  const getSipStateText = useCallback((sip_user: ISipStoreSip) => {
    return `${t(`SIP_STATE_${(sip_user.state + '').toUpperCase()}`)}${sip_user.voice_asterisk.__is_downgraded ? ` (${t('DOWNGRADED')})` : ''}`;
  }, []);

  return sip_users.sip_users.length ? <div className={'call-bar'}>
    <AudioSettingsModal speakerDevices={speakerDevices} microphoneDevices={microphoneDevices} />
    <div style={{ display: 'none' }}>
      <audio src={`/ringtones/${localStorage.getItem('atlas-voice-ringtone') || 'ringing1.ogg'}`} controls loop ref={ringtoneExternalVoiceAudioRef} />
      <audio src={`/ringtones/${localStorage.getItem('atlas-internal-voice-ringtone') || 'ringing5.ogg'}`} controls loop ref={ringtoneInternalVoiceAudioRef} />
      <audio src={`/ringtones/${localStorage.getItem('atlas-notification-ringtone') || 'notification1.wav'}`} controls loop ref={ringtoneNoneVoiceAudioRef} />
    </div>
    <div className='sip-info'>
      {sip_users.sip_users.map(sip_user => sip_user.state !== RegistererState.Registered && <div key={sip_user.voice_asterisk.id} className={`sip-state ${(sip_user.state + '').toLowerCase()}`}>{sip_users.sip_users.length > 0 && <span className='sip-name'>{sip_user.voice_asterisk.title}</span>}<span className='title'>{t('SIP_STATE')}</span> <span className='state'>{getSipStateText(sip_user)}</span></div>)}
    </div>
    <ul className={'actions'} >

      <li className='volume-icon' title={t('VOICE_DEVICE_AND_TWINNING_SETTINGS')}>
        <Icon icon={!voice_volume ? VolumeXIcon : voice_volume < .5 ? Volume1Icon : Volume2Icon} iconProps={{ size: 14 }} onClick={() => dispatch(actionSetGlobalVoiceVolume(!voice_volume ? 1 : 0)) } />
        <div className='slider-wrapper'>
          <Slider
            min={0}
            max={1}
            onChange={voice_volume => dispatch(actionSetGlobalVoiceVolume(voice_volume))}
            value={voice_volume}
            step={0.1}
            className='volume-slider'
            tooltip={{ open: false }}
          />
        </div>
      </li>

      <li className={`audio-settings${deviceErrors.length ? ' error' : ''}${deviceWarnings.length ? ' warning' : ''}`} title={t('VOICE_DEVICE_AND_TWINNING_SETTINGS')}>
        <Icon icon={SettingsIcon} iconProps={{ size: 14 }} onClick={() => dispatch(actionDisplayAudioSettings(true)) } tooltip={deviceErrors.length || deviceWarnings.length ? { title: [...deviceErrors, deviceWarnings].map((e, i) => <li key={i}>{e}</li>) } : undefined} />
      </li>

      <li title={t('VOICE_NOTIFICATION_SOUND')}>
        <Icon icon={play_tone ? BellIcon : BellOffIcon} iconProps={{ size: 14 }} onClick={() => set_play_tone(!play_tone)} />
      </li>

      <li className={'action-grow'}>
        <div className='sip-states'>
          {sip_users.sip_users.map(sip_user => <Tooltip key={sip_user.voice_asterisk.id} title={`${sip_users.sip_users.length > 1 ? `${sip_user.voice_asterisk.title}, ` : ''}${getSipStateText(sip_user)}`}><div className={`sip-state sip-state-${sip_user.state}`}></div></Tooltip>)}
        </div>
        <VoiceSuggestions popover />
      </li>

    </ul>
  </div> : <></>;
});
