import { SkillActions } from '@Store';
import { IPreviewSkill } from 'atlas-shared';
import { fetchSkills } from '@Api';

export const actionFetchSkills = () => {
  return async (dispatch) => {
    dispatch(actionLoadingSkills());
    const skills = await fetchSkills();

    dispatch(actionSetSkills(skills));
    return skills;
  };
};

export const actionLoadingSkills = () => ({
  type: SkillActions.LOADING,
  payload: []
});

export const actionSetSkills = (payload: Array<IPreviewSkill>) => ({
  type: SkillActions.SET_STATE,
  payload: payload
});

export const actionAddSkill = (payload: IPreviewSkill) => ({
  type: SkillActions.ADD_STATE,
  payload: payload
});

export const actionUpdateSkill = (payload: IPreviewSkill) => ({
  type: SkillActions.UPDATE_STATE,
  payload: payload
});
