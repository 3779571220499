import React, { useState } from 'react';
import { DirectionEnum, IPreviewVoiceAsterisk, IPreviewVoiceCall } from 'atlas-shared';
import { timeSince, TranslateAndDisplayPhoneNumber } from '@Utils';
import { TVoiceSuggestionOnSelect } from './voice-suggestion.utils';
import { PhoneIcon, PhoneIncomingIcon, PhoneOutgoingIcon } from '@Assets/icons';
import { useTranslation } from 'react-i18next';
import { IconText } from '@Components';
import { IAuth } from '@Store';

interface IProps {
  auth: IAuth;
  voice_call: IPreviewVoiceCall;
  onSelect: TVoiceSuggestionOnSelect;
  iso2: IPreviewVoiceAsterisk['iso2'];
  callBtn: React.ReactNode;
  callable?: boolean;
}
export const VoiceSuggestionNumber = React.memo(({ auth, voice_call, iso2, onSelect, callBtn, callable: _callable }: IProps) => {
  const { contact_phone, direction, created_at } = voice_call;
  const { t } = useTranslation();
  const [time] = useState(timeSince(t, new Date(created_at)));
  const callable = _callable || contact_phone !== 'anonymous';

  return <div className={`li-content${callable ? ' clickable' : ''}`} onClick={callable ? () => {
    onSelect([{
      title: contact_phone,
      voice_asterisk_id: -1,
      numbers: [contact_phone],
      requires_route: direction !== DirectionEnum.Internal
    }], true);
  } : undefined}>
    <IconText
      icon={direction === DirectionEnum.InBound ? PhoneIncomingIcon : direction === DirectionEnum.Outbound ? PhoneOutgoingIcon : PhoneIcon}
      text={<TranslateAndDisplayPhoneNumber t={t} phone_number={contact_phone} />}
      postFix={<span>{time}</span>}
    />
    {callBtn}
  </div>;
});
