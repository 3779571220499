import { RestRequest } from '@Utils';
import { IPreviewFormWebsite, ISharedFormWebsite, ISharedCreateFormWebsite, ISharedPatchFormWebsite } from 'atlas-shared';

export const fetchFormWebsites = async (): Promise<Array<IPreviewFormWebsite>> => {
  return RestRequest.get<Array<IPreviewFormWebsite>>('form_website', { socket_id: global.socketId });
};

export const fetchFormWebsite = async (id: IPreviewFormWebsite['id']): Promise<ISharedFormWebsite> => {
  return RestRequest.get<ISharedFormWebsite>(`form_website/${id}`, { socket_id: global.socketId });
};

export const createFormWebsite = (payload: ISharedCreateFormWebsite) => {
  return RestRequest.post<any, ISharedFormWebsite>('form_website', payload, { socket_id: global.socketId });
};

export const patchFormWebsite = (id: IPreviewFormWebsite['id'], payload: ISharedPatchFormWebsite) => {
  return RestRequest.patch<any, ISharedFormWebsite>(`form_website/${id}`, payload, { socket_id: global.socketId });
};

export const deleteFormWebsite = (id: IPreviewFormWebsite['id']) => {
  return RestRequest.delete(`form_website/${id}`, { socket_id: global.socketId });
};

export const getFormWebsiteUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('form_website/update/schema');
};

export const getFormWebsiteCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('form_website/create/schema');
};

export const getFormWebsitePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('form_website/patch/schema');
};
