import React, { useCallback } from 'react';
import '../header.popover.scss';
import './header.auth.user.popover.scss';
import { Popover, Radio, Upload } from 'antd';
import { useAgentprofiles, useAuth, useQueues } from '@Hooks';
import { LogoutIcon, CameraIcon, SettingsIcon } from '@Assets';
import { getFullName, isAgent, signout, RestRequest } from '@Utils';
import { ISharedAgentprofile, IPreviewAgentprofile } from 'atlas-shared';
import { patchUserStatus } from '@Api';
import ImgCrop from 'antd-img-crop';
import { useTranslation } from 'react-i18next';
import { Icon, Avatar } from '@Components';
import { actionSetResetPassword, useAppDispatch, actionSwitchingProfile } from '@Store';

export const HeaderAuthUserPopover = React.memo(() => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const agentprofiles = useAgentprofiles();
  const { t } = useTranslation();
  const queues = useQueues();

  const switchProfile = useCallback((pid: ISharedAgentprofile['id']) => {
    patchUserStatus(auth.user_status.id, {
      agentprofile_id: pid
    }).then(() => {
      location.reload();
    });

    dispatch(actionSwitchingProfile(true));
    setTimeout(() => {
      dispatch(actionSwitchingProfile(false));
    }, 4000);
  }, [dispatch, auth]);

  return (
    <Popover placement='bottomLeft' content={
      <div className={'header-popover user-menu-content'}>
        <div className={'header'}>
          <ImgCrop
            cropShape='round'
          >
            <Upload
              name='avatar'
              showUploadList={false}
              action={RestRequest.getFullSrc(`/user/${auth.user.id}/avatar`)}
              headers={RestRequest.getHeaders()}
              accept='.jpg,.jpeg,.png'
              onChange={(state) => {
                console.log('onChange', state);
                // state.file.status === 'done' && window.location.reload()
              }}
            >
              <Avatar size={40} user={auth.user}/>
              <Icon icon={CameraIcon} className='camera' circle={true} />
            </Upload>
          </ImgCrop>
          <div className={'title'}>
            <span className={'name'}>
              {getFullName(auth.user)}
            </span>
            <span className={'roles'}>
              {[auth.is_superadmin && t('SUPERADMIN'), auth.is_admin && t('ADMIN'), auth.is_agent && t('AGENT')].filter(Boolean).join(', ')}
            </span>
          </div>
          <div className={'right'}>
            { /* <IconText icon={SettingsIcon} iconProps={{ size: 15, style:{ color: 'var(--COLOR-FFFFFF)', marginRight: 10 } }}/> */ }
            <Icon icon={LogoutIcon} iconProps={{ size: 15, style: { color: 'var(--COLOR-FFFFFF)', cursor: 'pointer' } }} onClick={e => signout()}/>
            <Icon icon={SettingsIcon} iconProps={{ size: 15, style:{ color: 'var(--COLOR-FFFFFF)', cursor: 'pointer', marginTop: 3 } }} onClick={e => dispatch(actionSetResetPassword(auth.user.id))} />
          </div>
        </div>
        {isAgent(auth.user) && <div className={'agentprofiles'}>
          {
            agentprofiles.agentprofiles.filter(p => auth.user.__agentprofiles.includes(p.id)).map((profile: IPreviewAgentprofile) => {
              const selected = auth.user_status.agentprofile_id === profile.id;

              return (
                <div key={profile.id} className={`agentprofile${selected ? ' selected' : ''}`} onClick={() => switchProfile(profile.id)}>
                  <Radio className={'radio'} checked={selected}
                    value={profile.id}/>
                  <div className={'text'}>
                    <span className={'title'}>
                      {profile.title}
                    </span>
                    <span className={'subtitle'}>
                      {profile.__queues.map(qid => queues.queues.find(queue => queue.id === qid)?.title).join(', ')}
                    </span>
                  </div>
                </div>
              );
            })
          }

        </div>
        }
      </div>
    } trigger='click'>
      <Avatar size={40} user={auth.user} tooltipPlacement={'left'} />
    </Popover>
  );
});
