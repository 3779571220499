import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchAdminprofiles, IReducerStore, useAppDispatch } from '@Store';
import { useAuth } from './use.auth';
import { isAdmin } from '@Utils';

const useAdminprofiles = (autoload = true) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const adminprofiles = useSelector((store: IReducerStore) => store.adminprofiles);

  useEffect(() => {
    autoload && auth && isAdmin(auth.user) && !adminprofiles.loaded && !adminprofiles.loading && dispatch(actionFetchAdminprofiles());
  }, [autoload, auth, adminprofiles.loaded, adminprofiles.loading, dispatch]);

  return adminprofiles;
};

export { useAdminprofiles };
