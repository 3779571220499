import { IPreviewListFile } from 'atlas-shared';
import { IListFileStore, ListFileActions, TListFileActionTypes } from '@Store';

export const ListFileReducer = (state: IListFileStore = {
  loaded: false,
  loading: false,
  list_files: [],
  dict: {}
}, action: TListFileActionTypes): IListFileStore => {
  switch (action.type) {
  case ListFileActions.LOADING:
    return { ...state, loading: true };
  case ListFileActions.SET_STATE:
    const dict: IListFileStore['dict'] = {};

    action.payload.forEach((list_file: IPreviewListFile) => dict[list_file.id] = list_file);

    return { ...state, loaded: true, loading: false, list_files: action.payload, dict };
  case ListFileActions.ADD_STATE:
    return { ...state, list_files: [...state.list_files, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case ListFileActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, list_files: state.list_files.filter((item: IPreviewListFile) => item.id !== action.payload), dict };
  }
  case ListFileActions.UPDATE_STATE:
    const list_files = [...state.list_files];
    const index = state.list_files.findIndex((list_file: IPreviewListFile) => list_file.id === action.payload.id);

    if (!~index)
      list_files.push(action.payload);
    else
      list_files[index] = action.payload;

    return { ...state, list_files, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
