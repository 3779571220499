import { IPreviewJourneyDraft } from 'atlas-shared';

export enum JourneyDraftActions {
  SET_STATE = 'JOURNEY_DRAFT_SET_STATE',
  ADD_STATE = 'JOURNEY_DRAFT_ADD_STATE',
  REMOVE_STATE = 'JOURNEY_DRAFT_REMOVE_STATE',
  GET_STATE = 'JOURNEY_DRAFT_GET_STATE',
  UPDATE_STATE = 'JOURNEY_DRAFT_UPDATE_STATE',
  LOADING = 'JOURNEY_DRAFT_LOADING',
}

export interface IJourneyDraftLoading {
  type: JourneyDraftActions.LOADING;
}

export interface IJourneyDraftSetAction {
  type: JourneyDraftActions.SET_STATE;
  payload: Array<IPreviewJourneyDraft>;
}

export interface IJourneyDraftUpdateAction {
  type: JourneyDraftActions.UPDATE_STATE;
  payload: IPreviewJourneyDraft;
}

export interface IJourneyDraftAddAction {
  type: JourneyDraftActions.ADD_STATE;
  payload: IPreviewJourneyDraft;
}

export interface IJourneyDraftRemoveAction {
  type: JourneyDraftActions.REMOVE_STATE;
  payload: IPreviewJourneyDraft['id'];
}

export interface IJourneyDraftStore {
  loaded: boolean;
  loading: boolean;
  journey_drafts: Array<IPreviewJourneyDraft>;
  dict: Record<IPreviewJourneyDraft['id'], IPreviewJourneyDraft>;
}

export type TJourneyDraftActionTypes = IJourneyDraftSetAction | IJourneyDraftUpdateAction | IJourneyDraftAddAction | IJourneyDraftRemoveAction | IJourneyDraftLoading;
