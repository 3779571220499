import React, { useEffect, useState } from 'react';
import '../admin.list.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { IPreviewStatsReport, PermissionEnum, CrudEnum, TStatsReportSortableListCol } from 'atlas-shared';
import { Table } from 'antd';
import { AdminListPageHeader, ListPageEmpty } from '@Components';
import { IWithListProps, IWithListSorterProps, withList } from '@Hocs/with.list';
import { useAbilities, useAuth, useStatsReports, useOrganizations, useUsers } from '@Hooks';
import { StatsReportListColumns } from '@Utils';
import { IAuth } from '@Store';

const StatsReportList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams }: IWithListProps<IPreviewStatsReport, TStatsReportSortableListCol>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const stats_reports = useStatsReports();
  const abilities = useAbilities();
  const [loading, setLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  const users = useUsers();
  
  useEffect(() => {
    if (!stats_reports.loaded)
      return;
      
    setLoading(false);
    setRows((stats_reports?.stats_reports || []).map(row => ({ ...row, key: row.id })));
    
  }, [stats_reports]);

  const columns: ColumnsType<IPreviewStatsReport> = React.useMemo(() => {
    return StatsReportListColumns(t, navigate, false, organizations, users, abilities, auth, [], filters, sorter);
  }, [t, navigate, organizations, users, abilities, auth, filters, sorter]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('STATS_REPORTS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.StatsReport) ? { callback: () => navigate('/admin/stats_report/add') } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TStatsReportSortableListCol, order: s.order as IWithListSorterProps<IPreviewStatsReport, TStatsReportSortableListCol>['order'] }))
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />
        }}
      />
    </div>
  </div>;
};

export const StatsReportListView = React.memo(withList<IPreviewStatsReport, TStatsReportSortableListCol>(StatsReportList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend' }],
  pagination: { current: 1, pageSize: 10 }
}));
