import { IPreviewField } from 'atlas-shared';
import { IFieldStore, FieldActions, TFieldActionTypes } from '@Store';

export const FieldReducer = (state: IFieldStore = {
  loaded: false,
  loading: false,
  fields: [],
  dict: {}
}, action: TFieldActionTypes): IFieldStore => {
  switch (action.type) {
  case FieldActions.LOADING:
    return { ...state, loading: true };
  case FieldActions.SET_STATE:
    const dict: IFieldStore['dict'] = {};

    action.payload.forEach((field: IPreviewField) => dict[field.id] = field);

    return { ...state, loaded: true, loading: false, fields: action.payload, dict };
  case FieldActions.ADD_STATE:
    return { ...state, fields: [...state.fields, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case FieldActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, fields: state.fields.filter((item: IPreviewField) => item.id !== action.payload), dict };
  }
  case FieldActions.UPDATE_STATE:
    const fields = [...state.fields];
    const index = state.fields.findIndex((field: IPreviewField) => field.id === action.payload.id);

    if (!~index)
      fields.push(action.payload);
    else
      fields[index] = action.payload;

    return { ...state, fields, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
