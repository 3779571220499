import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateQueue, ISharedUpdateQueue, ISharedQueue, IPreviewOrganization, IPreviewList, IPreviewClient, IPreviewForm, IPreviewSlaPolicy, IPreviewSound, IPreviewDisposition, IPreviewCannedAnswer, IPreviewTag, IPreviewSignature, IPreviewMailAccount, IPreviewVoiceRoute, EnumOptions, ConversationAssignmentEnum, QueueStrategyEnum, QueueTimeoutUnitEnum, VoiceQueueStrategyEnum, VoiceRecordingFormatEnum, VoiceLanguageEnum, AsteriskMusicclassEnum, AsteriskAutopauseEnum, AsteriskAnnounceHoldtimeEnum, AsteriskAnnouncePositionEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IQueueFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  lists: Array<IPreviewList>;
  clients: Array<IPreviewClient>;
  forms: Array<IPreviewForm>;
  sla_policies: Array<IPreviewSlaPolicy>;
  sounds: Array<IPreviewSound>;
  dispositions: Array<IPreviewDisposition>;
  canned_answers: Array<IPreviewCannedAnswer>;
  tags: Array<IPreviewTag>;
  signatures: Array<IPreviewSignature>;
  mail_accounts: Array<IPreviewMailAccount>;
  voice_routes: Array<IPreviewVoiceRoute>;
}

export const QueueFormOptions = (queue: ISharedCreateQueue | ISharedQueue, props: IQueueFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Queue, {})), 'organization_id', false), 'organization'),
  create_list_id: (it: ISharedQueue) => filterRelated<IPreviewList>(it.organization_id || queue.organization_id, 'list', 'queue', props.lists, true),
  read_lists: (it: ISharedQueue) => filterRelated<IPreviewList>(it.organization_id || queue.organization_id, 'list', 'queue', props.lists, true),
  clients: (it: ISharedQueue) => filterRelated<IPreviewClient>(it.organization_id || queue.organization_id, 'client', 'queue', props.clients, true),
  conversation_form_id: (it: ISharedQueue) => filterRelated<IPreviewForm>(it.organization_id || queue.organization_id, 'form', 'queue', props.forms, true),
  inbound_sla_policy_id: (it: ISharedQueue) => filterRelated<IPreviewSlaPolicy>(it.organization_id || queue.organization_id, 'sla_policy', 'queue', props.sla_policies, true),
  outbound_sla_policy_id: (it: ISharedQueue) => filterRelated<IPreviewSlaPolicy>(it.organization_id || queue.organization_id, 'sla_policy', 'queue', props.sla_policies, true),
  voice_settings_announce: (it: ISharedQueue) => filterRelated<IPreviewSound>(it.organization_id || queue.organization_id, 'sound', 'queue', props.sounds, true),
  voice_settings_periodic_announce: (it: ISharedQueue) => filterRelated<IPreviewSound>(it.organization_id || queue.organization_id, 'sound', 'queue', props.sounds, true),
  __dispositions: (it: ISharedQueue) => filterRelated<IPreviewDisposition>(it.organization_id || queue.organization_id, 'disposition', 'queue', props.dispositions, true, it => !it.global),
  __canned_answers: (it: ISharedQueue) => filterRelated<IPreviewCannedAnswer>(it.organization_id || queue.organization_id, 'canned_answer', 'queue', props.canned_answers, true, it => !it.global),
  __tags: (it: ISharedQueue) => filterRelated<IPreviewTag>(it.organization_id || queue.organization_id, 'tag', 'queue', props.tags, true, it => !it.global),
  __agent_signatures: (it: ISharedQueue) => filterRelated<IPreviewSignature>(it.organization_id || queue.organization_id, 'signature', 'queue', props.signatures, true, it => !it.global),
  __outbound_mail_accounts: (it: ISharedQueue) => filterRelated<IPreviewMailAccount>(it.organization_id || queue.organization_id, 'mail_account', 'queue', props.mail_accounts, true),
  __outbound_voice_routes: (it: ISharedQueue) => filterRelated<IPreviewVoiceRoute>(it.organization_id || queue.organization_id, 'voice_route', 'queue', props.voice_routes, true),
  settings_conversation_assignment: EnumOptions('CONVERSATION_ASSIGNMENT', ConversationAssignmentEnum, t),
  mail_settings_strategy: EnumOptions('QUEUE_STRATEGY', QueueStrategyEnum, t),
  mail_settings_skill_timeout_settings_timeout_one_unit: EnumOptions('QUEUE_TIMEOUT_UNIT', QueueTimeoutUnitEnum, t),
  mail_settings_skill_timeout_settings_timeout_two_unit: EnumOptions('QUEUE_TIMEOUT_UNIT', QueueTimeoutUnitEnum, t),
  mail_settings_skill_timeout_settings_timeout_three_unit: EnumOptions('QUEUE_TIMEOUT_UNIT', QueueTimeoutUnitEnum, t),
  form_settings_strategy: EnumOptions('QUEUE_STRATEGY', QueueStrategyEnum, t),
  form_settings_skill_timeout_settings_timeout_one_unit: EnumOptions('QUEUE_TIMEOUT_UNIT', QueueTimeoutUnitEnum, t),
  form_settings_skill_timeout_settings_timeout_two_unit: EnumOptions('QUEUE_TIMEOUT_UNIT', QueueTimeoutUnitEnum, t),
  form_settings_skill_timeout_settings_timeout_three_unit: EnumOptions('QUEUE_TIMEOUT_UNIT', QueueTimeoutUnitEnum, t),
  voice_settings_strategy: EnumOptions('VOICE_QUEUE_STRATEGY', VoiceQueueStrategyEnum, t),
  voice_settings_recording: EnumOptions('VOICE_RECORDING_FORMAT', VoiceRecordingFormatEnum, t),
  voice_settings_default_language: EnumOptions('VOICE_LANGUAGE', VoiceLanguageEnum, t),
  voice_settings_musicclass: EnumOptions('ASTERISK_MUSICCLASS', AsteriskMusicclassEnum, t),
  voice_settings_autopause: EnumOptions('ASTERISK_AUTOPAUSE', AsteriskAutopauseEnum, t),
  voice_settings_announce_holdtime: EnumOptions('ASTERISK_ANNOUNCE_HOLDTIME', AsteriskAnnounceHoldtimeEnum, t),
  voice_settings_announce_position: EnumOptions('ASTERISK_ANNOUNCE_POSITION', AsteriskAnnouncePositionEnum, t),
});

export const QueueComponents: IForm['components'] = {};
export const QueueAdditionalParams: (auth: IAuth, props: IQueueFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IQueueFormOptionsProps) => ({
  create_list_id: {
    onModalAdd: {
      key: 'queue-create_list_id-add',
      path: '@Components/page-views/list',
      component: 'ListFormAddView',
      item_text: 'LIST'
    }
  }, 
  read_lists: {
    onModalAdd: {
      key: 'queue-read_lists-add',
      path: '@Components/page-views/list',
      component: 'ListFormAddView',
      item_text: 'LIST'
    }
  }, 
  conversation_form_id: {
    onModalAdd: {
      key: 'queue-conversation_form_id-add',
      path: '@Components/page-views/form',
      component: 'FormFormAddView',
      item_text: 'FORM'
    }
  }, 
  inbound_sla_policy_id: {
    onModalAdd: {
      key: 'queue-inbound_sla_policy_id-add',
      path: '@Components/page-views/sla_policy',
      component: 'SlaPolicyFormAddView',
      item_text: 'SLA_POLICY'
    }
  }, 
  outbound_sla_policy_id: {
    onModalAdd: {
      key: 'queue-outbound_sla_policy_id-add',
      path: '@Components/page-views/sla_policy',
      component: 'SlaPolicyFormAddView',
      item_text: 'SLA_POLICY'
    }
  }, 
  __dispositions: {
    onModalAdd: {
      key: 'queue-__dispositions-add',
      path: '@Components/page-views/disposition',
      component: 'DispositionFormAddView',
      item_text: 'DISPOSITION'
    }
  }, 
  __canned_answers: {
    onModalAdd: {
      key: 'queue-__canned_answers-add',
      path: '@Components/page-views/canned_answer',
      component: 'CannedAnswerFormAddView',
      item_text: 'CANNED_ANSWER'
    }
  }, 
  __tags: {
    onModalAdd: {
      key: 'queue-__tags-add',
      path: '@Components/page-views/tag',
      component: 'TagFormAddView',
      item_text: 'TAG'
    }
  }, 
  __agent_signatures: {
    onModalAdd: {
      key: 'queue-__agent_signatures-add',
      path: '@Components/page-views/signature',
      component: 'SignatureFormAddView',
      item_text: 'SIGNATURE'
    }
  }
});

export const QueueEditForm = (queue: ISharedQueue, props: IQueueFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `queue_edit_${queue.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === queue.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'create_list_id' },
    { ref: 'read_lists' },
    { ref: 'clients' },
    { ref: 'conversation_form_id' },
    { ref: 'inbound_sla_policy_id' },
    { ref: 'outbound_sla_policy_id' },
    { ref: 'settings' },
    { ref: 'mail_settings' },
    { ref: 'form_settings' },
    { ref: 'voice_settings' },
    { ref: '__dispositions' },
    { ref: '__canned_answers' },
    { ref: '__tags' },
    { ref: '__agent_signatures' },
    { ref: '__outbound_mail_accounts' },
    { ref: '__outbound_voice_routes' }
  ]
});

export const QueueAddForm = (queue: ISharedCreateQueue, props: IQueueFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'queue_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'create_list_id' },
    { ref: 'read_lists' },
    { ref: 'clients' },
    { ref: 'conversation_form_id' },
    { ref: 'inbound_sla_policy_id' },
    { ref: 'outbound_sla_policy_id' },
    { ref: 'settings' },
    { ref: 'mail_settings' },
    { ref: 'form_settings' },
    { ref: 'voice_settings' },
    { ref: '__dispositions' },
    { ref: '__canned_answers' },
    { ref: '__tags' },
    { ref: '__agent_signatures' },
    { ref: '__outbound_mail_accounts' },
    { ref: '__outbound_voice_routes' }
  ]
});

export const QueueAddInitialValues: ISharedCreateQueue = {} as ISharedCreateQueue;

export const QueueEditInitialValues = (queue: ISharedQueue): ISharedUpdateQueue => ({
  title: queue.title,
  create_list_id: queue.create_list_id,
  read_lists: queue.read_lists,
  clients: queue.clients,
  conversation_form_id: queue.conversation_form_id,
  inbound_sla_policy_id: queue.inbound_sla_policy_id,
  outbound_sla_policy_id: queue.outbound_sla_policy_id,
  settings: queue.settings,
  mail_settings: queue.mail_settings,
  form_settings: queue.form_settings,
  voice_settings: queue.voice_settings,
  __dispositions: queue.__dispositions,
  __canned_answers: queue.__canned_answers,
  __tags: queue.__tags,
  __agent_signatures: queue.__agent_signatures,
  __outbound_mail_accounts: queue.__outbound_mail_accounts,
  __outbound_voice_routes: queue.__outbound_voice_routes
});

export const onQueueFormSaved = (t: TFunction, action: 'edit' | 'add', queue: ISharedQueue, navigate: NavigateFunction): void => {
  navigate(onFormSaved('queues'));
};
