import { ISharedUser, Undefinable } from 'atlas-shared';
import SipUsersWrapper from './sip-users-wrapper';

export let sipUsersWrapper: Undefinable<SipUsersWrapper>;

export const initiateSipUsersWrapper = (user: ISharedUser, dispatch) => {
  if (!sipUsersWrapper)
    sipUsersWrapper = new SipUsersWrapper(user, dispatch);

  return sipUsersWrapper;
};

export * from './utils';
export * from './interface';
export * from './sip';
export * from './delegate';
export * from './options';
export * from './sip.container';
