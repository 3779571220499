import { ListFileActions } from '@Store';
import { IPreviewListFile } from 'atlas-shared';
import { fetchListFiles } from '@Api';

export const actionFetchListFiles = () => {
  return async (dispatch) => {
    dispatch(actionLoadingListFiles());
    const list_files = await fetchListFiles();

    dispatch(actionSetListFiles(list_files));
    return list_files;
  };
};

export const actionLoadingListFiles = () => ({
  type: ListFileActions.LOADING,
  payload: []
});

export const actionSetListFiles = (payload: Array<IPreviewListFile>) => ({
  type: ListFileActions.SET_STATE,
  payload: payload
});

export const actionAddListFile = (payload: IPreviewListFile) => ({
  type: ListFileActions.ADD_STATE,
  payload: payload
});

export const actionUpdateListFile = (payload: IPreviewListFile) => ({
  type: ListFileActions.UPDATE_STATE,
  payload: payload
});
