import { RestRequest } from '@Utils';
import { IPreviewConversationFavourite, ISharedConversationFavourite, ISharedCreateConversationFavourite, ISharedPatchConversationFavourite } from 'atlas-shared';

export const fetchConversationFavourites = async (): Promise<Array<IPreviewConversationFavourite>> => {
  return RestRequest.get<Array<IPreviewConversationFavourite>>('conversation_favourite', { socket_id: global.socketId });
};

export const createConversationFavourite = (payload: ISharedCreateConversationFavourite) => {
  return RestRequest.post<any, ISharedConversationFavourite>('conversation_favourite', payload, { socket_id: global.socketId });
};

export const patchConversationFavourite = (id: IPreviewConversationFavourite['id'], payload: ISharedPatchConversationFavourite) => {
  return RestRequest.patch<any, ISharedConversationFavourite>(`conversation_favourite/${id}`, payload, { socket_id: global.socketId });
};

export const deleteConversationFavourite = (id: IPreviewConversationFavourite['id']) => {
  return RestRequest.delete(`conversation_favourite/${id}`, { socket_id: global.socketId });
};

export const getConversationFavouriteCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation_favourite/create/schema');
};

export const getConversationFavouritePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation_favourite/patch/schema');
};
