import React, { useEffect, useState } from 'react';
import './conversation.timeline.scss';
import {
  IConversationTimeline,
  ISharedFullConversation
} from 'atlas-shared';
import { TFunction } from 'i18next';
import { useDispositions, useQueues, useUsers, useVoiceRoutes } from '@Hooks';
import { ITimelineEvent, Timeline, translateConversationTimelineEvent } from '@Components/timeline';

interface IProps {
  t: TFunction;
  conversation: ISharedFullConversation;
  timeline: Array<IConversationTimeline>;
  onClose?: () => void;
}

export const ConversationTimeline = React.memo(({ t, conversation, timeline, onClose }: IProps) => {

  const users = useUsers();
  const queues = useQueues();
  const voice_routes = useVoiceRoutes();
  const dispositions = useDispositions();

  const [events, setEvents] = useState<Array<{translated: ITimelineEvent} & IConversationTimeline>>([]);

  useEffect(() => {
    const main_journey_id = timeline.find(event => event.journey_id)?.journey_id;

    setEvents(timeline.map(event => ({
      ...event,
      dt: new Date(event.dt),
      translated: translateConversationTimelineEvent(
        t,
        event,
        {
          users,
          queues,
          voice_routes,
          dispositions
        },
        main_journey_id
      )
    })));

  }, [timeline]);

  return <Timeline events={events} />;
});
