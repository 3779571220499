import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const TrendingUpIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
    <polyline points="17 6 23 6 23 12"></polyline>
  </SvgIcon>;
};

export const TrendingDownIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="23 18 13.5 8.5 8.5 13.5 1 6"></polyline>
    <polyline points="17 18 23 18 23 12"></polyline>
  </SvgIcon>;
};
