import { StatsWidgetMetricData } from '../../../../stats_widget.metric.data';
import React from 'react';
import { IWithStatsWidgetProps } from '../../../../../../../hocs/with.stats_widget';
import {
  IMetric,
  IPreviewStatsCustomMetric,
  IPreviewStatsWidget,
  IStatsWidgetDataTableMinifiedRow,
  IPreviewStatsWidgetSettingsMetricProperty, IPreviewOrganization
} from 'atlas-shared/dist';

interface IProps {
  t: IWithStatsWidgetProps['t'];
  organization: IPreviewOrganization;
  stats_widget: IPreviewStatsWidget;
  metric_data_dicts: IWithStatsWidgetProps['metric_data_dicts'];
  metric: IMetric | IPreviewStatsCustomMetric;
  metric_settings: IPreviewStatsWidgetSettingsMetricProperty;
  value: any;
  trend_value: any;
  trend_comparison: any;
  trend_period?: IStatsWidgetDataTableMinifiedRow['tp'];
}
export const WidgetTypeTableTrTd = React.memo((props: IProps) => {
  const { stats_widget, t: _t, metric_settings, metric_data_dicts, metric, value, trend_value, trend_comparison, trend_period, organization } = props;

  return <td>
    <StatsWidgetMetricData
      t={_t}
      organization={organization}
      stats_widget={stats_widget}
      metric_settings={metric_settings}
      metric={metric}
      value={value}
      trend_value={trend_value}
      trend_comparison={trend_comparison}
      trend_period={trend_period}
      metric_data_dicts={metric_data_dicts}
    />
  </td>;
}, (prevProps, nextProps) => (
  !['value', 'trend_value'].some(k => prevProps[k] !== nextProps[k])
    && JSON.stringify(prevProps.metric_settings || {}) === JSON.stringify(nextProps.metric_settings || {})
  && prevProps.stats_widget.settings?.trend?.text_indicator === nextProps.stats_widget.settings?.trend?.text_indicator
));
