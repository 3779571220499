import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const WidgetTextIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g>
      <path fill="#444" fillRule="nonzero" stroke="none" d="M 13.125 7.875 L 13.125 16.5 C 13.125 16.914062 12.789062 17.25 12.375 17.25 C 11.960938 17.25 11.625 16.914062 11.625 16.5 L 11.625 9.273438 L 10.539062 10.003906 C 10.191406 10.230469 9.726562 10.132812 9.496094 9.789062 C 9.269531 9.441406 9.367188 8.976562 9.710938 8.746094 L 11.960938 7.246094 C 12.195312 7.101562 12.484375 7.085938 12.730469 7.210938 C 12.976562 7.339844 13.128906 7.597656 13.125 7.875 Z M 21 4.5 L 21 19.5 C 21 20.328125 20.328125 21 19.5 21 L 4.5 21 C 3.671875 21 3 20.328125 3 19.5 L 3 4.5 C 3 3.671875 3.671875 3 4.5 3 L 19.5 3 C 20.328125 3 21 3.671875 21 4.5 Z M 19.5 19.5 L 19.5 4.5 L 4.5 4.5 L 4.5 19.5 Z M 19.5 19.5 "/>
    </g>
  </SvgIcon>;
};
