import { IPreviewPhonenumberSetupCostParent } from 'atlas-shared';

export enum PhonenumberSetupCostParentActions {
  SET_STATE = 'PHONENUMBER_SETUP_COST_PARENT_SET_STATE',
  ADD_STATE = 'PHONENUMBER_SETUP_COST_PARENT_ADD_STATE',
  REMOVE_STATE = 'PHONENUMBER_SETUP_COST_PARENT_REMOVE_STATE',
  GET_STATE = 'PHONENUMBER_SETUP_COST_PARENT_GET_STATE',
  UPDATE_STATE = 'PHONENUMBER_SETUP_COST_PARENT_UPDATE_STATE',
  LOADING = 'PHONENUMBER_SETUP_COST_PARENT_LOADING',
}

export interface IPhonenumberSetupCostParentLoading {
  type: PhonenumberSetupCostParentActions.LOADING;
}

export interface IPhonenumberSetupCostParentSetAction {
  type: PhonenumberSetupCostParentActions.SET_STATE;
  payload: Array<IPreviewPhonenumberSetupCostParent>;
}

export interface IPhonenumberSetupCostParentUpdateAction {
  type: PhonenumberSetupCostParentActions.UPDATE_STATE;
  payload: IPreviewPhonenumberSetupCostParent;
}

export interface IPhonenumberSetupCostParentAddAction {
  type: PhonenumberSetupCostParentActions.ADD_STATE;
  payload: IPreviewPhonenumberSetupCostParent;
}

export interface IPhonenumberSetupCostParentRemoveAction {
  type: PhonenumberSetupCostParentActions.REMOVE_STATE;
  payload: IPreviewPhonenumberSetupCostParent['id'];
}

export interface IPhonenumberSetupCostParentStore {
  loaded: boolean;
  loading: boolean;
  phonenumber_setup_cost_parents: Array<IPreviewPhonenumberSetupCostParent>;
  dict: Record<IPreviewPhonenumberSetupCostParent['id'], IPreviewPhonenumberSetupCostParent>;
}

export type TPhonenumberSetupCostParentActionTypes = IPhonenumberSetupCostParentSetAction | IPhonenumberSetupCostParentUpdateAction | IPhonenumberSetupCostParentAddAction | IPhonenumberSetupCostParentRemoveAction | IPhonenumberSetupCostParentLoading;
