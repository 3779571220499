import { IPreviewJourneyVariable } from 'atlas-shared';
import { IJourneyVariableStore, JourneyVariableActions, TJourneyVariableActionTypes } from '@Store';

export const JourneyVariableReducer = (state: IJourneyVariableStore = {
  loaded: false,
  loading: false,
  journey_variables: [],
  dict: {}
}, action: TJourneyVariableActionTypes): IJourneyVariableStore => {
  switch (action.type) {
  case JourneyVariableActions.LOADING:
    return { ...state, loading: true };
  case JourneyVariableActions.SET_STATE:
    const dict: IJourneyVariableStore['dict'] = {};

    action.payload.forEach((journey_variable: IPreviewJourneyVariable) => dict[journey_variable.id] = journey_variable);

    return { ...state, loaded: true, loading: false, journey_variables: action.payload, dict };
  case JourneyVariableActions.ADD_STATE:
    return { ...state, journey_variables: [...state.journey_variables, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case JourneyVariableActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, journey_variables: state.journey_variables.filter((item: IPreviewJourneyVariable) => item.id !== action.payload), dict };
  }
  case JourneyVariableActions.UPDATE_STATE:
    const journey_variables = [...state.journey_variables];
    const index = state.journey_variables.findIndex((journey_variable: IPreviewJourneyVariable) => journey_variable.id === action.payload.id);

    if (!~index)
      journey_variables.push(action.payload);
    else
      journey_variables[index] = action.payload;

    return { ...state, journey_variables, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
