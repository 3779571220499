import { IForm } from 'atlas-form';
import { FormComponentInterval } from './components/interval';
import { ISharedCreateTimeInterval } from 'atlas-shared';

export * from './abstract/time_interval.form.abstract';

export const TimeIntervalComponents: IForm['components'] = {
  interval: FormComponentInterval
};

export const TimeIntervalAddInitialValues: ISharedCreateTimeInterval = {
  interval: '*,*,*,*'
} as ISharedCreateTimeInterval;
