import { useDispositions } from '@Hooks';
import React, { useEffect, useState } from 'react';
import { ArchiveIcon } from '@Assets/icons';
import { IPreviewConversation, IPreviewDisposition } from 'atlas-shared';
import { IconText } from '@Components';

interface IProps {
  conversation: IPreviewConversation;
  long?: boolean;
}

export const ConversationDisposition = React.memo(({ conversation, long }: IProps) => {
  const dispositions = useDispositions();

  const [disposition, setDisposition] = useState<IPreviewDisposition>();
  const [parents, setParents] = useState<Array<IPreviewDisposition>>([]);
  const [tree, setTree] = useState<string>('');

  useEffect(() => {
    if (!conversation.disposition_id)
      return;

    const disposition = dispositions.dict[conversation.thrd_disposition_id || conversation.sub_disposition_id || conversation.disposition_id];

    setDisposition(disposition);
    setParents(parents.reverse());
    setTree(([conversation.disposition_id, conversation.sub_disposition_id, conversation.thrd_disposition_id].filter(Boolean) as Array<IPreviewDisposition['id']>).map(disposition_id => dispositions.dict[disposition_id]).map(disposition => disposition.title).join(' -> '));
  }, [conversation.disposition_id, dispositions]);

  return (
    <div className={'id-wrapper disposition-wrapper'}>
      { disposition && <IconText icon={ArchiveIcon} tooltip={{ title: tree }} className={'icon-style'} iconProps={{ className:'icon-props', size: 10 }} textProps={{ className:'icon-text-props' }} text={long ? tree : disposition.title}/> }
    </div>
  );
});
