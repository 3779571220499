export const getTextWidth = (text, font: string = '12px Manrope') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context!.font = font;

  return context!.measureText(text).width;
};

export const ellipsis = (str: any, maxLength: number) => {
  if (!str)
    return str;

  return str.length - 3 > maxLength ? `${str.substr(0, maxLength)}...` : str;
};

export const stringWidth = (str: string, small: number = 3, medium: number = 7, large: number = 11): number => {
  const charMap = {
    i: small,
    ' ': small,
    I: small,
    l: small,
    M: large,
    m: large,
    w: large,
    W: large
  };

  return str
    .split('')
    .reduce(
      (total, char) => (charMap[char] || (char === char.toUpperCase() ? medium + 3 : medium)) + total,
      0
    );
};

export const stringToAlias = (str: string) => {
  return str.replaceAll(' ', '.').replace(/[^a-z0-9]/gmi, '_');
};
