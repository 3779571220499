import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const PhoneIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path
      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>

  </SvgIcon>;
};

export const PhoneOutgoingIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props} style={{ color: 'var(--red-main)', ...(props.style || {}) }}>
    <polyline points="23 7 23 1 17 1"></polyline>
    <line x1="16" y1="8" x2="23" y2="1"></line>
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </SvgIcon>;
};

export const PhoneIncomingIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props} style={{ color: 'var(--green-main)', ...(props.style || {}) }}>
    <polyline points="16 2 16 8 22 8"></polyline>
    <line x1="23" y1="1" x2="16" y2="8"></line>
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  
  </SvgIcon>;
};

export const PhoneInternalIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props} style={{ color: 'var(--green-main)', ...(props.style || {}) }}>
    <polyline points="14 4 14 10 20 10"></polyline>
    <line x1="23" y1="1" x2="15" y2="9"></line>
    <polyline points="23 7 23 1 17 1"></polyline>
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>

  </SvgIcon>;
};

export const PhoneMissedIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props} style={{ color: 'var(--green-main)', ...(props.style || {}) }}>
    <line x1="23" y1="1" x2="17" y2="7"></line>
    <line x1="17" y1="1" x2="23" y2="7"></line>
    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </SvgIcon>;
};

export const PhoneTransferIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props} style={{ color: 'var(--green-main)', ...(props.style || {}) }}>
    <path d="M22 11.5674V7.56738H18" />
    <path d="M12 1.56738L12 5.56738L16 5.56738" />
    <path d="M22 7.56738L18.5 11.0674L17.625 11.9424" />
    <path d="M12 5.56738L15.5 2.06738L16.375 1.19238" />
    <path d="M21 18.4875V21.4875C21.0011 21.766 20.9441 22.0416 20.8325 22.2968C20.7209 22.552 20.5573 22.7811 20.3521 22.9693C20.1468 23.1576 19.9046 23.301 19.6407 23.3902C19.3769 23.4794 19.0974 23.5125 18.82 23.4875C15.7428 23.1531 12.787 22.1016 10.19 20.4175C7.77382 18.8821 5.72533 16.8337 4.18999 14.4175C2.49997 11.8087 1.44824 8.83847 1.11999 5.74748C1.095 5.47095 1.12787 5.19224 1.21649 4.9291C1.30512 4.66597 1.44756 4.42417 1.63476 4.2191C1.82196 4.01403 2.0498 3.85019 2.30379 3.738C2.55777 3.62581 2.83233 3.56774 3.10999 3.56748H6.10999C6.5953 3.5627 7.06579 3.73456 7.43376 4.05101C7.80173 4.36747 8.04207 4.80693 8.10999 5.28748C8.23662 6.24755 8.47144 7.19021 8.80999 8.09748C8.94454 8.4554 8.97366 8.84439 8.8939 9.21836C8.81415 9.59233 8.62886 9.93559 8.35999 10.2075L7.08999 11.4775C8.51355 13.981 10.5864 16.0539 13.09 17.4775L14.36 16.2075C14.6319 15.9386 14.9751 15.7533 15.3491 15.6736C15.7231 15.5938 16.1121 15.6229 16.47 15.7575C17.3773 16.096 18.3199 16.3309 19.28 16.4575C19.7658 16.526 20.2094 16.7707 20.5265 17.145C20.8437 17.5193 21.0122 17.9971 21 18.4875Z" />
  </SvgIcon>;
};

export const PhoneCallIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </SvgIcon>;
};

export const PhoneCallLiveIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
  </SvgIcon>;
};
