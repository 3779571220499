import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateApiKey, ISharedUpdateApiKey, ISharedApiKey, IPreviewOrganization, IPreviewUser, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IApiKeyFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  users: Array<IPreviewUser>;
}

export const ApiKeyFormOptions = (api_key: ISharedCreateApiKey | ISharedApiKey, props: IApiKeyFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.ApiKey, {})), 'organization_id', false), 'organization'),
  user_id: (it: ISharedApiKey) => filterRelated<IPreviewUser>(it.organization_id || api_key.organization_id, 'user', 'api_key', props.users, true),
  
});

export const ApiKeyComponents: IForm['components'] = {};
export const ApiKeyAdditionalParams: (auth: IAuth, props: IApiKeyFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IApiKeyFormOptionsProps) => ({
});

export const ApiKeyEditForm = (api_key: ISharedApiKey, props: IApiKeyFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `api_key_edit_${api_key.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === api_key.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'active' },
    { ref: 'user_id' }
  ]
});

export const ApiKeyAddForm = (api_key: ISharedCreateApiKey, props: IApiKeyFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'api_key_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'active' },
    { ref: 'user_id' }
  ]
});

export const ApiKeyAddInitialValues: ISharedCreateApiKey = {} as ISharedCreateApiKey;

export const ApiKeyEditInitialValues = (api_key: ISharedApiKey): ISharedUpdateApiKey => ({
  active: api_key.active,
  user_id: api_key.user_id
});

export const onApiKeyFormSaved = (t: TFunction, action: 'edit' | 'add', api_key: ISharedApiKey, navigate: NavigateFunction): void => {
  navigate(onFormSaved('api_keys'));
};
