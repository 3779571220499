import { IAuthResponse, IPersonifyResponse, IPreviewUser, ISharedUser, ISharedCreateUser } from 'atlas-shared';
import { RestRequest } from '@Utils/rest';

export * from './abstract/user.api.abstract';

export const resetTwoFactorAuthenticate = (user_id: ISharedUser['id']): Promise<IAuthResponse> => {
  return RestRequest.patch<{}, IAuthResponse>(`user/${user_id}/reset-two-factor`, { });
};

export const patchUserTwinningSettings = (user_id: ISharedUser['id'], twinning_settings: ISharedCreateUser['voice_settings']['twinning']): Promise<IAuthResponse> => {
  return RestRequest.patch<{}, IAuthResponse>(`user/${user_id}/twinning`, twinning_settings);
};

export const userTwinningSettingsSchema = (): Promise<any> => {
  return RestRequest.get<any>('user/twinning/schema');
};

export const apiMe = (): Promise<IAuthResponse> => {
  return RestRequest.get<IAuthResponse>('auth/me');
};

export const personifyUser = (user_id): Promise<IPersonifyResponse> => {
  return RestRequest.post<{user_id: IPreviewUser['id']}, IPersonifyResponse>('auth/personify', { user_id });
};

export const resetTwoFactorAuth = (user_id): Promise<IPreviewUser> => {
  return RestRequest.patch<{}, IPreviewUser>(`user/${user_id}/reset-two-factor`, {});
};

export const userForceLogout = (user_id): Promise<boolean> => {
  return RestRequest.post<{user_id: IPreviewUser['id']}, boolean>('auth/force_logout', { user_id });
};
