import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollView, Sider, StatsWidgetFormAddView, StatsWidgetFormEditView } from '@Components';
import { FormSelect, Nullable } from 'atlas-form';
import { createStatsReportPreviewWidget, createStatsReportWidget, fetchStatsWidget, setStatsReportActive } from '@Api';
import './stats_widget.form.sider.scss';
import {
  IPreviewStatsReport, IPreviewStatsReportPreviewWidget,
  IPreviewStatsReportWidget,
  IPreviewStatsWidget,
  ISharedCreateStatsReportWidget,
  ISharedCreateStatsWidget,
  ISharedStatsWidget,
  StatsWidgetPeriodEnum,
  Undefinable
} from 'atlas-shared/dist';
import { IAuth, IStatsWidgetStore } from '@Store';
import { StatsWidgetView } from '../widget-types/stats.widget.view';
import { TFunction } from 'i18next';
import { StatsWidgetPreviewData } from '../stats_widget.preview.data';
import { useUsers, useWindowSize } from '@Hooks';
import { StatsWidgetFormTemplate } from './stats_widget.form.template';
import { StatsWidgetFormExisting } from './stats_widget.form.existing';
import { StatsWidgetFormCreateOptions } from './stats_widget.form.create_options';
import { AlertError, clone } from '@Utils';

interface IProps {
  t: TFunction;
  auth: IAuth;
  report: IPreviewStatsReport;
  stats_widgets: IStatsWidgetStore;
  widgets: Array<IPreviewStatsReportWidget | IPreviewStatsReportPreviewWidget>;
  widgetForm: number;
  widgetClone?: number;
  setWidgetForm: Dispatch<SetStateAction<number>>;
  setWidgetClone?: Dispatch<SetStateAction<number>>;
  isPreview?: boolean;
  isPreviewDefault?: boolean;
}

export const StatsWidgetFormSider = ({ t, auth, report, widgetForm, widgetClone, setWidgetForm, setWidgetClone, stats_widgets, widgets, isPreview = false, isPreviewDefault = false }: IProps) => {
  const users = useUsers();
  const [values, setValues] = useState<IPreviewStatsWidget>({} as IPreviewStatsWidget);
  const [selectedExisting, setSelectedExisting] = useState<Undefinable<IPreviewStatsWidget['id']>>();
  const [selectedTemplate, setSelectedTemplate] = useState<Undefinable<Partial<ISharedCreateStatsWidget>>>();
  const loadingCloneItem = useRef(false);

  const getStatsReportWidgetSettings = useCallback((): ISharedCreateStatsReportWidget['settings'] => {
    const settings: ISharedCreateStatsReportWidget['settings'] = {};

    if (!report!.is_dashboard)
      settings.position = (widgets.length ? ((widgets[widgets.length - 1] as IPreviewStatsReportWidget).settings.position || 0) : 0) + 1;
    else {
      settings.dashboard_position = { x: 0, y: 20, w: 5, h: 8 };

      // const preview_widgets = widgets.filter((stats_report_widget) => 'position' in stats_report_widget.settings).sort((a, b) => (a.settings.position || 0) - (b.settings.position || 0));
      //
      // if (preview_widgets.length < 3)
      //   settings.position = (preview_widgets[preview_widgets.length - 1 ]?.settings.position || 0) + 1;
    }

    return settings;
  }, [report]);

  const [windowWidth] = useWindowSize();
  const [width, setWidth] = useState(4);
  const [height, setHeight] = useState(10);
  const widthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const heightOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  const gridWidth = windowWidth / 12;
  const gridHeight = 40;
  const demoWidget = useMemo(() => {
    const widget = selectedExisting ? stats_widgets.dict[selectedExisting] : selectedTemplate ? (selectedTemplate as unknown as IPreviewStatsWidget) : (values?.settings ? values : undefined);

    return widget ? clone(widget) : widget;
  }, [selectedExisting, selectedTemplate, values]);
  const [initialValues, setInitialValues] = useState<Nullable<ISharedCreateStatsWidget>>(null);
  const widgetFormTitle = useMemo(() => {
    if (widgetForm > 0)
      return t('EDIT_WIDGET');

    switch (widgetForm) {
    case -1:
      return t('SELECT_EXISTING');
    case -2:
      return t('ADD_WIDGET');
    case -3:
      return t('SELECT_TEMPLATE');
    }

  }, [widgetForm]);

  if (demoWidget && !demoWidget.organization_id && widgetForm > 0 && stats_widgets.dict[widgetForm])
    demoWidget.organization_id = stats_widgets.dict[widgetForm].organization_id;

  useEffect(() => {
    if (widgetClone && !initialValues && !loadingCloneItem.current) {
      loadingCloneItem.current = true;
      fetchStatsWidget(widgetClone).then(stats_widget => {
        const { organization_id, title, type, settings, shared } = stats_widget;

        setInitialValues({
          organization_id,
          title: `${title} (${t('CLONED')})`,
          type,
          settings,
          shared
        });
        loadingCloneItem.current = false;
      });
    }
  }, [loadingCloneItem, widgetClone, initialValues, setInitialValues]);

  const onCancel = useCallback(() => {
    setWidgetForm(0);
    setWidgetClone?.(0);
    setInitialValues(null);
  }, [setWidgetForm, setWidgetClone]);

  return <Sider onClose={onCancel} classNames={['stats-form-sider']}>
    <div className='side-by-side' style={{ display: 'flex' }}>
      <ScrollView style={[-1, -3].includes(widgetForm) ? { display: 'flex', flexDirection: 'column' } : {}}>
        <h2>{widgetFormTitle}</h2>
        {widgetForm < 0 && <StatsWidgetFormCreateOptions
          t={t}
          widgetForm={widgetForm}
          setWidgetForm={setWidgetForm}
          stats_widgets={stats_widgets}
          is_dashboard={report.is_dashboard}
        />}
        {widgetForm === -3 && <StatsWidgetFormTemplate
          t={t}
          auth={auth}
          users={users}
          report={report}
          selectedTemplate={selectedTemplate}
          onSelect={item => setSelectedTemplate(item)}
          onUseTemplate={() => {
            setInitialValues(selectedTemplate as ISharedCreateStatsWidget);
            setSelectedTemplate(undefined);
            setWidgetForm(-2);
          }}
        />}
        {widgetForm === -1 && <StatsWidgetFormExisting
          t={t}
          auth={auth}
          users={users}
          report={report}
          stats_widgets={stats_widgets}
          selectedExisting={selectedExisting}
          getStatsReportWidgetSettings={getStatsReportWidgetSettings}
          onCancel={onCancel}
          onSelect={(item) => setSelectedExisting(item)}
          isPreview={isPreview}
          isPreviewDefault={isPreviewDefault}
          widgets={widgets}
          setWidgetClone={setWidgetClone}
        />
        }
        {(widgetForm === -2 || (!!widgetClone && initialValues)) && <StatsWidgetFormAddView
          onCancel={onCancel}
          onSaved={(item: ISharedStatsWidget) => {
            if (isPreview)
              createStatsReportPreviewWidget({
                stats_report_id: report.id,
                stats_widget_id: item.id,
                settings: { dashboard_position: { h: 6, y: widgets.length + 1 } },
                is_default: !!isPreviewDefault
              })
                .then(() => setStatsReportActive(report.id, null, true))
                .catch(err => {
                  AlertError(t, {
                    content: err
                  });
                });
            else
              createStatsReportWidget({
                stats_report_id: report.id,
                stats_widget_id: item.id,
                settings: getStatsReportWidgetSettings()
              }).then(() => {
                if (item.settings.period.type === StatsWidgetPeriodEnum.LIVE_TODAY && report?.is_dashboard)
                  setStatsReportActive(report.id, null, false);
              });

            onCancel();
          }}
          onChange={(values, changedValue, form) => setValues(form?.is_valid ? values : undefined)}
          hasAdvanced={true}
          initialValues={initialValues}
        />}
        {widgetForm > 0 && <StatsWidgetFormEditView
          id={widgetForm}
          onCancel={onCancel}
          onSaved={(item: ISharedStatsWidget) => {
            setWidgetForm(0);
          }}
          onChange={(values, changedValue, form) => setValues(form?.is_valid ? values : undefined)}
          hasAdvanced={true}
        />}
      </ScrollView>
      <div className='preview'>
        <div className='controllers'>
          <label>{t('WIDTH')}</label>
          <FormSelect value={width} options={widthOptions.map(s => ({ key: s, title: s + '' }))} placeholder={t('WIDTH')} onChange={v => setWidth(v)} />
          <label>{t('HEIGHT')}</label>
          <FormSelect value={height} options={heightOptions.map(s => ({ key: s, title: s + '' }))} placeholder={t('HEIGHT')} onChange={v => setHeight(v)} />
        </div>
        <div className='grids'>
          <div className='lines'>
            {widthOptions.map((g, i) => <div className='line line-x' style={{ left: i * gridWidth }}></div>)}
            {heightOptions.map((g, i) => <div className='line line-y' style={{ top: i * gridHeight }}></div>)}
          </div>
        </div>
        <div className='display-view' style={{ width: `${(gridWidth * width)}px`, height: `${(gridHeight * height) - 10}px` }}>
          {demoWidget && <StatsWidgetView
            t={t}
            auth={auth}
            report={report}
            widgets={[]}
            is_preview={false}
            stats_report_widget={{ stats_widget_id: 0 } as IPreviewStatsReportWidget}
            index={0}
            width={gridWidth * width}
            height={height ? (gridHeight * height) + 10 : undefined}
            is_demo={true}
            is_print={false}
            demo_data={demoWidget ? {
              // @ts-ignore
              id: -1,
              ...demoWidget,
              data: StatsWidgetPreviewData(demoWidget)
            } as any : selectedExisting ? stats_widgets.dict[selectedExisting] || undefined : undefined}
          />}
        </div>
      </div>
    </div>
  </Sider>;
};
