import React, { MouseEvent, useState } from 'react';
import Text from 'antd/es/typography/Text';
import { Icon, IIconProps } from './icon';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Tooltip } from 'antd';

interface IProps {
  icon: any;
  text: string | React.ReactNode;
  textProps?: Record<string, any>;
  iconProps?: Record<string, any>;
  style?: any;
  className?: string;
  onClick?: (e?: MouseEvent<HTMLElement>) => any;
  iconCircle?: boolean;
  iconWrapperStyle?: IIconProps['wrapperStyle'];
  loading?: boolean;
  tooltip?: {
    placement?: TooltipPlacement;
    title: string | React.ReactNode;
    style?: React.CSSProperties;
  };
  postFix?: React.ReactNode;
  ellipsis?: number | false;
  disabled?: boolean;
}

export const IconText = React.memo((props: IProps) => {

  const { text, textProps = { }, iconProps, icon, style: _style, className, onClick, iconCircle = false, postFix, ellipsis = false, disabled = false } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const isLoading = props.loading || loading;
  const style = { ..._style };
  let tooltip = props.tooltip;

  if (ellipsis) {
    if (!textProps.style)
      textProps.style = {};

    textProps.style.maxWidth = ellipsis;
  }

  if (!tooltip && ellipsis)
    tooltip = { title: text };

  if (onClick)
    style.cursor = 'pointer';

  // if (!textProps.style)
  //   textProps.style = { lineHeight: `${iconProps?.size ? iconProps.size * 1.4 : 16}px` };

  const elm = <span
    className={`icon-text-wrapper${disabled ? ' disabled' : ''}${className ? ` ${className}` : ''}`}
    onClick={(e) => {
      if (!props.onClick || isLoading)
        return;

      setLoading(true);
      const response = props.onClick?.(e);

      if (response?.finally)
        response.finally(() => setLoading(false));
      else
        setLoading(false);
    }}
    style={{ ...style }}
  >
    <Icon icon={icon}
      loading={isLoading}
      iconProps={iconProps}
      wrapperStyle={props.iconWrapperStyle}
      circle={iconCircle}
    />
    { text && <Text className='text' {...textProps}>{text}</Text> }
    { text && postFix && <Text className='postfix'>{postFix}</Text> }
  </span>;

  if (tooltip)
    return <Tooltip placement={tooltip.placement || 'top'} title={tooltip.title}>{elm}</Tooltip>;

  return elm;
});
