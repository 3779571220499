import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchUsers, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useUsers = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const users = useSelector((store: IReducerStore) => store.users);

  useEffect(() => {
    if (!initiated && autoload && !users.loaded && !users.loading) {
      initiated = true;
      dispatch(actionFetchUsers());
    }
  }, [autoload, users.loaded, users.loading, dispatch]);

  return users;
};
