import { SessionActions } from '@Store';
import { IPreviewSession } from 'atlas-shared';
import { fetchSessions } from '@Api';

export const actionFetchSessions = () => {
  return async (dispatch) => {
    dispatch(actionLoadingSessions());
    const sessions = await fetchSessions();

    dispatch(actionSetSessions(sessions));
    return sessions;
  };
};

export const actionLoadingSessions = () => ({
  type: SessionActions.LOADING,
  payload: []
});

export const actionSetSessions = (payload: Array<IPreviewSession>) => ({
  type: SessionActions.SET_STATE,
  payload: payload
});

export const actionAddSession = (payload: IPreviewSession) => ({
  type: SessionActions.ADD_STATE,
  payload: payload
});

export const actionUpdateSession = (payload: IPreviewSession) => ({
  type: SessionActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveSession = (id: IPreviewSession['id']) => ({
  type: SessionActions.REMOVE_STATE,
  payload: id
});
