import { SlaPolicyActions } from '@Store';
import { IPreviewSlaPolicy } from 'atlas-shared';
import { fetchSlaPolicies } from '@Api';

export const actionFetchSlaPolicies = () => {
  return async (dispatch) => {
    dispatch(actionLoadingSlaPolicies());
    const sla_policies = await fetchSlaPolicies();

    dispatch(actionSetSlaPolicies(sla_policies));
    return sla_policies;
  };
};

export const actionLoadingSlaPolicies = () => ({
  type: SlaPolicyActions.LOADING,
  payload: []
});

export const actionSetSlaPolicies = (payload: Array<IPreviewSlaPolicy>) => ({
  type: SlaPolicyActions.SET_STATE,
  payload: payload
});

export const actionAddSlaPolicy = (payload: IPreviewSlaPolicy) => ({
  type: SlaPolicyActions.ADD_STATE,
  payload: payload
});

export const actionUpdateSlaPolicy = (payload: IPreviewSlaPolicy) => ({
  type: SlaPolicyActions.UPDATE_STATE,
  payload: payload
});
