import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AtlasForm, FormElementTypes, FormLayoutTypes } from 'atlas-form';
import { fromJson } from 'json-joi-converter';
import {
  AlertError,
  entitiesToOptions,
  filterRelated,
  IOption,
  isEmailValid,
  nestedArray,
  RestRequest
} from '@Utils';
import { useTranslation } from 'react-i18next';
import {
  useAgentprofiles,
  useAuth,
  useCannedAnswers,
  useMailAccounts,
  useQueues,
  useOrganizations,
  useSignatures,
  useTags,
  useUsers, useClients
} from '@Hooks';
import {
  ChannelEnum,
  ConversationPriorityEnum,
  EnumToArray,
  getCreateChannels,
  IPreviewAgentprofile,
  IPreviewContact,
  IPreviewMailAccount,
  IPreviewQueue,
  ISharedContact,
  ISharedCreateConversation,
  SignatureTypeEnum,
  Undefinable,
  IPreviewOrganization,
  ISharedDraft,
  ISharedCreateDraft,
  DraftChannelEnum,
  ISharedOrganization,
  ISharedQueue
} from 'atlas-shared';
import { searchContact } from '@Api/search.api';
import {
  createDraft,
  deleteDraftAttachment,
  downloadDraftAttachment,
  fetchContact,
  fetchDraft,
  fetchGlobalDrafts,
  patchDraft
  , createConversation, getConversationCreateSchema } from '@Api';
import { FormComponentContact } from '@Utils/forms/components/contact';

import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch } from '@Store';

interface IProps {
  channel?: ChannelEnum;
  contact_id?: ISharedContact['id'];
  contact_organization_id?: ISharedContact['organization_id'];
  queue_id?: string;
  organization_id?: string;
  close?: () => void;
}

export const CreateConversation = React.memo(({ channel, contact_id, queue_id: str_queue_id, organization_id: str_organization_id, close }: IProps) => {

  const dispatch = useAppDispatch();
  const [organization_id, set_organization_id] = useState<Undefinable<ISharedOrganization['id']>>(str_organization_id ? +str_organization_id : undefined);
  const [queue_id, set_queue_id] = useState<Undefinable<ISharedQueue['id']>>(str_queue_id ? +str_queue_id : undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const agentprofiles = useAgentprofiles();
  const canned_answers = useCannedAnswers();
  const mail_accounts = useMailAccounts();
  const queues = useQueues();
  const organizations = useOrganizations();
  const users = useUsers();
  const signatures = useSignatures();
  const tags = useTags();
  const clients = useClients();
  const [channels, setChannels] = useState<Array<ChannelEnum>>([]);
  const [organizationOptions, setOrganizationOptions] = useState<Array<IOption>>([]);
  const [agentprofile, setAgentprofile] = useState<IPreviewAgentprofile>();
  const [queue, setQueue] = useState<Undefinable<IPreviewQueue>>();
  const [contact, setContact] = useState<IPreviewContact>();
  const [values, setValues] = useState<Partial<ISharedCreateConversation>>({});
  const [schema, setSchema] = useState();
  const [queueOptions, setQueueOptions] = useState<Array<IOption>>([]);
  const [clientOptions, setClientOptions] = useState<Array<IOption>>([]);
  const [signatureOptions, setSignatureOptions] = useState<Array<IOption>>([]);
  const [tagOptions, setTagOptions] = useState<Array<IOption>>([]);
  const [emailOptions, setEmailOptions] = useState<Array<IOption>>([]);
  const channelOptions = useMemo<Array<IOption>>(() => channels.map(channel => ({ key: channel, title: t(`CHANNEL_${channel.toUpperCase()}`) })), [channels]);
  const priorityOptions = useMemo<Array<IOption>>(() => EnumToArray(ConversationPriorityEnum).map(([v, k]) => ({ key: v, title: t(`PRIORITY_${k.toUpperCase()}`) })), []);
  const [mailAccountOptions, setMailAccountOptions] = useState<Array<IOption>>([]);
  const typing_started_at = useMemo(() => new Date(), []);
  const [initialValues, setInitialValues] = useState<Partial<ISharedCreateConversation>>({
    main_channel: channel || ChannelEnum.Mail,
    contact_id,
    user_id: auth.user.id,
    client_id: null
  });
  const [draft, setDraft] = useState<ISharedDraft>();

  const importFromDraft = useCallback((draft: ISharedDraft) => {
    const { body, subject: title = '' } = draft;

    setInitialValues({
      ...initialValues,
      body,
      title
    });
  }, [initialValues]);

  const reloadDraft = useCallback(() => {
    if (draft?.id)
      fetchDraft(draft.id).then(draft => setDraft(draft));
  }, [draft]);

  useEffect(() => {
    if (values.organization_id && !draft?.id)
      fetchGlobalDrafts(values.organization_id)
        .then(drafts => {
          if (!drafts.length) {
            createDraft({
              organization_id: values.organization_id as ISharedCreateDraft['organization_id'],
              channel: (values.main_channel as unknown as DraftChannelEnum) || DraftChannelEnum.Mail,
              user_id: auth.user.id,
              body: '',
              reply_to: null,
              reply_to_address: 'tmp@tmp.com'
            }).then(draft => {
              setDraft(draft);
              importFromDraft(draft);
            });
          }
          else {
            const last_draft = drafts[drafts.length - 1];

            setDraft(last_draft);
            importFromDraft(last_draft);
          }
        })
      ;
  }, [values.organization_id, auth]);

  useEffect(() => {
    if (draft && (draft.body !== values.body || draft.subject !== values.title))
      patchDraft(draft.id, { body: values.body, subject: values.title });
  }, [draft, values.body, values.title]);

  const dropProperty = useCallback((property: string) => {
    const merged = { ...mergedValues() };

    // delete merged[property];
    merged[property] = null;
    return merged;
  }, [initialValues, values]);

  const mergedValues = useCallback(() => {
    return { ...initialValues, ...values };
  }, [initialValues, values]);

  const dropIfRemoved = useCallback((options: Array<IOption>, key: string) => {
    if (values[key] && !options.some(option => option.key === values[key]))
      setInitialValues(dropProperty(key));
  }, [values]);

  useEffect(() => {
    setEmailOptions(contact ? contact.emails.map(email => ({ key: email, title: email })) : []);
  }, [contact]);

  useEffect(() => {
    const organizationOptions = entitiesToOptions<IPreviewOrganization>(nestedArray<any>(organizations.organizations, 'organization_id', false));

    setOrganizationOptions(organizationOptions);

    if (!values.organization_id && organization_id && organizationOptions.some(organizationOption => organizationOption.key === organization_id))
      setInitialValues({ ...mergedValues(), organization_id });

  }, [organizations.organizations, organization_id]);

  useEffect(() => {
    if (!organization_id && organizationOptions.length === 1)
      set_organization_id(organizationOptions[0].key as number);
  }, [organizationOptions, organization_id]);

  useEffect(() => {
    if (queue_id && !initialValues.queue_id)
      setInitialValues({ ...mergedValues(), queue_id });
  }, [queue_id]);

  useEffect(() => {
    if (!initialValues.queue_id) {
      if (queue_id)
        set_queue_id(queue_id);
      else {
        if (queueOptions.length === 1)
          set_queue_id(queueOptions[0].key as number);
      }
    }
  }, [queueOptions, queue_id]);

  useEffect(() => {
    if (!values.organization_id || !agentprofile || !queues.loaded)
      return;

    const queueOptions = values.organization_id ? queues.queues.filter(it => it.organization_id === values.organization_id && !!it.__outbound_mail_accounts.length && agentprofile.__queues.includes(it.id)).map(queue => ({
      key: queue.id,
      title: queue.title
    })) : [];

    setQueueOptions(queueOptions);
  }, [values.organization_id, values.queue_id, agentprofile, queues]);

  useEffect(() => {
    const clientOptions = values.queue_id && queue && queue.id === values.queue_id ? clients.clients.filter(it => queue.clients.includes(it.id)).map(client => ({
      key: client.id,
      title: client.title
    })) : [];

    setClientOptions(clientOptions);
  }, [values.queue_id, queue, clients]);

  useEffect(() => {
    dropIfRemoved(queueOptions, 'queue_id');
  }, [queueOptions, values]);

  useEffect(() => {
    if (values.organization_id && !values.queue_id && str_queue_id) {
      const queue_id = +str_queue_id;

      if (queueOptions.some(q => q.key === queue_id))
        setInitialValues({ ...values, queue_id });
    }
  }, [queueOptions, values]);

  useEffect(() => {
    if (!queue || !values.organization_id || !mail_accounts.loaded)
      return;

    const mailAccountOptions = !queue || !values.organization_id ? [] : filterRelated<IPreviewMailAccount>(values.organization_id, 'mail_account', 'conversation', mail_accounts.mail_accounts, true, it => queue.__outbound_mail_accounts.includes(it.id));

    setMailAccountOptions(mailAccountOptions);
  }, [queue, values.organization_id, mail_accounts]);

  useEffect(() => {
    dropIfRemoved(mailAccountOptions, 'mail_account_id');
  }, [mailAccountOptions, values]);

  useEffect(() => {
    if (!queue || !values.organization_id)
      return;

    const signatureOptions = queue && values.organization_id ? filterRelated(values.organization_id, 'signature', 'conversation', signatures.signatures.filter(signature => signature.type === SignatureTypeEnum.Agent && (signature.global || queue.__agent_signatures.includes(signature.id))), true) : [];

    setSignatureOptions(signatureOptions);
    dropIfRemoved(signatureOptions, 'signature_id');
  }, [signatures.signatures, values.organization_id, queue]);

  useEffect(() => {
    dropIfRemoved(signatureOptions, 'signature_id');
  }, [signatureOptions, values]);

  useEffect(() => {
    if (!queue || !values.organization_id)
      return;

    setTagOptions(queue && values.organization_id ? filterRelated(values.organization_id, 'tag', 'conversation', tags.tags.filter(tag => tag.global || queue.__tags.includes(tag.id)), true) : []);
  }, [tags.tags, values.organization_id, queue]);

  useEffect(() => {
    if (!auth)
      return;

    setChannels(getCreateChannels(auth.user));
  }, [auth]);

  // useEffect(() => {
  //   console.log('dropping values_changed', values);
  // }, [values]);

  useEffect(() => {
    getConversationCreateSchema().then(s => setSchema(s));
  }, []);

  useEffect(() => {
    if (!agentprofiles.loaded || !auth.user_status.agentprofile_id || agentprofile)
      return;

    const profile = agentprofiles.dict[auth.user_status.agentprofile_id];

    setAgentprofile(profile);
    // if (!initialValues.organization_id && !values.organization_id)
    //   setInitialValues({ ...mergedValues(), organization_id: profile.organization_id });
  }, [agentprofiles, auth.user_status.agentprofile_id, agentprofile]);

  useEffect(() => {
    if (values.queue_id !== queue?.id)
      setQueue(values.queue_id ? queues.dict[values.queue_id] : undefined);
  }, [values.queue_id, queue?.id, queues.dict]);

  useEffect(() => {

    if (agentprofile && queue && values?.contact_id && values.contact_id !== contact?.id)
      fetchContact(agentprofile?.organization_id, values.contact_id).then(c => {
        setContact(c);
        setTimeout(() => {
          setInitialValues({ ...mergedValues(), contact_email: c.emails?.[0] });
        }, 100);
      });

  }, [agentprofile, queue, values, contact?.id]);

  if (!channels.length)
    return <>{t('NO_CREATE_CHANNELS')}</>;

  if (!agentprofile)
    return <>{t('SELECT_AGENTPROFILE_ERROR')}</>;

  // if (!queueOptions.length)
  //   return <>{t('NO_OUTBOUND_QUEUES_ON_CREATE_CONVERSATION')}</>;

  if (!agentprofiles.loaded || !canned_answers.loaded || !queues.loaded || !mail_accounts.loaded || !organizations.loaded || !users.loaded || !tags.loaded || !signatures.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  if (!schema)
    return <>{t('LOADING_FORM')}</>;

  return <div className='create-conversation-form'>
    <AtlasForm
      dispatch={dispatch}
      formLayout={{
        id: 'CREATE_CONVERSATION',
        ui_layout: FormLayoutTypes.HORIZONTAL,
        labelWidth: '100px'
      }}
      title='CREATE_CONVERSATION'
      form={fromJson(schema)}
      initialValues={initialValues}
      additionalParams={{
        organization_id: { ui_type: FormElementTypes.TREE },
        contact_id: {
          ui_type: FormElementTypes.SELECT,
          onSearch: async (w: string) => entitiesToOptions((await searchContact(w, 10, [], auth.user_status.agentprofile_id, agentprofile?.organization_id)).items.map(({ item }) => item), 'contact') as Array<IOption>,
          nullable: false,
          hidden: !values.queue_id
        },
        contact_email: { hidden: !emailOptions.length || !values.queue_id || !contact },
        mail_account_id: { hidden: !values.queue_id || values.main_channel !== ChannelEnum.Mail },
        reply_to_cc: {
          mode: 'tags',
          hidden: !values.queue_id || values.main_channel !== ChannelEnum.Mail,
          tagsOptionValidation: isEmailValid
        },
        reply_to_bcc: {
          mode: 'tags',
          hidden: !values.queue_id || values.main_channel !== ChannelEnum.Mail,
          tagsOptionValidation: isEmailValid
        },
        user_id: { nullable: false, hidden: true },
        queue_id: (queue_id, { organization_id }) => ({ nullable: false, hidden: !organization_id }),
        client_id: (client_id, { organization_id, queue_id }) => ({ hidden: !queue_id || !clientOptions.length }),
        title: ({ title }) => ({ nullable: false, hidden: title === undefined }),
        body: ({ body }) => ({
          ui_type: FormElementTypes.HTML,
          hidden: body === undefined,
          hashOptions: ['contact', 'agent', 'conversation'],
          canned_answers: queue && canned_answers.canned_answers ? filterRelated(queue.organization_id, 'canned_answer', 'conversation', canned_answers.canned_answers) : undefined,
          file_upload: draft && {
            attachments: draft.attachments || [],
            action: RestRequest.getFullSrc(`/draft/${draft.id}/attachment`),
            headers: RestRequest.getHeaders(),
            onDelete: (uid: number) => deleteDraftAttachment(draft.id, uid),
            onDownload: (uid: number, name: string) => downloadDraftAttachment(draft.id, uid, name),
            onDownloadUri: (index: number) => `draft/${draft.id}/attachment/${index}`,
            onChange: (file) => file.status !== 'uploading' && reloadDraft(),
            dragAndDrop: true,
            draft_id: draft.id
          }
        }),
        main_channel: { label: t('CHANNEL') },
        typing_started_at: { hidden: true },
        signature_id: (_, { queue_id }) => ({ hidden: !queue_id }),
        priority: (_, { organization_id }) => ({ hidden: !organization_id }),
        __tags: (_, { queue_id }) => ({ hidden: !queue_id }),
      }}
      onValuesChange={(_, values) => setValues(values)}
      options={{
        organization_id: organizationOptions,
        //type: EnumToArray(ChannelEnum).filter(([v, k]) => [ChannelEnum.Mail].includes(v)).map(([v, k]) => ({ key: v, title: t(`CHANNEL_${k.toUpperCase()}`) })),
        //queue_id: entitiesToOptions(queues.queues, 'queue', it => agentprofile.__queues.includes(it.id)),
        queue_id: queueOptions,
        client_id: clientOptions,
        signature_id: signatureOptions,
        // user_id: organizationId && filterRelated<IPreviewUser>(organizationId, 'user', users.users.filter(u => isAgent(u)), true) || [],
        mail_account_id: mailAccountOptions,
        contact_email: emailOptions,
        priority: priorityOptions,
        main_channel: channelOptions,
        __tags: tagOptions,
        //contact_id: contacts
      }}
      components={{
        contact_id: FormComponentContact
      }}
      onCancel={close ? () => {
        close?.();
      } : undefined}
      onFinish={(values: ISharedCreateConversation) => {
        return new Promise((resolve) => {
          if (draft?.id)
            createConversation({
              ...values,
              typing_started_at: moment(typing_started_at).format()
            }, draft.id)
              .then(conversation => navigate(`/dashboard/conversation/${conversation.organization_id}/${conversation.id}`), error => AlertError(t, { content: error.toString() }))
              .finally(() => {
                resolve(true);
                close?.();
              })
            ;
        });
      }}
    />
  </div>;
});
