import React from 'react';
import { deleteJourney } from '@Api';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewJourney, PermissionEnum, TJourneySortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const JourneyListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewJourney) => React.ReactElement> = [], filters: IWithListProps<IPreviewJourney, TJourneySortableListCol>['filters'], sorter: IWithListProps<IPreviewJourney, TJourneySortableListCol>['sorter']): ColumnsType<IPreviewJourney> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewJourney, b: IPreviewJourney) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewJourney['organization_id'], journey: IPreviewJourney) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id } }),
      sorter: (a: IPreviewJourney, b: IPreviewJourney) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewJourney, b: IPreviewJourney) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('CHANNELS'),
      dataIndex: 'channels',
      key: 'channels',
      className: 'table-cell-channels',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewJourney['created_at'], journey: IPreviewJourney) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewJourney, b: IPreviewJourney) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewJourney['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewJourney, b: IPreviewJourney) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewJourney) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'journey', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.Journey, row) && listEditButton(t, navigate, 'journey', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.Journey, row) && listDeleteButton(t, row, deleteJourney)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewJourney>;

};
