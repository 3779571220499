import React, { RefObject } from 'react';
import { ISharedConversationWatcher, TypeConversationMessage, ISharedFullConversationReducer, IPreviewTab } from 'atlas-shared';
import './tab.mini.conversation.list.scss';
import { useTranslation } from 'react-i18next';
import { IAuth } from '@Store';
import { AppSpinner, Avatar, ConversationDispositionNote } from '@Components';
import { THighlightedMessage, useUsers } from '@Hooks';
import { TabMiniConversationMessage } from './tab.mini.conversation.message';

interface IProps {
  conversationId: number;
  source: ISharedConversationWatcher['source'];
  className?: string;
  conversation: ISharedFullConversationReducer;
  auth: IAuth;
  highlightedMessage: THighlightedMessage;
  scrollRef: RefObject<HTMLDivElement>;
  tab?: IPreviewTab;
}
export const TabMiniConversationList = React.memo(({ className, conversation, auth, highlightedMessage, scrollRef, tab }: IProps) => {
  const { t } = useTranslation();
  const users = useUsers();

  if (!conversation) {
    return <AppSpinner />;
  }

  return (
    <div className={'tab-mini-conversation-list-view-container ' + className}>
      <div className={'body'} ref={scrollRef}>
        {conversation.disposition_id && <div className='row-right conversation-message-row'>
          <Avatar size={20} user={conversation.user_id ? users.dict[conversation.user_id] : undefined} />
          <ConversationDispositionNote conversation={conversation} mini={true} />
        </div>
        }
        {
          conversation.messages.map((message: TypeConversationMessage, index) => <TabMiniConversationMessage key={`${message.channel}${message.id}`} t={t} auth={auth} message={message} conversation={conversation} users={users} highlightedMessage={highlightedMessage} tab={tab} />)
        }
      </div>
    </div>
  );
});
