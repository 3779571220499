import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchStatsReports, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useStatsReports = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const stats_reports = useSelector((store: IReducerStore) => store.stats_reports);

  useEffect(() => {
    if (!initiated && autoload && !stats_reports.loaded && !stats_reports.loading) {
      initiated = true;
      dispatch(actionFetchStatsReports());
    }
  }, [autoload, stats_reports.loaded, stats_reports.loading, dispatch]);

  return stats_reports;
};
