import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  AgentprofileEditForm,
  AgentprofileEditInitialValues,
  AgentprofileComponents,
  AgentprofileAdditionalParams,
  AgentprofileFormOptions,
  onAgentprofileFormSaved,
  IAgentprofileFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedAgentprofile, ISharedPatchAgentprofile, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchAgentprofile, patchAgentprofile, getAgentprofileUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, usePauses, useQueues, useTeams, useVoiceShortcuts } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedAgentprofile> {}

const AgentprofileFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const pauses = usePauses();
  const queues = useQueues();
  const teams = useTeams();
  const voice_shortcuts = useVoiceShortcuts();

  useEffect(() => {
    fetchAgentprofile(id).then(agentprofile => setValue(agentprofile));
  }, [id, setValue]);

  const param_props: IAgentprofileFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    pauses: pauses.pauses,
    queues: queues.queues,
    teams: teams.teams,
    voice_shortcuts: voice_shortcuts.voice_shortcuts
  };
  const formLayout: IForm | undefined = useMemo(() => value && AgentprofileEditForm(value, param_props, t), [value, organizations, pauses, queues, teams, voice_shortcuts, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && AgentprofileFormOptions(value, param_props, t), [value, organizations, pauses, queues, teams, voice_shortcuts, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchAgentprofile, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchAgentprofile) => value && save(() => patchAgentprofile(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onAgentprofileFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !pauses.loaded || !queues.loaded || !teams.loaded || !voice_shortcuts.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.Agentprofile, value)}
    >
      <AtlasForm<ISharedPatchAgentprofile>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={AgentprofileComponents}
        additionalParams={AgentprofileAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={AgentprofileEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('AGENTPROFILE')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const AgentprofileFormEditView = React.memo(withEdit(AgentprofileFormEdit, getAgentprofileUpdateSchema));
