import React, { useCallback } from 'react';
import { usePopupModals } from '@Hooks';
import { PopupModal } from './popup_modal';
import { actionRemovePopupModal, IGlobalPopupModal, useAppDispatch } from '@Store';
import './popup_modals.scss';

export const PopupModals = React.memo(() => {
  
  const popup_modals = usePopupModals();
  const dispatch = useAppDispatch();
  const onClose = useCallback((popup_modal_key: IGlobalPopupModal['key']) => {
    dispatch(actionRemovePopupModal(popup_modal_key));
  }, []);

  if (!popup_modals.length)
    return <></>;

  return <div id='popup-modals'>
    {popup_modals.map(popup_modal => <PopupModal popup_modal={popup_modal} key={popup_modal.key} onClose={onClose} />)}
  </div>;
});
