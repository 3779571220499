import { SuperadminProfileActions } from '@Store';
import { IPreviewSuperadminProfile } from 'atlas-shared';
import { fetchSuperadminProfiles } from '@Api';

export const actionFetchSuperadminProfiles = () => {
  return async (dispatch) => {
    dispatch(actionLoadingSuperadminProfiles());
    const superadmin_profiles = await fetchSuperadminProfiles();

    dispatch(actionSetSuperadminProfiles(superadmin_profiles));
    return superadmin_profiles;
  };
};

export const actionLoadingSuperadminProfiles = () => ({
  type: SuperadminProfileActions.LOADING,
  payload: []
});

export const actionSetSuperadminProfiles = (payload: Array<IPreviewSuperadminProfile>) => ({
  type: SuperadminProfileActions.SET_STATE,
  payload: payload
});

export const actionAddSuperadminProfile = (payload: IPreviewSuperadminProfile) => ({
  type: SuperadminProfileActions.ADD_STATE,
  payload: payload
});

export const actionUpdateSuperadminProfile = (payload: IPreviewSuperadminProfile) => ({
  type: SuperadminProfileActions.UPDATE_STATE,
  payload: payload
});
