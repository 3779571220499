import { TFunction } from 'i18next';
import { IAbilities, IAuth, IJourneyStore, IOrganizationStore } from '@Store';
import { IPreviewJourneyDraft, TJourneyDraftSortableListCol } from 'atlas-shared';
import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { JourneyDraftListColumns as JourneyDraftListColumnsBase } from './abstract/journey_draft.list.column.abstract';
import { Icon } from '@Components';
import { SaveIcon } from '@Assets/icons';
import { publishJourneyDraft } from '@Api';
import { NavigateFunction } from 'react-router';
import { IWithListProps } from '@Hocs/with.list';

export * from './abstract/journey_draft.list.column.abstract';

export const JourneyDraftListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, journeies: IJourneyStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewJourneyDraft) => React.ReactElement> = [], filters: IWithListProps<IPreviewJourneyDraft, TJourneyDraftSortableListCol>['filters'], sorter: IWithListProps<IPreviewJourneyDraft, TJourneyDraftSortableListCol>['sorter']): ColumnsType<IPreviewJourneyDraft> => {
  return JourneyDraftListColumnsBase(t, navigate, showDeleted, organizations, journeies, abilities, auth, [
    (row: IPreviewJourneyDraft) => <Icon tooltip={{ title: t('PUBLISH_JOURNEY') }} icon={SaveIcon} onClick={() => publishJourneyDraft(row.id, { remove: false })} />
  ], filters, sorter);
};
