import React, { useMemo } from 'react';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import './widget-type.timeline.scss';
import moment from 'moment';
import { AtlasWidgetTimeline } from '../../../../../modules/atlas-widget-timeline';
import {
  IMetric, IStatsWidgetDataTimeline,
  UserStatusStatusEnum
} from 'atlas-shared/dist';
import { usePauses, useQueues, useUsers } from '@Hooks';
import { getFullName } from '@Utils';

const DEFAULT_TIMELINE_COLOR = {
  login_activity: 'var(--green-main)',
  login_activity_only: 'var(--green-main)',
  idle_activity: 'var(--blue-main)',
  idle_activity_only: 'var(--blue-main)',
  handling_activity: 'var(--blue-light)',
  handling_activity_only: 'var(--blue-light)',
  pause_activity: 'var(--orange-dark)',
  pause_activity_only: 'var(--orange-dark)',
  busy_activity: 'var(--red-main)',
  busy_form_activity: 'var(--red-main)',
  busy_voice_activity: 'var(--red-main)',
  busy_mail_activity: 'var(--red-main)',
  busy_activity_only: 'var(--red-main)',
  busy_form_activity_only: 'var(--red-main)',
  busy_voice_activity_only: 'var(--red-main)',
  busy_mail_activity_only: 'var(--red-main)',
};

export const _WidgetTypeTimeline = ({ data, metrics, stats_widget, t, metric_data_dicts, innerWidth, innerHeight, stats_custom_metrics, stats_report }: IWithStatsWidgetProps & {data: IStatsWidgetDataTimeline}) => {

  const users = useUsers();
  const pauses = usePauses();
  const queues = useQueues();
  const legends = useMemo(() => {
    return metrics.map((metric, i) => ({
      alias: (metric as IMetric)?.alias,
      title: stats_widget.settings.metrics[i].title || metric?.title,
      color: stats_widget.settings.metrics[i].color || '#FFFFFF',
      bg_color: stats_widget.settings.metrics[i].bg_color || DEFAULT_TIMELINE_COLOR[(metric as IMetric)?.alias],
      border: stats_widget.settings.metrics[i].border || { width: 1, color: DEFAULT_TIMELINE_COLOR[(metric as IMetric)?.alias] }
    })).filter(({ alias }) => !!alias);
  }, [metrics]);
  const metric_legend_map = useMemo(() => {
    return {
      [UserStatusStatusEnum.Online]: legends.find(l => l.alias.startsWith('login_activity')),
      [UserStatusStatusEnum.Idle]: legends.find(l => ['idle_activity', 'login_activity'].some(key => l.alias.startsWith(key))),
      [UserStatusStatusEnum.HandlingConversation]: legends.find(l => ['handling_activity', 'idle_activity', 'login_activity'].some(key => l.alias.startsWith(key))),
      [UserStatusStatusEnum.Paused]: legends.find(l => ['pause_activity', 'handling_activity', 'idle_activity', 'login_activity'].some(key => l.alias.startsWith(key))),
      [UserStatusStatusEnum.Busy]: legends.find(l => ['busy_activity', 'busy_voice_activity', 'busy_form_activity', 'busy_mail_activity', 'handling_activity', 'idle_activity', 'login_activity'].some(key => l.alias.startsWith(key))),
    };
  }, [legends]);

  const tracks = useMemo(() => {
    return Object.entries(data?.d || {}).map(([user_id, _tracks]) => ({
      elements: _tracks.map((track, i) => {
        const s = moment(track.s);
        const e = moment(track.e);
        const legend = metric_legend_map[track.t];
        const pause = track.p ? pauses.dict[track.p] : null;
        const queue = track.q ? queues.dict[track.q] : null;
        const tooltip: Array<string> = [];

        if (legend?.title)
          tooltip.push(legend.title);
        else
          tooltip.push(t(`STATUS_${track.t}`));

        if (queue)
          tooltip.push(`${track.d ? `${t(`DIRECTION_${track.d}`)} - ` : ''}${queue.title}`);

        if (track.p === -1) {
          //if (track.t < UserStatusStatusEnum.BusyMail)
          tooltip.push('ACW');
        }
        else if (pause)
          tooltip.push(pause.title);

        if (track.c)
          tooltip.push(`${t('CONVERSATION')} #${track.c}`);

        tooltip.push(`${s.format('HH:mm:ss')} - ${e.format('HH:mm:ss')}`);

        return {
          id: `track-${user_id}-${i}`,
          start: s,
          end: e,
          //title: pause?.title || queue?.title || (track.p === -1 ? 'ACW' : '') || '',
          title: '',
          classes: [],
          tooltip,
          conversation_id: track.c,
          organization_id: track.o,
          styles: { color: legend?.color, backgroundColor: legend?.bg_color, border: `${legend?.border.width || 1}px solid ${legend?.border.color}` }
        };
      }),
      id: user_id,
      title: users.dict[user_id] ? getFullName(users.dict[user_id]) : 'User',
    }));
  }, [data, users.loaded, pauses.loaded, queues.loaded]);

  if (!data?.s)
    return <></>;

  return <div className='widget-type-timeline' style={{ height: stats_report.is_dashboard ? innerHeight : 'auto' }}>
    <AtlasWidgetTimeline
      t={t}
      legends={legends}
      zoom={24}
      //start={moment().startOf('day').subtract(2, 'hours')}
      tracks={tracks}
      s={data.s}
      e={data.e}
      is_dashboard={stats_report.is_dashboard}
    />
  </div>;
};

export const WidgetTypeTimeline = React.memo(withStatsWidget(_WidgetTypeTimeline));
