import { RestRequest } from '@Utils';
import { IPreviewConversationFilter, ISharedConversationFilter, ISharedCreateConversationFilter, ISharedPatchConversationFilter } from 'atlas-shared';

export const fetchConversationFilters = async (): Promise<Array<IPreviewConversationFilter>> => {
  return RestRequest.get<Array<IPreviewConversationFilter>>('conversation_filter', { socket_id: global.socketId });
};

export const fetchConversationFilter = async (id: IPreviewConversationFilter['id']): Promise<ISharedConversationFilter> => {
  return RestRequest.get<ISharedConversationFilter>(`conversation_filter/${id}`, { socket_id: global.socketId });
};

export const createConversationFilter = (payload: ISharedCreateConversationFilter) => {
  return RestRequest.post<any, ISharedConversationFilter>('conversation_filter', payload, { socket_id: global.socketId });
};

export const patchConversationFilter = (id: IPreviewConversationFilter['id'], payload: ISharedPatchConversationFilter) => {
  return RestRequest.patch<any, ISharedConversationFilter>(`conversation_filter/${id}`, payload, { socket_id: global.socketId });
};

export const deleteConversationFilter = (id: IPreviewConversationFilter['id']) => {
  return RestRequest.delete(`conversation_filter/${id}`, { socket_id: global.socketId });
};

export const getConversationFilterUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation_filter/update/schema');
};

export const getConversationFilterCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation_filter/create/schema');
};

export const getConversationFilterPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation_filter/patch/schema');
};
