import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  WallboardAddForm,
  WallboardAddInitialValues,
  WallboardComponents,
  WallboardAdditionalParams,
  WallboardFormOptions,
  onWallboardFormSaved,
  IWallboardFormOptionsProps,
  AlertError,
  queryParam
} from '@Utils';
import { AtlasForm, IFormOptions } from 'atlas-form';
import { CrudEnum, ISharedCreateWallboard, PermissionEnum } from 'atlas-shared';
import { createWallboard, fetchWallboard, getWallboardCreateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withAdd, IWithAddProps } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useUsers, useStatsReports } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateWallboard> {}

const WallboardFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const organizations = useOrganizations();
  const users = useUsers();
  const stats_reports = useStatsReports();
  const abilities = useAbilities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned] = useState<Partial<ISharedCreateWallboard>>();

  const param_props: IWallboardFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    users: users.users,
    stats_reports: stats_reports.stats_reports
  };
  const formLayout = useMemo(() => WallboardAddForm(values, param_props, t), [values, organizations, users, stats_reports, t, abilities]);
  const options: IFormOptions = useMemo(() => WallboardFormOptions(values || WallboardAddInitialValues, param_props, t), [values, organizations, users, stats_reports, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateWallboard, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues]);
  const onFinish = useCallback((values: ISharedCreateWallboard) => save(() => createWallboard(values).then(
    res => onSaved ? onSaved(res, navigate) : onWallboardFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [save, navigate, t]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchWallboard(+id)
        .then(({
          organization_id,
          title,
          wallboard_key,
          user_id,
          stats_report_id,
          share_data
        }) => {
          setCloned({
            organization_id,
            title,
            wallboard_key,
            user_id,
            stats_report_id,
            share_data
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !users.loaded || !stats_reports.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Wallboard, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={WallboardComponents}
        additionalParams={WallboardAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...WallboardAddInitialValues, ...initialValues }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('WALLBOARD')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const WallboardFormAddView = React.memo(withAdd(WallboardFormAdd, getWallboardCreateSchema, WallboardAddInitialValues));
