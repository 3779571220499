import { DispositionActions } from '@Store';
import { IPreviewDisposition } from 'atlas-shared';
import { fetchDispositions } from '@Api';

export const actionFetchDispositions = () => {
  return async (dispatch) => {
    dispatch(actionLoadingDispositions());
    const dispositions = await fetchDispositions();

    dispatch(actionSetDispositions(dispositions));
    return dispositions;
  };
};

export const actionLoadingDispositions = () => ({
  type: DispositionActions.LOADING,
  payload: []
});

export const actionSetDispositions = (payload: Array<IPreviewDisposition>) => ({
  type: DispositionActions.SET_STATE,
  payload: payload
});

export const actionAddDisposition = (payload: IPreviewDisposition) => ({
  type: DispositionActions.ADD_STATE,
  payload: payload
});

export const actionUpdateDisposition = (payload: IPreviewDisposition) => ({
  type: DispositionActions.UPDATE_STATE,
  payload: payload
});
