import { IPreviewConversationFavourite } from 'atlas-shared';

export enum ConversationFavouriteActions {
  SET_STATE = 'CONVERSATION_FAVOURITE_SET_STATE',
  ADD_STATE = 'CONVERSATION_FAVOURITE_ADD_STATE',
  REMOVE_STATE = 'CONVERSATION_FAVOURITE_REMOVE_STATE',
  GET_STATE = 'CONVERSATION_FAVOURITE_GET_STATE',
  UPDATE_STATE = 'CONVERSATION_FAVOURITE_UPDATE_STATE',
  LOADING = 'CONVERSATION_FAVOURITE_LOADING',
}

export interface IConversationFavouriteLoading {
  type: ConversationFavouriteActions.LOADING;
}

export interface IConversationFavouriteSetAction {
  type: ConversationFavouriteActions.SET_STATE;
  payload: Array<IPreviewConversationFavourite>;
}

export interface IConversationFavouriteUpdateAction {
  type: ConversationFavouriteActions.UPDATE_STATE;
  payload: IPreviewConversationFavourite;
}

export interface IConversationFavouriteAddAction {
  type: ConversationFavouriteActions.ADD_STATE;
  payload: IPreviewConversationFavourite;
}

export interface IConversationFavouriteRemoveAction {
  type: ConversationFavouriteActions.REMOVE_STATE;
  payload: IPreviewConversationFavourite['id'];
}

export interface IConversationFavouriteStore {
  loaded: boolean;
  loading: boolean;
  conversation_favourites: Array<IPreviewConversationFavourite>;
  dict: Record<IPreviewConversationFavourite['id'], IPreviewConversationFavourite>;
}

export type TConversationFavouriteActionTypes = IConversationFavouriteSetAction | IConversationFavouriteUpdateAction | IConversationFavouriteAddAction | IConversationFavouriteRemoveAction | IConversationFavouriteLoading;
