import { CannedAnswerActions } from '@Store';
import { IPreviewCannedAnswer } from 'atlas-shared';
import { fetchCannedAnswers } from '@Api';

export const actionFetchCannedAnswers = () => {
  return async (dispatch) => {
    dispatch(actionLoadingCannedAnswers());
    const canned_answers = await fetchCannedAnswers();

    dispatch(actionSetCannedAnswers(canned_answers));
    return canned_answers;
  };
};

export const actionLoadingCannedAnswers = () => ({
  type: CannedAnswerActions.LOADING,
  payload: []
});

export const actionSetCannedAnswers = (payload: Array<IPreviewCannedAnswer>) => ({
  type: CannedAnswerActions.SET_STATE,
  payload: payload
});

export const actionAddCannedAnswer = (payload: IPreviewCannedAnswer) => ({
  type: CannedAnswerActions.ADD_STATE,
  payload: payload
});

export const actionUpdateCannedAnswer = (payload: IPreviewCannedAnswer) => ({
  type: CannedAnswerActions.UPDATE_STATE,
  payload: payload
});
