import { VoiceInternalCallActions } from '@Store';
import { IPreviewVoiceInternalCall } from 'atlas-shared';
import { fetchVoiceInternalCalls } from '@Api';

export const actionFetchVoiceInternalCalls = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceInternalCalls());
    const voice_internal_calls = await fetchVoiceInternalCalls();

    dispatch(actionSetVoiceInternalCalls(voice_internal_calls));
    return voice_internal_calls;
  };
};

export const actionLoadingVoiceInternalCalls = () => ({
  type: VoiceInternalCallActions.LOADING,
  payload: []
});

export const actionSetVoiceInternalCalls = (payload: Array<IPreviewVoiceInternalCall>) => ({
  type: VoiceInternalCallActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceInternalCall = (payload: IPreviewVoiceInternalCall) => ({
  type: VoiceInternalCallActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceInternalCall = (payload: IPreviewVoiceInternalCall) => ({
  type: VoiceInternalCallActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveVoiceInternalCall = (id: IPreviewVoiceInternalCall['id']) => ({
  type: VoiceInternalCallActions.REMOVE_STATE,
  payload: id
});
