import { RestRequest } from '@Utils';
import { IPreviewAudit, ISharedAudit, ISharedSearchAudit } from 'atlas-shared';

export const fetchAudit = async (pk: IPreviewAudit['pk'], id: IPreviewAudit['id']): Promise<ISharedAudit> => {
  return RestRequest.get<ISharedAudit>(`audit/p/${pk}/${id}`, { socket_id: global.socketId });
};

export const searchAudit = async (params: ISharedSearchAudit): Promise<{items: Array<IPreviewAudit>; count: number}> => {
  return RestRequest.post<ISharedSearchAudit, {items: Array<IPreviewAudit>; count: number}>('audit/search', params, { socket_id: global.socketId });
};
