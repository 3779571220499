import React, { Suspense, useEffect, useState } from 'react';
import '../admin.list.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import { IPreviewUser, IPreviewQueue, filterMap } from 'atlas-shared';
import { Table } from 'antd';
import { Spinner, ListPageEmpty, IconText } from '@Components';
import {
  useQueues,
  useUsers, useUserStatuses
} from '@Hooks';

import { getFullName } from '@Utils';
import { unsubscribeEntity } from '@Api';

import { CheckIcon, XIcon } from '@Assets';
import { GetColumnSearchEqualProps, GetColumnSearchProps } from '@Utils/list/components';

interface IQueueAgentRow {
  agent_id: IPreviewUser['id'];
  agent: string;
  active: boolean;
}

const QueueAgentsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const users = useUsers();
  const user_statuses = useUserStatuses();
  const queues = useQueues();
  const [queue, setQueue] = useState<IPreviewQueue>();
  const [rows, setRows] = useState<Array<IQueueAgentRow>>([]);
  const { id } = useParams<'id'>();

  const [hasFilters, setHasFilters] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({});
  
  useEffect(() => {
    if (id)
      setQueue(queues.dict[+id]);
  }, [queues]);

  useEffect(() => {
    return () => {
      unsubscribeEntity('user_status', 'main');
    };
  }, []);
  
  useEffect(() => {
    if (queue)
      setRows(filterMap<IQueueAgentRow, IPreviewUser>(users.users, user => {
        if (!queue.__users.includes(user.id))
          return false;

        const user_status = user_statuses.dict[user.id];
        
        return {
          agent_id: user.id,
          agent: getFullName(user),
          active: (user_status?.__current_queues || []).includes(queue.id)
        };
      }));
  }, [ queue]);

  useEffect(() => {
    setHasFilters(Object.keys(filters).length > 0);
  }, [filters]);

  const columns: ColumnsType<IQueueAgentRow> = React.useMemo(() => {
    return [
      {
        title: t('ID'),
        dataIndex: 'agent_id',
        key: 'agent_id',
        render: (agent_id: IQueueAgentRow['agent_id']) => <a onClick={() => navigate(`/admin/user/${agent_id}/edit`)}>{agent_id}</a>,
        sorter: (a: IQueueAgentRow, b: IQueueAgentRow) => a.agent_id - b.agent_id,
        sortDirections: ['descend', 'ascend'],
        filteredValue: filters.agent_id || null,
        ...GetColumnSearchEqualProps(t, 'agent_id', t('ID'))
      },
      {
        title: t('AGENT'),
        dataIndex: 'agent',
        key: 'agent',
        render: (agent: IQueueAgentRow['agent'], row: IQueueAgentRow) => <a onClick={() => navigate(`/admin/user/${row.agent_id}/edit`)}>{agent}</a>,
        sorter: (a: IQueueAgentRow, b: IQueueAgentRow) => a.agent.localeCompare(b.agent),
        sortDirections: ['descend', 'ascend']
      },
      {
        title: t('ACTIVE_IN_QUEUE'),
        dataIndex: 'active',
        key: 'active',
        render: (active: IQueueAgentRow['active']) => <div className='boolean-column'>{active ? <IconText icon={CheckIcon} text={t('YES')} iconProps={{ size: 18, style: { color: 'var(--green)' } }} /> : <IconText icon={XIcon} text={t('NO')} iconProps={{ size: 18, style: { color: 'var(--red)' } }} />}</div>,
        sorter: (a: IQueueAgentRow, b: IQueueAgentRow) => a.active ? 0 : 1,
        sortDirections: ['descend', 'ascend'],
        filteredValue: filters.active || null,
        ...GetColumnSearchProps(t, 'active', t('ACTIVE'), [{ id: true, title: t('YES') }, { id: false, title: t('NO') }])
      }
    ];
  }, [t, navigate, filters]);

  if (!queue || !queues.loaded || !users.loaded)
    return <></>;

  return (
    <Suspense fallback={<Spinner />}>
      <div className='admin-list-view'>
        <div className='admin-list-page-header'>
          <h1>
            {t('QUEUE_AGENTS')}: <span>{queue.title}</span>
          </h1>
        </div>
        <div className='table-wrp'>
          <Table
            columns={columns}
            dataSource={rows}
            onChange={(pagination, filters, sorter) => {
              setFilters(filters || {});
            }}
            locale={{
              emptyText: <ListPageEmpty title={'NO_AGENTS_WERE_FOUND'} t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => setFilters({})} />
            }}
          />
        </div>
      </div>
    </Suspense>
  );
};

export const QueueAgentsListView = React.memo(QueueAgentsList);
