import { FormTypeEnum,
  IPreviewForm,
  ISharedCreateList, ISharedFormWebsite,
  ISharedList
} from 'atlas-shared';
import { TFunction } from 'i18next';
import { IFormOptions } from 'atlas-form';
import { filterRelated } from '@Utils';
import { IListFormOptionsProps, ListFormOptions as ListFormOptionsBase } from './abstract/list.form.abstract';

export * from './abstract/list.form.abstract';

export const ListFormOptions = (list: ISharedCreateList | ISharedList, props: IListFormOptionsProps, t: TFunction): IFormOptions => ({
  ...ListFormOptionsBase(list, props, t),
  form_id: (it: ISharedFormWebsite) => filterRelated<IPreviewForm>(it.organization_id || list.organization_id, 'form', 'list', props.forms, true, it => it.type === FormTypeEnum.Contact),

});
