import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';
import { IPreviewVoiceAsteriskDowngrade, TVoiceAsteriskDowngradeSortableListCol } from 'atlas-shared/dist';

export * from './abstract/voice_asterisk_downgrade.list.column.abstract';
import { VoiceAsteriskDowngradeListColumns as VoiceAsteriskDowngradeListColumnsBase } from './abstract/voice_asterisk_downgrade.list.column.abstract';
import { Icon } from '@Components';
import { RefreshIcon } from '@Assets';
import { resyncVoiceAsteriskDowngrade } from '@Api';

export const VoiceAsteriskDowngradeListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsteriskDowngrade) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsteriskDowngrade, TVoiceAsteriskDowngradeSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsteriskDowngrade, TVoiceAsteriskDowngradeSortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsteriskDowngrade> => {
  return VoiceAsteriskDowngradeListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    (row: IPreviewVoiceAsteriskDowngrade) => <Icon icon={RefreshIcon} tooltip={{ title: t('RESYNC') }} iconProps={{ size: 14 }} onClick={() => { resyncVoiceAsteriskDowngrade(row.id); }} />,
    ...buttons
  ], filters, sorter);
};
