import {
  IPreviewStatsReport,
  ISharedCreateStatsWidget,
  PrebuiltDashboardWidgets,
  PrebuiltReportWidgets, Undefinable
} from 'atlas-shared/dist';
import { Button } from '@Components';
import React from 'react';
import { TFunction } from 'i18next';
import { IAuth, IUserStore } from '@Store';
import { StatsWidgetFormWidgetList } from './stats_widget.form.widget.list';

interface IProps {
  t: TFunction;
  auth: IAuth;
  users: IUserStore;
  report: IPreviewStatsReport;
  onSelect: (template: Partial<ISharedCreateStatsWidget>) => void;
  selectedTemplate: Undefinable<Partial<ISharedCreateStatsWidget>>;
  onUseTemplate: () => void;
}

export const StatsWidgetFormTemplate = ({ t, auth, report, users, selectedTemplate, onSelect, onUseTemplate }: IProps) => {
  return <div className='select-template'>
    <StatsWidgetFormWidgetList
      t={t}
      auth={auth}
      users={users}
      widgets={report.is_dashboard ? PrebuiltDashboardWidgets : PrebuiltReportWidgets}
      isTemplates
      onSelect={(_template) => {
        const template = _template as Partial<ISharedCreateStatsWidget>;

        onSelect({
          organization_id: auth.user.__admin_organization_ids[0],
          ...template,
          settings: {
            ...(template.settings as ISharedCreateStatsWidget['settings']),
            filter: {
              ...template.settings!.filter,
              organization_ids: auth.user.__admin_organization_ids
            }
          }
        });
      }}
    />
    <div className='add-btns'>
      <Button
        className={'add-btn'}
        type='primary'
        disabled={!selectedTemplate}
        onClick={() => onUseTemplate()}
      >{t('USE_TEMPLATE')}</Button>
    </div>

  </div>;
};
