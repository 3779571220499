import { TFunction } from 'i18next';
import {
  ChannelEnum, DirectionEnum,
  IPreviewJourney,
  ISharedCreateMailAccount,
  ISharedMailAccount,
  MailAccountIsOauth2
} from 'atlas-shared';
import { IFormOptions } from 'atlas-form';
import { filterRelated } from '@Utils';
import { IMailAccountFormOptionsProps, MailAccountFormOptions as MailAccountFormOptionsBase } from './abstract/mail_account.form.abstract';
import { NavigateFunction } from 'react-router';

export * from './abstract/mail_account.form.abstract';

export const onMailAccountFormSaved = (t: TFunction, action: 'edit' | 'add', mail_account: ISharedMailAccount, navigate: NavigateFunction): void => {
  if ((mail_account.auth_error || action === 'add') && MailAccountIsOauth2(mail_account))
    window.location.href = `${process.env.REACT_APP_BASE_URL || 'http://localhost:8000'}/mail_account/${mail_account.id}/oauth2/redirect`;
  else
    navigate('/admin/mail_accounts');
};

export const MailAccountFormOptions = (mail_account: ISharedCreateMailAccount | ISharedMailAccount, props: IMailAccountFormOptionsProps, t: TFunction): IFormOptions => {
  return {
    ...MailAccountFormOptionsBase(mail_account, props, t),
    inbound_journey_id: (it: ISharedMailAccount) => filterRelated<IPreviewJourney>(it.organization_id || mail_account.organization_id, 'journey', 'mail_account', props.journeies, true, it => it.channels.some(({ c, d }) => c === ChannelEnum.Mail && d === DirectionEnum.InBound)),
    outbound_journey_id: (it: ISharedMailAccount) => filterRelated<IPreviewJourney>(it.organization_id || mail_account.organization_id, 'journey', 'mail_account', props.journeies, true, it => it.channels.some(({ c, d }) => c === ChannelEnum.Mail && d === DirectionEnum.Outbound)),
  };
};

export const MailAccountAddInitialValues: ISharedCreateMailAccount = {
  imap: {},
  smtp: {}
} as ISharedCreateMailAccount;
