import { RoleEnum } from 'atlas-shared';

export interface IChangelogHistoryHistoryItem {
  change: string;
  roles?: Array<RoleEnum>;
  bug?: boolean;
}
export interface IChangelogHistory {
  version: string;
  date: string;
  history: Array<IChangelogHistoryHistoryItem>;
}

export const CURRENT_VERSION = '1.1.59';
export const CHANGELOG_HISTORY: Array<IChangelogHistory> = [
  {
    version: '1.1.59',
    date: '2024-08-26',
    history: [
      {
        change: 'Conversation filter new options "Resolution time" and "Response time".',
      },
      {
        change: 'Edit voice language.',
      },
      {
        change: 'Option to trigger transcript on queue settings. By "Click", "Recordings by journey" and "Manual recordings."'
      },
      {
        change: 'Option to filter timeline by journey.'
      }
    ]
  },
  {
    version: '1.1.58',
    date: '2024-08-21',
    history: [
      {
        change: 'Allowing larger embedded images in canned answer body.',
        roles: [RoleEnum.Admin]
      },
      {
        change: 'Fixing issue where report widget could not be moved more than once step.',
        bug: true
      },
      {
        change: 'Loading indicator for global search.'
      },
      {
        change: 'Hide time minute picker in statistics widget settings for custom period as statistics data is summarized per hour.'
      }
    ]
  },
  {
    version: '1.1.57',
    date: '2024-08-20',
    history: [
      {
        change: `Voice call transcript released.
        - Turn on transcript on queue settings.
        - Select "Default language" on queue settings.
        - Make sure to set correct language in journeys, or default language of the queue will be used.`
      }
    ]
  },
  {
    version: '1.1.56',
    date: '2024-08-19',
    history: [
      {
        change: 'Enhanced global search where best matches with later creation date will end up higher in result page.'
      },
      {
        change: 'Timeline buttons will now toggle timeline.'
      },
      {
        change: 'New search keyword while in search result page will now change the results.',
        bug: true
      },
      {
        change: 'Previous search results will no longer be kept in search results after a new search is made.',
        bug: true
      }
    ]
  },
  {
    version: '1.1.55',
    date: '2024-08-15',
    history: [
      {
        change: 'Audit events in contact timeline.'
      },
      {
        change: 'Timeline events with colors.'
      },
      {
        change: 'Fixed issue where attachments from canned answers did not get attached in e-mail.',
        bug: true
      }
    ]
  },
  {
    version: '1.1.54',
    date: '2024-08-14',
    history: [
      {
        change: 'Audit events in conversation timeline.'
      },
      {
        change: 'Filter timeline events.'
      },
      {
        change: 'Change metric title to "Average messages / voice call bridges per thread"',
        roles: [RoleEnum.Superadmin]
      }
    ]
  },
  {
    version: '1.1.53',
    date: '2024-08-13',
    history: [
      {
        change: 'Conversation timeline refactored to same UI as voice call timeline in order to fit more events.'
      },
      {
        change: 'Conversation timeline grouped by date. One timeline per day.'
      },
      {
        change: 'Improved statistics tool to accept date range, and no organization id, in order to pick all organization ids from database.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Correction for voice call pre-queue time.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: '"Pre-queue time" changed on conversation voice call to "Journey time"'
      },
      {
        change: 'Default metric format changed on group metric "Day"'
      }
    ]
  },
  {
    version: '1.1.52',
    date: '2024-08-12',
    history: [
      {
        change: `Wallboard released.
        - Go to admin menu -> Statistics -> Wallboard.
        - Visit https://connectel.app/wallboard and add displayed key to Wallboard item.
        - Select desired report or dashboard to link with wallboard.
        
Created wallboards have a lifetime of 12 hours.
After expiry or page refresh, a new wallboard key will be displayed that needs to be linked to created wallboard in admin page.
        `,
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Text color added to editor.'
      }
    ]
  },
  {
    version: '1.1.51',
    date: '2024-08-08',
    history: [
      {
        change: 'Built tools in order to remotely re-index statistics without a need for new releases.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Journey voice action "Get Digits" will now log failed tries and print them in conversation journey timeline.'
      },
      {
        change: 'First response & resolved SLA failed now also includes none resolved/closed conversations that have exceeded their SLA timestamp.'
      },
      {
        change: 'Live metrics "Uncertain resolve SLA conversations" & "Uncertain first response SLA conversations" containing waiting & ongoing conversation that have not exceeded their SLA timestamp.'
      }
    ]
  },
  {
    version: '1.1.50',
    date: '2024-08-06',
    history: [
      {
        change: 'Channel based busy metrics in statistics.',
      },
      {
        change: 'ACW can now be grouped by thread and messages group metrics.'
      },
      {
        change: 'New metric added "Average talk time (voice call bridges) + Average ACW"'
      }
    ]
  },
  {
    version: '1.1.49',
    date: '2024-08-05',
    history: [
      {
        change: 'Optimized global search where e-mail address and phone number gives conversations with e-mail messages and voice calls a higher rank.',
      },
      {
        change: 'Option to switch period in preview mode, on stats report widget shared with you, where you don\'t have permission to edit widget settings.'
      },
      {
        change: 'Register voice call bridge on user activity ACW.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Force image resize on e-mail body that extended out entire page middle section.'
      },
      {
        change: 'Fixed issue on "Add new user" page issue.'
      }
    ]
  },
  {
    version: '1.1.48',
    date: '2024-08-01',
    history: [
      {
        change: 'Statistics, group by new metric "Conversation status"',
      },
      {
        change: 'Removed statistics primary key as it would extend over BIGINT limitations.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Conversation filter option "Skill" was added.',
      },
      {
        change: 'Conversation filter option "Is waiting" was added. Turn on to filter only none answered conversations. Turned off will not have any effect on the filtering.',
      },
      {
        change: 'Conversation filter option "Is ongoing" was added. Turn on to filter only answered conversations. Turned off will not have any effect on the filtering.',
      },
      {
        change: 'New statistics metric "Average talk time + Average ACW (voice calls)"'
      }
    ]
  },
  {
    version: '1.1.47',
    date: '2024-07-30',
    history: [
      {
        change: 'Select "Default voice country" on agent profile in order to define default voice call bar country.',
      },
      {
        change: 'User skills limited to skills associated with user organizations.',
        bug: true,
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Alphabetical sort for nested select boxes like dispositions.',
      }
    ]
  },
  {
    version: '1.1.46',
    date: '2024-07-29',
    history: [
      {
        change: 'Metrics for Pre-queue conversations, thread / voice calls and message / voice call bridges added.',
      },
      {
        change: 'Keep default sorting instead of alphabetic sorting on specific select options.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Do not cover statistics header with loading overlay.',
        bug: true,
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      }
    ]
  },
  {
    version: '1.1.45',
    date: '2024-07-28',
    history: [
      {
        change: 'Skills module and queues with skill based strategies.',
      }
    ]
  },
  {
    version: '1.1.44',
    date: '2024-07-25',
    history: [
      {
        change: 'Added new database indexes to improve statistics query reads.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Change statistics columns from INT to BIGINT as number of integers where about to run about.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Added "Undo" and "Redo" buttons to editor.'
      }
    ]
  },
  {
    version: '1.1.43',
    date: '2024-07-24',
    history: [
      {
        change: 'Type "*" in the editor to search for, and paste canned answers.'
      },
      {
        change: 'New datapoints message and thread resolved collected in order to feed new and existing metrics.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'New metric "Average handling time" available in statistics.'
      },
      {
        change: 'Fixed issue were voice call recording, without a association to a voice call bridge, could not be played or downloaded.',
        bug: true
      }
    ]
  },
  {
    version: '1.1.42',
    date: '2024-07-22',
    history: [
      {
        change: 'Metrics "First login time" and "Last logout time" added.'
      }
    ]
  },
  {
    version: '1.1.41',
    date: '2024-07-21',
    history: [
      {
        change: 'Actual "to" address is now registered on e-mail messages, so it will be clear if e-mail has been send directly to mail account or as cc / bcc.'
      },
      {
        change: 'User activity were missing some activities is now fixed so all events are catched an registered as they should.',
        bug: true
      },
      {
        change: 'Translating phone number to human readable format when grouping by account in statistics.'
      },
      {
        change: 'No longer highlight selected metrics in statistics widget form as missing.',
        bug: true
      }
    ]
  },
  {
    version: '1.1.40',
    date: '2024-07-18',
    history: [
      {
        change: 'Users with status pause will now be listed under correct status in statistics.',
        bug: true
      },
      {
        change: 'Icon for busy indication in form channel added to metric status info.'
      },
      {
        change: 'Queue title added to status info metric.'
      },
      {
        change: 'Fixed corner case where duplicate notifications could be raised.',
        bug: true
      },
      {
        change: 'Live metrics related to voice call excluded calls waiting more than 1 hour is now fixed.',
        bug: true
      },
      {
        change: 'Fixed minor issue on metric filter on conversation channel.',
        bug: true
      },
    ]
  },
  {
    version: '1.1.39',
    date: '2024-07-15',
    history: [
      {
        change: 'Released new queue-distribution strategy where we take consideration on all user queues and not only one at the time.\nThis update will prevent duplicate notifications on channels Mail and Form where user is assigned multiple queues with queue-distribution activated.'
      },
      {
        change: 'Data for stats_channel_live is now stored in memory.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Journey "When" conversation updated will now trigger on custom data updates.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Contact journey log added.'
      }
    ]
  },
  {
    version: '1.1.38',
    date: '2024-07-11',
    history: [
      {
        change: 'Statistics channel data in memory improved',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Metric "Total after call work time" now displays data from conversations instead of user activity.',
      },
      {
        change: 'New statistics metric "Conversation handling time" was added.',
      },
      {
        change: 'Fixed issue where rule comparison "Includes" did not function as expected.',
        bug: true
      },
      {
        change: 'Fixed issue where rule comparison "Includes" did not function as expected.',
        bug: true
      },
      {
        change: 'Reduced number of statistics re-syncs.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Sorting by custom metric in statistics widget now does sort as expected.',
        bug: true
      },
      {
        change: 'Conversation journey timeline does now scroll vertically when needed.',
        bug: true
      },
      {
        change: 'Review on some statistics widgets did not have selectable thread channels and direction on metric edit form.',
        bug: true
      },
      {
        change: 'Translations for thread direction, channel and queue was added',
        bug: true
      },
      {
        change: 'Issue fixed when widget setting "Split by" were removed and caused a white-screen.',
        bug: true
      },
      {
        change: 'Stats widgets content were not displayed in report when to many splits were generated in result of widget setting "Split by" is now fixed.',
        bug: true
      },
      {
        change: 'Custom metrics now selectable in widget filter row.',
        bug: true
      },
      {
        change: 'Statistics widget "No data" indicator added.'
      },
      {
        change: 'Statistics widget loading indicator added.'
      }
    ]
  },
  {
    version: '1.1.37',
    date: '2024-07-08',
    history: [
      {
        change: 'stats_channel table today\'s data now stored in memory to reduce ACU and IO usage',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Sort conversation list by last inbound & outbound message'
      },
      {
        change: 'New journey rule options inbound & outbound message',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'All admin list filter options ordered in alphanumeric order.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      }
    ]
  },
  {
    version: '1.1.36',
    date: '2024-07-02',
    history: [
      {
        change: 'Deleted queues available in call bar suggestions are now removed.',
        bug: true
      },
      {
        change: 'A total count is now shown above conversation list, matching all conversations with selected conversation filter.'
      },
      {
        change: 'Transfer entire dashboard/report to user.'
      },
      {
        change: 'Transfer widget to report/dashboard owner.'
      },
      {
        change: 'Option to clone entire dashboard/report.'
      },
      {
        change: 'Strange formatting of seconds to :010 fixed.',
        bug: true
      },
      {
        change: 'Prevent custom period in stats widget to be longer than 31 days.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Image and Pdf screenshots are now working.',
        bug: true
      },
      {
        change: 'CSV export added to statistics widget.'
      }
    ]
  },
  {
    version: '1.1.35',
    date: '2024-06-30',
    history: [
      {
        change: '"Contact" permission added to admin profile.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
      },
      {
        change: 'Logging put in place in order to catch errors generated in ws-microservice.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Journey rule issue with "Oldest waiting call" is fixed.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Journey auto-response issue related to e-mail subject being empty is fixed.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Hanged voice calls (often attended transfers) will now be ended by cronjob until the issue is fixed.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'All select field options are now sorted alphabetically.',
      },
      {
        change: 'Clone & change widget settings while adding existing widgets to report/dashboard.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
      }
    ]
  },
  {
    version: '1.1.34',
    date: '2024-06-26',
    history: [
      {
        change: 'Reduce resource usage setting conversation channels without using reducer.',
        roles: [RoleEnum.Superadmin],
      },
      {
        change: 'Remove journey triggers when journey is deleted.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Agent profile settings Play & Download recordings option "Owned by user" includes now recordings where agent has been involved in instead of earlier manuel recordings.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
      },
      {
        change: 'Date-picker has correct color in dark-mode theme.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Change "Dashboard" to "Report" in stats report page.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
        bug: true
      }
    ]
  },
  {
    version: '1.1.33',
    date: '2024-06-25',
    history: [
      {
        change: 'Option available to hide entire stats column by user.',
      },
      {
        change: 'Clear unnecessary rows in stats_channel_live to decrease resource usage.',
        roles: [RoleEnum.Superadmin],
      },
      {
        change: 'New journey action "Reply latest inbound message" to use on conversation triggers.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
      },
      {
        change: 'Deleted options will no longer be added to field when pressing "Select all".',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Resolve white-screen issue when clicking on conversation timeline.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Optimized stats read query for form submission.',
        roles: [RoleEnum.Superadmin],
      },
    ]
  },
  {
    version: '1.1.32',
    date: '2024-06-24',
    history: [
      {
        change: 'Delete report or dashboard from statistics page.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin]
      },
      {
        change: 'Widgets will be permanently removed upon deletion, only if they dont appear in any other dashboards, dashboard preview or reports.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin]
      },
      {
        change: 'Confirm deletion of report, dashboard or widget before deletion.',
      },
      {
        change: 'Make sure changes in widget settings takes affect after save.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Filter conversation by "Created after"'
      },
      {
        change: 'Options "Last message by" and "Last message at" added to journey rule options.',
        roles: [RoleEnum.Admin, RoleEnum.Superadmin],
      },
      {
        change: 'Optimized stats read queries for conversation and mail message',
        roles: [RoleEnum.Superadmin],
      },
    ]
  },
  {
    version: '1.1.31',
    date: '2024-06-23',
    history: [
      {
        change: 'Option to clone existing widget while adding to dashboard/report.',
      },
      {
        change: 'Improvements regarding drag & drop of widgets in dashboard.',
        bug: true
      },
      {
        change: 'Search results in global search bar will include from all organizations for admins.'
      },
      {
        change: 'Search pagination added. More items will be loaded upon scroll.'
      },
      {
        change: '"Global search queues" now available on agent profiles in order to include search results for those queues in global search.'
      },
      {
        change: 'More results in global search when filtering on "Conversation" or "Contact"'
      },
      {
        change: 'Major improvements regarding decreasing number of reads from main database.',
        roles: [RoleEnum.Superadmin]
      }
    ]
  },
  {
    version: '1.1.30',
    date: '2024-06-19',
    history: [
      {
        change: 'Conversation timeline introduced.',
      },
      {
        change: 'Detection for auto-answer e-mails that are not flagged as one.'
      }
    ]
  },
  {
    version: '1.1.29',
    date: '2024-06-16',
    history: [
      {
        change: 'Load more customer journey items.',
      },
      {
        change: 'Conversation view no longer expanded due to conversation fields preview.',
        bug: true
      },
      {
        change: 'Support for longer body texts in drafts and internal notes.',
        bug: true
      },
    ]
  },
  {
    version: '1.1.28',
    date: '2024-06-13',
    history: [
      {
        change: 'Toolbar option "Clean up messy code" is now available on html editor" to remove unwanted html tags when copy/pasting text from other sources.',
      },
      {
        change: 'Canned answer file upload issue fixed.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
    ]
  },
  {
    version: '1.1.27',
    date: '2024-06-12',
    history: [
      {
        change: 'Auto-select organization and queue if only one option available on create conversation page.',
      },
      {
        change: 'UI fix for mail body from "Mathem"',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Hide deleted options in tree select (disposition) and display red border on box if previous selected option is no longer available.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Html editor toolbar button "Clean up messy code" added',
      }
    ]
  },
  {
    version: '1.1.26',
    date: '2024-06-11',
    history: [
      {
        change: 'Superadmins are now able to filter on organizations in conversation filter.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Browser microphone connection will be dropped while not in call. This caused noise while playing sound tracks.',
        bug: true
      },
      {
        change: 'Issue fixed where conversation filter "No queue" had no effect in conversation results.',
        bug: true
      },
      {
        change: 'Callback button restored on conversation voice call cards.',
        bug: true
      },
      {
        change: 'Audio settings for internal voice calls'
      }
    ]
  },
  {
    version: '1.1.25',
    date: '2024-06-10',
    history: [
      {
        change: 'Custom field data now selectable in journey rest-request block.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Operational hours now selectable as "Asterisk operational hours". This option is only available for super administrators.',
        roles: [RoleEnum.Superadmin],
      },
      {
        change: 'Improvements made in statistics handling of updates.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
      },
      {
        change: 'Fixed some values in statistics displaying wrong value.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Improved UI of widgets with "Split by" option.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
      },
      {
        change: 'Additional queues in conversation filter form now contains only queues available in any of agents, agent profiles.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Dialpad key press sounds added.',
      },
      {
        change: 'Allow "Un-assign" option added to queue settings.',
      },
      {
        change: 'Sub journeys should no longer override each other.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Moved conversation message options higher up in order to not cover message title/subject.',
      },
      {
        change: 'Forcing attached images in e-mail body to have a maximum width in order to avoid having the content stretched outside the conversation view.',
        bug: true
      },
    ]
  },
  {
    version: '1.1.24',
    date: '2024-05-29',
    history: [
      {
        change: 'Sub journey in sub journeys, in any depth, is now detected.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Sub journey registration to conversation is now made in a synchronous way so there is no risk of overwriting.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Journey action "Internal note" added.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'DTMF request changed from 2000 to 100ms',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.1.23',
    date: '2024-05-28',
    history: [
      {
        change: 'Disposition note in "Edit conversation" journey block is back to html form element textarea',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Typeahead form elements where your own items can be added will no longer be highlighted in red.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
    ]
  },
  {
    version: '1.1.22',
    date: '2024-05-27',
    history: [
      {
        change: 'Slow rendering of dispositions due to multi-level and number solved.',
      },
      {
        change: 'Icons in different sizes added to build',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Description added to atlas in case of pasting urls to show correct preview.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Journey rule "Mail message title" renamed to "Mail message subject"',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.1.21',
    date: '2024-05-26',
    history: [
      {
        change: `Attachments can now be charged and invocied.\n
                  \t- All attachment file sizes will be calculated at arrival and be charged.
                  \t- Define price under tariff settings.
                  \t- Mail and form attachments will now be charge accordingly.
                  \t- Results can be seen in current invoice.`,
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Highlight deleted items in form fields, with title, to indicate what has been removed instead of an id.',
      },
      {
        change: 'Auto accept for mail and form channels fixed.',
        bug: true
      },
      {
        change: 'Form submission queue assignments issue fixed',
        bug: true
      },
      {
        change: 'Max file size for field type "File" raised from 2MB to 25MB',
      },
      {
        change: 'Fully sign out user from Atlas when "Force signout" is used by administrator.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
    ]
  },
  {
    version: '1.1.20',
    date: '2024-05-25',
    history: [
      {
        change: 'WebSocket separated from rest microservice.',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.1.19',
    date: '2024-05-23',
    history: [
      {
        change: 'Option to force user sign out from admin panel user list page.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Rest-request journey block has now support for json body responses.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Fixed issue transferring blind/direct call to user in different queue.',
        bug: true
      },
      {
        change: 'Fixed issue where some attended transfer calls did not end in some cases, and caused "Busy" status on agent when idle should been shown.',
        bug: true
      },
      {
        change: 'Fixed transfer issue when call was internally transferred to another queue.',
        bug: true
      },
    ]
  },
  {
    version: '1.1.18',
    date: '2024-05-22',
    history: [
      {
        change: 'Added triggers "Field data changes" to journey "When" action-block, for Conversation and Contact updates.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Require "Client" in queue settings.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Added cypress endpoint for making/simulating inbound calls.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Changed to correct options for Contact fields.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
    ]
  },
  {
    version: '1.1.17',
    date: '2024-05-21',
    history: [
      {
        change: 'Form website field translation.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      }
    ]
  },
  {
    version: '1.1.16',
    date: '2024-05-20',
    history: [
      {
        change: 'E-mail unique id is now unique per mail account, which will open up for transferring e-mails between mail accounts.',
      },
      {
        change: 'Field translations now sets language translation to sub-languages if translation on the sub-translation is missing.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Form website title picked for form submission conversation title.'
      },
      {
        change: 'New journey action "Forward e-mail"',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Ability to transfer call to all queues and agents, options splitted into "Profile queues" and "All queues".'
      },
      {
        change: 'Field settings restored where you can make field required on different scenarios.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Voice shortcuts in call bar suggestions when not in call.'
      },
      {
        change: 'Extended editor canned answer options width.'
      },
      {
        change: 'Only related fields to the organization available in editor # field-option.',
        roles: [RoleEnum.Superadmin],
        bug: true
      }
    ]
  },
  {
    version: '1.1.15',
    date: '2024-05-18',
    history: [
      {
        change: 'Multiple parent disposition support for dispositions.',
      },
    ]
  },
  {
    version: '1.1.14',
    date: '2024-05-15',
    history: [
      {
        change: 'Form submission queue handling now available under queue settings.\nForm capacity available under user settings.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Canned answer deletion issue fixed.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: '"Additional queues" now available under conversation filter form, in order to display conversations outside of selected agent profile. These conversations are marked to clarify if its from agent profile or not.',
      }
    ]
  },
  {
    version: '1.1.13',
    date: '2024-05-14',
    history: [
      {
        change: '# option for fields now available in canned answer body.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Video formats mp4 and mov now available as mime-types for form fields of type "File".',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: '"Additional queues" now available under conversation filter form, in order to display conversations outside of selected agent profile. These conversations are marked to clarify if its from agent profile or not.',
      },
      {
        change: 'Form submissions text fields will now be searchable in global search.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Form submission display fields issue fixed',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Browser window will now be refreshed after switching profile to avoid receiving multiple voice call notifications simultaneously.',
      }
    ]
  },
  {
    version: '1.1.12',
    date: '2024-05-13',
    history: [
      {
        change: 'Form field translations added.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Form display fields added for form type "Website"',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Form conversation messages will now display "Display fields" from form settings in conversation page.',
      }
    ]
  },
  {
    version: '1.1.11',
    date: '2024-05-11',
    history: [
      {
        change: `Client module released.
        - Create Clients under "Staff" in admin menu.
        - Select relevant clients under queue settings.
        - Agents can now select client on conversations.
        - Group metric "Client" available now under stats.
        - Client also available on Journey actions like "Edit conversation" and "Rule"`,
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Clone feature for all entities',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      }
    ]
  },
  {
    version: '1.1.10',
    date: '2024-05-06',
    history: [
      {
        change: 'Custom events released for customer journey.',
      }
    ]
  },
  {
    version: '1.1.9',
    date: '2024-05-02',
    history: [
      {
        change: 'Journey action "Close tabs" released.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'More than 7 open tabs will now display a warning message to encourage agents to keep number of open tabs low.',
      }
    ]
  },
  {
    version: '1.1.8',
    date: '2024-04-30',
    history: [
      {
        change: 'Action "Fetch contact data" released to receive and merge contact data using rest request.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      }
    ]
  },
  {
    version: '1.1.7',
    date: '2024-04-29',
    history: [
      {
        change: 'Action "Rule (Contact)" released to trigger events on contact entity.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Display queue phone number instead of caller phone number when twinning.',
      }
    ]
  },
  {
    version: '1.1.6',
    date: '2024-04-28',
    history: [
      {
        change: 'Api-key module release in order to make rest-api calls using api keys.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      }
    ]
  },
  {
    version: '1.1.5',
    date: '2024-04-25',
    history: [
      {
        change: 'Form field ui type "File" is now added and available for creation for field type "Form submission".',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Form channel now supports attachments.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Sub and third-dispositions, and global dispositions no longer available as options on queue settings.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
    ]
  },
  {
    version: '1.1.4',
    date: '2024-04-24',
    history: [
      {
        change: 'Maintaining correct pause status in asterisk after profile change and browser reload.',
        bug: true
      },
      {
        change: 'Auto-pause setting moved from Queue settings to agent profile settings.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
    ]
  },
  {
    version: '1.1.3',
    date: '2024-04-23',
    history: [
      {
        change: 'New ringtone files available for selection under voice settings.',
      },
      {
        change: 'Contact data is now merged on form submission.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Update handler moved to cron-handler microservice.',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.1.2',
    date: '2024-04-22',
    history: [
      {
        change: 'Readonly (UI) added to form field settings.',
      },
      {
        change: 'New contact search endpoint added for API use.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Correct navision id should now be sent to SBC.',
        roles: [RoleEnum.Superadmin]
      }
    ]
  },
  {
    version: '1.1.1',
    date: '2024-04-21',
    history: [
      {
        change: 'Fix for oauth2 login issue using Microsoft accounts.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Added new options to journey "Rule" action. "Queue agents ready", "Queue agents busy", "Queue longest waiting" and "Queue Waiting".',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
      },
      {
        change: 'Allow attachments on canned answers.',
      },
      {
        change: 'Journey action "UnAssign" added, which allows agent and queue to be un-assigned from conversation when required.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Allow user "login strategy" to be edited.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Voice route "Inbound journey" and "description" added to list columns.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Preventing duplicate contact creation and removing duplicates from past.',
        roles: [RoleEnum.Superadmin],
        bug: true
      }
    ]
  },
  {
    version: '1.1.0',
    date: '2024-04-17',
    history: [
      {
        change: 'Audit module released',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Fixed issue where agent were not un-paused after changing profiles.',
        bug: true
      },
      {
        change: 'Text widget split issue fixed.',
        bug: true
      },
      {
        change: 'Adding "Pre-queue time" on voice call cards in conversations.',
      },
      {
        change: 'Changed asterisk languages',
        roles: [RoleEnum.Superadmin]
      }
    ]
  },
  {
    version: '1.0.14',
    date: '2024-04-08',
    history: [
      {
        change: 'Fixed issue when twinning was made even when twinning settings was not "on"',
        bug: true
      },
      {
        change: 'After editing items you will be redirected to exact same list page, with correct sorting and filtering.'
      },
      {
        change: 'Param option "Voice call contact phone" and others were added to "Rest Request" journey action block.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Params options for agent (id, first name, last name and full name) were added to "Rest request" and "Rule" Journey action blocks.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Issue fixed with users being created with type "Microsoft" and "Google"',
        bug: true
      },
      {
        change: 'Relation issue regarding tags on conversations should now be fixed.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Smtp reconnection improved.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Asterisk config "autopause" set to "all" when "yes" chosen.',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.0.13',
    date: '2024-03-12',
    history: [
      {
        change: 'Fixed styling issue where side-by-side conversations did not fit in window, where secondary conversation was squeezed.',
        bug: true
      },
      {
        change: 'Button for microphone mute/unmute is now functioning',
        bug: true
      },
      {
        change: 'Actions made to minimize "Deadlock" on MySql.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Preventing statistics microservice to send "Deadlock" emergency messages to teams channel.',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.0.12',
    date: '2024-03-10',
    history: [
      {
        change: 'Issue fixed with auto player starting from click point and not from start.',
        bug: true
      },
      {
        change: 'Operational hours no longer required on sla policy',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Bug fixed on re-send mail message, which returned an empty error while the e-mail actually was re-sent',
        bug: true
      },
      {
        change: 'Bug fixed on operational hours was not calculated correctly to find earliest start and latest end time for asterisk downgrade.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
    ]
  },
  {
    version: '1.0.11',
    date: '2024-03-06',
    history: [
      {
        change: 'Improvement on [Show more] button for e-mail messages in conversations page. This change will take action on mail message height instead of hiding elements.'
      },
      {
        change: 'BCC is now listed under mail messages in conversation page.'
      },
      {
        change: 'Different style and icon for auto reply mail messages.'
      },
      {
        change: 'Forwarded mail messages will now have the full history in the body.'
      },
      {
        change: 'User is no longer connected to auto reply mail messages. Auto reply messages are now entirely excluded from statistics.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Removed download button from pdf previewer and restyled other buttons.',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.0.10',
    date: '2024-03-03',
    history: [
      {
        change: 'Preview documents without in atlas application. ',
      },
      {
        change: 'CC e-mails when creating new conversation.',
      },
      {
        change: 'BCC e-mails when replying to e-mail or when creating new conversation.',
      },
      {
        change: 'Redis auto reconnect in case of disconnection, without application exit.',
        roles: [RoleEnum.Superadmin],
      },
      {
        change: 'Application will function without redis running. Message queueing will however not work until redis has reconnected.',
        roles: [RoleEnum.Superadmin],
      },
      {
        change: 'Catch unhandled errors and report to teams channel "Emerg" without exiting application.',
        roles: [RoleEnum.Superadmin],
      },
      {
        change: 'Fix for deleted phone numbers were not valid and throwed error. For instance for stats where widget were grouped by account.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Potential fix for calls that hangs up before we manage to insert the call in database. Result of this would be calls without a hangup timestamp.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Calls from foreign countries (outside Sweden) failed to get their phone numbers validated. As a result no contact could be generated. A fix for this should solve the issue.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'No longer receive updates from conversations after changing anget profile',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
    ]
  },
  {
    version: '1.0.9',
    date: '2024-02-27',
    history: [
      {
        change: 'Actions made to prevent incoming calls from coming to agent when paused. This issue may still occur in some corner cases. Make sure to report these occurrences when and if they occur!',
        bug: true
      },
      {
        change: 'Drag & drop file to upload on draft editor.',
      },
      {
        change: 'File upload and draft history for "Create new e-mail conversation"',
      },
      {
        change: 'Toggle between edit and html content using new [html] button on editor.',
      },
      {
        change: 'Return to same list page after editing item in all admin list pages.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'List shortcut buttons to filter journey drafts or create a new journey draft, directly from journey list.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Fixed issues on 2 of Jens widgets. Also removed metric "Total pause time" as it has exact same value as "Pause time"',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
    ]
  },
  {
    version: '1.0.8',
    date: '2024-02-21',
    history: [
      {
        change: 'Navision customer id now available on organization settings.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Super admins are now excluded from editor @ options for none super admins.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Super admins are now removed from presence display for none super admins.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Canned answers on draft editor are now searchable.',
      },
      {
        change: '[Select queue] input field in conversation header is now searchable.',
      },
      {
        change: 'Tree select inputs are now searchable.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Tree select inputs are now searchable.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: '<style> is now removed from mail body to avoid style injection.',
        bug: true
      },
      {
        change: '[Select all] button now available on multi select inputs with.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Outbound routes are now hidden on none voice queues.',
        bug: true
      },
      {
        change: 'Draft attachments will now be attached on e-mail forward.'
      },
      {
        change: 'Attachments on draft will now be removed when draft is deleted.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Stuck voice call bridges will now be terminated by cron as there are no other ways to handle this.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Fixed null exception on user_status.user',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
    ]
  },
  {
    version: '1.0.7',
    date: '2024-02-20',
    history: [
      {
        change: 'Added changelog.'
      },
      {
        change: 'Added current version to atlas logo. Click to display changelog.'
      },
      {
        change: 'Added button to display and toggle theme on atlas logo.'
      },
    ]
  },
  {
    version: '1.0.6',
    date: '2024-02-19',
    history: [
      {
        change: 'E-mail attachments with filenames including spaces or none AZ characters where not handled correctly for inbound and outbound e-mails. This issue is now fixed.',
        bug: true
      }
    ]
  },
  {
    version: '1.0.5',
    date: '2024-02-18',
    history: [
      {
        change: 'Journey was reset to original when update happened in the background is now fixed.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      },
      {
        change: 'Fixed the issue when e-mails exceeded dashboard width.',
        bug: true
      },
      {
        change: 'Conversation SLA box is now adjusted to show "Reply in" or "Resolve in " depending on the conversation status.',
        bug: true
      },
      {
        change: 'Improvements to the conversation list where checkboxes was hidden until mouseover, and other styling adjustments!',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'New validation on journey lines. Incorrect lines between none equal channels will be detected!',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Fixed issue with voice call ending up in wrong conversation.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Issue fixed with background color on text widget metrics, that was visible on background color with lower opacity than 100% was selected.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Issue fixed with filter not affecting on live metrics.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Added options "No queue" and "No user" to queue and user filter on stats widget form.',
        roles: [RoleEnum.Superadmin]
      },
      {
        change: 'Sub group by & split by missing titles/labels in form fixed.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Fixed issue with widget pie total/summary color was same as background.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Changed "Average ongoing time of voice calls" to "Average talk time of voice calls"',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Fixed issue with sort by not working on stats widget settings.',
        roles: [RoleEnum.Superadmin],
        bug: true
      },
      {
        change: 'Added multiple new metrics for answered and abandoned within or after, compared to total answered or abandoned.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Added mute button, for microphone, to call bar.',
      },
      {
        change: 'Added filter by organization to user list.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin]
      },
      {
        change: 'Disposition now required on both statuses resolved and closed. Setting "Required on conversation closed" will now include both statuses "Resolved" and "Closed"',
        bug: true
      },
      {
        change: 'Added button "To me" besides button "Assign to" in conversation box buttons. Will appear only when queue is selected on conversation.',
      },
      {
        change: 'Fixed issue with adding new voice route.',
        roles: [RoleEnum.Superadmin]
      },
    ]
  },
  {
    version: '1.0.4',
    date: '2024-02-14',
    history: [
      {
        change: 'Issue with blind transfers fixed.',
        roles: [RoleEnum.Superadmin, RoleEnum.Admin],
        bug: true
      }
    ]
  }
];
