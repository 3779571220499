import {
  ChannelEnum,
  DirectionEnum,
  FormTypeEnum,
  IPreviewForm,
  IPreviewJourney,
  ISharedCreateFormWebsite,
  ISharedFormWebsite
} from 'atlas-shared';
import { TFunction } from 'i18next';
import { IFormOptions } from 'atlas-form';
import {
  FormWebsiteFormOptions as FormWebsiteFormOptionsBase,
  IFormWebsiteFormOptionsProps
} from './abstract/form_website.form.abstract';
import { filterRelated } from '@Utils';

export * from './abstract/form_website.form.abstract';

export const FormWebsiteFormOptions = (form_website: ISharedCreateFormWebsite | ISharedFormWebsite, props: IFormWebsiteFormOptionsProps, t: TFunction): IFormOptions => ({
  ...FormWebsiteFormOptionsBase(form_website, props, t),
  journey_id: (it: ISharedFormWebsite) => filterRelated<IPreviewJourney>(it.organization_id || form_website.organization_id, 'journey', 'form_website', props.journeies, true, it => it.channels.some(({ c, d }) => c === ChannelEnum.Form && d === DirectionEnum.InBound)),
  form_id: (it: ISharedFormWebsite) => filterRelated<IPreviewForm>(it.organization_id || form_website.organization_id, 'form', 'form_website', props.forms, true, it => it.type === FormTypeEnum.Website)
});
