import { IPreviewStatsWidget, IMetric, IPreviewStatsCustomMetric, IPreviewStatsReport, StatsWidgetTypeEnum, IPreviewOrganization } from 'atlas-shared';
import './stats_widget.split.view.scss';
import { TFunction } from 'i18next';
import { IStatsCustomMetricStore } from '@Store';
import { getStatsWidgetMetricData, getStatsWidgetSplitHeaderStyles, IMetricDataDicts } from '@Utils';
import { useMemo } from 'react';
import { StatsWidgetTypesRechartsMap } from './widget-type/recharts/map';

interface IProps {
  t: TFunction;
  organization: IPreviewOrganization;
  stats_report: IPreviewStatsReport;
  stats_widget: IPreviewStatsWidget;
  data: IPreviewStatsWidget['data'];
  metrics: Array<IMetric | IPreviewStatsCustomMetric>;
  stats_custom_metrics: IStatsCustomMetricStore;
  metric_data_dicts: IMetricDataDicts;
  has_trend: boolean;
  width: number;
  height: number;
  innerWidth: number;
  innerHeight: number;
}
export const StatsWidgetSplitView = ({ stats_widget, stats_report, t, data, metrics, stats_custom_metrics, metric_data_dicts, has_trend, width, height, innerWidth, innerHeight, organization }: IProps) => {
  const Component2 = StatsWidgetTypesRechartsMap[stats_widget.type];
  const splits = data?.r || [];
  const items = splits.length || 0;
  const splitPerRow = useMemo(() => {
    let perRow = 1;
    const widgetWidth = stats_widget.type === StatsWidgetTypeEnum.Table ? (stats_widget.settings.metrics.length * 120) + ([...stats_widget.settings.group_by, ...stats_widget.settings.sub_group_by].length * 55) : 380;

    while (true) {
      if (perRow < items && innerWidth / (perRow + 1) > widgetWidth)
        perRow++;
      else
        break;
    }

    return Math.min(7, perRow);
  }, [innerWidth]);
  const { rowsArr, splitPrc, splitWidth, splitHeight } = useMemo(() => {
    const rows = items ? Math.ceil(items / splitPerRow) : 0;

    const rowsArr = items ? new Array(rows).fill([]).map((_, row) => {
      return new Array(Math.min(splitPerRow, items - (row * splitPerRow))).fill(0).map((_, s) => (row * splitPerRow) + s);
    }) : [];
    const splitPrc = 100 / splitPerRow;
    const splitWidth = (innerWidth / splitPerRow) - ((splitPerRow - 1) * 10 / splitPerRow);

    return { rowsArr, splitPrc, splits, splitWidth, splitHeight: !stats_report.is_dashboard ? splitWidth * .65 : Math.min((innerHeight / rows) - 15 - (rows ? 10 : 0), splitWidth * .65) };
  }, [splitPerRow, items, innerWidth]);

  return <div className='splits'>
    {(stats_widget.settings?.split_by?.length || 0) > 0 && (rowsArr || []).flatMap((_, r) => {
      return <div className='split-row' key={r}>{
        _.map((i, s) => {
          const split = splits[i];

          return <div className='split' style={{ width: `${splitPrc}%` }} key={s}>
            <h2 style={getStatsWidgetSplitHeaderStyles(stats_widget, split.d)}>
              {stats_widget.settings.split_by.map((m, i) => getStatsWidgetMetricData(t, m, split.d[i], metric_data_dicts, organization))}
            </h2>
            <Component2
              t={t}
              data={{
                g: 'split',
                r: [split]
              }}
              stats_widget={stats_widget}
              stats_report={stats_report}
              metrics={metrics}
              width={splitWidth}
              height={height}
              innerWidth={splitWidth - 5}
              innerHeight={splitHeight - 5}
              stats_custom_metrics={stats_custom_metrics}
              metric_data_dicts={metric_data_dicts}
              has_trend={has_trend}
              organization={organization}
            />
          </div>;
        })
      }</div>;
    })}
  </div>;
};
