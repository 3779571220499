import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchVoiceInternalCalls, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useVoiceInternalCalls = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_internal_calls = useSelector((store: IReducerStore) => store.voice_internal_calls);

  useEffect(() => {
    if (!initiated && autoload && !voice_internal_calls.loaded && !voice_internal_calls.loading) {
      initiated = true;
      dispatch(actionFetchVoiceInternalCalls());
    }
  }, [autoload, voice_internal_calls.loaded, voice_internal_calls.loading, dispatch]);

  return voice_internal_calls;
};
