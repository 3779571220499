import { VoiceVoicemailMessageActions } from '@Store';
import { IPreviewVoiceVoicemailMessage } from 'atlas-shared';
import { fetchVoiceVoicemailMessages } from '@Api';

export const actionFetchVoiceVoicemailMessages = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceVoicemailMessages());
    const voice_voicemail_messages = await fetchVoiceVoicemailMessages();

    dispatch(actionSetVoiceVoicemailMessages(voice_voicemail_messages));
    return voice_voicemail_messages;
  };
};

export const actionLoadingVoiceVoicemailMessages = () => ({
  type: VoiceVoicemailMessageActions.LOADING,
  payload: []
});

export const actionSetVoiceVoicemailMessages = (payload: Array<IPreviewVoiceVoicemailMessage>) => ({
  type: VoiceVoicemailMessageActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceVoicemailMessage = (payload: IPreviewVoiceVoicemailMessage) => ({
  type: VoiceVoicemailMessageActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceVoicemailMessage = (payload: IPreviewVoiceVoicemailMessage) => ({
  type: VoiceVoicemailMessageActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveVoiceVoicemailMessage = (id: IPreviewVoiceVoicemailMessage['id']) => ({
  type: VoiceVoicemailMessageActions.REMOVE_STATE,
  payload: id
});
