import { AdminprofileActions } from '@Store';
import { IPreviewAdminprofile } from 'atlas-shared';
import { fetchAdminprofiles } from '@Api';

export const actionFetchAdminprofiles = () => {
  return async (dispatch) => {
    dispatch(actionLoadingAdminprofiles());
    const adminprofiles = await fetchAdminprofiles();

    dispatch(actionSetAdminprofiles(adminprofiles));
    return adminprofiles;
  };
};

export const actionLoadingAdminprofiles = () => ({
  type: AdminprofileActions.LOADING,
  payload: []
});

export const actionSetAdminprofiles = (payload: Array<IPreviewAdminprofile>) => ({
  type: AdminprofileActions.SET_STATE,
  payload: payload
});

export const actionAddAdminprofile = (payload: IPreviewAdminprofile) => ({
  type: AdminprofileActions.ADD_STATE,
  payload: payload
});

export const actionUpdateAdminprofile = (payload: IPreviewAdminprofile) => ({
  type: AdminprofileActions.UPDATE_STATE,
  payload: payload
});
