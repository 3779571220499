import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './conversation.reply.scss';
import { Select, Skeleton } from 'antd';
import { TrashIcon } from '@Assets/icons';
import {
  CannedAnswerTypeEnum,
  DraftChannelEnum,
  IPreviewCannedAnswer,
  IPreviewQueue,
  IPreviewSignature,
  ISharedDraft,
  ISharedFullConversation,
  ISharedMailMessage,
  Nullable,
  SignatureTypeEnum,
  Undefinable,
  IPreviewMailAccount
} from 'atlas-shared';
import { deleteDraftAttachment, downloadDraftAttachment, patchDraft, sendDraft } from '@Api';
import { AlertError, Confirm, filterRelated, TypeModal, RestRequest, isEmailValid } from '@Utils';
import { useCannedAnswers, useMailAccounts, useQueues, useSignatures } from '@Hooks';
import { FormSelect, FormHtmlEditor } from 'atlas-form';
import { Button, Icon } from '@Components';
import { ConversationMessageReplyTimer } from './conversation.message.reply.timer';

interface IProps {
  draft: ISharedDraft;
  conversation: ISharedFullConversation;
  onDelete?: () => Promise<any>;
}

export const ConversationReply = React.memo(({ conversation, onDelete, draft: _draft }: IProps) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [draft, _setDraft] = useState<ISharedDraft>(_draft);
  const signatures = useSignatures();
  const mail_accounts = useMailAccounts();
  const canned_answers = useCannedAnswers();
  const queues = useQueues();
  const [queue, setQueue] = useState<Undefinable<IPreviewQueue>>();
  const [save, setSave] = useState<Nullable<number>>(null);
  const [sent, setSent] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  // const [channels, setChannels] = useState<Array<any>>([]);
  const [ccOptions, setCCOptions] = useState<Array<string>>([]);
  const [bccOptions, setBCCOptions] = useState<Array<string>>([]);
  const [emailOptions, setEmailOptions] = useState<Array<ISharedMailMessage['contact_email']>>([]);
  const [addedEmailOption, setAddedEmailOption] = useState<Undefinable<ISharedMailMessage['contact_email']>>();
  const [history, setHistory] = useState<Nullable<string>>(null);

  const mailAccount = useMemo(() => {
    return draft.account_id && draft.channel === DraftChannelEnum.Mail && mail_accounts.dict[draft.account_id];
  }, [mail_accounts, draft]);

  useEffect(() => {
    if (queues.loaded && conversation.queue_id)
      setQueue(queues.dict[conversation.queue_id]);
  }, [queues, conversation.queue_id]);

  useEffect(() => {
    setEmailOptions([...(conversation.contact?.emails || []), addedEmailOption as ISharedMailMessage['contact_email']].filter(Boolean));
  }, [conversation.contact, addedEmailOption]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (mailAccount)
      setCCOptions([
        ...draft.reply_to_cc,
        ...conversation.messages.map(m => (m as ISharedMailMessage).cc || []).flat().flatMap(cc => cc.address),
        ...conversation.messages.map(m => (m as ISharedMailMessage).bcc || []).flat()
      ].filter(address => address !== mailAccount.address));
  }, [conversation.messages, mailAccount]);

  useEffect(() => {
    if (mailAccount)
      setBCCOptions([
        ...draft.reply_to_bcc,
        ...conversation.messages.map(m => (m as ISharedMailMessage).cc || []).flat().flatMap(cc => cc.address),
        ...conversation.messages.map(m => (m as ISharedMailMessage).bcc || []).flat()
      ].filter(address => address !== mailAccount.address));
  }, [conversation.messages, mailAccount]);

  useEffect(() => {
    // const prefix = `${ChannelEnum.Mail}-`;
    //
    // if (history || !draft.reply_to || !draft.reply_to.startsWith(prefix))
    //   return;
    //
    // const mail_message_id = +draft.reply_to.replace(prefix, '');
    //
    // const reply_message = conversation.messages.find(message => message.channel === ChannelEnum.Mail && message.id === mail_message_id);
    //
    // if (reply_message)
    //   setHistory(blockquoteHistory(reply_message as ISharedMailMessage));
  }, [conversation, draft, history]);

  // useEffect(() => {
  //
  //   if (!auth?.user)
  //     return;
  //
  //   setChannels(EnumToArray(DraftChannelEnum).filter(channel => {
  //     if (channel[0] === DraftChannelEnum.Mail)
  //       return canEmail(auth.user);
  //
  //     return true;
  //   }));
  // }, [auth?.user]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!draft)
      return;

    _setDraft({ ...draft, attachments: _draft.attachments });
  }, [_draft.attachments]);

  const _patchDraft = useCallback((draft: ISharedDraft) => {
    /* eslint-disable unused-imports/no-unused-vars */
    const { id, organization_id, created_at, updated_at, ...patch } = draft;

    return patchDraft(id, patch);
  }, []);

  const saveDraft = useCallback(() => {
    return _patchDraft(draft)
      .then(saved => {
        _setDraft({ ...draft, updated_at: saved.updated_at });
      })
      .catch(error => {
        AlertError(t, { title: t('ERROR'), content: error.toString() });
      });
  }, [draft, t, _patchDraft]);

  useEffect(() => {
    if (!save || sent)
      return;

    const timer = setTimeout(() => {
      saveDraft();
      setSave(null);
    }, 2000);

    return () => clearTimeout(timer);
  }, [save, sent, setSave, saveDraft]);

  const setDraft = useCallback((partial_draft: Partial<ISharedDraft>) => {
    const _draft = { ...draft, ...partial_draft };

    _setDraft(_draft);
    setSave(Math.random());
  }, [draft]);

  if (sending)
    return <div style={{ marginBottom: 20, opacity: .5 }}><Skeleton avatar active paragraph={{ rows: 3 }} /></div>;

  return (
    <>
      <div className={'reply-wrapper'}>
        {
          draft.channel === DraftChannelEnum.Mail &&
          <div className={'subject-container'}>
            <span className={'text'}>
              {t('SUBJECT')}
            </span>
            <input
              className='subject-input'
              onChange={e => setDraft({ subject: e.target.value })}
              defaultValue={draft.subject || ''}
            />
          </div>
        }
        <div className='reply-contents'>
          <div className='reply-header'>
            <div className='h-left'>
              {
                draft.channel === DraftChannelEnum.Mail &&
                <>
                  {<div className='section'>
                    <label>{t('FROM')}</label>
                    <FormSelect
                      onChange={v => setDraft({ account_id: v || null })}
                      value={draft.account_id || undefined}
                      className='bold'
                      options={filterRelated(draft.organization_id, 'mail_account', 'conversation', mail_accounts.mail_accounts, true, (mail_account: IPreviewMailAccount) => (queue?.__outbound_mail_accounts || []).includes(mail_account.id))}
                    />
                  </div>}
                  <div className='section' >
                    <label>{t('TO')}</label>
                    <Select showSearch onChange={v => setDraft({ reply_to_address: v })} value={draft.reply_to_address} onKeyDown={e => {
                      if (e.keyCode === 13) {
                        const email = (e.target as HTMLInputElement)?.value;

                        if (isEmailValid(email)) {
                          setAddedEmailOption(email);
                          setDraft({ reply_to_address: email });
                        }
                      }
                    }}>
                      {emailOptions.map(email => <Option key={email} value={email}>{email}</Option>)}
                    </Select>
                  </div>
                  <div className='section' >
                    <label>{t('CC')}</label>
                    <Select dropdownMatchSelectWidth={false} onChange={v => setDraft({ reply_to_cc: (v || []).filter(m => isEmailValid(m)) })} value={draft.reply_to_cc} mode='tags'>
                      {ccOptions.map(email => <Option key={email} value={email}>{email}</Option>)}
                    </Select>
                  </div>
                  <div className='section' >
                    <label>{t('BCC')}</label>
                    <Select dropdownMatchSelectWidth={false} onChange={v => setDraft({ reply_to_bcc: (v || []).filter(m => isEmailValid(m)) })} value={draft.reply_to_bcc} mode='tags'>
                      {bccOptions.map(email => <Option key={email} value={email}>{email}</Option>)}
                    </Select>
                  </div>
                </>
              }
            </div>
            <div className='h-right'>
              <Icon onClick={() => Confirm(t, { onOk: (modal: TypeModal) => onDelete?.().then(deleted => modal.destroy()), title: t('PERMANENTLY_DELETE_DRAFT') })} icon={TrashIcon} iconProps={{ size: 17 }} tooltip={{ title: t('DELETE_DRAFT') }}/>
            </div>
          </div>
          {canned_answers.loaded && <FormHtmlEditor
            onChange={value => {
              setDraft({ body: value });
            }}
            value={draft.body}
            hashOptions={['contact', 'conversation', 'agent']}
            atOptions={['user']}
            canned_answers={draft.channel === DraftChannelEnum.InternalNote ? [] : canned_answers.canned_answers.filter((canned_answer: IPreviewCannedAnswer) => canned_answer.type == CannedAnswerTypeEnum.Agent && canned_answer.channel === (draft.channel as any) && (canned_answer.global || (queue?.__canned_answers || []).includes(canned_answer.id)))}
            file_upload={{
              attachments: draft.attachments || [],
              action: RestRequest.getFullSrc(`/draft/${draft.id}/attachment`),
              headers: RestRequest.getHeaders(),
              onDelete: (uid: number) => deleteDraftAttachment(draft.id, uid),
              onDownload: (uid: number, name: string) => downloadDraftAttachment(draft.id, uid, name),
              onDownloadUri: (index: number) => `draft/${draft.id}/attachment/${index}`,
              draft_id: draft.id
            }}
          />}
          <div className='reply-footer'>
            {
              draft.channel !== DraftChannelEnum.InternalNote && <div className='signature'>
                <label>{t('SIGNATURE')}</label>
                <FormSelect
                  onChange={v => setDraft({ signature_id: v || null })}
                  value={draft.signature_id || undefined}
                  className='bold'
                  options={filterRelated(draft.organization_id, 'signature', 'conversation', signatures.signatures, true, (signature: IPreviewSignature) => signature.type == SignatureTypeEnum.Agent && signature.channel === (draft.channel as any) && (signature.global || (queue?.__agent_signatures || []).includes(signature.id)))}
                />
              </div>
            }
            <div className='update-since'>
              <label>{t('LAST_SAVE')}</label>
              <ConversationMessageReplyTimer t={t} since={draft.updated_at} />
            </div>
            <Button onClick={() => saveDraft()} className='draft' >
              {t('SAVE_DRAFT')}
            </Button>
            <Button type='primary' onClick={() => {
              return new Promise((resolve) => {
                setSending(true);
                saveDraft()
                  .then(_ => {
                    sendDraft(draft.id)
                      .then(() => {
                        setSent(true);
                        setSending(false);
                      })
                      .catch(e => {
                        AlertError(t, { content: e });
                        setSending(false);
                      });
                  })
                  .finally(() => resolve(true));
              });
            }} disabled={!draft.body}>
              {t(draft.channel === DraftChannelEnum.InternalNote ? 'CREATE_INTERNAL_NOTE' : 'SEND')}
            </Button>
          </div>
          {history && <FormHtmlEditor
            onChange={value => {
              setHistory(value);
            }}
            value={history}
          />}
        </div>
      </div>
    </>
  );
});
