import { IPreviewPhonenumberPurchase } from 'atlas-shared';

export enum PhonenumberPurchaseActions {
  SET_STATE = 'PHONENUMBER_PURCHASE_SET_STATE',
  ADD_STATE = 'PHONENUMBER_PURCHASE_ADD_STATE',
  REMOVE_STATE = 'PHONENUMBER_PURCHASE_REMOVE_STATE',
  GET_STATE = 'PHONENUMBER_PURCHASE_GET_STATE',
  UPDATE_STATE = 'PHONENUMBER_PURCHASE_UPDATE_STATE',
  LOADING = 'PHONENUMBER_PURCHASE_LOADING',
}

export interface IPhonenumberPurchaseLoading {
  type: PhonenumberPurchaseActions.LOADING;
}

export interface IPhonenumberPurchaseSetAction {
  type: PhonenumberPurchaseActions.SET_STATE;
  payload: Array<IPreviewPhonenumberPurchase>;
}

export interface IPhonenumberPurchaseUpdateAction {
  type: PhonenumberPurchaseActions.UPDATE_STATE;
  payload: IPreviewPhonenumberPurchase;
}

export interface IPhonenumberPurchaseAddAction {
  type: PhonenumberPurchaseActions.ADD_STATE;
  payload: IPreviewPhonenumberPurchase;
}

export interface IPhonenumberPurchaseRemoveAction {
  type: PhonenumberPurchaseActions.REMOVE_STATE;
  payload: IPreviewPhonenumberPurchase['id'];
}

export interface IPhonenumberPurchaseStore {
  loaded: boolean;
  loading: boolean;
  phonenumber_purchases: Array<IPreviewPhonenumberPurchase>;
  dict: Record<IPreviewPhonenumberPurchase['id'], IPreviewPhonenumberPurchase>;
}

export type TPhonenumberPurchaseActionTypes = IPhonenumberPurchaseSetAction | IPhonenumberPurchaseUpdateAction | IPhonenumberPurchaseAddAction | IPhonenumberPurchaseRemoveAction | IPhonenumberPurchaseLoading;
