import { Skeleton } from 'antd';
import React from 'react';

interface IProps {
  long?: boolean;
}

export const ConversationListLeftSkeleton = ({ long }: IProps) => {
  return <>
    {
      new Array(long ? 8 : 3).fill(null).map((row, index) => <div key={index} style={{ display: 'flex', padding: 10 }}>
        <Skeleton.Avatar active={true} size={'small'} shape={'square'} style={{ margin: '4px 10px 5px 4px', height: 18, width: 18 }} />
        <Skeleton.Avatar active={true} size={'small'} style={{ marginRight: 18, height: 28, width: 28 }} />
        <div>
          <Skeleton.Button active={true} size={'small'} style={{ width: 320, marginBottom: 5, height: 18 }} />
          <Skeleton.Button active={true} size={'small'} style={{ width: 320, marginBottom: 8, height: 18 }} />
          <Skeleton.Button active={true} size={'small'} style={{ width: 280, height: 15 }} />
        </div>
      </div>)
    }
  </>;
};
