import React, { useCallback, useMemo } from 'react';
import { TFunction } from 'i18next';
import { IPreviewTab, ISharedInternalNote, ISharedFullConversation, TabSizeEnum } from 'atlas-shared';
import { useNavigate } from 'react-router-dom';
import { actionRequestTab, useAppDispatch } from '@Store';
import { useTabs } from '@Hooks';
import { fetchConversation, patchTab } from '@Api';

interface IProps {
  t: TFunction;
  message: ISharedInternalNote;
  conversation: ISharedFullConversation;
  tab?: IPreviewTab;
}

export const ConversationMessageInternalNoteBody = ({ message, t, tab, conversation }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tabs = useTabs();
  
  const linkedConversationLink = useCallback((parsed) => {
    return <span className='clickable link' onClick={() => {
      if (tab) {
        fetchConversation(parsed.organization_id, parsed.id)
          .then(linked_conversation => {
            if (tab.size === TabSizeEnum.Maximized)
              tabs.tabs.forEach(tab => tab.size === TabSizeEnum.Maximized && patchTab(tab.id, { size: TabSizeEnum.Minimized }));

            return dispatch(actionRequestTab(linked_conversation, linked_conversation.organization_id, tab.size, tabs, () => {}));
          })
          .catch(() => {})
        ;
        return;
      }

      navigate(`/dashboard/conversation/${parsed.organization_id}/${parsed.id}`);
    }}>{parsed.title}</span>;
  }, [tabs]);

  const body: {safe: false; body: string} | {safe: true; body: Array<string | React.ReactElement>} = useMemo(() => {
    let safe: boolean = false;

    const parsedBody = message.body.split(/(\[\[\[.+\]\]\])/gm).map(a => {
      const from = a.startsWith('[[[merge-from=');
      const to = !from && a.startsWith('[[[merge-to=');

      if (from || to) {
        const parsed = JSON.parse(a.replace('[[[merge-from=', '').replace('[[[merge-to=', '').replace(']]]', ''));

        safe = true;

        const link = linkedConversationLink(parsed);

        return <>
          {to && link}&nbsp;{t(from ? 'CONVERSATION_MERGE_FROM' : 'CONVERSATION_MERGE_TO', { replace: { conversation: '' } })}&nbsp;{from && link}
        </>;
      }

      return a;
    });

    if (safe)
      return { safe: true, body: parsedBody };

    return { safe: false, body: parsedBody.join('') };
  }, [message.body, tabs]);
  
  return body.safe ? <span className={'body'}>{body.body}</span> : <span className={'body'} dangerouslySetInnerHTML={{ __html: body.body as string }} />;
};
