import { useCalls } from '@Hooks';
import { Modal } from 'antd';
import React from 'react';
import './voice-calls-modal.scss';
import { actionSetGlobalCall, actionSetGlobalCalls, useAppDispatch } from '@Store';
import { useTranslation } from 'react-i18next';

export const VoiceCallsModal = () => {
  const calls = useCalls();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!calls?.length)
    return <></>;

  return <Modal
    visible={!!calls?.length}
    width={420}
    className={'voice-calls-modal'}
    onCancel={() => dispatch(actionSetGlobalCalls(null))}
    zIndex={100}
  >
    <h3>{t('SELECT_AN_OPTION')}</h3>
    <div className='voice-call-options'>
      {calls?.map(({ title, ...call }) => {
        return <div 
          onClick={() => {
            dispatch(actionSetGlobalCall(call));
            dispatch(actionSetGlobalCalls(null));
          }}
        >
          {title}
        </div>;
      })}
    </div>
  </Modal>;
};
