import React from 'react';
import { getStatsWidgetChartMetricStyles, getStatsWidgetMetricTitle } from '@Utils';
import { TFunction } from 'i18next';
import { IPreviewStatsWidget, IPreviewStatsWidgetSettingsMetricProperty, StatsWidgetTypeEnum } from 'atlas-shared/dist';
import { TooltipProps } from 'recharts';
import { IStatsCustomMetricStore } from '@Store';
import { WIDGET_COLOR_PALETTE } from '@Components/stats/widget-types/widget.color.palette';

interface IProps extends TooltipProps<number, string> {
  t: TFunction;
  metrics: Array<IPreviewStatsWidgetSettingsMetricProperty>;
  stats_custom_metrics: IStatsCustomMetricStore;
  group_based?: boolean;
  stats_widget: IPreviewStatsWidget;
  data: Array<any>;
}

export const WidgetChartLegend = React.memo((props: IProps) => {
  if (props.group_based) {
    const [metric] = props.stats_widget.settings.metrics;

    return <ul className='recharts-default-legend'>
      {props.data.map((g, i) => {
        const styles = getStatsWidgetChartMetricStyles(props.t, g.value, g.value_trend || 0, g.value_trend_comparison, metric, i, props.stats_widget, props.stats_custom_metrics.stats_custom_metrics);

        return <li className={`recharts-legend-item legend-item-${i}`} key={`item-${i}`}>
          <div className='recharts-legend-item-color' style={{
            backgroundColor: styles.backgroundColor,
            borderWidth: 0
          }}></div>
          <div className='recharts-legend-item-title'>{g.name}</div>
        </li>;
      })}
    </ul>;
  }

  const forcedBorder = [StatsWidgetTypeEnum.Radar, StatsWidgetTypeEnum.Line].includes(props.stats_widget.type);
  const filteredPayloads = (props.payload || []).filter(p => !(p?.value + '').endsWith('_trend'));

  return <ul className='recharts-default-legend'>
    {
      ([...props.stats_widget.settings.metrics || [], ...(props.stats_widget.settings.nested_metrics || []).flatMap(({ metrics }) => metrics)]).map((metric, i) => {
        
        const { elm } = getStatsWidgetMetricTitle(props.t, metric, props.stats_custom_metrics.stats_custom_metrics);
        const filteredPayload = filteredPayloads[i];
        const styles = filteredPayload?.value ? getStatsWidgetChartMetricStyles(props.t, filteredPayload.payload?.value || 0, filteredPayload.payload?.value_trend || 0, filteredPayload.payload.value_trend_comparison, metric, i, props.stats_widget, props.stats_custom_metrics.stats_custom_metrics) : {};

        return <li className={`recharts-legend-item legend-item-${i}`} key={`item-${i}`}>
          <div className='recharts-legend-item-color' style={{
            borderColor: styles.borderColor || metric.border?.color || (forcedBorder && metric.bg_color) || WIDGET_COLOR_PALETTE[i],
            borderWidth: styles.borderWidth || metric.border?.width || (forcedBorder ? 2 : 0)
          }}>
            <span className='bg' style={{
              backgroundColor: styles.backgroundColor || metric.bg_color || filteredPayload?.[i]?.payload?.fill,
              opacity: props.payload?.[i]?.payload?.fillOpacity || 1
            }}></span>
          </div>
          <div className='recharts-legend-item-title'>{elm}</div>
        </li>;
      })
    }
  </ul>;
});
//}, (prevProps, nextProps) => JSON.stringify(prevProps.payload) === JSON.stringify(nextProps.payload) && JSON.stringify(prevProps.metrics) === JSON.stringify(nextProps.metrics));
