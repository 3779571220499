import React, { useEffect, useState } from 'react';
import { ConversationWatcherSourceEnum } from 'atlas-shared';
import { BookOpenIcon } from '@Assets/icons';
import './conversation.view.scss';
import { withFullConversation, IWithFullConversationProps } from '@Hocs/with.full.conversation';
import { Skeleton } from 'antd';
import { ConversationMessages, CustomerJourneyListMini, Icon } from '@Components';
import { useStableNavigate } from '../../../context/StableNavigateContext';
import { ContactCustomEventView } from '@Components/contact_custom_event/contact_custom_event.view';
import { useTranslation } from 'react-i18next';

interface IProps extends IWithFullConversationProps {
}

const ConversationViewComponent = (props: IProps) => {
  const {
    organization_id,
    conversation_id,
    conversation,
    customerJourneyVisible,
    setCustomerJourneyVisible,
    editConversationVisible,
    setEditConversationVisible,
    secondaryConversation,
    secondaryConversationId,
    event,
    eventId,
    setEventId,
    setSecondaryConversationId,
    conversationForm,
    contact_view,
    conversation_title_view,
    customer_journey_view,
    contact_form_view,
    conversation_form_view,
    conversationTimeline,
    onConversationTimelineClose,
    contactTimeline,
    onContactTimelineClose
  } = props;

  const [shrinked, setShrinked] = useState<boolean>(false);
  const navigate = useStableNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setShrinked(!!(contact_form_view || conversation_form_view || (customerJourneyVisible && customer_journey_view)));
  }, [contact_form_view, conversation_form_view, customerJourneyVisible, customer_journey_view]);

  return (
    <div className={'conversation-wrapper'}>
      <div className={'conversation-container'}>
        <div className={'header'}>
          <div className='box conversation-info-box'>
            {conversation_title_view}
            <div className={'vr'}/>
          </div>
          {
            contact_view && <div className='box conversation-contact-box'>
              {contact_view}
              <div className={'vr'}/>
            </div>
          }
          {conversation.journey.length > 0 && <div className={'box conversation-journey-box'}>
            <div className={'customer-journey'}>
              <CustomerJourneyListMini
                t={t}
                conversation={conversation}
                journeys={conversation.journey}
                setSecondaryConversationId={setSecondaryConversationId}
                setEventId={setEventId}
              />
              <Icon icon={BookOpenIcon} className='journey-open' iconProps={{ size: 22 }} onClick={() => setCustomerJourneyVisible(!customerJourneyVisible)}/>
            </div>
          </div>}
        </div>
        <div className={`body${shrinked ? ' shrinked' : ''}`}>
          {
            conversation && <ConversationMessages
              organization_id={organization_id}
              conversation_id={conversation_id}
              conversation={conversation}
              source={ConversationWatcherSourceEnum.Dashboard}
              form={conversationForm}
              editConversationVisible={editConversationVisible}
              setEditConversationVisible={setEditConversationVisible}
              conversationTimeline={conversationTimeline}
              onConversationTimelineClose={onConversationTimelineClose}
              contactTimeline={contactTimeline}
              onContactTimelineClose={onContactTimelineClose}
            />
          }
          { contact_form_view }
          { conversation_form_view }
          { customerJourneyVisible && customer_journey_view }
        </div>
      </div>
      {
        secondaryConversationId && secondaryConversation &&
        <div className={'secondary-view'}>
          <ConversationMessages
            className={'secondary-list'}
            source={ConversationWatcherSourceEnum.Dashboard}
            organization_id={organization_id}
            conversation_id={secondaryConversationId}
            conversation={secondaryConversation}
            header={{ onClose:() => {
              navigate(`/dashboard/conversation/${conversation.organization_id}/${conversation.id}`);
              setSecondaryConversationId(undefined);
            } }}
            isSecondary
          />
        </div>
      }
      {
        eventId && event && <div className={'secondary-view'}>
          <ContactCustomEventView
            event={event}
            onClose={() => {
              navigate(`/dashboard/conversation/${conversation.organization_id}/${conversation.id}`);
              setEventId(undefined);
            }}
          />
        </div>
      }
    </div>
  );
};

export const ConversationView = React.memo(withFullConversation(ConversationViewComponent, ConversationWatcherSourceEnum.Dashboard, false, <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
  <div style={{ display: 'flex', width: '100%' }}>
    {new Array(3).fill(null).map((_, i) => <div key={i} style={{ width: '33%', display: 'flex', padding: 15 }}>
      <Skeleton active paragraph={{ rows: 3 }} />
    </div>)}
  </div>
  <div style={{ padding: 40 }}>
    {new Array(3).fill(null).map((_, i) => <div key={i} style={{ marginBottom: 20, opacity: .5 }}><Skeleton avatar active paragraph={{ rows: 3 }} /></div>)}

  </div>
</div>));
