import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchSuperadminProfiles, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useSuperadminProfiles = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const superadmin_profiles = useSelector((store: IReducerStore) => store.superadmin_profiles);

  useEffect(() => {
    if (!initiated && autoload && !superadmin_profiles.loaded && !superadmin_profiles.loading) {
      initiated = true;
      dispatch(actionFetchSuperadminProfiles());
    }
  }, [autoload, superadmin_profiles.loaded, superadmin_profiles.loading, dispatch]);

  return superadmin_profiles;
};
