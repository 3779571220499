import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const WidgetRadarIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g>
      <path fill="#444" fillRule="nonzero" stroke="none" d="M 15.089844 11 L 12.386719 11.609375 L 12.386719 8.429688 C 12.132812 8.492188 11.867188 8.488281 11.613281 8.429688 L 11.613281 11.609375 L 8.460938 10.902344 C 8.457031 11.03125 8.4375 11.160156 8.40625 11.289062 L 8.910156 11.792969 L 11.375 12.347656 L 10.933594 13.023438 C 11.1875 13.09375 11.421875 13.222656 11.613281 13.394531 L 12 12.800781 L 13.550781 15.1875 L 14.730469 15.785156 C 14.761719 15.765625 14.792969 15.746094 14.824219 15.730469 L 12.625 12.347656 L 15.363281 11.730469 C 15.21875 11.515625 15.121094 11.265625 15.089844 11 Z M 15.089844 11 "/>
      <path fill="#444" fillRule="nonzero" stroke="none" d="M 12 0.585938 L 0 9.304688 L 4.582031 23.414062 L 19.417969 23.414062 L 24 9.304688 Z M 18.46875 21.335938 L 16.625 18.5 C 16.421875 18.660156 16.179688 18.777344 15.917969 18.828125 L 17.894531 21.867188 L 6.105469 21.867188 L 9.828125 16.144531 C 9.585938 16.035156 9.378906 15.871094 9.214844 15.671875 L 5.53125 21.335938 L 1.921875 10.222656 L 5.148438 10.949219 C 5.144531 10.914062 5.144531 10.878906 5.144531 10.847656 C 5.144531 10.613281 5.195312 10.390625 5.28125 10.1875 L 2.328125 9.523438 L 11.613281 2.777344 L 11.613281 5.203125 C 11.738281 5.171875 11.867188 5.15625 12 5.15625 C 12.132812 5.15625 12.261719 5.171875 12.386719 5.203125 L 12.386719 2.777344 L 21.671875 9.523438 L 18.3125 10.277344 C 18.367188 10.441406 18.398438 10.617188 18.398438 10.800781 C 18.398438 10.886719 18.390625 10.972656 18.378906 11.054688 L 22.078125 10.222656 Z M 18.46875 21.335938 "/>
      <path fill="#444" fillRule="nonzero" stroke="none" d="M 16.714844 9.660156 C 16.492188 9.660156 16.289062 9.722656 16.113281 9.832031 L 13.039062 7.246094 C 13.089844 7.113281 13.121094 6.972656 13.121094 6.820312 C 13.121094 6.191406 12.605469 5.675781 11.976562 5.675781 C 11.34375 5.675781 10.832031 6.191406 10.832031 6.820312 C 10.832031 6.960938 10.855469 7.089844 10.898438 7.214844 L 7.460938 9.929688 C 7.269531 9.789062 7.035156 9.707031 6.78125 9.707031 C 6.148438 9.707031 5.636719 10.21875 5.636719 10.851562 C 5.636719 11.484375 6.148438 11.996094 6.78125 11.996094 C 6.945312 11.996094 7.097656 11.960938 7.238281 11.898438 L 9.457031 14.121094 C 9.378906 14.273438 9.335938 14.449219 9.335938 14.632812 C 9.335938 15.265625 9.847656 15.777344 10.480469 15.777344 C 10.796875 15.777344 11.082031 15.652344 11.289062 15.445312 L 14.4375 17.039062 C 14.425781 17.09375 14.421875 17.148438 14.421875 17.207031 C 14.421875 17.835938 14.9375 18.351562 15.570312 18.351562 C 16.199219 18.351562 16.714844 17.835938 16.714844 17.207031 C 16.714844 16.773438 16.472656 16.394531 16.113281 16.199219 L 16.875 11.9375 C 17.429688 11.859375 17.859375 11.382812 17.859375 10.804688 C 17.859375 10.175781 17.34375 9.660156 16.714844 9.660156 Z M 15.40625 16.074219 C 15.15625 16.109375 14.933594 16.226562 14.761719 16.394531 L 11.613281 14.800781 C 11.621094 14.746094 11.625 14.691406 11.625 14.632812 C 11.625 14.003906 11.113281 13.488281 10.480469 13.488281 C 10.296875 13.488281 10.121094 13.53125 9.96875 13.609375 L 7.777344 11.417969 C 7.871094 11.25 7.925781 11.054688 7.925781 10.851562 C 7.925781 10.738281 7.910156 10.625 7.878906 10.523438 L 11.347656 7.777344 C 11.527344 7.898438 11.742188 7.964844 11.976562 7.964844 C 12.195312 7.964844 12.402344 7.90625 12.574219 7.796875 L 15.648438 10.382812 C 15.597656 10.515625 15.570312 10.65625 15.570312 10.804688 C 15.570312 11.238281 15.8125 11.617188 16.167969 11.8125 Z M 15.40625 16.074219 "/>
    </g>
  </SvgIcon>;
};
