import { IPreviewRecentlyViewedConversation } from 'atlas-shared';
import { IRecentlyViewedConversationStore, RecentlyViewedConversationActions, TRecentlyViewedConversationActionTypes } from '@Store';

export const RecentlyViewedConversationReducer = (state: IRecentlyViewedConversationStore = {
  loaded: false,
  loading: false,
  recently_viewed_conversations: [],
  dict: {}
}, action: TRecentlyViewedConversationActionTypes): IRecentlyViewedConversationStore => {
  switch (action.type) {
  case RecentlyViewedConversationActions.LOADING:
    return { ...state, loading: true };
  case RecentlyViewedConversationActions.SET_STATE:
    const dict: IRecentlyViewedConversationStore['dict'] = {};

    action.payload.forEach((recently_viewed_conversation: IPreviewRecentlyViewedConversation) => dict[recently_viewed_conversation.id] = recently_viewed_conversation);

    return { ...state, loaded: true, loading: false, recently_viewed_conversations: action.payload, dict };
  case RecentlyViewedConversationActions.ADD_STATE:
    return { ...state, recently_viewed_conversations: [...state.recently_viewed_conversations, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case RecentlyViewedConversationActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, recently_viewed_conversations: state.recently_viewed_conversations.filter((item: IPreviewRecentlyViewedConversation) => item.id !== action.payload), dict };
  }
  case RecentlyViewedConversationActions.UPDATE_STATE:
    const recently_viewed_conversations = [...state.recently_viewed_conversations];
    const index = state.recently_viewed_conversations.findIndex((recently_viewed_conversation: IPreviewRecentlyViewedConversation) => recently_viewed_conversation.id === action.payload.id);

    if (!~index)
      recently_viewed_conversations.push(action.payload);
    else
      recently_viewed_conversations[index] = action.payload;

    return { ...state, recently_viewed_conversations, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
