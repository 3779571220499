import { IPreviewWallboard } from 'atlas-shared';

export enum WallboardActions {
  SET_STATE = 'WALLBOARD_SET_STATE',
  ADD_STATE = 'WALLBOARD_ADD_STATE',
  REMOVE_STATE = 'WALLBOARD_REMOVE_STATE',
  GET_STATE = 'WALLBOARD_GET_STATE',
  UPDATE_STATE = 'WALLBOARD_UPDATE_STATE',
  LOADING = 'WALLBOARD_LOADING',
}

export interface IWallboardLoading {
  type: WallboardActions.LOADING;
}

export interface IWallboardSetAction {
  type: WallboardActions.SET_STATE;
  payload: Array<IPreviewWallboard>;
}

export interface IWallboardUpdateAction {
  type: WallboardActions.UPDATE_STATE;
  payload: IPreviewWallboard;
}

export interface IWallboardAddAction {
  type: WallboardActions.ADD_STATE;
  payload: IPreviewWallboard;
}

export interface IWallboardRemoveAction {
  type: WallboardActions.REMOVE_STATE;
  payload: IPreviewWallboard['id'];
}

export interface IWallboardStore {
  loaded: boolean;
  loading: boolean;
  wallboards: Array<IPreviewWallboard>;
  dict: Record<IPreviewWallboard['id'], IPreviewWallboard>;
}

export type TWallboardActionTypes = IWallboardSetAction | IWallboardUpdateAction | IWallboardAddAction | IWallboardRemoveAction | IWallboardLoading;
