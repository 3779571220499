import {
  SessionDescriptionHandler as SessionDescriptionHandlerBase
} from 'sip.js/lib/platform/web';
import { Nullable } from 'atlas-shared/dist';

export class SessionDescriptionHandler extends SessionDescriptionHandlerBase {
  
  public setMicrophone(deviceId: Nullable<string>) {
    navigator.mediaDevices.getUserMedia({
      audio: deviceId ? { deviceId } : true
    }).then(stream => {
      super.setLocalMediaStream(stream);
    });
  }

}
