import { RestRequest } from '@Utils';
import { IPreviewVoiceAsterisk, ISharedVoiceAsterisk, ISharedCreateVoiceAsterisk, ISharedPatchVoiceAsterisk } from 'atlas-shared';

export const fetchVoiceAsterisks = async (): Promise<Array<IPreviewVoiceAsterisk>> => {
  return RestRequest.get<Array<IPreviewVoiceAsterisk>>('voice_asterisk', { socket_id: global.socketId });
};

export const fetchVoiceAsterisk = async (id: IPreviewVoiceAsterisk['id']): Promise<ISharedVoiceAsterisk> => {
  return RestRequest.get<ISharedVoiceAsterisk>(`voice_asterisk/${id}`, { socket_id: global.socketId });
};

export const createVoiceAsterisk = (payload: ISharedCreateVoiceAsterisk) => {
  return RestRequest.post<any, ISharedVoiceAsterisk>('voice_asterisk', payload, { socket_id: global.socketId });
};

export const patchVoiceAsterisk = (id: IPreviewVoiceAsterisk['id'], payload: ISharedPatchVoiceAsterisk) => {
  return RestRequest.patch<any, ISharedVoiceAsterisk>(`voice_asterisk/${id}`, payload, { socket_id: global.socketId });
};

export const deleteVoiceAsterisk = (id: IPreviewVoiceAsterisk['id']) => {
  return RestRequest.delete(`voice_asterisk/${id}`, { socket_id: global.socketId });
};

export const getVoiceAsteriskUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_asterisk/update/schema');
};

export const getVoiceAsteriskCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_asterisk/create/schema');
};

export const getVoiceAsteriskPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_asterisk/patch/schema');
};
