import { RestRequest } from '@Utils';
import { IPreviewInvoicePost, ISharedInvoicePost, ISharedCreateInvoicePost, ISharedPatchInvoicePost, ISharedSearchInvoicePost } from 'atlas-shared';

export const fetchInvoicePost = async (id: IPreviewInvoicePost['id']): Promise<ISharedInvoicePost> => {
  return RestRequest.get<ISharedInvoicePost>(`invoice_post/${id}`, { socket_id: global.socketId });
};

export const createInvoicePost = (payload: ISharedCreateInvoicePost) => {
  return RestRequest.post<any, ISharedInvoicePost>('invoice_post', payload, { socket_id: global.socketId });
};

export const patchInvoicePost = (id: IPreviewInvoicePost['id'], payload: ISharedPatchInvoicePost) => {
  return RestRequest.patch<any, ISharedInvoicePost>(`invoice_post/${id}`, payload, { socket_id: global.socketId });
};

export const deleteInvoicePost = (id: IPreviewInvoicePost['id']) => {
  return RestRequest.delete(`invoice_post/${id}`, { socket_id: global.socketId });
};

export const searchInvoicePost = async (params: ISharedSearchInvoicePost): Promise<{items: Array<IPreviewInvoicePost>; count: number}> => {
  return RestRequest.post<ISharedSearchInvoicePost, {items: Array<IPreviewInvoicePost>; count: number}>('invoice_post/search', params, { socket_id: global.socketId });
};

export const getInvoicePostUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('invoice_post/update/schema');
};

export const getInvoicePostCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('invoice_post/create/schema');
};

export const getInvoicePostPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('invoice_post/patch/schema');
};
