import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchUserStatuses, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useUserStatuses = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const user_statuses = useSelector((store: IReducerStore) => store.user_statuses);

  useEffect(() => {
    if (!initiated && autoload && !user_statuses.loaded && !user_statuses.loading) {
      initiated = true;
      dispatch(actionFetchUserStatuses());
    }
  }, [autoload, user_statuses.loaded, user_statuses.loading, dispatch]);

  return user_statuses;
};
