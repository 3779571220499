import { NotificationUserActions } from '@Store';
import { IPreviewNotificationUser } from 'atlas-shared';
import { fetchNotificationUsers } from '@Api';

export const actionFetchNotificationUsers = () => {
  return async (dispatch) => {
    dispatch(actionLoadingNotificationUsers());
    const notification_users = await fetchNotificationUsers();

    dispatch(actionSetNotificationUsers(notification_users));
    return notification_users;
  };
};

export const actionLoadingNotificationUsers = () => ({
  type: NotificationUserActions.LOADING,
  payload: []
});

export const actionSetNotificationUsers = (payload: Array<IPreviewNotificationUser>) => ({
  type: NotificationUserActions.SET_STATE,
  payload: payload
});

export const actionAddNotificationUser = (payload: IPreviewNotificationUser) => ({
  type: NotificationUserActions.ADD_STATE,
  payload: payload
});

export const actionUpdateNotificationUser = (payload: IPreviewNotificationUser) => ({
  type: NotificationUserActions.UPDATE_STATE,
  payload: payload
});
