import { InvoiceCurrencyEnum } from 'atlas-shared/dist';

export const displayWithCurrency = (currency: InvoiceCurrencyEnum, unit: number) => {
  let display = '';

  switch (currency) {
  case InvoiceCurrencyEnum.EUR:
    display = '€';
    break;
  case InvoiceCurrencyEnum.USD:
    display = '$';
    break;
  case InvoiceCurrencyEnum.SEK:
    display = 'kr';
    break;
  }
  return <span className={`currency-display currency-display-${currency.toLowerCase()}`}>
    <span className='currency currency-prefix'>{display}</span>
    <span className='unit'>{unit}</span>
    <span className='currency currency-postfix'>{display}</span>
  </span>;
};
