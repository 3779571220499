import React, { PropsWithChildren } from 'react';
import './styles.scss';
import { AppModal, AppSpinner } from '@Components';
import { useLoaders } from '@Hooks';

export const LoadingContainer = ({ children }: PropsWithChildren<{}>) => {
  const loaders = useLoaders();

  return <>
    {children}
    <AppModal
      visible={loaders > 0}
    >
      <AppSpinner />
    </AppModal>
  </>;
};
