import { configureStore } from '@reduxjs/toolkit';
import {
  AuthReducer,
  SipReducer,
  AbilitiesReducer,
  GlobalReducer,
  AdminprofileReducer,
  AgentprofileReducer,
  ApiKeyReducer,
  CannedAnswerReducer,
  ClientReducer,
  ConversationReducer,
  ConversationFavouriteReducer,
  ConversationFilterReducer,
  DestinationRestrictorReducer,
  DispositionReducer,
  FieldReducer,
  FormReducer,
  FormWebsiteReducer,
  InvoiceReducer,
  JourneyReducer,
  JourneyDraftReducer,
  JourneyVariableReducer,
  ListReducer,
  ListFileReducer,
  MailAccountReducer,
  NotificationUserReducer,
  OperationalHoursReducer,
  OrganizationReducer,
  PauseReducer,
  PhonenumberMonthlyCostReducer,
  PhonenumberMonthlyCostParentReducer,
  PhonenumberPurchaseReducer,
  PhonenumberSetupCostReducer,
  PhonenumberSetupCostParentReducer,
  PrepaidCreditReducer,
  QueueReducer,
  RecentlySearchedReducer,
  RecentlyViewedConversationReducer,
  ReleaseReducer,
  SessionReducer,
  SignatureReducer,
  SkillReducer,
  SlaPolicyReducer,
  SoundReducer,
  StatsCustomMetricReducer,
  StatsReportReducer,
  StatsReportPreviewWidgetReducer,
  StatsReportWidgetReducer,
  StatsWidgetReducer,
  SuperadminProfileReducer,
  TabReducer,
  TagReducer,
  TariffReducer,
  TariffParentReducer,
  TeamReducer,
  TimeIntervalReducer,
  UserReducer,
  UserStatusReducer,
  VoiceAsteriskReducer,
  VoiceAsteriskDowngradeReducer,
  VoiceCallReducer,
  VoiceChanspyReducer,
  VoiceInternalCallReducer,
  VoiceRouteReducer,
  VoiceShortcutReducer,
  VoiceTrunkReducer,
  VoiceVoicemailReducer,
  VoiceVoicemailMessageReducer,
  WallboardReducer
} from './reducers';
import { ReducersMapObject } from 'redux';
import { useDispatch } from 'react-redux';

const reducer: ReducersMapObject = {
  auth: AuthReducer,
  // @ts-ignore
  sip_users: SipReducer,
  abilities: AbilitiesReducer,
  // @ts-ignore
  global: GlobalReducer,
  adminprofiles: AdminprofileReducer,
  agentprofiles: AgentprofileReducer,
  api_keys: ApiKeyReducer,
  canned_answers: CannedAnswerReducer,
  clients: ClientReducer,
  // @ts-ignore
  conversations: ConversationReducer,
  conversation_favourites: ConversationFavouriteReducer,
  conversation_filters: ConversationFilterReducer,
  destination_restrictors: DestinationRestrictorReducer,
  dispositions: DispositionReducer,
  fields: FieldReducer,
  forms: FormReducer,
  form_websites: FormWebsiteReducer,
  invoices: InvoiceReducer,
  journeies: JourneyReducer,
  journey_drafts: JourneyDraftReducer,
  journey_variables: JourneyVariableReducer,
  lists: ListReducer,
  list_files: ListFileReducer,
  mail_accounts: MailAccountReducer,
  notification_users: NotificationUserReducer,
  operational_hourses: OperationalHoursReducer,
  organizations: OrganizationReducer,
  pauses: PauseReducer,
  phonenumber_monthly_costs: PhonenumberMonthlyCostReducer,
  phonenumber_monthly_cost_parents: PhonenumberMonthlyCostParentReducer,
  phonenumber_purchases: PhonenumberPurchaseReducer,
  phonenumber_setup_costs: PhonenumberSetupCostReducer,
  phonenumber_setup_cost_parents: PhonenumberSetupCostParentReducer,
  prepaid_credits: PrepaidCreditReducer,
  queues: QueueReducer,
  recently_searcheds: RecentlySearchedReducer,
  recently_viewed_conversations: RecentlyViewedConversationReducer,
  releases: ReleaseReducer,
  sessions: SessionReducer,
  signatures: SignatureReducer,
  skills: SkillReducer,
  sla_policies: SlaPolicyReducer,
  sounds: SoundReducer,
  stats_custom_metrics: StatsCustomMetricReducer,
  stats_reports: StatsReportReducer,
  stats_report_preview_widgets: StatsReportPreviewWidgetReducer,
  stats_report_widgets: StatsReportWidgetReducer,
  stats_widgets: StatsWidgetReducer,
  superadmin_profiles: SuperadminProfileReducer,
  tabs: TabReducer,
  tags: TagReducer,
  tariffs: TariffReducer,
  tariff_parents: TariffParentReducer,
  teams: TeamReducer,
  time_intervals: TimeIntervalReducer,
  users: UserReducer,
  user_statuses: UserStatusReducer,
  voice_asterisks: VoiceAsteriskReducer,
  voice_asterisk_downgrades: VoiceAsteriskDowngradeReducer,
  voice_calls: VoiceCallReducer,
  voice_chanspies: VoiceChanspyReducer,
  voice_internal_calls: VoiceInternalCallReducer,
  voice_routes: VoiceRouteReducer,
  voice_shortcuts: VoiceShortcutReducer,
  voice_trunks: VoiceTrunkReducer,
  voice_voicemails: VoiceVoicemailReducer,
  voice_voicemail_messages: VoiceVoicemailMessageReducer,
  wallboards: WallboardReducer
};

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch; // you can use this Dispatch type in your thunks
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types
