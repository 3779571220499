import { IPreviewForm } from 'atlas-shared';
import { IFormStore, FormActions, TFormActionTypes } from '@Store';

export const FormReducer = (state: IFormStore = {
  loaded: false,
  loading: false,
  forms: [],
  dict: {}
}, action: TFormActionTypes): IFormStore => {
  switch (action.type) {
  case FormActions.LOADING:
    return { ...state, loading: true };
  case FormActions.SET_STATE:
    const dict: IFormStore['dict'] = {};

    action.payload.forEach((form: IPreviewForm) => dict[form.id] = form);

    return { ...state, loaded: true, loading: false, forms: action.payload, dict };
  case FormActions.ADD_STATE:
    return { ...state, forms: [...state.forms, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case FormActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, forms: state.forms.filter((item: IPreviewForm) => item.id !== action.payload), dict };
  }
  case FormActions.UPDATE_STATE:
    const forms = [...state.forms];
    const index = state.forms.findIndex((form: IPreviewForm) => form.id === action.payload.id);

    if (!~index)
      forms.push(action.payload);
    else
      forms[index] = action.payload;

    return { ...state, forms, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
