import { IPreviewPhonenumberMonthlyCostParent } from 'atlas-shared';

export enum PhonenumberMonthlyCostParentActions {
  SET_STATE = 'PHONENUMBER_MONTHLY_COST_PARENT_SET_STATE',
  ADD_STATE = 'PHONENUMBER_MONTHLY_COST_PARENT_ADD_STATE',
  REMOVE_STATE = 'PHONENUMBER_MONTHLY_COST_PARENT_REMOVE_STATE',
  GET_STATE = 'PHONENUMBER_MONTHLY_COST_PARENT_GET_STATE',
  UPDATE_STATE = 'PHONENUMBER_MONTHLY_COST_PARENT_UPDATE_STATE',
  LOADING = 'PHONENUMBER_MONTHLY_COST_PARENT_LOADING',
}

export interface IPhonenumberMonthlyCostParentLoading {
  type: PhonenumberMonthlyCostParentActions.LOADING;
}

export interface IPhonenumberMonthlyCostParentSetAction {
  type: PhonenumberMonthlyCostParentActions.SET_STATE;
  payload: Array<IPreviewPhonenumberMonthlyCostParent>;
}

export interface IPhonenumberMonthlyCostParentUpdateAction {
  type: PhonenumberMonthlyCostParentActions.UPDATE_STATE;
  payload: IPreviewPhonenumberMonthlyCostParent;
}

export interface IPhonenumberMonthlyCostParentAddAction {
  type: PhonenumberMonthlyCostParentActions.ADD_STATE;
  payload: IPreviewPhonenumberMonthlyCostParent;
}

export interface IPhonenumberMonthlyCostParentRemoveAction {
  type: PhonenumberMonthlyCostParentActions.REMOVE_STATE;
  payload: IPreviewPhonenumberMonthlyCostParent['id'];
}

export interface IPhonenumberMonthlyCostParentStore {
  loaded: boolean;
  loading: boolean;
  phonenumber_monthly_cost_parents: Array<IPreviewPhonenumberMonthlyCostParent>;
  dict: Record<IPreviewPhonenumberMonthlyCostParent['id'], IPreviewPhonenumberMonthlyCostParent>;
}

export type TPhonenumberMonthlyCostParentActionTypes = IPhonenumberMonthlyCostParentSetAction | IPhonenumberMonthlyCostParentUpdateAction | IPhonenumberMonthlyCostParentAddAction | IPhonenumberMonthlyCostParentRemoveAction | IPhonenumberMonthlyCostParentLoading;
