import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateClient, ISharedUpdateClient, ISharedClient, IPreviewOrganization, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IClientFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const ClientFormOptions = (client: ISharedCreateClient | ISharedClient, props: IClientFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Client, {})), 'organization_id', false), 'organization'),
  
});

export const ClientComponents: IForm['components'] = {};
export const ClientAdditionalParams: (auth: IAuth, props: IClientFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IClientFormOptionsProps) => ({
});

export const ClientEditForm = (client: ISharedClient, props: IClientFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `client_edit_${client.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === client.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' }
  ]
});

export const ClientAddForm = (client: ISharedCreateClient, props: IClientFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'client_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' }
  ]
});

export const ClientAddInitialValues: ISharedCreateClient = {} as ISharedCreateClient;

export const ClientEditInitialValues = (client: ISharedClient): ISharedUpdateClient => ({
  title: client.title
});

export const onClientFormSaved = (t: TFunction, action: 'edit' | 'add', client: ISharedClient, navigate: NavigateFunction): void => {
  navigate(onFormSaved('clients'));
};
