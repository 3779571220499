import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateJourneyVariable, ISharedUpdateJourneyVariable, ISharedJourneyVariable, IPreviewOrganization, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IJourneyVariableFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const JourneyVariableFormOptions = (journey_variable: ISharedCreateJourneyVariable | ISharedJourneyVariable, props: IJourneyVariableFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.JourneyVariable, {})), 'organization_id', false), 'organization'),
  
});

export const JourneyVariableComponents: IForm['components'] = {};
export const JourneyVariableAdditionalParams: (auth: IAuth, props: IJourneyVariableFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IJourneyVariableFormOptionsProps) => ({
});

export const JourneyVariableEditForm = (journey_variable: ISharedJourneyVariable, props: IJourneyVariableFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `journey_variable_edit_${journey_variable.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === journey_variable.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'alias' },
    { ref: 'title' },
    { ref: 'description' }
  ]
});

export const JourneyVariableAddForm = (journey_variable: ISharedCreateJourneyVariable, props: IJourneyVariableFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'journey_variable_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'alias' },
    { ref: 'title' },
    { ref: 'description' }
  ]
});

export const JourneyVariableAddInitialValues: ISharedCreateJourneyVariable = {} as ISharedCreateJourneyVariable;

export const JourneyVariableEditInitialValues = (journey_variable: ISharedJourneyVariable): ISharedUpdateJourneyVariable => ({
  alias: journey_variable.alias,
  title: journey_variable.title,
  description: journey_variable.description
});

export const onJourneyVariableFormSaved = (t: TFunction, action: 'edit' | 'add', journey_variable: ISharedJourneyVariable, navigate: NavigateFunction): void => {
  navigate(onFormSaved('journey_variables'));
};
