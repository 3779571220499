import { JourneyActionQueue } from './queue';
import { JourneyActionTimeout } from './timeout';
import { JourneyActionNoOp } from './no_op';
import { JourneyActionRule } from './rule';
import { JourneyActionRuleContact } from './rule_contact';
import { JourneyActionOperationalHours } from './operational_hours';
import { IJourneyActionPropsResponse } from './base';
import { JourneyActionMailAutoResponse } from './mail_auto_response';
import { JourneyActionWhen } from './when';
import { JourneyActionStartForm } from './start_form';
import { JourneyActionRestRequest } from './rest_request';
import { JourneyActionEditConversation } from './edit_conversation';
import { JourneyActionCloseConversation } from './close_conversation';
import { JourneyActionSendMail } from './send_mail';
import { JourneyActionJourney } from './journey';
import { JourneyActionEnum } from 'atlas-shared';
import { JourneyActionStartMailInbound } from './start_mail_inbound';
import { JourneyActionStartMailOutbound } from './start_mail_outbound';
import { JourneyActionStartVoiceInbound } from './start_voice_inbound';
import { JourneyActionStartVoiceOutbound } from './start_voice_outbound';
import { JourneyActionAgent } from './agent';
import { JourneyActionUnAssign } from './unassign';
import { JourneyActionVoiceAnswer } from './voice_answer';
import { JourneyActionVoiceExternalDial } from './voice_external_dial';
import { JourneyActionVoiceGetDigits } from './voice_get_digits';
import { JourneyActionVoiceHangup } from './voice_hangup';
import { JourneyActionVoiceInternalDial } from './voice_internal_dial';
import { JourneyActionVoiceMenu } from './voice_menu';
import { JourneyActionVoicePlayback } from './voice_playback';
import { JourneyActionVoiceSayAlpha } from './voice_say_alpha';
import { JourneyActionVoiceSayDigits } from './voice_say_digits';
import { JourneyActionVoiceSayNumber } from './voice_say_number';
import { JourneyActionVoiceSayPhonetic } from './voice_say_phonetic';
import { JourneyActionVoiceStartRecording } from './voice_start_recording';
import { JourneyActionVoiceVoicemail } from './voice_voicemail';
import { JourneyActionVoiceQueue } from './voice_queue';
import { JourneyActionVoiceVariableSet } from './voice_variable_set';
import { JourneyActionVoiceStopRecording } from './voice_stop_recording';
import { JourneyActionSetField } from './set_field';
import { JourneyActionVoiceSetLanguage } from './voice_set_language';
import { JourneyActionFetchContactData } from './fetch_contact_data';
import { JourneyActionCloseTabs } from './close_tabs';
import { JourneyActionClient } from './client';
import { JourneyActionForwardMail } from './forward_mail';
import { JourneyActionAddInternalNote } from './add_internal_note';
import { JourneyActionReplyLatestInboundMessage } from './reply_latest_inbound_message';

export * from './agent';
export * from './unassign';
export * from './start_mail_inbound';
export * from './start_mail_outbound';
export * from './start_voice_inbound';
export * from './start_voice_outbound';
export * from './voice_answer';
export * from './voice_variable_set';
export * from './voice_set_language';
export * from './voice_external_dial';
export * from './voice_get_digits';
export * from './voice_hangup';
export * from './voice_internal_dial';
export * from './voice_menu';
export * from './voice_playback';
export * from './voice_say_alpha';
export * from './voice_say_digits';
export * from './voice_say_number';
export * from './voice_say_phonetic';
export * from './voice_start_recording';
export * from './voice_stop_recording';
export * from './voice_voicemail';
export * from './base';
export * from './queue';
export * from './client';
export * from './timeout';
export * from './start_form';
export * from './no_op';
export * from './when';
export * from './rule';
export * from './rule_contact';
export * from './operational_hours';
export * from './mail_auto_response';
export * from './rest_request';
export * from './fetch_contact_data';
export * from './edit_conversation';
export * from './close_conversation';
export * from './close_tabs';
export * from './send_mail';
export * from './set_field';
export * from './journey';
export * from './forward_mail';
export * from './add_internal_note';
export * from './reply_latest_inbound_message';

export const actions: Record<JourneyActionEnum, IJourneyActionPropsResponse> = {
  [JourneyActionEnum.StartVoiceInbound]: JourneyActionStartVoiceInbound,
  [JourneyActionEnum.StartVoiceOutbound]: JourneyActionStartVoiceOutbound,
  [JourneyActionEnum.VoiceAnswer]: JourneyActionVoiceAnswer,
  [JourneyActionEnum.VoiceVariableSet]: JourneyActionVoiceVariableSet,
  [JourneyActionEnum.VoiceSetLanguage]: JourneyActionVoiceSetLanguage,
  [JourneyActionEnum.VoiceExternalDial]: JourneyActionVoiceExternalDial,
  [JourneyActionEnum.VoiceGetDigits]: JourneyActionVoiceGetDigits,
  [JourneyActionEnum.VoiceHangup]: JourneyActionVoiceHangup,
  [JourneyActionEnum.VoiceInternalDial]: JourneyActionVoiceInternalDial,
  [JourneyActionEnum.VoiceMenu]: JourneyActionVoiceMenu,
  [JourneyActionEnum.VoicePlayback]: JourneyActionVoicePlayback,
  [JourneyActionEnum.VoiceSayAlpha]: JourneyActionVoiceSayAlpha,
  [JourneyActionEnum.VoiceSayDigits]: JourneyActionVoiceSayDigits,
  [JourneyActionEnum.VoiceSayNumber]: JourneyActionVoiceSayNumber,
  [JourneyActionEnum.VoiceSayPhonetic]: JourneyActionVoiceSayPhonetic,
  [JourneyActionEnum.VoiceStartRecording]: JourneyActionVoiceStartRecording,
  [JourneyActionEnum.VoiceStopRecording]: JourneyActionVoiceStopRecording,
  [JourneyActionEnum.VoiceVoicemail]: JourneyActionVoiceVoicemail,
  [JourneyActionEnum.UnAssign]: JourneyActionUnAssign,
  [JourneyActionEnum.Agent]: JourneyActionAgent,
  [JourneyActionEnum.SetField]: JourneyActionSetField,
  [JourneyActionEnum.StartMailInbound]: JourneyActionStartMailInbound,
  [JourneyActionEnum.StartMailOutbound]: JourneyActionStartMailOutbound,
  [JourneyActionEnum.StartForm]: JourneyActionStartForm,
  [JourneyActionEnum.Queue]: JourneyActionQueue,
  [JourneyActionEnum.Client]: JourneyActionClient,
  [JourneyActionEnum.VoiceQueue]: JourneyActionVoiceQueue,
  [JourneyActionEnum.Timeout]: JourneyActionTimeout,
  [JourneyActionEnum.RestRequest]: JourneyActionRestRequest,
  [JourneyActionEnum.FetchContactData]: JourneyActionFetchContactData,
  [JourneyActionEnum.EditConversation]: JourneyActionEditConversation,
  [JourneyActionEnum.CloseConversation]: JourneyActionCloseConversation,
  [JourneyActionEnum.AddInternalNote]: JourneyActionAddInternalNote,
  [JourneyActionEnum.CloseTabs]: JourneyActionCloseTabs,
  [JourneyActionEnum.SendMail]: JourneyActionSendMail,
  [JourneyActionEnum.ForwardMail]: JourneyActionForwardMail,
  [JourneyActionEnum.Journey]: JourneyActionJourney,
  [JourneyActionEnum.NoOp]: JourneyActionNoOp,
  [JourneyActionEnum.Rule]: JourneyActionRule,
  [JourneyActionEnum.RuleContact]: JourneyActionRuleContact,
  [JourneyActionEnum.OperationalHours]: JourneyActionOperationalHours,
  [JourneyActionEnum.MailAutoResponse]: JourneyActionMailAutoResponse,
  [JourneyActionEnum.ReplyLatestInboundMessage]: JourneyActionReplyLatestInboundMessage,
  [JourneyActionEnum.When]: JourneyActionWhen
};
