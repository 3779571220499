import { IPreviewStatsReport } from 'atlas-shared';
import { IStatsReportStore, StatsReportActions, TStatsReportActionTypes } from '@Store';

export const StatsReportReducer = (state: IStatsReportStore = {
  loaded: false,
  loading: false,
  stats_reports: [],
  dict: {}
}, action: TStatsReportActionTypes): IStatsReportStore => {
  switch (action.type) {
  case StatsReportActions.LOADING:
    return { ...state, loading: true };
  case StatsReportActions.SET_STATE:
    const dict: IStatsReportStore['dict'] = {};

    action.payload.forEach((stats_report: IPreviewStatsReport) => dict[stats_report.id] = stats_report);

    return { ...state, loaded: true, loading: false, stats_reports: action.payload, dict };
  case StatsReportActions.ADD_STATE:
    return { ...state, stats_reports: [...state.stats_reports, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case StatsReportActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, stats_reports: state.stats_reports.filter((item: IPreviewStatsReport) => item.id !== action.payload), dict };
  }
  case StatsReportActions.UPDATE_STATE:
    const stats_reports = [...state.stats_reports];
    const index = state.stats_reports.findIndex((stats_report: IPreviewStatsReport) => stats_report.id === action.payload.id);

    if (!~index)
      stats_reports.push(action.payload);
    else
      stats_reports[index] = action.payload;

    return { ...state, stats_reports, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
