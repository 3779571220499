import { RestRequest } from '@Utils';
import { IPreviewField, ISharedField, ISharedCreateField, ISharedPatchField } from 'atlas-shared';

export const fetchFields = async (): Promise<Array<IPreviewField>> => {
  return RestRequest.get<Array<IPreviewField>>('field', { socket_id: global.socketId });
};

export const fetchField = async (id: IPreviewField['id']): Promise<ISharedField> => {
  return RestRequest.get<ISharedField>(`field/${id}`, { socket_id: global.socketId });
};

export const createField = (payload: ISharedCreateField) => {
  return RestRequest.post<any, ISharedField>('field', payload, { socket_id: global.socketId });
};

export const patchField = (id: IPreviewField['id'], payload: ISharedPatchField) => {
  return RestRequest.patch<any, ISharedField>(`field/${id}`, payload, { socket_id: global.socketId });
};

export const deleteField = (id: IPreviewField['id']) => {
  return RestRequest.delete(`field/${id}`, { socket_id: global.socketId });
};

export const getFieldUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('field/update/schema');
};

export const getFieldCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('field/create/schema');
};

export const getFieldPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('field/patch/schema');
};
