import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RestRequest } from '@Utils/rest';
import { jwtRefreshToken } from '@Utils';
import { actionRemoveAuth, useAppDispatch } from '@Store';
import { useAuth } from '@Hooks';
import { apiMe } from '@Api';
import { SocketContainer } from './socket.container';
import { LoadDefaults } from './load.defaults';
import { AppLayout } from '@Layouts';
import { useStableNavigate } from '../context/StableNavigateContext';

export const AuthorizedRoute = React.memo(({ children }: PropsWithChildren<{ }>) => {

  const dispatch = useAppDispatch();
  const navigate = useStableNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useAuth();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {

    const stored_auth_personified = localStorage.getItem('atlas-auth-personified');
    const stored_auth_personified_parsed = stored_auth_personified ? JSON.parse(stored_auth_personified) : null;

    if (stored_auth_personified) {
      RestRequest.setAuth(`Bearer ${stored_auth_personified_parsed.access_token}`);
      apiMe()
        .then(({ user, user_status, tokens, }) => {
          jwtRefreshToken(user, user_status, tokens || stored_auth_personified_parsed, dispatch, navigate, true);
        })
        .catch(e => {
          dispatch(actionRemoveAuth());
          localStorage.removeItem('atlas-auth-personified');
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }

    const stored_auth = localStorage.getItem('atlas-auth');
    const stored_auth_parsed = stored_auth ? JSON.parse(stored_auth) : null;

    if (stored_auth_parsed) {
      RestRequest.setAuth(`Bearer ${stored_auth_parsed.access_token}`);
      apiMe()
        .then(({ user, user_status, tokens, }) => {
          jwtRefreshToken(user, user_status, tokens || stored_auth_parsed, dispatch, navigate);
        })
        .catch(e => {
          dispatch(actionRemoveAuth());
          localStorage.removeItem('atlas-auth');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else {
      const params_access_token = { access_token: new URLSearchParams(window.location.search).get('access_token') };

      if (params_access_token.access_token) {
        RestRequest.setAuth(`Bearer ${params_access_token.access_token}`);
        apiMe()
          .then(({ user, user_status, tokens, }) => {
            jwtRefreshToken(user, user_status, tokens || params_access_token, dispatch, navigate);
          })
          .catch(e => {
            dispatch(actionRemoveAuth());
          })
          .finally(() => {
            setLoading(false);
          });
      }
      else {
        setLoading(false);
        // history.push('/login');
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (!loading && !auth) {
      navigate('/login');
    }
  }, [auth, loading, navigate]);

  return <>
    {
      auth && <SocketContainer>
        <LoadDefaults>
          <AppLayout>
            <Outlet />
          </AppLayout>
        </LoadDefaults>
      </SocketContainer>
    }
  </>;
});
