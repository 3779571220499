import { IPreviewSkill } from 'atlas-shared';
import { ISkillStore, SkillActions, TSkillActionTypes } from '@Store';

export const SkillReducer = (state: ISkillStore = {
  loaded: false,
  loading: false,
  skills: [],
  dict: {}
}, action: TSkillActionTypes): ISkillStore => {
  switch (action.type) {
  case SkillActions.LOADING:
    return { ...state, loading: true };
  case SkillActions.SET_STATE:
    const dict: ISkillStore['dict'] = {};

    action.payload.forEach((skill: IPreviewSkill) => dict[skill.id] = skill);

    return { ...state, loaded: true, loading: false, skills: action.payload, dict };
  case SkillActions.ADD_STATE:
    return { ...state, skills: [...state.skills, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case SkillActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, skills: state.skills.filter((item: IPreviewSkill) => item.id !== action.payload), dict };
  }
  case SkillActions.UPDATE_STATE:
    const skills = [...state.skills];
    const index = state.skills.findIndex((skill: IPreviewSkill) => skill.id === action.payload.id);

    if (!~index)
      skills.push(action.payload);
    else
      skills[index] = action.payload;

    return { ...state, skills, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
