import { IPreviewVoiceAsteriskDowngrade } from 'atlas-shared';
import { IVoiceAsteriskDowngradeStore, VoiceAsteriskDowngradeActions, TVoiceAsteriskDowngradeActionTypes } from '@Store';

export const VoiceAsteriskDowngradeReducer = (state: IVoiceAsteriskDowngradeStore = {
  loaded: false,
  loading: false,
  voice_asterisk_downgrades: [],
  dict: {}
}, action: TVoiceAsteriskDowngradeActionTypes): IVoiceAsteriskDowngradeStore => {
  switch (action.type) {
  case VoiceAsteriskDowngradeActions.LOADING:
    return { ...state, loading: true };
  case VoiceAsteriskDowngradeActions.SET_STATE:
    const dict: IVoiceAsteriskDowngradeStore['dict'] = {};

    action.payload.forEach((voice_asterisk_downgrade: IPreviewVoiceAsteriskDowngrade) => dict[voice_asterisk_downgrade.id] = voice_asterisk_downgrade);

    return { ...state, loaded: true, loading: false, voice_asterisk_downgrades: action.payload, dict };
  case VoiceAsteriskDowngradeActions.ADD_STATE:
    return { ...state, voice_asterisk_downgrades: [...state.voice_asterisk_downgrades, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceAsteriskDowngradeActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_asterisk_downgrades: state.voice_asterisk_downgrades.filter((item: IPreviewVoiceAsteriskDowngrade) => item.id !== action.payload), dict };
  }
  case VoiceAsteriskDowngradeActions.UPDATE_STATE:
    const voice_asterisk_downgrades = [...state.voice_asterisk_downgrades];
    const index = state.voice_asterisk_downgrades.findIndex((voice_asterisk_downgrade: IPreviewVoiceAsteriskDowngrade) => voice_asterisk_downgrade.id === action.payload.id);

    if (!~index)
      voice_asterisk_downgrades.push(action.payload);
    else
      voice_asterisk_downgrades[index] = action.payload;

    return { ...state, voice_asterisk_downgrades, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
