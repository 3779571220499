import { useSelector } from 'react-redux';
import { useAuth } from './use.auth';
import { actionFetchRecentlySearcheds, IReducerStore, useAppDispatch } from '@Store';
import { useEffect, useState } from 'react';
import { IPreviewUserStatus } from 'atlas-shared';

export const useRecentlySearcheds = ( autoload = true) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const recently_searcheds = useSelector((store: IReducerStore) => store.recently_searcheds);
  const [agentprofileId, setAgentprofileId] = useState<IPreviewUserStatus['agentprofile_id']>(auth.user_status.agentprofile_id);

  useEffect(() => {
    autoload && !recently_searcheds.loaded && !recently_searcheds.loading && dispatch(actionFetchRecentlySearcheds(auth.user_status.agentprofile_id));
  }, [autoload, auth.user_status.agentprofile_id, recently_searcheds.loaded, recently_searcheds.loading, dispatch]);

  useEffect(() => {
    if (autoload && recently_searcheds.loaded && !recently_searcheds.loading && auth.user_status.agentprofile_id !== agentprofileId) {
      dispatch(actionFetchRecentlySearcheds(auth.user_status.agentprofile_id));
      setAgentprofileId(auth.user_status.agentprofile_id);
    }
  }, [autoload, auth.user_status.agentprofile_id, recently_searcheds.loaded, recently_searcheds.loading, agentprofileId, dispatch]);

  return recently_searcheds;
};
