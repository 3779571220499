import {
  CrudEnum,
  IPreviewAgentprofile,
  IPreviewVoiceCall,
  IPreviewVoiceRecording,
  PermissionEnum,
  PermissionSelfEnum,
  Undefinable,
  IPreviewVoiceCallBridge,
  ISharedVoiceRecordingTranscript
} from 'atlas-shared';
import { downloadVoiceRecordingFilename, generateVoiceRecordingTranscript, streamVoiceRecordingFilename } from '@Api';
import React, { useCallback, useEffect, useState } from 'react';
import { IAbilities, IAuth, IUserStore } from '@Store';
import { AlertError } from '@Utils';
import { useTranslation } from 'react-i18next';
import { AudioPlayer, AudioPlayerTheme } from '@Utils/audio-player';

interface IProps {
  recording: IPreviewVoiceRecording;
  users: IUserStore;
  voice_call: IPreviewVoiceCall;
  voice_call_bridge?: IPreviewVoiceCallBridge;
  agentprofile: Undefinable<IPreviewAgentprofile>;
  auth: IAuth;
  abilities: IAbilities;
  size?: 'm' | 's';
  transcript?: {
    get?: ISharedVoiceRecordingTranscript['id'];
    set: (id?: ISharedVoiceRecordingTranscript['id']) => void;
  };
  can_generate_transcript?: boolean;
  setCurrentTime?: (seconds: number) => void;
}

export const ConversationMessageVoiceCallVoiceRecording = React.memo(({
  voice_call,
  voice_call_bridge,
  recording,
  agentprofile,
  auth,
  abilities,
  users,
  transcript,
  setCurrentTime,
  size = 'm',
  can_generate_transcript
}: IProps) => {
  const [audio, setAudio] = useState<Undefinable<string>>();
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const [canDownload, setCanDownload] = useState<boolean>(false);
  const [canReadTranscript, setCanReadTranscript] = useState<boolean>(false);
  const { t } = useTranslation();

  const load = useCallback(() => {
    if (!audio && (canPlay || canDownload) && recording.stopped_at)
      streamVoiceRecordingFilename(recording.organization_id, recording.id, e => AlertError(t, { content: t('DOWNLOAD_RECORDING_ERROR') })).then(blob => setAudio(blob));
  }, [audio, canPlay, recording]);

  useEffect(() => {
    const play_permission: Undefinable<PermissionSelfEnum> = agentprofile?.settings?.play_recording;
    const download_permission: Undefinable<PermissionSelfEnum> = agentprofile?.settings?.download_recording;
    const view_transcript: Undefinable<PermissionSelfEnum> = agentprofile?.settings?.view_transcript;

    setCanPlay(
      abilities.canImplicit(null, CrudEnum.Read, PermissionEnum.VoiceRecording)
      || play_permission === PermissionSelfEnum.All
      || (
        play_permission === PermissionSelfEnum.Self
      && (
        recording.user_id === auth.user.id
          || voice_call_bridge?.user_id === auth.user.id
      )
      )
    );

    setCanDownload(
      abilities.canImplicit(null, CrudEnum.Read, PermissionEnum.VoiceRecording)
      || download_permission === PermissionSelfEnum.All
      || (
        download_permission === PermissionSelfEnum.Self
      && (
        recording.user_id === auth.user.id
      || voice_call_bridge?.user_id === auth.user.id
      )
      )
    );

    setCanReadTranscript(
      abilities.canImplicit(null, CrudEnum.Read, PermissionEnum.VoiceRecordingTranscript)
      || view_transcript === PermissionSelfEnum.All
      || (
        view_transcript === PermissionSelfEnum.Self
        && (
          recording.user_id === auth.user.id
          || voice_call_bridge?.user_id === auth.user.id
        )
      )
    );

  }, [agentprofile, abilities, agentprofile, auth.user.id, recording.user_id]);

  if (!canPlay && !canDownload)
    return <></>;

  return <div>
    <span onClick={e => !audio && load()}>
      <AudioPlayer
        t={t}
        canPlay={canPlay}
        theme={!recording.stopped_at ? AudioPlayerTheme.RecordingRecording : AudioPlayerTheme.RecordingPlayable}
        src={audio}
        user_id={recording.user_id}
        users={users}
        start_time={recording.created_at}
        end_time={recording.stopped_at}
        download={canDownload && recording.stopped_at ? () => downloadVoiceRecordingFilename(recording.organization_id, recording.id, recording.filename, (err => AlertError(t, { title: t('SOMETHING_WENT_WRONG'), content: <span><div>{t('DOWNLOAD_RECORDING_ERROR')}</div><div>{err}</div></span> }))) : undefined}
        size={size}
        transcript={canReadTranscript && transcript && recording.voice_recording_transcript_id ? {
          id: recording.id,
          ...transcript
        } : undefined}
        generate_transcript={can_generate_transcript ? () => generateVoiceRecordingTranscript(recording.organization_id, recording.id) : undefined}
        setCurrentTime={setCurrentTime}
      />
    </span>
  </div>;
});
