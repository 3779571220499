import { ISipStore, ISipStoreSip, SipActions, TSipActionTypes } from '@Store';

export const SipReducer = (state: ISipStore = {
  sip_users: []
}, action: TSipActionTypes): ISipStore => {
  switch (action.type) {
  case SipActions.ADD_STATE: 
  case SipActions.UPDATE_STATE: 
  {
    const sip_user = state.sip_users.find(sip => sip.voice_asterisk.id === action.payload.voice_asterisk.id);

    if (action.type === SipActions.UPDATE_STATE && !sip_user)
      return state;
    
    const new_sip_user = {
      ...(sip_user || {}),
      ...action.payload
    } as ISipStoreSip;

    if (sip_user && new_sip_user.sessions) {
      Object.entries(new_sip_user.sessions).forEach(([key, session]) => {
        if (!session.last_update)
          session.last_update = sip_user.sessions[key]?.last_update || Date.now();
      });
    }

    return {
      sip_users: [
        ...state.sip_users.filter(sip => sip.voice_asterisk.id !== action.payload.voice_asterisk.id),
        new_sip_user
      ]
    };
  }
  case SipActions.REMOVE_STATE: {
    return {
      sip_users: state.sip_users.filter(sip_user => sip_user.voice_asterisk.id !== action.payload.voice_asterisk.id)
    };
  }
  default:
    return state;
  }
};
