import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, IUserStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';

export * from './abstract/phonenumber_purchase.list.column.abstract';
import { PhonenumberPurchaseListColumns as PhonenumberPurchaseListColumnsBase } from './abstract/phonenumber_purchase.list.column.abstract';
import { IPreviewPhonenumberPurchase, TPhonenumberPurchaseSortableListCol, CrudEnum, PermissionEnum, SuperadminProfilePermissionIdEnum } from 'atlas-shared';
import { Icon } from '@Components';
import { InboxIcon, InfoIcon } from '@Assets';

export const PhonenumberPurchaseListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewPhonenumberPurchase) => React.ReactElement> = [], filters: IWithListProps<IPreviewPhonenumberPurchase, TPhonenumberPurchaseSortableListCol>['filters'], sorter: IWithListProps<IPreviewPhonenumberPurchase, TPhonenumberPurchaseSortableListCol>['sorter']): ColumnsType<IPreviewPhonenumberPurchase> => {

  const cols = PhonenumberPurchaseListColumnsBase(t, navigate, showDeleted, organizations, users, abilities, auth, [
    (row: IPreviewPhonenumberPurchase) => row.conversation_id && abilities.can(null, CrudEnum.Update, SuperadminProfilePermissionIdEnum.PhonenumberPurchase as unknown as PermissionEnum, row) ? <Icon icon={InboxIcon} tooltip={{ title: t('GO_TO_CONVERSATION') }} iconProps={{ size: 14 }} onClick={() => { navigate(`/dashboard/conversation/${row.conversation_organization_id}/${row.conversation_id}`); }} /> : <></>,
    ...buttons
  ], filters, sorter);

  return [
    ...cols.slice(0, 4),
    {
      title: t('VOICE_ROUTE'),
      dataIndex: 'voice_route_id',
      key: 'voice_route_id',
      className: 'table-cell-voice_route_id',
      render: (voice_route_id) => <a onClick={() => navigate(`/admin/voice_route/${voice_route_id}/edit`)}>{voice_route_id}</a>
    },
    ...cols.slice(4, 5),
    {
      title: '',
      dataIndex: 'additional_info',
      key: 'additional_info',
      className: 'table-cell-additional_info',
      render: (additional_info) => !!additional_info.length && <Icon icon={InfoIcon} tooltip={{ title: additional_info.join('\n') }} />
    },
    ...cols.slice(5, 10),
  ];
  
};
