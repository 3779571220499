import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './stats_reports.scss';
import {
  useAbilities,
  useAuth,
  useOrganizations,
  useStatsReportPreviewWidgets,
  useStatsReports,
  useStatsReportWidgets, useUsers
} from '@Hooks';
import { useStableNavigate } from '../../context/StableNavigateContext';
import { CrudEnum, IPreviewStatsReport, PermissionEnum, PrebuiltDashboards, PrebuiltReports, IPrebuildStats, Nullable, ISharedCreateStatsReport, ISharedStatsReport, ISharedStatsWidget, IPreviewUser } from 'atlas-shared';
import { Button, Icon, IconText, ScrollView, Sider, StatsReportFormAddView, StatsReportFormEditView } from '@Components';
import { useTranslation } from 'react-i18next';
import {
  CloneIcon,
  CornerUpRight,
  DashboardIcon,
  EditIcon,
  GridIcon,
  PlusIcon,
  ReportIcon,
  TrashIcon,
  TrendingUpIcon
} from '@Assets';
import {
  AlertError,
  Confirm, filterRelated,
  preventDefault,
  TypeModal, uniq
} from '@Utils';
import {
  createStatsReportPreviewWidget,
  createStatsReportWidget,
  createStatsWidget,
  deleteStatsReport,
  fetchStatsWidget,
  getStatsWidgetCreateSchema,
  transferStatsReport,
  transferStatsWidgetToOwner
} from '@Api';
import { AtlasForm, FormLayoutTypes, IForm, JSONForm } from 'atlas-form';
import { fromJson } from 'json-joi-converter';
import { useAppDispatch } from '@Store';

export const StatsReportsLayout = React.memo(() => {

  const navigate = useStableNavigate();
  const dispatch = useAppDispatch();
  const stats_reports = useStatsReports();
  const stats_report_widgets = useStatsReportWidgets();
  const stats_report_preview_widgets = useStatsReportPreviewWidgets();
  const users = useUsers();
  const { t } = useTranslation();
  const [form, setForm] = useState<number>(0);
  const [transfer, setTransfer] = useState<Nullable<IPreviewStatsReport>>(null);
  const [isEditDashboard, setIsEditDashboard] = useState<boolean>(false);
  const [reports, setReports] = useState<Array<IPreviewStatsReport>>([]);
  const [dashboards, setDashboards] = useState<Array<IPreviewStatsReport>>([]);
  const abilities = useAbilities();
  const organizations = useOrganizations();
  const auth = useAuth();
  const [dashboardFilter, setDashboardFilter] = useState<number>(0);
  const [reportFilter, setReportFilter] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<Nullable<Partial<ISharedCreateStatsReport>>>(null);
  const [clone, setClone] = useState<Nullable<ISharedStatsReport>>(null);
  const filteredDashboards = useMemo(() => {
    return dashboardFilter === 1
      ? dashboards.filter(r => r.user_id !== auth.user.id)
      : dashboardFilter === 2
        ? []
        : dashboards;
  }, [dashboards, dashboardFilter, auth]);
  const filteredReports = useMemo(() => {
    return reportFilter === 1
      ? reports.filter(r => r.user_id !== auth.user.id)
      : reportFilter === 2
        ? []
        : reports;
  }, [reports, reportFilter, auth]);
  const PrebuiltTemplate = useCallback((dashboard: boolean, template: IPrebuildStats, onClick: () => void) => {
    return <div className='template' onClick={() => onClick()}>
      <Icon icon={dashboard ? DashboardIcon : ReportIcon} className='display-icon' />
      <div className='default-img img' style={{ backgroundImage: 'url(/stats/template-1.jpg)' }}></div>
      <div className='img' style={{ backgroundImage: `url(/stats/template-${template.id}.jpg)` }}></div>
      <div className='background'></div>
      <div className='description'>{t('CREATE_FROM_THIS_TEMPLATE')}</div>
      <div className='title'>{template.title}</div>
    </div>;
  }, []);
  const getInitialValues = useCallback((template: Nullable<IPrebuildStats>): Partial<ISharedCreateStatsReport> => {
    return !template ? {} : {
      title: template.title,
      template_id: template.id
    };
  }, []);

  useEffect(() => {
    const reports: Array<IPreviewStatsReport> = [];
    const dashboards: Array<IPreviewStatsReport> = [];

    stats_reports.stats_reports.forEach(stats_report => {
      if (stats_report.is_dashboard)
        dashboards.push(stats_report);
      else
        reports.push(stats_report);

      setReports(reports);
      setDashboards(dashboards);
    });
  }, [stats_reports]);
  const canCreate = useMemo(() => abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.StatsReport), [abilities]);

  return <div id='stats-page-wrapper'>
    <div id='stats-report-header'>
      <h1><IconText icon={TrendingUpIcon} iconProps={{ size: 24 }} text={t('STATISTICS')} textProps={{ style:{ marginLeft: 7 } }}/></h1>
    </div>
    <div id='stats-reports'>

      {auth.user.dashboard_license && <div id='dashboards'>
        <div className='header'>
          <IconText icon={GridIcon} text={t('DASHBOARDS')} className='title' iconProps={{ size: 20 }}/>
          {canCreate && <Button onClick={() => {
            setInitialValues({
              is_dashboard: true
            });
            setForm(-2);
          }} icon={<PlusIcon size={14}/>}>{t('CREATE_DASHBOARD')}</Button>}
        </div>
        <div className='filters'>
          {(canCreate ? ['ALL_DASHBOARDS', 'SHARED_WITH_ME', 'PREBUILD_DASHBOARDS'] : ['ALL_DASHBOARDS', 'SHARED_WITH_ME']).map((f, i) =>
            <div className={`filter${dashboardFilter === i ? ' active' : ''}`}
              onClick={() => setDashboardFilter(i)}>{t(f)}</div>)}
        </div>
        {canCreate && dashboardFilter === 0 && !dashboards.length && <div className='add-first-report'>
          <Icon icon={DashboardIcon} className='display-icon'/>
          <h1>{t('ADD_YOUR_FIRST_DASHBOARD')}</h1>
          <h2>{t('ADD_YOUR_FIRST_DASHBOARD_DESCRIPTION')}</h2>
        </div>}
        {!canCreate && dashboardFilter === 0 && !dashboards.length && <div className='no-first-report'>
          <Icon icon={DashboardIcon} className='display-icon'/>
          <h1>{t('NO_DASHBOARDS')}</h1>
        </div>}
        {dashboardFilter === 1 && !filteredDashboards.length && <div className='no-shared-reports'>
          <Icon icon={DashboardIcon} className='display-icon'/>
          <h1>{t('NO_SHARED_DASHBOARDS')}</h1>
        </div>}
        {canCreate && dashboardFilter === 2 && <div className='add-pre-built-report'>
          <Icon icon={DashboardIcon} className='display-icon'/>
          <h1>{t('ADD_YOUR_PREBUILT_DASHBOARD')}</h1>
          <h2>{t('ADD_YOUR_PREBUILT_DASHBOARD_DESCRIPTION')}</h2>
        </div>}
        <ScrollView>
          {canCreate && (dashboardFilter === 2 || (dashboardFilter === 0 && !dashboards.length)) &&
          <div className='prebuilt-reports'>
            {PrebuiltDashboards.map(template => PrebuiltTemplate(true, template, () => {
              setInitialValues({
                ...getInitialValues(template),
                is_dashboard: true
              });
              setForm(-2);
            }))}
          </div>}
          <div className='reports'>
            {filteredDashboards.map(report => <div
              className='report'
              onClick={() => navigate(`/stats/${report.id}/dashboard`)}
            >
              <div className='report-buttons'>
                {report.user_id === auth.user.id && <Icon
                  onClick={preventDefault(() => {
                    setTransfer(report);
                  })}
                  icon={CornerUpRight}
                  className='transfer-report'
                  tooltip={{ title: `${t('TRANSFER')} ${t('DASHBOARD')}` }}
                />}
                {report.user_id === auth.user.id && <Icon
                  onClick={preventDefault(() => {
                    setForm(report.id);
                    setIsEditDashboard(true);
                  })}
                  icon={EditIcon}
                  className='edit-report'
                  tooltip={{ title: `${t('EDIT')} ${t('DASHBOARD')}` }}
                />}
                {canCreate && <Icon
                  onClick={preventDefault(() => {
                    const { organization_id, title, shared } = stats_reports.dict[report.id];

                    setClone(report);

                    setInitialValues({
                      organization_id,
                      title: `${title} (${t('CLONE')})`,
                      shared,
                      is_dashboard: true
                    });
                    setForm(-2);
                  })}
                  icon={CloneIcon}
                  className='clone-report'
                  tooltip={{ title: `${t('CLONE')} ${t('DASHBOARD')}` }}
                />}
                {report.user_id === auth.user.id && <Icon
                  onClick={preventDefault(() => {
                    Confirm(t, {
                      onOk: (modal: TypeModal) => deleteStatsReport(report.id).then(deleted => modal.destroy()),
                      title: t('PERMANENTLY_DELETE_DASHBOARD'),
                      content: t('PERMANENTLY_DELETE_DASHBOARD_DESCRIPTION')
                    });
                  })}
                  icon={TrashIcon}
                  className='delete-report'
                  tooltip={{ title: `${t('DELETE')} ${t('DASHBOARD')}` }}
                />}
              </div>
              <Icon icon={DashboardIcon} className='display-icon'/>
              <div className='info'>
                <div className='left'>
                  <div className='title'>{report.title}</div>
                  <div className='organization'>{organizations.dict[report.organization_id]?.title}</div>
                </div>
                <div className='right'>
                  {report.user_id !== auth.user.id && <div className='shared'>{t('SHARED')}</div>}
                </div>
              </div>
            </div>)}
          </div>

        </ScrollView>
      </div>}

      {auth.user.reporting_license && <div id='reports'>
        <div className='header'>
          <IconText icon={TrendingUpIcon} text={t('REPORTS')} className='title' iconProps={{ size: 20 }}/>
          {canCreate && <Button onClick={() => {
            setInitialValues({});
            setForm(-1);
          }} icon={<PlusIcon size={14}/>}>{t('CREATE_REPORT')}</Button>}
        </div>
        <div className='filters'>
          {(canCreate ? ['ALL_REPORTS', 'SHARED_WITH_ME', 'PREBUILD_REPORTS'] : ['ALL_REPORTS', 'SHARED_WITH_ME']).map((f, i) =>
            <div className={`filter${reportFilter === i ? ' active' : ''}`}
              onClick={() => setReportFilter(i)}>{t(f)}</div>)}
        </div>
        {canCreate && reportFilter === 0 && !reports.length && <div className='add-first-report'>
          <Icon icon={ReportIcon} className='display-icon'/>
          <h1>{t('ADD_YOUR_FIRST_REPORT')}</h1>
          <h2>{t('ADD_YOUR_FIRST_REPORT_DESCRIPTION')}</h2>
        </div>}
        {!canCreate && reportFilter === 0 && !reports.length && <div className='no-first-report'>
          <Icon icon={ReportIcon} className='display-icon'/>
          <h1>{t('NO_REPORTS')}</h1>
        </div>}
        {reportFilter === 1 && !filteredReports.length && <div className='no-shared-reports'>
          <Icon icon={ReportIcon} className='display-icon'/>
          <h1>{t('NO_SHARED_REPORTS')}</h1>
        </div>}
        {canCreate && reportFilter === 2 && <div className='add-pre-built-report'>
          <Icon icon={ReportIcon} className='display-icon'/>
          <h1>{t('ADD_YOUR_PREBUILT_REPORT')}</h1>
          <h2>{t('ADD_YOUR_PREBUILT_REPORT_DESCRIPTION')}</h2>
        </div>}
        <ScrollView>
          {canCreate && (reportFilter === 2 || (reportFilter === 0 && !reports.length)) &&
          <div className='prebuilt-reports'>
            {PrebuiltReports.map(template => PrebuiltTemplate(false, template, () => {
              setInitialValues({
                ...getInitialValues(template),
                is_dashboard: false
              });
              setForm(-1);
            }))}
          </div>}
          <div className='reports'>
            {filteredReports.map(report => <div
              className='report'
              onClick={() => navigate(`/stats/${report.id}/report`)}
            >
              <div className='report-buttons'>
                {report.user_id === auth.user.id && <Icon
                  onClick={preventDefault(() => {
                    setTransfer(report);
                  })}
                  icon={CornerUpRight}
                  className='transfer-report'
                  tooltip={{ title: `${t('TRANSFER')} ${t('REPORT')}` }}
                />}
                {report.user_id === auth.user.id && <Icon
                  onClick={preventDefault(() => {
                    setForm(report.id);
                    setIsEditDashboard(false);
                  })}
                  icon={EditIcon}
                  className='edit-report'
                  tooltip={{ title: `${t('EDIT')} ${t('REPORT')}` }}
                />}
                {canCreate && <Icon
                  onClick={preventDefault(() => {
                    const { organization_id, title, shared } = stats_reports.dict[report.id];

                    setClone(report);

                    setInitialValues({
                      organization_id,
                      title: `${title} (${t('CLONE')})`,
                      shared,
                      is_dashboard: false
                    });
                    setForm(-1);
                  })}
                  icon={CloneIcon}
                  className='clone-report'
                  tooltip={{ title: `${t('CLONE')} ${t('REPORT')}` }}
                />}
                {report.user_id === auth.user.id && <Icon
                  onClick={preventDefault(() => {
                    Confirm(t, {
                      onOk: (modal: TypeModal) => deleteStatsReport(report.id).then(deleted => modal.destroy()),
                      title: t('PERMANENTLY_DELETE_REPORT'),
                      content: t('PERMANENTLY_DELETE_REPORT_DESCRIPTION')
                    });
                  })}
                  icon={TrashIcon}
                  className='delete-report'
                  tooltip={{ title: `${t('DELETE')} ${t('REPORT')}` }}
                />}
              </div>
              <Icon icon={ReportIcon} className='display-icon'/>
              <div className='info'>
                <div className='left'>
                  <div className='title'>{report.title}</div>
                  <div className='organization'>{organizations.dict[report.organization_id]?.title}</div>
                </div>
                <div className='right'>
                  {report.user_id !== auth.user.id && <div className='shared'>{t('SHARED')}</div>}
                </div>
              </div>
            </div>)}
          </div>
        </ScrollView>
      </div>}

      {!!form && <Sider onClose={() => setForm(0)}>
        {form > 0 && <StatsReportFormEditView
          id={form}
          onCancel={() => setForm(0)}
          title={t(isEditDashboard ? 'EDIT_DASHBOARD' : 'EDIT_REPORT')}
          onSaved={() => setForm(0)}
        />}
        {form < 0 && initialValues && <StatsReportFormAddView
          title={`CREATE_${form === -1 ? 'REPORT' : 'DASHBOARD'}`}
          onSaved={async (res, navigate) => {
            const redirect = () => navigate(`/stats/${res.id}/${form === -1 ? 'report' : 'dashboard'}`);

            if (clone) {

              try {
                const schema = await getStatsWidgetCreateSchema();
                const report_widgets = stats_report_widgets.stats_report_widgets.filter(({ stats_report_id }) => stats_report_id === clone.id);
                const report_preview_widgets = stats_report_preview_widgets.stats_report_preview_widgets.filter(({ stats_report_id, is_default }) => stats_report_id === clone.id && is_default);
                const widget_dict: Record<ISharedStatsWidget['id'], ISharedStatsWidget['id']> = {};

                await Promise.all(report_widgets.map(async ({ stats_widget_id, settings: report_widget_settings }) => {

                  const stats_widget = await fetchStatsWidget(stats_widget_id);

                  const form = new JSONForm({} as IForm, fromJson(schema), undefined, {});

                  const values = form
                    .setValues(stats_widget)
                    .validate(true, { stripUnknown: true })
                    .getValues()
                  ;

                  const {
                    title,
                    type,
                    settings,
                    shared
                  } = values;

                  if (stats_widget) {
                    const new_widget = await createStatsWidget({
                      organization_id: res.organization_id,
                      title: title,
                      type: type,
                      settings: {
                        ...settings,
                        filter: {
                          ...(settings.filter || {}),
                          organization_ids: [res.organization_id]
                        }
                      },
                      shared
                    });

                    widget_dict[stats_widget.id] = new_widget.id;

                    await createStatsReportWidget({
                      stats_report_id: res.id,
                      stats_widget_id: new_widget.id,
                      settings: report_widget_settings
                    });
                  }
                }));

                await Promise.all(report_preview_widgets.map(async ({ stats_widget_id, settings: report_preview_widget_settings }) => {

                  let widget_id = widget_dict[stats_widget_id];

                  if (!widget_id) {
                    const stats_widget = await fetchStatsWidget(stats_widget_id);

                    const form = new JSONForm({} as IForm, fromJson(schema), undefined, {});

                    const values = form
                      .setValues(stats_widget)
                      .validate(true, { stripUnknown: true })
                      .getValues()
                    ;

                    const {
                      title,
                      type,
                      settings,
                      shared
                    } = values;

                    if (stats_widget) {
                      const new_widget = await createStatsWidget({
                        organization_id: res.organization_id,
                        title: title,
                        type: type,
                        settings: {
                          ...settings,
                          filter: {
                            ...(settings.filter || {}),
                            organization_ids: [res.organization_id]
                          }
                        },
                        shared
                      });

                      widget_dict[stats_widget.id] = new_widget.id;
                    }
                  }

                  if (widget_id)
                    createStatsReportPreviewWidget({
                      stats_report_id: res.id,
                      stats_widget_id: widget_id,
                      is_default: true,
                      settings: report_preview_widget_settings
                    });
                }));
              } catch (e) {
                AlertError(t, {
                  title: 'SOMETHING_WENT_WRONG',
                  content: 'SOMETHING_WENT_WRONG_CLONING_WIDGET'
                });
              }
            }

            redirect();

            setInitialValues(null);
          }}
          onCancel={() => {
            setForm(0);
            setInitialValues(null);
          }}
          initialValues={initialValues}
        />}
      </Sider>}

      {!!transfer && <Sider onClose={() => setTransfer(null)}>
        <div className='entity-form-edit'>
          <AtlasForm
            dispatch={dispatch}
            formLayout={{
              id: 'stats_report_add',
              ui_layout: FormLayoutTypes.HORIZONTAL,
              elements: [
                { ref: 'user' }
              ]
            }}
            form={fromJson({
              type: 'object',
              properties: {
                user: {
                  type: 'number'
                }
              }
            })}
            options={{
              user: filterRelated<IPreviewUser>(transfer.organization_id, 'user_reverse', 'stats_report', users.users, true, user => (transfer.is_dashboard && user.dashboard_license) || (!transfer.is_dashboard && user.reporting_license))
            }}
            onFinish={res => {
              transferStatsReport(transfer.id, res.user)
                .then(() => {
                  Promise.all(uniq([
                    ...stats_report_widgets.stats_report_widgets,
                    ...stats_report_preview_widgets.stats_report_preview_widgets
                  ]
                    .filter(({ stats_report_id }) => stats_report_id === transfer.id)
                    .map(({ stats_widget_id }) => stats_widget_id))
                    .map(stats_widget_id => transferStatsWidgetToOwner(transfer.id, stats_widget_id)))
                    .finally(() => {
                      setTransfer(null);
                    })
                  ;
                })
                .catch(() => {
                  AlertError(t, { title: t('SOMETHING_WENT_WRONG') });
                })
              ;
            }}
            onCancel={() => setTransfer(null)}
            initialValues={{ user: transfer.user_id }}
            title={t(transfer.is_dashboard ? 'TRANSFER_DASHBOARD' : 'TRANSFER_REPORT')}
            saveBtnTitle={'TRANSFER'}
          />
        </div>
      </Sider>}

    </div>
  </div>;
});
