import { VoiceCallActions } from '@Store';
import { IPreviewVoiceCall } from 'atlas-shared';

export const actionLoadingVoiceCalls = () => ({
  type: VoiceCallActions.LOADING,
  payload: []
});

export const actionSetVoiceCalls = (payload: Array<IPreviewVoiceCall>) => ({
  type: VoiceCallActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceCall = (payload: IPreviewVoiceCall) => ({
  type: VoiceCallActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceCall = (payload: IPreviewVoiceCall) => ({
  type: VoiceCallActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveVoiceCall = (id: IPreviewVoiceCall['id']) => ({
  type: VoiceCallActions.REMOVE_STATE,
  payload: id
});
