import React, { useEffect, useState } from 'react';
import { useSipUsers } from '@Hooks';
import { ISipStoreSession, ISipStoreSip } from '@Store';
import { getSipUsersWrapper } from 'SIP';
import './emergency.scss';
import { ChannelIcon, Icon } from '@Components';
import { HangupIcon } from '@Assets';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
}

export const EmergencyTabs = React.memo((props: IProps) => {

  const [emerg_calls, set_emerg_calls] = useState<Array<{sip_user: ISipStoreSip; sessions: Array<ISipStoreSession>}>>([
    // @ts-ignore
    //{ sip_user: { voice_asterisk: { id: 1 } }, sessions: [{ key: 'a', emergency: '113' }] }
  ]);
  const sipUsersWrapper = useSipUsers();
  const sipUserWrapper = getSipUsersWrapper();

  useEffect(() => {
    set_emerg_calls(sipUsersWrapper?.sip_users?.map(sip_user => ({
      sip_user,
      sessions: Object.values(sip_user.sessions || {}).filter(session => !!session.emergency)
    })).filter(({ sessions }) => sessions.length) || []);
  }, [sipUsersWrapper]);
  
  return <div>
    {emerg_calls.flatMap(({ sip_user, sessions }) => sessions.map(session => <div className='emergency-call'>
      <div className='icon'><ChannelIcon channel={{ c: 'Emergency' }} size={18} padding={1} /></div>
      <div className='callerid'>{session.emergency} <span>- {props.t('EMERGENCY')}</span></div>
      <div className='hangup'>
        <Icon
          icon={HangupIcon}
          iconProps={{ size: 20, style: { strokeWidth: 2 } }}
          onClick={() => {
            sipUserWrapper?.getSipUser(sip_user.voice_asterisk.id)?.hangupByKey(session.key);
          }}
        />
      </div>
    </div>))}
  </div>;
});
