import { IPreviewRelease } from 'atlas-shared';
import { IReleaseStore, ReleaseActions, TReleaseActionTypes } from '@Store';

export const ReleaseReducer = (state: IReleaseStore = {
  loaded: false,
  loading: false,
  releases: [],
  dict: {}
}, action: TReleaseActionTypes): IReleaseStore => {
  switch (action.type) {
  case ReleaseActions.LOADING:
    return { ...state, loading: true };
  case ReleaseActions.SET_STATE:
    const dict: IReleaseStore['dict'] = {};

    action.payload.forEach((release: IPreviewRelease) => dict[release.id] = release);

    return { ...state, loaded: true, loading: false, releases: action.payload, dict };
  case ReleaseActions.ADD_STATE:
    return { ...state, releases: [...state.releases, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case ReleaseActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, releases: state.releases.filter((item: IPreviewRelease) => item.id !== action.payload), dict };
  }
  case ReleaseActions.UPDATE_STATE:
    const releases = [...state.releases];
    const index = state.releases.findIndex((release: IPreviewRelease) => release.id === action.payload.id);

    if (!~index)
      releases.push(action.payload);
    else
      releases[index] = action.payload;

    return { ...state, releases, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
