import React from 'react';
import { IAbilities, IAuth, IOrganizationStore, IUserStore } from '@Store';
import { CrudEnum, IPreviewPhonenumberPurchase, PermissionEnum, EnumTranslateTitle, TPhonenumberPurchaseSortableListCol } from 'atlas-shared';
import { getFullName, tableColumnDatetimeSorter, tableColumnStringSorter, DisplayPhoneNumber, timeSince, translatePhoneNumber } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listEditButton, GetColumnSearchProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const PhonenumberPurchaseListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewPhonenumberPurchase) => React.ReactElement> = [], filters: IWithListProps<IPreviewPhonenumberPurchase, TPhonenumberPurchaseSortableListCol>['filters'], sorter: IWithListProps<IPreviewPhonenumberPurchase, TPhonenumberPurchaseSortableListCol>['sorter']): ColumnsType<IPreviewPhonenumberPurchase> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewPhonenumberPurchase, b: IPreviewPhonenumberPurchase) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewPhonenumberPurchase['organization_id'], phonenumber_purchase: IPreviewPhonenumberPurchase) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id } }),
      sorter: (a: IPreviewPhonenumberPurchase, b: IPreviewPhonenumberPurchase) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('STATUS'),
      dataIndex: 'status',
      key: 'status',
      render: (status: IPreviewPhonenumberPurchase['status'], phonenumber_purchase: IPreviewPhonenumberPurchase) => EnumTranslateTitle('PHONENUMBER_PURCHASE_STATUS', status, t),
      sorter: (a: IPreviewPhonenumberPurchase, b: IPreviewPhonenumberPurchase) => EnumTranslateTitle('PHONENUMBER_PURCHASE_STATUS', a.status, t).localeCompare(EnumTranslateTitle('PHONENUMBER_PURCHASE_STATUS', b.status, t)),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'status')?.order || null,
      className: 'table-cell-status',
    },
    {
      title: t('NUMBER'),
      dataIndex: 'number',
      key: 'number',
      render: (number: IPreviewPhonenumberPurchase['number'], phonenumber_purchase: IPreviewPhonenumberPurchase) => <DisplayPhoneNumber t={t} phonenumber={translatePhoneNumber(number)} />,
      sorter: (a: IPreviewPhonenumberPurchase, b: IPreviewPhonenumberPurchase) => tableColumnStringSorter(a.number, b.number),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'number')?.order || null,
      className: 'table-cell-number',
    },
    {
      title: t('USER'),
      dataIndex: 'user_id',
      key: 'user_id',
      render: (user_id: IPreviewPhonenumberPurchase['user_id'], phonenumber_purchase: IPreviewPhonenumberPurchase) => user_id === -1 ? t('SYS_USER') : users.dict?.[user_id] ? getFullName(users.dict[user_id]) : t('USER_NOT_FOUND', { replace: { user_id } }),
      sorter: (a: IPreviewPhonenumberPurchase, b: IPreviewPhonenumberPurchase) => (users.dict?.[a.user_id] ? a.user_id === -1 ? t('SYS_USER') : getFullName(users.dict?.[a.user_id]) : '').localeCompare(users.dict?.[b.user_id] ? getFullName(users.dict?.[b.user_id]) : ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'user_id')?.order || null,
      className: 'table-cell-user_id',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewPhonenumberPurchase['created_at'], phonenumber_purchase: IPreviewPhonenumberPurchase) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewPhonenumberPurchase, b: IPreviewPhonenumberPurchase) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewPhonenumberPurchase) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'phonenumber_purchase', row)}
          {abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.PhonenumberPurchase, row) && listEditButton(t, navigate, 'phonenumber_purchase', row)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewPhonenumberPurchase>;

};
