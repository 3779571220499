import { RestRequest } from '@Utils';
import { IOngoingChannel, IPreviewVoiceRecording, ISharedVoiceCall, ISharedVoiceRecording, IPreviewVoiceCall, ISharedVoiceCallBridge, ChannelEnum, ISharedConversation, IConversationTimeline } from 'atlas-shared';

export const voiceCallStartRecording = (organization_id: ISharedVoiceCall['organization_id'], voice_call_id: ISharedVoiceCall['id'], voice_call_bridge_id: ISharedVoiceCallBridge['id']): Promise<any> => {
  return RestRequest.post<any, ISharedVoiceRecording>(`voice_recording/${organization_id}/${voice_call_id}/${voice_call_bridge_id}/start_recording`, {});
};

export const voiceCallStopRecording = (organization_id: ISharedVoiceCall['organization_id'], voice_recording_id: IPreviewVoiceRecording['id']): Promise<any> => {
  return RestRequest.put<any, ISharedVoiceRecording>(`voice_recording/${organization_id}/${voice_recording_id}/stop_recording`, {});
};

export const voiceCallsOngoing = (): Promise<Array<IOngoingChannel>> => {
  return RestRequest.get<Array<IOngoingChannel>>('voice_call/ongoing');
};

export const hangupVoiceCall = async (organization_id: ISharedVoiceCall['organization_id'], id: IPreviewVoiceCall['id']): Promise<IPreviewVoiceCall> => {
  return RestRequest.delete<IPreviewVoiceCall>(`voice_call/p/${organization_id}/${id}`);
};

export const voiceCallsTimeline = (organization_id: ISharedVoiceCall['organization_id'], conversation_id: ISharedConversation['id'], voice_call_id: ISharedVoiceCall['id']): Promise<{voice_call: IPreviewVoiceCall; events: Array<IConversationTimeline>}> => {
  return RestRequest.get<{voice_call: IPreviewVoiceCall; events: Array<IConversationTimeline>}>(`conversation/p/${organization_id}/${conversation_id}/${ChannelEnum.Voice}/${voice_call_id}/timeline`, {});
};

export const fetchVoiceCalls = async (): Promise<Array<IPreviewVoiceCall>> => {
  return RestRequest.get<Array<IPreviewVoiceCall>>('voice_call', { socket_id: global.socketId });
};

export { fetchVoiceCall, patchVoiceCall } from './abstract/voice_call.api.abstract';
