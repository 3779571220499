import React, { useEffect, useState } from 'react';
import '../admin.list.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { IPreviewOrganization, PermissionEnum, CrudEnum, TOrganizationSortableListCol } from 'atlas-shared';
import { Table } from 'antd';
import { AdminListPageHeader, ListPageEmpty } from '@Components';
import { IWithListProps, IWithListSorterProps, withList } from '@Hocs/with.list';
import { useAbilities, useAuth, useOrganizations } from '@Hooks';
import { nestedArray, OrganizationListColumns } from '@Utils';
import { IAuth } from '@Store';

const OrganizationList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams }: IWithListProps<IPreviewOrganization, TOrganizationSortableListCol>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const [loading, setLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<any>>([]);
  
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedCount, setDeletedCount] = useState<number>(0);
  
  useEffect(() => {
    if (!organizations.loaded)
      return;
      
    setLoading(false);
    setRows(nestedArray<IPreviewOrganization>((!showDeleted ? organizations.organizations.filter(p => !p.deleted_at) : organizations.organizations || []).map(row => ({ ...row, key: row.id })), 'organization_id', false));
    setDeletedCount(organizations.organizations.filter(organization => !!organization.deleted_at).length);
  }, [organizations, showDeleted]);

  const columns: ColumnsType<IPreviewOrganization> = React.useMemo(() => {
    return OrganizationListColumns(t, navigate, showDeleted, abilities, auth, [], filters, sorter);
  }, [t, navigate, showDeleted, abilities, auth, filters, sorter]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('ORGANIZATIONS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      deleted={[showDeleted, setShowDeleted]} deletedCount={deletedCount}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.Organization) ? { callback: () => navigate('/admin/organization/add') } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        defaultExpandedRowKeys={rows.map(r => r.id)}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TOrganizationSortableListCol, order: s.order as IWithListSorterProps<IPreviewOrganization, TOrganizationSortableListCol>['order'] }))
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />
        }}
      />
    </div>
  </div>;
};

export const OrganizationListView = React.memo(withList<IPreviewOrganization, TOrganizationSortableListCol>(OrganizationList, {
  filters: {},
  sorter: [{ column: 'created_at', order: 'descend' }],
  pagination: { current: 1, pageSize: 10 }
}));
