import { PrepaidCreditActions } from '@Store';
import { IPreviewPrepaidCredit } from 'atlas-shared';
import { fetchPrepaidCredits } from '@Api';

export const actionFetchPrepaidCredits = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPrepaidCredits());
    const prepaid_credits = await fetchPrepaidCredits();

    dispatch(actionSetPrepaidCredits(prepaid_credits));
    return prepaid_credits;
  };
};

export const actionLoadingPrepaidCredits = () => ({
  type: PrepaidCreditActions.LOADING,
  payload: []
});

export const actionSetPrepaidCredits = (payload: Array<IPreviewPrepaidCredit>) => ({
  type: PrepaidCreditActions.SET_STATE,
  payload: payload
});

export const actionAddPrepaidCredit = (payload: IPreviewPrepaidCredit) => ({
  type: PrepaidCreditActions.ADD_STATE,
  payload: payload
});

export const actionUpdatePrepaidCredit = (payload: IPreviewPrepaidCredit) => ({
  type: PrepaidCreditActions.UPDATE_STATE,
  payload: payload
});

export const actionRemovePrepaidCredit = (id: IPreviewPrepaidCredit['id']) => ({
  type: PrepaidCreditActions.REMOVE_STATE,
  payload: id
});
