import { journeyAction, settingsFormSchema } from './base';

import { JourneyActionForwardMail as JourneyActionForwardMailBase } from 'atlas-shared';
import { FormElementTypes } from 'atlas-form';

export const JourneyActionForwardMail = journeyAction({
  ...JourneyActionForwardMailBase,
  settings_form: settingsFormSchema({
    elements: [
      { ref: 'mail_account_id' },
      { ref: 'to' },
      { ref: 'cc', mode: 'tags' },
      { ref: 'bcc', mode: 'tags' },
      { ref: 'subject' },
      { ref: 'body' }
    ]
  }),
  additionalParams: {
    properties_body: ({ ui_type: FormElementTypes.HTML, hashOptions: ['conversation', 'contact'] })
  },
  icon: 'Send'
});
