import { RestRequest } from '@Utils';
import { IPreviewDraft, ISharedDraft, ISharedCreateDraft, ISharedPatchDraft } from 'atlas-shared';

export const fetchDraft = async (id: IPreviewDraft['id']): Promise<ISharedDraft> => {
  return RestRequest.get<ISharedDraft>(`draft/${id}`, { socket_id: global.socketId });
};

export const createDraft = (payload: ISharedCreateDraft) => {
  return RestRequest.post<any, ISharedDraft>('draft', payload, { socket_id: global.socketId });
};

export const patchDraft = (id: IPreviewDraft['id'], payload: ISharedPatchDraft) => {
  return RestRequest.patch<any, ISharedDraft>(`draft/${id}`, payload, { socket_id: global.socketId });
};

export const deleteDraft = (id: IPreviewDraft['id']) => {
  return RestRequest.delete(`draft/${id}`, { socket_id: global.socketId });
};

export const getDraftUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('draft/update/schema');
};

export const getDraftCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('draft/create/schema');
};

export const getDraftPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('draft/patch/schema');
};
