import { RestRequest } from '@Utils';
import { IPreviewPhonenumberSetupCost, ISharedPhonenumberSetupCost, ISharedCreatePhonenumberSetupCost, ISharedPatchPhonenumberSetupCost } from 'atlas-shared';

export const fetchPhonenumberSetupCosts = async (): Promise<Array<IPreviewPhonenumberSetupCost>> => {
  return RestRequest.get<Array<IPreviewPhonenumberSetupCost>>('phonenumber_setup_cost', { socket_id: global.socketId });
};

export const fetchPhonenumberSetupCost = async (id: IPreviewPhonenumberSetupCost['id']): Promise<ISharedPhonenumberSetupCost> => {
  return RestRequest.get<ISharedPhonenumberSetupCost>(`phonenumber_setup_cost/${id}`, { socket_id: global.socketId });
};

export const createPhonenumberSetupCost = (payload: ISharedCreatePhonenumberSetupCost) => {
  return RestRequest.post<any, ISharedPhonenumberSetupCost>('phonenumber_setup_cost', payload, { socket_id: global.socketId });
};

export const patchPhonenumberSetupCost = (id: IPreviewPhonenumberSetupCost['id'], payload: ISharedPatchPhonenumberSetupCost) => {
  return RestRequest.patch<any, ISharedPhonenumberSetupCost>(`phonenumber_setup_cost/${id}`, payload, { socket_id: global.socketId });
};

export const deletePhonenumberSetupCost = (id: IPreviewPhonenumberSetupCost['id']) => {
  return RestRequest.delete(`phonenumber_setup_cost/${id}`, { socket_id: global.socketId });
};

export const getPhonenumberSetupCostUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_setup_cost/update/schema');
};

export const getPhonenumberSetupCostCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_setup_cost/create/schema');
};

export const getPhonenumberSetupCostPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_setup_cost/patch/schema');
};
