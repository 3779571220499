import { StatsWidgetReducer as StatsWidgetReducerBase } from './abstract/stats_widget.reducer.abstract';
import { IStatsWidgetStore, StatsWidgetActions, TStatsWidgetActionTypes } from '@Store/types';
import { IPreviewStatsWidget } from 'atlas-shared/dist';

export const StatsWidgetReducer = (state: IStatsWidgetStore = {
  loaded: false,
  loading: false,
  stats_widgets: [],
  dict: {}
}, action: TStatsWidgetActionTypes): IStatsWidgetStore => {
  switch (action.type) {
  case StatsWidgetActions.SET_DATA: {
    const stats_widgets = [...state.stats_widgets];
    const index = state.stats_widgets.findIndex((stats_widget: IPreviewStatsWidget) => stats_widget.id === action.payload.id);

    if (index === -1)
      return state;

    const payload = { ...stats_widgets[index], ...action.payload };

    stats_widgets[index] = payload;

    return { ...state, stats_widgets, dict: { ...state.dict, [action.payload.id]: payload } };
  }
  case StatsWidgetActions.UPDATE_STATE: {
    const stats_widgets = [...state.stats_widgets];
    const index = state.stats_widgets.findIndex((stats_widget: IPreviewStatsWidget) => stats_widget.id === action.payload.id);
    const payload = { ...stats_widgets[index], ...action.payload };

    if (!~index)
      stats_widgets.push(payload);
    else
      stats_widgets[index] = payload;

    return { ...state, stats_widgets, dict: { ...state.dict, [action.payload.id]: payload } };
  }
  default:
    return StatsWidgetReducerBase(state, action);
  }
};
