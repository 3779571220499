import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchSounds, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useSounds = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const sounds = useSelector((store: IReducerStore) => store.sounds);

  useEffect(() => {
    if (!initiated && autoload && !sounds.loaded && !sounds.loading) {
      initiated = true;
      dispatch(actionFetchSounds());
    }
  }, [autoload, sounds.loaded, sounds.loading, dispatch]);

  return sounds;
};
