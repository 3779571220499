import { TFunction } from 'i18next';
import { FlagIcon } from '@Assets/icons';
import { } from '@Store';
import React from 'react';
import { IPreviewConversation } from 'atlas-shared';
import { FlagEnumColors } from '@Utils';
import { IconText } from '@Components';

interface IProps {
  t: TFunction;
  shortened: boolean;
  conversation: IPreviewConversation;
}

export const ConversationStatus = React.memo(({ conversation, shortened, t }: IProps) => {
  return (
    <div className={'row status-flag-container'}>
      <small className={'status-container'}>
        {t(`STATUS_${conversation.status}`)}
      </small>
      {conversation.flag && <div className={'flag-container'}>
        <IconText icon={FlagIcon} iconProps={{ size: 8, stroke: 4, style: { color: 'white' } }} iconWrapperStyle={{ backgroundColor: FlagEnumColors[conversation.flag], color: 'white', padding: '3px' }} style={{ color: FlagEnumColors[conversation.flag] }} iconCircle={true} text={shortened ? '' : t(conversation.flag.toUpperCase())}/>
      </div>}
    </div>
  );
});
