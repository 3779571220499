import React from 'react';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewVoiceAsteriskToggleHistory, PermissionEnum, TVoiceAsteriskToggleHistorySortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const VoiceAsteriskToggleHistoryListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsteriskToggleHistory) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsteriskToggleHistory, TVoiceAsteriskToggleHistorySortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsteriskToggleHistory, TVoiceAsteriskToggleHistorySortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsteriskToggleHistory> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewVoiceAsteriskToggleHistory, b: IPreviewVoiceAsteriskToggleHistory) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('DATE'),
      dataIndex: 'date',
      key: 'date',
      render: (date: IPreviewVoiceAsteriskToggleHistory['date'], voice_asterisk_toggle_history: IPreviewVoiceAsteriskToggleHistory) => <div className='date-column'>{date ? timeSince(t, new Date(date), true) : ''}</div>,
      sorter: (a: IPreviewVoiceAsteriskToggleHistory, b: IPreviewVoiceAsteriskToggleHistory) => tableColumnDatetimeSorter(a.date, b.date),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'date')?.order || null,
      className: 'table-cell-date',
    },
    {
      title: t('IS_DOWNGRADED'),
      dataIndex: 'is_downgraded',
      key: 'is_downgraded',
      render: (is_downgraded: IPreviewVoiceAsteriskToggleHistory['is_downgraded'], voice_asterisk_toggle_history: IPreviewVoiceAsteriskToggleHistory) => tableColumnBooleanRender(t, is_downgraded),
      className: 'table-cell-is_downgraded',
    },
    {
      title: t('CAN_TOGGLE'),
      dataIndex: 'can_toggle',
      key: 'can_toggle',
      render: (can_toggle: IPreviewVoiceAsteriskToggleHistory['can_toggle'], voice_asterisk_toggle_history: IPreviewVoiceAsteriskToggleHistory) => tableColumnBooleanRender(t, can_toggle),
      className: 'table-cell-can_toggle',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewVoiceAsteriskToggleHistory['created_at'], voice_asterisk_toggle_history: IPreviewVoiceAsteriskToggleHistory) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewVoiceAsteriskToggleHistory, b: IPreviewVoiceAsteriskToggleHistory) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewVoiceAsteriskToggleHistory) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'voice_asterisk_toggle_history', row)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewVoiceAsteriskToggleHistory>;

};
