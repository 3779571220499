import React, { memo, useEffect, useState } from 'react';
import { NodeProps } from 'react-flow-renderer';
import { getActionIconText, getActionSubtitle, IJourneyActionProps, stringWidth } from '@Utils';
import {
  ChannelEnum,
  IJourneyPayloadNodesKeyPortsKeyProperty,
  JourneyPortDirectionEnum,
  Nullable, IJourneyNodeError } from 'atlas-shared';
import { getActionProps, INodeFlowElementData } from './index';
import { RenderInputHandle } from './input.handle';
import { RenderOutputHandle } from './output.handle';
import { ChannelsIcon } from '@Components';
import './styles.scss';

import { useTranslation } from 'react-i18next';
import JourneyError from './journey.error';

const Header = memo((props:{onClick?:()=> void; title: React.ReactElement; subTitle: string; channels: Nullable<Array<ChannelEnum>>}) => {
  const { onClick, title, subTitle, channels } = props;

  return (
    <div onClick={onClick} className={'node-header'}>
      <div className={'row1'}>
        {channels && <ChannelsIcon channels={channels.map(c => ({ c }))} size={10} padding={5}/>}
        <span className={'title'}>
          {title}
        </span>
      </div>
      <div className={'row2'}>
        <span className={'subtitle'}>
          {subTitle}
        </span>
      </div>
      <hr className={'hr'}/>
    </div>
  );
});

const NodeView = (props: NodeProps<INodeFlowElementData>) => {
  const { data } = props;

  const { node, errors } = data;
  const { t } = useTranslation();
  const [nodeErrors, setNodeErrors] = useState<Array<IJourneyNodeError>>([]);
  const action: IJourneyActionProps = getActionProps(node.action);
  const [handles, setHandles] = useState<Array<React.ReactElement>>([]);

  useEffect(() => {
    let left = 0;

    setHandles(Object.values(node.ports)
      .sort((a, b) => a.index < b.index ? -1 : 1)
      .map((port: IJourneyPayloadNodesKeyPortsKeyProperty, index: number) => {

        const errors = data.errors.filter(e => e.portKey === port.id);
        const title = port.title ? t(port.title) : t(`OPTION_${port.index + 1}`);
        const width = Math.max(stringWidth(title), 24);

        if (port.type === JourneyPortDirectionEnum.Output)
          left += width + 8;

        return port.type === JourneyPortDirectionEnum.Input ? <RenderInputHandle key={port.id} t={t} port={port} index={index} actionProps={action} errors={errors} required={port.required} /> : <RenderOutputHandle left={left - (width / 2)} width={width} title={title} key={port.id} t={t} port={port} index={index} actionProps={action} errors={errors} />;
      }));
  }, [node.ports, errors, action, data.errors, t]);

  useEffect(() => {
    setNodeErrors(errors.filter(e => !e.portKey));
  }, [errors]);

  if (!handles.length)
    return <></>;

  return (
    <>
      {handles}
      <div
        className={'node'}
      >
        <Header title={getActionIconText(action, t, node)} subTitle={getActionSubtitle(action, t, node)} channels={action.channels || null}/>
        <div className={'body'}>
          {node.description || t(`NODE_DESCRIPTION_${node.action}`, '', {})}
          <JourneyError errors={nodeErrors} />
        </div>
      </div>
    </>
  );
};

// export default React.memo(NodeView, (prev, curr) => {
//   console.log('prev.data.errors', prev.data.errors, curr.data.errors);
//   return JSON.stringify(prev.data.node) === JSON.stringify(curr.data.node) && JSON.stringify(prev.data.errors) === JSON.stringify(curr.data.errors);
// });

const prepareComparisonObject = (props: NodeProps<INodeFlowElementData>) => ({
  errors: props.data.errors.length,
  node: {
    properties: props.data.node.properties,
    title: props.data.node.title,
    description: props.data.node.description,
    ports: props.data.node.ports
  }
});

export default React.memo(NodeView, (prevProps, nextProps) => JSON.stringify(prepareComparisonObject(prevProps)) === JSON.stringify(prepareComparisonObject(nextProps)));
