import { RestRequest } from '@Utils';
import {
  IPreviewPhonenumberStock,
  ISharedBulkCreatePhonenumberStock,
  ISharedPhonenumberStock,
  ISharedSearchPhonenumberStock
} from 'atlas-shared/dist';

export * from './abstract/phonenumber_stock.api.abstract';

export const searchPhonenumberStock = async (params: ISharedSearchPhonenumberStock): Promise<{items: Array<IPreviewPhonenumberStock>; count: number}> => {
  return RestRequest.post<ISharedSearchPhonenumberStock, {items: Array<IPreviewPhonenumberStock>; count: number}>('phonenumber_stock/_search', params, { socket_id: global.socketId });
};

export const bulkCreatePhonenumberStock = (payload: ISharedBulkCreatePhonenumberStock) => {
  return RestRequest.post<any, Array<ISharedPhonenumberStock>>('phonenumber_stock/bulk', payload, { socket_id: global.socketId });
};

export const getPhonenumberStockBulkCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_stock/bulk/create/schema');
};
