import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  TeamEditForm,
  TeamEditInitialValues,
  TeamComponents,
  TeamAdditionalParams,
  TeamFormOptions,
  onTeamFormSaved,
  ITeamFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedTeam, ISharedPatchTeam, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchTeam, patchTeam, getTeamUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useUsers } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedTeam> {}

const TeamFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const users = useUsers();

  useEffect(() => {
    fetchTeam(id).then(team => setValue(team));
  }, [id, setValue]);

  const param_props: ITeamFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    users: users.users
  };
  const formLayout: IForm | undefined = useMemo(() => value && TeamEditForm(value, param_props, t), [value, organizations, users, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && TeamFormOptions(value, param_props, t), [value, organizations, users, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchTeam, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchTeam) => value && save(() => patchTeam(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onTeamFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !users.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.Team, value)}
    >
      <AtlasForm<ISharedPatchTeam>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={TeamComponents}
        additionalParams={TeamAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={TeamEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('TEAM')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const TeamFormEditView = React.memo(withEdit(TeamFormEdit, getTeamUpdateSchema));
