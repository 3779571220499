import { IPreviewRecentlySearched } from 'atlas-shared';

export enum RecentlySearchedActions {
  SET_STATE = 'RECENTLY_SEARCHED_SET_STATE',
  ADD_STATE = 'RECENTLY_SEARCHED_ADD_STATE',
  REMOVE_STATE = 'RECENTLY_SEARCHED_REMOVE_STATE',
  GET_STATE = 'RECENTLY_SEARCHED_GET_STATE',
  UPDATE_STATE = 'RECENTLY_SEARCHED_UPDATE_STATE',
  LOADING = 'RECENTLY_SEARCHED_LOADING',
}

export interface IRecentlySearchedLoading {
  type: RecentlySearchedActions.LOADING;
}

export interface IRecentlySearchedSetAction {
  type: RecentlySearchedActions.SET_STATE;
  payload: Array<IPreviewRecentlySearched>;
}

export interface IRecentlySearchedUpdateAction {
  type: RecentlySearchedActions.UPDATE_STATE;
  payload: IPreviewRecentlySearched;
}

export interface IRecentlySearchedAddAction {
  type: RecentlySearchedActions.ADD_STATE;
  payload: IPreviewRecentlySearched;
}

export interface IRecentlySearchedRemoveAction {
  type: RecentlySearchedActions.REMOVE_STATE;
  payload: IPreviewRecentlySearched['id'];
}

export interface IRecentlySearchedStore {
  loaded: boolean;
  loading: boolean;
  recently_searcheds: Array<IPreviewRecentlySearched>;
  dict: Record<IPreviewRecentlySearched['id'], IPreviewRecentlySearched>;
}

export type TRecentlySearchedActionTypes = IRecentlySearchedSetAction | IRecentlySearchedUpdateAction | IRecentlySearchedAddAction | IRecentlySearchedRemoveAction | IRecentlySearchedLoading;
