import { ISvgIconProps } from '@Assets/icons/interface';
import { SvgIcon } from '@Assets/icons/SvgIcon';
import React from 'react';

export const DollarIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="12" y1="1" x2="12" y2="23"></line>
    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
  </SvgIcon>;
};

export const DollarStrokedIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="12" y1="1" x2="12" y2="23"></line>
    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    <line x1="1" y1="1" x2="22" y2="22" fill="red" />
  </SvgIcon>;
};
