import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchVoiceTrunks, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useVoiceTrunks = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_trunks = useSelector((store: IReducerStore) => store.voice_trunks);

  useEffect(() => {
    if (!initiated && autoload && !voice_trunks.loaded && !voice_trunks.loading) {
      initiated = true;
      dispatch(actionFetchVoiceTrunks());
    }
  }, [autoload, voice_trunks.loaded, voice_trunks.loading, dispatch]);

  return voice_trunks;
};
