type TypeNested<T> = Array<T & {children: TypeNested<T>}>;

const fetchChildren = (parent: any, rows: Array<any>, property: string, includes: boolean = false) => {
  let children = rows
    .filter(child => !includes ? child[property] === parent.id : child[property]?.includes(parent.id))
    .map(child => fetchChildren(child, rows, property, includes));

  if (!children.length)
    children = undefined;

  return {
    ...parent,
    children
  };
};

export const nestedArray = <T extends {id: number}>(rows: Array<any>, property: string, ignoreParentless: boolean = true, includes: boolean = false): TypeNested<T> => {
  return rows.filter(row => !row[property] || (!ignoreParentless && !rows.some(r => !includes ? r.id === row[property] : row[property].includes(r.id)))).map(row => fetchChildren(row, rows, property, includes));
};

export const uniq = (arr: Array<any>) => {
  return arr.filter((value, index) => {
    return arr.indexOf(value) === index;
  });
};

export const findLast = (arr: Array<any>, callback: Function) => {
  const len = arr.length >>> 0;

  for (let i = len - 1; i >= 0; i--) {
    if (callback(arr[i], i, arr)) {
      return arr[i];
    }
  }

  return undefined;
};
