import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './conversation.message.mail.scss';
import { AlertError, getContactFullName, highlightClassNames, InfoModal, timeSince } from '@Utils';
import {
  ISharedMailMessage,
  Undefinable,
  DirectionEnum,
  ISharedFullConversation,
  IPreviewMailAccount,
  ISharedMailMessageAttachmentProperty,
  ISharedMailMessageToProperty
} from 'atlas-shared';

import { THighlightedMessage, useMailAccounts } from '@Hooks';
import { ConversationMessageMailAttachment } from './conversation.message.mail.attachment';
import {
  Avatar,
  ConversationMessageReply,
  ConversationMessageReplies, Button
} from '@Components';
import { TFunction } from 'i18next';
import { IAuth } from '@Store';
import { fetchMailMessageAttachmentBlob, resendMailMessage } from '@Api';

interface IProps {
  t: TFunction;
  dispatch: any;
  auth: IAuth;
  index: number;
  message: ISharedMailMessage;
  conversation: ISharedFullConversation;
  avatar: React.ReactNode;
  highlightedMessage: THighlightedMessage;
}

export const ConversationMessageMail = React.memo(({ message, conversation, t, auth, avatar, highlightedMessage, dispatch, index }: IProps) => {
  const ref = useRef(null);
  const [mailAccount, setMailAccount] = useState<Undefinable<IPreviewMailAccount>>();
  const mail_accounts = useMailAccounts();
  const hasMoreLimit = useMemo(() => message.direction === DirectionEnum.InBound ? (!index ? 250 : 200) : 150, [message]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [__html, setHtml] = useState<string>(
    (message.body?.html || message.body?.textAsHtml || message.body?.text || `<div class="missing_body">${t('MAIL_HAS_NO_BODY')}</div>`)
      .replaceAll(/(color:(\s)?rgb\((\s)?0(\s)?,(\s)?0(\s)?,(\s)?0(\s)?\))|(color="#000000")/gim, '')
      .replaceAll(/<meta\s[^>]+>/igm, '')
      .replaceAll(/<base([\s\S]+?)>/igm, '')
      .replaceAll(/<style([\s\S]+?)<\/style>/igm, '')
      .replaceAll(/<script[^>]*>((.|[\n\r])*)<\/script>/igm, '')
  );

  useEffect(() => {
    if (hasMore)
      return;

    if (ref.current)
      setHasMore((ref.current as HTMLElement).clientHeight > hasMoreLimit);
  }, [hasMoreLimit, hasMore]);

  useEffect(() => {
    setMailAccount(mail_accounts.mail_accounts.find(ma => ma.id === message.mail_account_id));
  }, [mail_accounts, message.mail_account_id]);

  const translateEmail = useCallback((item: ISharedMailMessageToProperty) => {
    let name = item.name;

    if (!name && mailAccount && mailAccount?.address === item.address)
      name = mailAccount.title;

    if (!name && conversation.contact?.emails.includes(item.address))
      name = getContactFullName(conversation.contact, t, true);

    if (!name)
      return <div className='email-address' title={item.address}>{item.address}<span className='delimiter'></span></div>;

    return <div className='email-address' title={item.address}>{name} &lt;{item.address}&gt;<span className='delimiter'></span></div>;
  }, [mailAccount, conversation.contact]);

  const displayMailAccount = useMemo(() => {
    return mailAccount ? translateEmail({
      address: mailAccount.address,
      name: mailAccount.title
    }) : <></>;
  }, [mailAccount]);

  const displayContact = useMemo(() => {
    return translateEmail({
      address: message.contact_email,
      name: conversation.contact && getContactFullName(conversation.contact, t, true)
    });
  }, [mailAccount]);

  const from = useMemo(() => {
    return message.direction === DirectionEnum.Outbound ? displayMailAccount : displayContact;
  }, [displayMailAccount, displayContact]);

  const to = useMemo(() => {
    return message.to?.length ? message.to.map(translateEmail) : message.direction === DirectionEnum.Outbound ? displayContact : displayMailAccount;
  }, [message, displayMailAccount, displayContact]);

  useEffect(() => {
    if (!message.attachments?.length)
      return;

    const regExp = /<img([^>]*)>/igm;
    const matches = __html.match(regExp) || [];

    Promise.all(matches.map(img => {

      return new Promise(resolve => {
        const match = img.match(/"cid:[^"]*"/ig);

        if (!match?.length)
          return resolve(img);

        const filename = match[0].substr(5, match[0].length - 6);
        const attachment_index = message.attachments?.findIndex(attachment => attachment.filename === filename || attachment.cid === filename) ?? -1;
        const attachment = attachment_index > -1 && message.attachments?.[attachment_index];

        if (!attachment)
          return resolve(img);

        fetchMailMessageAttachmentBlob(message.organization_id, message.id, attachment.unique_filename || attachment.filename, attachment.filename, () => {
          resolve(img);
        }).then(blob => {
          const urlCreator = window.URL || window.webkitURL;

          resolve(img.replace(match[0], `"${urlCreator.createObjectURL(blob)}"`));
        });
      });

    }))
      .then(replacements => {
        let index = -1;

        setHtml(__html.replaceAll(regExp, (_) => {

          index++;
          return (replacements[index] as string) || '';
        }));
      })
    ;
  }, [__html]);

  return (
    <div className={`conversation-message conversation-message-mail${message.auto_reply ? ' auto_reply' : ''} ${message.direction === DirectionEnum.InBound ? 'inbound' : 'outbound'}${highlightClassNames(message, highlightedMessage)}`}>
      <ConversationMessageReply t={t} auth={auth} message={message} conversation={conversation} />
      <div className='conversation-message-content'>
        {message.smtp_error && <div className='smtp-error'>
          <Button
            type='primary'
            size='small'
            onClick={() => resendMailMessage(message.organization_id, message.id)
              .then(_ => InfoModal(t, { content: t('RESEND_INITIATED') }))
              .catch(e => AlertError(t, { content: t(e) }))
            }
          >{t('RESEND_EMAIL')}</Button>
          <div className='title'><span>{t('SMTP_ERROR')}</span> {message.handled_at && timeSince(t, new Date(message.handled_at), true)}</div>
          <p className='description'>{message.smtp_error}</p>
        </div>}
        <div className={'head'} >
          <div className={'info'}>
            <div className={'time'} >
              {timeSince(t, new Date(message.created_at), true)}
            </div>
            <div className={'title'} key='message_title'>
              {message.subject ? message.subject : <span style={{ opacity: .5 }}>{t('NO_SUBJECT')}</span>}
            </div>
            {
              <div className={'extras'} key='message_from'>
                <span className='t'>{t('FROM')}</span> {from}
              </div>
            }
            {
              <div className={'extras'} key='message_to'>
                <span className='t'>{t('TO')}</span> <div className='email-addresses'>{to}</div>
              </div>
            }
            {
              message.cc?.length > 0 && <div key='message_cc' className={'extras'}>
                <span className='t'>{t('CC')}</span> <div className='email-addresses'>{message.cc.map(translateEmail)}</div>
              </div>
            }
            {
              message.bcc?.length > 0 && <div key='message_bcc' className={'extras'}>
                <span className='t'>{t('BCC')}</span> <div className='email-addresses'>{message.bcc.map(bcc => translateEmail({ address: bcc }))}</div>
              </div>
            }
            {
              message.attachments && <div className={'attachments'} >
                {message.attachments.map((attachment, index) => <ConversationMessageMailAttachment dispatch={dispatch} key={index} organization_id={message.organization_id} attachments={message.attachments as Array<ISharedMailMessageAttachmentProperty>} index={index} attachment={attachment} mail_message_id={message.id} t={t} />)}
              </div>
            }
          </div>
          {message.direction === DirectionEnum.Outbound && avatar}
          {message.direction === DirectionEnum.InBound && conversation.contact && <div className={'user'}>
            <Avatar size={24} style={{ background: 'var(--grey-middle)', color: 'var(--blue-grey)' }} />
            <div className={'user-info'} >
              <span>
                {getContactFullName(conversation.contact, t)}
              </span>
              {
                message.contact_email &&
                <span>
                  {`<${message.contact_email || ''}>`}
                </span>
              }
            </div>
          </div>
          }
        </div>
        <div ref={ref} className={`isolated-mail-body more-wrapper${showMore ? ' show-more' : ''}${hasMore ? ' has-more' : ''}`} style={{ maxHeight: hasMore ? hasMoreLimit : 'auto' }} dangerouslySetInnerHTML={{ __html }}>
        </div>
        {hasMore && <div className={`more-actions${showMore ? ' show-more' : ''}`} onClick={() => setShowMore(!showMore)}>
          <div className={'action'}>
            <span>{t(showMore ? 'SHOW_LESS' : 'SHOW_MORE')}</span>
          </div>
        </div>}
      </div>
      <ConversationMessageReplies message={message} conversation={conversation} highlightedMessage={highlightedMessage} />
    </div>
  );
});
