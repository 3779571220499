import { AgentprofileActions } from '@Store';
import { IPreviewAgentprofile } from 'atlas-shared';
import { fetchAgentprofiles } from '@Api';

export const actionFetchAgentprofiles = () => {
  return async (dispatch) => {
    dispatch(actionLoadingAgentprofiles());
    const agentprofiles = await fetchAgentprofiles();

    dispatch(actionSetAgentprofiles(agentprofiles));
    return agentprofiles;
  };
};

export const actionLoadingAgentprofiles = () => ({
  type: AgentprofileActions.LOADING,
  payload: []
});

export const actionSetAgentprofiles = (payload: Array<IPreviewAgentprofile>) => ({
  type: AgentprofileActions.SET_STATE,
  payload: payload
});

export const actionAddAgentprofile = (payload: IPreviewAgentprofile) => ({
  type: AgentprofileActions.ADD_STATE,
  payload: payload
});

export const actionUpdateAgentprofile = (payload: IPreviewAgentprofile) => ({
  type: AgentprofileActions.UPDATE_STATE,
  payload: payload
});
