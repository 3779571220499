import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateStatsWidget, ISharedUpdateStatsWidget, ISharedStatsWidget, IPreviewOrganization, IPreviewStatsCustomMetric, IPreviewUser, IPreviewQueue, IPreviewDisposition, IPreviewTeam, IPreviewMailAccount, IPreviewVoiceRoute, IPreviewAgentprofile, EnumOptions, StatsWidgetTypeEnum, StatsWidgetPeriodEnum, StatsWidgetTrendsAdjustUnitEnum, StatsWidgetTableTrendIndicatorEnum, DirectionEnum, StatsWidgetMetricFormatEnum, FontStyleEnum, AlignmentEnum, StatsWidgetThresholdComparisonEnum, ConditionAndOr, StatsWidgetFilterMetricComparisonEnum, StatsWidgetLegendPlacementEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IStatsWidgetFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  stats_custom_metrics: Array<IPreviewStatsCustomMetric>;
  users: Array<IPreviewUser>;
  queues: Array<IPreviewQueue>;
  dispositions: Array<IPreviewDisposition>;
  teams: Array<IPreviewTeam>;
  mail_accounts: Array<IPreviewMailAccount>;
  voice_routes: Array<IPreviewVoiceRoute>;
  agentprofiles: Array<IPreviewAgentprofile>;
}

export const StatsWidgetFormOptions = (stats_widget: ISharedCreateStatsWidget | ISharedStatsWidget, props: IStatsWidgetFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.StatsWidget, {})), 'organization_id', false), 'organization'),
  settings_metrics_stats_custom_metric_id: (it: ISharedStatsWidget) => filterRelated<IPreviewStatsCustomMetric>(it.organization_id || stats_widget.organization_id, 'stats_custom_metric', 'stats_widget', props.stats_custom_metrics, true),
  settings_metrics_filter_organization_ids: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  settings_metrics_filter_conversation_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_metrics_filter_thread_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_metrics_filter_message_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_metrics_filter_conversation_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_metrics_filter_thread_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_metrics_filter_message_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_metrics_filter_disposition_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewDisposition>(it.organization_id || stats_widget.organization_id, 'disposition', 'stats_widget', props.dispositions, true, it => !it.global),
  settings_metrics_filter_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_metrics_filter_thread_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_metrics_filter_message_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_metrics_filter_mail_account_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewMailAccount>(it.organization_id || stats_widget.organization_id, 'mail_account', 'stats_widget', props.mail_accounts, true),
  settings_metrics_filter_voice_route_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewVoiceRoute>(it.organization_id || stats_widget.organization_id, 'voice_route', 'stats_widget', props.voice_routes, true),
  settings_nested_metrics_metrics_stats_custom_metric_id: (it: ISharedStatsWidget) => filterRelated<IPreviewStatsCustomMetric>(it.organization_id || stats_widget.organization_id, 'stats_custom_metric', 'stats_widget', props.stats_custom_metrics, true),
  settings_nested_metrics_metrics_filter_organization_ids: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  settings_nested_metrics_metrics_filter_conversation_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_nested_metrics_metrics_filter_thread_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_nested_metrics_metrics_filter_message_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_nested_metrics_metrics_filter_conversation_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_nested_metrics_metrics_filter_thread_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_nested_metrics_metrics_filter_message_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_nested_metrics_metrics_filter_disposition_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewDisposition>(it.organization_id || stats_widget.organization_id, 'disposition', 'stats_widget', props.dispositions, true, it => !it.global),
  settings_nested_metrics_metrics_filter_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_nested_metrics_metrics_filter_thread_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_nested_metrics_metrics_filter_message_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_nested_metrics_metrics_filter_mail_account_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewMailAccount>(it.organization_id || stats_widget.organization_id, 'mail_account', 'stats_widget', props.mail_accounts, true),
  settings_nested_metrics_metrics_filter_voice_route_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewVoiceRoute>(it.organization_id || stats_widget.organization_id, 'voice_route', 'stats_widget', props.voice_routes, true),
  settings_filter_organization_ids: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  settings_filter_conversation_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_filter_thread_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_filter_message_user_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  settings_filter_conversation_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_filter_thread_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_filter_message_queue_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true),
  settings_filter_disposition_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewDisposition>(it.organization_id || stats_widget.organization_id, 'disposition', 'stats_widget', props.dispositions, true, it => !it.global),
  settings_filter_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_filter_thread_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_filter_message_team_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  settings_filter_mail_account_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewMailAccount>(it.organization_id || stats_widget.organization_id, 'mail_account', 'stats_widget', props.mail_accounts, true),
  settings_filter_voice_route_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewVoiceRoute>(it.organization_id || stats_widget.organization_id, 'voice_route', 'stats_widget', props.voice_routes, true),
  shared_with_users: (it: ISharedStatsWidget) => filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true),
  shared_with_teams: (it: ISharedStatsWidget) => filterRelated<IPreviewTeam>(it.organization_id || stats_widget.organization_id, 'team_reverse', 'stats_widget', props.teams, true),
  shared_with_agentprofiles: (it: ISharedStatsWidget) => filterRelated<IPreviewAgentprofile>(it.organization_id || stats_widget.organization_id, 'agentprofile', 'stats_widget', props.agentprofiles, true),
  type: EnumOptions('STATS_WIDGET_TYPE', StatsWidgetTypeEnum, t),
  settings_period_type: EnumOptions('STATS_WIDGET_PERIOD', StatsWidgetPeriodEnum, t),
  settings_trend_adjust_unit: EnumOptions('STATS_WIDGET_TRENDS_ADJUST_UNIT', StatsWidgetTrendsAdjustUnitEnum, t),
  settings_trend_text_indicator: EnumOptions('STATS_WIDGET_TABLE_TREND_INDICATOR', StatsWidgetTableTrendIndicatorEnum, t),
  settings_metrics_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_metrics_thread_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_metrics_message_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_metrics_format: EnumOptions('STATS_WIDGET_METRIC_FORMAT', StatsWidgetMetricFormatEnum, t),
  settings_metrics_font_style: EnumOptions('FONT_STYLE', FontStyleEnum, t),
  settings_metrics_alignment: EnumOptions('ALIGNMENT', AlignmentEnum, t),
  settings_metrics_thresholds_comparison: EnumOptions('STATS_WIDGET_THRESHOLD_COMPARISON', StatsWidgetThresholdComparisonEnum, t),
  settings_nested_metrics_metrics_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_nested_metrics_metrics_thread_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_nested_metrics_metrics_message_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  settings_nested_metrics_metrics_format: EnumOptions('STATS_WIDGET_METRIC_FORMAT', StatsWidgetMetricFormatEnum, t),
  settings_nested_metrics_metrics_font_style: EnumOptions('FONT_STYLE', FontStyleEnum, t),
  settings_nested_metrics_metrics_alignment: EnumOptions('ALIGNMENT', AlignmentEnum, t),
  settings_nested_metrics_metrics_thresholds_comparison: EnumOptions('STATS_WIDGET_THRESHOLD_COMPARISON', StatsWidgetThresholdComparisonEnum, t),
  settings_group_by_format: EnumOptions('STATS_WIDGET_METRIC_FORMAT', StatsWidgetMetricFormatEnum, t),
  settings_group_by_font_style: EnumOptions('FONT_STYLE', FontStyleEnum, t),
  settings_group_by_alignment: EnumOptions('ALIGNMENT', AlignmentEnum, t),
  settings_group_by_thresholds_comparison: EnumOptions('STATS_WIDGET_THRESHOLD_COMPARISON', StatsWidgetThresholdComparisonEnum, t),
  settings_sub_group_by_format: EnumOptions('STATS_WIDGET_METRIC_FORMAT', StatsWidgetMetricFormatEnum, t),
  settings_sub_group_by_font_style: EnumOptions('FONT_STYLE', FontStyleEnum, t),
  settings_sub_group_by_alignment: EnumOptions('ALIGNMENT', AlignmentEnum, t),
  settings_sub_group_by_thresholds_comparison: EnumOptions('STATS_WIDGET_THRESHOLD_COMPARISON', StatsWidgetThresholdComparisonEnum, t),
  settings_split_by_format: EnumOptions('STATS_WIDGET_METRIC_FORMAT', StatsWidgetMetricFormatEnum, t),
  settings_split_by_font_style: EnumOptions('FONT_STYLE', FontStyleEnum, t),
  settings_split_by_alignment: EnumOptions('ALIGNMENT', AlignmentEnum, t),
  settings_split_by_thresholds_comparison: EnumOptions('STATS_WIDGET_THRESHOLD_COMPARISON', StatsWidgetThresholdComparisonEnum, t),
  settings_filter_row_type: EnumOptions('CONDITION_AND_OR', ConditionAndOr, t),
  settings_filter_row_rules_comparison: EnumOptions('STATS_WIDGET_FILTER_METRIC_COMPARISON', StatsWidgetFilterMetricComparisonEnum, t),
  settings_ui_legend_placement: EnumOptions('STATS_WIDGET_LEGEND_PLACEMENT', StatsWidgetLegendPlacementEnum, t),
});

export const StatsWidgetComponents: IForm['components'] = {};
export const StatsWidgetAdditionalParams: (auth: IAuth, props: IStatsWidgetFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsWidgetFormOptionsProps) => ({
  settings_filter_disposition_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_disposition_ids-add',
      path: '@Components/page-views/disposition',
      component: 'DispositionFormAddView',
      item_text: 'DISPOSITION'
    }
  }, 
  settings_filter_team_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM'
    }
  }, 
  settings_filter_thread_team_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_thread_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM'
    }
  }, 
  settings_filter_message_team_ids: {
    onModalAdd: {
      key: 'queue-settings_filter_message_team_ids-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM'
    }
  }, 
  shared_with_teams: {
    onModalAdd: {
      key: 'queue-shared_with_teams-add',
      path: '@Components/page-views/team',
      component: 'TeamFormAddView',
      item_text: 'TEAM'
    }
  }
});

export const StatsWidgetEditForm = (stats_widget: ISharedStatsWidget, props: IStatsWidgetFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `stats_widget_edit_${stats_widget.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === stats_widget.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'type' },
    { ref: 'settings' },
    { ref: 'shared' }
  ]
});

export const StatsWidgetAddForm = (stats_widget: ISharedCreateStatsWidget, props: IStatsWidgetFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'stats_widget_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'type' },
    { ref: 'settings' },
    { ref: 'shared' }
  ]
});

export const StatsWidgetAddInitialValues: ISharedCreateStatsWidget = {} as ISharedCreateStatsWidget;

export const StatsWidgetEditInitialValues = (stats_widget: ISharedStatsWidget): ISharedUpdateStatsWidget => ({
  title: stats_widget.title,
  type: stats_widget.type,
  settings: stats_widget.settings,
  shared: stats_widget.shared
});

export const onStatsWidgetFormSaved = (t: TFunction, action: 'edit' | 'add', stats_widget: ISharedStatsWidget, navigate: NavigateFunction): void => {
  navigate(onFormSaved('stats_widgets'));
};
