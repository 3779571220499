import { ValidationErrorItem } from 'joi';
import { Undefinable } from 'atlas-shared';
import { IFormOptions } from '../atlas-form-core/Interfaces';
import { TFunction } from 'i18next';

export const buildFormErrorKey = (error: ValidationErrorItem, t: TFunction, options?: IFormOptions): string => {
  let key = error.type.replaceAll('.', '__').toUpperCase();
  const params: Record<string, any> = {};

  if (options && ['NUMBER__BASE', 'STRING__BASE'].includes(key))
    key = 'VALID_OPTION';

  if ((error.path.at(-1) + '').endsWith('_id'))
    key = 'REF_NOT_FOUND';

  if (['STRING__MAX', 'NUMBER__MAX', 'ARRAY__MAX'].includes(key))
    params.max = error.context?.limit || 0;
  else if (['STRING__MIN', 'NUMBER__MIN', 'ARRAY__MIN'].includes(key))
    params.min = error.context?.limit || 0;
  else if (['STRING__LENGTH', 'ARRAY__LENGTH'].includes(key))
    params.lngth = error.context?.limit || 0;
  else if (key === 'STRING__PATTERN__BASE') {
    if (error.context?.regex.toString() === '/^[-.a-z0-9]+$/')
      key = 'USERNAME_INVALID_CHARS';
    else
      params.pattern = error.context?.regex.toString();
  }

  if (key.endsWith('EMPTY') && (error.path[0] + '').includes('phone'))
    key = 'INVALID_PHONE';

  return t('FORM_ERROR_' + key, params);
};

export const buildFormFieldTitleKey = (path: Undefinable<string | Array<string | number>>): string => {
  if (!path)
    return '';

  if (!Array.isArray(path))
    path = [path];

  return path.filter((p: string | number) => isNaN(+p)).join('__').toUpperCase();
};
