import React from 'react';
import { deleteOperationalHours } from '@Api';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewOperationalHours, PermissionEnum, TOperationalHoursSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const OperationalHoursListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewOperationalHours) => React.ReactElement> = [], filters: IWithListProps<IPreviewOperationalHours, TOperationalHoursSortableListCol>['filters'], sorter: IWithListProps<IPreviewOperationalHours, TOperationalHoursSortableListCol>['sorter']): ColumnsType<IPreviewOperationalHours> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewOperationalHours['organization_id'], operational_hours: IPreviewOperationalHours) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id } }),
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('TIMEZONE'),
      dataIndex: 'timezone',
      key: 'timezone',
      render: (timezone: IPreviewOperationalHours['timezone'], operational_hours: IPreviewOperationalHours) => <div className='number-column'>{timezone}</div>,
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => a.timezone - b.timezone,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'timezone')?.order || null,
      filteredValue: filters.timezone || null,
      ...GetColumnSearchEqualProps(t, 'timezone', t('TIMEZONE')),
      className: 'table-cell-timezone',
    },
    {
      title: t('ASTERISK_HOURS'),
      dataIndex: 'asterisk_hours',
      key: 'asterisk_hours',
      render: (asterisk_hours: IPreviewOperationalHours['asterisk_hours'], operational_hours: IPreviewOperationalHours) => tableColumnBooleanRender(t, asterisk_hours),
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => (a.asterisk_hours ? 1 : 0) > (b.asterisk_hours ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'asterisk_hours')?.order || null,
      className: 'table-cell-asterisk_hours',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewOperationalHours['created_at'], operational_hours: IPreviewOperationalHours) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewOperationalHours['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewOperationalHours, b: IPreviewOperationalHours) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewOperationalHours) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Insert, PermissionEnum.OperationalHours, row) && listCloneButton(t, navigate, 'operational_hours', row)}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'operational_hours', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.OperationalHours, row) && listEditButton(t, navigate, 'operational_hours', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.OperationalHours, row) && listDeleteButton(t, row, deleteOperationalHours)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewOperationalHours>;

};
