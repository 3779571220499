import React from 'react';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewInvoice, PermissionEnum, EnumTranslateTitle, TInvoiceSortableListCol } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listEditButton, GetColumnSearchProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const InvoiceListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewInvoice) => React.ReactElement> = [], filters: IWithListProps<IPreviewInvoice, TInvoiceSortableListCol>['filters'], sorter: IWithListProps<IPreviewInvoice, TInvoiceSortableListCol>['sorter']): ColumnsType<IPreviewInvoice> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewInvoice['organization_id'], invoice: IPreviewInvoice) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id } }),
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      className: 'table-cell-title',
    },
    {
      title: t('AMOUNT'),
      dataIndex: 'amount',
      key: 'amount',
      className: 'table-cell-amount',
    },
    {
      title: t('CURRENCY'),
      dataIndex: 'currency',
      key: 'currency',
      render: (currency: IPreviewInvoice['currency'], invoice: IPreviewInvoice) => EnumTranslateTitle('INVOICE_CURRENCY', currency, t),
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => EnumTranslateTitle('INVOICE_CURRENCY', a.currency, t).localeCompare(EnumTranslateTitle('INVOICE_CURRENCY', b.currency, t)),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'currency')?.order || null,
      className: 'table-cell-currency',
    },
    {
      title: t('LOCKED_AT'),
      dataIndex: 'locked_at',
      key: 'locked_at',
      render: (locked_at: IPreviewInvoice['locked_at'], invoice: IPreviewInvoice) => <div className='date-column'>{locked_at ? timeSince(t, new Date(locked_at), true) : ''}</div>,
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => tableColumnDatetimeSorter(a.locked_at, b.locked_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'locked_at')?.order || null,
      className: 'table-cell-locked_at',
    },
    {
      title: t('PAID_AT'),
      dataIndex: 'paid_at',
      key: 'paid_at',
      render: (paid_at: IPreviewInvoice['paid_at'], invoice: IPreviewInvoice) => <div className='date-column'>{paid_at ? timeSince(t, new Date(paid_at), true) : ''}</div>,
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => tableColumnDatetimeSorter(a.paid_at, b.paid_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'paid_at')?.order || null,
      className: 'table-cell-paid_at',
    },
    {
      title: t('DUE_DATE'),
      dataIndex: 'due_date',
      key: 'due_date',
      render: (due_date: IPreviewInvoice['due_date'], invoice: IPreviewInvoice) => <div className='date-column'>{due_date ? timeSince(t, new Date(due_date), true) : ''}</div>,
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => tableColumnDatetimeSorter(a.due_date, b.due_date),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'due_date')?.order || null,
      className: 'table-cell-due_date',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewInvoice['created_at'], invoice: IPreviewInvoice) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewInvoice, b: IPreviewInvoice) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewInvoice) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'invoice', row)}
          {abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.Invoice, row) && listEditButton(t, navigate, 'invoice', row)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewInvoice>;

};
