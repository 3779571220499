import { RestRequest } from '@Utils';
import { IPreviewUser, ISharedUser, ISharedCreateUser, ISharedPatchUser } from 'atlas-shared';

export const fetchUsers = async (): Promise<Array<IPreviewUser>> => {
  return RestRequest.get<Array<IPreviewUser>>('user', { socket_id: global.socketId });
};

export const fetchUser = async (id: IPreviewUser['id']): Promise<ISharedUser> => {
  return RestRequest.get<ISharedUser>(`user/${id}`, { socket_id: global.socketId });
};

export const createUser = (payload: ISharedCreateUser) => {
  return RestRequest.post<any, ISharedUser>('user', payload, { socket_id: global.socketId });
};

export const patchUser = (id: IPreviewUser['id'], payload: ISharedPatchUser) => {
  return RestRequest.patch<any, ISharedUser>(`user/${id}`, payload, { socket_id: global.socketId });
};

export const deleteUser = (id: IPreviewUser['id']) => {
  return RestRequest.delete(`user/${id}`, { socket_id: global.socketId });
};

export const getUserUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('user/update/schema');
};

export const getUserCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('user/create/schema');
};

export const getUserPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('user/patch/schema');
};
