import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore, IUserStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';

import { ApiKeyListColumns as ApiKeyListColumnsBase } from './abstract/api_key.list.column.abstract';
import { IPreviewApiKey, AdminprofilePermissionIdEnum, CrudEnum, PermissionEnum, TApiKeySortableListCol } from 'atlas-shared';
import { Icon } from '@Components';
import { CopyIcon } from '@Assets';
import { fetchApiKey } from '@Api';

export const ApiKeyListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewApiKey) => React.ReactElement> = [], filters: IWithListProps<IPreviewApiKey, TApiKeySortableListCol>['filters'], sorter: IWithListProps<IPreviewApiKey, TApiKeySortableListCol>['sorter']): ColumnsType<IPreviewApiKey> => {
  const cols = ApiKeyListColumnsBase(t, navigate, showDeleted, organizations, users, abilities, auth, [
    (row: IPreviewApiKey) => abilities.can(null, CrudEnum.Update, AdminprofilePermissionIdEnum.ApiKey as unknown as PermissionEnum, row) ? <Icon icon={CopyIcon} tooltip={{ title: t('COPY_API_KEY_TO_CLIPBOARD') }} iconProps={{ size: 14 }} onClick={() => {
      fetchApiKey(row.id).then(api_key => navigator.clipboard.writeText(api_key.api_key));
    }} /> : <></>,
    ...buttons
  ], filters, sorter);

  cols[4].title = t('CREATED_BY');

  return cols;
};
