import { VoiceChanspyActions } from '@Store';
import { IPreviewVoiceChanspy } from 'atlas-shared';
import { fetchVoiceChanspies } from '@Api';

export const actionFetchVoiceChanspies = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceChanspies());
    const voice_chanspies = await fetchVoiceChanspies();

    dispatch(actionSetVoiceChanspies(voice_chanspies));
    return voice_chanspies;
  };
};

export const actionLoadingVoiceChanspies = () => ({
  type: VoiceChanspyActions.LOADING,
  payload: []
});

export const actionSetVoiceChanspies = (payload: Array<IPreviewVoiceChanspy>) => ({
  type: VoiceChanspyActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceChanspy = (payload: IPreviewVoiceChanspy) => ({
  type: VoiceChanspyActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceChanspy = (payload: IPreviewVoiceChanspy) => ({
  type: VoiceChanspyActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveVoiceChanspy = (id: IPreviewVoiceChanspy['id']) => ({
  type: VoiceChanspyActions.REMOVE_STATE,
  payload: id
});
