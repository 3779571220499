import { StatsCustomMetricActions } from '@Store';
import { IPreviewStatsCustomMetric } from 'atlas-shared';
import { fetchStatsCustomMetrics } from '@Api';

export const actionFetchStatsCustomMetrics = () => {
  return async (dispatch) => {
    dispatch(actionLoadingStatsCustomMetrics());
    const stats_custom_metrics = await fetchStatsCustomMetrics();

    dispatch(actionSetStatsCustomMetrics(stats_custom_metrics));
    return stats_custom_metrics;
  };
};

export const actionLoadingStatsCustomMetrics = () => ({
  type: StatsCustomMetricActions.LOADING,
  payload: []
});

export const actionSetStatsCustomMetrics = (payload: Array<IPreviewStatsCustomMetric>) => ({
  type: StatsCustomMetricActions.SET_STATE,
  payload: payload
});

export const actionAddStatsCustomMetric = (payload: IPreviewStatsCustomMetric) => ({
  type: StatsCustomMetricActions.ADD_STATE,
  payload: payload
});

export const actionUpdateStatsCustomMetric = (payload: IPreviewStatsCustomMetric) => ({
  type: StatsCustomMetricActions.UPDATE_STATE,
  payload: payload
});
