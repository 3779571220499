import React from 'react';
import { IPreviewVoiceShortcut } from 'atlas-shared';
import { TranslateAndDisplayPhoneNumber } from '@Utils';
import { PhoneOutgoingIcon } from '@Assets/icons';
import { IconText } from '@Components';
import { TVoiceSuggestionOnSelect } from './voice-suggestion.utils';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
  voice_shortcut: IPreviewVoiceShortcut;
  onSelect: TVoiceSuggestionOnSelect;
  callBtn: React.ReactNode;
}
export const VoiceSuggestionVoiceShortcut = React.memo(({ t, voice_shortcut, callBtn, onSelect }: IProps) => {
  const { number, title } = voice_shortcut;

  return <div className={'li-content clickable'} onClick={() => {
    onSelect([{
      title,
      voice_asterisk_id: -1,
      numbers: [number],
      requires_route: true
    }], true);
  }}>
    <IconText
      icon={PhoneOutgoingIcon}
      text={<span>{title}<TranslateAndDisplayPhoneNumber t={t} phone_number={number} /></span>}
    />
    {callBtn}
  </div>;
});
