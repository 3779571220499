import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import '../conversation.merge.scss';
import { IPreviewAgentprofile, IPreviewContact, ISharedFullConversation } from 'atlas-shared';
import { useTranslation } from 'react-i18next';
import { entitiesToOptions, IOption } from '@Utils';
import { searchContact, mergeContacts } from '@Api';
import { FormSelect } from 'atlas-form';
import { useAgentprofiles, useAuth } from '@Hooks';

interface IProps {
  conversation: ISharedFullConversation;
  children?: any;
}

export const ConversationContactMerge = ({ conversation, children }: IProps) => {
  const { t } = useTranslation();
  const [primaryId, setPrimaryId] = useState<IPreviewContact['id']>();
  const auth = useAuth();
  const [agentprofile, setAgentprofile] = useState<IPreviewAgentprofile>();
  const agentprofiles = useAgentprofiles();

  useEffect(() => {
    if (auth.user_status.agentprofile_id)
      setAgentprofile(agentprofiles.dict[auth.user_status.agentprofile_id]);
  }, [auth.user_status.agentprofile_id, agentprofiles.dict]);

  return (
    <Popover
      className={'merge-popover-container'}
      placement='bottom'
      overlayStyle={{ width: 320 }}
      content={
        <div className={'merge-popover-content'}>
          <div className={'popover-header'}>
            <span className='popover-title' style={{ opacity: .5, textTransform: 'uppercase' }}>{t('MERGE_CONTACT')}</span>
            <span className='popover-title' style={{ opacity: .5 }}>{t('CONTACT_MERGE_EXPLANATION')}</span>
            <span className='popover-title'>{t('CONTACT')}</span>
          </div>
          <div className={'popover-content'}>
            <FormSelect
              onChange={id => setPrimaryId(id)}
              onSearch={async (w: string) => entitiesToOptions((await searchContact(w, 10, [conversation.contact.id], auth.user_status.agentprofile_id, agentprofile?.organization_id)).items.map(({ item }) => item), 'contact_primary') as Array<IOption>}
              placeholder={t('SEARCH_CONTACT')}
              fixedDropdown={true}
              dropdownClassName='contact-select'
              className='contact-selector'
            />
          </div>
          {primaryId && <div className={'done-btn'} onClick={e => mergeContacts(conversation.contact.organization_id, conversation.contact.id, primaryId)}>{t('MERGE')}</div>}
        </div>
      } trigger='click'>
      {children}
    </Popover>
  );
};
