import { ChannelIcon, Icon, IconText, Timer } from '@Components';
import {
  IPreviewVoiceChanspy,
  IPreviewVoiceInternalCall, Undefinable,
  VoiceCallTableEnum
} from 'atlas-shared/dist';
import { ClockIcon, HangupIcon } from '@Assets';
import React, { useMemo } from 'react';
import { useUsers } from '@Hooks';
import { getFullName } from '@Utils';
import { TFunction } from 'i18next';
import { IVoiceSessionObject, SipUser, sipUsersWrapper } from 'SIP';
import { hangupVoiceChanspy, hangupVoiceInternalCall } from '@Api';
import { IAuth } from '@Store';

interface IProps {
  t: TFunction;
  auth: IAuth;
  type: VoiceCallTableEnum;
  call: IPreviewVoiceInternalCall | IPreviewVoiceChanspy;
  sipUsersWrapper: typeof sipUsersWrapper;
}

export const VoiceInternal = ({ t, auth, type, call, sipUsersWrapper }: IProps) => {
  const sip_user: Undefinable<SipUser> = useMemo(() => sipUsersWrapper?.getSipUser(call.voice_asterisk_id)?.getSipUser(), [sipUsersWrapper]);
  const users = useUsers();
  const caller = useMemo(() => users.dict[(call as IPreviewVoiceInternalCall).user_id!], [users, (call as IPreviewVoiceInternalCall).callee_user_id, (call as IPreviewVoiceChanspy).spyee_user_id]);
  const callee = useMemo(() => users.dict[(call as IPreviewVoiceInternalCall).callee_user_id || (call as IPreviewVoiceChanspy).spyee_user_id], [users, (call as IPreviewVoiceInternalCall).callee_user_id, (call as IPreviewVoiceChanspy).spyee_user_id]);
  const timeStart = useMemo(() => type === VoiceCallTableEnum.VoiceChanSpy ? call.start_time : (call as IPreviewVoiceInternalCall).answer_time, [call]);

  return <div className={`voice-internal voice-internal-${type.toLowerCase()}`}>
    <ChannelIcon channel={{ c: type === VoiceCallTableEnum.VoiceInternalCall ? 'VoiceInternal' : 'ChanSpy' }} size={14} padding={6} />
    <div className='callerid'>{getFullName(callee.id !== auth.user.id ? callee : caller)}</div>
    <div className='timer'>
      <IconText icon={ClockIcon} text={timeStart ? <Timer base={timeStart} format="HH:mm:ss" default_value='00:00' ignoreZeros={true}/> : t('RINGING')} />
    </div>
    <div className='hangup'>
      <Icon
        icon={HangupIcon}
        iconProps={{ size: 20, style: { strokeWidth: 2 } }}
        onClick={() => {
          const voice_session: Undefinable<IVoiceSessionObject> = sip_user?.getSessionBySharedVoiceInternalCall(call);

          if (voice_session)
            sip_user?.hangup(voice_session); 
          else
            (type === VoiceCallTableEnum.VoiceChanSpy ? hangupVoiceChanspy(call.id) : hangupVoiceInternalCall(call.id));
        }}
      />
    </div>
  </div>;
};
