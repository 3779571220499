import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  StatsCustomMetricEditForm,
  StatsCustomMetricEditInitialValues,
  StatsCustomMetricComponents,
  StatsCustomMetricAdditionalParams,
  StatsCustomMetricFormOptions,
  onStatsCustomMetricFormSaved,
  IStatsCustomMetricFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedStatsCustomMetric, ISharedPatchStatsCustomMetric, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchStatsCustomMetric, patchStatsCustomMetric, getStatsCustomMetricUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useStatsCustomMetrics } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedStatsCustomMetric> {}

const StatsCustomMetricFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const stats_custom_metrics = useStatsCustomMetrics();

  useEffect(() => {
    fetchStatsCustomMetric(id).then(stats_custom_metric => setValue(stats_custom_metric));
  }, [id, setValue]);

  const param_props: IStatsCustomMetricFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    stats_custom_metrics: stats_custom_metrics.stats_custom_metrics
  };
  const formLayout: IForm | undefined = useMemo(() => value && StatsCustomMetricEditForm(value, param_props, t), [value, organizations, stats_custom_metrics, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && StatsCustomMetricFormOptions(value, param_props, t), [value, organizations, stats_custom_metrics, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchStatsCustomMetric, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchStatsCustomMetric) => value && save(() => patchStatsCustomMetric(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onStatsCustomMetricFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !stats_custom_metrics.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.StatsCustomMetric, value)}
    >
      <AtlasForm<ISharedPatchStatsCustomMetric>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={StatsCustomMetricComponents}
        additionalParams={StatsCustomMetricAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={StatsCustomMetricEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('STATS_CUSTOM_METRIC')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const StatsCustomMetricFormEditView = React.memo(withEdit(StatsCustomMetricFormEdit, getStatsCustomMetricUpdateSchema));
