import { IPreviewSlaPolicy } from 'atlas-shared';

export enum SlaPolicyActions {
  SET_STATE = 'SLA_POLICY_SET_STATE',
  ADD_STATE = 'SLA_POLICY_ADD_STATE',
  REMOVE_STATE = 'SLA_POLICY_REMOVE_STATE',
  GET_STATE = 'SLA_POLICY_GET_STATE',
  UPDATE_STATE = 'SLA_POLICY_UPDATE_STATE',
  LOADING = 'SLA_POLICY_LOADING',
}

export interface ISlaPolicyLoading {
  type: SlaPolicyActions.LOADING;
}

export interface ISlaPolicySetAction {
  type: SlaPolicyActions.SET_STATE;
  payload: Array<IPreviewSlaPolicy>;
}

export interface ISlaPolicyUpdateAction {
  type: SlaPolicyActions.UPDATE_STATE;
  payload: IPreviewSlaPolicy;
}

export interface ISlaPolicyAddAction {
  type: SlaPolicyActions.ADD_STATE;
  payload: IPreviewSlaPolicy;
}

export interface ISlaPolicyRemoveAction {
  type: SlaPolicyActions.REMOVE_STATE;
  payload: IPreviewSlaPolicy['id'];
}

export interface ISlaPolicyStore {
  loaded: boolean;
  loading: boolean;
  sla_policies: Array<IPreviewSlaPolicy>;
  dict: Record<IPreviewSlaPolicy['id'], IPreviewSlaPolicy>;
}

export type TSlaPolicyActionTypes = ISlaPolicySetAction | ISlaPolicyUpdateAction | ISlaPolicyAddAction | ISlaPolicyRemoveAction | ISlaPolicyLoading;
