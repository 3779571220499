import { RestRequest } from '@Utils';
import { IPreviewSlaPolicy, ISharedSlaPolicy, ISharedCreateSlaPolicy, ISharedPatchSlaPolicy } from 'atlas-shared';

export const fetchSlaPolicies = async (): Promise<Array<IPreviewSlaPolicy>> => {
  return RestRequest.get<Array<IPreviewSlaPolicy>>('sla_policy', { socket_id: global.socketId });
};

export const fetchSlaPolicy = async (id: IPreviewSlaPolicy['id']): Promise<ISharedSlaPolicy> => {
  return RestRequest.get<ISharedSlaPolicy>(`sla_policy/${id}`, { socket_id: global.socketId });
};

export const createSlaPolicy = (payload: ISharedCreateSlaPolicy) => {
  return RestRequest.post<any, ISharedSlaPolicy>('sla_policy', payload, { socket_id: global.socketId });
};

export const patchSlaPolicy = (id: IPreviewSlaPolicy['id'], payload: ISharedPatchSlaPolicy) => {
  return RestRequest.patch<any, ISharedSlaPolicy>(`sla_policy/${id}`, payload, { socket_id: global.socketId });
};

export const deleteSlaPolicy = (id: IPreviewSlaPolicy['id']) => {
  return RestRequest.delete(`sla_policy/${id}`, { socket_id: global.socketId });
};

export const getSlaPolicyUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('sla_policy/update/schema');
};

export const getSlaPolicyCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('sla_policy/create/schema');
};

export const getSlaPolicyPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('sla_policy/patch/schema');
};
