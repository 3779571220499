import { FieldActions } from '@Store';
import { IPreviewField } from 'atlas-shared';
import { fetchFields } from '@Api';

export const actionFetchFields = () => {
  return async (dispatch) => {
    dispatch(actionLoadingFields());
    const fields = await fetchFields();

    dispatch(actionSetFields(fields));
    return fields;
  };
};

export const actionLoadingFields = () => ({
  type: FieldActions.LOADING,
  payload: []
});

export const actionSetFields = (payload: Array<IPreviewField>) => ({
  type: FieldActions.SET_STATE,
  payload: payload
});

export const actionAddField = (payload: IPreviewField) => ({
  type: FieldActions.ADD_STATE,
  payload: payload
});

export const actionUpdateField = (payload: IPreviewField) => ({
  type: FieldActions.UPDATE_STATE,
  payload: payload
});
