import {
  IPreviewStatsWidget,
  IPreviewStatsWidgetSettingsGroupByProperty,
  IStatsWidgetDataTableMinifiedRow, IStatsWidgetDataTimeline,
  StatsWidgetMetricFormatEnum,
  StatsWidgetTypeEnum, UserStatusStatusEnum
} from 'atlas-shared/dist';
import moment from 'moment-timezone';

export const StatsWidgetPreviewData = (stats_widget: IPreviewStatsWidget): IPreviewStatsWidget['data'] => {
  
  if (stats_widget.type === StatsWidgetTypeEnum.Timeline) {
    const DEFAULT_ALIAS = {
      login_activity: UserStatusStatusEnum.Online,
      login_activity_only: UserStatusStatusEnum.Online,
      idle_activity: UserStatusStatusEnum.Idle,
      idle_activity_only: UserStatusStatusEnum.Idle,
      handling_activity: UserStatusStatusEnum.HandlingConversation,
      handling_activity_only: UserStatusStatusEnum.HandlingConversation,
      pause_activity: UserStatusStatusEnum.Paused,
      pause_activity_only: UserStatusStatusEnum.Paused,
      busy_activity: UserStatusStatusEnum.Busy,
      busy_activity_only: UserStatusStatusEnum.Busy,
    };
    const momentStart = moment().startOf('day').hour(8);
    const events = new Array(20).fill(null).map((_, i) => ({
      i: i + 1,
      s: momentStart.clone().add(i, 'hour').minute(5).format('YYYY-MM-DD HH:mm:ss'),
      e: momentStart.clone().add(i, 'hour').minute(50).format('YYYY-MM-DD HH:mm:ss'),
      t: DEFAULT_ALIAS[stats_widget.settings.metrics[0].alias as string]
    }));
    const data: IStatsWidgetDataTimeline = {
      d: {
        1: events,
        2: events,
        3: events,
        4: events,
      },
      s: momentStart.format('YYYY-MM-DD HH:mm:ss'),
      e: events.at(-1)!.e
    };

    return data as any;
  }

  const group_row = () => ({
    ...generateSampleRow(stats_widget.settings.group_by, stats_widget),
    'c': stats_widget.settings.sub_group_by?.length ? {
      'g': 'sub_group',
      'r': [
        generateSampleRow(stats_widget.settings.sub_group_by, stats_widget),
        generateSampleRow(stats_widget.settings.sub_group_by, stats_widget),
        generateSampleRow(stats_widget.settings.sub_group_by, stats_widget)
      ]
    } : undefined,
  });
  
  const summary_row = {
    ...generateSampleRow([], stats_widget),
    c: {
      g: 'group',
      r: new Array(stats_widget.type === StatsWidgetTypeEnum.Bar ? 3 : 12).fill(0).map(() => group_row())
    }
  };
  
  if (!stats_widget.settings.summary)
    return summary_row.c;

  return {
    'g': 'summary',
    'r': [
      summary_row
    ],

  } as any;
};

const generateSampleRow = (groups: Array<IPreviewStatsWidgetSettingsGroupByProperty> = [], stats_widget: IPreviewStatsWidget): IStatsWidgetDataTableMinifiedRow => {
  const metrics = [...(stats_widget.settings.metrics || []), ...(stats_widget.settings.nested_metrics || []).map(m => m.metrics)].flat();

  return {
    'd': [
      ...(groups || []).map(m => '0'),
      ...metrics.map(m => m.format === StatsWidgetMetricFormatEnum.VALUE_PERCENT ? randomizeMetricData(m) * 10.3 / 10 : randomizeMetricData(m))
    ],
    't': [
      ...metrics.map(m => m.format === StatsWidgetMetricFormatEnum.VALUE_PERCENT ? randomizeMetricData(m) * 10.3 / 10 : randomizeMetricData(m))
    ],
    'tp': [
      '2023-02-01 00:00:00',
      '2023-01-30 00:00:00'
    ]
  };
};

const randomizeMetricData = (metric) => {
  return Math.round(Math.random() * 100);
};
