import React from 'react';
import { deletePhonenumberMonthlyCost } from '@Api';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewPhonenumberMonthlyCost, PermissionEnum, EnumTranslateTitle, TPhonenumberMonthlyCostSortableListCol } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchLikeProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const PhonenumberMonthlyCostListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewPhonenumberMonthlyCost) => React.ReactElement> = [], filters: IWithListProps<IPreviewPhonenumberMonthlyCost, TPhonenumberMonthlyCostSortableListCol>['filters'], sorter: IWithListProps<IPreviewPhonenumberMonthlyCost, TPhonenumberMonthlyCostSortableListCol>['sorter']): ColumnsType<IPreviewPhonenumberMonthlyCost> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewPhonenumberMonthlyCost, b: IPreviewPhonenumberMonthlyCost) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('CURRENCY'),
      dataIndex: 'currency',
      key: 'currency',
      render: (currency: IPreviewPhonenumberMonthlyCost['currency'], phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => EnumTranslateTitle('INVOICE_CURRENCY', currency, t),
      sorter: (a: IPreviewPhonenumberMonthlyCost, b: IPreviewPhonenumberMonthlyCost) => EnumTranslateTitle('INVOICE_CURRENCY', a.currency, t).localeCompare(EnumTranslateTitle('INVOICE_CURRENCY', b.currency, t)),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'currency')?.order || null,
      className: 'table-cell-currency',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewPhonenumberMonthlyCost, b: IPreviewPhonenumberMonthlyCost) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewPhonenumberMonthlyCost['created_at'], phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewPhonenumberMonthlyCost, b: IPreviewPhonenumberMonthlyCost) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewPhonenumberMonthlyCost) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Insert, PermissionEnum.PhonenumberMonthlyCost, row) && listCloneButton(t, navigate, 'phonenumber_monthly_cost', row)}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'phonenumber_monthly_cost', row)}
          {abilities.can(null, CrudEnum.Update, PermissionEnum.PhonenumberMonthlyCost, row) && listEditButton(t, navigate, 'phonenumber_monthly_cost', row)}
          {abilities.can(null, CrudEnum.Delete, PermissionEnum.PhonenumberMonthlyCost, row) && listDeleteButton(t, row, deletePhonenumberMonthlyCost)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewPhonenumberMonthlyCost>;

};
