import { IPreviewInvoice } from 'atlas-shared';
import { IInvoiceStore, InvoiceActions, TInvoiceActionTypes } from '@Store';

export const InvoiceReducer = (state: IInvoiceStore = {
  loaded: false,
  loading: false,
  invoices: [],
  dict: {}
}, action: TInvoiceActionTypes): IInvoiceStore => {
  switch (action.type) {
  case InvoiceActions.LOADING:
    return { ...state, loading: true };
  case InvoiceActions.SET_STATE:
    const dict: IInvoiceStore['dict'] = {};

    action.payload.forEach((invoice: IPreviewInvoice) => dict[invoice.id] = invoice);

    return { ...state, loaded: true, loading: false, invoices: action.payload, dict };
  case InvoiceActions.ADD_STATE:
    return { ...state, invoices: [...state.invoices, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case InvoiceActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, invoices: state.invoices.filter((item: IPreviewInvoice) => item.id !== action.payload), dict };
  }
  case InvoiceActions.UPDATE_STATE:
    const invoices = [...state.invoices];
    const index = state.invoices.findIndex((invoice: IPreviewInvoice) => invoice.id === action.payload.id);

    if (!~index)
      invoices.push(action.payload);
    else
      invoices[index] = action.payload;

    return { ...state, invoices, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
