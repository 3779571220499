import { NavigateFunction } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { ISharedCreateVoiceAsteriskDowngrade, ISharedUpdateVoiceAsteriskDowngrade, ISharedVoiceAsteriskDowngrade, IPreviewOrganization, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { entitiesToOptions, onFormSaved } from '@Utils';

export interface IVoiceAsteriskDowngradeFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const VoiceAsteriskDowngradeFormOptions = (voice_asterisk_downgrade: ISharedCreateVoiceAsteriskDowngrade | ISharedVoiceAsteriskDowngrade, props: IVoiceAsteriskDowngradeFormOptionsProps, t: TFunction): IFormOptions => ({
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  
});

export const VoiceAsteriskDowngradeComponents: IForm['components'] = {};
export const VoiceAsteriskDowngradeAdditionalParams: (auth: IAuth, props: IVoiceAsteriskDowngradeFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceAsteriskDowngradeFormOptionsProps) => ({
});

export const VoiceAsteriskDowngradeEditForm = (voice_asterisk_downgrade: ISharedVoiceAsteriskDowngrade, props: IVoiceAsteriskDowngradeFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_asterisk_downgrade_edit_${voice_asterisk_downgrade.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title' },
    { ref: 'host' },
    { ref: 'internal_host' },
    { ref: 'port' },
    { ref: 'tls' },
    { ref: 'from_domain' },
    { ref: 'rest' },
    { ref: 'description' }
  ]
});

export const VoiceAsteriskDowngradeAddForm = (voice_asterisk_downgrade: ISharedCreateVoiceAsteriskDowngrade, props: IVoiceAsteriskDowngradeFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'voice_asterisk_downgrade_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title' },
    { ref: 'host' },
    { ref: 'internal_host' },
    { ref: 'port' },
    { ref: 'tls' },
    { ref: 'from_domain' },
    { ref: 'rest' },
    { ref: 'description' }
  ]
});

export const VoiceAsteriskDowngradeAddInitialValues: ISharedCreateVoiceAsteriskDowngrade = {} as ISharedCreateVoiceAsteriskDowngrade;

export const VoiceAsteriskDowngradeEditInitialValues = (voice_asterisk_downgrade: ISharedVoiceAsteriskDowngrade): ISharedUpdateVoiceAsteriskDowngrade => ({
  title: voice_asterisk_downgrade.title,
  host: voice_asterisk_downgrade.host,
  internal_host: voice_asterisk_downgrade.internal_host,
  port: voice_asterisk_downgrade.port,
  tls: voice_asterisk_downgrade.tls,
  from_domain: voice_asterisk_downgrade.from_domain,
  rest: voice_asterisk_downgrade.rest,
  description: voice_asterisk_downgrade.description
});

export const onVoiceAsteriskDowngradeFormSaved = (t: TFunction, action: 'edit' | 'add', voice_asterisk_downgrade: ISharedVoiceAsteriskDowngrade, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_asterisk_downgrades'));
};
