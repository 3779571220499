import { IPreviewOrganization } from 'atlas-shared';
import { IOrganizationStore, OrganizationActions, TOrganizationActionTypes } from '@Store';

export const OrganizationReducer = (state: IOrganizationStore = {
  loaded: false,
  loading: false,
  organizations: [],
  dict: {}
}, action: TOrganizationActionTypes): IOrganizationStore => {
  switch (action.type) {
  case OrganizationActions.LOADING:
    return { ...state, loading: true };
  case OrganizationActions.SET_STATE:
    const dict: IOrganizationStore['dict'] = {};

    action.payload.forEach((organization: IPreviewOrganization) => dict[organization.id] = organization);

    return { ...state, loaded: true, loading: false, organizations: action.payload, dict };
  case OrganizationActions.ADD_STATE:
    return { ...state, organizations: [...state.organizations, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case OrganizationActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, organizations: state.organizations.filter((item: IPreviewOrganization) => item.id !== action.payload), dict };
  }
  case OrganizationActions.UPDATE_STATE:
    const organizations = [...state.organizations];
    const index = state.organizations.findIndex((organization: IPreviewOrganization) => organization.id === action.payload.id);

    if (!~index)
      organizations.push(action.payload);
    else
      organizations[index] = action.payload;

    return { ...state, organizations, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
