import { getTopBitwiseMember, IPreviewPause, Nullable, UserStatusStatusEnum } from 'atlas-shared';
import { UserStatusColors } from '@Utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePauses } from '@Hooks';
import { Tooltip } from 'antd';

interface IProps {
  status: number;
  pause_id?: Nullable<IPreviewPause['id']>;
  tooltip?: boolean;
}

export const StatusColor = React.memo(({ status: _status, pause_id, tooltip }: IProps) => {
  const { t } = useTranslation();
  const pauses = usePauses();
  const [status, setStatus] = useState<UserStatusStatusEnum>();
  const [pause, setPause] = useState<IPreviewPause>();
  const [statusTitle, setStatusTitle] = useState<string>(t(`STATUS_${status}`));

  useEffect(() => {
    setStatus(getTopBitwiseMember<UserStatusStatusEnum>(UserStatusStatusEnum, _status || UserStatusStatusEnum.Offline));
  }, [_status]);
  
  useEffect(() => {
    if (pause_id)
      setPause(pauses.dict[pause_id]);
  }, [pause_id, pauses.dict]);

  useEffect(() => {
    setStatusTitle(t(`STATUS_${status}`));
  }, [status, t]);

  const color = <div className='color' style={{ backgroundColor: UserStatusColors[status || 0], width: 8, height: 8, borderRadius: '50%' }} />;

  return <div className={'status-wrapper'} >
    {!tooltip && color}
    {tooltip && <Tooltip placement={'top'} title={statusTitle}>{color}</Tooltip>}
    {!tooltip && <div className='status'>{pause?.title || t(`STATUS_${status}`)}</div>}
  </div>;

});
