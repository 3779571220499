import { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { useFields } from '@Hooks/abstract/use.fields.abstract';
import { FieldTypeEnum, FormElementTypeExposedEnum, ISharedOrganization } from 'atlas-shared/dist';

interface IEditorHashOption {
  id: string;
  value: string;
}
export type TEditorHashOptions = Array<IEditorHashOption>;

export const useEditorHashContactOptions = (t: TFunction): TEditorHashOptions => {
  const [hashOptions] = useState<TEditorHashOptions>([
    { id: 'contact_first_name', value: t('CONTACT_FIRST_NAME') },
    { id: 'contact_last_name', value: t('CONTACT_LAST_NAME') },
    { id: 'contact_full_name', value: t('CONTACT_FULL_NAME') },
    { id: 'contact_email', value: t('CONTACT_EMAIL') },
    { id: 'contact_phone', value: t('CONTACT_PHONE') }
  ]);

  return hashOptions;
};

export const useEditorHashAgentOptions = (t: TFunction): TEditorHashOptions => {
  const [hashOptions] = useState<TEditorHashOptions>([
    { id: 'agent_first_name', value: t('AGENT_FIRST_NAME') },
    { id: 'agent_last_name', value: t('AGENT_LAST_NAME') },
    { id: 'agent_full_name', value: t('AGENT_FULL_NAME') }
  ]);

  return hashOptions;
};

export const useEditorHashConversationOptions = (t: TFunction): TEditorHashOptions => {
  const [hashOptions] = useState<TEditorHashOptions>([
    { id: 'conversation_id', value: t('CONVERSATION__ID') }
  ]);

  return hashOptions;
};

export const useEditorHashFieldOptions = (t: TFunction, organization_id?: ISharedOrganization['id']): TEditorHashOptions => {
  const fields = useFields();
  const [hashOptions, setHashOptions] = useState<TEditorHashOptions>([]);

  useEffect(() => {
    setHashOptions((organization_id ? fields.fields : [])?.filter(field => (
      field.removable
      && field.type === FieldTypeEnum.Conversation
      && field.organization_id === organization_id
      && [
        FormElementTypeExposedEnum.NUMBER,
        FormElementTypeExposedEnum.TEXT,
        FormElementTypeExposedEnum.INPUT,
        FormElementTypeExposedEnum.HTML,
        FormElementTypeExposedEnum.EMAIL,
        FormElementTypeExposedEnum.PHONE
      ].includes(field.settings.ui_type)
    )).map(field => {
      return { id: `field___${field.alias}`, value: `${t('FIELD')}: ${field.title}` };
    }) || []);
  }, [fields, organization_id]);

  return hashOptions;
};
