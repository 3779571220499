import { IPreviewTeam } from 'atlas-shared';

export enum TeamActions {
  SET_STATE = 'TEAM_SET_STATE',
  ADD_STATE = 'TEAM_ADD_STATE',
  REMOVE_STATE = 'TEAM_REMOVE_STATE',
  GET_STATE = 'TEAM_GET_STATE',
  UPDATE_STATE = 'TEAM_UPDATE_STATE',
  LOADING = 'TEAM_LOADING',
}

export interface ITeamLoading {
  type: TeamActions.LOADING;
}

export interface ITeamSetAction {
  type: TeamActions.SET_STATE;
  payload: Array<IPreviewTeam>;
}

export interface ITeamUpdateAction {
  type: TeamActions.UPDATE_STATE;
  payload: IPreviewTeam;
}

export interface ITeamAddAction {
  type: TeamActions.ADD_STATE;
  payload: IPreviewTeam;
}

export interface ITeamRemoveAction {
  type: TeamActions.REMOVE_STATE;
  payload: IPreviewTeam['id'];
}

export interface ITeamStore {
  loaded: boolean;
  loading: boolean;
  teams: Array<IPreviewTeam>;
  dict: Record<IPreviewTeam['id'], IPreviewTeam>;
}

export type TTeamActionTypes = ITeamSetAction | ITeamUpdateAction | ITeamAddAction | ITeamRemoveAction | ITeamLoading;
