import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  OperationalHoursEditForm,
  OperationalHoursEditInitialValues,
  OperationalHoursComponents,
  OperationalHoursAdditionalParams,
  OperationalHoursFormOptions,
  onOperationalHoursFormSaved,
  IOperationalHoursFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedOperationalHours, ISharedPatchOperationalHours, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchOperationalHours, patchOperationalHours, getOperationalHoursUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useTimeIntervals } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedOperationalHours> {}

const OperationalHoursFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const time_intervals = useTimeIntervals();

  useEffect(() => {
    fetchOperationalHours(id).then(operational_hours => setValue(operational_hours));
  }, [id, setValue]);

  const param_props: IOperationalHoursFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    time_intervals: time_intervals.time_intervals
  };
  const formLayout: IForm | undefined = useMemo(() => value && OperationalHoursEditForm(value, param_props, t), [value, organizations, time_intervals, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && OperationalHoursFormOptions(value, param_props, t), [value, organizations, time_intervals, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchOperationalHours, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchOperationalHours) => value && save(() => patchOperationalHours(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onOperationalHoursFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !time_intervals.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.OperationalHours, value)}
    >
      <AtlasForm<ISharedPatchOperationalHours>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={OperationalHoursComponents}
        additionalParams={OperationalHoursAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={OperationalHoursEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('OPERATIONAL_HOURS')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const OperationalHoursFormEditView = React.memo(withEdit(OperationalHoursFormEdit, getOperationalHoursUpdateSchema));
