import { IAuth } from '@Store';
import { IForm, IFormOptions } from 'atlas-form';

import {
  AgentprofileAdditionalParams as AgentprofileAdditionalParamsBase,
  AgentprofileFormOptions as AgentprofileFormOptionsBase,
  IAgentprofileFormOptionsProps
} from './abstract/agentprofile.form.abstract';
import { TFunction } from 'i18next';
import { filterRelated } from '@Utils/relation';
import { IPreviewPause, ISharedAgentprofile, ISharedCreateAgentprofile, Countries } from 'atlas-shared';

export const AgentprofileAdditionalParams: (auth: IAuth, props: IAgentprofileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IAgentprofileFormOptionsProps) => ({
  ...AgentprofileAdditionalParamsBase(auth, props),
  settings_inactivity_ready: { postFixText: 'MINUTES' },
  settings_inactivity_pause: { postFixText: 'MINUTES' },
  settings_iso2: { label: 'DEFAULT_VOICE_COUNTRY' }
});

export const AgentprofileFormOptions = (agentprofile: ISharedCreateAgentprofile | ISharedAgentprofile, props: IAgentprofileFormOptionsProps, t: TFunction): IFormOptions => ({
  ...AgentprofileFormOptionsBase(agentprofile, props, t),
  settings_autopause: (it: ISharedAgentprofile) => filterRelated<IPreviewPause>(it.organization_id || agentprofile.organization_id, 'pause', 'agentprofile', props.pauses, true, it => it.global),
  settings_iso2: Countries.map(([ISO2]) => ({
    title: t(`COUNTRY_${ISO2}`),
    key: ISO2
  })),
});

export * from './abstract/agentprofile.form.abstract';
