import { IPreviewRecentlyViewedConversation } from 'atlas-shared';

export enum RecentlyViewedConversationActions {
  SET_STATE = 'RECENTLY_VIEWED_CONVERSATION_SET_STATE',
  ADD_STATE = 'RECENTLY_VIEWED_CONVERSATION_ADD_STATE',
  REMOVE_STATE = 'RECENTLY_VIEWED_CONVERSATION_REMOVE_STATE',
  GET_STATE = 'RECENTLY_VIEWED_CONVERSATION_GET_STATE',
  UPDATE_STATE = 'RECENTLY_VIEWED_CONVERSATION_UPDATE_STATE',
  LOADING = 'RECENTLY_VIEWED_CONVERSATION_LOADING',
}

export interface IRecentlyViewedConversationLoading {
  type: RecentlyViewedConversationActions.LOADING;
}

export interface IRecentlyViewedConversationSetAction {
  type: RecentlyViewedConversationActions.SET_STATE;
  payload: Array<IPreviewRecentlyViewedConversation>;
}

export interface IRecentlyViewedConversationUpdateAction {
  type: RecentlyViewedConversationActions.UPDATE_STATE;
  payload: IPreviewRecentlyViewedConversation;
}

export interface IRecentlyViewedConversationAddAction {
  type: RecentlyViewedConversationActions.ADD_STATE;
  payload: IPreviewRecentlyViewedConversation;
}

export interface IRecentlyViewedConversationRemoveAction {
  type: RecentlyViewedConversationActions.REMOVE_STATE;
  payload: IPreviewRecentlyViewedConversation['id'];
}

export interface IRecentlyViewedConversationStore {
  loaded: boolean;
  loading: boolean;
  recently_viewed_conversations: Array<IPreviewRecentlyViewedConversation>;
  dict: Record<IPreviewRecentlyViewedConversation['id'], IPreviewRecentlyViewedConversation>;
}

export type TRecentlyViewedConversationActionTypes = IRecentlyViewedConversationSetAction | IRecentlyViewedConversationUpdateAction | IRecentlyViewedConversationAddAction | IRecentlyViewedConversationRemoveAction | IRecentlyViewedConversationLoading;
