import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchJourneyDrafts, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useJourneyDrafts = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const journey_drafts = useSelector((store: IReducerStore) => store.journey_drafts);

  useEffect(() => {
    if (!initiated && autoload && !journey_drafts.loaded && !journey_drafts.loading) {
      initiated = true;
      dispatch(actionFetchJourneyDrafts());
    }
  }, [autoload, journey_drafts.loaded, journey_drafts.loading, dispatch]);

  return journey_drafts;
};
