import { IPreviewAgentprofile } from 'atlas-shared';

export enum AgentprofileActions {
  SET_STATE = 'AGENTPROFILE_SET_STATE',
  ADD_STATE = 'AGENTPROFILE_ADD_STATE',
  REMOVE_STATE = 'AGENTPROFILE_REMOVE_STATE',
  GET_STATE = 'AGENTPROFILE_GET_STATE',
  UPDATE_STATE = 'AGENTPROFILE_UPDATE_STATE',
  LOADING = 'AGENTPROFILE_LOADING',
}

export interface IAgentprofileLoading {
  type: AgentprofileActions.LOADING;
}

export interface IAgentprofileSetAction {
  type: AgentprofileActions.SET_STATE;
  payload: Array<IPreviewAgentprofile>;
}

export interface IAgentprofileUpdateAction {
  type: AgentprofileActions.UPDATE_STATE;
  payload: IPreviewAgentprofile;
}

export interface IAgentprofileAddAction {
  type: AgentprofileActions.ADD_STATE;
  payload: IPreviewAgentprofile;
}

export interface IAgentprofileRemoveAction {
  type: AgentprofileActions.REMOVE_STATE;
  payload: IPreviewAgentprofile['id'];
}

export interface IAgentprofileStore {
  loaded: boolean;
  loading: boolean;
  agentprofiles: Array<IPreviewAgentprofile>;
  dict: Record<IPreviewAgentprofile['id'], IPreviewAgentprofile>;
}

export type TAgentprofileActionTypes = IAgentprofileSetAction | IAgentprofileUpdateAction | IAgentprofileAddAction | IAgentprofileRemoveAction | IAgentprofileLoading;
