import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchQueues, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useQueues = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const queues = useSelector((store: IReducerStore) => store.queues);

  useEffect(() => {
    if (!initiated && autoload && !queues.loaded && !queues.loading) {
      initiated = true;
      dispatch(actionFetchQueues());
    }
  }, [autoload, queues.loaded, queues.loading, dispatch]);

  return queues;
};
