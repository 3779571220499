import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './tabs.scss';
import { useAuth, useSort, useTabs } from '@Hooks';
import { IPreviewTab, ISharedFullConversation, TabSizeEnum } from 'atlas-shared';
import { TabMaximizeWrapper } from './tab.maximize';
import { deleteTab, patchTab, readConversation } from '@Api';
import { useTranslation } from 'react-i18next';
import {
  actionSetFocusedConversation,
  actionSetHandlingConversation,
  actionUpdateTab,
  useAppDispatch
} from '@Store';
import { setPreventDefault } from '@Utils';
import { TabMini } from '@Components/tab/tab.mini';
import { VoiceInternals } from '@Components/tab/voice-internals/voice-internals';
import { EmergencyTabs } from './emergency';

export const Tabs = React.memo(() => {
  const TABS_LIMIT = 7;
  const tabs = useTabs();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [filteredTabs, setFilteredTabs] = useState<{active: Array<IPreviewTab>; inactive: number}>({ active: [], inactive: 0 });
  const { onDragStart, onDragEnd, onDragOver, canDrop } = useSort({
    dropElement: <div className="drop-element">{t('DROP_HERE')}</div>,
    value: filteredTabs.active,
    onChange: (new_order) => {
      new_order.forEach((new_tab, index) => {
        if (new_tab.order !== index) {
          dispatch(actionUpdateTab({ ...new_tab, order: index }));
          patchTab(new_tab.id, {
            order: index
          });
        }
      });

    }
  });

  useEffect(() => {
    const allTabs = [...tabs.tabs].sort((a, b) => a.order - b.order)
      .filter(t => t.agentprofile_id === auth.user_status.agentprofile_id);

    setFilteredTabs({
      active: allTabs.slice(0, TABS_LIMIT),
      inactive: allTabs.slice(TABS_LIMIT, allTabs.length).length
    });
  }, [tabs, auth.user_status.agentprofile_id]);

  const expandedTab = tabs.tabs.find(t => t.size === TabSizeEnum.Maximized);

  const onClose = (tab: IPreviewTab): Promise<IPreviewTab> => {
    return new Promise((resolve) => {
      deleteTab(tab.id)
        .then(_ => {
          //dispatch(conversationsRemoveFullState(tab.conversation_id));
        })
        .finally(() => resolve(tab));
    });
  };

  const onHeaderClick = useCallback((tab: IPreviewTab) => {
    if (expandedTab) {
      if (expandedTab.id !== tab.id) {
        patchTab(expandedTab.id, { size: TabSizeEnum.Minimized });
        return patchTab(tab.id, { size: TabSizeEnum.Maximized });
      } else {
        return patchTab(tab.id, { size: TabSizeEnum.Minimized });
      }
    } else {
      if (tab.size === TabSizeEnum.Minimized) {
        return patchTab(tab.id, { size: TabSizeEnum.Mini });
      } else {
        return patchTab(tab.id, { size: TabSizeEnum.Minimized });
      }
    }

  }, [expandedTab]);

  const onMinimize = useCallback((tab: IPreviewTab) => {
    return patchTab(tab.id, { size: TabSizeEnum.Minimized });
  }, []);

  const onMaximize = useCallback((tab: IPreviewTab) => {
    [...tabs.tabs].filter(t => t.size === TabSizeEnum.Maximized).forEach(t => onMinimize(t));
    // return new Promise((resolve) => {
    //   setTimeout(() => { resolve(true); }, 4000);
    // });
    return patchTab(tab.id, { size: TabSizeEnum.Maximized });
  }, [tabs, onMinimize]);

  const onMini = useCallback((tab: IPreviewTab) => {
    return patchTab(tab.id, { size: TabSizeEnum.Mini });
  }, []);

  const onFocus = useCallback((tab: IPreviewTab, conversation: ISharedFullConversation) => {
    if (conversation.user_id === auth.user.id && conversation.read < conversation.unread)
      readConversation(conversation.organization_id, conversation.id);

    dispatch(actionSetFocusedConversation(conversation.id));
    dispatch(actionSetHandlingConversation(conversation.id));
  }, [auth.user.id, dispatch]);

  const hasVoice = useMemo(() => auth?.user.main && auth?.user.voice_license, [auth]);

  return (
    <div className={'tabs-wrapper'}>
      <EmergencyTabs t={t} />
      {hasVoice && <VoiceInternals />}
      <div
        className={'tabs'}
        onDrop={setPreventDefault}
        onDragOver={setPreventDefault}
        draggable={false}
      >
        {!!filteredTabs.inactive && <div id="inactive-tabs">
          <h1>{filteredTabs.inactive}</h1>
          <div className='line-1'>{t('INACTIVE_TABS_HIDDEN')}</div>
          <div className='line-2'>{t('INACTIVE_TABS_DESCRIPTION')}</div>
        </div>}
        {filteredTabs.active.map((tab, index) => {

          return <React.Fragment key={tab.id}>
            {canDrop(index, true)}
            <TabMini
              auth={auth}
              tab={tab}
              organization_id={tab.organization_id}
              conversation_id={tab.conversation_id}
              index={index}
              expandedTab={expandedTab}
              onClose={() => onClose(tab)}
              onMaximize={() => onMaximize(tab)}
              onHeaderClick={() => onHeaderClick(tab)}
              onFocus={(conversation) => onFocus(tab, conversation)}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
            />
            {canDrop(index, false)}
          </React.Fragment>;

        })}
      </div>
      {filteredTabs.active.map(tab => {
        return <TabMaximizeWrapper
          key={tab.id}
          auth={auth}
          visible={expandedTab?.id === tab.id}
          onClose={() => onClose(tab)}
          onMinimize={() => onMinimize(tab)}
          onMini={() => onMini(tab)}
          organization_id={tab.organization_id}
          conversation_id={tab.conversation_id}
          tab={tab}
          onFocus={(conversation) => onFocus(tab, conversation)}
          absolute={true}
        />;
      })}
    </div>
  );
});
