import { RestRequest } from '@Utils';
import { IPreviewVoiceVoicemailMessage, ISharedVoiceVoicemailMessage } from 'atlas-shared';

export const fetchVoiceVoicemailMessages = async (): Promise<Array<IPreviewVoiceVoicemailMessage>> => {
  return RestRequest.get<Array<IPreviewVoiceVoicemailMessage>>('voice_voicemail_message', { socket_id: global.socketId });
};

export const fetchVoiceVoicemailMessage = async (id: IPreviewVoiceVoicemailMessage['id']): Promise<ISharedVoiceVoicemailMessage> => {
  return RestRequest.get<ISharedVoiceVoicemailMessage>(`voice_voicemail_message/${id}`, { socket_id: global.socketId });
};
