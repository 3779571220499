import { ISvgIconProps } from '@Assets/icons/interface';
import { SvgIcon } from '@Assets/icons/SvgIcon';

export const PercentIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="19" y1="5" x2="5" y2="19"></line>
    <circle cx="6.5" cy="6.5" r="2.5"></circle>
    <circle cx="17.5" cy="17.5" r="2.5"></circle>
  </SvgIcon>;
};
