import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const WidgetTimelineIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <g id="surface1">
      <g fill="#444" transform="scale(0.0234375 0.0234375)"><path d="M832 406q44 0 75 31t31 75-31 75-75 31q-72 0-98-64h-124q-26 64-98 64t-98-64h-124q-26 64-98 64-44 0-75-31t-31-75 31-75 75-31q72 0 98 64h124q26-64 98-64t98 64h124q26-64 98-64z" /></g>
    </g>
  </SvgIcon>;
};
