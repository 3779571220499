import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDevices, useDevicesAvailable } from '@Hooks';
import { deviceSetErrorCatch } from '@Utils';
import { TFunction } from 'i18next';

interface IProps {
  id: string;
  volume: number;
  t: TFunction;
}

const VoiceAudioElement = ({ id, volume, t }: IProps) => {
  const audioElement = useRef<HTMLAudioElement>(null);
  const devices = useDevices();
  const devices_available = useDevicesAvailable();
  const [didMount, setDidMount] = useState<boolean>(false);

  useEffect(() => {
    if (audioElement.current)
      audioElement.current.volume = volume;
  }, [volume]);
  
  useEffect(() => {
    if (devices.speaker)
      (audioElement.current as any)?.setSinkId?.(devices_available.speaker ? devices.speaker || null : 'default').catch(err => deviceSetErrorCatch(t('ERROR_SETTING_SPEAKER'), err, t));
  }, [devices.speaker, devices_available.speaker, didMount]);

  useLayoutEffect(() => {
    if (!didMount && audioElement.current)
      setDidMount(true);
  });

  return <>
    <audio id={`remoteAudio-${id}`} controls ref={audioElement}>
      <p>Your browser does not support HTML5 audio.</p>
    </audio>
  </>;
};

export default React.memo(VoiceAudioElement);
