import { RestRequest } from '@Utils';
import { IPreviewSession, ISharedSession } from 'atlas-shared';

export const fetchSessions = async (): Promise<Array<IPreviewSession>> => {
  return RestRequest.get<Array<IPreviewSession>>('session', { socket_id: global.socketId });
};

export const fetchSession = async (id: IPreviewSession['id']): Promise<ISharedSession> => {
  return RestRequest.get<ISharedSession>(`session/${id}`, { socket_id: global.socketId });
};

export const deleteSession = (id: IPreviewSession['id']) => {
  return RestRequest.delete(`session/${id}`, { socket_id: global.socketId });
};
