import {
  IJourneyPayloadLinksKeyProperty,
  IJourneyPayloadNodesKeyProperty, ISharedJourneyDraft, JourneyActionEnum, IJourneyNodeError } from 'atlas-shared';
import { Edge, Node, Position } from 'react-flow-renderer';
import { actions, IJourneyActionProps } from '@Utils';
import './styles.scss';

export const getActionProps = (action: JourneyActionEnum): IJourneyActionProps => actions[action];

export interface INodeFlowElementData {
  node: IJourneyPayloadNodesKeyProperty;
  journey: ISharedJourneyDraft['payload'];
  store: any;
  options: any;
  errors: Array<IJourneyNodeError>;
}

export const createNode = (journey: ISharedJourneyDraft['payload'], node: IJourneyPayloadNodesKeyProperty, t, store: any, options: any): Node<INodeFlowElementData> => {

  return {
    id: node.id,
    type: node.type,
    data: {
      node,
      store,
      options,
      journey,
      errors: []
    },
    position: node.position,
    selectable: true,
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
  };
};

export const createConnector = (link: IJourneyPayloadLinksKeyProperty): Edge => {

  if (link.from.nodeId === link.to.nodeId)
    alert('Cannot connect to itself!');

  return {
    id: link.id,
    source: link.from.nodeId,
    target: link.to.nodeId,
    style:{ strokeWidth: 4 },
    type: 'edge',
    sourceHandle: link.from.portId,
    targetHandle: link.to.portId,
    data: {}
  };
};

export * from './journey.view';
