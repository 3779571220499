import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DirectionEnum,
  InvoiceDuePeriodEnum,
  InvoicePaymentInfoTemplateEnum,
  InvoicePostAmountTypeEnum,
  InvoicePostTypeEnum,
  ISharedFullInvoice,
  ISharedInvoice,
  ISharedInvoicePost,
  ISharedOrganization
} from 'atlas-shared/dist';
import { fetchInvoiceFull, fetchOrganization } from '@Api';
import { Spinner } from '@Components/misc';
import './invoice.pdf.scss';
import moment from 'moment';
import { useOrganizations } from '@Hooks';
import logo from '@Assets/graphic/logo.png';
import { useTranslation } from 'react-i18next';

export const InvoicePdf = React.memo(({ id }: {id: ISharedInvoice['id']}) => {

  const { t } = useTranslation();
  const [invoiceFull, setInvoiceFull] = useState<ISharedFullInvoice>();
  const [organization, setOrganization] = useState<ISharedOrganization>();
  const organizations = useOrganizations();
  const dateFormat = 'YYYY-MM-DD';
  const total = useMemo(() => (+(invoiceFull?.vat || 0)) + (+(invoiceFull?.amount || 0)), [invoiceFull]);
  const currencyFormatter = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: invoiceFull?.currency || 'SEK'
    });
  }, [invoiceFull]);
  const splitCurrency = (_str, ...indices) => {
    const str = _str.split('').reverse().join('');

    return [0, ...indices].map((startIndex, index, arr) => {
      return str.slice(startIndex, arr[index + 1]).split('').reverse().join('');
    }).reverse().join(' ');
  };
  const fetchCurrency = useCallback((amount: number | string | null | undefined, min_decimals: number = 2, max_decimals: number = 2) => {
    const [n = '0', d = ''] = ((amount || '') + '').split('.');

    let _d = '';

    (d || '').split('').reverse().forEach(_ => {
      if (_ === '0' && !_d)
        return;

      _d += _;
    });

    const [__n, __d] = (+(`${n || 0}.${_d.split('').reverse().join('')}`)).toFixed(_d.length > 2 ? Math.min(_d.length, max_decimals) : min_decimals).split('.');

    return <div className='amount'>
      {splitCurrency(__n, 3, 6, 9)}{__d.length && ','}{__d.substr(0, 4)}
      <span>{invoiceFull?.currency}</span>
    </div>;
  }, [invoiceFull]);
  const printableSeconds = useCallback((seconds: number | null | undefined) => {
    if (!seconds)
      return <span className='duration'>00:00:00</span>;

    const hours = Math.floor(seconds / 60 / 60);

    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);

    seconds -= minutes * 60;

    return <span className='duration'>{(hours < 10 ? '0' : '')}{hours}:{(minutes < 10 ? '0' : '')}{minutes}:{(seconds < 10 ? '0' : '')}{seconds}</span>;

  }, []);

  const printableFileSize = useCallback((file_size: number) => {
    return <div className='file-size'>{(file_size / 1024).toFixed(2)}<span>MB</span></div>;
  }, []);

  const printablePercent = useCallback((percent: any) => {
    const [n, d] = (percent + '').split('.');

    let _d = '';

    d.split('').reverse().forEach(_ => {
      if (_ === '0' && !_d)
        return;

      _d += _;
    });

    return <div className='percent'>
      {n}{_d && ','}{_d.split('').reverse()}
      <span>%</span>
    </div>;
  }, []);

  useEffect(() => {
    fetchInvoiceFull(id).then(invoice => {
      setInvoiceFull(invoice);
      fetchOrganization(invoice.organization_id).then(organization => setOrganization(organization));
    });
  }, [id]);

  const posts = useMemo(() => {
    const organizations: Record<ISharedOrganization['id'], Record<ISharedInvoicePost['period_start'], Array<ISharedInvoicePost>>> = {};
    const addons: Array<ISharedInvoicePost> = [];
    const total_addons: Array<ISharedInvoicePost> = [];

    if (!invoiceFull)
      return { organizations, addons, total_addons };

    invoiceFull.invoice_posts.forEach(invoice_post => {

      if ([InvoicePostTypeEnum.Custom].includes(invoice_post.type)) {
        total_addons.push(invoice_post);
        return;
      }

      if (invoice_post.type.includes('Addon')) {
        addons.push(invoice_post);
        return;
      }

      if (!organizations[invoice_post.organization_id])
        organizations[invoice_post.organization_id] = {};

      if (!organizations[invoice_post.organization_id][invoice_post.period])
        organizations[invoice_post.organization_id][invoice_post.period] = [];

      organizations[invoice_post.organization_id][invoice_post.period].push(invoice_post);
    });

    return { organizations, addons, total_addons };
  }, [invoiceFull]);

  const is_multi_month = useMemo(() => {
    return true;
    //return invoiceFull && (moment(invoiceFull.period_end).month() - moment(invoiceFull.period_start).month()) > 0;
  }, [invoiceFull]);

  const is_multi_organizations = useMemo(() => Object.keys(posts.organizations).length > 1, [posts.organizations]);

  const payment_info = useMemo(() => {
    switch ((organization?.invoice.payment_info_template || InvoicePaymentInfoTemplateEnum.HANDELSBANKEN_BG_SEK) as InvoicePaymentInfoTemplateEnum) {
    case InvoicePaymentInfoTemplateEnum.HANDELSBANKEN_BG_SEK:
      return <section>
        <div className='title'>{t('BANKGIRO')}</div>
        <div>281-8300</div>
      </section>;
    case InvoicePaymentInfoTemplateEnum.HANDELSBANKEN_IBAN_SEK:
    case InvoicePaymentInfoTemplateEnum.HANDELSBANKEN_IBAN_EUR:
    case InvoicePaymentInfoTemplateEnum.HANDELSBANKEN_IBAN_USD:
      return <section>
        <div className='title'>{t('BIC_SWIFT')}</div>
        <div>HANDSESS</div>
        <div className='title'>{t('IBAN')}</div>
        <div>SE8660000000000637497252</div>
        <div style={{ marginTop: 5 }}>
          Svenska Handelsbanken<br />
          Björkgatan 61<br />
          SE-751 06 Uppsala<br />
          Sweden
        </div>
      </section>;
    case InvoicePaymentInfoTemplateEnum.WIZE_EUR:
      return <section>
        <div className='title'>{t('BIC_SWIFT')}</div>
        <div>WIZSESS</div>
        <div className='title'>{t('IBAN')}</div>
        <div>SE8660000000000637497252</div>
      </section>;
    }
  }, [organization]);

  if (!invoiceFull?.id || !organization?.id || !organizations.loaded)
    return <Spinner />;

  if (!organization.invoice?.data)
    return <></>;

  const address = organization.invoice.data.company_address;
  const VATpercent = organization.invoice.data.VAT;

  return <div className='invoice-print'>

    <div className='invoice-header'>
      <div className='logo'>
        <img src={logo} alt="logo" style={{ width: 80 }} />
      </div>
      <div className='right'>
        <div className='main-info'>
          <div className='box'>
            <div className='title'>{t('DUE_DATE')}</div>
            <div className='value'>{moment(invoiceFull.due_date).format(dateFormat)}</div>
          </div>
          <div className='box'>
            <div className='title'>{t('INVOICE_DATE')}</div>
            <div className='value'>{moment(invoiceFull.period_end).add(1, 'day').format(dateFormat)}</div>
          </div>
          <div className='box'>
            <div className='title'>{t('INVOICE_NUMBER')}</div>
            <div className='value'>{invoiceFull.id}</div>
          </div>
          <div className='box'>
            <div className='title'>{t('CUSTOMER_NUMBER')}</div>
            <div className='value'>{organization.id}</div>
          </div>
          {
            /*
            <div className='box'>
            <div className='title'>{t('AMOUNT_TO_PAY')}</div>
            <div className='value'>{fetchCurrency(total)}</div>
          </div>
             */
          }
        </div>
        <div className='period'>{t('INVOICE_PERIOD')}: {moment(invoiceFull.period_start).format(dateFormat)} - {moment(invoiceFull.period_end).format(dateFormat)}</div>
      </div>
    </div>

    <div className='subheader'>
      <div className='address'>
        <div>{organization.invoice.data.company_name}</div>
        {organization.invoice.data.cin && <div>{organization.invoice.data.cin}</div>}
        <div>{address.address_row1}</div>
        {address.address_row2 && <div>{address.address_row2}</div>}
        <div>{address.country}-{address.postal_code} {address.city}</div>
      </div>
      <div className='invoice-info'>
        {organization.invoice.data.salesperson && <div className='info-row'>
          <div className='info-title'>{t('SALESPERSON')}</div>
          <div className='info-value'>{organization.invoice.data.salesperson}</div>
        </div>}
        {organization.invoice.data.company_reference && <div className='info-row'>
          <div className='info-title'>{t('YOUR_REFERENCE')}</div>
          <div className='info-value'>{organization.invoice.data.company_reference}</div>
        </div>}
        <div className='info-row'>
          <div className='info-title'>{t('PAYMENT_TERMS')}</div>
          <div className='info-value'>{organization.invoice.data.due_period.count} {t(organization.invoice.data.due_period.type === InvoiceDuePeriodEnum.Month ? 'MONTHS' : 'DAYS')}</div>
        </div>
        {organization.invoice.data.VAT_registration_no && <div className='info-row'>
          <div className='info-title'>{t('VAT_REGISTRATION_NO')}</div>
          <div className='info-value'>{organization.invoice.data.VAT_registration_no}</div>
        </div>}
        {organization.invoice.data.fin_charge_terms && <div className='info-row'>
          <div className='info-title'>{t('FIN_CHARGE_TERMS')}</div>
          <div className='info-value'>{organization.invoice.data.fin_charge_terms}%</div>
        </div>}
      </div>
    </div>

    <div className='helpdesk'>For questions on this specification, contact invoice@connectel.se or your Account Manager</div>

    <div className='details'>
      {Object.entries(posts.organizations).map(([organization_id, __invoice_posts]) => {
        return Object.entries(__invoice_posts).map(([_, _invoice_posts]) => {
          const invoice_posts = _invoice_posts.filter(invoice_post => !!(+(invoice_post.amount || 0)));
          const _organization = organizations.dict[organization_id];
          let total = 0;

          if (!invoice_posts.length)
            return <></>;

          const { period_start, period_end } = invoice_posts[0];
          const invoice_organization_period = is_multi_month ? <span className='invoice-organization-period'>{moment(period_start).format(dateFormat)} - {moment(period_end).format(dateFormat)}</span> : <></>;

          return <div className='organization'>
            <h3>{is_multi_organizations && (_organization?.title || organization_id)}{invoice_organization_period}</h3>
            <table className='price-list'>
              <thead>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {invoice_posts.map(invoice_post => {

                  const amount = +(invoice_post.amount || 0);

                  total += amount;

                  if (invoice_post.details?.length)
                    return invoice_post.details.map(detail => {

                      const detail_count = (detail.billable_seconds || detail.seconds) ? printableSeconds(detail.billable_seconds || detail.seconds) : detail.file_size ? printableFileSize(detail.file_size) : null;

                      return <tr>
                        <td colSpan={detail_count ? 1 : 2}>{[
                          invoice_post.title,
                          [InvoicePostTypeEnum.VoiceUsage, InvoicePostTypeEnum.VoiceTariff].includes(invoice_post.type) && detail.direction && detail.direction === DirectionEnum.Outbound && t(`DIRECTION_${detail.direction}`),
                          detail.title,
                          detail.voice_type && t(detail.voice_type),
                          detail.sub_type && t(detail.sub_type),
                          detail.country && t(`COUNTRY_${detail.country}`)
                        ].filter(Boolean).join(' - ')}</td>
                        {detail_count && <td className='right'>
                          {detail_count}
                        </td>}
                        <td className='right'>
                          {detail.a_price && fetchCurrency(detail.a_price, 2, detail.a_price > 10 ? 2 : 20)}
                        </td>
                        <td className='right'>
                          <div className='pieces'>
                            {detail.quantity}
                            <span>{t('PCS')}</span>
                          </div>
                        </td>
                        <td className='right'>{fetchCurrency(detail.amount)}</td>
                      </tr>;
                    });

                  return <tr>
                    <td colSpan={4}>{invoice_post.title}</td>
                    <td className='right'>{fetchCurrency(invoice_post.amount)}</td>
                  </tr>;
                })}
                <tr className='total'>
                  <td colSpan={4}>{t('SUBTOTAL')}</td>
                  <td className='right'>{fetchCurrency(total)}</td>
                </tr>
              </tbody>
            </table>
          </div>;
        });
      })}
    </div>

    <div className='addons'>
      <table className='price-list'>
        <tbody>
          {posts.addons.map(invoice_post => {
            return <tr>
              <td>{invoice_post.title}</td>
              <td className='invoice-post-period'>{is_multi_month && <span>{moment(invoice_post.period_start).format(dateFormat)} - {moment(invoice_post.period_end).format(dateFormat)}</span>}</td>
              <td className='right'>
                {[InvoicePostAmountTypeEnum.Currency, InvoicePostAmountTypeEnum.MinDeb].includes(invoice_post.amount_type) && invoice_post.order < 800 && fetchCurrency(invoice_post.amount_currency)}
                {invoice_post.amount_type === InvoicePostAmountTypeEnum.Percent && printablePercent(invoice_post.amount_percent)}
              </td>
              <td className='right'>{fetchCurrency(invoice_post.amount)}</td>
            </tr>;
          })}
          <tr><td><span style={{ opacity: 0 }}>Total addons</span></td></tr>
          {posts.total_addons.map(invoice_post => {
            return <tr>
              <td>{invoice_post.title}</td>
              <td className='invoice-post-period'>{is_multi_month && <span>{moment(invoice_post.period_start).format(dateFormat)} - {moment(invoice_post.period_end).format(dateFormat)}</span>}</td>
              <td className='right'>
                {[InvoicePostAmountTypeEnum.Currency, InvoicePostAmountTypeEnum.MinDeb].includes(invoice_post.amount_type) && invoice_post.order < 800 && fetchCurrency(invoice_post.amount_currency)}
                {invoice_post.amount_type === InvoicePostAmountTypeEnum.Percent && printablePercent(invoice_post.amount_percent)}
              </td>
              <td className='right'>{fetchCurrency(invoice_post.amount)}</td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>

    <div className='total'>
      <table className='price-list'>
        <tbody>
          {!!invoiceFull.vat && invoiceFull.amount && <>
            <tr className='sub-total'>
              <td colSpan={2}>{t('NET_AMOUNT')}</td>
              <td className='right'>{fetchCurrency(invoiceFull.amount)}</td>
            </tr>
            {!!invoiceFull.vat && <tr className='vat'>
              <td>{t('VAT')}</td>
              <td className='right'>{VATpercent}%</td>
              <td className='right'>{fetchCurrency(invoiceFull.vat)}</td>
            </tr>}
          </>}
          {!!invoiceFull.invoice_rounding && <tr className='vat'>
            <td colSpan={2}>{t('INVOICE_ROUNDING')}</td>
            <td className='right'>{fetchCurrency(invoiceFull.invoice_rounding)}</td>
          </tr>}

          <tr className='total'>
            <td colSpan={2}>{t('AMOUNT_TO_PAY')}</td>
            <td className='right'>{fetchCurrency((+(invoiceFull.vat || 0)) + (+(invoiceFull.amount || 0)) + (+(invoiceFull.invoice_rounding || 0)))}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className='footer'>
      <section>
        <div className='title'>{t('ADDRESS')}</div>
        <div>Connectel AB</div>
        <div>Kivra:556755-6559</div>
        <div>106 31 Stockholm</div>
      </section>
      <section>
        <div className='title'>{t('PHONE')}</div>
        <div>+46 (0)771 12 12 10</div>
        <div className='title'>{t('INTERNET')}</div>
        <div>www.connectel.se</div>
        <div className='title'>{t('E_MAIL')}</div>
        <div>invoice@connectel.se</div>
      </section>
      <section>
        <div className='title'>{t('CORPORATE_IDENTITY_NO')}</div>
        <div>556755-6559</div>
        <div className='title'>{t('VAT_REGISTRATION_NO')}</div>
        <div>SE556755655901</div>
      </section>
      {payment_info}
    </div>

  </div>;
});
