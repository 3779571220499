import { NavigateFunction } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { ISharedCreatePhonenumberMonthlyCost, ISharedUpdatePhonenumberMonthlyCost, ISharedPhonenumberMonthlyCost, IPreviewPhonenumberMonthlyCostParent, IPreviewOrganization, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { entitiesToOptions, onFormSaved } from '@Utils';

export interface IPhonenumberMonthlyCostFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  phonenumber_monthly_cost_parents: Array<IPreviewPhonenumberMonthlyCostParent>;
  organizations: Array<IPreviewOrganization>;
}

export const PhonenumberMonthlyCostFormOptions = (phonenumber_monthly_cost: ISharedCreatePhonenumberMonthlyCost | ISharedPhonenumberMonthlyCost, props: IPhonenumberMonthlyCostFormOptionsProps, t: TFunction): IFormOptions => ({
  phonenumber_monthly_cost_parent_id: entitiesToOptions<IPreviewPhonenumberMonthlyCostParent>(props.phonenumber_monthly_cost_parents, 'phonenumber_monthly_cost_parent'),
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  
});

export const PhonenumberMonthlyCostComponents: IForm['components'] = {};
export const PhonenumberMonthlyCostAdditionalParams: (auth: IAuth, props: IPhonenumberMonthlyCostFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberMonthlyCostFormOptionsProps) => ({
});

export const PhonenumberMonthlyCostEditForm = (phonenumber_monthly_cost: ISharedPhonenumberMonthlyCost, props: IPhonenumberMonthlyCostFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `phonenumber_monthly_cost_edit_${phonenumber_monthly_cost.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'phonenumber_monthly_cost_parent_id' },
    { ref: 'title' },
    { ref: 'phonenumber_monthly_cost_list' }
  ]
});

export const PhonenumberMonthlyCostAddForm = (phonenumber_monthly_cost: ISharedCreatePhonenumberMonthlyCost, props: IPhonenumberMonthlyCostFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'phonenumber_monthly_cost_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'phonenumber_monthly_cost_parent_id' },
    { ref: 'title' },
    { ref: 'phonenumber_monthly_cost_list' }
  ]
});

export const PhonenumberMonthlyCostAddInitialValues: ISharedCreatePhonenumberMonthlyCost = {} as ISharedCreatePhonenumberMonthlyCost;

export const PhonenumberMonthlyCostEditInitialValues = (phonenumber_monthly_cost: ISharedPhonenumberMonthlyCost): ISharedUpdatePhonenumberMonthlyCost => ({
  phonenumber_monthly_cost_parent_id: phonenumber_monthly_cost.phonenumber_monthly_cost_parent_id,
  title: phonenumber_monthly_cost.title,
  phonenumber_monthly_cost_list: phonenumber_monthly_cost.phonenumber_monthly_cost_list
});

export const onPhonenumberMonthlyCostFormSaved = (t: TFunction, action: 'edit' | 'add', phonenumber_monthly_cost: ISharedPhonenumberMonthlyCost, navigate: NavigateFunction): void => {
  navigate(onFormSaved('phonenumber_monthly_costs'));
};
