import { PhonenumberMonthlyCostActions } from '@Store';
import { IPreviewPhonenumberMonthlyCost } from 'atlas-shared';
import { fetchPhonenumberMonthlyCosts } from '@Api';

export const actionFetchPhonenumberMonthlyCosts = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPhonenumberMonthlyCosts());
    const phonenumber_monthly_costs = await fetchPhonenumberMonthlyCosts();

    dispatch(actionSetPhonenumberMonthlyCosts(phonenumber_monthly_costs));
    return phonenumber_monthly_costs;
  };
};

export const actionLoadingPhonenumberMonthlyCosts = () => ({
  type: PhonenumberMonthlyCostActions.LOADING,
  payload: []
});

export const actionSetPhonenumberMonthlyCosts = (payload: Array<IPreviewPhonenumberMonthlyCost>) => ({
  type: PhonenumberMonthlyCostActions.SET_STATE,
  payload: payload
});

export const actionAddPhonenumberMonthlyCost = (payload: IPreviewPhonenumberMonthlyCost) => ({
  type: PhonenumberMonthlyCostActions.ADD_STATE,
  payload: payload
});

export const actionUpdatePhonenumberMonthlyCost = (payload: IPreviewPhonenumberMonthlyCost) => ({
  type: PhonenumberMonthlyCostActions.UPDATE_STATE,
  payload: payload
});

export const actionRemovePhonenumberMonthlyCost = (id: IPreviewPhonenumberMonthlyCost['id']) => ({
  type: PhonenumberMonthlyCostActions.REMOVE_STATE,
  payload: id
});
