import { RestRequest } from '@Utils';
import { IPreviewConversationWatcher, ISharedConversationWatcher, ISharedCreateConversationWatcher } from 'atlas-shared';

export const fetchConversationWatcher = async (id: IPreviewConversationWatcher['id']): Promise<ISharedConversationWatcher> => {
  return RestRequest.get<ISharedConversationWatcher>(`conversation_watcher/${id}`, { socket_id: global.socketId });
};

export const createConversationWatcher = (payload: ISharedCreateConversationWatcher) => {
  return RestRequest.post<any, ISharedConversationWatcher>('conversation_watcher', payload, { socket_id: global.socketId });
};

export const deleteConversationWatcher = (id: IPreviewConversationWatcher['id']) => {
  return RestRequest.delete(`conversation_watcher/${id}`, { socket_id: global.socketId });
};

export const getConversationWatcherCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation_watcher/create/schema');
};
