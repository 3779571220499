import { Dialpad, Icon, IconText, VoiceSuggestions } from '@Components';
import './voice_call.controller.scss';
import {
  CallTransferIcon, DialpadIcon, HangupIcon,
  MicIcon,
  MicOffIcon,
  PauseIcon,
  PlayIcon,
  RecordIcon,
  RecordingIcon
} from '@Assets';
import { AlertError, isStorybook } from '@Utils';
import { hangupVoiceCall, voiceCallStartRecording, voiceCallStopRecording } from '@Api';
import Text from 'antd/es/typography/Text';
import { Popover } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import {
  IPreviewVoiceCallBridge,
  IPreviewVoiceRecording,
  ISharedFullConversation,
  TConversationVoiceCall,
  Undefinable
} from 'atlas-shared/dist';
import { IVoiceSessionObject, SipUser } from 'SIP';
import { IAuth } from '@Store';

interface IProps {
  t: TFunction;
  auth: IAuth;
  mini?: boolean;
  voice_call: TConversationVoiceCall;
  voice_call_bridge: IPreviewVoiceCallBridge;
  conversation: ISharedFullConversation;
  voice_session?: IVoiceSessionObject;
  sip_user?: SipUser;
  canRecord: boolean;
}

export const VoiceCallController = ({ t, voice_call, voice_call_bridge, conversation, voice_session, sip_user, auth, canRecord, mini = false }: IProps) => {
  
  const tooltip = (title) => ({ title: t(title) });
  const iconSize: number = 16;
  const iconHangupProps = {
    iconProps: { size: iconSize },
    icon: HangupIcon,
    onClick: e => voice_session ? sip_user?.hangup(voice_session) : hangupVoiceCall(voice_call.organization_id, voice_call.id)
  };
  const [manualRecording, setManualRecording] = useState<Undefinable<IPreviewVoiceRecording>>();

  const _storybook = useMemo(() => isStorybook(), []);
  const hasSession = useMemo(() => !!(voice_session || _storybook), [voice_session, _storybook]);

  useEffect(() => {
    setManualRecording((voice_call as TConversationVoiceCall).voice_recordings.find(recording => recording.user_id === auth.user.id && !recording.stopped_at));
  }, [(voice_call as TConversationVoiceCall).voice_recordings]);

  const is_active = voice_call_bridge.answer_time;

  return <div className={`voice-call-controller ${mini ? 'short' : 'long'}`}>
    <div className='voice-controller-buttons'>
      {hasSession && canRecord && <div className={`voice-controller-button voice-controller-button-recording${manualRecording ? ' is-recording' : ''}${is_active ? ' active' : ''}`}>
        <Icon
          className={`voice-controller-${manualRecording ? 'stop' : 'start'}-recording`}
          iconProps={{ size: iconSize }}
          icon={manualRecording ? RecordingIcon : RecordIcon}
          onClick={e => {
            if (voice_session && voice_call.start_time)
              (manualRecording ? voiceCallStopRecording(voice_call.organization_id, manualRecording.id) : voiceCallStartRecording(voice_call.organization_id, voice_call.id, voice_call_bridge.id)).catch(err => AlertError(t, { title: t('SOMETHING_WENT_WRONG'), content: <div>{err}</div> }));
          }}
          tooltip={tooltip(manualRecording ? 'STOP_RECORDING' : 'START_RECORDING')}
        />
        <Text>{t(manualRecording ? 'STOP' : 'RECORD')}</Text>
      </div>}
      {hasSession && <div className={`voice-controller-button voice-controller-button-hold${is_active ? ' active' : ''}`}>
        {
          voice_session?.held ? <Icon
            className='voice-controller-hold'
            iconProps={{ size: iconSize }}
            icon={PlayIcon}
            tooltip={tooltip('UNHOLD')}
            onClick={e => voice_session && sip_user?.unhold(voice_session)}
          /> : <Icon
            iconProps={{ size: iconSize }}
            icon={PauseIcon}
            tooltip={tooltip('HOLD')}
            onClick={e => voice_session && sip_user?.hold(voice_session)}
          />
        }
        <Text>{t(voice_session?.held ? 'UNHOLD' : 'HOLD')}</Text>
      </div>}
      {hasSession && <div className={`voice-controller-button voice-controller-button-mute${is_active ? ' active' : ''}`}>
        {!voice_session?.muted ? <Icon
          iconProps={{ size: iconSize }}
          icon={MicIcon}
          tooltip={tooltip('MUTE')}
          onClick={e => voice_session && sip_user?.mute(voice_session)}
        /> : <Icon
          className='voice-controller-mute'
          iconProps={{ size: iconSize }}
          icon={MicOffIcon}
          tooltip={tooltip('UNMUTE')}
          onClick={e => voice_session && sip_user?.unmute(voice_session)}
        />}
        <Text>{t(voice_session?.muted ? 'UNMUTE' : 'MUTE')}</Text>
      </div>}
      {hasSession && <div className={`voice-controller-button voice-controller-button-transfer${is_active ? ' active' : ''}`}>
        <Popover
          trigger='click'
          content={<>
            <VoiceSuggestions
              popover={false}
              transfer={sip_user && voice_session ? {
                voice_call,
                voice_session,
                sip_user,
                conversation
              } : undefined}
            />
          </>}
          destroyTooltipOnHide
        >
          <Icon
            className='voice-controller-transfer'
            iconProps={{ size: iconSize }}
            icon={CallTransferIcon}
            tooltip={tooltip('TRANSFER')}
          />
        </Popover>

        <Text>{t('TRANSFER')}</Text>
      </div>}
      {hasSession && <div className={`voice-controller-button voice-controller-dialpad${is_active ? ' active' : ''}`}>
        <Popover
          content={<Dialpad
            t={t}
            onPress={digit => {
              voice_session && sip_user?.sendDTMF(voice_session, digit);
            }}
          />}
          trigger={'click'}
          overlayStyle={{ minWidth: 'auto' }}
        >
          <Icon
            className='voice-controller-dialpad'
            icon={DialpadIcon}
            iconProps={{ size: iconSize }}
            tooltip={tooltip('DIALPAD')}
          />
        </Popover>
        <Text>{t('DIALPAD')}</Text>
      </div>}
      {hasSession && <div className='voice-controller-button voice-controller-hangup active'>
        {mini ? <Icon {...iconHangupProps} tooltip={tooltip('HANGUP')}/> :
          <IconText text={t('HANGUP')} {...iconHangupProps} />}
      </div>}
    </div>

  </div>;
};
