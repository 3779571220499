import React, { useEffect, useMemo, useState } from 'react';
import './header.menu.scss';
import { Link, useLocation } from 'react-router-dom';
import { InboxIcon, TrendingUpIcon } from '@Assets/icons';
import { useTranslation } from 'react-i18next';
import { IconText } from '@Components';
import { IAuth } from '@Store';

interface IProps {
  auth: IAuth;
}

export const HeaderMenu = React.memo(({ auth }: IProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [active, setActive] = useState<string>();

  useEffect(() => {
    const _active = location.pathname.startsWith('/dashboard') 
      ? 'dashboard'
      : location.pathname.startsWith('/stats')
        ? 'stats'
        : undefined;

    if (_active !== active)
      setActive(_active);
  }, [location]);
  
  const stats_visible = useMemo(() => auth.user.dashboard_license || auth.user.reporting_license, [auth]);

  return (
    <div className={'header-menu'}>
      <ul>
        <li data-title={t('CONVERSATIONS')} className={`${active === 'dashboard' ? 'active' : ''}`}>
          <Link to="/dashboard">
            <IconText icon={InboxIcon} iconProps={{ size: 22 }} text={t('CONVERSATIONS')} textProps={{ style:{ marginLeft: 7 } }}/>
          </Link>
        </li>
        {
          /*
          <li data-title={t('CONTACTS')} >
          <Link to="/test">
            <IconText icon={UsersIcon} iconProps={{ size: 22 }} text={t('CONTACTS')} textProps={{ style:{ marginLeft: 7 } }}/>
          </Link>
        </li>
           */
        }
        {stats_visible && <li data-title={t('STATISTICS')} className={`${active === 'stats' ? 'active' : ''}`}>
          <Link to="/stats">
            <IconText icon={TrendingUpIcon} iconProps={{ size: 22 }} text={t('STATISTICS')}
              textProps={{ style: { marginLeft: 7 } }}/>
          </Link>
        </li>}
      </ul>
    </div>
  );
}, (prevProps, nextProps) => prevProps.auth.user.dashboard_license === nextProps.auth.user.dashboard_license && prevProps.auth.user.reporting_license === nextProps.auth.user.reporting_license);
