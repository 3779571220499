import { RestRequest } from './rest';
import { ISharedInvoice, ISharedStatsReportWidget, Nullable } from 'atlas-shared/dist';
import { IGlobalStore } from '@Store';

export const download = (url: string, filename: string, onError?: (error) => void) => {
  return new Promise(resolve => {
    RestRequest
      .getFile(url)
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }, rejected => {
        onError?.(rejected?.message || rejected);
      })
      .finally(() => resolve(true))
    ;
  });
};

export const getBlob = (url: string, filename: string, onError?: (error) => void): Promise<Blob> => {
  return new Promise(resolve => {
    RestRequest
      .getFile(url)
      .then(blob => {
        resolve(blob);
      }, rejected => {
        onError?.(rejected?.message || rejected);
      })
    ;
  });
};

export const stream = (url: string, onError?: (error) => void): Promise<string> => {
  return new Promise(resolve => {
    RestRequest
      .getFile(url)
      .then(blob => {
        resolve(window.URL.createObjectURL(blob));
      }, rejected => {
        onError?.(rejected?.message || rejected);
      })
    ;
  });
};

export const screenshot = (url: string, filename: string, is_dashboard: boolean, theme: IGlobalStore['theme'], stats_report_widget_id: Nullable<ISharedStatsReportWidget['id']>, onError?: (error) => void) => {
  return new Promise(resolve => {
    RestRequest
      .getScreenshot(url, filename, is_dashboard, theme, stats_report_widget_id)
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }, rejected => {
        onError?.(rejected?.message || rejected);
      })
      .finally(() => resolve(true))
    ;
  });
};

export const invoicePdf = (id: ISharedInvoice['id'], filename: string, onError?: (error) => void) => {
  return new Promise(resolve => {
    RestRequest
      .getInvoicePdf(id, filename)
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }, rejected => {
        onError?.(rejected?.message || rejected);
      })
      .finally(() => resolve(true))
    ;
  });
};

export const csv = (url: string, filename: string, is_dashboard: boolean, theme: IGlobalStore['theme'], stats_report_widget_id: Nullable<ISharedStatsReportWidget['id']>, onError?: (error) => void) => {
  return new Promise(resolve => {
    RestRequest
      .getCsv(url, filename, is_dashboard, theme, stats_report_widget_id)
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }, rejected => {
        onError?.(rejected?.message || rejected);
      })
      .finally(() => resolve(true))
    ;
  });
};
