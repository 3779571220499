import { RestRequest } from '@Utils';
import { IPreviewTag, ISharedTag, ISharedCreateTag, ISharedPatchTag } from 'atlas-shared';

export const fetchTags = async (): Promise<Array<IPreviewTag>> => {
  return RestRequest.get<Array<IPreviewTag>>('tag', { socket_id: global.socketId });
};

export const fetchTag = async (id: IPreviewTag['id']): Promise<ISharedTag> => {
  return RestRequest.get<ISharedTag>(`tag/${id}`, { socket_id: global.socketId });
};

export const createTag = (payload: ISharedCreateTag) => {
  return RestRequest.post<any, ISharedTag>('tag', payload, { socket_id: global.socketId });
};

export const patchTag = (id: IPreviewTag['id'], payload: ISharedPatchTag) => {
  return RestRequest.patch<any, ISharedTag>(`tag/${id}`, payload, { socket_id: global.socketId });
};

export const deleteTag = (id: IPreviewTag['id']) => {
  return RestRequest.delete(`tag/${id}`, { socket_id: global.socketId });
};

export const getTagUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tag/update/schema');
};

export const getTagCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tag/create/schema');
};

export const getTagPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('tag/patch/schema');
};
