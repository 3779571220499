import { download, RestRequest } from '@Utils';
import { ISharedCannedAnswer } from 'atlas-shared';

export * from './abstract/canned_answer.api.abstract';

export const downloadCannedAnswerAttachment = (canned_answer_id: ISharedCannedAnswer['id'], index: number, filename: string, onError?: (error) => void) => {
  download(`canned_answer/${canned_answer_id}/attachment/${index}`, filename, onError);
};

export const deleteCannedAnswerAttachment = (canned_answer_id: ISharedCannedAnswer['id'], index: number) => {
  return RestRequest.delete<any>(`canned_answer/${canned_answer_id}/attachment/${index}`);
};
