import { IPreviewAdminprofile } from 'atlas-shared';
import { IAdminprofileStore, AdminprofileActions, TAdminprofileActionTypes } from '@Store';

export const AdminprofileReducer = (state: IAdminprofileStore = {
  loaded: false,
  loading: false,
  adminprofiles: [],
  dict: {}
}, action: TAdminprofileActionTypes): IAdminprofileStore => {
  switch (action.type) {
  case AdminprofileActions.LOADING:
    return { ...state, loading: true };
  case AdminprofileActions.SET_STATE:
    const dict: IAdminprofileStore['dict'] = {};

    action.payload.forEach((adminprofile: IPreviewAdminprofile) => dict[adminprofile.id] = adminprofile);

    return { ...state, loaded: true, loading: false, adminprofiles: action.payload, dict };
  case AdminprofileActions.ADD_STATE:
    return { ...state, adminprofiles: [...state.adminprofiles, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case AdminprofileActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, adminprofiles: state.adminprofiles.filter((item: IPreviewAdminprofile) => item.id !== action.payload), dict };
  }
  case AdminprofileActions.UPDATE_STATE:
    const adminprofiles = [...state.adminprofiles];
    const index = state.adminprofiles.findIndex((adminprofile: IPreviewAdminprofile) => adminprofile.id === action.payload.id);

    if (!~index)
      adminprofiles.push(action.payload);
    else
      adminprofiles[index] = action.payload;

    return { ...state, adminprofiles, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
