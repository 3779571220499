import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';

export * from './abstract/phonenumber_stock.list.column.abstract';
import { PhonenumberStockListColumns as PhonenumberStockListColumnsBase } from './abstract/phonenumber_stock.list.column.abstract';
import { IPreviewPhonenumberStock, TPhonenumberStockSortableListCol, AdminprofilePermissionIdEnum, CrudEnum, PermissionEnum, PhonenumberProviderEnum, PhonenumberStockConnectelCountries } from 'atlas-shared';
import { Icon } from '@Components';
import { DollarIcon } from '@Assets';
import { parsePhoneNumber } from 'libphonenumber-js';
import { queryParam } from '@Utils/url';

export const PhonenumberStockListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewPhonenumberStock) => React.ReactElement> = [], filters: IWithListProps<IPreviewPhonenumberStock, TPhonenumberStockSortableListCol>['filters'], sorter: IWithListProps<IPreviewPhonenumberStock, TPhonenumberStockSortableListCol>['sorter']): ColumnsType<IPreviewPhonenumberStock> => {
  return [
    ...PhonenumberStockListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
      (row: IPreviewPhonenumberStock) => abilities.can(null, CrudEnum.Insert, AdminprofilePermissionIdEnum.PhonenumberPurchase as unknown as PermissionEnum, row) ? <Icon icon={DollarIcon} tooltip={{ title: t('PURCHASE') }} iconProps={{ size: 14 }} onClick={() => { navigate(`/admin/phonenumber_purchase/add?iv=${JSON.stringify({
        provider: PhonenumberStockConnectelCountries.includes(parsePhoneNumber(row.number).country || '') ? PhonenumberProviderEnum.CONNECTEL : PhonenumberProviderEnum.VOXBONE,
        phonenumber_prefix_id: JSON.parse(queryParam('ff') as string).phonenumber_prefix_id[0],
        number: encodeURIComponent(row.number)
      })}`); }} /> : <></>,
      ...buttons
    ], filters, sorter)
  ];
};
