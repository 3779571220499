import { IPreviewUser } from 'atlas-shared/dist';
import { fromJson } from 'json-joi-converter';
import React from 'react';
import { Form } from 'antd';
import { AtlasForm, FormLayoutTypes } from 'atlas-form';
import { patchUser } from '@Api';
import { AlertError, InfoModal } from '@Utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  user_id: IPreviewUser['id'];
  onOk?: () => void;
  onCancel?: () => void;
}

export const PasswordResetForm = ({ user_id, onOk, onCancel }: IProps) => {

  const [antForm] = Form.useForm();
  const { t } = useTranslation();

  return <>
    <AtlasForm
      formLayout={{
        id: 'reset-password',
        ui_layout: FormLayoutTypes.HORIZONTAL
      }}
      form={fromJson({
        type: 'object',
        properties: {
          password: {
            type: 'string',
            required: true,
            min: 6,
            max: 255
          }
        }
      })}
      title={`${t('RESET_PASSWORD')}`}
      initialValues={{ password: '' }}
      additionalParams={{
        password: { labelWidth: '70px' }
      }}
      antForm={antForm}
      onFinish={(values) => {
        patchUser(user_id, {
          password: values.password
        })
          .then(() => {
            InfoModal(t, { content: t('PASSWORD_RESET_SUCCESSFUL') });
            onOk?.();
          })
          .catch(error => {
            AlertError(t, { content: t('SOMETHING_WENT_WRING') });
          });
      }}
      onCancel={onCancel}
    />
  </>;
};
