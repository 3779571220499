import { DestinationRestrictorActions } from '@Store';
import { IPreviewDestinationRestrictor } from 'atlas-shared';
import { fetchDestinationRestrictors } from '@Api';

export const actionFetchDestinationRestrictors = () => {
  return async (dispatch) => {
    dispatch(actionLoadingDestinationRestrictors());
    const destination_restrictors = await fetchDestinationRestrictors();

    dispatch(actionSetDestinationRestrictors(destination_restrictors));
    return destination_restrictors;
  };
};

export const actionLoadingDestinationRestrictors = () => ({
  type: DestinationRestrictorActions.LOADING,
  payload: []
});

export const actionSetDestinationRestrictors = (payload: Array<IPreviewDestinationRestrictor>) => ({
  type: DestinationRestrictorActions.SET_STATE,
  payload: payload
});

export const actionAddDestinationRestrictor = (payload: IPreviewDestinationRestrictor) => ({
  type: DestinationRestrictorActions.ADD_STATE,
  payload: payload
});

export const actionUpdateDestinationRestrictor = (payload: IPreviewDestinationRestrictor) => ({
  type: DestinationRestrictorActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveDestinationRestrictor = (id: IPreviewDestinationRestrictor['id']) => ({
  type: DestinationRestrictorActions.REMOVE_STATE,
  payload: id
});
