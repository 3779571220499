import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AgentprofileAddForm,
  AgentprofileAddInitialValues,
  AgentprofileComponents,
  AgentprofileAdditionalParams,
  AgentprofileFormOptions,
  onAgentprofileFormSaved,
  IAgentprofileFormOptionsProps,
  AlertError,
  queryParam
} from '@Utils';
import { AtlasForm, IFormOptions } from 'atlas-form';
import { CrudEnum, ISharedCreateAgentprofile, PermissionEnum } from 'atlas-shared';
import { createAgentprofile, fetchAgentprofile, getAgentprofileCreateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withAdd, IWithAddProps } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, usePauses, useQueues, useTeams, useVoiceShortcuts } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateAgentprofile> {}

const AgentprofileFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const organizations = useOrganizations();
  const pauses = usePauses();
  const queues = useQueues();
  const teams = useTeams();
  const voice_shortcuts = useVoiceShortcuts();
  const abilities = useAbilities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned] = useState<Partial<ISharedCreateAgentprofile>>();

  const param_props: IAgentprofileFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    pauses: pauses.pauses,
    queues: queues.queues,
    teams: teams.teams,
    voice_shortcuts: voice_shortcuts.voice_shortcuts
  };
  const formLayout = useMemo(() => AgentprofileAddForm(values, param_props, t), [values, organizations, pauses, queues, teams, voice_shortcuts, t, abilities]);
  const options: IFormOptions = useMemo(() => AgentprofileFormOptions(values || AgentprofileAddInitialValues, param_props, t), [values, organizations, pauses, queues, teams, voice_shortcuts, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateAgentprofile, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues]);
  const onFinish = useCallback((values: ISharedCreateAgentprofile) => save(() => createAgentprofile(values).then(
    res => onSaved ? onSaved(res, navigate) : onAgentprofileFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [save, navigate, t]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchAgentprofile(+id)
        .then(({
          organization_id,
          title,
          __pauses,
          __queues,
          global_search_queues,
          __teams,
          __voice_shortcuts,
          settings
        }) => {
          setCloned({
            organization_id,
            title,
            __pauses,
            __queues,
            global_search_queues,
            __teams,
            __voice_shortcuts,
            settings
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !pauses.loaded || !queues.loaded || !teams.loaded || !voice_shortcuts.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Agentprofile, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={AgentprofileComponents}
        additionalParams={AgentprofileAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...AgentprofileAddInitialValues, ...initialValues }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('AGENTPROFILE')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const AgentprofileFormAddView = React.memo(withAdd(AgentprofileFormAdd, getAgentprofileCreateSchema, AgentprofileAddInitialValues));
