import { ConversationActions } from '@Store';
import { IPreviewConversation } from 'atlas-shared';

export const actionLoadingConversations = () => ({
  type: ConversationActions.LOADING,
  payload: []
});

export const actionSetConversations = (payload: Array<IPreviewConversation>) => ({
  type: ConversationActions.SET_STATE,
  payload: payload
});

export const actionAddConversation = (payload: IPreviewConversation) => ({
  type: ConversationActions.ADD_STATE,
  payload: payload
});

export const actionUpdateConversation = (payload: IPreviewConversation) => ({
  type: ConversationActions.UPDATE_STATE,
  payload: payload
});
