import { UserStatusActions } from '@Store';
import { IPreviewUserStatus } from 'atlas-shared';
import { fetchUserStatuses } from '@Api';

export const actionFetchUserStatuses = () => {
  return async (dispatch) => {
    dispatch(actionLoadingUserStatuses());
    const user_statuses = await fetchUserStatuses();

    dispatch(actionSetUserStatuses(user_statuses));
    return user_statuses;
  };
};

export const actionLoadingUserStatuses = () => ({
  type: UserStatusActions.LOADING,
  payload: []
});

export const actionSetUserStatuses = (payload: Array<IPreviewUserStatus>) => ({
  type: UserStatusActions.SET_STATE,
  payload: payload
});

export const actionAddUserStatus = (payload: IPreviewUserStatus) => ({
  type: UserStatusActions.ADD_STATE,
  payload: payload
});

export const actionUpdateUserStatus = (payload: IPreviewUserStatus) => ({
  type: UserStatusActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveUserStatus = (id: IPreviewUserStatus['id']) => ({
  type: UserStatusActions.REMOVE_STATE,
  payload: id
});
