import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateVoiceShortcut, ISharedUpdateVoiceShortcut, ISharedVoiceShortcut, IPreviewOrganization, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IVoiceShortcutFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const VoiceShortcutFormOptions = (voice_shortcut: ISharedCreateVoiceShortcut | ISharedVoiceShortcut, props: IVoiceShortcutFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.VoiceShortcut, {})), 'organization_id', false), 'organization'),
  
});

export const VoiceShortcutComponents: IForm['components'] = {};
export const VoiceShortcutAdditionalParams: (auth: IAuth, props: IVoiceShortcutFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceShortcutFormOptionsProps) => ({
});

export const VoiceShortcutEditForm = (voice_shortcut: ISharedVoiceShortcut, props: IVoiceShortcutFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_shortcut_edit_${voice_shortcut.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === voice_shortcut.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'number' }
  ]
});

export const VoiceShortcutAddForm = (voice_shortcut: ISharedCreateVoiceShortcut, props: IVoiceShortcutFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'voice_shortcut_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'number' }
  ]
});

export const VoiceShortcutAddInitialValues: ISharedCreateVoiceShortcut = {} as ISharedCreateVoiceShortcut;

export const VoiceShortcutEditInitialValues = (voice_shortcut: ISharedVoiceShortcut): ISharedUpdateVoiceShortcut => ({
  title: voice_shortcut.title,
  number: voice_shortcut.number
});

export const onVoiceShortcutFormSaved = (t: TFunction, action: 'edit' | 'add', voice_shortcut: ISharedVoiceShortcut, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_shortcuts'));
};
