import React from 'react';
import { ellipsis, preventDefault } from '@Utils';
import { ChannelIcon } from '@Components';
import { NavigateFunction } from 'react-router';
import { IElasticPreviewConversation, IElasticSearchResultItem } from 'atlas-shared';

interface IProps {
  keyword: string;
  item: IElasticSearchResultItem<IElasticPreviewConversation>;
  navigate: NavigateFunction;
}

export const HeaderSearchResultsConversation = React.memo(({ item, keyword, navigate }: IProps) => {
  const { item: conversation } = item;

  return <li key={conversation.id} onClick={preventDefault(() => {
    navigate(`/dashboard/search/${conversation.organization_id}/${conversation.id}?keyword=${keyword || ''}`);
  })}>
    <div className={'channel-wrp'} >
      { conversation.main_channel && <ChannelIcon channel={{ c: conversation.main_channel, d: conversation.direction }} size={14} padding={7} /> }
    </div>
    <div className='result-text'>
      <div className='result-row'>
        <span>
          {ellipsis(conversation.title, 90)}
        </span>
        <strong style={{ opacity: .5 }}>
            #{conversation.id}
        </strong>
      </div>
    </div>
  </li>;
});
