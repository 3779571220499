import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  ListEditForm,
  ListEditInitialValues,
  ListComponents,
  ListAdditionalParams,
  ListFormOptions,
  onListFormSaved,
  IListFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedList, ISharedPatchList, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchList, patchList, getListUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useForms } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedList> {}

const ListFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const forms = useForms();

  useEffect(() => {
    fetchList(id).then(list => setValue(list));
  }, [id, setValue]);

  const param_props: IListFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    forms: forms.forms
  };
  const formLayout: IForm | undefined = useMemo(() => value && ListEditForm(value, param_props, t), [value, organizations, forms, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && ListFormOptions(value, param_props, t), [value, organizations, forms, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchList, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchList) => value && save(() => patchList(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onListFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !forms.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.List, value)}
    >
      <AtlasForm<ISharedPatchList>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={ListComponents}
        additionalParams={ListAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={ListEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('LIST')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const ListFormEditView = React.memo(withEdit(ListFormEdit, getListUpdateSchema));
