import { FormElementTypes, IForm } from 'atlas-form';
import { CannedAnswerTypeEnum, ISharedCannedAnswer, ISharedUpdateCannedAnswer } from 'atlas-shared';

export * from './abstract/canned_answer.form.abstract';
import {
  CannedAnswerAdditionalParams as CannedAnswerAdditionalParamsBase,
  ICannedAnswerFormOptionsProps
} from './abstract/canned_answer.form.abstract';
import { IAuth } from '@Store';
import { RestRequest } from '@Utils/rest';
import { downloadCannedAnswerAttachment, deleteCannedAnswerAttachment } from '@Api';

export const CannedAnswerAdditionalParams: (auth: IAuth, props: ICannedAnswerFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ICannedAnswerFormOptionsProps) => ({
  ...CannedAnswerAdditionalParamsBase(auth, props),
  body: (it: ISharedUpdateCannedAnswer, b, c, d, canned_answer: ISharedCannedAnswer) => ({
    ui_type: FormElementTypes.HTML,
    atOptions: ['user'],
    hashOptions: it.type === CannedAnswerTypeEnum.Agent ? ['conversation', 'contact', 'agent', 'field'] : ['conversation', 'contact', 'field'],
    file_upload: canned_answer ? {
      attachments: canned_answer.attachments || [],
      action: RestRequest.getFullSrc(`/canned_answer/${canned_answer.id}/attachment`),
      headers: RestRequest.getHeaders(),
      onDelete: (uid: number) => deleteCannedAnswerAttachment(canned_answer.id, uid),
      onDownload: (uid: number, name: string) => downloadCannedAnswerAttachment(canned_answer.id, uid, name),
      onDownloadUri: (index: number) => `canned_answer/${canned_answer.id}/attachment/${index}`,
      static: true
    } : undefined
  })
});
