import { RestRequest, download, stream } from '@Utils';
import { IPreviewSound, ISharedSound, ISharedCreateSound, ISharedPatchSound } from 'atlas-shared';

export const fetchSounds = async (): Promise<Array<IPreviewSound>> => {
  return RestRequest.get<Array<IPreviewSound>>('sound', { socket_id: global.socketId });
};

export const fetchSound = async (id: IPreviewSound['id']): Promise<ISharedSound> => {
  return RestRequest.get<ISharedSound>(`sound/${id}`, { socket_id: global.socketId });
};

export const createSound = (payload: ISharedCreateSound) => {
  return RestRequest.post<any, ISharedSound>('sound', payload, { socket_id: global.socketId });
};

export const patchSound = (id: IPreviewSound['id'], payload: ISharedPatchSound) => {
  return RestRequest.patch<any, ISharedSound>(`sound/${id}`, payload, { socket_id: global.socketId });
};

export const deleteSound = (id: IPreviewSound['id']) => {
  return RestRequest.delete(`sound/${id}`, { socket_id: global.socketId });
};

export const getSoundUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('sound/update/schema');
};

export const getSoundCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('sound/create/schema');
};

export const getSoundPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('sound/patch/schema');
};

export const downloadSoundFilename = (id: IPreviewSound['id'], filename: string, onError?: (error) => void) => {
  download(`sound/${id}/filename`, filename, onError);
};

export const streamSoundFilename = (id: IPreviewSound['id'], onError?: (error) => void) => {
  return stream(`sound/${id}/filename`, onError);
};
