import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  ApiKeyEditForm,
  ApiKeyEditInitialValues,
  ApiKeyComponents,
  ApiKeyAdditionalParams,
  ApiKeyFormOptions,
  onApiKeyFormSaved,
  IApiKeyFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedApiKey, ISharedPatchApiKey, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchApiKey, patchApiKey, getApiKeyUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useUsers } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedApiKey> {}

const ApiKeyFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const users = useUsers();

  useEffect(() => {
    fetchApiKey(id).then(api_key => setValue(api_key));
  }, [id, setValue]);

  const param_props: IApiKeyFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    users: users.users
  };
  const formLayout: IForm | undefined = useMemo(() => value && ApiKeyEditForm(value, param_props, t), [value, organizations, users, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && ApiKeyFormOptions(value, param_props, t), [value, organizations, users, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchApiKey, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchApiKey) => value && save(() => patchApiKey(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onApiKeyFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !users.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.ApiKey, value)}
    >
      <AtlasForm<ISharedPatchApiKey>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={ApiKeyComponents}
        additionalParams={ApiKeyAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={ApiKeyEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('API_KEY')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const ApiKeyFormEditView = React.memo(withEdit(ApiKeyFormEdit, getApiKeyUpdateSchema));
