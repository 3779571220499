import { IPreviewVoiceShortcut } from 'atlas-shared';
import { IVoiceShortcutStore, VoiceShortcutActions, TVoiceShortcutActionTypes } from '@Store';

export const VoiceShortcutReducer = (state: IVoiceShortcutStore = {
  loaded: false,
  loading: false,
  voice_shortcuts: [],
  dict: {}
}, action: TVoiceShortcutActionTypes): IVoiceShortcutStore => {
  switch (action.type) {
  case VoiceShortcutActions.LOADING:
    return { ...state, loading: true };
  case VoiceShortcutActions.SET_STATE:
    const dict: IVoiceShortcutStore['dict'] = {};

    action.payload.forEach((voice_shortcut: IPreviewVoiceShortcut) => dict[voice_shortcut.id] = voice_shortcut);

    return { ...state, loaded: true, loading: false, voice_shortcuts: action.payload, dict };
  case VoiceShortcutActions.ADD_STATE:
    return { ...state, voice_shortcuts: [...state.voice_shortcuts, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceShortcutActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_shortcuts: state.voice_shortcuts.filter((item: IPreviewVoiceShortcut) => item.id !== action.payload), dict };
  }
  case VoiceShortcutActions.UPDATE_STATE:
    const voice_shortcuts = [...state.voice_shortcuts];
    const index = state.voice_shortcuts.findIndex((voice_shortcut: IPreviewVoiceShortcut) => voice_shortcut.id === action.payload.id);

    if (!~index)
      voice_shortcuts.push(action.payload);
    else
      voice_shortcuts[index] = action.payload;

    return { ...state, voice_shortcuts, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
