import React, { useCallback, useEffect, useState } from 'react';
import { IGlobalPopupModal } from '@Store';

interface IProps {
  popup_modal: IGlobalPopupModal;
  onClose: (key: IGlobalPopupModal['key']) => void;
}

export const PopupModal = React.memo(({ popup_modal: { key, path, component, props = {}, onFormChangePayload, onFormChange, formInitialValues }, onClose }: IProps) => {
  const [Component, setComponent] = useState<any>();

  useEffect(() => {
    if (path)
      import('@Components/page-views').then(Source => {
        setComponent(Source?.[component]);
      });
  }, []);
  
  const _onClose = useCallback(() => {
    props?.onClose?.();
    onClose(key);
  }, []);
  
  const onSaved = useCallback((payload) => {
    props.onSaved?.(payload);
    if (onFormChangePayload && onFormChange) {
      setTimeout(() => {
        onFormChange(onFormChangePayload(payload));
      }, 200);
    }

    onClose(key);
  }, []);

  if (!Component)
    return <div>Component {component} not found in {path}</div>;

  return <div className='popup-modal' id={`popup-modal-${key}`}>
    <div className='popup-modal-inner'>
      <div className='popup-modal-close' onClick={() => _onClose()}>X</div>
      <Component {...props} onClose={_onClose} onSaved={onSaved} onCancel={_onClose} initialValues={formInitialValues} />
    </div>
  </div>;
});
