import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  StatsReportEditForm,
  StatsReportEditInitialValues,
  StatsReportComponents,
  StatsReportAdditionalParams,
  StatsReportFormOptions,
  onStatsReportFormSaved,
  IStatsReportFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedStatsReport, ISharedPatchStatsReport, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchStatsReport, patchStatsReport, getStatsReportUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useUsers, useTeams, useAgentprofiles } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedStatsReport> {}

const StatsReportFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const users = useUsers();
  const teams = useTeams();
  const agentprofiles = useAgentprofiles();

  useEffect(() => {
    fetchStatsReport(id).then(stats_report => setValue(stats_report));
  }, [id, setValue]);

  const param_props: IStatsReportFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    users: users.users,
    teams: teams.teams,
    agentprofiles: agentprofiles.agentprofiles
  };
  const formLayout: IForm | undefined = useMemo(() => value && StatsReportEditForm(value, param_props, t), [value, organizations, users, teams, agentprofiles, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && StatsReportFormOptions(value, param_props, t), [value, organizations, users, teams, agentprofiles, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchStatsReport, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchStatsReport) => value && save(() => patchStatsReport(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onStatsReportFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !users.loaded || !teams.loaded || !agentprofiles.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.StatsReport, value)}
    >
      <AtlasForm<ISharedPatchStatsReport>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={StatsReportComponents}
        additionalParams={StatsReportAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={StatsReportEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('STATS_REPORT')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const StatsReportFormEditView = React.memo(withEdit(StatsReportFormEdit, getStatsReportUpdateSchema));
