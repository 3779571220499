import React, { useState, useEffect } from 'react';
import { getUnixTime } from 'atlas-shared/dist';

interface ICountUpTimerProps {
  since: number;
}

export const Counter = React.memo(({ since }: ICountUpTimerProps) => {
  let _diff = getUnixTime() - since;
  const [display, setDisplay] = useState<string>(`${_diff / 24 / 60 / 60 > 1 ? '0d' : ''} ${_diff / 60 / 60 > 1 ? '00:' : ''}00:00`);

  useEffect(() => {
    const interval = setInterval(() => {
      let display: Array<string> = [];
      let _diff = getUnixTime() - since;
      
      if (_diff) {
        const days = Math.floor(_diff / 24 / 60 / 60);

        _diff -= days * 24 * 60 * 60;
        const hours = Math.floor(_diff / 60 / 60);

        _diff -= hours * 60 * 60;
        const minutes = Math.floor(_diff / 60);

        _diff -= minutes * 60;
        const seconds = _diff;

        if (days)
          display.push(`${days}d `);
        
        if (days || hours)
          display.push(`${hours < 10 ? '0' : ''}${hours}:`);

        display.push(`${minutes < 10 ? '0' : ''}${minutes}:`);
        display.push(`${seconds < 10 ? '0' : ''}${seconds}`);
      }
      else
        return;

      setDisplay(display.join(''));
    }, 1000);
    
    return () => {
      clearInterval(interval);
    };
  }, [since]);

  return <>{display}</>;
});
