import { IPreviewSuperadminProfile } from 'atlas-shared';
import { ISuperadminProfileStore, SuperadminProfileActions, TSuperadminProfileActionTypes } from '@Store';

export const SuperadminProfileReducer = (state: ISuperadminProfileStore = {
  loaded: false,
  loading: false,
  superadmin_profiles: [],
  dict: {}
}, action: TSuperadminProfileActionTypes): ISuperadminProfileStore => {
  switch (action.type) {
  case SuperadminProfileActions.LOADING:
    return { ...state, loading: true };
  case SuperadminProfileActions.SET_STATE:
    const dict: ISuperadminProfileStore['dict'] = {};

    action.payload.forEach((superadmin_profile: IPreviewSuperadminProfile) => dict[superadmin_profile.id] = superadmin_profile);

    return { ...state, loaded: true, loading: false, superadmin_profiles: action.payload, dict };
  case SuperadminProfileActions.ADD_STATE:
    return { ...state, superadmin_profiles: [...state.superadmin_profiles, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case SuperadminProfileActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, superadmin_profiles: state.superadmin_profiles.filter((item: IPreviewSuperadminProfile) => item.id !== action.payload), dict };
  }
  case SuperadminProfileActions.UPDATE_STATE:
    const superadmin_profiles = [...state.superadmin_profiles];
    const index = state.superadmin_profiles.findIndex((superadmin_profile: IPreviewSuperadminProfile) => superadmin_profile.id === action.payload.id);

    if (!~index)
      superadmin_profiles.push(action.payload);
    else
      superadmin_profiles[index] = action.payload;

    return { ...state, superadmin_profiles, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
