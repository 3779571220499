import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreatePrepaidCredit, ISharedUpdatePrepaidCredit, ISharedPrepaidCredit, IPreviewOrganization, EnumOptions, InvoiceCurrencyEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IPrepaidCreditFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const PrepaidCreditFormOptions = (prepaid_credit: ISharedCreatePrepaidCredit | ISharedPrepaidCredit, props: IPrepaidCreditFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.PrepaidCredit, {})), 'organization_id', false), 'organization'),
  currency: EnumOptions('INVOICE_CURRENCY', InvoiceCurrencyEnum, t),
});

export const PrepaidCreditComponents: IForm['components'] = {};
export const PrepaidCreditAdditionalParams: (auth: IAuth, props: IPrepaidCreditFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPrepaidCreditFormOptionsProps) => ({
});

export const PrepaidCreditEditForm = (prepaid_credit: ISharedPrepaidCredit, props: IPrepaidCreditFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `prepaid_credit_edit_${prepaid_credit.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === prepaid_credit.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'currency' },
    { ref: 'amount' },
    { ref: 'valid_from' },
    { ref: 'valid_until' }
  ]
});

export const PrepaidCreditAddForm = (prepaid_credit: ISharedCreatePrepaidCredit, props: IPrepaidCreditFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'prepaid_credit_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'currency' },
    { ref: 'amount' },
    { ref: 'valid_from' },
    { ref: 'valid_until' }
  ]
});

export const PrepaidCreditAddInitialValues: ISharedCreatePrepaidCredit = {} as ISharedCreatePrepaidCredit;

export const PrepaidCreditEditInitialValues = (prepaid_credit: ISharedPrepaidCredit): ISharedUpdatePrepaidCredit => ({
  currency: prepaid_credit.currency,
  amount: prepaid_credit.amount,
  valid_from: prepaid_credit.valid_from,
  valid_until: prepaid_credit.valid_until
});

export const onPrepaidCreditFormSaved = (t: TFunction, action: 'edit' | 'add', prepaid_credit: ISharedPrepaidCredit, navigate: NavigateFunction): void => {
  navigate(onFormSaved('prepaid_credits'));
};
