import { RestRequest } from '@Utils';
import { IPreviewSkill, ISharedSkill, ISharedCreateSkill, ISharedPatchSkill } from 'atlas-shared';

export const fetchSkills = async (): Promise<Array<IPreviewSkill>> => {
  return RestRequest.get<Array<IPreviewSkill>>('skill', { socket_id: global.socketId });
};

export const fetchSkill = async (id: IPreviewSkill['id']): Promise<ISharedSkill> => {
  return RestRequest.get<ISharedSkill>(`skill/${id}`, { socket_id: global.socketId });
};

export const createSkill = (payload: ISharedCreateSkill) => {
  return RestRequest.post<any, ISharedSkill>('skill', payload, { socket_id: global.socketId });
};

export const patchSkill = (id: IPreviewSkill['id'], payload: ISharedPatchSkill) => {
  return RestRequest.patch<any, ISharedSkill>(`skill/${id}`, payload, { socket_id: global.socketId });
};

export const deleteSkill = (id: IPreviewSkill['id']) => {
  return RestRequest.delete(`skill/${id}`, { socket_id: global.socketId });
};

export const getSkillUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('skill/update/schema');
};

export const getSkillCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('skill/create/schema');
};

export const getSkillPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('skill/patch/schema');
};
