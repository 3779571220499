import React, { useEffect, useState } from 'react';
import { FormItemClassNames, FormItemProps, getFormValueByElementType, IFormItemsInputProps } from './utils';
import {
  IFormAdditionalParams,
  IFormElementArray,
  IFormElementObject,
  IFormElements
} from '../atlas-form-core/Interfaces';
import FormSection from './form.section';
import { FormItem } from './form.item';
import { Icon } from '@Components';
import { MoveIcon, PlusIcon, TrashIcon } from '@Assets/icons';
import { Button, Form } from 'antd';
import { useSort } from '@Hooks/use.sort';
import { setPreventDefault } from '@Utils';

const getAddValue = (item: IFormElementArray): any => {
  if (item.items?.[0]) {
    if ((item.items[0] as IFormElementArray).element?.hasOwnProperty('default'))
      return (item.items[0] as IFormElementArray)?.element?.default;
    else if ((item.items[0] as IFormElementArray)?.element?.hasOwnProperty('type')) {
      return getFormValueByElementType((item.items?.[0] as IFormElementArray)?.element?.type);
    }
  }

  if (item.map_items?.elements)
    return item.map_items?.default || {};

  return '';
};

interface IProps extends IFormItemsInputProps {
  item: IFormElementArray;
  name: Array<string | number>; 
  value: Array<any>;
  list_delimiter: IFormAdditionalParams['list_delimiter'];
  disabled?: boolean;
}

export const FormList = React.memo((props: IProps) => {

  const { item, name, value, disabled = false, ...rest } = props;
  const [oldValue, setOldValue] = useState<Array<any>>(value);
  const [listKey, setListKey] = useState<number>(Math.random());
  const { onDragStart, onDragEnd, onDragOver, canDrop } = useSort({
    dropElement: <div className="drop-element">{props.t('DROP_HERE')}</div>,
    value,
    onChange: (new_order) => {
      props.json_form.setValueByPath(props.form.path, new_order);
      setListKey(Math.random());
    }
  });

  const { addItems = true, sortable = true, group_labels = true } = item as IFormElementArray;

  useEffect(() => {
    if ((oldValue || []).length > (value || []).length)
      setListKey(Math.random());

    setOldValue(value);
  }, [value, oldValue]);

  return (
    <div
      onDrop={setPreventDefault}
      onDragOver={setPreventDefault}
      className={`form-list-wrapper form-list-wrapper-${item.ui_layout}`}
    >
      {(item.items || []).map((child_elm: IFormElements, index) => {

        /**
         * Key may not relate to index as for child deletion
         */

        return (
          <React.Fragment key={`${props?.form?.path}_${listKey}_${index}`}>
            {canDrop(index, true) }
            <div
              className={`ant-form-list-item ${FormItemClassNames([...props.path, ...name])} ${(child_elm as IFormElementObject).elements?.length ? 'ant-form-list-item-section' : 'ant-form-list-item-simple'}${group_labels ? ' ant-form-list-group-labels' : ''}`}
              style={item.element_style}
              onDragOver={() => onDragOver(index)}
            >
              {index > 0 && props.list_delimiter && <div className='list-delimiter' title={props.list_delimiter.title && props.t(props.list_delimiter.title)}>{props.list_delimiter.delimiter}</div>}
              <div className='ant-form-list-item-form'>
                {(child_elm as IFormElementObject).elements?.length && <FormSection
                  {...rest}
                  form={(child_elm as IFormElementObject) as IFormElementObject}
                  name={[index]}
                  path={[...props.path, ...name]}
                />}
                {(child_elm as IFormElementArray).element && <FormItem
                  {...FormItemProps({
                    ...rest,
                    name: [index],
                    path: [...props.path, ...name],
                    form: { ...(child_elm as IFormElementArray).element, hideLabel: true }
                  })}
                />}
              </div>
              <div className='ant-form-list-item-form-handles'>
                {!disabled && <div className='ant-form-list-item-form-remove-handle' title={props.t('REMOVE_ITEM')}>
                  <Icon icon={TrashIcon} onClick={() => props.json_form.dropValue(props.form.path, index)} iconProps={{ size: 14 }}/>
                </div>}
                {!disabled && sortable && <div className='ant-form-list-item-form-sort-handle' title={props.t('DRAG_n_MOVE')} draggable={true} onDragStart={e => onDragStart(e, index)} onDragEnd={onDragEnd}>
                  <Icon icon={MoveIcon} iconProps={{ size: 14 }} />
                </div>}
              </div>

            </div>
            {canDrop(index, false) }
          </React.Fragment>
        );
      })}
      {addItems && <Form.Item className='ant-form-row-add'>
        <Button
          onClick={() => props.json_form.pushValue(props.form.path, getAddValue(item as IFormElementArray))}
          icon={<Icon icon={PlusIcon} />}
          disabled={disabled}
        >
          {props.t((item as IFormElementArray).addText || 'ADD_ITEM')}
        </Button>
      </Form.Item>}
    </div>
  );
});
