import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const GridIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <rect x="3" y="3" width="7" height="7"></rect>
    <rect x="14" y="3" width="7" height="7"></rect>
    <rect x="14" y="14" width="7" height="7"></rect>
    <rect x="3" y="14" width="7" height="7"></rect>
  </SvgIcon>;
};
