import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  AdminprofileEditForm,
  AdminprofileEditInitialValues,
  AdminprofileComponents,
  AdminprofileAdditionalParams,
  AdminprofileFormOptions,
  onAdminprofileFormSaved,
  IAdminprofileFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedAdminprofile, ISharedPatchAdminprofile, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchAdminprofile, patchAdminprofile, getAdminprofileUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, usePauses, useUsers, useAdminprofiles, useAgentprofiles, useCannedAnswers, useDispositions, useForms, useFields, useJourneies, useLists, useMailAccounts, useQueues, useOperationalHourses, useSignatures, useSlaPolicies, useTags, useTeams, useTimeIntervals, useVoiceShortcuts, useVoiceVoicemails, useDestinationRestrictors, useInvoices, useClients, useSkills } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedAdminprofile> {}

const AdminprofileFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const pauses = usePauses();
  const users = useUsers();
  const adminprofiles = useAdminprofiles();
  const agentprofiles = useAgentprofiles();
  const canned_answers = useCannedAnswers();
  const dispositions = useDispositions();
  const forms = useForms();
  const fields = useFields();
  const journeies = useJourneies();
  const lists = useLists();
  const mail_accounts = useMailAccounts();
  const queues = useQueues();
  const operational_hourses = useOperationalHourses();
  const signatures = useSignatures();
  const sla_policies = useSlaPolicies();
  const tags = useTags();
  const teams = useTeams();
  const time_intervals = useTimeIntervals();
  const voice_shortcuts = useVoiceShortcuts();
  const voice_voicemails = useVoiceVoicemails();
  const destination_restrictors = useDestinationRestrictors();
  const invoices = useInvoices();
  const clients = useClients();
  const skills = useSkills();

  useEffect(() => {
    fetchAdminprofile(id).then(adminprofile => setValue(adminprofile));
  }, [id, setValue]);

  const param_props: IAdminprofileFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    pauses: pauses.pauses,
    users: users.users,
    adminprofiles: adminprofiles.adminprofiles,
    agentprofiles: agentprofiles.agentprofiles,
    canned_answers: canned_answers.canned_answers,
    dispositions: dispositions.dispositions,
    forms: forms.forms,
    fields: fields.fields,
    journeies: journeies.journeies,
    lists: lists.lists,
    mail_accounts: mail_accounts.mail_accounts,
    queues: queues.queues,
    operational_hourses: operational_hourses.operational_hourses,
    signatures: signatures.signatures,
    sla_policies: sla_policies.sla_policies,
    tags: tags.tags,
    teams: teams.teams,
    time_intervals: time_intervals.time_intervals,
    voice_shortcuts: voice_shortcuts.voice_shortcuts,
    voice_voicemails: voice_voicemails.voice_voicemails,
    destination_restrictors: destination_restrictors.destination_restrictors,
    invoices: invoices.invoices,
    clients: clients.clients,
    skills: skills.skills
  };
  const formLayout: IForm | undefined = useMemo(() => value && AdminprofileEditForm(value, param_props, t), [value, organizations, pauses, users, adminprofiles, agentprofiles, canned_answers, dispositions, forms, fields, journeies, lists, mail_accounts, queues, operational_hourses, signatures, sla_policies, tags, teams, time_intervals, voice_shortcuts, voice_voicemails, destination_restrictors, invoices, clients, skills, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && AdminprofileFormOptions(value, param_props, t), [value, organizations, pauses, users, adminprofiles, agentprofiles, canned_answers, dispositions, forms, fields, journeies, lists, mail_accounts, queues, operational_hourses, signatures, sla_policies, tags, teams, time_intervals, voice_shortcuts, voice_voicemails, destination_restrictors, invoices, clients, skills, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchAdminprofile, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchAdminprofile) => value && save(() => patchAdminprofile(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onAdminprofileFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !pauses.loaded || !users.loaded || !adminprofiles.loaded || !agentprofiles.loaded || !canned_answers.loaded || !dispositions.loaded || !forms.loaded || !fields.loaded || !journeies.loaded || !lists.loaded || !mail_accounts.loaded || !queues.loaded || !operational_hourses.loaded || !signatures.loaded || !sla_policies.loaded || !tags.loaded || !teams.loaded || !time_intervals.loaded || !voice_shortcuts.loaded || !voice_voicemails.loaded || !destination_restrictors.loaded || !invoices.loaded || !clients.loaded || !skills.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.Adminprofile, value)}
    >
      <AtlasForm<ISharedPatchAdminprofile>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={AdminprofileComponents}
        additionalParams={AdminprofileAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={AdminprofileEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('ADMINPROFILE')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const AdminprofileFormEditView = React.memo(withEdit(AdminprofileFormEdit, getAdminprofileUpdateSchema));
