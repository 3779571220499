import React, { ReactElement } from 'react';
import { AnySchema } from 'json-joi-converter';
import { FormProps, ModalProps, PopoverProps, SelectProps, UploadProps } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { IPreviewCannedAnswer, Undefinable } from 'atlas-shared';
import { IOption } from '@Utils';
import { JSONForm } from '../index';
import { TFunction } from 'i18next';
import { IGlobalPopupModal } from '@Store';
import { IFormItemsProps } from '@Modules/atlas-form-react/utils';
import { IQuillFileUpload } from '@Components/editor';

export type Nullable<T> = T | null;

export type TypeFormComponent = (antForm: FormInstance, name: string | Array<string | number>, value: any, onValuesChange?: FormProps['onValuesChange']) => React.ReactElement;

export type IElements = Array<IFormElements>;

export type IFormElements =
  | IFormElementUnknown
  | IFormElementInput
  | IFormElementNumber
  | IFormElementSelect
  | IFormElementObject
  | IFormElementTime
  | IFormElementDate
  | IFormElementDatetime
  | IFormElementCustom
  | IFormElementTypeahead
  | IFormElementArray
  | IFormElementList
  | IFormElementText
  | IFormElementTree
  | IFormElementFile
  ;
//interface IFormElements {ref: string;}

export enum FormElementTypes {
  INPUT = 'input',
  PASSWORD = 'password',
  FILE = 'file',
  NUMBER = 'number',
  SELECT = 'select',
  TREE = 'tree',
  TYPEAHEAD = 'typeahead',
  LIST = 'list',
  SECTION = 'section',
  TIME = 'time',
  DATE = 'date',
  DATETIME = 'datetime',
  SWITCH = 'switch',
  RADIO = 'radio',
  COLOR = 'color',
  CHECKBOXES = 'checkboxes',
  CHECKBOX = 'checkbox',
  CUSTOM = 'custom',
  TEXT = 'text',
  HTML = 'html',
  PREVIEW = 'preview'
}

export enum FormLayoutTypes {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  INLINE = 'inline',
}

export enum FormSizeTypes {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

interface IFormStar {
  key: string;
  title: string;
}

export interface IFormElement {
  ref?: string;
  ui_type?: FormElementTypes;
  type?: AnySchema['type'];
  label?: IFormItemsProps['label'];
  labelRenderer?: IFormItemsProps['labelRenderer'];
  labelWidth?: string;
  width?: number;
  component?: string | IFormComponent; // key to render form element with custom frontend component
  help?: string; // internal note
  star?: IFormStar['key'] | Array<IFormStar['key']>;
  default?: any; // default value
  default_un_null?: any; // default un null value
  required?: boolean;
  nullable?: boolean;
  disableable?: boolean; // when not required
  disable_value?: any; // when disableable = true
  placeholder?: string;
  // elements?: elements;
  items?: Array<IFormElements>;
  stars?: Array<IFormStar>;
  path?: string;
  errors?: Array<any>;
  min?: number;
  max?: number;
  hidden?: boolean;
  valid?: Array<any>;
  hideLabel?: boolean;
  noPadding?: boolean;
  compact?: boolean;
  postFixText?: string;
  postFixTextNull?: string;
  preFixText?: string;
  preFixTextNull?: string;
  row_style?: React.CSSProperties;
  element_style?: React.CSSProperties;
  sortable?: boolean;
  shouldUpdate?: (pre: any, next: any) => boolean;
  hideHelp?: boolean;
  data_type?: 'phone';
  preview_value?: string | number;
  advanced?: boolean;
  precision?: number;
  disabled?: boolean;
}

export interface IFormAdditionalParams {
  ui_type?: FormElementTypes;
  ui_layout?: FormLayoutTypes;
  label?: IFormItemsProps['label'];
  labelRenderer?: IFormItemsProps['labelRenderer'];
  help?: string;
  hideLabel?: boolean;
  hidden?: boolean;
  noPadding?: boolean;
  compact?: boolean;
  labelWidth?: IFormElement['labelWidth'];
  width?: IFormElement['width'];
  addText?: string;
  group_labels? : boolean; // list - default true
  postFixText?: IFormElement['postFixText'];
  postFixTextNull?: IFormElement['postFixTextNull'];
  preFixText?: IFormElement['preFixText'];
  preFixTextNull?: IFormElement['preFixTextNull'];
  nullable?: boolean;
  hashOptions?: IFormElementHtml['hashOptions'];
  atOptions?: IFormElementHtml['atOptions'];
  canned_answers?: IFormElementHtml['canned_answers'];
  row_style?: IFormElement['row_style'];
  element_style?: IFormElement['element_style'];
  onSearch?: IFormElementSelect['onSearch'];
  mode?: IFormElementTypeahead['mode'];
  sortable?: boolean;
  shouldUpdate?: IForm['shouldUpdate'];
  uploadProps?: IFormElementFile['uploadProps'];
  hideHelp?: IFormElement['hideHelp'];
  default_un_null?: IFormElement['default_un_null'];
  onAdd?: IFormElementTypeahead['onAdd'];
  onModalAdd?: IFormElementTypeahead['onModalAdd'];
  legend?: IFormElementObject['legend'];
  advanced?: boolean;
  list_delimiter?: IFormElementList['list_delimiter'];
  child_params? : IFormElements;
  disabled?: IForm['disabled'];
  file_upload?: Undefinable<IQuillFileUpload>;
  tagsOptionValidation?: IFormElementTypeahead['tagsOptionValidation'];
  keep_default_sorting?: IFormElementSelect['keep_default_sorting'];
}
export type TypeFormOptionFunction = (base: any, parent: any, _self: any, parent_parent: any, entity: any, json_form: JSONForm) => Nullable<Array<IOption>>;
export type TypeFormAdditionalParamsFunction = (base: any, parent: any, _self: any, parent_parent: any, entity: any, json_form: JSONForm) => IFormAdditionalParams;
export type TypeFormOption = Array<IOption> | TypeFormOptionFunction;
export type IFormOptions = Record<string, TypeFormOption>;
export type TypeFormPreviewFunction = (base: any, parent: any, _self: any, parent_parent: any, entity: any, json_form: JSONForm) => React.ReactElement;
export type IFormPreviews = Record<string, TypeFormPreviewFunction>;
export type IFormComponent<T = any> = React.FC<T & CustomFormItemProps>;

export interface IForm extends IFormElement {
  id: string;
  ref?: string;
  elements?: IElements;
  ui_layout?: FormLayoutTypes;
  modal?: ModalProps;
  popover?: PopoverProps;
  //state: object; // default values, when updating form
  label?: IFormItemsProps['label'];
  components?: { [key: string]: IFormComponent };
  additionalParams?: {
    [key: string]: IFormAdditionalParams | TypeFormAdditionalParamsFunction;
  };
  entity_id?: Undefinable<number>;
}

export type CustomFormItemProps = {
  t: TFunction;
  value?: any;
  onChange?: (value: any) => void;
  data: [any, any, any, any, any, JSONForm];
  entityId: IForm['entity_id'];
  entity?: Record<string, any>;
  options?: Array<IOption>;
  disabled?: boolean;
};

export type IFormElementOption = IOption | string | number;

export interface IFormElementUnknown extends IFormElement {}

interface IFormElementInput extends IFormElement {
  ui_type?: FormElementTypes.INPUT;
  minLength?: number;
  maxLength?: number;
}

interface IFormElementNumber extends IFormElement {
  ui_type?: FormElementTypes.NUMBER;
  min?: number;
  precision?: number;
}

interface IFormElementCustom extends IFormElement {
  ui_type?: FormElementTypes.CUSTOM;
  component: string | IFormComponent;
}

export interface IFormElementTime extends IFormElement {
  ui_type: FormElementTypes.TIME;
  ui_settings?: {
    format?: string;
  };
}

export interface IFormElementDate extends IFormElement {
  ui_type?: FormElementTypes.DATE;
  ui_settings?: {
    format?: string;
  };
}

export interface IFormElementDatetime extends IFormElement {
  ui_type?: FormElementTypes.DATETIME;
  ui_settings?: {
    format?: string;
  };
}

export interface IFormElementSelect extends IFormElement {
  ui_type?: FormElementTypes.SELECT;
  options: TypeFormOption | string; // string = pointer to form options object
  onSearch?: (w: string) => Promise<Array<IOption>>;
  keep_default_sorting?: boolean;
}

export interface IFormElementObject extends IFormElement {
  ui_type?: FormElementTypes.SECTION;
  elements: Array<IFormElement>;
  ui_layout?: FormLayoutTypes;
  modal?: ModalProps;
  popover?: PopoverProps;
  compact?: boolean;
  legend?: string;
  child_params?: IFormElements;
}

export interface IFormElementArray extends IFormElement {
  ui_type: FormElementTypes.LIST | FormElementTypes.TYPEAHEAD;
  ui_layout?: FormLayoutTypes;
  map_items?: {
    elements?: Array<IFormElements>;
    element?: IFormElements;
    default?: Record<string, any>;
  };
  options?: TypeFormOption | string; // string = pointer to form options object
  elements?: Array<IFormElements>;
  element?: IFormElements;
  addItems?: boolean; // default true;
  sortable?: boolean; // default true;
  addText?: string;
  group_labels? : boolean; // default true
  child_params?: IFormElementObject & {
    width: number | string;
  };
}

export interface IFormElementTypeahead extends IFormElement {
  ui_layout?: FormLayoutTypes;
  ui_type: FormElementTypes.TYPEAHEAD;
  options?: TypeFormOption | string; // string = pointer to form options object
  searchable?: boolean; // will header.search in IFormElementOption["title"] if FuncOptions not defined
  multiple?: boolean; // default true
  sortable?: boolean; // default true, if multiple = true
  duplicate?: boolean; // default false, if one option can be added multiple times
  createOption?: boolean; // default false
  onCreateOption?: string; // func_option_events
  mode: SelectProps<any>['mode'];
  tagsOptionValidation?: (value) => boolean;
  maxTagCount?: number;
  onAdd?: (selected: any, item: IFormElementTypeahead, value: Array<any>, baseValues: Record<string, any>) => any;
  onModalAdd?: IGlobalPopupModal;
  tagRender?: (props, options: Array<IOption>, t: TFunction) => ReactElement | string;
  tag_style?: React.CSSProperties;
}

export interface IFormElementList extends IFormElement {
  ui_type: FormElementTypes.LIST;
  list: Array<IForm>;
  sortable?: boolean; // default true
  list_delimiter?: {
    delimiter: string;
    placement?: 'left' | 'center' | 'right';
    title?: string;
  };
}

export interface IFormElementText extends IFormElement {
  ui_type: FormElementTypes.TEXT;
  rows?: number;
}

export interface IFormElementHtml extends IFormElement {
  ui_type: FormElementTypes.HTML;
  hashOptions?: Array<'contact' | 'agent' | 'conversation' | 'field'>;
  atOptions?: Array<'user'>;
  canned_answers?: Array<IPreviewCannedAnswer>;
  file_upload?: IQuillFileUpload;
}

export interface IFormElementTree extends IFormElement {
  ui_type: FormElementTypes.TREE;
  multiple?: boolean; // default false
}

export interface IFormElementFile extends IFormElement {
  ui_type: FormElementTypes.FILE;
  uploadProps: UploadProps & { onValueChange: (onChange: (value: any) => any) => UploadProps['onChange'] };
}

export interface IFormElementPreview extends IFormElement {
  ui_type: FormElementTypes.PREVIEW;
  preview_value: string;
}
