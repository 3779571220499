import { IPreviewVoiceVoicemailMessage } from 'atlas-shared';
import { download, stream } from '@Utils';

export * from './abstract/voice_voicemail_message.api.abstract';

export const downloadVoiceVoicemailMessage = (id: IPreviewVoiceVoicemailMessage['id'], filename: string, onError?: (error) => void) => {
  download(`voice_voicemail_message/${id}/filename`, filename, onError);
};

export const streamVoiceVoicemailMessage = (id: IPreviewVoiceVoicemailMessage['id'], onError?: (error) => void): Promise<string> => {
  return stream(`voice_voicemail_message/${id}/filename`, onError);
};
