import { IPreviewStatsCustomMetric } from 'atlas-shared';

export enum StatsCustomMetricActions {
  SET_STATE = 'STATS_CUSTOM_METRIC_SET_STATE',
  ADD_STATE = 'STATS_CUSTOM_METRIC_ADD_STATE',
  REMOVE_STATE = 'STATS_CUSTOM_METRIC_REMOVE_STATE',
  GET_STATE = 'STATS_CUSTOM_METRIC_GET_STATE',
  UPDATE_STATE = 'STATS_CUSTOM_METRIC_UPDATE_STATE',
  LOADING = 'STATS_CUSTOM_METRIC_LOADING',
}

export interface IStatsCustomMetricLoading {
  type: StatsCustomMetricActions.LOADING;
}

export interface IStatsCustomMetricSetAction {
  type: StatsCustomMetricActions.SET_STATE;
  payload: Array<IPreviewStatsCustomMetric>;
}

export interface IStatsCustomMetricUpdateAction {
  type: StatsCustomMetricActions.UPDATE_STATE;
  payload: IPreviewStatsCustomMetric;
}

export interface IStatsCustomMetricAddAction {
  type: StatsCustomMetricActions.ADD_STATE;
  payload: IPreviewStatsCustomMetric;
}

export interface IStatsCustomMetricRemoveAction {
  type: StatsCustomMetricActions.REMOVE_STATE;
  payload: IPreviewStatsCustomMetric['id'];
}

export interface IStatsCustomMetricStore {
  loaded: boolean;
  loading: boolean;
  stats_custom_metrics: Array<IPreviewStatsCustomMetric>;
  dict: Record<IPreviewStatsCustomMetric['id'], IPreviewStatsCustomMetric>;
}

export type TStatsCustomMetricActionTypes = IStatsCustomMetricSetAction | IStatsCustomMetricUpdateAction | IStatsCustomMetricAddAction | IStatsCustomMetricRemoveAction | IStatsCustomMetricLoading;
