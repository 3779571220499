import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  OrganizationEditForm,
  OrganizationEditInitialValues,
  OrganizationComponents,
  OrganizationAdditionalParams,
  OrganizationFormOptions,
  onOrganizationFormSaved,
  IOrganizationFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedOrganization, ISharedPatchOrganization, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchOrganization, patchOrganization, getOrganizationUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useVoiceAsterisks, useTariffs, usePhonenumberMonthlyCosts, usePhonenumberSetupCosts } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedOrganization> {}

const OrganizationFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const voice_asterisks = useVoiceAsterisks();
  const tariffs = useTariffs();
  const phonenumber_monthly_costs = usePhonenumberMonthlyCosts();
  const phonenumber_setup_costs = usePhonenumberSetupCosts();

  useEffect(() => {
    fetchOrganization(id).then(organization => setValue(organization));
  }, [id, setValue]);

  const param_props: IOrganizationFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    voice_asterisks: voice_asterisks.voice_asterisks,
    tariffs: tariffs.tariffs,
    phonenumber_monthly_costs: phonenumber_monthly_costs.phonenumber_monthly_costs,
    phonenumber_setup_costs: phonenumber_setup_costs.phonenumber_setup_costs
  };
  const formLayout: IForm | undefined = useMemo(() => value && OrganizationEditForm(value, param_props, t), [value, organizations, voice_asterisks, tariffs, phonenumber_monthly_costs, phonenumber_setup_costs, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && OrganizationFormOptions(value, param_props, t), [value, organizations, voice_asterisks, tariffs, phonenumber_monthly_costs, phonenumber_setup_costs, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchOrganization, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchOrganization) => value && save(() => patchOrganization(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onOrganizationFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !voice_asterisks.loaded || !tariffs.loaded || !phonenumber_monthly_costs.loaded || !phonenumber_setup_costs.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.Organization, value)}
    >
      <AtlasForm<ISharedPatchOrganization>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={OrganizationComponents}
        additionalParams={OrganizationAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={OrganizationEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('ORGANIZATION')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const OrganizationFormEditView = React.memo(withEdit(OrganizationFormEdit, getOrganizationUpdateSchema));
