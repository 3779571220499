import React from 'react';
import { IAbilities, IAuth, IOrganizationStore, IJourneyStore, IUserStore } from '@Store';
import { CrudEnum, IPreviewVoiceRoute, PermissionEnum, TVoiceRouteSortableListCol, } from 'atlas-shared';
import { getFullName, tableColumnDatetimeSorter, tableColumnStringSorter, DisplayPhoneNumber, timeSince, translatePhoneNumber } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const VoiceRouteListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, journeies: IJourneyStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceRoute) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceRoute, TVoiceRouteSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceRoute, TVoiceRouteSortableListCol>['sorter']): ColumnsType<IPreviewVoiceRoute> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewVoiceRoute['organization_id'], voice_route: IPreviewVoiceRoute) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id } }),
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('NUMBER'),
      dataIndex: 'number',
      key: 'number',
      render: (number: IPreviewVoiceRoute['number'], voice_route: IPreviewVoiceRoute) => <DisplayPhoneNumber t={t} phonenumber={translatePhoneNumber(number)} />,
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => tableColumnStringSorter(a.number, b.number),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'number')?.order || null,
      filteredValue: filters.number || null,
      ...GetColumnSearchLikeProps(t, 'number', t('NUMBER')),
      className: 'table-cell-number',
    },
    {
      title: t('JOURNEY'),
      dataIndex: 'inbound_journey_id',
      key: 'inbound_journey_id',
      render: (inbound_journey_id: IPreviewVoiceRoute['inbound_journey_id'], voice_route: IPreviewVoiceRoute) => !inbound_journey_id ? '' : journeies.dict?.[inbound_journey_id]?.title || t('JOURNEY_NOT_FOUND', { replace: { inbound_journey_id } }),
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => (a.inbound_journey_id && journeies.dict?.[a.inbound_journey_id]?.title || '').localeCompare(b.inbound_journey_id && journeies.dict?.[b.inbound_journey_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'inbound_journey_id')?.order || null,
      className: 'table-cell-inbound_journey_id',
    },
    {
      title: t('USER'),
      dataIndex: 'terminated_by',
      key: 'terminated_by',
      render: (terminated_by: IPreviewVoiceRoute['terminated_by'], voice_route: IPreviewVoiceRoute) => terminated_by === -1 ? t('SYS_USER') : terminated_by && users.dict?.[terminated_by] ? getFullName(users.dict[terminated_by]) : t('USER_NOT_FOUND', { replace: { user_id: terminated_by } }),
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => (a.terminated_by && users.dict?.[a.terminated_by] ? a.terminated_by === -1 ? t('SYS_USER') : getFullName(users.dict?.[a.terminated_by]) : '').localeCompare(b.terminated_by && users.dict?.[b.terminated_by] ? getFullName(users.dict?.[b.terminated_by]) : ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'terminated_by')?.order || null,
      className: 'table-cell-terminated_by',
    },
    {
      title: t('DESCRIPTION'),
      dataIndex: 'description',
      key: 'description',
      render: (description: IPreviewVoiceRoute['description'], voice_route: IPreviewVoiceRoute) => <span className='list-description'>{description}</span>,
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => tableColumnStringSorter(a.description, b.description),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'description')?.order || null,
      className: 'table-cell-description',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewVoiceRoute['created_at'], voice_route: IPreviewVoiceRoute) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewVoiceRoute['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewVoiceRoute, b: IPreviewVoiceRoute) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewVoiceRoute) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Insert, PermissionEnum.VoiceRoute, row) && listCloneButton(t, navigate, 'voice_route', row)}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'voice_route', row)}
          {!row.deleted_at && abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.VoiceRoute, row) && listEditButton(t, navigate, 'voice_route', row)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewVoiceRoute>;

};
