import React from 'react';
import { IChangelogHistoryHistoryItem } from './changelog.history';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
  change: IChangelogHistoryHistoryItem;
}

export const ChangelogHistoryVersionChange = React.memo(({ t, change }: IProps) => {
  return <li className={`change${change.bug ? ' bugfix' : ' improvement'}`}>
    <div className='border'></div>
    <div className='text'><div className='type ellipsis'>{t(change.bug ? 'BUG_FIX' : 'IMPROVEMENT')}</div>{change.change}</div>
  </li>;
});
