import React from 'react';
import {
  FormElementTypes,
  IForm, IFormAdditionalParams,
  IFormElementArray,
  IFormElementObject,
  IFormOptions,
  IFormPreviews,
  JSONForm, TypeFormAdditionalParamsFunction
} from '../atlas-form-core';
import { FormProps } from 'antd';
import './styles.scss';
import { FormItem } from './form.item';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useTranslation } from 'react-i18next';
import { FormItemClassNames, FormItemProps, IFormItemsInputProps, IModals, ISetModal } from './utils';
import { clone } from '@Utils';
import { Undefinable } from 'atlas-shared/dist';

interface IProps {
  form: IFormElementObject;
  json_form: JSONForm;
  options: IFormOptions;
  previews: IFormPreviews;
  components: IForm['components'];
  additionalParams: IForm['additionalParams'];
  inheritedAdditionalParams?: IFormAdditionalParams | TypeFormAdditionalParamsFunction;
  antForm: FormInstance;
  isSubmitted?: boolean;
  name: Array<number | string>;
  path: Array<number | string>;
  setModal: ISetModal;
  modals: IModals;
  onValuesChange?: FormProps['onValuesChange'];
  entity: any;
  AudioRecorder: IFormItemsInputProps['AudioRecorder'];
  addGlobalPopupModal: IFormItemsInputProps['addGlobalPopupModal'];
  displayAdvanced: boolean;
}
function FormSection (props: IProps) {
  const { form: _form, antForm, name, isSubmitted, modals, setModal, options, previews, path, AudioRecorder, addGlobalPopupModal, displayAdvanced } = props;
  const { t } = useTranslation();
  const string_path: string = [...path, ...name].filter(p => isNaN(p as any)).join('_');

  const baseValues = props.json_form.getValues();
  const getFunctionParams = (): [any, any, any, any, any] => [
    baseValues,
    path.length > 1 ? props.json_form.findValueFromPath(path.slice(0, path.length - 1).join('.')) : baseValues,
    path.length ? props.json_form.findValueFromPath(path.join('.')) : baseValues,
    path.length > 2 ? props.json_form.findValueFromPath(path.slice(0, path.length - 2).join('.')) : undefined,
    props.entity
  ];

  const functionParams: [any, any, any, any, any, JSONForm] = [...clone(getFunctionParams()), props.json_form];
  const row_style: React.CSSProperties = {};

  let additionalParams = isNaN(+path?.[path.length - 1]) ? props.additionalParams?.[string_path] : null;

  if (typeof additionalParams === 'function')
    additionalParams = additionalParams(...functionParams);

  let inheritedAdditionalParams: IFormAdditionalParams = props.inheritedAdditionalParams as IFormAdditionalParams;

  if (typeof inheritedAdditionalParams === 'function')
    inheritedAdditionalParams = (inheritedAdditionalParams as TypeFormAdditionalParamsFunction)(...functionParams);

  let childAdditionalParams: Undefinable<IFormAdditionalParams> = inheritedAdditionalParams?.child_params;

  if (additionalParams?.ui_type === FormElementTypes.LIST) {
    const { child_params, ...rest } = additionalParams as IFormElementArray;

    additionalParams = { ...rest, ...child_params };
  }
  else if (additionalParams?.ui_type === FormElementTypes.SECTION) {
    const child_params = (additionalParams as IFormElementObject).child_params;

    if (child_params)
      childAdditionalParams = child_params;
  }

  const form = { ..._form, ...additionalParams, ...inheritedAdditionalParams };

  const style: React.CSSProperties = {};

  if (form.noPadding)
    style.padding = 0;
  
  // if (form.advanced && !displayAdvanced)
  //  return <></>;

  if (childAdditionalParams?.width)
    row_style.width = childAdditionalParams.width;

  return (
    <div
      key={form.path}
      className={`ant-form ${FormItemClassNames([...props.path, ...name])} ant-form-${form.ui_layout}${form.compact ? ' compact' : ''} ant-form-section`}
      style={{ width: (additionalParams as IFormElementArray['child_params'])?.width }}
    >
      {form.legend && <div className='legend'>{form.legend}</div>}
      <div className='ant-col ant-form-item-control' style={style}>
        {(form.elements || []).map(
          (elm: any, index: number) => {
            return <FormItem
              {...FormItemProps({
                form: { ...elm, labelWidth: form.labelWidth, row_style },
                json_form: props.json_form,
                options,
                previews,
                components: props.components,
                additionalParams: props.additionalParams || {},
                inheritedAdditionalParams: childAdditionalParams || undefined,
                antForm,
                isSubmitted,
                modals,
                name: [],
                path: [],
                entity: props.entity,
                t,
                setModal,
                AudioRecorder,
                addGlobalPopupModal,
                displayAdvanced,
                advanced: form.advanced,
              })}
              key={`${[...props.path, ...name].map(c => `${c}_`).join('')}${index}_${elm.ref}`}
            />;
          },
        )}
      </div>
    </div>
  );
}

export default FormSection;
