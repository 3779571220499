import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchPhonenumberMonthlyCosts, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const usePhonenumberMonthlyCosts = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const phonenumber_monthly_costs = useSelector((store: IReducerStore) => store.phonenumber_monthly_costs);

  useEffect(() => {
    if (!initiated && autoload && !phonenumber_monthly_costs.loaded && !phonenumber_monthly_costs.loading) {
      initiated = true;
      dispatch(actionFetchPhonenumberMonthlyCosts());
    }
  }, [autoload, phonenumber_monthly_costs.loaded, phonenumber_monthly_costs.loading, dispatch]);

  return phonenumber_monthly_costs;
};
