import { IPreviewTeam } from 'atlas-shared';
import { ITeamStore, TeamActions, TTeamActionTypes } from '@Store';

export const TeamReducer = (state: ITeamStore = {
  loaded: false,
  loading: false,
  teams: [],
  dict: {}
}, action: TTeamActionTypes): ITeamStore => {
  switch (action.type) {
  case TeamActions.LOADING:
    return { ...state, loading: true };
  case TeamActions.SET_STATE:
    const dict: ITeamStore['dict'] = {};

    action.payload.forEach((team: IPreviewTeam) => dict[team.id] = team);

    return { ...state, loaded: true, loading: false, teams: action.payload, dict };
  case TeamActions.ADD_STATE:
    return { ...state, teams: [...state.teams, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case TeamActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, teams: state.teams.filter((item: IPreviewTeam) => item.id !== action.payload), dict };
  }
  case TeamActions.UPDATE_STATE:
    const teams = [...state.teams];
    const index = state.teams.findIndex((team: IPreviewTeam) => team.id === action.payload.id);

    if (!~index)
      teams.push(action.payload);
    else
      teams[index] = action.payload;

    return { ...state, teams, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
