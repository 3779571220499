import { IconText } from '@Components';
import './voice_call.info.scss';
import { DirectionEnum, TConversationVoiceCall } from 'atlas-shared/dist';
import { PhoneIncomingIcon, PhoneOutgoingIcon } from '@Assets';
import { TranslateAndDisplayPhoneNumber } from '@Utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  voice_call: TConversationVoiceCall;
}

export const VoiceCallInfo = ({ voice_call }: IProps) => {
  const { t } = useTranslation();

  return <div className='voice-controller-info'>
    <div className={'info-section shrink'}>
      <IconText icon={voice_call.direction === DirectionEnum.InBound ? PhoneIncomingIcon : PhoneOutgoingIcon}
        text={<TranslateAndDisplayPhoneNumber t={t} phone_number={voice_call.contact_phone}/>}/>
    </div>
  </div>;
};
