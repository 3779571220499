import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IQueueStore, IOrganizationStore, IPauseStore, ITeamStore } from '@Store';
import { IPreviewAgentprofile, TAgentprofileSortableListCol } from 'atlas-shared/dist';
import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { AgentprofileListColumns as AgentprofileListColumnsBase } from './abstract/agentprofile.list.column.abstract';
import { Icon } from '@Components';
import { UsersIcon } from '@Assets';
import { IWithListProps } from '@Hocs/with.list';

export const AgentprofileListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, pauses: IPauseStore, queues: IQueueStore, teams: ITeamStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewAgentprofile) => React.ReactElement> = [], filters: IWithListProps<IPreviewAgentprofile, TAgentprofileSortableListCol>['filters'], sorter: IWithListProps<IPreviewAgentprofile, TAgentprofileSortableListCol>['sorter']): ColumnsType<IPreviewAgentprofile> => {
  return AgentprofileListColumnsBase(t, navigate, showDeleted, organizations, pauses, queues, teams, abilities, auth, [
    ...buttons,
    (row: IPreviewAgentprofile) => <Icon
      icon={UsersIcon}
      tooltip={{ title: t('AGENTS') }}
      onClick={() => navigate(`/admin/agentprofile/${row.id}/agents`)}
    />
  ], filters, sorter);
};
