import { Avatar } from '@Components';
import { getFullName } from '@Utils';
import React from 'react';
import { IPreviewUser } from 'atlas-shared/dist';

interface IProps {
  user?: IPreviewUser;
}
  
export const ConversationMessageAvatar = ({ user }: IProps) => {
  return user ? <div className={'user'} >
    <Avatar user={user} size={24} />
    <div className={'user-info'} >
      <span>
        {getFullName(user)}
      </span>
    </div>
  </div> : <></>;
};
