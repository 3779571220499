import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchVoiceAsteriskDowngrades, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useVoiceAsteriskDowngrades = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_asterisk_downgrades = useSelector((store: IReducerStore) => store.voice_asterisk_downgrades);

  useEffect(() => {
    if (!initiated && autoload && !voice_asterisk_downgrades.loaded && !voice_asterisk_downgrades.loading) {
      initiated = true;
      dispatch(actionFetchVoiceAsteriskDowngrades());
    }
  }, [autoload, voice_asterisk_downgrades.loaded, voice_asterisk_downgrades.loading, dispatch]);

  return voice_asterisk_downgrades;
};
