import { RestRequest } from '@Utils';
import { IPreviewContact, ISharedContact, ISharedCreateContact, ISharedPatchContact } from 'atlas-shared';

export const fetchContact = async (organization_id: IPreviewContact['organization_id'], id: IPreviewContact['id']): Promise<ISharedContact> => {
  return RestRequest.get<ISharedContact>(`contact/p/${organization_id}/${id}`, { socket_id: global.socketId });
};

export const createContact = (payload: ISharedCreateContact) => {
  return RestRequest.post<any, ISharedContact>('contact', payload, { socket_id: global.socketId });
};

export const patchContact = (organization_id: IPreviewContact['organization_id'], id: IPreviewContact['id'], payload: ISharedPatchContact) => {
  return RestRequest.patch<any, ISharedContact>(`contact/p/${organization_id}/${id}`, payload, { socket_id: global.socketId });
};

export const deleteContact = (organization_id: IPreviewContact['organization_id'], id: IPreviewContact['id']) => {
  return RestRequest.delete(`contact/p/${organization_id}/${id}`, { socket_id: global.socketId });
};

export const getContactUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('contact/update/schema');
};

export const getContactCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('contact/create/schema');
};

export const getContactPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('contact/patch/schema');
};
