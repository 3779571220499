import React, { Dispatch, SetStateAction, useMemo, useState, useTransition } from 'react';
import { IAuth, IStatsWidgetStore, IUserStore } from '@Store';
import { IPreviewStatsWidget, ISharedCreateStatsWidget, StatsWidgetTypeEnum } from 'atlas-shared';
import { WidgetTypeIcons } from '@Utils';

import { Avatar, Icon } from '@Components';
import { TFunction } from 'i18next';
import { CloneIcon, SearchIcon } from '@Assets';

interface IProps {
  t: TFunction;
  auth: IAuth;
  users: IUserStore;
  widgets: IStatsWidgetStore['stats_widgets'] | Array<Partial<ISharedCreateStatsWidget>>;
  onSelect: (widget: IPreviewStatsWidget | Partial<ISharedCreateStatsWidget>) => void;
  isTemplates?: boolean;
  setWidgetClone?: Dispatch<SetStateAction<number>>;
}

export const StatsWidgetFormWidgetList = React.memo(({ t, auth, users, widgets, onSelect, setWidgetClone, isTemplates = false }: IProps) => {
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [isPending, startTransition] = useTransition();
  const [keyword, setKeyword] = useState<string>('');
  const filteredWidgets = useMemo(() => {
    if (keyword)
      return [...(widgets as Array<any>).filter(widget => widget.title.toLowerCase().includes(keyword))].reverse();

    return [...widgets].reverse();
  }, [widgets, keyword]);

  return <div className='widget-list'>
    <div className='widget-list-head'>
      <div className='left'>
        <h3>{t(isTemplates ? 'WIDGET_TEMPLATES' : 'EXISTING_WIDGETS')} ({filteredWidgets.length})</h3>
      </div>
      <div className='right'>
        <input placeholder={t(`SEARCH_${isTemplates ? 'TEMPLATES' : 'WIDGETS'}`)} onChange={v => {
          startTransition(() => {
            setKeyword(v.target.value.toLowerCase());
          });
        }} />
        <Icon icon={SearchIcon} />
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <td></td>
          <td>{t('WIDGET')}</td>
          <td>{t('CREATED_AT')}</td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {filteredWidgets.map((widget, index) => {
          const isSelected = (widget.id && selectedId === widget.id) || (selectedIndex > -1 && index === selectedIndex);
          const isSelectedClassName = isSelected ? ' selected' : '';

          return <>
            <tr className={`space space-top ${isSelectedClassName}`}>
              <td colSpan={5}></td>
            </tr>
            <tr
              className={`widget-item ${isSelectedClassName}`}
              onClick={() => {
                onSelect(widget);
                if (widget.id)
                  setSelectedId(widget.id);
                else
                  setSelectedIndex(index);
              }}
            >
              <td className='widget-icon'><Icon icon={WidgetTypeIcons[widget.type] || WidgetTypeIcons[StatsWidgetTypeEnum.Table]} iconProps={{ size: 21 }} /></td>
              <td className='title'><span title={widget.title}>{widget.title}</span></td>
              <td className='created_at'>{(widget as IPreviewStatsWidget).created_at && (new Date(widget.created_at).toLocaleDateString())}</td>
              <td className='avatar'>{(widget as IPreviewStatsWidget).user_id && widget.user_id !== auth.user.id && <Avatar size={20} user={users.dict[widget.user_id]} />}</td>
              <td width={30}>{setWidgetClone && <Icon
                icon={CloneIcon}
                onClick={() => {
                  setWidgetClone(widget.id);
                }}
              />}</td>
            </tr>
            <tr className={`space space-bottom ${isSelectedClassName}`}>
              <td colSpan={5}></td>
            </tr>
          </>;
        })}
      </tbody>
    </table>
  </div>;
});
