import React, { useCallback, useMemo, useState } from 'react';
import './conversation.message.draft.scss';
import { ISharedDraft, ISharedFullConversation } from 'atlas-shared';
import { deleteDraft } from '@Api';
import { useTranslation } from 'react-i18next';
import { ConversationMessageReplies, ConversationReply } from '@Components';
import { ConversationMessageDraftTyping } from './conversation.message.draft.typing';
import { THighlightedMessage } from '@Hooks';
import { htmlElementOnDropFiles } from '@Utils';

interface IProps {
  conversation: ISharedFullConversation;
  draft: ISharedDraft;
  editable: boolean;
  highlightedMessage: THighlightedMessage;
}

export const ConversationMessageDraft = React.memo(({ conversation, draft, editable, highlightedMessage }: IProps) => {
  const { t } = useTranslation();
  const [dragOver, setDragOver] = useState<boolean>(false);
  const onDrop = useMemo(() => {
    if (editable && draft)
      return htmlElementOnDropFiles(`draft/${draft.id}/attachment`, 'attachment', () => setDragOver(false));

    return null;
  }, [draft, editable]);
  const onDragOver = useCallback((event) => {
    
    if (!editable)
      return;

    if (event.target?.className?.toString().includes('ant-upload-button')) {
      if (dragOver)
        setDragOver(false);

      return;
    }

    if (!dragOver)
      setDragOver(true);

    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'move';
  }, [editable, dragOver]);
  const onDragLeave = useCallback(() => setDragOver(false), []);

  if (!onDrop && editable)
    return <></>;

  return (
    <div className={`conversation-message conversation-message-draft${editable ? ' editable' : ''}`} onDrop={onDrop || undefined} onDragOver={onDragOver} onDragLeave={onDragLeave} onDragEnd={onDragLeave}>
      <div className='conversation-message-content'>
        {dragOver && <div className='drop-info'>{t('DROP_TO_ATTACH_FILE')}</div>}
        {editable
          ? <ConversationReply conversation={conversation} draft={draft} onDelete={() => deleteDraft(draft.id)} />
          : <ConversationMessageDraftTyping t={t} draft={draft} />
        }
      </div>
      <ConversationMessageReplies draft={draft} conversation={conversation} highlightedMessage={highlightedMessage} />
    </div>
  );
}, (prev, curr) => {
  return curr.draft.body === prev.draft.body && curr.draft.attachments?.length === prev.draft.attachments?.length;
});
