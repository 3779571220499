import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchVoiceShortcuts, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useVoiceShortcuts = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const voice_shortcuts = useSelector((store: IReducerStore) => store.voice_shortcuts);

  useEffect(() => {
    if (!initiated && autoload && !voice_shortcuts.loaded && !voice_shortcuts.loading) {
      initiated = true;
      dispatch(actionFetchVoiceShortcuts());
    }
  }, [autoload, voice_shortcuts.loaded, voice_shortcuts.loading, dispatch]);

  return voice_shortcuts;
};
