import {
  IPreviewVoiceAsteriskDowngrade,
  ISharedVoiceAsteriskDowngrade
} from 'atlas-shared/dist';
import { RestRequest } from '@Utils';

export * from './abstract/voice_asterisk_downgrade.api.abstract';

export const resyncVoiceAsteriskDowngrade = (id: IPreviewVoiceAsteriskDowngrade['id']) => {
  return RestRequest.put<any, ISharedVoiceAsteriskDowngrade>(`voice_asterisk_downgrade/${id}/resync`, {}, { socket_id: global.socketId });
};
