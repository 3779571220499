import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateVoiceTrunk, ISharedUpdateVoiceTrunk, ISharedVoiceTrunk, IPreviewOrganization, IPreviewVoiceAsterisk, EnumOptions, TrunkTypeEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IVoiceTrunkFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  voice_asterisks: Array<IPreviewVoiceAsterisk>;
}

export const VoiceTrunkFormOptions = (voice_trunk: ISharedCreateVoiceTrunk | ISharedVoiceTrunk, props: IVoiceTrunkFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.VoiceTrunk, {})), 'organization_id', false), 'organization'),
  voice_asterisk_id: entitiesToOptions<IPreviewVoiceAsterisk>(props.voice_asterisks, 'voice_asterisk'),
  type: EnumOptions('TRUNK_TYPE', TrunkTypeEnum, t),
});

export const VoiceTrunkComponents: IForm['components'] = {};
export const VoiceTrunkAdditionalParams: (auth: IAuth, props: IVoiceTrunkFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceTrunkFormOptionsProps) => ({
});

export const VoiceTrunkEditForm = (voice_trunk: ISharedVoiceTrunk, props: IVoiceTrunkFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_trunk_edit_${voice_trunk.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === voice_trunk.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'type' },
    { ref: 'voice_asterisk_id' },
    { ref: 'settings' },
    { ref: 'title' },
    { ref: 'description' }
  ]
});

export const VoiceTrunkAddForm = (voice_trunk: ISharedCreateVoiceTrunk, props: IVoiceTrunkFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'voice_trunk_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'type' },
    { ref: 'voice_asterisk_id' },
    { ref: 'settings' },
    { ref: 'title' },
    { ref: 'description' }
  ]
});

export const VoiceTrunkAddInitialValues: ISharedCreateVoiceTrunk = {} as ISharedCreateVoiceTrunk;

export const VoiceTrunkEditInitialValues = (voice_trunk: ISharedVoiceTrunk): ISharedUpdateVoiceTrunk => ({
  type: voice_trunk.type,
  voice_asterisk_id: voice_trunk.voice_asterisk_id,
  settings: voice_trunk.settings,
  title: voice_trunk.title,
  description: voice_trunk.description
});

export const onVoiceTrunkFormSaved = (t: TFunction, action: 'edit' | 'add', voice_trunk: ISharedVoiceTrunk, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_trunks'));
};
