import { journeyAction, settingsFormSchema } from './base';

import { JourneyActionVoicePlayback as JourneyActionVoicePlaybackBase } from 'atlas-shared';

export const JourneyActionVoicePlayback = journeyAction({
  ...JourneyActionVoicePlaybackBase,
  settings_form: settingsFormSchema({
    elements: [
      { ref: 'asterisk_sound_id' }
    ]
  }),
  icon: 'Music'
});
