import React from 'react';
import { PolarGrid, PolarRadiusAxis, Sector, Surface, Tooltip } from 'recharts';
import { getStatsWidgetMetricData } from '@Utils';
import { WidgetChartTooltip } from '../../widget.chart.tooltip';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';

const data2 = {
  rowFills: ['#0C7927', '#63B24A', '#C5C04B', '#0C7927', '#63B24A', '#C5C04B', '#0C7927', '#63B24A', '#C5C04B', '#0C7927', '#63B24A', '#C5C04B'],
  rowOuterRadius: [
    [30, 50, 70, 90, 110, 130],
    [50, 70, 90, 110, 130, 150],
    [70, 90, 110, 130, 150, 170]
  ],
  numSegments: 6
};
const centerX = 200;
const centerY = 200;
const angle = 360 / data2.numSegments;
const startAngleMargin = 0;
const endAngleMargin = 0;

export const WidgetTypePolar = React.memo(withStatsWidget(({ data: _data, stats_widget, t: _t, metric_data_dicts, stats_custom_metrics, innerHeight = 400, innerWidth, organization }: IWithStatsWidgetProps) => {

  if (!_data?.r[0])
    return <></>;

  const has_trend = !!stats_widget.settings.trend;
  const group_length = stats_widget.settings.group_by.length;
  const data = (_data.g === 'split' ? _data.r[0].c!.r : _data.r).map(row => {
    const d = {
      name: stats_widget.settings.group_by.map((m, i) => getStatsWidgetMetricData(_t, m, row.d[i], metric_data_dicts, organization)).join(' '),
      value: 0,
      value_trend: 0
    };

    stats_widget.settings.metrics.forEach((m, i) => {
      d.value = +row.d[group_length + i];
      if (has_trend && row.t)
        d.value_trend = +row.t[i];
    });

    return d;
  });

  const flattened_metrics = has_trend ? [...stats_widget.settings.metrics, ...stats_widget.settings.metrics] : stats_widget.settings.metrics;
  const centerX = innerWidth / 2;
  const centerY = innerHeight / 2;
  const polarAnglesCount = data2.rowOuterRadius[0].length;
  const polarAngles = new Array(polarAnglesCount).fill(0).map((_, i) => 360 / polarAnglesCount * i);
  const polarRadius = [10, 20, 40, 80];

  return <div>
    <Surface
      width={innerWidth}
      height={innerHeight}
    >
      <PolarGrid
        cx={centerX}
        cy={centerY}
        innerRadius={0}
        outerRadius={Math.min(centerX, centerY)}
        width={innerWidth}
        height={innerHeight}
        polarAngles={polarAngles}
        polarRadius={polarRadius}
      />
      <PolarRadiusAxis angle={90} />
      {data2.rowOuterRadius[0].map((outerRadius, index) => {
        const outerRadiusIndex = index;
        const segmentIndex = index;

        return (
          <Sector
            fill={data2.rowFills[index]}
            innerRadius={0}
            outerRadius={outerRadius}
            cx={centerX}
            cy={centerY}
            startAngle={(segmentIndex - 1) * angle + startAngleMargin}
            endAngle={segmentIndex * angle - endAngleMargin}
            key={segmentIndex * outerRadiusIndex}
          />
        );

        // return outerRadius.map((radius, index) => {
        //   const segmentIndex = index;
        //   const innerRadius = prevOuterRadius[segmentIndex];
        //
        //   // debugger;
        //   return (
        //     <Sector
        //       fill={fill}
        //       innerRadius={innerRadius}
        //       outerRadius={radius}
        //       cx={centerX}
        //       cy={centerY}
        //       startAngle={(segmentIndex - 1) * angle + startAngleMargin}
        //       endAngle={segmentIndex * angle - endAngleMargin}
        //       key={segmentIndex * outerRadiusIndex}
        //     />
        //   );
        // });
      })}
      ;
      <Tooltip content={<WidgetChartTooltip organization={organization} t={_t} metrics={flattened_metrics} stats_custom_metrics={stats_custom_metrics} />} />
    </Surface>
  </div>;
}));
