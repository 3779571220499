import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const HelpCircleIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
    <line x1="12" y1="17" x2="12.01" y2="17"></line>
  </SvgIcon>;
};

export const JourneyHelpCircleIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="1" strokeWidth="18"></circle>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" style={{ color: 'var(--BG-FFFFFF)' }}></path>
    <line x1="12" y1="17" x2="12.01" y2="17" style={{ color: 'var(--BG-FFFFFF)' }}></line>
  </SvgIcon>;
};
