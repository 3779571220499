import React, { useEffect, useState } from 'react';
import { IAuth } from '@Store';
import { AtlasForm, FormLayoutTypes } from 'atlas-form';
import { fromJson } from 'json-joi-converter';
import { patchUserTwinningSettings, userTwinningSettingsSchema } from '@Api';
import { TFunction } from 'i18next';

interface IProps {
  auth: IAuth;
  t: TFunction;
}

export const TwinningSettingsForm = React.memo(({ auth, t }: IProps) => {
  const [initialValues] = useState(auth.user.voice_settings.twinning || {});
  const [schema, setSchema] = useState();

  useEffect(() => {
    userTwinningSettingsSchema().then(s => setSchema(s));
  }, []);

  if (!schema)
    return <>{t('LOADING_FORM')}</>;

  return <div style={{ width: '100%' }}>
    <AtlasForm
      formLayout={{
        id: 'TWINNING_SETTINGS',
        ui_layout: FormLayoutTypes.HORIZONTAL,
        labelWidth: '240px'
      }}
      title='TWINNING'
      form={fromJson(schema)}
      initialValues={initialValues}
      onFinish={values => patchUserTwinningSettings(auth.user.id, values)}
    />
  </div>;
});
