import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchTariffParents, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useTariffParents = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const tariff_parents = useSelector((store: IReducerStore) => store.tariff_parents);

  useEffect(() => {
    if (!initiated && autoload && !tariff_parents.loaded && !tariff_parents.loading) {
      initiated = true;
      dispatch(actionFetchTariffParents());
    }
  }, [autoload, tariff_parents.loaded, tariff_parents.loading, dispatch]);

  return tariff_parents;
};
