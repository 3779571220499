import { ISharedAgentprofile, ISharedRecentlyViewedConversation, ISharedUser, Nullable } from 'atlas-shared';
import { RestRequest } from '@Utils/rest';

export * from './abstract/recently_viewed_conversation.api.abstract';

export const fetchRecentlyViewedConversations = async (agentprofile_id?: Nullable<ISharedAgentprofile['id']>): Promise<Array<ISharedRecentlyViewedConversation>> => {
  return RestRequest.get<Array<ISharedRecentlyViewedConversation>>('recently_viewed_conversation', { socket_id: global.socketId, agentprofile_id: agentprofile_id || undefined });
};

export const clearRecentlyViewedConversation = (user_id: ISharedUser['id'], agentprofile_id: ISharedAgentprofile['id']) => {
  return RestRequest.delete(`recently_viewed_conversation/clear/${agentprofile_id}/${user_id}`);
};
