import moment, { Moment } from 'moment';
import { IAtlasWidgetTimelineTrack } from './interfaces';
import './style.scss';
import { useMemo, useState } from 'react';
import { ZoomInIcon, ZoomOutIcon } from '@Assets';
import { Icon } from '@Components';
import { TFunction } from 'i18next';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

interface IProps {
  t: TFunction;
  legends: Array<{title: string; color: string; bg_color: string; border: {width: number; color: string}}>;
  start?: Moment;
  end?: Moment;
  zoom?: number;
  tracks: Array<IAtlasWidgetTimelineTrack>;
  s: string;
  e: string;
  is_dashboard: boolean;
}
const Track = ({ title }: {title: string}) => {
  return <div className='track'><span>{title}</span></div>;
};

type IInterval = {start: Moment; end: Moment};

const headerItems = (_start: Moment, end: Moment) => {
  const days: Array<IInterval & {count: number}> = [];
  const hours: Array<IInterval> = [];
  const startHour = _start.clone().startOf('hour');

  while (true) {
    hours.push({
      start: startHour.clone(),
      end: startHour.add(1, 'hour').clone()
    });
    
    if (startHour >= end)
      break;
  }

  const startDay = _start.clone().startOf('day');

  while (true) {
    const _start = startDay.clone().startOf('day');
    const _end = startDay.add(1, 'day').clone().startOf('day');

    days.push({
      start: _start,
      end: _end,
      count: hours.filter(h => h.start < _end && h.start >= _start).length
    });

    if (startDay >= end)
      break;
  }
  
  return { days, hours };
};

const calculateElementLeft = (hours: number, start: Moment, element_start: Moment, element_end: Moment) => {
  const hour_width = 100 / hours;

  let left_percent = 0;
  let left_minutes = element_start.diff(start) / 1000 / 60;
  const left_hours = Math.floor(left_minutes / 60);

  left_minutes -= (left_hours * 60);
  
  left_percent += left_hours * hour_width;
  left_percent += hour_width / 60 * left_minutes;

  let width_percent = 0;
  let width_minutes = element_end.diff(element_start) / 1000 / 60;
  const width_hours = Math.floor(width_minutes / 60);

  width_minutes -= (width_hours * 60);

  width_percent += width_hours * hour_width;
  width_percent += hour_width / 60 * width_minutes;

  return {
    left: `${left_percent}%`,
    width: `${width_percent}%`
  };
};

export const AtlasWidgetTimeline = ({ t, tracks, legends, start: _start, end: _end, zoom: _zoom, s, e, is_dashboard }: IProps) => {

  const [zoom, setZoom] = useState(_zoom || 8);
  const navigate = useNavigate();

  const start = moment(s).minutes(0);
  const end = moment(e);

  const { days, hours } = useMemo(() => {
    return headerItems(start, end);
  }, [start, end]);

  const calculatedTracks = useMemo(() => {
    return tracks.map(track => ({
      ...track,
      elements: track.elements.map(element => ({
        ...element,
        styles: {
          ...element.styles,
          ...calculateElementLeft(hours.length, start, element.start, element.end)
        }
      }))
    }));
  }, [tracks]);
  const HEADER_TRACK_HEIGHT = 24;
  const HEADER_HEIGHT = useMemo(() => days.length * HEADER_TRACK_HEIGHT, [days]);

  return <div className='atlas-widget-timeline'>
    <div className='controls'>
      <Icon icon={ZoomInIcon} onClick={() => zoom < 60 && setZoom(zoom + 1)} tooltip={{ title: t('ZOOM_IN') }} iconProps={{ size: 16 }} />
      <Icon icon={ZoomOutIcon} onClick={() => zoom > 0 && setZoom(zoom - 1)} tooltip={{ title: t('ZOOM_OUT') }} iconProps={{ size: 16 }} />
    </div>
    <div className='board'>
      <div className='left'>
        <div className='head' style={{ height: HEADER_HEIGHT }}>
          <div className='inner'>
          </div>
        </div>
        <div className='tracks'>
          <div className='inner'>
            {tracks.map(track => <Track title={track.title} />)}
          </div>
        </div>
      </div>
      <div className='right'>
        <div className='scroll' style={{ width: `${(zoom * 100 / 2) + 50}%` }}>
          <div className='head' style={{ height: HEADER_HEIGHT }}>
            <div className='inner'>
              {days.length > 1 && <div className='track' style={{ height: HEADER_TRACK_HEIGHT }}>{days.map((day, i) => <div key={i} className='element' style={{ width: `${100 / hours.length * day.count}%` }}>{day.start.format('D/M')}</div>)}</div>}
              <div className='track' style={{ height: HEADER_TRACK_HEIGHT }}>{hours.map((element, i) => <div className='element' key={i} style={{ width: `${100 / hours.length}%` }}>{element.start.format('HH:mm')}</div>)}</div>
            </div>
          </div>
          <div className='tracks'>
            {calculatedTracks.map(track => <div className='track' key={track.id}>
              {track.elements.map(element => {
                return <Tooltip key={element.id} title={element.tooltip?.map(t => <div>{t}</div>)}><div className={`element${element.conversation_id ? ' clickable' : ''}`} style={element.styles} onClick={() => element.conversation_id && navigate(`/dashboard/conversation/${element.organization_id}/${element.conversation_id}`)}><span>{element.title}</span></div></Tooltip>;
              })}
            </div>)}
          </div>
        </div>
      </div>
    </div>
    <div className='legends' style={{ margin: !is_dashboard ? '20px 0 5px' : 0 }}>
      {legends.map((legend, i) => <div className='legend' key={i}>
        <div className='box' style={{ backgroundColor: legend.bg_color, border: `${legend.border.width}px solid ${legend.border.color}` }}></div>
        <div className='text'>{legend.title}</div>
      </div>)}
    </div>
  </div>;
};
