import { TFunction } from 'i18next';
import { IForm, IFormOptions } from '@Modules/atlas-form-core';
import { filterRelated } from '@Utils/relation';
import { IInvoicePostFormOptionsProps, InvoicePostFormOptions as InvoicePostFormOptionsBase, InvoicePostAdditionalParams as InvoicePostAdditionalParamsBase } from './abstract/invoice_post.form.abstract';
import {
  IPreviewInvoice,
  ISharedCreateInvoicePost,
  ISharedInvoicePost
} from 'atlas-shared/dist';
import { IAuth } from '@Store';
import { NavigateFunction } from 'react-router';

export * from './abstract/invoice_post.form.abstract';

export const InvoicePostFormOptions = (invoice_post: ISharedCreateInvoicePost | ISharedInvoicePost, props: IInvoicePostFormOptionsProps, t: TFunction): IFormOptions => ({
  ...InvoicePostFormOptionsBase(invoice_post, props, t),
  invoice_id: (it: ISharedInvoicePost) => filterRelated<IPreviewInvoice>(it.organization_id || invoice_post.organization_id, 'invoice', 'invoice_post', props.invoices, true, it => !it.locked_at),
});

export const InvoicePostAdditionalParams: (auth: IAuth, props: IInvoicePostFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IInvoicePostFormOptionsProps) => ({
  ...InvoicePostAdditionalParamsBase(auth, props),
  // amount_type: (it: IPreviewInvoicePost, b, c, d) => ({ hidden: it.type !== InvoicePostTypeEnum.Custom }),
  // amount_percent: (it: IPreviewInvoicePost) => ({ hidden: it.type !== InvoicePostTypeEnum.Custom }),
  // amount_currency: (it: IPreviewInvoicePost) => ({ hidden: it.type !== InvoicePostTypeEnum.Custom })
});

export const onInvoicePostFormSaved = (t: TFunction, action: 'edit' | 'add', invoice_post: ISharedInvoicePost, navigate: NavigateFunction): void => {
  navigate('/admin/invoices');
};
