import { ApiKeyActions } from '@Store';
import { IPreviewApiKey } from 'atlas-shared';
import { fetchApiKeys } from '@Api';

export const actionFetchApiKeys = () => {
  return async (dispatch) => {
    dispatch(actionLoadingApiKeys());
    const api_keys = await fetchApiKeys();

    dispatch(actionSetApiKeys(api_keys));
    return api_keys;
  };
};

export const actionLoadingApiKeys = () => ({
  type: ApiKeyActions.LOADING,
  payload: []
});

export const actionSetApiKeys = (payload: Array<IPreviewApiKey>) => ({
  type: ApiKeyActions.SET_STATE,
  payload: payload
});

export const actionAddApiKey = (payload: IPreviewApiKey) => ({
  type: ApiKeyActions.ADD_STATE,
  payload: payload
});

export const actionUpdateApiKey = (payload: IPreviewApiKey) => ({
  type: ApiKeyActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveApiKey = (id: IPreviewApiKey['id']) => ({
  type: ApiKeyActions.REMOVE_STATE,
  payload: id
});
