import { IAuth } from '@Store';
import { FormElementTypes, FormLayoutTypes, IForm } from 'atlas-form';
import { ITariffParentFormOptionsProps } from '@Utils/forms/abstract/tariff_parent.form.abstract';
import { TariffParentAdditionalParams as TariffParentAdditionalParamsBase } from './abstract/tariff_parent.form.abstract';
import { CountryFlagTitle } from '@Utils/phone';

export * from './abstract/tariff_parent.form.abstract';

export const TariffParentAdditionalParams: (auth: IAuth, props: ITariffParentFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITariffParentFormOptionsProps) => ({
  ...TariffParentAdditionalParamsBase(auth, props),
  voice_tariff_list: {
    ui_type: FormElementTypes.SECTION,
    child_params: {
      ui_type: FormElementTypes.SECTION,
      ui_layout: FormLayoutTypes.INLINE,
      labelRenderer: label => <CountryFlagTitle t={props.t} country={label as string} width={21} />,
      child_params: {
        // hideLabel: true,
        // width: '105px'
      }
    }
  },
  voice_recording: {
    postFixText: '/min',
    postFixTextNull: ''
  },
  voice_recording_transcript: {
    postFixText: '/min',
    postFixTextNull: ''
  },
  voice_recording_storage: {
    postFixText: '/MB'
  },
  attachments_storage: {
    postFixText: '/MB'
  }
});
