import React from 'react';
import { useSwitchingProfile } from '@Hooks';
import './switching_profile.scss';
import { Spinner } from '../spinner';

export const SwitchingProfile = React.memo(() => {
  const switching = useSwitchingProfile();

  return switching ? <div id='switching-profile'>
    <Spinner />
  </div> : <></>;
});
