import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { getStatsWidgetMetricData, getStatsWidgetMetricDataFormatted } from '@Utils';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import { WidgetChartTooltip } from '@Components/stats/widget-types/widget.chart.tooltip';
import { WidgetChartLegend } from '@Components/stats/widget-types/widget.chart.legend';
import { WidgetChartBar } from '@Components/stats/widget-types/widget.chart.bar';
import { WIDGET_COLOR_PALETTE } from '@Components/stats/widget-types/widget.color.palette';
import { StatsWidgetMetricFormatEnum } from 'atlas-shared/dist';

export const WidgetTypeBar = withStatsWidget(({ data: _data, stats_widget, t: _t, metric_data_dicts, stats_custom_metrics, innerWidth, innerHeight, organization }: IWithStatsWidgetProps) => {

  if (!_data?.r[0])
    return <></>;

  const has_trend = useMemo(() => !!stats_widget.settings.trend, [stats_widget.settings.trend]);
  const group_length = useMemo(() => stats_widget.settings.group_by.length, [stats_widget.settings.group_by.length]);
  const data = useMemo(() => (_data.g === 'split' ? _data.r[0].c!.r : _data.r).map(row => {
    let nested_metric_index = 0;
    const d = {
      //group: stats_widget.settings.group_by.map((m, i) => row.d[i]).join('-'),
      group: stats_widget.settings.group_by.map((m, i) => getStatsWidgetMetricData(_t, m, row.d[i], metric_data_dicts, organization)).join(' - ')
    };

    stats_widget.settings.nested_metrics.forEach(({ metrics }, i) => {
      metrics.forEach((m, i2) => {
        d[m.value] = +row.d[group_length + nested_metric_index] || 0;
        if (has_trend && row.t)
          d[m.value + '_trend'] = +row.t?.[nested_metric_index] || 0;

        if (has_trend && row.tc)
          d[m.value + '_trend_comparison'] = +row.tc?.[nested_metric_index] || 0;

        nested_metric_index++;
      });
    });

    return d;
  }), [_data]);
  //const flattened_metrics = stats_widget.settings.nested_metrics.flatMap(({ metrics }) => has_trend ? metrics.flatMap(m => [m, m]) : metrics);
  const flattened_metrics = useMemo(() => stats_widget.settings.nested_metrics.flatMap(({ metrics }, i) => {
    return [
      ...metrics,
      ...(has_trend ? metrics : [])
    ];
  }), [stats_widget.settings.nested_metrics]);
  
  const bars = useMemo(() => {
    let nested_metric_index = 0;

    return stats_widget.settings.nested_metrics.flatMap(({ metrics }, i) => {
      const ln = metrics.length;

      nested_metric_index += ln;

      return [
        metrics.map((metric, mi) => {
          const _nested_metric_index = nested_metric_index - ln + mi;
          //const styles = (data.find(({ group }) => group === metric.value) || []).map(d => getStatsWidgetChartMetricStyles(_t, d, metric, i, stats_widget, stats_custom_metrics.stats_custom_metrics));

          return <Bar
            shape={<WidgetChartBar t={_t} dataKey={metric.value} metric={metric} stats_widget={stats_widget} stats_custom_metrics={stats_custom_metrics} nested_metric_index={_nested_metric_index} organization={organization} />}
            strokeWidth={metric.border?.width || 0}
            stroke={metric.border?.color || 'transparent'}
            order={i * 2}
            key={metric.value}
            dataKey={metric.value}
            stackId={`${i}_data`}
            fill={metric.bg_color || WIDGET_COLOR_PALETTE[_nested_metric_index]}
            isAnimationActive={false}
          />;
        }),
        has_trend && metrics.map((metric, mi) => {
          const _nested_metric_index = nested_metric_index - ln + mi;

          return <Bar
            shape={<WidgetChartBar trend t={_t} dataKey={`${metric.value}_trend`} metric={metric} stats_widget={stats_widget} stats_custom_metrics={stats_custom_metrics} nested_metric_index={_nested_metric_index} organization={organization} />}
            order={(i + 1) * 2}
            key={`${metric.value}_trend`}
            dataKey={`${metric.value}_trend`}
            stackId={`${i}_trend`}
            fill={metric.bg_color || WIDGET_COLOR_PALETTE[_nested_metric_index]}
            opacity={.6}
            style={{ transform: 'translateX(-5px)' }}
            strokeWidth={metric.border?.width || 0}
            stroke={metric.border?.color || 'transparent'}
            isAnimationActive={false}
          />;
        })
      ];
    });
  }, [stats_widget.settings.nested_metrics, has_trend]);
  
  const legendPlacement = useMemo(() => stats_widget.settings.ui.legend?.placement || 'bottom', [stats_widget.settings.ui.legend?.placement]);
  const legendVertical = useMemo(() => ['left', 'right'].includes(legendPlacement), [legendPlacement]);
  const YMetric = useMemo(() => flattened_metrics.find(m => m.format), [flattened_metrics]);
  const YMetricChars = useMemo(() => {
    if (YMetric) {
      switch (YMetric.format) {
      case StatsWidgetMetricFormatEnum.D_SLASH_M:
        return 3;
      case StatsWidgetMetricFormatEnum.HH_mm:
      case StatsWidgetMetricFormatEnum.mm_ss:
      case StatsWidgetMetricFormatEnum.VALUE_PERCENT:
      case StatsWidgetMetricFormatEnum.VALUE_DECIMAL:
        return 5;
      case StatsWidgetMetricFormatEnum.VALUE_PCS:
        return 5;
      case StatsWidgetMetricFormatEnum.HH_mm_ss:
      case StatsWidgetMetricFormatEnum.YYYYMMDD:
        return 8;
      case StatsWidgetMetricFormatEnum.YYYY_MM_DD:
        return 10;
      case StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm:
        return 16;
      case StatsWidgetMetricFormatEnum.YYYY_MM_DD_HH_mm_ss:
        return 19;
      }
    }

    return 3;
  }, [YMetric]);
  const YAxisWidth = useMemo(() => YMetricChars * 8, [YMetricChars]);

  return <div className={`widget-type-${stats_widget.type}`}>
    <BarChart
      width={innerWidth}
      height={innerHeight}
      data={data}
      margin={{
        top: legendPlacement === 'top' ? 10 : 0,
        right: legendPlacement === 'right' ? 15 : 0,
        left: legendPlacement === 'left' ? 15 : 0,
        bottom: legendPlacement === 'bottom' ? 0 : 0,
      }}
      barCategoryGap={'10%'}
      barGap={6}
      className={`legend-${legendVertical ? 'vertical' : 'horizontal'} legend-${legendPlacement}`}
    >
      <CartesianGrid strokeDasharray="1 3" vertical={false} stroke={'var(--COLOR-000533)'} opacity={.25} />
      <XAxis
        dataKey="group"
        fontSize={11}
        strokeWidth={0}
        tick={{ style: { transform: 'translateY(2px)' }, fill: 'var(--COLOR-000533)' }}
        opacity={.7}
      />
      <YAxis
        tickFormatter={value => YMetric ? getStatsWidgetMetricDataFormatted(_t, YMetric, value, organization) : value}
        width={YAxisWidth}
        strokeWidth={0}
        tick={{ style: { transform: 'translateX(4px)' }, fill: 'var(--COLOR-000533)' }}
        fontSize={11}
        opacity={.5}
      />
      <Tooltip content={<WidgetChartTooltip t={_t} organization={organization} metrics={flattened_metrics} stats_custom_metrics={stats_custom_metrics} />} isAnimationActive={false} cursor={{ fill: 'var(--flex-grey1002)' }} />
      {!!stats_widget.settings.ui.legend && <Legend
        content={<WidgetChartLegend stats_widget={stats_widget} data={data} t={_t} metrics={flattened_metrics} stats_custom_metrics={stats_custom_metrics} />}
        align={legendVertical ? stats_widget.settings.ui.legend!.placement as any : undefined}
        layout={legendVertical ? 'vertical' : 'horizontal'}
        verticalAlign={legendVertical ? 'middle' : legendPlacement as any}
      />}
      {bars}
    </BarChart>
  </div>;
});
