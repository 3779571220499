import React, { useEffect, useMemo, useState } from 'react';
import {
  IPreviewAgentprofile,
  IPreviewContact,
  IPreviewForm,
  IPreviewList,
  IPreviewOrganization,
  Nullable
} from 'atlas-shared';
import { AlertError, entitiesToOptions, entityToOption, IOption } from '@Utils';
import { searchContact, createContactByList } from '@Api';
import { useTranslation } from 'react-i18next';
import { AtlasForm, FormSelect, FormElementTypes, FormLayoutTypes } from 'atlas-form';
import Joi from 'joi';
import { fromJson } from 'json-joi-converter';
import { useAgentprofiles, useAuth, useForms, useLists } from '@Hooks';

interface IProps {
  organization_id: IPreviewOrganization['id'];
  contact_id: Nullable<IPreviewContact['id']>;
  create_list_id: IPreviewList['id'];
  onChange: (value: IPreviewContact['id']) => void;
  options?: Array<IOption>;
  createButton?: boolean;
  fixedDropdown?: boolean;
  createContact?: boolean;
}

export const ConversationContactMergeForm = React.memo((props: IProps) => {

  const { organization_id, contact_id, create_list_id, onChange, options, createButton, createContact: _createContact, fixedDropdown } = props;

  const { t } = useTranslation();
  const [createContact, setCreateContact] = useState<boolean>(!!_createContact);
  const [createSchema, setCreateSchema] = useState<Joi.Schema>();
  const [contacts, setContacts] = useState<Array<IOption>>(options || []);
  const [form, setForm] = useState<IPreviewForm>();
  const [list, setList] = useState<IPreviewList>();
  const lists = useLists();
  const forms = useForms();
  const auth = useAuth();
  const [agentprofile, setAgentprofile] = useState<IPreviewAgentprofile>();
  const agentprofiles = useAgentprofiles();

  useEffect(() => {
    if (agentprofiles.loaded && auth.user_status.agentprofile_id)
      setAgentprofile(agentprofiles.dict[auth.user_status.agentprofile_id]);
  }, [auth.user_status.agentprofile_id, agentprofiles]);

  useEffect(() => {
    if (!lists.loaded)
      return;

    setList(lists.dict[create_list_id]);
  }, [create_list_id, lists]);

  useEffect(() => {
    setCreateContact(!!_createContact);
  }, [_createContact]);

  useEffect(() => {
    if (form || !createContact || !create_list_id || !forms.loaded || !list)
      return;

    if (!list?.form_id)
      return;

    setForm(forms.dict[list.form_id]);
  }, [create_list_id, list, createContact, form, forms]);

  useEffect(() => {
    if (!form)
      return;

    setCreateSchema(fromJson({
      type: 'object',
      properties: {
        organization_id: { type: 'number' },
        __lists: {
          type: 'array',
          items: {
            type: 'number'
          }
        },
        ...form.__validation_on_save
      }
    }));
  }, [form]);

  const initialValues = useMemo(() => {
    const fields = {
      organization_id: organization_id,
      __lists: [create_list_id],
    };

    ['__contact_emails', '__contact_phones'].forEach(k => {
      if (form?.__layout.some(l => l.ref === k))
        fields[k] = [];
    });
    return fields;
  }, [form]);

  if (createContact && !createSchema)
    return <>{t('LOADING_FORM')}</>;

  if (form && !form.__fields.length)
    return <div style={{ color: 'red' }}>{t('CONTACT_FORM_HAS_NO_FIELDS')}</div>;

  return <>
    {!createContact && <>
      <FormSelect
        value={contact_id}
        onChange={onChange}
        options={contacts}
        onSearch={async (w: string) => entitiesToOptions((await searchContact(w, 10, contact_id ? [contact_id] : [], auth.user_status.agentprofile_id, agentprofile?.organization_id)).items.map(({ item }) => item), 'contact') as Array<IOption>}
        placeholder={t('SELECT_CONTACT')}
        fixedDropdown={fixedDropdown || undefined}
        dropdownClassName='contact-select'
        className='contact-selector'
      />
      {createButton !== false && create_list_id && list?.form_id && <span className='create-contact-link' onClick={e => {
        setCreateContact(true);
        //loadSchema();
      }}>{t('OR_CREATE_NEW_CONTACT')}</span>}
    </>
    }
    {createContact && createSchema && form && <AtlasForm
      formLayout={{
        id: 'CREATE_CONTACT',
        ui_layout: FormLayoutTypes.HORIZONTAL,
        labelWidth: '120px',
        elements: form.__layout as any
      }}
      form={createSchema}
      initialValues={initialValues}
      additionalParams={{
        organization_id: { hidden: true },
        __lists: { hidden: true },
        __contact_emails: { ui_type: FormElementTypes.LIST },
        __contact_phones: { ui_type: FormElementTypes.LIST }
      }}
      onCancel={createButton !== false ? () => setCreateContact(false) : undefined}
      onFinish={(values) => {
        return new Promise((resolve) => {
          createContactByList(create_list_id, values)
            .then((contact: IPreviewContact) => {
              onChange(contact.id);
              setCreateContact(false);
              setContacts([entityToOption(contact, 'contact')]);
            }, error => {
              AlertError(t, {
                content: error
              });
            })
            .finally(() => {
              resolve(true);
            });
        });
      }}
    />
    }
  </>;
});
