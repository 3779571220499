import { RestRequest } from '@Utils';
import { IPreviewConversation, ISharedConversation, ISharedCreateConversation, ISharedPatchConversation } from 'atlas-shared';

export const createConversation = (payload: ISharedCreateConversation) => {
  return RestRequest.post<any, ISharedConversation>('conversation', payload, { socket_id: global.socketId });
};

export const patchConversation = (organization_id: IPreviewConversation['organization_id'], id: IPreviewConversation['id'], payload: ISharedPatchConversation) => {
  return RestRequest.patch<any, ISharedConversation>(`conversation/p/${organization_id}/${id}`, payload, { socket_id: global.socketId });
};

export const getConversationCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation/create/schema');
};

export const getConversationPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('conversation/patch/schema');
};
