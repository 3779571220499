import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchPhonenumberSetupCostParents, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const usePhonenumberSetupCostParents = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const phonenumber_setup_cost_parents = useSelector((store: IReducerStore) => store.phonenumber_setup_cost_parents);

  useEffect(() => {
    if (!initiated && autoload && !phonenumber_setup_cost_parents.loaded && !phonenumber_setup_cost_parents.loading) {
      initiated = true;
      dispatch(actionFetchPhonenumberSetupCostParents());
    }
  }, [autoload, phonenumber_setup_cost_parents.loaded, phonenumber_setup_cost_parents.loading, dispatch]);

  return phonenumber_setup_cost_parents;
};
