import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  StatsReportAddForm,
  StatsReportAddInitialValues,
  StatsReportComponents,
  StatsReportAdditionalParams,
  StatsReportFormOptions,
  onStatsReportFormSaved,
  IStatsReportFormOptionsProps,
  AlertError
} from '@Utils';
import { AtlasForm, IFormOptions } from 'atlas-form';
import { CrudEnum, ISharedCreateStatsReport, PermissionEnum } from 'atlas-shared';
import { createStatsReport, getStatsReportCreateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withAdd, IWithAddProps } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useUsers, useTeams, useAgentprofiles } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateStatsReport> {}

const StatsReportFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const organizations = useOrganizations();
  const users = useUsers();
  const teams = useTeams();
  const agentprofiles = useAgentprofiles();
  const abilities = useAbilities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const param_props: IStatsReportFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    users: users.users,
    teams: teams.teams,
    agentprofiles: agentprofiles.agentprofiles
  };
  const formLayout = useMemo(() => StatsReportAddForm(values, param_props, t), [values, organizations, users, teams, agentprofiles, t, abilities]);
  const options: IFormOptions = useMemo(() => StatsReportFormOptions(values || StatsReportAddInitialValues, param_props, t), [values, organizations, users, teams, agentprofiles, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateStatsReport, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues]);
  const onFinish = useCallback((values: ISharedCreateStatsReport) => save(() => createStatsReport(values).then(
    res => onSaved ? onSaved(res, navigate) : onStatsReportFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !users.loaded || !teams.loaded || !agentprofiles.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.StatsReport, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={StatsReportComponents}
        additionalParams={StatsReportAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...StatsReportAddInitialValues, ...initialValues }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('STATS_REPORT')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const StatsReportFormAddView = React.memo(withAdd(StatsReportFormAdd, getStatsReportCreateSchema, StatsReportAddInitialValues));
