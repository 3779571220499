import {
  IPreviewStatsReport,
  IPreviewStatsReportPreviewWidget,
  IPreviewStatsReportWidget,
  IPreviewStatsWidget,
  ISharedCreateStatsReportWidget,
  Undefinable,
  StatsWidgetPeriodEnum
} from 'atlas-shared';
import { Button } from '@Components';
import {
  createStatsReportPreviewWidget,
  createStatsReportWidget,
  createStatsWidget,
  fetchStatsWidget, getStatsWidgetCreateSchema,
  setStatsReportActive
} from '@Api';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { IAuth, IStatsWidgetStore, IUserStore } from '@Store';
import { TFunction } from 'i18next';
import { StatsWidgetFormWidgetList } from '@Components/stats/stats_widget.form/stats_widget.form.widget.list';
import { AlertError } from '@Utils';
import { Switch } from 'antd';
import { IForm, JSONForm } from 'atlas-form';
import { fromJson } from 'json-joi-converter';

interface IProps {
  t: TFunction;
  report: IPreviewStatsReport;
  auth: IAuth;
  users: IUserStore;
  stats_widgets: IStatsWidgetStore;
  widgets: Array<IPreviewStatsReportWidget | IPreviewStatsReportPreviewWidget>;
  selectedExisting: Undefinable<IPreviewStatsWidget['id']>;
  getStatsReportWidgetSettings: () => ISharedCreateStatsReportWidget['settings'];
  onCancel: () => void;
  onSelect: (stats_widget_id: Undefinable<IPreviewStatsWidget['id']>) => void;
  isPreview?: boolean;
  isPreviewDefault?: boolean;
  setWidgetClone?: Dispatch<SetStateAction<number>>;
}

export const StatsWidgetFormExisting = ({ t, auth, report, stats_widgets, users, selectedExisting, setWidgetClone, getStatsReportWidgetSettings, onCancel, onSelect, widgets, isPreview = false, isPreviewDefault = false }: IProps) => {
  const is_dashboard = (window.location.pathname as string).includes('dashboard');
  const [clone, setClone] = useState<boolean>(false);

  return <div className='select-existing'>
    <StatsWidgetFormWidgetList
      t={t}
      auth={auth}
      widgets={is_dashboard ? stats_widgets.stats_widgets : stats_widgets.stats_widgets.filter(w => w.settings.period.type !== StatsWidgetPeriodEnum.LIVE_TODAY)}
      users={users}
      onSelect={widget => onSelect((widget as IPreviewStatsWidget).id)}
      setWidgetClone={setWidgetClone}
    />
    <div className='add-btns'>
      {!isPreview && <div className='clone'>
        <Switch checked={clone} onChange={checked => setClone(checked)}/>
        <div className='text'>{t('CLONE')}</div>
      </div>}
      <Button
        className={'add-btn'}
        type={'primary'}
        disabled={!selectedExisting}
        onClick={() => {
          if (selectedExisting)
            if (isPreview)
              createStatsReportPreviewWidget({
                stats_report_id: report.id,
                stats_widget_id: selectedExisting,
                settings: { dashboard_position: { h: 6, y: widgets.length + 1 } },
                is_default: !!isPreviewDefault
              })
                .then(() => setStatsReportActive(report.id, null, true))
                .catch(err => {
                  AlertError(t, {
                    content: err
                  });
                });
            else {

              const _createStatsReportWidget = (stats_widget_id) => {
                return createStatsReportWidget({
                  stats_report_id: report.id,
                  stats_widget_id,
                  settings: getStatsReportWidgetSettings()
                }).then(() => {
                  if (report?.is_dashboard)
                    setStatsReportActive(report.id, null, false);

                  onCancel();
                });
              };

              if (clone) {

                Promise.all([
                  getStatsWidgetCreateSchema(),
                  fetchStatsWidget(selectedExisting)
                ])
                  .then(([schema, stats_widget]) => {
                    const form = new JSONForm({} as IForm, fromJson(schema), undefined, {});

                    const values = form
                      .setValues(stats_widget)
                      .validate(true, { stripUnknown: true })
                      .getValues()
                    ;

                    const {
                      organization_id,
                      title,
                      type,
                      settings,
                      shared
                    } = values;

                    createStatsWidget({
                      ...values,
                      title: `${title} (${t('CLONED')}})`
                    })
                      .then(stats_widget => {
                        _createStatsReportWidget(stats_widget.id);
                      });
                  });

              }
              else
                _createStatsReportWidget(selectedExisting);

            }
        }}
      >
        {t(`ADD_WIDGET_TO_${report.is_dashboard ? 'DASHBOARD' : 'REPORT'}`)}
      </Button>
    </div>
  </div>;
};
