import { IPreviewUser } from 'atlas-shared';

export enum UserActions {
  SET_STATE = 'USER_SET_STATE',
  ADD_STATE = 'USER_ADD_STATE',
  REMOVE_STATE = 'USER_REMOVE_STATE',
  GET_STATE = 'USER_GET_STATE',
  UPDATE_STATE = 'USER_UPDATE_STATE',
  LOADING = 'USER_LOADING',
}

export interface IUserLoading {
  type: UserActions.LOADING;
}

export interface IUserSetAction {
  type: UserActions.SET_STATE;
  payload: Array<IPreviewUser>;
}

export interface IUserUpdateAction {
  type: UserActions.UPDATE_STATE;
  payload: IPreviewUser;
}

export interface IUserAddAction {
  type: UserActions.ADD_STATE;
  payload: IPreviewUser;
}

export interface IUserRemoveAction {
  type: UserActions.REMOVE_STATE;
  payload: IPreviewUser['id'];
}

export interface IUserStore {
  loaded: boolean;
  loading: boolean;
  users: Array<IPreviewUser>;
  dict: Record<IPreviewUser['id'], IPreviewUser>;
}

export type TUserActionTypes = IUserSetAction | IUserUpdateAction | IUserAddAction | IUserRemoveAction | IUserLoading;
