import React, { useCallback, useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { getStatsWidgetChartMetricStyles, getStatsWidgetMetricData, getStatsWidgetMetricDataFormatted } from '@Utils';
import { WidgetChartTooltip } from '../../widget.chart.tooltip';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import { StatsWidgetMetricData } from '@Components/stats/widget-types/stats_widget.metric.data';
import './widget-type.pie.scss';
import { WidgetChartLegend } from '@Components/stats/widget-types/widget.chart.legend';
import { IPreviewStatsWidgetSettingsMetricProperty, Undefinable } from 'atlas-shared/dist';

const RADIAN = Math.PI / 180;

export const WidgetTypePie = withStatsWidget(({
  data: _data, 
  stats_widget, 
  t: _t,
  has_trend,
  metric_data_dicts,
  stats_custom_metrics,
  innerWidth,
  innerHeight,
  metrics,
  organization
}: IWithStatsWidgetProps) => {

  if (!_data?.r[0])
    return <></>;

  const group_length = stats_widget.settings.group_by.length;
  const isSummaryOrSplit = _data?.g && ['summary', 'split'].includes(_data?.g);
  const summary: any = isSummaryOrSplit ? _data.r[0] : false;
  
  const data = useMemo(() => {
    
    if (stats_widget.settings.metrics.length === 1) {
      const root = isSummaryOrSplit && stats_widget.settings.group_by.length ? _data.r[0].c!.r : (_data?.r || []);

      return root.map(row => {
        const d: {name: string; value: number; value_trend: number; value_trend_comparison: Undefinable<number>} = {
          name: stats_widget.settings.group_by.map((m, i) => getStatsWidgetMetricData(_t, m, row.d[i], metric_data_dicts, organization)).join(' '),
          value: 0,
          value_trend: 0,
          value_trend_comparison: undefined
        };

        stats_widget.settings.metrics.forEach((m, i) => {
          d.value = +row.d[group_length + i];
          if (has_trend && row.t) {
            d.value_trend = +row.t[i];
            if (row.tc)
              d.value_trend_comparison = +row.tc[i];
          }
        });

        return d;
      });
    }

    const root = (isSummaryOrSplit && stats_widget.settings.group_by.length ? _data.r[0].c!.r : (_data?.r || []))?.[0];

    if (!root)
      return [];

    return stats_widget.settings.metrics.map((metric, i) => {
      return {
        name: metric.value,
        value: +root.d[i] || 0,
        value_trend: +root.t?.[i] || 0,
        value_trend_comparison: root.tc ? +root.tc?.[i] : undefined
      };
    });
  }, [_data, stats_widget]);

  const { total, trend_total, trend_comparison_total } = useMemo(() => {
    return {
      total: data.reduce((a, b) => a + b.value, 0),
      trend_total: data.reduce((a, b) => a + b.value_trend, 0),
      trend_comparison_total: data?.[0].value_trend_comparison !== undefined ? data.reduce((a, b) => a + (b.value_trend_comparison || 0), 0) : undefined
    };
  }, [summary, data]);
  const isGroupBased = stats_widget.settings.metrics.length === 1;

  const renderCustomizedLabel = useCallback((props) => {
    
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value, value_trend, value_trend_comparison, width } = props;

    if (!value || percent < .05)
      return <></>;

    const metric = stats_widget.settings.metrics[isGroupBased ? 0 : index];
    const styles = getStatsWidgetChartMetricStyles(_t, value, value_trend, value_trend_comparison, metric, index, stats_widget, stats_custom_metrics.stats_custom_metrics);
    const radius = innerRadius - 15 + (outerRadius + 15 - innerRadius) * 0.5;
    let x = cx + radius * Math.cos(-midAngle * RADIAN) - 10;
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const display_value = getStatsWidgetMetricDataFormatted(_t, metric, value, organization);
    const charLength = ((display_value + '').length * 6) + 7;

    return (
      <>
        {styles.boxColor && <rect width={charLength} height={17} x={x - (x > cx ? 3 : (charLength / 2))} y={y - 8} rx={3} fill={styles.boxColor}/>}
        <text x={x} y={y} fill={styles.color} textAnchor={x > cx ? 'start' : 'middle'} dominantBaseline="central" fontSize={11}>
          {display_value}
        </text>
      </>
    );
  }, [stats_widget]);
  
  const flattened_metrics = has_trend ? [...stats_widget.settings.metrics, ...stats_widget.settings.metrics] : stats_widget.settings.metrics;
  const legendPlacement = useMemo(() => stats_widget.settings.ui?.legend?.placement || 'bottom', [stats_widget.settings.ui?.legend?.placement]);
  const legendVertical = useMemo(() => ['left', 'right'].includes(legendPlacement), [legendPlacement]);

  return <div className={`widget-type-${stats_widget.type}`}>
    {stats_widget.settings.summary && summary && <div className='summary'>
      {stats_widget.settings.metrics.length === 1 ? <StatsWidgetMetricData
        t={_t}
        stats_widget={stats_widget}
        metric_settings={stats_widget.settings.metrics[0]}
        metric={metrics[0]}
        value={total}
        trend_value={trend_total}
        trend_comparison={summary.tc?.[0]}
        trend_period={summary.tp}
        metric_data_dicts={metric_data_dicts}
        organization={organization}
        exclude_styles
      /> : <StatsWidgetMetricData
        t={_t}
        stats_widget={stats_widget}
        metric_settings={{ thresholds: [] } as unknown as IPreviewStatsWidgetSettingsMetricProperty}
        metric={metrics[0]}
        value={total}
        trend_value={trend_total}
        trend_comparison={trend_comparison_total}
        trend_period={summary.tp}
        metric_data_dicts={metric_data_dicts}
        organization={organization}
        exclude_styles
      />}
      <span className='title total'>{_t('TOTAL')}</span>
    </div>}
    <PieChart
      width={innerWidth}
      height={innerHeight}
      className={`legend-${legendVertical ? 'vertical' : 'horizontal'} legend-${legendPlacement}`}
    >

      <Pie
        data={data}
        key={'value'}
        dataKey={'value'}
        innerRadius={`${stats_widget.settings.ui.cutout + (has_trend ? 45 : 0)}%`}
        isAnimationActive={false}
        // label={props => {
        //   const metric = stats_widget.settings.metrics.find(m => m.value === props.name);
        //
        //   if (!metric)
        //     return props.name;
        //
        //   const { elm, txt } = getStatsWidgetMetricTitle(_t, metric, stats_custom_metrics.stats_custom_metrics, false);
        //
        //   return txt;
        // }}
        //label={props => props.value}
        label={stats_widget.settings.ui.display_values && renderCustomizedLabel}
        labelLine={false}
      >
        {data.map((entry, index) => {
          const metric = stats_widget.settings.metrics[isGroupBased ? 0 : index];
          const styles = getStatsWidgetChartMetricStyles(_t, entry.value, entry.value_trend || 0, entry.value_trend_comparison, metric, index, stats_widget, stats_custom_metrics.stats_custom_metrics);

          return <Cell key={`cell-${index}`} fill={styles.backgroundColor} stroke={styles.borderColor} strokeWidth={styles.borderWidth} />;
        })}
      </Pie>
      {has_trend && <Pie
        data={data}
        key={'value_trend'}
        dataKey={'value_trend'}
        outerRadius={'45%'}
        label={stats_widget.settings.ui.display_values && renderCustomizedLabel}
        labelLine={false}
        isAnimationActive={false}
      >
        {data.map((entry, index) => {
          const metric = stats_widget.settings.metrics[isGroupBased ? 0 : index];
          const styles = getStatsWidgetChartMetricStyles(_t, entry.value, 0, undefined, metric, index, stats_widget, stats_custom_metrics.stats_custom_metrics, true);

          return <Cell key={`cell-${index}_trend`} fill={styles.backgroundColor} stroke={styles.borderColor} strokeWidth={styles.borderWidth} opacity={.5} />;
        })}
      </Pie>}
      {!!stats_widget.settings.ui.legend && <Legend content={<WidgetChartLegend stats_widget={stats_widget} data={data} group_based={stats_widget.settings.metrics.length === 1} t={_t} metrics={flattened_metrics} stats_custom_metrics={stats_custom_metrics}/>}
        align={legendVertical ? stats_widget.settings.ui.legend!.placement as any : undefined}
        layout={legendVertical ? 'vertical' : 'horizontal'}
        verticalAlign={legendVertical ? 'middle' : legendPlacement as any}
      />}
      <Tooltip content={<WidgetChartTooltip organization={organization} group_based={stats_widget.settings.metrics.length === 1} t={_t} metrics={flattened_metrics} stats_custom_metrics={stats_custom_metrics} />} isAnimationActive={false} cursor={{ fill: 'var(--flex-grey1003)' }} />
    </PieChart>
  </div>;
});
