import { JourneyDraftActions } from '@Store';
import { IPreviewJourneyDraft } from 'atlas-shared';
import { fetchJourneyDrafts } from '@Api';

export const actionFetchJourneyDrafts = () => {
  return async (dispatch) => {
    dispatch(actionLoadingJourneyDrafts());
    const journey_drafts = await fetchJourneyDrafts();

    dispatch(actionSetJourneyDrafts(journey_drafts));
    return journey_drafts;
  };
};

export const actionLoadingJourneyDrafts = () => ({
  type: JourneyDraftActions.LOADING,
  payload: []
});

export const actionSetJourneyDrafts = (payload: Array<IPreviewJourneyDraft>) => ({
  type: JourneyDraftActions.SET_STATE,
  payload: payload
});

export const actionAddJourneyDraft = (payload: IPreviewJourneyDraft) => ({
  type: JourneyDraftActions.ADD_STATE,
  payload: payload
});

export const actionUpdateJourneyDraft = (payload: IPreviewJourneyDraft) => ({
  type: JourneyDraftActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveJourneyDraft = (id: IPreviewJourneyDraft['id']) => ({
  type: JourneyDraftActions.REMOVE_STATE,
  payload: id
});
