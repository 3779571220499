import { IPreviewVoiceVoicemailMessage } from 'atlas-shared';
import { IVoiceVoicemailMessageStore, VoiceVoicemailMessageActions, TVoiceVoicemailMessageActionTypes } from '@Store';

export const VoiceVoicemailMessageReducer = (state: IVoiceVoicemailMessageStore = {
  loaded: false,
  loading: false,
  voice_voicemail_messages: [],
  dict: {}
}, action: TVoiceVoicemailMessageActionTypes): IVoiceVoicemailMessageStore => {
  switch (action.type) {
  case VoiceVoicemailMessageActions.LOADING:
    return { ...state, loading: true };
  case VoiceVoicemailMessageActions.SET_STATE:
    const dict: IVoiceVoicemailMessageStore['dict'] = {};

    action.payload.forEach((voice_voicemail_message: IPreviewVoiceVoicemailMessage) => dict[voice_voicemail_message.id] = voice_voicemail_message);

    return { ...state, loaded: true, loading: false, voice_voicemail_messages: action.payload, dict };
  case VoiceVoicemailMessageActions.ADD_STATE:
    return { ...state, voice_voicemail_messages: [...state.voice_voicemail_messages, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceVoicemailMessageActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_voicemail_messages: state.voice_voicemail_messages.filter((item: IPreviewVoiceVoicemailMessage) => item.id !== action.payload), dict };
  }
  case VoiceVoicemailMessageActions.UPDATE_STATE:
    const voice_voicemail_messages = [...state.voice_voicemail_messages];
    const index = state.voice_voicemail_messages.findIndex((voice_voicemail_message: IPreviewVoiceVoicemailMessage) => voice_voicemail_message.id === action.payload.id);

    if (!~index)
      voice_voicemail_messages.push(action.payload);
    else
      voice_voicemail_messages[index] = action.payload;

    return { ...state, voice_voicemail_messages, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
