import { IPreviewTab } from 'atlas-shared';

export enum TabActions {
  SET_STATE = 'TAB_SET_STATE',
  ADD_STATE = 'TAB_ADD_STATE',
  REMOVE_STATE = 'TAB_REMOVE_STATE',
  GET_STATE = 'TAB_GET_STATE',
  UPDATE_STATE = 'TAB_UPDATE_STATE',
  LOADING = 'TAB_LOADING',
}

export interface ITabLoading {
  type: TabActions.LOADING;
}

export interface ITabSetAction {
  type: TabActions.SET_STATE;
  payload: Array<IPreviewTab>;
}

export interface ITabUpdateAction {
  type: TabActions.UPDATE_STATE;
  payload: IPreviewTab;
}

export interface ITabAddAction {
  type: TabActions.ADD_STATE;
  payload: IPreviewTab;
}

export interface ITabRemoveAction {
  type: TabActions.REMOVE_STATE;
  payload: IPreviewTab['id'];
}

export interface ITabStore {
  loaded: boolean;
  loading: boolean;
  tabs: Array<IPreviewTab>;
  dict: Record<IPreviewTab['id'], IPreviewTab>;
}

export type TTabActionTypes = ITabSetAction | ITabUpdateAction | ITabAddAction | ITabRemoveAction | ITabLoading;
