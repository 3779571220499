import { RestRequest } from '@Utils';
import { IPreviewTariffParent, ISharedTariffParent, ISharedCreateTariffParent, ISharedPatchTariffParent } from 'atlas-shared';

export const fetchTariffParents = async (): Promise<Array<IPreviewTariffParent>> => {
  return RestRequest.get<Array<IPreviewTariffParent>>('tariff_parent', { socket_id: global.socketId });
};

export const fetchTariffParent = async (id: IPreviewTariffParent['id']): Promise<ISharedTariffParent> => {
  return RestRequest.get<ISharedTariffParent>(`tariff_parent/${id}`, { socket_id: global.socketId });
};

export const createTariffParent = (payload: ISharedCreateTariffParent) => {
  return RestRequest.post<any, ISharedTariffParent>('tariff_parent', payload, { socket_id: global.socketId });
};

export const patchTariffParent = (id: IPreviewTariffParent['id'], payload: ISharedPatchTariffParent) => {
  return RestRequest.patch<any, ISharedTariffParent>(`tariff_parent/${id}`, payload, { socket_id: global.socketId });
};

export const deleteTariffParent = (id: IPreviewTariffParent['id']) => {
  return RestRequest.delete(`tariff_parent/${id}`, { socket_id: global.socketId });
};

export const getTariffParentUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tariff_parent/update/schema');
};

export const getTariffParentCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('tariff_parent/create/schema');
};

export const getTariffParentPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('tariff_parent/patch/schema');
};
