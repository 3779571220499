import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const SearchIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="24" y1="24" x2="16.65" y2="16.65"></line>
  </SvgIcon>;
};
