import React, { useEffect, useState } from 'react';
import '../admin.list.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { IPreviewPhonenumberStock, TPhonenumberStockSortableListCol } from 'atlas-shared';
import { Table } from 'antd';
import { AdminListPageHeader, ListPageEmpty } from '@Components';
import { IWithListProps, IWithListSorterProps, withList } from '@Hocs/with.list';
import { useAbilities, useAuth, useOrganizations } from '@Hooks';
import { PhonenumberStockListColumns } from '@Utils';
import { IAuth } from '@Store';
import { searchPhonenumberStock } from '@Api';

const PhonenumberStockSearch = ({ filters, forcedFilters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams }: IWithListProps<IPreviewPhonenumberStock, TPhonenumberStockSortableListCol>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const abilities = useAbilities();
  const [rows, setRows] = useState<Array<any>>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const organizations = useOrganizations();

  const columns: ColumnsType<IPreviewPhonenumberStock> = React.useMemo(() => {
    return PhonenumberStockListColumns(t, navigate, false, organizations, abilities, auth, [], filters, sorter);
  }, [t, navigate, organizations, abilities, auth, filters, sorter]);
  
  useEffect(() => {
    setLoading(true);
    searchPhonenumberStock({
      conditions: Object.fromEntries(Object.entries({ ...filters, ...forcedFilters }).map(([key, value]) => ([key, value.length > 1 ? { in: value } : { [['number'].includes(key) ? 'like' : 'eq']: value[0] }]))),
      order_by: sorter.map(({ column, order }) => ({ column, order: order === 'descend' ? 'DESC' : 'ASC' })),
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize
    })
      .then(r => {
        setRows(r.items);
        setTotal(r.count);
        setLoading(false);
      })
      .catch(e => setError(e.toString()))
    ;
  }, [pagination, sorter, filters, forcedFilters]);

  if (error)
    return <div className='page-error'><div className='error'>{error}</div></div>;

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('PHONENUMBER_STOCKS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
         
    />
    <div className='table-wrp'>
      <Table
        columns={columns}
        dataSource={rows}
        defaultExpandAllRows={true}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total
        }}
        loading={loading}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TPhonenumberStockSortableListCol, order: s.order as IWithListSorterProps<IPreviewPhonenumberStock, TPhonenumberStockSortableListCol>['order'] }))
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />
        }}
      />
    </div>
  </div>;
};

export const PhonenumberStockSearchView = React.memo(withList<IPreviewPhonenumberStock, TPhonenumberStockSortableListCol>(PhonenumberStockSearch, {
  filters: {},
  sorter: [{ column: 'number', order: 'descend' }],
  pagination: { current: 1, pageSize: 10 }
}));
