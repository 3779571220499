import {
  ConversationFilterSortOrderEnum,
  ConversationLastMessageByEnum,
  default_conversation_filter_sort,
  IPreviewConversation,
  ISharedContact,
  ISharedDraft,
  TConversationVoiceCall,
  Undefinable,
  ChannelEnum,
  ISharedFullConversation
} from 'atlas-shared';
import { ConversationActions, TConversationActionTypes, IConversationStore, TConversationActionTypes as TConversationActionTypesBase } from '@Store';
import { ConversationReducer as ConversationReducerBase, } from './abstract/conversation.reducer.abstract';

import { clone, getActiveVoiceCalls, sortByProperty } from '@Utils';

export const ConversationReducer = (state: IConversationStore = {
  loaded: false,
  loading: false,
  conversations: [],
  dict: {},
  full_conversations_dict: {},
  full_conversations_loading: [],
  draft_dict: {},
  current_dashboard_conversation_id: null
}, action: TConversationActionTypes & {store: any}) => {

  switch (action.type) {
  case ConversationActions.ADDS_STATE:
    const dict = { ...state.dict };
    const ids = action.payload.map(conversation => conversation.id);

    action.payload.forEach((conversation: IPreviewConversation) => dict[conversation.id] = conversation);

    return { ...state, loaded: true, loading: false, conversations: [...state.conversations.filter(conversation => !ids.includes(conversation.id)), ...action.payload], dict };
  case ConversationActions.ADD_FULL_STATE:
  case ConversationActions.UPDATE_FULL_STATE:

    const draft_dict = { ...state.draft_dict };

    if (action.payload.drafts)
      action.payload.drafts.forEach(draft => {
        if (draft.conversation_id)
          draft_dict[draft.id] = { conversation_id: draft.conversation_id };
      });

    let conversations = [...state.conversations];
    const conversation_index = conversations.findIndex(conversation => conversation.id === action.payload.id);

    if (~conversation_index) {
      const { state_updated_at: _state_updated_at, state_updated_text: _state_updated_text, ...conversation } = conversations[conversation_index];

      conversations[conversation_index] = conversation;
    }

    const payload = { ...(state.full_conversations_dict[action.payload.id] || { messages: [], drafts: [] }), ...action.payload };
    const messages: ISharedFullConversation['messages'] = sortByProperty(payload.messages);

    return {
      ...state,
      conversations,
      full_conversations_dict: {
        ...state.full_conversations_dict,
        [action.payload.id]: {
          ...payload,
          messages,
          drafts: sortByProperty(payload.drafts, 'created_at', false),
          active_calls: getActiveVoiceCalls(messages)
        }
      },
      draft_dict
    };
  case ConversationActions.REMOVE_STATE: {
    const { [action.payload]: _deleted, ...dict } = { ...state.dict };

    return { ...state, conversations: state.conversations.filter((item: IPreviewConversation) => item.id !== action.payload), dict };
  }
  case ConversationActions.REMOVE_FULL_STATE: {
    const full_conversations_dict: IConversationStore['full_conversations_dict'] = {};

    [
      ...action.store.tabs.tabs.filter(tab => tab.agentprofile_id === action.store.auth.user_status.agentprofile_id).map(tab => tab.conversation_id),
      state.current_dashboard_conversation_id
    ].forEach(conversation_id => {
      if (state.full_conversations_dict[conversation_id])
        full_conversations_dict[conversation_id] = state.full_conversations_dict[conversation_id];
    });

    return { ...state, full_conversations_dict };
    // const { [action.payload]: deleted, ...full_conversations_dict } = { ...state.full_conversations_dict };
    //
    // return { ...state, full_conversations_dict };
  }
  case ConversationActions.ADD_STATE:
  case ConversationActions.UPDATE_STATE: {
    let conversations = [...state.conversations];
    const conversation = { ...action.payload };
    //const full_conversations_dict = { ...state.full_conversations_dict };
    const index = conversations.findIndex(it => it.id === action.payload.id);
    const now = Date.now();

    if (!~index) {
      conversations.push(action.store ? { ...action.payload, state_updated_at: now, state_updated_text: 'NEW_CONVERSATION' } : action.payload);
    }
    else {
      const current = conversations[index];

      if (current.state_updated_at) {
        conversation.state_updated_at = current.state_updated_at;
        conversation.state_updated_text = current.state_updated_text;
      }

      let updated_key: Undefinable<string> = ['status', 'user_id', 'queue', 'disposition_id', 'resolution_time', 'response_time', 'flag'].find(key => current[key] !== conversation[key]);

      if (!updated_key && current.contact?.id !== conversation.contact?.id)
        updated_key = 'contact';

      const new_message = current.last_message_at !== conversation.last_message_at && conversation.last_message_by === ConversationLastMessageByEnum.Contact;

      if (updated_key && !['CONVERSATION_NEW_MESSAGE', 'NEW_CONVERSATION'].includes(conversation?.state_updated_text || ''))
        conversation.state_updated_text = `CONVERSATION_UPDATE_${updated_key.toUpperCase()}`;

      if (new_message)
        conversation.state_updated_text = 'CONVERSATION_NEW_MESSAGE';

      if (updated_key || new_message)
        conversation.state_updated_at = now;

      // if (conversation?.state_updated_at && conversation.state_updated_at < now - 60000)  {
      //   conversation.state_updated_text = undefined;
      //   conversation.state_updated_at = undefined;
      // }

      conversations[index] = conversation;
    }

    if (action.store?.conversation_filters?.loaded) {
      const { order, column } = action.store.conversation_filters.dict[action.store.auth.user_status.conversation_filter_id]?.sort_by || default_conversation_filter_sort;

      if (order && column) {
        const reversed = order === ConversationFilterSortOrderEnum.Desc ? -1 : 1;

        conversations = [...conversations].sort((a, b) => a[column] < b[column] ? -1 * reversed : 1 * reversed);
      }
    }

    // if (full_conversations_dict[action.payload.id])
    //   full_conversations_dict[action.payload.id] = {
    //     ...full_conversations_dict[action.payload.id],
    //     ...action.payload as any,
    //     //contact: full_conversations_dict[action.payload.id].contact
    //   };

    return {
      ...state,
      conversations,
      // full_conversations_dict,
      dict: { ...state.dict, [action.payload.id]: conversation }
    };
  }

  case ConversationActions.UPDATE_CONVERSATION_DRAFT:
  case ConversationActions.ADD_CONVERSATION_DRAFT: {

    const new_state = { ...state };
    const full_conversations_dict = { ...state.full_conversations_dict };

    if (action.payload.conversation_id && full_conversations_dict[action.payload.conversation_id]) {

      new_state.draft_dict = { ...state.draft_dict, [action.payload.id]: { conversation_id: action.payload.conversation_id } };

      full_conversations_dict[action.payload.conversation_id] = {
        ...full_conversations_dict[action.payload.conversation_id],
        drafts: sortByProperty<ISharedDraft>([...full_conversations_dict[action.payload.conversation_id].drafts.filter(draft => draft.id !== action.payload.id), action.payload], 'created_at', false)
      };

      new_state.full_conversations_dict = full_conversations_dict;
    }

    return new_state;
  }

  case ConversationActions.REMOVE_CONVERSATION_DRAFT: {

    const conversation_id = state.draft_dict[action.payload]?.conversation_id;

    if (!conversation_id)
      return state;

    const conversation = state.full_conversations_dict[conversation_id];

    if (!conversation)
      return state;

    const full_conversations_dict = { ...state.full_conversations_dict };

    full_conversations_dict[conversation.id] = {
      ...full_conversations_dict[conversation.id],
      drafts: full_conversations_dict[conversation.id].drafts.filter(draft => draft.id !== action.payload)
    };

    return {
      ...state,
      full_conversations_dict
    };
  }

  case ConversationActions.UPDATE_CONVERSATION_CONTACT:
    const full_conversations_dict = { ...state.full_conversations_dict };
    const contact: ISharedContact = action.payload;

    Object.entries(full_conversations_dict).forEach(([_, conversation]) => {
      if (conversation.contact?.id === contact.id)
        full_conversations_dict[conversation.id] = {
          ...conversation,
          contact
        };
    });
    return {
      ...state,
      full_conversations_dict
    };

  case ConversationActions.SET_CONVERSATION_CONTACT:
  {
    const full_conversations_dict = { ...state.full_conversations_dict };
    const { conversation_id, contact } = action.payload;

    full_conversations_dict[conversation_id] = {
      ...full_conversations_dict[conversation_id],
      contact
    };
    return {
      ...state,
      full_conversations_dict
    };
  }

  case ConversationActions.SET_CONVERSATION_JOURNEY:
  {
    const full_conversations_dict = { ...state.full_conversations_dict };
    const { conversation_id, journey } = action.payload;

    full_conversations_dict[conversation_id] = {
      ...full_conversations_dict[conversation_id],
      journey
    };
    return {
      ...state,
      full_conversations_dict
    };
  }

  case ConversationActions.ADD_CONVERSATION_MESSAGE:
  case ConversationActions.UPDATE_CONVERSATION_MESSAGE: {

    const full_conversations_dict = { ...state.full_conversations_dict };

    if (action.payload.conversation_id && full_conversations_dict[action.payload.conversation_id]) {
      const current_voice_call = action.payload.channel === ChannelEnum.Voice && full_conversations_dict[action.payload.conversation_id].messages.find(message => message.id === action.payload.id && message.channel === action.payload.channel);
      const messages = sortByProperty([
        ...full_conversations_dict[action.payload.conversation_id].messages.filter(message => !(message.id === action.payload.id && message.channel === action.payload.channel)),
        {
          ...action.payload,
          voice_call_bridges: (current_voice_call as TConversationVoiceCall)?.voice_call_bridges || [],
          voice_recordings: (current_voice_call as TConversationVoiceCall)?.voice_recordings || [],
          voice_voicemail_messages: (current_voice_call as TConversationVoiceCall)?.voice_voicemail_messages || [],
          voice_chanspy_calls: (current_voice_call as TConversationVoiceCall)?.voice_chanspy_calls || [],
        }
      ]);

      full_conversations_dict[action.payload.conversation_id] = {
        ...full_conversations_dict[action.payload.conversation_id],
        messages,
        active_calls: getActiveVoiceCalls(messages)
      };
    }

    return {
      ...state,
      full_conversations_dict
    };
  }

  case ConversationActions.ADD_FULL_STATE_LOADING:
    return {
      ...state,
      full_conversations_loading: [...state.full_conversations_loading, action.payload]
    };

  case ConversationActions.REMOVE_FULL_STATE_LOADING:
    return {
      ...state,
      full_conversations_loading: [...state.full_conversations_loading.filter(id => id !== action.payload)]
    };

  case ConversationActions.SET_CURRENT_DASHBOARD_CONVERSATION_ID:
    return { ...state, current_dashboard_conversation_id: action.payload };

  case ConversationActions.ADD_CONVERSATION_CONVERSATION_WATCHER: {
    
    const output = { ...state };

    {
      const conversations = clone(state.conversations);
      const conversation = conversations.find(conversation => conversation.id === action.payload.conversation_id);

      if (conversation) {
        if (!conversation.conversation_watchers)
          conversation.conversation_watchers = [];

        conversation.conversation_watchers.push(action.payload);

        output.conversations = conversations;
      }
    }

    {
      const full_conversations_dict = clone(state.full_conversations_dict);
      const conversation = full_conversations_dict[action.payload.conversation_id];

      if (conversation) {
        if (!conversation.conversation_watchers)
          conversation.conversation_watchers = [];

        conversation.conversation_watchers.push(action.payload);

        output.full_conversations_dict = full_conversations_dict;
      }
    }

    return output;
  }

  case ConversationActions.REMOVE_CONVERSATION_CONVERSATION_WATCHER: {
    const output = { ...state };
    const conversations = clone(state.conversations);
    const conversation = conversations.find(conversation => conversation.id === action.payload.conversation_id);

    if (conversation) {
      if (conversation.conversation_watchers) {
        conversation.conversation_watchers = conversation.conversation_watchers.filter(conversation_watcher => conversation_watcher.id !== action.payload.id);
        output.conversations = conversations;
      }

    }

    const full_conversations_dict = clone(state.full_conversations_dict);
    const full_conversation = full_conversations_dict[action.payload.conversation_id];
    
    if (full_conversation) {
      if (full_conversation.conversation_watchers) {
        full_conversation.conversation_watchers = full_conversation.conversation_watchers.filter(conversation_watcher => conversation_watcher.id !== action.payload.id);
        output.full_conversations_dict = full_conversations_dict;
      }
    }

    return output;
  }

  case ConversationActions.ADD_CONVERSATION_VOICE_RECORDING:
  case ConversationActions.UPDATE_CONVERSATION_VOICE_RECORDING: {
    if (state.full_conversations_dict[action.payload.conversation_id]) {
      const conversation = clone(state.full_conversations_dict[action.payload.conversation_id]);
      const voice_call = conversation.messages.find(message => message.channel === ChannelEnum.Voice && message.id === action.payload.voice_call_id);

      if (voice_call) {
        (voice_call as TConversationVoiceCall).voice_recordings = [
          ...(voice_call as TConversationVoiceCall).voice_recordings.filter(recording => recording.id !== action.payload.id),
          action.payload
        ];
        conversation.active_calls = getActiveVoiceCalls(conversation.messages);
      }

      return {
        ...state,
        full_conversations_dict: {
          ...state.full_conversations_dict,
          [conversation.id]: conversation
        }
      };
    }

    return state;

  }

  case ConversationActions.ADD_CONVERSATION_VOICE_VOICEMAIL_MESSAGE:
  case ConversationActions.UPDATE_CONVERSATION_VOICE_VOICEMAIL_MESSAGE: {
    if (state.full_conversations_dict[action.payload.conversation_id]) {
      const conversation = clone(state.full_conversations_dict[action.payload.conversation_id]);
      const voice_call = conversation.messages.find(message => message.channel === ChannelEnum.Voice && message.id === action.payload.voice_call_id);

      if (voice_call) {
        (voice_call as TConversationVoiceCall).voice_voicemail_messages = [
          ...(voice_call as TConversationVoiceCall).voice_voicemail_messages.filter(voicemail => voicemail.id !== action.payload.id),
          action.payload
        ];
      }

      return {
        ...state,
        full_conversations_dict: {
          ...state.full_conversations_dict,
          [conversation.id]: conversation
        }
      };
    }

    return state;

  }
  case ConversationActions.ADD_CONVERSATION_VOICE_CALL_BRIDGE:
  case ConversationActions.UPDATE_CONVERSATION_VOICE_CALL_BRIDGE: {
    if (state.full_conversations_dict[action.payload.conversation_id]) {
      const conversation = clone(state.full_conversations_dict[action.payload.conversation_id]);
      const voice_call = conversation.messages.find(message => message.channel === ChannelEnum.Voice && message.id === action.payload.voice_call_id);

      if (voice_call) {
        (voice_call as TConversationVoiceCall).voice_call_bridges = [
          ...(voice_call as TConversationVoiceCall).voice_call_bridges.filter(recording => recording.id !== action.payload.id),
          action.payload
        ].sort((a, b) => a.created_at < b.created_at ? -1 : 1);
        conversation.active_calls = getActiveVoiceCalls(conversation.messages);
      }

      return {
        ...state,
        full_conversations_dict: {
          ...state.full_conversations_dict,
          [conversation.id]: conversation
        }
      };
    }

    return state;
  }

  default:
    return ConversationReducerBase(state, action as TConversationActionTypesBase);
  }
};
