import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { ISharedCreateUser, ISharedUpdateUser, ISharedUser, IPreviewQueue, IPreviewAgentprofile, IPreviewStatsReport, IPreviewPause, IPreviewOrganization, IPreviewAdminprofile, IPreviewSuperadminProfile, IPreviewSkill, EnumOptions, LoginStrategyEnum, RoleEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { entitiesToOptions, onFormSaved } from '@Utils';

export interface IUserFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  queues: Array<IPreviewQueue>;
  agentprofiles: Array<IPreviewAgentprofile>;
  stats_reports: Array<IPreviewStatsReport>;
  pauses: Array<IPreviewPause>;
  organizations: Array<IPreviewOrganization>;
  adminprofiles: Array<IPreviewAdminprofile>;
  superadmin_profiles: Array<IPreviewSuperadminProfile>;
  skills: Array<IPreviewSkill>;
}

export const UserFormOptions = (user: ISharedCreateUser | ISharedUser, props: IUserFormOptionsProps, t: TFunction): IFormOptions => ({
  settings_auto_accept_V_queues: entitiesToOptions<IPreviewQueue>(props.queues, 'queue'),
  settings_auto_accept_M_queues: entitiesToOptions<IPreviewQueue>(props.queues, 'queue'),
  settings_auto_accept_F_queues: entitiesToOptions<IPreviewQueue>(props.queues, 'queue'),
  settings_stats_reports_agentprofile_id: entitiesToOptions<IPreviewAgentprofile>(props.agentprofiles, 'agentprofile'),
  settings_stats_reports_stats_report_id: entitiesToOptions<IPreviewStatsReport>(props.stats_reports, 'stats_report'),
  login_pause: entitiesToOptions<IPreviewPause>(props.pauses, 'pause', it => !it.global),
  __organizations_organization_id: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  __organizations_adminprofile_id: entitiesToOptions<IPreviewAdminprofile>(props.adminprofiles, 'adminprofile'),
  __organizations_superadmin_profile_id: entitiesToOptions<IPreviewSuperadminProfile>(props.superadmin_profiles, 'superadmin_profile'),
  skills_level_one: entitiesToOptions<IPreviewSkill>(props.skills, 'skill'),
  skills_level_two: entitiesToOptions<IPreviewSkill>(props.skills, 'skill'),
  skills_level_three: entitiesToOptions<IPreviewSkill>(props.skills, 'skill'),
  login_strategy: EnumOptions('LOGIN_STRATEGY', LoginStrategyEnum, t),
  __organizations_role: EnumOptions('ROLE', RoleEnum, t),
});

export const UserComponents: IForm['components'] = {};
export const UserAdditionalParams: (auth: IAuth, props: IUserFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IUserFormOptionsProps) => ({
  login_pause: {
    onModalAdd: {
      key: 'queue-login_pause-add',
      path: '@Components/page-views/pause',
      component: 'PauseFormAddView',
      item_text: 'PAUSE'
    }
  }
});

export const UserEditForm = (user: ISharedUser, props: IUserFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `user_edit_${user.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: user.username, path: 'username', shouldUpdate: () => false },
    { ref: 'login_strategy' },
    { ref: 'email' },
    { ref: 'active' },
    { ref: 'two_factor_auth' },
    { ref: 'first_name' },
    { ref: 'last_name' },
    { ref: 'mail_capacity' },
    { ref: 'form_capacity' },
    { ref: 'settings' },
    { ref: 'voice_license' },
    { ref: 'voice_settings' },
    { ref: 'login_pause' },
    { ref: 'dashboard_license' },
    { ref: 'reporting_license' },
    { ref: '__organizations' },
    { ref: 'skills' }
  ]
});

export const UserAddForm = (user: ISharedCreateUser, props: IUserFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'user_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'username' },
    { ref: 'login_strategy' },
    { ref: 'email' },
    { ref: 'active' },
    { ref: 'two_factor_auth' },
    { ref: 'first_name' },
    { ref: 'last_name' },
    { ref: 'password' },
    { ref: 'mail_capacity' },
    { ref: 'form_capacity' },
    { ref: 'settings' },
    { ref: 'voice_license' },
    { ref: 'voice_settings' },
    { ref: 'login_pause' },
    { ref: 'dashboard_license' },
    { ref: 'reporting_license' },
    { ref: '__organizations' },
    { ref: 'skills' }
  ]
});

export const UserAddInitialValues: ISharedCreateUser = {} as ISharedCreateUser;

export const UserEditInitialValues = (user: ISharedUser): ISharedUpdateUser => ({
  login_strategy: user.login_strategy,
  email: user.email,
  active: user.active,
  two_factor_auth: user.two_factor_auth,
  first_name: user.first_name,
  last_name: user.last_name,
  mail_capacity: user.mail_capacity,
  form_capacity: user.form_capacity,
  settings: user.settings,
  voice_license: user.voice_license,
  voice_settings: user.voice_settings,
  login_pause: user.login_pause,
  dashboard_license: user.dashboard_license,
  reporting_license: user.reporting_license,
  __organizations: user.__organizations,
  skills: user.skills
});

export const onUserFormSaved = (t: TFunction, action: 'edit' | 'add', user: ISharedUser, navigate: NavigateFunction): void => {
  navigate(onFormSaved('users'));
};
