import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const MicrosoftIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.363632 0.36377H11.4225V11.4227H0.363632V0.36377Z" fill="#F25022"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5774 0.36377H23.6364V11.4227H12.5774V0.36377Z" fill="#7FBA00"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.363632 12.5774H11.4225V23.6363H0.363632V12.5774Z" fill="#00A4EF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5774 12.5774H23.6364V23.6363H12.5774V12.5774Z" fill="#FFB900"/>
  </SvgIcon>;
};
