import React from 'react';
import { deleteUser } from '@Api';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewUser, PermissionEnum, TUserSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listCloneButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const UserListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewUser) => React.ReactElement> = [], filters: IWithListProps<IPreviewUser, TUserSortableListCol>['filters'], sorter: IWithListProps<IPreviewUser, TUserSortableListCol>['sorter']): ColumnsType<IPreviewUser> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewUser, b: IPreviewUser) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('USERNAME'),
      dataIndex: 'username',
      key: 'username',
      sorter: (a: IPreviewUser, b: IPreviewUser) => tableColumnStringSorter(a.username, b.username),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'username')?.order || null,
      filteredValue: filters.username || null,
      ...GetColumnSearchLikeProps(t, 'username', t('USERNAME')),
      className: 'table-cell-username',
    },
    {
      title: t('ACTIVE'),
      dataIndex: 'active',
      key: 'active',
      render: (active: IPreviewUser['active'], user: IPreviewUser) => tableColumnBooleanRender(t, active),
      sorter: (a: IPreviewUser, b: IPreviewUser) => (a.active ? 1 : 0) > (b.active ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'active')?.order || null,
      filteredValue: filters.active || null,
      ...GetColumnSearchProps(t, 'active', t('ACTIVE'), [{ id: true, title: t('YES') }, { id: false, title: t('NO') }]),
      className: 'table-cell-active',
    },
    {
      title: t('FIRST_NAME'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a: IPreviewUser, b: IPreviewUser) => tableColumnStringSorter(a.first_name, b.first_name),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'first_name')?.order || null,
      filteredValue: filters.first_name || null,
      ...GetColumnSearchLikeProps(t, 'first_name', t('FIRST_NAME')),
      className: 'table-cell-first_name',
    },
    {
      title: t('LAST_NAME'),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a: IPreviewUser, b: IPreviewUser) => tableColumnStringSorter(a.last_name, b.last_name),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'last_name')?.order || null,
      filteredValue: filters.last_name || null,
      ...GetColumnSearchLikeProps(t, 'last_name', t('LAST_NAME')),
      className: 'table-cell-last_name',
    },
    showDeleted && { 
      title: t('DELETED'), 
      dataIndex: 'deleted_at',
      key: 'deleted_at',
      className: 'table-cell-deleted_at',
      render: (deleted_at: IPreviewUser['deleted_at']) => deleted_at ? timeSince(t, new Date(deleted_at), true) : '',
      sorter: (a: IPreviewUser, b: IPreviewUser) => tableColumnDatetimeSorter(a.deleted_at, b.deleted_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'deleted_at')?.order || null,
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewUser) => (
        <div className={'table-operation-wrp'}>
          {!row.deleted_at && buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Insert, PermissionEnum.User, row) && listCloneButton(t, navigate, 'user', row)}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, ['user', 'user_status'], row)}
          {!row.deleted_at && row.id !== auth.user.id && abilities.can(null, CrudEnum.Update, PermissionEnum.User, row) && listEditButton(t, navigate, 'user', row)}
          {!row.deleted_at && row.id !== auth.user.id && abilities.can(null, CrudEnum.Delete, PermissionEnum.User, row) && listDeleteButton(t, row, deleteUser)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewUser>;

};
