import React from 'react';
import { EdgeProps, getEdgeCenter, Position } from 'react-flow-renderer';
import { JourneyHelpCircleIcon } from '@Assets/icons';
import { Icon } from '@Components';

const getSmoothStepPathCustom = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}: {
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  targetPosition: Position;
}) => {
  const downWize = sourceY < targetY;
  const leftWize = sourceX < targetX;
  const parts: Array<[number, number]> = [];
  let X = sourceX;
  let Y = sourceY;

  targetY += 7;
  const halfY = (((sourceY + (downWize ? 14 : 0)) - (targetY)) / 2);

  if (!downWize) {
    Y += 8;
    parts.push([X, Y]);

    if (!leftWize)
      X -= 46;
    else
      X += 240;

    parts.push([X, Y]);
  }
  
  Y = sourceY - halfY;

  parts.push([X, Y]);

  X -= X - targetX;

  if (!downWize) {
    X -= 30;
    parts.push([X, Y]);
    Y -= halfY;
    parts.push([X, Y]);
  }

  parts.push([X, Y]);

  //return `M ${sourceX},${sourceY}L ${parts.map(([X, Y]) => `${X},${Y}Q ${X - 5},${Y} ${X - 5},${Y - 5}L`).join(' ')} ${targetX},${targetY}`;
  return `M ${sourceX},${sourceY}L ${parts.map(([X, Y]) => `${X},${Y}L`).join(' ')} ${targetX},${targetY}`;
};

const foreignObjectSize = 22;
const EdgeView = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}: EdgeProps) => {
  const edgePath = getSmoothStepPathCustom({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const hasError: boolean = !!data.errors?.length;

  return (
    <>
      <path
        style={{ ...style, padding: 5, cursor: 'pointer', ...(hasError ? { stroke: 'var(--red-main)' } : {}) }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <path
        style={{ ...style, strokeOpacity: 0, strokeWidth: 14 }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {hasError &&
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - 2 - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <Icon
          icon={JourneyHelpCircleIcon}
          iconProps={{ size: foreignObjectSize, style: { color: 'var(--red-main)' }, stroke: 2 }}
          tooltip={
            {
              title: data.errors.map(e => e.reason).join(', '),
              overlayClassName: 'error',
              overlayInnerStyle: { textAlign: 'center' }
            }
          }
        />
      </foreignObject>}
    </>
  );
};

export default React.memo(EdgeView);
