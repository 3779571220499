import { useEffect, useState } from 'react';
import { useAppDispatch } from '@Store';
import {
  ISharedFullConversation,
  TConversationInternalNote,
  TConversationMailMessage,
  TypeConversationMessage,
  Undefinable,
  ChannelEnum,
  ISharedDraft,
  TConversationVoiceCall
} from 'atlas-shared';

export const useReplyMessages = (message: Undefinable<TypeConversationMessage>, draft: Undefinable<ISharedDraft>, conversation: ISharedFullConversation): [Array<TypeConversationMessage>, Array<TypeConversationMessage>] => {
  const dispatch = useAppDispatch();
  const [replyTo, setReplyTo] = useState<Array<TypeConversationMessage>>([]);
  const [replyFrom, setReplyFrom] = useState<Array<TypeConversationMessage>>([]);

  useEffect(() => {
    const voice_call_bridges = conversation.messages.filter(m => m.channel === ChannelEnum.Voice).flatMap(v => (v as TConversationVoiceCall).voice_call_bridges);

    if (message) {
      if (message.channel === ChannelEnum.Voice)
        setReplyTo((message as TConversationVoiceCall).voice_call_bridges.map(bridge => {
          let transferred_bridge = bridge.transferred_to ? voice_call_bridges.find(b => b.id === bridge.transferred_to) : null;

          return transferred_bridge && conversation.messages.find(m => transferred_bridge && m.id !== message.id && m.channel === ChannelEnum.Voice && transferred_bridge.voice_call_id === m.id);
        }).filter(Boolean) as Array<TypeConversationMessage>);

      if (message.channel === ChannelEnum.Mail)
        setReplyTo(conversation.messages.filter(m => message.id === (m as TConversationMailMessage).reply_to && m.channel === ChannelEnum.Mail));

      else if (message.channel === ChannelEnum.InternalNote && (message as TConversationInternalNote).reply_to) {
        const [channel, id] = (message as TConversationInternalNote).reply_to!.split('-');

        setReplyTo(conversation.messages.filter(m => m.id === +id && m.channel === channel) || undefined);
      }
    }

    else if (draft?.reply_to) {
      const [channel, id] = draft.reply_to.split('-');

      setReplyTo(conversation.messages.filter(m => m.id === +id && m.channel === channel));
    }

    setReplyFrom(message ? conversation.messages.filter(_message => {
      if (message.id !== _message.id) {
        if (_message.channel === ChannelEnum.Voice && message.channel === ChannelEnum.Voice && (_message as TConversationVoiceCall).voice_call_bridges.some(bridge => (message as TConversationVoiceCall).voice_call_bridges.some(_bridge => bridge.transferred_to === _bridge.id)))
          return true;

        if (_message.channel === ChannelEnum.Mail && _message.id === (message as TConversationMailMessage).reply_to)
          return true;

        else if (_message.channel === ChannelEnum.InternalNote && (_message as TConversationInternalNote).reply_to === `${message.channel}-${message.id}`)
          return true;
      }

      return false;
    }) : []);
  }, [message, conversation]);

  return [replyTo, replyFrom];
};
