import { IconText } from '@Components';
import { SquareIcon, SquarePlusIcon } from '@Assets';
import React, { useMemo } from 'react';
import { TFunction } from 'i18next';
import { CrudEnum, OrganizationDashboardReportingTypeEnum, PermissionEnum } from 'atlas-shared/dist';
import { useAbilities, useOrganizations } from '@Hooks';
import { IStatsWidgetStore } from '@Store';

interface IProps {
  t: TFunction;
  widgetForm: number;
  setWidgetForm: (widgetForm: number) => void;
  stats_widgets: IStatsWidgetStore;
  is_dashboard: boolean;
}

export const StatsWidgetFormCreateOptions = React.memo(({ t, widgetForm, setWidgetForm, stats_widgets, is_dashboard }: IProps) => {
  const abilities = useAbilities();
  const organizations = useOrganizations();
  const canCreate = useMemo(() => abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.StatsWidget), [abilities]);
  const isAllLicensesEntry = useMemo(() => organizations.organizations.every(organization => organization[is_dashboard ? 'reduced_dashboard_license_type' : 'reduced_reporting_license_type'] === OrganizationDashboardReportingTypeEnum.Entry), [organizations]);

  return <div className='create-options'>
    {canCreate && !isAllLicensesEntry && <div onClick={() => setWidgetForm(-2)} className={widgetForm === -2 ? 'active' : ''}>
      <IconText icon={widgetForm === -2 ? SquarePlusIcon : SquareIcon} text={t('NEW_WIDGET')}/>
    </div>}
    {stats_widgets?.stats_widgets.length > 0 && <div onClick={() => setWidgetForm(-1)} className={widgetForm === -1 ? 'active' : ''}>
      <IconText icon={widgetForm === -1 ? SquarePlusIcon : SquareIcon} text={t('EXISTING_WIDGETS')}/>
    </div>}
    {canCreate && <div onClick={() => setWidgetForm(-3)} className={widgetForm === -3 ? 'active' : ''}>
      <IconText icon={widgetForm === -3 ? SquarePlusIcon : SquareIcon} text={t('TEMPLATES')}/>
    </div>}
  </div>;
});
