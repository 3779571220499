import { RestRequest } from '@Utils';
import {
  IPreviewStatsReport,
  IPreviewStatsWidget,
  ISharedStatsWidget,
  IStatsWidgetDataTableMinified, StatsWidgetPeriodEnum
} from 'atlas-shared/dist';

export * from './abstract/stats_widget.api.abstract';

export const fetchStatsWidgetData = async (id: IPreviewStatsWidget['id'], custom_period?: StatsWidgetPeriodEnum): Promise<IStatsWidgetDataTableMinified> => {
  return RestRequest.get<IStatsWidgetDataTableMinified>(`stats_widget/${id}/data`, { socket_id: global.socketId, custom_period });
};

export const transferStatsWidgetToOwner = (report_id: IPreviewStatsReport['id'], id: IPreviewStatsWidget['id']) => {
  return RestRequest.patch<any, ISharedStatsWidget>(`stats_widget/${report_id}/${id}/transfer/report_owner`, {}, { socket_id: global.socketId });
};
