import { RestRequest } from '@Utils';
import { IPreviewPhonenumberStock, ISharedPhonenumberStock, ISharedCreatePhonenumberStock, ISharedPatchPhonenumberStock, ISharedSearchPhonenumberStock } from 'atlas-shared';

export const fetchPhonenumberStock = async (id: IPreviewPhonenumberStock['id']): Promise<ISharedPhonenumberStock> => {
  return RestRequest.get<ISharedPhonenumberStock>(`phonenumber_stock/${id}`, { socket_id: global.socketId });
};

export const createPhonenumberStock = (payload: ISharedCreatePhonenumberStock) => {
  return RestRequest.post<any, ISharedPhonenumberStock>('phonenumber_stock', payload, { socket_id: global.socketId });
};

export const patchPhonenumberStock = (id: IPreviewPhonenumberStock['id'], payload: ISharedPatchPhonenumberStock) => {
  return RestRequest.patch<any, ISharedPhonenumberStock>(`phonenumber_stock/${id}`, payload, { socket_id: global.socketId });
};

export const deletePhonenumberStock = (id: IPreviewPhonenumberStock['id']) => {
  return RestRequest.delete(`phonenumber_stock/${id}`, { socket_id: global.socketId });
};

export const searchPhonenumberStock = async (params: ISharedSearchPhonenumberStock): Promise<{items: Array<IPreviewPhonenumberStock>; count: number}> => {
  return RestRequest.post<ISharedSearchPhonenumberStock, {items: Array<IPreviewPhonenumberStock>; count: number}>('phonenumber_stock/search', params, { socket_id: global.socketId });
};

export const getPhonenumberStockCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_stock/create/schema');
};

export const getPhonenumberStockPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('phonenumber_stock/patch/schema');
};
