import { FormTypeEnum, IPreviewField, ISharedCreateForm, ISharedForm } from 'atlas-shared';
import { TFunction } from 'i18next';
import { IFormOptions } from 'atlas-form';
import { FormFormOptions as FormFormOptionsBase, IFormFormOptionsProps } from './abstract/form.form.abstract';
import { filterRelated } from '@Utils';

export * from './abstract/form.form.abstract';

export const FormFormOptions = (form: ISharedCreateForm | ISharedForm, props: IFormFormOptionsProps, t: TFunction): IFormOptions => ({
  ...FormFormOptionsBase(form, props, t),
  __fields: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || form.organization_id, 'field', 'form', props.fields, true, field => {
    const formType = (it.type || form.type);
    const websiteForm = formType === FormTypeEnum.Website;

    return websiteForm || (formType === (field.type as any) && (field.alias !== '__conversation_body' || (websiteForm && field.alias === '__conversation_body')));
  }, true),
});
