import { ISharedVoiceRecordingTranscript } from 'atlas-shared';
import React, { useEffect, useState } from 'react';
import { getVoiceRecordingTranscript } from '@Api';
import './conversation.message.voice_call.voice_recording_transcript.scss';
import { ConversationMessageVoiceCallVoiceRecordingTranscriptSentence } from './conversation.message.voice_call.voice_recording_transcript.sentence';
import { useTranslation } from 'react-i18next';

interface IProps {
  organization_id: ISharedVoiceRecordingTranscript['organization_id'];
  id: ISharedVoiceRecordingTranscript['id'];
  currentTime: number;
}

export const ConversationMessageVoiceCallVoiceRecordingTranscript = React.memo(({ organization_id, id, currentTime }: IProps) => {

  const { t } = useTranslation();
  const [transcript, setTranscript] = useState<ISharedVoiceRecordingTranscript>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (id !== transcript?.id)
      getVoiceRecordingTranscript(organization_id, id)
        .then(transcript => setTranscript(transcript))
        .catch(error => {
          setError(error.toString());
          setTranscript(undefined);
        })
      ;
  }, [id]);

  return <div className='call-transcript'>
    <div className={`call-transcript-inner${error ? ' has-error' : ''}${currentTime ? ' playing' : ''}`}>
      {error}
      {transcript?.transcript.sentences.map(sentence => <ConversationMessageVoiceCallVoiceRecordingTranscriptSentence
        t={t}
        sentence={sentence}
        currentTime={sentence.start <= currentTime && sentence.end >= currentTime ? currentTime : 0}
      />)}
    </div>
  </div>;
});
