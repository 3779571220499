import { IPreviewStatsCustomMetric } from 'atlas-shared';
import { IStatsCustomMetricStore, StatsCustomMetricActions, TStatsCustomMetricActionTypes } from '@Store';

export const StatsCustomMetricReducer = (state: IStatsCustomMetricStore = {
  loaded: false,
  loading: false,
  stats_custom_metrics: [],
  dict: {}
}, action: TStatsCustomMetricActionTypes): IStatsCustomMetricStore => {
  switch (action.type) {
  case StatsCustomMetricActions.LOADING:
    return { ...state, loading: true };
  case StatsCustomMetricActions.SET_STATE:
    const dict: IStatsCustomMetricStore['dict'] = {};

    action.payload.forEach((stats_custom_metric: IPreviewStatsCustomMetric) => dict[stats_custom_metric.id] = stats_custom_metric);

    return { ...state, loaded: true, loading: false, stats_custom_metrics: action.payload, dict };
  case StatsCustomMetricActions.ADD_STATE:
    return { ...state, stats_custom_metrics: [...state.stats_custom_metrics, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case StatsCustomMetricActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, stats_custom_metrics: state.stats_custom_metrics.filter((item: IPreviewStatsCustomMetric) => item.id !== action.payload), dict };
  }
  case StatsCustomMetricActions.UPDATE_STATE:
    const stats_custom_metrics = [...state.stats_custom_metrics];
    const index = state.stats_custom_metrics.findIndex((stats_custom_metric: IPreviewStatsCustomMetric) => stats_custom_metric.id === action.payload.id);

    if (!~index)
      stats_custom_metrics.push(action.payload);
    else
      stats_custom_metrics[index] = action.payload;

    return { ...state, stats_custom_metrics, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
