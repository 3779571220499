import { IPreviewTariffParent } from 'atlas-shared';
import { ITariffParentStore, TariffParentActions, TTariffParentActionTypes } from '@Store';

export const TariffParentReducer = (state: ITariffParentStore = {
  loaded: false,
  loading: false,
  tariff_parents: [],
  dict: {}
}, action: TTariffParentActionTypes): ITariffParentStore => {
  switch (action.type) {
  case TariffParentActions.LOADING:
    return { ...state, loading: true };
  case TariffParentActions.SET_STATE:
    const dict: ITariffParentStore['dict'] = {};

    action.payload.forEach((tariff_parent: IPreviewTariffParent) => dict[tariff_parent.id] = tariff_parent);

    return { ...state, loaded: true, loading: false, tariff_parents: action.payload, dict };
  case TariffParentActions.ADD_STATE:
    return { ...state, tariff_parents: [...state.tariff_parents, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case TariffParentActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, tariff_parents: state.tariff_parents.filter((item: IPreviewTariffParent) => item.id !== action.payload), dict };
  }
  case TariffParentActions.UPDATE_STATE:
    const tariff_parents = [...state.tariff_parents];
    const index = state.tariff_parents.findIndex((tariff_parent: IPreviewTariffParent) => tariff_parent.id === action.payload.id);

    if (!~index)
      tariff_parents.push(action.payload);
    else
      tariff_parents[index] = action.payload;

    return { ...state, tariff_parents, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
