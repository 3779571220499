import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchFields, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useFields = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const fields = useSelector((store: IReducerStore) => store.fields);

  useEffect(() => {
    if (!initiated && autoload && !fields.loaded && !fields.loading) {
      initiated = true;
      dispatch(actionFetchFields());
    }
  }, [autoload, fields.loaded, fields.loading, dispatch]);

  return fields;
};
