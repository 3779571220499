import { RestRequest } from '@Utils';
import { IPreviewInvoice, ISharedFullInvoice } from 'atlas-shared/dist';

export * from './abstract/invoice.api.abstract';

export const reProcessInvoice = (id: IPreviewInvoice['id']) => {
  return RestRequest.put<any, boolean>(`invoice/${id}/re_process`, {}, { socket_id: global.socketId });
};

export const reProcessInvoiceTariffs = (id: IPreviewInvoice['id'], reset_tariff_id: boolean = false) => {
  return RestRequest.put<any, boolean>(`invoice/${id}/re_process_tariffs`, { reset_tariff_id }, { socket_id: global.socketId });
};

export const fetchInvoiceFull = (id: IPreviewInvoice['id']) => {
  return RestRequest.get<ISharedFullInvoice>(`invoice/${id}/full`, { socket_id: global.socketId });
};

export const putInvoiceLock = (id: IPreviewInvoice['id']) => {
  return RestRequest.put<any, ISharedFullInvoice>(`invoice/${id}/lock`, {}, { socket_id: global.socketId });
};

export const putInvoicePaid = (id: IPreviewInvoice['id']) => {
  return RestRequest.put<any, ISharedFullInvoice>(`invoice/${id}/paid`, {}, { socket_id: global.socketId });
};

export const putInvoiceNotPaid = (id: IPreviewInvoice['id']) => {
  return RestRequest.put<any, ISharedFullInvoice>(`invoice/${id}/not_paid`, {}, { socket_id: global.socketId });
};

export const putInvoiceSend = (id: IPreviewInvoice['id']) => {
  return RestRequest.put<any, ISharedFullInvoice>(`invoice/${id}/send`, {}, { socket_id: global.socketId });
};
