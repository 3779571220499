import React, { useMemo } from 'react';
import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip } from 'recharts';
import { getStatsWidgetMetricData, getStatsWidgetMetricDataFormatted } from '@Utils';
import { WidgetChartTooltip } from '../../widget.chart.tooltip';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import { WidgetChartLegend } from '@Components/stats/widget-types/widget.chart.legend';
import { WIDGET_COLOR_PALETTE } from '@Components/stats/widget-types/widget.color.palette';

export const WidgetTypeRadar = withStatsWidget(({ data: _data, stats_widget, t: _t, metric_data_dicts, stats_custom_metrics, innerWidth, innerHeight, organization }: IWithStatsWidgetProps) => {

  if (!_data?.r[0])
    return <></>;

  const legendPlacement = useMemo(() => stats_widget.settings.ui.legend?.placement || 'bottom', [stats_widget.settings.ui.legend?.placement]);
  const legendVertical = useMemo(() => ['left', 'right'].includes(legendPlacement), [legendPlacement]);
  const has_trend = !!stats_widget.settings.trend;
  const group_length = stats_widget.settings.group_by.length;
  const data = useMemo(() => {
    return (_data.g === 'split' ? _data.r[0].c!.r : _data.r).map(row => {
      const d = {
        group: stats_widget.settings.group_by.map((m, i) => getStatsWidgetMetricData(_t, m, row.d[i], metric_data_dicts, organization)).join(' ')
      };

      stats_widget.settings.metrics.forEach((m, i) => {
        d[m.value] = +row.d[group_length + i];
        if (has_trend && row.t)
          d[`${m.value}_trend`] = +row.t[i];
      });

      return d;
    });
  }, [_data, stats_widget]);

  const YMetric = useMemo(() => stats_widget.settings.metrics.find(m => m.format) || stats_widget.settings.metrics[0], [stats_widget.settings.metrics]);

  return <div>
    <RadarChart
      width={innerWidth}
      height={innerHeight}
      data={data}
      className={`legend-${legendVertical ? 'vertical' : 'horizontal'} legend-${legendPlacement}`}
    >
      <PolarGrid strokeDasharray="1 3" stroke={'var(--COLOR-000533)'} opacity={.25} />
      <PolarAngleAxis dataKey="group" fontSize={11} tick={{ fill: 'var(--COLOR-000533)' }} />
      <PolarRadiusAxis
        angle={90}
        fontSize={11}
        tick={{ style: { transform: 'translateY(12px) translateX(2px)' }, fill: 'var(--COLOR-000533)' }}
        tickFormatter={v => getStatsWidgetMetricDataFormatted(_t, YMetric, v, organization)}
        opacity={.7}
        axisLine={false}
      />
      {stats_widget.settings.metrics.map((metric, i) => <Radar
        dataKey={metric.value}
        label={props => props.name}
        fill={metric.bg_color || 'transparent'}
        fillOpacity={.7}
        stroke={metric.border?.color || metric.bg_color || WIDGET_COLOR_PALETTE[i]}
        strokeWidth={metric.border?.width || 2}
        isAnimationActive={false}
      />)}
      {has_trend && stats_widget.settings.metrics.map((metric, i) => <Radar
        dataKey={`${metric.value}_trend`}
        fill={metric.bg_color || 'transparent'}
        fillOpacity={.4}
        stroke={metric.border?.color || metric.bg_color || WIDGET_COLOR_PALETTE[i]}
        strokeWidth={metric.border?.width || 2}
        opacity={.25}
        isAnimationActive={false}
      />)}
      <Tooltip animationDuration={0} content={<WidgetChartTooltip organization={organization} t={_t} metrics={stats_widget.settings.metrics} stats_custom_metrics={stats_custom_metrics} />} />
      {!!stats_widget.settings.ui.legend && <Legend
        content={<WidgetChartLegend stats_widget={stats_widget} data={data} t={_t} metrics={stats_widget.settings.metrics} stats_custom_metrics={stats_custom_metrics}/>}
        align={legendVertical ? stats_widget.settings.ui.legend!.placement as any : undefined}
        layout={legendVertical ? 'vertical' : 'horizontal'}
        verticalAlign={legendVertical ? 'middle' : legendPlacement as any}
      />}
    </RadarChart>
  </div>;
});
