import React from 'react';
import './widget-type.table.scss';
import { getStatsWidgetMetricTitle } from '@Utils';
import { StatsWidgetMetricData } from '../../stats_widget.metric.data';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import { WidgetTypeTableTr } from '@Components/stats/widget-types/widget-type/shared/components/widget-type.table/widget-type.table.tr';

const _WidgetTypeTable = ({ data: _data, metrics, stats_widget, t: _t, metric_data_dicts, stats_custom_metrics, organization }: IWithStatsWidgetProps) => {
  const group_metrics = [
    ...stats_widget.settings.group_by,
    ...stats_widget.settings.sub_group_by
  ];
  const isSummaryOrSplit = _data?.g && ['summary', 'split'].includes(_data?.g);
  const data = isSummaryOrSplit ? _data.r?.[0]?.c : _data;
  const summary: any = isSummaryOrSplit && stats_widget.settings.summary ? _data.r[0] : false;

  const stats_widget_style_patch = `#stats-widget-${stats_widget.id || 'demo'}  .widget-type-table`;

  if (!data)
    return <></>;

  return <div className='widget-type-table'>
    <style>
      {`
      ${stats_widget_style_patch} td {
        border-bottom: ${stats_widget.settings.ui.border?.width || 0}px solid ${stats_widget.settings.ui.border?.color || 'transparent'};
      }
      
      ${stats_widget_style_patch} tr.group.row-odd td {
        background-color: ${stats_widget.settings.ui.table?.group.odd_row.bg_color || 'transparent'};
        ${stats_widget.settings.ui.table?.group.odd_row.color ? `color: ${stats_widget.settings.ui.table.group.odd_row.color};` : ''}
      }
      ${stats_widget_style_patch} tr.group.row-even td {
        background-color: ${stats_widget.settings.ui.table?.group.even_row.bg_color || 'transparent'};
        ${stats_widget.settings.ui.table?.group.even_row.color ? `color: ${stats_widget.settings.ui.table.group.even_row.color};` : ''}
      }
      
      ${stats_widget_style_patch} tr.sub-group.row-odd td {
        background-color: ${stats_widget.settings.ui.table?.sub_group.odd_row.bg_color || 'transparent'};
        ${stats_widget.settings.ui.table?.sub_group.odd_row.color ? `color: ${stats_widget.settings.ui.table.sub_group.odd_row.color};` : ''}
      }
      ${stats_widget_style_patch} tr.sub-group.row-even td {
        background-color: ${stats_widget.settings.ui.table?.sub_group.even_row.bg_color || 'transparent'};
        ${stats_widget.settings.ui.table?.sub_group.even_row.color ? `color: ${stats_widget.settings.ui.table.sub_group.even_row.color};` : ''}
      }
    `}
    </style>
    <table cellPadding={0} cellSpacing={0}>
      {stats_widget.settings.ui.display_header && <thead>
        <tr>
          {[...group_metrics, ...stats_widget.settings.metrics].map(m => {
            const { elm, txt } = getStatsWidgetMetricTitle(_t, m, stats_custom_metrics.stats_custom_metrics);

            return <th title={txt} key={m.value}><span>{elm}</span></th>;
          })}
        </tr>
      </thead>}
      <tbody>
        {data.r.map((row, index) => <WidgetTypeTableTr
          organization={organization}
          key={index}
          t={_t}
          row={row}
          index={index}
          stats_widget={stats_widget}
          data={data}
          metrics={metrics}
          stats_custom_metrics={stats_custom_metrics}
          metric_data_dicts={metric_data_dicts}
        />)}
      </tbody>
      <tfoot>
        {summary && <tr className='total'>
          {[
            ...stats_widget.settings.group_by,
            ...stats_widget.settings.sub_group_by
          ].map((m, i) => <td key={m.value}></td>)}
          {stats_widget.settings.metrics.map((m, i) => {
            const { d, t, tc, tp: trend_period } = summary;
            const metric_index = stats_widget.settings.split_by.length + i;

            return <td key={m.value}>
              <StatsWidgetMetricData
                t={_t}
                organization={organization}
                stats_widget={stats_widget}
                metric_settings={m}
                metric={metrics[i]}
                value={d[metric_index]}
                trend_value={t?.[i]}
                trend_comparison={tc?.[i]}
                trend_period={trend_period}
                metric_data_dicts={metric_data_dicts}
              />
            </td>;
          })}
        </tr>}
      </tfoot>
    </table>
  </div>;
};

export const WidgetTypeTable = React.memo(withStatsWidget(_WidgetTypeTable));
