import React, { useEffect, useState } from 'react';
import '../admin.list.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { IPreviewUser, PermissionEnum, CrudEnum, TUserSortableListCol } from 'atlas-shared';
import { Table } from 'antd';
import { AdminListPageHeader, ListPageEmpty } from '@Components';
import { IWithListProps, IWithListSorterProps, withList } from '@Hocs/with.list';
import { useAbilities, useAuth, useUsers, useOrganizations } from '@Hooks';
import { isSuperAdmin, UserListColumns } from '@Utils';
import { IAuth } from '@Store';

const UserList = ({ filters, filtersCount, hasFilters, sorter, pagination, setQueryParams, resetQueryParams }: IWithListProps<IPreviewUser, TUserSortableListCol>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth: IAuth = useAuth();
  const users = useUsers();
  const abilities = useAbilities();
  const [loading, setLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<any>>([]);
  const organizations = useOrganizations();
  
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedCount, setDeletedCount] = useState<number>(0);
  
  useEffect(() => {
    if (!users.loaded)
      return;
      
    setLoading(false);
    setRows((!showDeleted ? users.users.filter(p => !p.deleted_at && (auth.is_superadmin || (!auth.is_superadmin && !isSuperAdmin(p)))) : users.users.filter(p => (auth.is_superadmin || (!auth.is_superadmin && !isSuperAdmin(p)))) || []).map(row => ({ ...row, key: row.id })));
    setDeletedCount(users.users.filter(user => !!user.deleted_at).length);
  }, [users, showDeleted]);

  const columns: ColumnsType<IPreviewUser> = React.useMemo(() => {
    return UserListColumns(t, navigate, showDeleted, organizations, abilities, auth, [], filters, sorter);
  }, [t, navigate, showDeleted, organizations, abilities, auth, filters, sorter]);

  return <div className='admin-list-view'>
    <AdminListPageHeader 
      t={t}
      title={t('USERS')}
      filtersCount={filtersCount}
      reset={() => resetQueryParams()}
      deleted={[showDeleted, setShowDeleted]} deletedCount={deletedCount}
      addBtn={abilities.canImplicit(null, CrudEnum.Insert, PermissionEnum.User) ? { callback: () => navigate('/admin/user/add') } : undefined} 
    />
    <div className='table-wrp'>
      <Table
        key={loading ? 1 : 0}
        columns={columns}
        dataSource={rows}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize
        }}
        onChange={(pagination, filters, _sorter) => {
          const sorter: Array<SorterResult<any>> = Array.isArray(_sorter) ? _sorter : _sorter?.columnKey ? [_sorter] : [];

          setQueryParams({
            pagination: { current: pagination.current as number, pageSize: pagination.pageSize as number },
            filters: Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== null)),
            sorter: sorter
              .filter(s => !!s.order)
              .map(s => ({ column: s.columnKey as TUserSortableListCol, order: s.order as IWithListSorterProps<IPreviewUser, TUserSortableListCol>['order'] }))
          });
        }}
        locale={{
          emptyText: <ListPageEmpty t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => resetQueryParams()} />
        }}
      />
    </div>
  </div>;
};

export const UserListView = React.memo(withList<IPreviewUser, TUserSortableListCol>(UserList, {
  filters: {},
  sorter: [{ column: 'id', order: 'descend' }],
  pagination: { current: 1, pageSize: 10 }
}));
