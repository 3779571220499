import { StatsReportActions } from '@Store';
import { IPreviewStatsReport } from 'atlas-shared';
import { fetchStatsReports } from '@Api';

export const actionFetchStatsReports = () => {
  return async (dispatch) => {
    dispatch(actionLoadingStatsReports());
    const stats_reports = await fetchStatsReports();

    dispatch(actionSetStatsReports(stats_reports));
    return stats_reports;
  };
};

export const actionLoadingStatsReports = () => ({
  type: StatsReportActions.LOADING,
  payload: []
});

export const actionSetStatsReports = (payload: Array<IPreviewStatsReport>) => ({
  type: StatsReportActions.SET_STATE,
  payload: payload
});

export const actionAddStatsReport = (payload: IPreviewStatsReport) => ({
  type: StatsReportActions.ADD_STATE,
  payload: payload
});

export const actionUpdateStatsReport = (payload: IPreviewStatsReport) => ({
  type: StatsReportActions.UPDATE_STATE,
  payload: payload
});

export const actionRemoveStatsReport = (id: IPreviewStatsReport['id']) => ({
  type: StatsReportActions.REMOVE_STATE,
  payload: id
});
