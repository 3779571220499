import { IPreviewTimeInterval } from 'atlas-shared';
import { ITimeIntervalStore, TimeIntervalActions, TTimeIntervalActionTypes } from '@Store';

export const TimeIntervalReducer = (state: ITimeIntervalStore = {
  loaded: false,
  loading: false,
  time_intervals: [],
  dict: {}
}, action: TTimeIntervalActionTypes): ITimeIntervalStore => {
  switch (action.type) {
  case TimeIntervalActions.LOADING:
    return { ...state, loading: true };
  case TimeIntervalActions.SET_STATE:
    const dict: ITimeIntervalStore['dict'] = {};

    action.payload.forEach((time_interval: IPreviewTimeInterval) => dict[time_interval.id] = time_interval);

    return { ...state, loaded: true, loading: false, time_intervals: action.payload, dict };
  case TimeIntervalActions.ADD_STATE:
    return { ...state, time_intervals: [...state.time_intervals, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case TimeIntervalActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, time_intervals: state.time_intervals.filter((item: IPreviewTimeInterval) => item.id !== action.payload), dict };
  }
  case TimeIntervalActions.UPDATE_STATE:
    const time_intervals = [...state.time_intervals];
    const index = state.time_intervals.findIndex((time_interval: IPreviewTimeInterval) => time_interval.id === action.payload.id);

    if (!~index)
      time_intervals.push(action.payload);
    else
      time_intervals[index] = action.payload;

    return { ...state, time_intervals, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
