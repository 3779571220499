import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const MailIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
    <polyline points="22,6 12,13 2,6"></polyline>
  </SvgIcon>;
};

export const MailInboundIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M12 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V10" />
    <path d="M19.5 22L15.5 18L19.5 14" />
    <path d="M15.5 18H23.5" />
    <path d="M22 4L12 11L2 4" />
  </SvgIcon>;
};

export const MailInboundAutoReplyIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M12 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V10" />
    <path d="M19.5 22L15.5 18L19.5 14" />
    <path d="M15.5 18H23.5" />
    <path d="M22 4L12 11L2 4" />
    <text x="3" y="16" style={{ fontSize: 12 }}>A</text>
  </SvgIcon>;
};

export const MailOutboundIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M12 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V10" />
    <path d="M19.5 14L23.5 18L19.5 22" />
    <path d="M15.5 18H23.5" />
    <path d="M22 4L12 11L2 4" />
  </SvgIcon>;
};

export const MailOutboundAutoReplayIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M12 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H20C21.1 2 22 2.9 22 4V10" />
    <path d="M19.5 14L23.5 18L19.5 22" />
    <path d="M15.5 18H23.5" />
    <path d="M22 4L12 11L2 4" />
    <text x="3" y="16" style={{ fontSize: 12 }}>A</text>
  </SvgIcon>;
};
