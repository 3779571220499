import React from 'react';
import {
  IMetric,
  IPreviewOrganization,
  IPreviewStatsCustomMetric,
  IPreviewStatsReport,
  IPreviewStatsWidget
} from 'atlas-shared/dist';
import { TFunction } from 'i18next';
import { IMetricDataDicts } from '@Utils';

import { IStatsCustomMetricStore } from '@Store';

interface IStatsWidgetChildProps {
  data: IPreviewStatsWidget['data'];
  organization: IPreviewOrganization;
  stats_report: IPreviewStatsReport;
  stats_widget: IPreviewStatsWidget;
  t: TFunction;
  width: number;
  height?: number;
  metrics: Array<IMetric | IPreviewStatsCustomMetric>;
  stats_custom_metrics: IStatsCustomMetricStore;
  metric_data_dicts: IMetricDataDicts;
  has_trend: boolean;
}

export interface IWithStatsWidgetProps extends IStatsWidgetChildProps {
  innerWidth: number;
  innerHeight?: number;
}

export const withStatsWidget = (Component: React.JSXElementConstructor<any>) => {
  return (props: IStatsWidgetChildProps) => {
    
    return <Component
      {...props}
    />;
  };
};
