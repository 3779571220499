import { VoiceAsteriskActions } from '@Store';
import { IPreviewVoiceAsterisk } from 'atlas-shared';
import { fetchVoiceAsterisks } from '@Api';

export const actionFetchVoiceAsterisks = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceAsterisks());
    const voice_asterisks = await fetchVoiceAsterisks();

    dispatch(actionSetVoiceAsterisks(voice_asterisks));
    return voice_asterisks;
  };
};

export const actionLoadingVoiceAsterisks = () => ({
  type: VoiceAsteriskActions.LOADING,
  payload: []
});

export const actionSetVoiceAsterisks = (payload: Array<IPreviewVoiceAsterisk>) => ({
  type: VoiceAsteriskActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceAsterisk = (payload: IPreviewVoiceAsterisk) => ({
  type: VoiceAsteriskActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceAsterisk = (payload: IPreviewVoiceAsterisk) => ({
  type: VoiceAsteriskActions.UPDATE_STATE,
  payload: payload
});
