import { RestRequest } from '@Utils';
import { IPreviewVoiceAsteriskToggleHistory, ISharedVoiceAsteriskToggleHistory, ISharedSearchVoiceAsteriskToggleHistory } from 'atlas-shared';

export const fetchVoiceAsteriskToggleHistory = async (id: IPreviewVoiceAsteriskToggleHistory['id']): Promise<ISharedVoiceAsteriskToggleHistory> => {
  return RestRequest.get<ISharedVoiceAsteriskToggleHistory>(`voice_asterisk_toggle_history/${id}`, { socket_id: global.socketId });
};

export const searchVoiceAsteriskToggleHistory = async (params: ISharedSearchVoiceAsteriskToggleHistory): Promise<{items: Array<IPreviewVoiceAsteriskToggleHistory>; count: number}> => {
  return RestRequest.post<ISharedSearchVoiceAsteriskToggleHistory, {items: Array<IPreviewVoiceAsteriskToggleHistory>; count: number}>('voice_asterisk_toggle_history/search', params, { socket_id: global.socketId });
};
