import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { IFormElement } from '../../atlas-form-core/Interfaces';

interface IProps {
  className?: string;
  onChange?: (d?)=> void;
  value?: string;
  format?: string;
  style?: IFormElement['element_style'];
  disabled?: boolean;
}

function FormTimePicker (props: IProps) {
  return (
    <DatePicker
      {...props}
      picker="time"
      format={props.format || 'HH:mm'}
      value={props.value ? moment(props.value, 'HH:mm') : undefined}
      onChange={(d) => props.onChange && props.onChange(d ? d.format('HH:mm') : undefined)}
      style={props.style}
      disabled={props.disabled}
    />
  );
}

export default React.memo(FormTimePicker);
