import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  VoiceTrunkEditForm,
  VoiceTrunkEditInitialValues,
  VoiceTrunkComponents,
  VoiceTrunkAdditionalParams,
  VoiceTrunkFormOptions,
  onVoiceTrunkFormSaved,
  IVoiceTrunkFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedVoiceTrunk, ISharedPatchVoiceTrunk, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchVoiceTrunk, patchVoiceTrunk, getVoiceTrunkUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useVoiceAsterisks } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedVoiceTrunk> {}

const VoiceTrunkFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const voice_asterisks = useVoiceAsterisks();

  useEffect(() => {
    fetchVoiceTrunk(id).then(voice_trunk => setValue(voice_trunk));
  }, [id, setValue]);

  const param_props: IVoiceTrunkFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    voice_asterisks: voice_asterisks.voice_asterisks
  };
  const formLayout: IForm | undefined = useMemo(() => value && VoiceTrunkEditForm(value, param_props, t), [value, organizations, voice_asterisks, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && VoiceTrunkFormOptions(value, param_props, t), [value, organizations, voice_asterisks, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchVoiceTrunk, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchVoiceTrunk) => value && save(() => patchVoiceTrunk(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceTrunkFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !voice_asterisks.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.VoiceTrunk, value)}
    >
      <AtlasForm<ISharedPatchVoiceTrunk>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={VoiceTrunkComponents}
        additionalParams={VoiceTrunkAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={VoiceTrunkEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('VOICE_TRUNK')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceTrunkFormEditView = React.memo(withEdit(VoiceTrunkFormEdit, getVoiceTrunkUpdateSchema));
