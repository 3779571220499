import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchPauses, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const usePauses = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const pauses = useSelector((store: IReducerStore) => store.pauses);

  useEffect(() => {
    if (!initiated && autoload && !pauses.loaded && !pauses.loading) {
      initiated = true;
      dispatch(actionFetchPauses());
    }
  }, [autoload, pauses.loaded, pauses.loading, dispatch]);

  return pauses;
};
