import { useCallback, useEffect, useState } from 'react';
import { fromJson, Schema } from 'json-joi-converter';
import * as Joi from 'joi';
import { Undefinable } from 'atlas-shared';

const useSchema = (schemaFetcher: () => Promise<Schema>): [Undefinable<Joi.Schema>, boolean, Undefinable<Schema>] => {
  const [formSchema, setFormSchema] = useState<Joi.Schema>();
  const [schema, setSchema] = useState<Schema>();
  const [loading, setLoading] = useState<boolean>(false);

  const loadSchema = useCallback(async (cb: ()=> Promise<Schema>) => {
    setLoading(true);
    try {
      const response = await cb();

      setSchema(response);
      setFormSchema(fromJson(response));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setFormSchema]);

  useEffect(() => {
    loadSchema(() => schemaFetcher());
  }, [loadSchema, schemaFetcher]);

  return [formSchema, loading, schema];
};

export { useSchema };
