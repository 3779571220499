import { RestRequest } from '@Utils';
import { IPreviewUserStatus, ISharedUserStatus, ISharedPatchUserStatus } from 'atlas-shared';

export const fetchUserStatuses = async (): Promise<Array<IPreviewUserStatus>> => {
  return RestRequest.get<Array<IPreviewUserStatus>>('user_status', { socket_id: global.socketId });
};

export const fetchUserStatus = async (id: IPreviewUserStatus['id']): Promise<ISharedUserStatus> => {
  return RestRequest.get<ISharedUserStatus>(`user_status/${id}`, { socket_id: global.socketId });
};

export const patchUserStatus = (id: IPreviewUserStatus['id'], payload: ISharedPatchUserStatus) => {
  return RestRequest.patch<any, ISharedUserStatus>(`user_status/${id}`, payload, { socket_id: global.socketId });
};

export const getUserStatusUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('user_status/update/schema');
};

export const getUserStatusPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('user_status/patch/schema');
};
