import { fetchRecentlySearcheds } from '@Api';
import {
  actionLoadingRecentlySearcheds, actionRemoveRecentlySearched,
  actionSetRecentlySearcheds
} from './abstract/recently_searched.actions.abstract';
import { ISharedAgentprofile, Nullable } from 'atlas-shared';
import { IRecentlySearchedStore } from '@Store';

export * from './abstract/recently_searched.actions.abstract';

export const actionFetchRecentlySearcheds = (agentprofile_id?: Nullable<ISharedAgentprofile['id']>) => {
  return async (dispatch) => {
    dispatch(actionLoadingRecentlySearcheds());
    const recently_searcheds = await fetchRecentlySearcheds(agentprofile_id);

    dispatch(actionSetRecentlySearcheds(recently_searcheds));
    return recently_searcheds;
  };
};

export const actionClearAllRecentlySearched = () => {
  return (dispatch, getState) => {
    (getState().recently_searcheds as IRecentlySearchedStore).recently_searcheds.forEach((search) => {
      dispatch(actionRemoveRecentlySearched(search.id));
    });
  };
};
