import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateStatsCustomMetric, ISharedUpdateStatsCustomMetric, ISharedStatsCustomMetric, IPreviewOrganization, IPreviewStatsCustomMetric, EnumOptions, MetricQueryTypeEnum, DirectionEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IStatsCustomMetricFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  stats_custom_metrics: Array<IPreviewStatsCustomMetric>;
}

export const StatsCustomMetricFormOptions = (stats_custom_metric: ISharedCreateStatsCustomMetric | ISharedStatsCustomMetric, props: IStatsCustomMetricFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.StatsCustomMetric, {})), 'organization_id', false), 'organization'),
  metrics_stats_custom_metric_id: (it: ISharedStatsCustomMetric) => filterRelated<IPreviewStatsCustomMetric>(it.organization_id || stats_custom_metric.organization_id, 'stats_custom_metric', 'stats_custom_metric', props.stats_custom_metrics, true, it => it.id !== (stats_custom_metric as ISharedStatsCustomMetric)?.id),
  query_type: EnumOptions('METRIC_QUERY_TYPE', MetricQueryTypeEnum, t),
  metrics_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  metrics_thread_direction: EnumOptions('DIRECTION', DirectionEnum, t),
  metrics_message_direction: EnumOptions('DIRECTION', DirectionEnum, t),
});

export const StatsCustomMetricComponents: IForm['components'] = {};
export const StatsCustomMetricAdditionalParams: (auth: IAuth, props: IStatsCustomMetricFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsCustomMetricFormOptionsProps) => ({
});

export const StatsCustomMetricEditForm = (stats_custom_metric: ISharedStatsCustomMetric, props: IStatsCustomMetricFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `stats_custom_metric_edit_${stats_custom_metric.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === stats_custom_metric.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'query_type' },
    { ref: 'metrics' },
    { ref: 'description' }
  ]
});

export const StatsCustomMetricAddForm = (stats_custom_metric: ISharedCreateStatsCustomMetric, props: IStatsCustomMetricFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'stats_custom_metric_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'query_type' },
    { ref: 'metrics' },
    { ref: 'description' }
  ]
});

export const StatsCustomMetricAddInitialValues: ISharedCreateStatsCustomMetric = {} as ISharedCreateStatsCustomMetric;

export const StatsCustomMetricEditInitialValues = (stats_custom_metric: ISharedStatsCustomMetric): ISharedUpdateStatsCustomMetric => ({
  title: stats_custom_metric.title,
  query_type: stats_custom_metric.query_type,
  metrics: stats_custom_metric.metrics,
  description: stats_custom_metric.description
});

export const onStatsCustomMetricFormSaved = (t: TFunction, action: 'edit' | 'add', stats_custom_metric: ISharedStatsCustomMetric, navigate: NavigateFunction): void => {
  navigate(onFormSaved('stats_custom_metrics'));
};
