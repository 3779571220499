import React, { useEffect, useRef, useState } from 'react';
import {
  useAuth,
  useDevices,
  useDevicesAvailable,
  useIsInactive,
  useSipUsers,
  useVoiceAsterisks,
  useVoiceMic,
  useVoiceVolume
} from '@Hooks';
import { initiateSipUsersWrapper } from './index';
import VoiceAudioElement from './audio.element';
import { useAppDispatch } from '@Store';
import SipUsersWrapper, { IVoiceAsteriskItem } from '@Modules/SIP/sip-users-wrapper';
import { useTranslation } from 'react-i18next';
import { Undefinable } from 'atlas-shared/dist';

export const SipContainer = React.memo(() => {
  const auth = useAuth();
  const voice_asterisks = useVoiceAsterisks();
  const dispatch = useAppDispatch();
  const sip_users = useSipUsers();
  const [currentVoiceAsterisks, setCurrentVoiceAsterisks] = useState<Array<IVoiceAsteriskItem>>([]);
  const [sipUsersWrapper, setSipUsersWrapper] = useState<SipUsersWrapper>();
  const [sessions, setSessions] = useState<Array<string>>([]);
  const voice_volume = useVoiceVolume();
  const audioElement = useRef<HTMLAudioElement>(null);
  const devices = useDevices();
  const devices_available = useDevicesAvailable();
  const voice_mic = useVoiceMic();
  const { t } = useTranslation();
  const isInactive = useIsInactive();

  useEffect(() => {
    setSessions(sip_users.sip_users.map(sip_user => Object.keys(sip_user.sessions || {})).flat());
  }, [sip_users]);

  useEffect(() => {
    if (!voice_asterisks.loaded)
      return;

    setCurrentVoiceAsterisks(auth.user.voice_license ? auth.user_status.__current_voice_asterisks.map(voice_asterisk_item => {
      return {
        voice_asterisk: voice_asterisks.voice_asterisks.find(voice_asterisk => voice_asterisk.id === voice_asterisk_item.vaid),
        agentprofile_id: voice_asterisk_item.apid
      };
    }).filter(item => !!item.voice_asterisk) as Array<IVoiceAsteriskItem> : []);
  }, [auth.user_status.__current_voice_asterisks, voice_asterisks, auth.user.voice_license]);

  useEffect(() => {
    let initiated: Undefinable<SipUsersWrapper>;

    if (voice_asterisks.loaded && !sipUsersWrapper) {
      initiated = initiateSipUsersWrapper(auth.user, dispatch);
      setSipUsersWrapper(initiated);
    }

    if (sipUsersWrapper || initiated)
      (sipUsersWrapper || initiated)!.addSipUsers(auth.user.main ? currentVoiceAsterisks : [], devices.microphone);

  }, [currentVoiceAsterisks, auth, dispatch, sipUsersWrapper, voice_asterisks.loaded]);

  useEffect(() => {
    sipUsersWrapper?.setMicrophone(devices_available.microphone ? devices.microphone : 'default');
  }, [devices, devices_available]);

  useEffect(() => {
    if (isInactive)
      sipUsersWrapper?.disconnectAll();
  }, [isInactive]);

  useEffect(() => {
    sipUsersWrapper?.setMicrophoneMute(voice_mic);
  }, [voice_mic]);

  return <div style={{ display: 'none' }}>
    <audio id={'remoteAudio'} controls ref={audioElement}>
      <p>Your browser does not support HTML5 audio.</p>
    </audio>
    {sessions.map(session => <VoiceAudioElement t={t} id={session} key={session} volume={voice_volume} />)}
  </div>;
});
