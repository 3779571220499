import { IForm } from 'atlas-form';
import { JourneyViewLocked } from '@Components';
import {
  IJourneyFormOptionsProps,
  JourneyAdditionalParams as JourneyAdditionalParamsBase
} from './abstract/journey.form.abstract';
import { IAuth } from '@Store';

export * from './abstract/journey.form.abstract';
export const JourneyComponents: IForm['components'] = {
  payload: JourneyViewLocked
};

export const JourneyAdditionalParams: (auth: IAuth, props: IJourneyFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IJourneyFormOptionsProps) => ({
  ...JourneyAdditionalParamsBase(auth, props),
  payload: { label: 'JOURNEY' }
});
