import { RestRequest } from '@Utils';
import { IPreviewAgentprofile, ISharedAgentprofile, ISharedCreateAgentprofile, ISharedPatchAgentprofile } from 'atlas-shared';

export const fetchAgentprofiles = async (): Promise<Array<IPreviewAgentprofile>> => {
  return RestRequest.get<Array<IPreviewAgentprofile>>('agentprofile', { socket_id: global.socketId });
};

export const fetchAgentprofile = async (id: IPreviewAgentprofile['id']): Promise<ISharedAgentprofile> => {
  return RestRequest.get<ISharedAgentprofile>(`agentprofile/${id}`, { socket_id: global.socketId });
};

export const createAgentprofile = (payload: ISharedCreateAgentprofile) => {
  return RestRequest.post<any, ISharedAgentprofile>('agentprofile', payload, { socket_id: global.socketId });
};

export const patchAgentprofile = (id: IPreviewAgentprofile['id'], payload: ISharedPatchAgentprofile) => {
  return RestRequest.patch<any, ISharedAgentprofile>(`agentprofile/${id}`, payload, { socket_id: global.socketId });
};

export const deleteAgentprofile = (id: IPreviewAgentprofile['id']) => {
  return RestRequest.delete(`agentprofile/${id}`, { socket_id: global.socketId });
};

export const getAgentprofileUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('agentprofile/update/schema');
};

export const getAgentprofileCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('agentprofile/create/schema');
};

export const getAgentprofilePatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('agentprofile/patch/schema');
};
