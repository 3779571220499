import { RestRequest } from '@Utils';
import { IPreviewForm, ISharedForm, ISharedCreateForm, ISharedPatchForm } from 'atlas-shared';

export const fetchForms = async (): Promise<Array<IPreviewForm>> => {
  return RestRequest.get<Array<IPreviewForm>>('form', { socket_id: global.socketId });
};

export const fetchForm = async (id: IPreviewForm['id']): Promise<ISharedForm> => {
  return RestRequest.get<ISharedForm>(`form/${id}`, { socket_id: global.socketId });
};

export const createForm = (payload: ISharedCreateForm) => {
  return RestRequest.post<any, ISharedForm>('form', payload, { socket_id: global.socketId });
};

export const patchForm = (id: IPreviewForm['id'], payload: ISharedPatchForm) => {
  return RestRequest.patch<any, ISharedForm>(`form/${id}`, payload, { socket_id: global.socketId });
};

export const deleteForm = (id: IPreviewForm['id']) => {
  return RestRequest.delete(`form/${id}`, { socket_id: global.socketId });
};

export const getFormUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('form/update/schema');
};

export const getFormCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('form/create/schema');
};

export const getFormPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('form/patch/schema');
};
