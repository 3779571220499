import React from 'react';
import { TFunction } from 'i18next';
import { IPreviewContact } from 'atlas-shared';
import { NoResult } from '@Components';

interface IProps {
  t: TFunction;
  keyword?: string;
  contact_id?: IPreviewContact['id'];
  style?: React.CSSProperties;
}

export const SearchNoResult = React.memo((props: IProps) => {
  const { t, keyword, contact_id, style } = props;

  if (contact_id) 
    return <NoResult description={t('NO_CONVERSATIONS_WERE_FOUND')} style={style} />;

  return <NoResult description={t('NO_CONVERSATIONS_OR_CONTACTS_WERE_FOUND', { keyword })} style={style} />;
});
