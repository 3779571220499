import { TFunction } from 'i18next';
import {
  DirectionEnum,
  IConversationTimeline, ISharedJourney,
  JourneyActionEnum,
  TConversationVoiceCall,
  VoiceCallTransferTypeEnum
} from 'atlas-shared/dist';
import { IDispositionStore, IQueueStore, IUserStore, IVoiceRouteStore } from '@Store';
import { getFullName, translatePhoneNumber } from '@Utils';
import { ITimelineEvent } from './timeline.event.interface';

export const translateConversationTimelineEvent = (
  t: TFunction,
  { params, title, dt, journey_id, journey_trigger_id }: IConversationTimeline,
  store: {users: IUserStore; queues: IQueueStore; voice_routes: IVoiceRouteStore; dispositions: IDispositionStore},
  main_journey_id?: ISharedJourney['id'],
  voice_call?: TConversationVoiceCall,
): ITimelineEvent => {
  let output: {title: string; description: Array<string>} = {
    title: title,
    description: []
  };
  const destination_bridge = voice_call && params?.transferred_to ? voice_call.voice_call_bridges.find(bridge => bridge.id === params?.transferred_to) : null;

  let user: any = params?.user_id;

  if (user && store.users.dict[user])
    user = getFullName(store.users.dict[user]);

  if (user === -1)
    user = t('SYSTEM');

  if (params?.transfer_type)
    output.description.push(t('TRANSFER_TYPE_X', { replace: { type: t(params.transfer_type === VoiceCallTransferTypeEnum.Attended ? 'ATTENDED' : 'BLIND') } }));

  switch (title) {
  case 'DIALED':

    if (voice_call) {
      output.title = t(`${voice_call.direction === DirectionEnum.InBound ? 'INBOUND' : voice_call.direction === DirectionEnum.Outbound ? 'OUTBOUND' : 'INTERNAL'}_CALL_INITIATED`);
      if (params?.voice_route_id) {
        const route = store.voice_routes?.dict?.[params.voice_route_id].number || params.voice_route_id;
        const translated = translatePhoneNumber(route as string);

        output.description.push(t('ROUTE_X', { replace: { route: translated?.formatInternational() || route } }));
      }
    }

    break;
  case 'SYSTEM_ANSWER':
    output.title = t('SYSTEM_ANSWER');
    break;
  case 'JOURNEY_JOB':
    output.title = t(`${params?.journey_action}`);
    output.description.push(`${t(`NODE_SUBTITLE_${params?.journey_action}`)}`);

    if (params?.node_params?.queue_id) {
      const queue = store.queues?.dict?.[params.node_params.queue_id].title || params.node_params.queue_id;

      if (params.node_params.timeout)
        output.description.push(t('QUEUE_X_WITH_TIMEOUT', { queue, timeout: params.node_params.timeout || t('UNKNOWN') }));
      else
        output.description.push(`${t('QUEUE')}: ${queue}`);
    }
    else if (params?.journey_action === JourneyActionEnum.VoiceMenu)
      output.description.push(t('AVAILABLE_VOICE_MENU_OPTIONS', { replace: { options: params?.node_params?.options.join(', ') || t('UNKNOWN'), retries: params?.node_params?.retries || t('UNKNOWN') } }));
    else if (params?.journey_action === JourneyActionEnum.When && params?.node_params?.type)
      output.description[output.description.length - 1] += ` "${t(params.node_params.type.toUpperCase())}"`;
    else if (params?.journey_action === JourneyActionEnum.VoiceExternalDial && params?.node_params?.phone)
      output.description.push(t('PARAMS_PHONE', { replace: { phone: translatePhoneNumber(params.node_params.phone)?.formatInternational() || params.node_params.phone } }));
    else if (params?.journey_action === JourneyActionEnum.Journey && params?.node_params?.journey_id)
      output.description.push(t('PARAMS_JOURNEY', { replace: { journey: params.node_params.journey_id } }));

    (params?.journey_log || []).forEach(log => {
      const log_time = new Date(log.ts).toLocaleTimeString();

      const addDescription = (str: string) => {
        output.description.push(`${str} [${log_time}]`);
      };

      if (log.p.error)
        addDescription(log.p.error);
      else if (log.p.hasOwnProperty('i') && params?.journey_action && [JourneyActionEnum.Rule, JourneyActionEnum.RuleContact].includes(params.journey_action)) {
        if (log.p.i === -1)
          addDescription(t('RULE_OTHERWISE_MATCHED'));
        else
          addDescription(t('RULE_X_WAS_MATCHED', { replace: { n: log.p.i + 1 } }));
      }
      else if (log.p.hasOwnProperty('t') && params?.journey_action === JourneyActionEnum.VoiceMenu)
        addDescription(t('TRY_X_BUTTON_X_PRESSED', { replace: { try: log.p.t + 1, num: log.p.v, time: new Date(log.ts) } }));
      else if (log.p.hasOwnProperty('t') && params?.journey_action === JourneyActionEnum.VoiceGetDigits)
        addDescription(t('TRY_X_DIGITS_X_PRESSED', { replace: { try: log.p.t + 1, num: log.p.v, time: new Date(log.ts) } }));
      else if (log.p.hasOwnProperty('a') && params?.journey_action === JourneyActionEnum.VoiceQueue) {
        addDescription(t(`VOICE_QUEUE_${log.p.a.toUpperCase()}`));
        if (log.p.qs && params?.journey_action === JourneyActionEnum.VoiceQueue)
          addDescription(`${t('VOICE_QUEUE_QUEUE_STATUS')}: ${t(log.p.qs.toUpperCase())}`);
      }
    });

    break;
  case 'NOTIFICATION_START':
    output.title = t('USER_X_PINGED', { replace: { user } });

    if (params?.queue_id) {
      const queue = store.queues?.dict?.[params?.queue_id].title || params?.queue_id;

      output.description.push(`${t('QUEUE')}: ${queue}`);
    }

    if (params?.timeout)
      output.description.push(t('NOTIFICATION_TIMEOUT_X_SECONDS', { replace: { sec: params?.timeout } }));

    break;
  case 'NOTIFICATION_END':
    output.title = t('USER_X_NOTIFICATION_END', { replace: { user } });

    if (params?.notification_action)
      output.description.push(t('NOTIFICATION_X_ACTION', { replace: { action: t(`NOTIFICATION_ACTION_${params?.notification_action}`) } }));

    if (params?.queue_id) {
      const queue = store.queues?.dict?.[params?.queue_id].title || params?.queue_id;

      output.description.push(`${t('QUEUE')}: ${queue}`);
    }

    if (params?.timeout)
      output.description.push(t('NOTIFICATION_TIMEOUT_X_SECONDS', { replace: { sec: params?.timeout } }));

    break;
  case 'RECORDING_START':
    output.title = t('RECORDING_STARTED_BY_X', { replace: { user: user || t('SYSTEM') } });
    break;
  case 'RECORDING_END':
    output.title = t('RECORDING_STOPPED_BY_X', { replace: { user: user || t('SYSTEM') } });
    break;
  case 'LEFT_BRIDGE':
    //output.title = t('X_LEFT_CALL', { replace: { leg: params?.hangup_by === VoiceCallHangupByEnum.Agent ? user : t(params?.hangup_by || 'UNKNOWN') } });
    if (params?.exit_reason && params?.queue_id && store.queues?.dict?.[params.queue_id])
      output.title = t('LEFT_QUEUE_WITH_REASON', { replace: { reason: params.exit_reason, queue: store.queues.dict[params.queue_id].title } });
    else if (params?.exit_reason)
      output.title = t('LEFT_CALL_WITH_REASON', { replace: { reason: params.exit_reason } });
    else
      output.title = t(title);

    break;
  case 'ANSWERED':

    if (voice_call) {
      output.title = (params?.direction || voice_call.direction) === DirectionEnum.Outbound ? params?.destination_phone ? t('CALLEE_ANSWERED') : t('CONTACT_ANSWERED') : t('USER_ANSWERED', { replace: { user } });
      if (params?.destination_phone)
        output.description.push(t('PARAMS_PHONE', { replace: { phone: translatePhoneNumber(params.destination_phone)?.formatInternational() || params.destination_phone } }));
    }

    break;
  case 'ENDED':
    output.title = t('HANGUP');
    break;
  case 'TRANSFER_OUT':

    if (voice_call) {
      output.title = t(params?.transfer_type === VoiceCallTransferTypeEnum.Blind ? 'TRANSFER' : 'CALL_TRANSFERRED_OUT');
      if (destination_bridge?.destination_phone)
        output.description.push(t('DESTINATION_PHONE', { replace: { phone: translatePhoneNumber(destination_bridge.destination_phone)?.formatInternational() || destination_bridge.destination_phone } }));

    }

    break;
  case 'TRANSFER_IN':
    output.title = t('CALL_TRANSFERRED_IN');
    break;
  case 'HOLD':
  case 'UNHOLD':
    output.title = t(title);
    break;
  case 'DISPOSED':
  case 'STATUS_CHANGED':
  case 'USER_ASSIGNED':
  case 'USER_UNASSIGNED':
  case 'QUEUE_ASSIGNED':
    output.title = t(title);
    output.description.push(`${t('USER')}: ${user}`);

    if (params) {

      if (journey_trigger_id)
        output.description.push(`${t('JOURNEY_TRIGGER_ID')}: ${journey_trigger_id}`);

      if (params.disposition_id) {
        const disposition = store.dispositions.dict[params.disposition_id];

        output.description.push(`${t('DISPOSITION_ID')}: ${disposition?.title || disposition.id}`);
      }

      if (params.sub_disposition_id) {
        const disposition = store.dispositions.dict[params.sub_disposition_id];

        output.description.push(`${t('SUB_DISPOSITION_ID')}: ${disposition?.title || disposition.id}`);
      }

      if (params.thrd_disposition_id) {
        const disposition = store.dispositions.dict[params.thrd_disposition_id];

        output.description.push(`${t('THRD_DISPOSITION_ID')}: ${disposition?.title || disposition.id}`);
      }

      if (params.status)
        output.description.push(`${t('STATUS')}: ${t(`STATUS_${params.status}`)}`);

      if (params.agent && params.agent !== params.user_id) {
        if (title === 'USER_ASSIGNED')
          output.description.push(`${t('ASSIGNED_TO')}: ${store.users.dict[params.agent] ? getFullName(store.users.dict[params.agent]) : params.agent}`);
        else if (title === 'USER_UNASSIGNED')
          output.description.push(`${t('UNASSIGNED_FROM')}: ${store.users.dict[params.agent] ? getFullName(store.users.dict[params.agent]) : params.agent}`);
      }

      if (title === 'QUEUE_ASSIGNED')
        output.description.push(`${t('QUEUE')}: ${store.queues.dict[params.queue_id || -2]?.title || params.queue_id}`);

    }

    break;
  case 'AUDIT':
    output.title = t('UPDATE');
    output.description.push(`${t('USER')}: ${user}`);
    ['first_name', 'last_name', 'emails', 'phones'].forEach(key => {
      if (params?.changed_props?.hasOwnProperty(key))
        output.description.push(`${t(key.toUpperCase())}: ${params.changed_props[key].b} -> ${params.changed_props[key].a}`);
    });
    if (params?.changed_props)
      Object.entries(params.changed_props).forEach(([key, value]) => {
        if (key.startsWith('data.') && params.changed_props)
          output.description.push(`${t(key.replace('data.', ''))}: ${params.changed_props[key].b} -> ${params.changed_props[key].a}`);
      });

    break;
  default:
    output.title = t(title);
  }

  // if (Object.keys(params || {}).length) {
  //   output.description.push('');
  //   output.description.push(JSON.stringify(params || {}));
  // }

  return {
    title: output.title,
    description: output.description.join('\n'),
    journey_id
  };
};
