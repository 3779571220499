import React from 'react';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { CrudEnum, IPreviewRelease, PermissionEnum, TReleaseSortableListCol, } from 'atlas-shared';
import { tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const ReleaseListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewRelease) => React.ReactElement> = [], filters: IWithListProps<IPreviewRelease, TReleaseSortableListCol>['filters'], sorter: IWithListProps<IPreviewRelease, TReleaseSortableListCol>['sorter']): ColumnsType<IPreviewRelease> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewRelease, b: IPreviewRelease) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    {
      title: t('VERSION'),
      dataIndex: 'version',
      key: 'version',
      sorter: (a: IPreviewRelease, b: IPreviewRelease) => tableColumnStringSorter(a.version, b.version),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'version')?.order || null,
      className: 'table-cell-version',
    },
    {
      title: t('EXECUTION_AT'),
      dataIndex: 'execution_at',
      key: 'execution_at',
      render: (execution_at: IPreviewRelease['execution_at'], release: IPreviewRelease) => <div className='date-column'>{execution_at ? timeSince(t, new Date(execution_at), true) : ''}</div>,
      sorter: (a: IPreviewRelease, b: IPreviewRelease) => tableColumnDatetimeSorter(a.execution_at, b.execution_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'execution_at')?.order || null,
      className: 'table-cell-execution_at',
    },
    {
      title: t('EXECUTED_AT'),
      dataIndex: 'executed_at',
      key: 'executed_at',
      render: (executed_at: IPreviewRelease['executed_at'], release: IPreviewRelease) => <div className='date-column'>{executed_at ? timeSince(t, new Date(executed_at), true) : ''}</div>,
      sorter: (a: IPreviewRelease, b: IPreviewRelease) => tableColumnDatetimeSorter(a.executed_at, b.executed_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'executed_at')?.order || null,
      className: 'table-cell-executed_at',
    },
    {
      title: t('FULLFILLED'),
      dataIndex: 'fullfilled',
      key: 'fullfilled',
      render: (fullfilled: IPreviewRelease['fullfilled'], release: IPreviewRelease) => tableColumnBooleanRender(t, fullfilled),
      sorter: (a: IPreviewRelease, b: IPreviewRelease) => (a.fullfilled ? 1 : a.fullfilled === null ? -1 : 0) > (b.fullfilled ? 1 : a.fullfilled === null ? -1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'fullfilled')?.order || null,
      className: 'table-cell-fullfilled',
    },
    {
      title: t('ERROR'),
      dataIndex: 'error',
      key: 'error',
      render: (error: IPreviewRelease['error'], release: IPreviewRelease) => <span className='list-description'>{error}</span>,
      className: 'table-cell-error',
    },
    {
      title: t('OUTPUT'),
      dataIndex: 'output',
      key: 'output',
      render: (output: IPreviewRelease['output'], release: IPreviewRelease) => <span className='list-description'>{output}</span>,
      className: 'table-cell-output',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewRelease['created_at'], release: IPreviewRelease) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewRelease, b: IPreviewRelease) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewRelease) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(null, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'release', row)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewRelease>;

};
