import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateVoiceVoicemail, ISharedUpdateVoiceVoicemail, ISharedVoiceVoicemail, IPreviewOrganization, IPreviewVoiceAsterisk, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IVoiceVoicemailFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  voice_asterisks: Array<IPreviewVoiceAsterisk>;
}

export const VoiceVoicemailFormOptions = (voice_voicemail: ISharedCreateVoiceVoicemail | ISharedVoiceVoicemail, props: IVoiceVoicemailFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.VoiceVoicemail, {})), 'organization_id', false), 'organization'),
  voice_asterisk_id: entitiesToOptions<IPreviewVoiceAsterisk>(props.voice_asterisks, 'voice_asterisk'),
  
});

export const VoiceVoicemailComponents: IForm['components'] = {};
export const VoiceVoicemailAdditionalParams: (auth: IAuth, props: IVoiceVoicemailFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IVoiceVoicemailFormOptionsProps) => ({
});

export const VoiceVoicemailEditForm = (voice_voicemail: ISharedVoiceVoicemail, props: IVoiceVoicemailFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `voice_voicemail_edit_${voice_voicemail.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === voice_voicemail.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'voice_asterisk_id' },
    { ref: 'title' },
    { ref: 'description' }
  ]
});

export const VoiceVoicemailAddForm = (voice_voicemail: ISharedCreateVoiceVoicemail, props: IVoiceVoicemailFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'voice_voicemail_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'voice_asterisk_id' },
    { ref: 'title' },
    { ref: 'description' }
  ]
});

export const VoiceVoicemailAddInitialValues: ISharedCreateVoiceVoicemail = {} as ISharedCreateVoiceVoicemail;

export const VoiceVoicemailEditInitialValues = (voice_voicemail: ISharedVoiceVoicemail): ISharedUpdateVoiceVoicemail => ({
  voice_asterisk_id: voice_voicemail.voice_asterisk_id,
  title: voice_voicemail.title,
  description: voice_voicemail.description
});

export const onVoiceVoicemailFormSaved = (t: TFunction, action: 'edit' | 'add', voice_voicemail: ISharedVoiceVoicemail, navigate: NavigateFunction): void => {
  navigate(onFormSaved('voice_voicemails'));
};
