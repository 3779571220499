import { TFunction } from 'i18next';
import { ellipsis } from '@Utils';
import Text from 'antd/lib/typography/Text';
import React from 'react';
import { ConversationStatusEnum, IPreviewConversation } from 'atlas-shared';

interface IProps {
  conversation: IPreviewConversation;
  shortened: boolean;
  t: TFunction;
}

export const ConversationTitle = React.memo(({ conversation, shortened, t }: IProps) => {
  const title = ellipsis(conversation.title, 85);

  return (
    <Text className={`title ${conversation.status === ConversationStatusEnum.Open ? 'bold' : ''}${!title ? ' title-missing' : ''}`} style={{ whiteSpace: shortened ? 'unset' : 'nowrap' }}>
      {title || t('TITLE_MISSING')}
    </Text>
  );
});
