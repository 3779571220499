import { RestRequest } from '@Utils/rest';

export const IMAGE_CONTENT_TYPE_PATTERN = '^image/';
export const ANY_FILE_CONTENT_TYPE_PATTERN = '.+';

export interface IDraggable {
  content_type_pattern: string;
  tag: 'img' | 'any';
  attr: 'src' | 'any';
}

export interface IDraggableConverted extends Omit<IDraggable, 'content_type_pattern'> {
  content_type_regex: RegExp;
}

interface IListFile extends Omit<IDraggable, 'content_type_pattern'> {
  file: any;
}

export function convertDraggable(draggable: IDraggable): IDraggableConverted {
  if (draggable.content_type_pattern && draggable.tag && draggable.attr) {
    return {
      content_type_regex: new RegExp(draggable.content_type_pattern),
      tag: draggable.tag,
      attr: draggable.attr
    };
  } else {
    const e = new Error('draggables should have content_type_pattern, tag and attr keys');

    // @ts-ignore
    e.invalid_draggable = draggable;
    throw e;
  }
}

export const GLOBAL_DRAGGABLE: IDraggableConverted = {
  content_type_regex: /./,
  tag: 'any',
  attr: 'any'
};

export function filesMatching(file_list, draggables: Array<IDraggableConverted> = [GLOBAL_DRAGGABLE]): Array<IListFile> {
  const ret: Array<IListFile> = [];

  for (let i = 0; i < file_list.length; i++) {
    const file = file_list.item(i);
    const draggable = draggables.find(d => d.content_type_regex.test(file.type || '-'));

    draggable && ret.push({ file, tag: draggable.tag, attr: draggable.attr });
  }
  return ret;
}

export function getFileDataUrl(file) {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.addEventListener('load', function () {
      resolve(reader.result);
    }, false);
    reader.readAsDataURL(file);
  });
}

export const htmlElementOnDropFiles = (url: string, propName?: string, onDrop?: Function, onUploaded?: Function) => {
  return (event) => {
    
    const upload = async () => {
      const files = filesMatching(event.dataTransfer.files);

      for (const file of files) {
        await RestRequest.upload(url, file.file, propName);
      }

      onUploaded?.();
    };

    event.preventDefault();
    event.stopPropagation();

    onDrop?.();
    
    upload();

  };
};
