import { PhonenumberMonthlyCostParentActions } from '@Store';
import { IPreviewPhonenumberMonthlyCostParent } from 'atlas-shared';
import { fetchPhonenumberMonthlyCostParents } from '@Api';

export const actionFetchPhonenumberMonthlyCostParents = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPhonenumberMonthlyCostParents());
    const phonenumber_monthly_cost_parents = await fetchPhonenumberMonthlyCostParents();

    dispatch(actionSetPhonenumberMonthlyCostParents(phonenumber_monthly_cost_parents));
    return phonenumber_monthly_cost_parents;
  };
};

export const actionLoadingPhonenumberMonthlyCostParents = () => ({
  type: PhonenumberMonthlyCostParentActions.LOADING,
  payload: []
});

export const actionSetPhonenumberMonthlyCostParents = (payload: Array<IPreviewPhonenumberMonthlyCostParent>) => ({
  type: PhonenumberMonthlyCostParentActions.SET_STATE,
  payload: payload
});

export const actionAddPhonenumberMonthlyCostParent = (payload: IPreviewPhonenumberMonthlyCostParent) => ({
  type: PhonenumberMonthlyCostParentActions.ADD_STATE,
  payload: payload
});

export const actionUpdatePhonenumberMonthlyCostParent = (payload: IPreviewPhonenumberMonthlyCostParent) => ({
  type: PhonenumberMonthlyCostParentActions.UPDATE_STATE,
  payload: payload
});

export const actionRemovePhonenumberMonthlyCostParent = (id: IPreviewPhonenumberMonthlyCostParent['id']) => ({
  type: PhonenumberMonthlyCostParentActions.REMOVE_STATE,
  payload: id
});
