import { IPreviewConversation, Nullable, IPreviewConversationFilter, IPreviewAgentprofile } from 'atlas-shared';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { fetchConversationCount } from '@Api';

interface IProps {
  conversation_filter_id: IPreviewConversationFilter['id'];
  agentprofile_id: Nullable<IPreviewAgentprofile['id']>;
  shortened: boolean;
  conversations: Array<IPreviewConversation>;
  checked: boolean;
  indeterminate: boolean;
  onChange: (checked: boolean) => void;
}

export const ConversationListLeftCountBar = ({
  conversation_filter_id,
  agentprofile_id,
  shortened,
  conversations,
  checked,
  indeterminate,
  onChange
}: IProps) => {
  const { t } = useTranslation();
  const [count, setCount] = useState<Nullable<number>>(null);

  const hide = useMemo(() => {
    return conversations.length > 100;
  }, [conversations]);

  useEffect(() => {
    fetchConversationCount(conversation_filter_id, agentprofile_id || 0)
      .then(count => setCount(count));
  }, []);

  return (
    <div className={'count-bar'}>
      {!hide && <Checkbox
        checked={checked}
        indeterminate={indeterminate}
        onChange={() => onChange(!checked)}
      />}
      <span className={'text'}>
        {t('SHOWING')} <strong>{`1-${conversations.length}`}</strong>{!!count && <> {t('OF')} ~<strong>{count}</strong></>}{!shortened && <> {t('CONVERSATIONS')}</>}
      </span>
    </div>
  );
};
