import React, { useMemo } from 'react';
import { getStatsWidgetMetricTitle, getStatsWidgetTableMetricStyles } from '@Utils';
import { StatsWidgetMetricData } from '../../stats_widget.metric.data';
import { IWithStatsWidgetProps, withStatsWidget } from '@Hocs/with.stats_widget';
import './stats.widget.text.scss';
import { useWindowSize } from '@Hooks';
import { AlignmentEnum } from 'atlas-shared/dist';

export const _WidgetTypeText = ({ data: _data, metrics, stats_widget, t: _t, metric_data_dicts, innerWidth, innerHeight, stats_custom_metrics, organization }: IWithStatsWidgetProps) => {

  const [windowWidth] = useWindowSize();
  const w = useMemo(() => Math.max(innerWidth || windowWidth, 1), [innerWidth, windowWidth]);
  const h = useMemo(() => Math.max(innerHeight || 800, 30), [innerHeight]);
  const area = useMemo(() => w * h * .9, [w, h]);

  const layout = useMemo(() => {

    let boxSize = Math.floor(Math.sqrt(area / stats_widget.settings.metrics.length));
    let rows = Math.floor(h / boxSize);
    let perRow = Math.ceil(stats_widget.settings.metrics.length / rows);

    boxSize = Math.min(w / perRow, h / rows);

    while (true) {
      if (perRow > 1 && h / rows > boxSize) {
        const _rows = rows + 1;
        const _perRow = Math.ceil(stats_widget.settings.metrics.length / _rows);
        const _boxSize = Math.min(w / _perRow, h / _rows);

        if (_boxSize > boxSize) {
          rows = _rows;
          perRow = _perRow;
          boxSize = _boxSize;
          continue;
        }
        else
          break;
      }

      break;
    }

    boxSize *= .9;

    return {
      rows,
      perRow,
      boxSize: Math.floor(boxSize)
    };
  }, [w, h, stats_widget.settings.metrics]);

  if (!_data?.r[0])
    return <></>;

  const { d, t, tc, tp: trend_period } = _data.r[0];

  return <div className='widget-type-text'>
    <div className='metric-wrapper'>
      {new Array(layout.rows).fill(null).map((r, ri) => {
        return <div className='row'>
          {new Array(layout.perRow).fill(null).map((_, i) => {
            const index = (layout.perRow * ri) + i;
            const metric = stats_widget.settings.metrics[index];

            if (!metric)
              return <></>;

            const metric_index = stats_widget.settings.split_by.length + index;
            const { elm } = getStatsWidgetMetricTitle(_t, metric, stats_custom_metrics.stats_custom_metrics);

            return <div className='box' style={{ width: layout.boxSize, height: layout.boxSize, ...getStatsWidgetTableMetricStyles(metric, d[metric_index], t?.[metric_index], tc?.[metric_index], stats_widget) }}>
              {!metric.hide_title && <div className='title'>{elm}</div>}
              <StatsWidgetMetricData
                t={_t}
                organization={organization}
                stats_widget={stats_widget}
                metric_settings={metric}
                metric={metrics[index]}
                value={d[metric_index]}
                trend_value={t?.[metric_index]}
                trend_comparison={tc?.[metric_index]}
                trend_period={trend_period}
                metric_data_dicts={metric_data_dicts}
                fitText={layout.boxSize}
                defaultAlignment={AlignmentEnum.Center}
                exclude_styles
              />
            </div>;
          })}
        </div>;

      })}
    </div>
  </div>;
};

export const WidgetTypeText = React.memo(withStatsWidget(_WidgetTypeText));
