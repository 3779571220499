import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateWallboard, ISharedUpdateWallboard, ISharedWallboard, IPreviewOrganization, IPreviewUser, IPreviewStatsReport, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IWallboardFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  users: Array<IPreviewUser>;
  stats_reports: Array<IPreviewStatsReport>;
}

export const WallboardFormOptions = (wallboard: ISharedCreateWallboard | ISharedWallboard, props: IWallboardFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Wallboard, {})), 'organization_id', false), 'organization'),
  user_id: (it: ISharedWallboard) => filterRelated<IPreviewUser>(it.organization_id || wallboard.organization_id, 'user', 'wallboard', props.users, true),
  stats_report_id: (it: ISharedWallboard) => filterRelated<IPreviewStatsReport>(it.organization_id || wallboard.organization_id, 'stats_report', 'wallboard', props.stats_reports, true),
  
});

export const WallboardComponents: IForm['components'] = {};
export const WallboardAdditionalParams: (auth: IAuth, props: IWallboardFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IWallboardFormOptionsProps) => ({
});

export const WallboardEditForm = (wallboard: ISharedWallboard, props: IWallboardFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `wallboard_edit_${wallboard.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === wallboard.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'wallboard_key' },
    { ref: 'user_id' },
    { ref: 'stats_report_id' },
    { ref: 'share_data' }
  ]
});

export const WallboardAddForm = (wallboard: ISharedCreateWallboard, props: IWallboardFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'wallboard_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'wallboard_key' },
    { ref: 'user_id' },
    { ref: 'stats_report_id' },
    { ref: 'share_data' }
  ]
});

export const WallboardAddInitialValues: ISharedCreateWallboard = {} as ISharedCreateWallboard;

export const WallboardEditInitialValues = (wallboard: ISharedWallboard): ISharedUpdateWallboard => ({
  title: wallboard.title,
  wallboard_key: wallboard.wallboard_key,
  user_id: wallboard.user_id,
  stats_report_id: wallboard.stats_report_id,
  share_data: wallboard.share_data
});

export const onWallboardFormSaved = (t: TFunction, action: 'edit' | 'add', wallboard: ISharedWallboard, navigate: NavigateFunction): void => {
  navigate(onFormSaved('wallboards'));
};
