import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchApiKeys, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useApiKeys = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const api_keys = useSelector((store: IReducerStore) => store.api_keys);

  useEffect(() => {
    if (!initiated && autoload && !api_keys.loaded && !api_keys.loading) {
      initiated = true;
      dispatch(actionFetchApiKeys());
    }
  }, [autoload, api_keys.loaded, api_keys.loading, dispatch]);

  return api_keys;
};
