import React from 'react';
import { ISharedFullConversation, TConversationVoiceCall } from 'atlas-shared';
import { IAuth } from '@Store';
import { ConversationMessageVoiceCall } from '@Components';
import { THighlightedMessage } from '@Hooks';

interface IProps {
  message: TConversationVoiceCall;
  conversation: ISharedFullConversation;
  auth: IAuth;
  highlightedMessage: THighlightedMessage;
}

export const ConversationMessageVoiceCallMini = React.memo(({ message, conversation, auth, highlightedMessage }: IProps) => {
  return <>
    <ConversationMessageVoiceCall message={message} conversation={conversation} auth={auth} mini={true} highlightedMessage={highlightedMessage} />
  </>;
});
