import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const UnknownIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M 10.324219 16.199219 L 9.359375 16.199219 C 8.675781 16.199219 8.125 16.753906 8.125 17.433594 L 8.125 18.765625 C 8.125 19.445312 8.675781 20 9.359375 20 L 10.324219 20 C 11.003906 20 11.558594 19.445312 11.558594 18.765625 L 11.558594 17.433594 C 11.558594 16.75 11.003906 16.199219 10.324219 16.199219 Z M 10.324219 16.199219 "/>
    <path d="M 3.511719 5.875 L 5.292969 6.097656 C 5.859375 6.164062 6.394531 5.839844 6.597656 5.308594 C 6.855469 4.636719 7.214844 4.105469 7.671875 3.71875 C 8.320312 3.171875 9.128906 2.898438 10.09375 2.898438 C 11.097656 2.898438 11.894531 3.160156 12.484375 3.683594 C 13.078125 4.210938 13.371094 4.839844 13.371094 5.578125 C 13.371094 6.109375 13.203125 6.59375 12.871094 7.03125 C 12.65625 7.3125 11.992188 7.902344 10.882812 8.796875 C 9.773438 9.695312 9.035156 10.507812 8.667969 11.226562 C 8.289062 11.960938 8.15625 12.777344 8.125 13.597656 C 8.09375 14.296875 8.65625 14.878906 9.359375 14.878906 L 10.363281 14.878906 C 11.007812 14.878906 11.535156 14.390625 11.59375 13.75 C 11.636719 13.292969 11.710938 12.945312 11.824219 12.710938 C 12.019531 12.304688 12.519531 11.769531 13.320312 11.105469 C 14.871094 9.820312 15.882812 8.804688 16.359375 8.058594 C 16.832031 7.3125 17.070312 6.519531 17.070312 5.683594 C 17.070312 4.171875 16.425781 2.847656 15.136719 1.707031 C 13.851562 0.570312 12.117188 0 9.9375 0 C 7.867188 0 6.195312 0.5625 4.921875 1.683594 C 3.835938 2.644531 3.175781 3.777344 2.941406 5.085938 C 2.871094 5.46875 3.132812 5.828125 3.511719 5.875 Z M 3.511719 5.875 "/>
  </SvgIcon>;
};
