import { FormElementTypes, IForm } from 'atlas-form';
import { CannedAnswerTypeEnum } from 'atlas-shared';

export * from './abstract/signature.form.abstract';
import {
  ISignatureFormOptionsProps,
  SignatureAdditionalParams as SignatureAdditionalParamsBase
} from './abstract/signature.form.abstract';
import { IAuth } from '@Store';

export const SignatureAdditionalParams: (auth: IAuth, props: ISignatureFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISignatureFormOptionsProps) => ({
  ...SignatureAdditionalParamsBase(auth, props),
  body: (a) => ({ ui_type: FormElementTypes.HTML, atOptions: ['user'], hashOptions: a.type === CannedAnswerTypeEnum.Agent ? ['conversation', 'contact', 'agent'] : ['conversation', 'contact'] })
});
