import { IPreviewTariff } from 'atlas-shared';

export enum TariffActions {
  SET_STATE = 'TARIFF_SET_STATE',
  ADD_STATE = 'TARIFF_ADD_STATE',
  REMOVE_STATE = 'TARIFF_REMOVE_STATE',
  GET_STATE = 'TARIFF_GET_STATE',
  UPDATE_STATE = 'TARIFF_UPDATE_STATE',
  LOADING = 'TARIFF_LOADING',
}

export interface ITariffLoading {
  type: TariffActions.LOADING;
}

export interface ITariffSetAction {
  type: TariffActions.SET_STATE;
  payload: Array<IPreviewTariff>;
}

export interface ITariffUpdateAction {
  type: TariffActions.UPDATE_STATE;
  payload: IPreviewTariff;
}

export interface ITariffAddAction {
  type: TariffActions.ADD_STATE;
  payload: IPreviewTariff;
}

export interface ITariffRemoveAction {
  type: TariffActions.REMOVE_STATE;
  payload: IPreviewTariff['id'];
}

export interface ITariffStore {
  loaded: boolean;
  loading: boolean;
  tariffs: Array<IPreviewTariff>;
  dict: Record<IPreviewTariff['id'], IPreviewTariff>;
}

export type TTariffActionTypes = ITariffSetAction | ITariffUpdateAction | ITariffAddAction | ITariffRemoveAction | ITariffLoading;
