import React, { PropsWithChildren } from 'react';
import { IPreviewUser } from 'atlas-shared';
import { getFullName, getInitials } from '@Utils';
import { UserIcon } from '@Assets/icons';
import { RestRequest } from '@Utils/rest';
import { Icon } from './icon';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface IProps {
  user?: IPreviewUser;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  style?: any;
  size: number;
  postFixName?: boolean;
  tooltipPlacement?: TooltipPlacement;
  tooltipTitle?: string;
}

export const Avatar = React.memo((props: PropsWithChildren<IProps>) => {
  const { onClick, user, className, style: _style, size, postFixName, tooltipPlacement, tooltipTitle } = props;
  const style = { ..._style, width: size, height: size, fontSize: `${Math.round(size / 2)}px` };
  
  if (!user) {
    const figure = <figure style={{ ...style, background: 'var(--grey-middle)', color: '#000533' }} className={`avatar ${className}`}>
      <Icon icon={UserIcon} iconProps={{ style: { color: 'rgba(0,5,51,.5)', width: size, height: size } }}/>
    </figure>;
    
    if (tooltipTitle)
      return <Tooltip title={tooltipTitle} placement={tooltipPlacement}>{figure}</Tooltip>;

    return figure;
  }
  
  return <Tooltip title={getFullName(user)} placement={tooltipPlacement}>
    <div className={`avatar-wrapper${postFixName ? ' has-full-name' : ''}`}>
      <figure {...props} className={`avatar ${className}`} style={{ ...style }}>
        {((user.avatar) && (<img style={{ borderRadius: '50%', display: 'block' }} onError={(event) => (event.target as HTMLImageElement).style.display = 'none' } className={className} onClick={onClick} src={RestRequest.getFullSrc(`/user/${[14, 18].includes(size) ? size : 40}-${user.avatar}/avatar`)} alt={getInitials(user)} />))}
        {getInitials(user)}
      </figure>
      {postFixName && <span className='full-name'>{getFullName(user)}</span>}
    </div>
  </Tooltip>;
}, (prev, next) => prev.user?.avatar === next.user?.avatar);
