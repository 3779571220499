import { FieldTypeEnum, IPreviewField, ISharedCreateListFile, ISharedForm, ISharedListFile } from 'atlas-shared';
import { TFunction } from 'i18next';
import { IForm, IFormOptions } from 'atlas-form';
import {
  IListFileFormOptionsProps,
  ListFileAdditionalParams as ListFileAdditionalParamsBase,
  ListFileFormOptions as ListFileFormOptionsBase
} from './abstract/list_file.form.abstract';
import { filterRelated } from '@Utils';
import { FormComponentListFields } from './components/list-fields';
import { IAuth } from '@Store';

export * from './abstract/list_file.form.abstract';

export const ListFileFormOptions = (list_file: ISharedCreateListFile | ISharedListFile, props: IListFileFormOptionsProps, t: TFunction): IFormOptions => ({
  ...ListFileFormOptionsBase(list_file, props, t),
  fields: (it: ISharedForm) => filterRelated<IPreviewField>(it.organization_id || list_file.organization_id, 'field', 'list_file', props.fields, true, field => field.type === FieldTypeEnum.Contact, true),
});

export const ListFileAdditionalParams: (auth: IAuth, props: IListFileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IListFileFormOptionsProps) => ({
  ...ListFileAdditionalParamsBase(auth, props),
  fields: {
    shouldUpdate: (prev, next) => prev.functionParams[0].original_filename !== next.functionParams[0].original_filename,
    help: 'LIST_FILE_FIELDS_HELP'
  }
});

export const ListFileComponents: IForm['components'] = {
  fields: FormComponentListFields
};
