import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const CameraIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
    <circle cx="12" cy="13" r="4"></circle>
  </SvgIcon>;
};
