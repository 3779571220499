import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions } from 'atlas-form';
import {
  VoiceVoicemailEditForm,
  VoiceVoicemailEditInitialValues,
  VoiceVoicemailComponents,
  VoiceVoicemailAdditionalParams,
  VoiceVoicemailFormOptions,
  onVoiceVoicemailFormSaved,
  IVoiceVoicemailFormOptionsProps,
  AlertError
} from '@Utils';
import { ISharedVoiceVoicemail, ISharedPatchVoiceVoicemail, PermissionEnum, CrudEnum } from 'atlas-shared';
import { fetchVoiceVoicemail, patchVoiceVoicemail, getVoiceVoicemailUpdateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withEdit, IWithEditProps } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useVoiceAsterisks } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithEditProps<ISharedVoiceVoicemail> {}

const VoiceVoicemailFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const voice_asterisks = useVoiceAsterisks();

  useEffect(() => {
    fetchVoiceVoicemail(id).then(voice_voicemail => setValue(voice_voicemail));
  }, [id, setValue]);

  const param_props: IVoiceVoicemailFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    voice_asterisks: voice_asterisks.voice_asterisks
  };
  const formLayout: IForm | undefined = useMemo(() => value && VoiceVoicemailEditForm(value, param_props, t), [value, organizations, voice_asterisks, t, abilities]);
  const options: IFormOptions | undefined = useMemo(() => value && VoiceVoicemailFormOptions(value, param_props, t), [value, organizations, voice_asterisks, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchVoiceVoicemail, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchVoiceVoicemail) => value && save(() => patchVoiceVoicemail(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceVoicemailFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [value, save, navigate, t]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !voice_asterisks.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.VoiceVoicemail, value)}
    >
      <AtlasForm<ISharedPatchVoiceVoicemail>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={VoiceVoicemailComponents}
        additionalParams={VoiceVoicemailAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={VoiceVoicemailEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('VOICE_VOICEMAIL')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceVoicemailFormEditView = React.memo(withEdit(VoiceVoicemailFormEdit, getVoiceVoicemailUpdateSchema));
