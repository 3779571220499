import { IPreviewQueue } from 'atlas-shared';
import { IQueueStore, QueueActions, TQueueActionTypes } from '@Store';

export const QueueReducer = (state: IQueueStore = {
  loaded: false,
  loading: false,
  queues: [],
  dict: {}
}, action: TQueueActionTypes): IQueueStore => {
  switch (action.type) {
  case QueueActions.LOADING:
    return { ...state, loading: true };
  case QueueActions.SET_STATE:
    const dict: IQueueStore['dict'] = {};

    action.payload.forEach((queue: IPreviewQueue) => dict[queue.id] = queue);

    return { ...state, loaded: true, loading: false, queues: action.payload, dict };
  case QueueActions.ADD_STATE:
    return { ...state, queues: [...state.queues, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case QueueActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, queues: state.queues.filter((item: IPreviewQueue) => item.id !== action.payload), dict };
  }
  case QueueActions.UPDATE_STATE:
    const queues = [...state.queues];
    const index = state.queues.findIndex((queue: IPreviewQueue) => queue.id === action.payload.id);

    if (!~index)
      queues.push(action.payload);
    else
      queues[index] = action.payload;

    return { ...state, queues, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
