import { fetchVoiceCalls } from '@Api';
import { actionLoadingVoiceCalls, actionSetVoiceCalls } from './abstract/voice_call.actions.abstract';

export const actionFetchVoiceCalls = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceCalls());
    const voice_calls = await fetchVoiceCalls();

    dispatch(actionSetVoiceCalls(voice_calls));
    return voice_calls;
  };
};

export * from './abstract/voice_call.actions.abstract';
