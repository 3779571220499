import React, { Dispatch, SetStateAction } from 'react';
import './admin.page.header.scss';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { Button } from '@Components';
import { IWithListProps } from '@Hocs/with.list';
import { TFunction } from 'i18next';

interface IAdminPageHeaderProps{
  t: TFunction;
  title: string;
  addBtn?: {
    callback: () => void;
    text?: string;
  };
  deleted?: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ];
  deletedCount?: number;
  filtersCount: IWithListProps<any, any>['filtersCount'];
  reset: () => void;
}

export const AdminListPageHeader = React.memo(({ deleted, title, deletedCount, addBtn, filtersCount, reset }: IAdminPageHeaderProps) => {

  const [showDeleted, setShowDeleted] = deleted || [false, () => {}];

  const { t } = useTranslation();

  return (
    <div className='admin-list-page-header'>
      <h1>
        {title}
        {filtersCount > 0 && <span className='filters' onClick={() => reset?.()}>{t('HEADER_FILTERS_COUNT', { replace: { count: filtersCount } })}</span>}
      </h1>
      {(deletedCount || 0) > 0 && <div className='display-deleted'><Checkbox checked={showDeleted} onChange={e => setShowDeleted(e.target.checked)}>{t('DISPLAY_DELETED')} <span className='count'>{deletedCount}</span></Checkbox></div>}
      {
        addBtn && <Button type='primary' onClick={() => addBtn?.callback()}>
          {addBtn?.text || t('ADD_NEW')}
        </Button>
      }
    </div>
  );
});
