import { getStatsWidgetMetricData, getStatsWidgetTableMetricStyles } from '@Utils';
import React, { useMemo } from 'react';
import {
  IPreviewStatsWidget,
  IStatsWidgetDataTableMinifiedRow,
  IStatsWidgetDataTableMinified,
  IPreviewOrganization
} from 'atlas-shared/dist';
import { IWithStatsWidgetProps } from '@Hocs/with.stats_widget';
import { WidgetTypeTableTrTd } from './widget-type.table.tr.td';

interface IProps {
  t: IWithStatsWidgetProps['t'];
  organization: IPreviewOrganization;
  stats_widget: IPreviewStatsWidget;
  data: IStatsWidgetDataTableMinified;
  row: IStatsWidgetDataTableMinifiedRow;
  index: number;
  metrics: IWithStatsWidgetProps['metrics'];
  stats_custom_metrics: IWithStatsWidgetProps['stats_custom_metrics'];
  metric_data_dicts: IWithStatsWidgetProps['metric_data_dicts'];
}

export const WidgetTypeTableTr = React.memo((props: IProps) => {

  const { stats_widget, data, t: _t, stats_custom_metrics, metric_data_dicts, metrics, row, index, organization } = props;
  const { d, c, t, tc, tp: trend_period } = row;
  const sub_rows = useMemo(() => c?.r?.map((sr, si) => <WidgetTypeTableTr key={si} {...props} data={c} row={sr} index={si} />), [c]);

  return <React.Fragment key={`${data.g}-${index}`}>
    <tr key={`${data.g}-${index}`} className={`${data.g} row-${index % 2 ? 'odd' : 'even'}`}>
      {stats_widget.settings.group_by.map((m, i) => data.g !== 'group' ? <td key={m.value}></td> : <td key={m.value} style={getStatsWidgetTableMetricStyles(m, d[i], t?.[i], tc?.[i])}><div className='metric'><div className='value ellipsis'>{getStatsWidgetMetricData(_t, m, d[i], metric_data_dicts, organization)}</div></div></td>)}
      {stats_widget.settings.sub_group_by.map((m, i) => data.g !== 'sub_group' ? <td key={m.value}></td> : <td key={m.value}><div className='metric'><div className='value ellipsis'>{getStatsWidgetMetricData(_t, m, d[i], metric_data_dicts, organization)}</div></div></td>)}
      {stats_widget.settings.metrics.map((m, i) => {
        const metric_index = stats_widget.settings.group_by.length + i;

        return <WidgetTypeTableTrTd
          key={m.value}
          t={_t}
          organization={organization}
          stats_widget={stats_widget}
          metric_settings={m}
          metric={metrics[i]}
          value={d[metric_index]}
          trend_value={t?.[i]}
          trend_comparison={tc?.[i]}
          trend_period={trend_period}
          metric_data_dicts={metric_data_dicts}
        />;
      })}
    </tr>
    {sub_rows}
  </React.Fragment>;
}, (prevProps, nextProps) => (
  JSON.stringify(prevProps.row) === JSON.stringify(nextProps.row)
  && JSON.stringify(prevProps.stats_widget.settings || {}) === JSON.stringify(nextProps.stats_widget.settings || {})
));
