import {
  IPreviewAgentprofile,
  IPreviewVoiceCall, IPreviewVoiceVoicemailMessage, PermissionEnum,
  PermissionSelfEnum,
  Undefinable,
  CrudEnum
} from 'atlas-shared';
import {
  downloadVoiceVoicemailMessage, streamVoiceVoicemailMessage
} from '@Api';
import React, { useCallback, useEffect, useState } from 'react';
import { IAbilities, IAuth, IUserStore } from '@Store';
import { AlertError, timeDiff } from '@Utils';
import { useTranslation } from 'react-i18next';
import { AudioPlayer, AudioPlayerTheme } from '@Utils/audio-player';

interface IProps {
  voice_voicemail_message: IPreviewVoiceVoicemailMessage;
  users: IUserStore;
  call: IPreviewVoiceCall;
  agentprofile: Undefinable<IPreviewAgentprofile>;
  auth: IAuth;
  abilities: IAbilities;
}

export const ConversationMessageVoiceCallVoicemail = React.memo(({ call, voice_voicemail_message, agentprofile, auth, abilities, users }: IProps) => {
  const [audio, setAudio] = useState<Undefinable<string>>();
  const [canPlay, setCanPlay] = useState<boolean>(false);
  const [canDownload, setCanDownload] = useState<boolean>(false);
  const { t } = useTranslation();

  const renderTime = useCallback(() => timeDiff(voice_voicemail_message.created_at, voice_voicemail_message.end_time || new Date()), [t, voice_voicemail_message.created_at, voice_voicemail_message.end_time]);
  const [duration, setDuration] = useState<number>(renderTime());
  const [timerInterval, setTimerInterval] = useState<any>();

  useEffect(() => {
    if (voice_voicemail_message.end_time)
      return setDuration(renderTime());

    setTimerInterval(
      setInterval(() => {
        setDuration(renderTime());
        return () => {
          clearInterval(timerInterval);
        };
      }, 1000)
    );
  }, []);

  useEffect(() => {
    if (voice_voicemail_message.end_time) {
      clearInterval(timerInterval);
      setTimerInterval(undefined);
    }
  }, [voice_voicemail_message.end_time]);

  const load = () => {
    if (!audio && canPlay && voice_voicemail_message.end_time)
      streamVoiceVoicemailMessage(voice_voicemail_message.id, e => AlertError(t, { content: t('DOWNLOAD_VOICEMAIL_ERROR') })).then(blob => setAudio(blob));
  };

  useEffect(() => {
    const play_permission: Undefinable<PermissionSelfEnum> = agentprofile?.settings?.play_recording;
    const download_permission: Undefinable<PermissionSelfEnum> = agentprofile?.settings?.download_recording;

    setCanPlay(abilities.canImplicit(null, CrudEnum.Read, PermissionEnum.VoiceVoicemailMessage) || play_permission === PermissionSelfEnum.All);
    setCanDownload(abilities.canImplicit(null, CrudEnum.Read, PermissionEnum.VoiceVoicemailMessage) || download_permission === PermissionSelfEnum.All);
  }, [agentprofile, abilities]);

  if (!canPlay && !canDownload)
    return <></>;

  return <div>
    <span onClick={e => load()}>
      <AudioPlayer
        t={t}
        users={users}
        canPlay={canPlay}
        theme={!voice_voicemail_message.end_time ? AudioPlayerTheme.VoicemailRecording : AudioPlayerTheme.VoicemailPlayable}
        src={audio}
        start_time={voice_voicemail_message.created_at}
        end_time={voice_voicemail_message.end_time}
        download={canDownload ? () => downloadVoiceVoicemailMessage(voice_voicemail_message.id, `voicemail-${call.conversation_id}-${call.id}-${voice_voicemail_message.id}.wav`, (err => AlertError(t, { title: t('SOMETHING_WENT_WRONG'), content: <span><div>{t('DOWNLOAD_RECORDING_ERROR')}</div><div>{err}</div></span> }))) : undefined}
      />
    </span>
  </div>;
});
