import { TagActions } from '@Store';
import { IPreviewTag } from 'atlas-shared';
import { fetchTags } from '@Api';

export const actionFetchTags = () => {
  return async (dispatch) => {
    dispatch(actionLoadingTags());
    const tags = await fetchTags();

    dispatch(actionSetTags(tags));
    return tags;
  };
};

export const actionLoadingTags = () => ({
  type: TagActions.LOADING,
  payload: []
});

export const actionSetTags = (payload: Array<IPreviewTag>) => ({
  type: TagActions.SET_STATE,
  payload: payload
});

export const actionAddTag = (payload: IPreviewTag) => ({
  type: TagActions.ADD_STATE,
  payload: payload
});

export const actionUpdateTag = (payload: IPreviewTag) => ({
  type: TagActions.UPDATE_STATE,
  payload: payload
});
