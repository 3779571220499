import { Icon } from '@Components';
import { EditIcon } from '@Assets/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { queryParam } from '@Utils/url';

export const listEditButton = <T extends {id: number}>(t: TFunction, navigate: NavigateFunction, entity: string, row: T): React.ReactElement => {
  const params: Array<{key: string; value: string}> = [];

  ['p', 's', 'f', 'ff'].forEach(key => {
    const value = queryParam(key);

    if (value)
      params.push({ key, value });
  });

  return <Tooltip overlayClassName={'table-tooltip-custom'} arrowPointAtCenter title={t('EDIT')}
    placement="bottom">
    <div className={'table-icon-wrp'}>
      <Icon icon={EditIcon} iconProps={{ size: 14 }} onClick={_ => navigate(`/admin/${entity}/${row.id}/edit${params.length ? `?${params.map(({ key, value }) => `${key}=${value}`).join('&')}` : ''}`) }/>
    </div>
  </Tooltip>;
};
