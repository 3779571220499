import { IAuth } from '@Store';
import { IForm } from 'atlas-form';
import { IPhonenumberPurchaseFormOptionsProps } from './abstract/phonenumber_purchase.form.abstract';
import { FormComponentPhonenumberPreview } from '@Utils/forms/components/phonenumber-preview';

export * from './abstract/phonenumber_purchase.form.abstract';

export const PhonenumberPurchaseComponents: IForm['components'] = {
  number: FormComponentPhonenumberPreview
};

export const PhonenumberPurchaseAdditionalParams: (auth: IAuth, props: IPhonenumberPurchaseFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberPurchaseFormOptionsProps) => ({
  provider: { hidden: true },
  phonenumber_prefix_id: { hidden: true }
});
