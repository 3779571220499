import { RestRequest } from '@Utils';
import { IPreviewVoiceTrunk, ISharedVoiceTrunk, ISharedCreateVoiceTrunk, ISharedPatchVoiceTrunk } from 'atlas-shared';

export const fetchVoiceTrunks = async (): Promise<Array<IPreviewVoiceTrunk>> => {
  return RestRequest.get<Array<IPreviewVoiceTrunk>>('voice_trunk', { socket_id: global.socketId });
};

export const fetchVoiceTrunk = async (id: IPreviewVoiceTrunk['id']): Promise<ISharedVoiceTrunk> => {
  return RestRequest.get<ISharedVoiceTrunk>(`voice_trunk/${id}`, { socket_id: global.socketId });
};

export const createVoiceTrunk = (payload: ISharedCreateVoiceTrunk) => {
  return RestRequest.post<any, ISharedVoiceTrunk>('voice_trunk', payload, { socket_id: global.socketId });
};

export const patchVoiceTrunk = (id: IPreviewVoiceTrunk['id'], payload: ISharedPatchVoiceTrunk) => {
  return RestRequest.patch<any, ISharedVoiceTrunk>(`voice_trunk/${id}`, payload, { socket_id: global.socketId });
};

export const deleteVoiceTrunk = (id: IPreviewVoiceTrunk['id']) => {
  return RestRequest.delete(`voice_trunk/${id}`, { socket_id: global.socketId });
};

export const getVoiceTrunkUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_trunk/update/schema');
};

export const getVoiceTrunkCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_trunk/create/schema');
};

export const getVoiceTrunkPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('voice_trunk/patch/schema');
};
