import { VoiceVoicemailActions } from '@Store';
import { IPreviewVoiceVoicemail } from 'atlas-shared';
import { fetchVoiceVoicemails } from '@Api';

export const actionFetchVoiceVoicemails = () => {
  return async (dispatch) => {
    dispatch(actionLoadingVoiceVoicemails());
    const voice_voicemails = await fetchVoiceVoicemails();

    dispatch(actionSetVoiceVoicemails(voice_voicemails));
    return voice_voicemails;
  };
};

export const actionLoadingVoiceVoicemails = () => ({
  type: VoiceVoicemailActions.LOADING,
  payload: []
});

export const actionSetVoiceVoicemails = (payload: Array<IPreviewVoiceVoicemail>) => ({
  type: VoiceVoicemailActions.SET_STATE,
  payload: payload
});

export const actionAddVoiceVoicemail = (payload: IPreviewVoiceVoicemail) => ({
  type: VoiceVoicemailActions.ADD_STATE,
  payload: payload
});

export const actionUpdateVoiceVoicemail = (payload: IPreviewVoiceVoicemail) => ({
  type: VoiceVoicemailActions.UPDATE_STATE,
  payload: payload
});
