import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const CornerUpLeft = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
  </SvgIcon>;
};

export const CornerUpLeftDouble = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="9 14 4 9 9 4"></polyline>
    <path d="M20 20v-7a4 4 0 0 0-4-4H4"></path>
  </SvgIcon>;
};

export const CornerUpRight = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="15 14 20 9 15 4"></polyline><path d="M4 20v-7a4 4 0 0 1 4-4h12"></path>
  </SvgIcon>;
};
