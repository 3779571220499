import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { queryParam } from '@Utils';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IWithListSorterProps<T, TSortableCols> {
  column: TSortableCols;
  order: 'ascend' | 'descend';
}

export interface IWithListPaginationProps {
  current: number;
  pageSize: number;
}

export interface IWithListQueryParamsProps<T extends any, TSortableCols> {
  sorter?: IWithListProps<T, TSortableCols>['sorter'];
  pagination?: IWithListProps<T, TSortableCols>['pagination'];
  filters?: IWithListProps<T, TSortableCols>['filters'];
}

export interface IWithListProps<T extends any, TSortableCols> {
  filters: {
    [P in keyof T]?: Array<any>;
  };
  forcedFilters: {
    [P in keyof T]?: Array<any>;
  };
  filtersCount: number;
  setFilters: Dispatch<SetStateAction<Record<string, any>>>;
  hasFilters: boolean;
  sorter: Array<IWithListSorterProps<T, TSortableCols>>;
  setSorter: Dispatch<SetStateAction<Array<IWithListSorterProps<T, TSortableCols>>>>;
  pagination: IWithListPaginationProps;
  setPagination: Dispatch<SetStateAction<IWithListPaginationProps>>;
  queryParams: IWithListQueryParamsProps<T, TSortableCols>;
  setQueryParams: Dispatch<SetStateAction<IWithListQueryParamsProps<T, TSortableCols>>>;
  resetQueryParams: () => void;
}

export function withList<T, TSortableCols>(Component: React.JSXElementConstructor<any>, queryParamsDefault: IWithListQueryParamsProps<T, TSortableCols>) {
  
  return (props: any) => {

    const [s, p, f, ff] = ['s', 'p', 'f', 'ff'].map(k => queryParam(k));
    const location = useLocation();
    const navigate = useNavigate();
    const [hasFilters, setHasFilters] = useState<boolean>(false);
    const [filtersCount, setFiltersCount] = useState<number>(0);
    const [filters, setFilters] = useState<Record<string, any>>(f ? JSON.parse(f) : queryParamsDefault.filters);
    const [forcedFilters, setForcedFilters] = useState<Record<string, any>>(ff ? JSON.parse(ff) : {});
    const [sorter, setSorter] = useState<IWithListProps<T, TSortableCols>['sorter']>(s ? JSON.parse(s) : queryParamsDefault.sorter);
    const [pagination, setPagination] = useState<IWithListProps<T, TSortableCols>['pagination']>(p ? JSON.parse(p) : queryParamsDefault.pagination);
    const [queryParams, setQueryParams] = useState<IWithListQueryParamsProps<T, TSortableCols>>({
      pagination: p ? JSON.parse(p) : queryParamsDefault.pagination,
      sorter: s ? JSON.parse(s) : queryParamsDefault.sorter,
      filters: f ? JSON.parse(f) : queryParamsDefault.filters,
    });
    
    useEffect(() => {
      setFiltersCount(Object.entries(filters).filter(([_, f]) => f !== null).length);
    }, [filters]);
    
    useEffect(() => {

      ([
        ['s', sorter, setSorter, queryParamsDefault.sorter],
        ['p', pagination, setPagination, queryParamsDefault.pagination],
        ['f', filters, setFilters, queryParamsDefault.filters],
        ['ff', forcedFilters, setForcedFilters, {}]
      ] as Array<[string, object, Function, any]>).forEach(([k, getter, setter, def]) => {
        let value = queryParam(k);

        value = value ? JSON.parse(value) : def;

        if (JSON.stringify(value) !== JSON.stringify(getter))
          setter(value);
      });

    }, [location]);
    
    useEffect(() => {
      let s = Object.entries(queryParams)
        .filter(([key, value]) => value && JSON.stringify(value) !== JSON.stringify(queryParamsDefault[key]))
        .map(([key, value]) => `${key.substr(0, 1)}=${JSON.stringify(value)}`)
        .join('&')
      ;

      const ff = queryParam('ff');

      if (ff)
        s += `${s ? '&' : ''}ff=${ff}`;

      if (s)
        s = `?${s}`;

      if (s !== decodeURIComponent(location.search))
        navigate(s || '');

    }, [queryParams]);

    useEffect(() => {
      setHasFilters(filtersCount > 0);
    }, [filtersCount]);
    
    return <Component
      filters={filters}
      forcedFilters={forcedFilters}
      hasFilters={hasFilters}
      setFilters={setFilters}
      filtersCount={filtersCount}
      sorter={sorter}
      setSorter={setSorter}
      pagination={pagination}
      setPagination={setPagination}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      resetQueryParams={() => setQueryParams(queryParamsDefault)}
    />;
  };
}
