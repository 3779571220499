import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Tabs } from 'antd';
import {
  IPreviewQueue, IPreviewConversation, IPreviewContact, ISharedPatchConversation, Nullable
} from 'atlas-shared';
import '../conversation.merge.scss';
import { AlertError, entityToOption } from '@Utils';
import { IQueueStore } from '@Store';
import './conversation.contact.popover.scss';
import { patchConversation } from '@Api';
import { useQueues } from '@Hooks';
import { ConversationContactMergeForm } from './conversation.contact.merge.form';

interface IProps extends PropsWithChildren<any> {
  conversation: IPreviewConversation;
}

export const ConversationContactPopover = React.memo(({ conversation, children }: IProps) => {
  const { t } = useTranslation();
  const queues: IQueueStore = useQueues();
  const [Queue, setQueue] = useState<IPreviewQueue>();
  const [patch, setPatch] = useState<ISharedPatchConversation>({ contact_id: conversation.contact?.id || null });
  const [contact] = useState<IPreviewContact>(conversation.contact as IPreviewContact);
  const [activeKey, setActiveKey] = useState<string>('1');
  const { TabPane } = Tabs;

  useEffect(() => {
    if (!queues.loaded)
      return;

    setQueue(conversation?.queue_id ? queues.queues.find(queue => queue.id === conversation.queue_id) : undefined);
  }, [queues, conversation.queue_id]);

  const setPreventDefault = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const selectContact = (contact_id): void => {
    setPatch({ contact_id });
    setActiveKey('1');
  };

  const canPatch: boolean = !!patch.contact_id && patch.contact_id !== conversation.contact?.id;

  const doneSelection = (e: React.MouseEvent<HTMLElement>): void => {
    if (!canPatch)
      return;

    patchConversation(conversation.organization_id, conversation.id, patch).catch(error => AlertError(t, { content: error.toString() }));
  };

  const handleTabClick = (activeKey: string, e: React.KeyboardEvent | React.MouseEvent): void => {
    setActiveKey(activeKey);
  };

  if (!Queue)
    return <></>;

  return (
    <Popover
      trigger={'click'}
      placement={'bottom'}
      overlayStyle={{ width: activeKey === '2' ? 560 : 280 }}
      content={<div
        className={'contact-selector-wrapper'}
        onClick={setPreventDefault}
      >
        <Tabs onTabClick={handleTabClick} activeKey={activeKey}>
          <TabPane
            tab={
              <div>
                <span>
                  {t('CHANGE_CONTACT')}
                </span>
              </div>
            }
            key="1" />
          <TabPane
            tab={
              <div>
                <span>
                  {t('CREATE_CONTACT')}
                </span>
              </div>
            }
            key="2" />
        </Tabs>
        <div className={'popover-content'}>
          <ConversationContactMergeForm
            organization_id={conversation.organization_id}
            contact_id={patch.contact_id as Nullable<IPreviewContact['id']>}
            create_list_id={Queue.create_list_id}
            options={contact ? [entityToOption(contact, 'contact')] : []}
            onChange={contact_id => selectContact(contact_id)}
            createButton={false}
            fixedDropdown={true}
            createContact={activeKey === '2'}
          />
          {canPatch && <div className={'done-btn'} onClick={doneSelection}>{t('SAVE')}</div>}
        </div>
      </div>}>
      <span style={{ display: 'flex', cursor: 'pointer' }} onClick={setPreventDefault}>{children}</span>
    </Popover>
  );
});
