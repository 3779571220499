import React, { useState } from 'react';
import './customer.journey.list.mini.scss';
import { ISharedCustomerJourney, ISharedConversation, IPreviewContactCustomEvent, ISharedFullConversation } from 'atlas-shared';
import { Timeline } from 'antd';
import { ChannelsIcon } from '@Components';
import { CustomerJourneyListMiniItem } from './customer.journey.list.mini.item';
import { fetchConversationCustomerJourney } from '@Api';
import { AlertError, preventDefault } from '@Utils';
import { TFunction } from 'i18next';

interface IProps {
  t: TFunction;
  conversation: ISharedFullConversation;
  journeys: Array<ISharedCustomerJourney>;
  miniMode?: boolean;
  setSecondaryConversationId?: (conversation_id: ISharedConversation['id']) => void;
  setEventId?: (eventId: IPreviewContactCustomEvent['id'])=> void;
}

export const CustomerJourneyListMini = React.memo(({ t, conversation, journeys, miniMode, setSecondaryConversationId, setEventId }: IProps) => {

  const [moreJourney, setMoreJourney] = useState<Array<ISharedCustomerJourney>>();
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  return (
    <div className={'costumer-journey-mini'}>
      <small className={'title'}>
        {t('CUSTOMER_JOURNEY').toUpperCase()}
      </small>
      <Timeline className={'timeline'}>
        {
          [
            ...journeys,
            ...(moreJourney || [])
          ].map(customer_journey => {
            return (
              <Timeline.Item
                key={customer_journey.id}
                dot={<ChannelsIcon channels={customer_journey.channels || [{ c: 'Unknown' }]} max={3} size={9} padding={3}/>}
              >
                <CustomerJourneyListMiniItem t={t} miniMode={miniMode} customer_journey={customer_journey} setSecondaryConversationId={setSecondaryConversationId} setEventId={setEventId} />
              </Timeline.Item>
            );
          })
        }
      </Timeline>
      {journeys.length === 3 && (!Array.isArray(moreJourney) || (moreJourney.length % 5) === 0) && <div
        className='load-more'
        onClick={preventDefault(() => {
          if (loadingMore)
            return;

          setLoadingMore(true);
          return fetchConversationCustomerJourney(conversation.organization_id, conversation.id, 5, (moreJourney?.length || 0) + 3)
            .then(journeys => setMoreJourney([
              ...(moreJourney || []),
              ...journeys
            ]))
            .catch(e => {
              AlertError(e, { content: e.toString() });
            })
            .finally(() => {
              setLoadingMore(false);
            });
        })}
      >{t('LOAD_MORE')}{loadingMore && '...'}</div>}
    </div>
  );
});

export default React.memo(CustomerJourneyListMini);
