import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreatePause, ISharedUpdatePause, ISharedPause, IPreviewOrganization, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved } from '@Utils';

export interface IPauseFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const PauseFormOptions = (pause: ISharedCreatePause | ISharedPause, props: IPauseFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Pause, {})), 'organization_id', false), 'organization'),
  
});

export const PauseComponents: IForm['components'] = {};
export const PauseAdditionalParams: (auth: IAuth, props: IPauseFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPauseFormOptionsProps) => ({
});

export const PauseEditForm = (pause: ISharedPause, props: IPauseFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `pause_edit_${pause.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === pause.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'global' },
    { ref: 'work_time' },
    { ref: 'invoiceable' },
    { ref: 'description' }
  ]
});

export const PauseAddForm = (pause: ISharedCreatePause, props: IPauseFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'pause_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'global' },
    { ref: 'work_time' },
    { ref: 'invoiceable' },
    { ref: 'description' }
  ]
});

export const PauseAddInitialValues: ISharedCreatePause = {} as ISharedCreatePause;

export const PauseEditInitialValues = (pause: ISharedPause): ISharedUpdatePause => ({
  title: pause.title,
  global: pause.global,
  work_time: pause.work_time,
  invoiceable: pause.invoiceable,
  description: pause.description
});

export const onPauseFormSaved = (t: TFunction, action: 'edit' | 'add', pause: ISharedPause, navigate: NavigateFunction): void => {
  navigate(onFormSaved('pauses'));
};
