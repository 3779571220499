import { StatsWidgetTypeEnum } from 'atlas-shared';
import { WidgetTypeLine } from './widget-type.line';
import { WidgetTypeBar } from './widget-type.bar';
import { WidgetTypePie } from './widget-type.pie';
import { WidgetTypeRadar } from './widget-type.radar';
import { WidgetTypePolar } from './widget-type.polar';
import { WidgetTypeTable } from '../shared/widget-type.table';
import { WidgetTypeGrid } from '../shared/widget-type.grid';
import { WidgetTypeText } from '../shared/widget-type.text';
import { WidgetTypeTimeline } from '../shared/widget-type.timeline';

export const StatsWidgetTypesRechartsMap: Record<StatsWidgetTypeEnum, any> = {
  [StatsWidgetTypeEnum.Table]: WidgetTypeTable,
  //[StatsWidgetTypeEnum.Table_api]: NotImplemented(StatsWidgetTypeEnum.Table_api),
  [StatsWidgetTypeEnum.Grid]: WidgetTypeGrid,
  [StatsWidgetTypeEnum.Bar]: WidgetTypeBar,
  [StatsWidgetTypeEnum.Line]: WidgetTypeLine,
  [StatsWidgetTypeEnum.Text]: WidgetTypeText,
  [StatsWidgetTypeEnum.Pie]: WidgetTypePie,
  [StatsWidgetTypeEnum.Radar]: WidgetTypeRadar,
  [StatsWidgetTypeEnum.Polar]: WidgetTypePolar,
  [StatsWidgetTypeEnum.Timeline]: WidgetTypeTimeline
};
