import React, { Suspense, useEffect, useState } from 'react';
import '../admin.list.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import { IAgentprofileAgent, IPreviewAgentprofile, IPreviewTeam, IPreviewUser, IAdminprofileUser } from 'atlas-shared';
import { Table } from 'antd';
import { Spinner, ListPageEmpty } from '@Components';
import {
  useAgentprofiles,
  useTeams,
  useUsers
} from '@Hooks';
import { fetchAgentprofileAgents } from '@Api';
import { getFullName } from '@Utils';
import { GetColumnSearchEqualProps, GetColumnSearchProps } from '@Utils/list/components';

interface IAgentprofileUserRow {
  agent_id: IPreviewUser['id'];
  agent: string;
  team_id: IPreviewTeam['id'];
  team: IPreviewTeam['title'];
}

const AgentprofileAgentsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const users = useUsers();
  const teams = useTeams();
  const agentprofiles = useAgentprofiles();
  const [agentprofile, setAgentprofile] = useState<IPreviewAgentprofile>();
  const [rows, setRows] = useState<Array<IAgentprofileUserRow>>([]);
  const { id } = useParams<'id'>();
  
  const [hasFilters, setHasFilters] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>({});

  useEffect(() => {
    if (id && agentprofiles.loaded)
      setAgentprofile(agentprofiles.dict[id]);
  }, [agentprofiles]);
  
  useEffect(() => {
    if (teams.loaded && users.loaded)
      fetchAgentprofileAgents(+(id as string)).then(agentprofile_agents => setRows(agentprofile_agents.map(({ agent_id, team_id }) => {
        return {
          agent_id,
          agent: users.dict?.[agent_id] ? getFullName(users.dict?.[agent_id]) : 'AGENT NOT FOUND',
          team_id,
          team: teams.dict?.[team_id]?.title || 'ORGANIZATION NOT FOUND'
        };
      })));
  }, [teams, users]);

  useEffect(() => {
    setHasFilters(Object.keys(filters).length > 0);
  }, [filters]);

  const columns: ColumnsType<IAgentprofileUserRow> = React.useMemo(() => {
    return [
      {
        title: t('AGENT_ID'),
        dataIndex: 'agent_id',
        key: 'agent_id',
        render: (agent_id: IAdminprofileUser['user_id'], row: IAgentprofileUserRow) => <a onClick={() => navigate(`/admin/user/${agent_id}/edit`)}>{row.agent_id}</a>,
        sorter: (a: IAgentprofileUserRow, b: IAgentprofileUserRow) => a.agent_id - b.agent_id,
        sortDirections: ['descend', 'ascend'],
        filteredValue: filters.agent_id || null,
        ...GetColumnSearchEqualProps(t, 'agent_id', t('ID'))
      },
      {
        title: t('AGENT'),
        dataIndex: 'agent_id',
        key: 'agent_id',
        render: (agent_id: IAgentprofileAgent['agent_id'], row: IAgentprofileUserRow) => <a onClick={() => navigate(`/admin/user/${agent_id}/edit`)}>{row.agent}</a>,
        sorter: (a: IAgentprofileUserRow, b: IAgentprofileUserRow) => a.agent.localeCompare(b.agent),
        sortDirections: ['descend', 'ascend'],
        //...GetColumnSearchProps(t, 'user_id', t('AGENT'), entitiesToOptions(users.users, 'user'))
      },
      {
        title: t('TEAM'),
        dataIndex: 'team_id',
        key: 'team_id',
        render: (team_id: IAgentprofileAgent['team_id'], row: IAgentprofileUserRow) => <a onClick={() => navigate(`/admin/team/${team_id}/edit`)}>{row.team}</a>,
        sorter: (a: IAgentprofileUserRow, b: IAgentprofileUserRow) => a.team.localeCompare(b.team),
        sortDirections: ['descend', 'ascend'],
        filteredValue: filters.team_id || null,
        ...GetColumnSearchProps(t, 'team_id', t('TEAM'), teams.teams)
      }
    ];
  }, [t, navigate, teams.loaded, filters, users.loaded]);
  
  if (!agentprofile || !teams.loaded || !users.loaded)
    return <></>;

  return (
    <Suspense fallback={<Spinner />}>
      <div className='admin-list-view'>
        <div className='admin-list-page-header'>
          <h1>
            {t('AGENTPROFILE_AGENTS')}: <span>{agentprofile.title}</span>
          </h1>
        </div>
        <div className='table-wrp'>
          <Table
            columns={columns}
            dataSource={rows}
            onChange={(pagination, filters, sorter) => {
              setFilters(filters || {});
            }}
            locale={{
              emptyText: <ListPageEmpty title={'NO_AGENTS_WERE_FOUND'} t={t} hasFilters={hasFilters} hasRows={!!rows.length} reset={() => setFilters({})} />
            }}
          />
        </div>
      </div>
    </Suspense>
  );
};

export const AgentprofileAgentsListView = React.memo(AgentprofileAgentsList);
