import { TimeIntervalActions } from '@Store';
import { IPreviewTimeInterval } from 'atlas-shared';
import { fetchTimeIntervals } from '@Api';

export const actionFetchTimeIntervals = () => {
  return async (dispatch) => {
    dispatch(actionLoadingTimeIntervals());
    const time_intervals = await fetchTimeIntervals();

    dispatch(actionSetTimeIntervals(time_intervals));
    return time_intervals;
  };
};

export const actionLoadingTimeIntervals = () => ({
  type: TimeIntervalActions.LOADING,
  payload: []
});

export const actionSetTimeIntervals = (payload: Array<IPreviewTimeInterval>) => ({
  type: TimeIntervalActions.SET_STATE,
  payload: payload
});

export const actionAddTimeInterval = (payload: IPreviewTimeInterval) => ({
  type: TimeIntervalActions.ADD_STATE,
  payload: payload
});

export const actionUpdateTimeInterval = (payload: IPreviewTimeInterval) => ({
  type: TimeIntervalActions.UPDATE_STATE,
  payload: payload
});
