import { IPreviewVoiceVoicemailMessage } from 'atlas-shared';

export enum VoiceVoicemailMessageActions {
  SET_STATE = 'VOICE_VOICEMAIL_MESSAGE_SET_STATE',
  ADD_STATE = 'VOICE_VOICEMAIL_MESSAGE_ADD_STATE',
  REMOVE_STATE = 'VOICE_VOICEMAIL_MESSAGE_REMOVE_STATE',
  GET_STATE = 'VOICE_VOICEMAIL_MESSAGE_GET_STATE',
  UPDATE_STATE = 'VOICE_VOICEMAIL_MESSAGE_UPDATE_STATE',
  LOADING = 'VOICE_VOICEMAIL_MESSAGE_LOADING',
}

export interface IVoiceVoicemailMessageLoading {
  type: VoiceVoicemailMessageActions.LOADING;
}

export interface IVoiceVoicemailMessageSetAction {
  type: VoiceVoicemailMessageActions.SET_STATE;
  payload: Array<IPreviewVoiceVoicemailMessage>;
}

export interface IVoiceVoicemailMessageUpdateAction {
  type: VoiceVoicemailMessageActions.UPDATE_STATE;
  payload: IPreviewVoiceVoicemailMessage;
}

export interface IVoiceVoicemailMessageAddAction {
  type: VoiceVoicemailMessageActions.ADD_STATE;
  payload: IPreviewVoiceVoicemailMessage;
}

export interface IVoiceVoicemailMessageRemoveAction {
  type: VoiceVoicemailMessageActions.REMOVE_STATE;
  payload: IPreviewVoiceVoicemailMessage['id'];
}

export interface IVoiceVoicemailMessageStore {
  loaded: boolean;
  loading: boolean;
  voice_voicemail_messages: Array<IPreviewVoiceVoicemailMessage>;
  dict: Record<IPreviewVoiceVoicemailMessage['id'], IPreviewVoiceVoicemailMessage>;
}

export type TVoiceVoicemailMessageActionTypes = IVoiceVoicemailMessageSetAction | IVoiceVoicemailMessageUpdateAction | IVoiceVoicemailMessageAddAction | IVoiceVoicemailMessageRemoveAction | IVoiceVoicemailMessageLoading;
