import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateAdminprofile, ISharedUpdateAdminprofile, ISharedAdminprofile, IPreviewOrganization, IPreviewPause, IPreviewUser, IPreviewAdminprofile, IPreviewAgentprofile, IPreviewCannedAnswer, IPreviewDisposition, IPreviewForm, IPreviewField, IPreviewJourney, IPreviewList, IPreviewMailAccount, IPreviewQueue, IPreviewOperationalHours, IPreviewSignature, IPreviewSlaPolicy, IPreviewTag, IPreviewTeam, IPreviewTimeInterval, IPreviewVoiceShortcut, IPreviewVoiceVoicemail, IPreviewDestinationRestrictor, IPreviewInvoice, IPreviewClient, IPreviewSkill, EnumOptions, AdminprofilePermissionIdEnum } from 'atlas-shared';
import { TFunction } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';

export interface IAdminprofileFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  pauses: Array<IPreviewPause>;
  users: Array<IPreviewUser>;
  adminprofiles: Array<IPreviewAdminprofile>;
  agentprofiles: Array<IPreviewAgentprofile>;
  canned_answers: Array<IPreviewCannedAnswer>;
  dispositions: Array<IPreviewDisposition>;
  forms: Array<IPreviewForm>;
  fields: Array<IPreviewField>;
  journeies: Array<IPreviewJourney>;
  lists: Array<IPreviewList>;
  mail_accounts: Array<IPreviewMailAccount>;
  queues: Array<IPreviewQueue>;
  operational_hourses: Array<IPreviewOperationalHours>;
  signatures: Array<IPreviewSignature>;
  sla_policies: Array<IPreviewSlaPolicy>;
  tags: Array<IPreviewTag>;
  teams: Array<IPreviewTeam>;
  time_intervals: Array<IPreviewTimeInterval>;
  voice_shortcuts: Array<IPreviewVoiceShortcut>;
  voice_voicemails: Array<IPreviewVoiceVoicemail>;
  destination_restrictors: Array<IPreviewDestinationRestrictor>;
  invoices: Array<IPreviewInvoice>;
  clients: Array<IPreviewClient>;
  skills: Array<IPreviewSkill>;
}

export const AdminprofileFormOptions = (adminprofile: ISharedCreateAdminprofile | ISharedAdminprofile, props: IAdminprofileFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Adminprofile, {})), 'organization_id', false), 'organization'),
  pause: (it: ISharedAdminprofile) => filterRelated<IPreviewPause>(it.organization_id || adminprofile.organization_id, 'pause', 'adminprofile', props.pauses, true, it => !it.global),
  user: (it: ISharedAdminprofile) => filterRelated<IPreviewUser>(it.organization_id || adminprofile.organization_id, 'user', 'adminprofile', props.users, true),
  adminprofile: (it: ISharedAdminprofile) => filterRelated<IPreviewAdminprofile>(it.organization_id || adminprofile.organization_id, 'adminprofile', 'adminprofile', props.adminprofiles, true, it => it.id !== (adminprofile as ISharedAdminprofile)?.id),
  agentprofile: (it: ISharedAdminprofile) => filterRelated<IPreviewAgentprofile>(it.organization_id || adminprofile.organization_id, 'agentprofile', 'adminprofile', props.agentprofiles, true),
  canned_answer: (it: ISharedAdminprofile) => filterRelated<IPreviewCannedAnswer>(it.organization_id || adminprofile.organization_id, 'canned_answer', 'adminprofile', props.canned_answers, true, it => !it.global),
  disposition: (it: ISharedAdminprofile) => filterRelated<IPreviewDisposition>(it.organization_id || adminprofile.organization_id, 'disposition', 'adminprofile', props.dispositions, true, it => !it.global),
  form: (it: ISharedAdminprofile) => filterRelated<IPreviewForm>(it.organization_id || adminprofile.organization_id, 'form', 'adminprofile', props.forms, true),
  field: (it: ISharedAdminprofile) => filterRelated<IPreviewField>(it.organization_id || adminprofile.organization_id, 'field', 'adminprofile', props.fields, true),
  journey: (it: ISharedAdminprofile) => filterRelated<IPreviewJourney>(it.organization_id || adminprofile.organization_id, 'journey', 'adminprofile', props.journeies, true),
  list: (it: ISharedAdminprofile) => filterRelated<IPreviewList>(it.organization_id || adminprofile.organization_id, 'list', 'adminprofile', props.lists, true),
  mail_account: (it: ISharedAdminprofile) => filterRelated<IPreviewMailAccount>(it.organization_id || adminprofile.organization_id, 'mail_account', 'adminprofile', props.mail_accounts, true),
  queue: (it: ISharedAdminprofile) => filterRelated<IPreviewQueue>(it.organization_id || adminprofile.organization_id, 'queue', 'adminprofile', props.queues, true),
  operational_hours: (it: ISharedAdminprofile) => filterRelated<IPreviewOperationalHours>(it.organization_id || adminprofile.organization_id, 'operational_hours', 'adminprofile', props.operational_hourses, true),
  signature: (it: ISharedAdminprofile) => filterRelated<IPreviewSignature>(it.organization_id || adminprofile.organization_id, 'signature', 'adminprofile', props.signatures, true, it => !it.global),
  sla_policy: (it: ISharedAdminprofile) => filterRelated<IPreviewSlaPolicy>(it.organization_id || adminprofile.organization_id, 'sla_policy', 'adminprofile', props.sla_policies, true),
  tag: (it: ISharedAdminprofile) => filterRelated<IPreviewTag>(it.organization_id || adminprofile.organization_id, 'tag', 'adminprofile', props.tags, true, it => !it.global),
  team: (it: ISharedAdminprofile) => filterRelated<IPreviewTeam>(it.organization_id || adminprofile.organization_id, 'team', 'adminprofile', props.teams, true),
  time_interval: (it: ISharedAdminprofile) => filterRelated<IPreviewTimeInterval>(it.organization_id || adminprofile.organization_id, 'time_interval', 'adminprofile', props.time_intervals, true),
  voice_shortcut: (it: ISharedAdminprofile) => filterRelated<IPreviewVoiceShortcut>(it.organization_id || adminprofile.organization_id, 'voice_shortcut', 'adminprofile', props.voice_shortcuts, true),
  voice_voicemail: (it: ISharedAdminprofile) => filterRelated<IPreviewVoiceVoicemail>(it.organization_id || adminprofile.organization_id, 'voice_voicemail', 'adminprofile', props.voice_voicemails, true),
  destination_restrictor: (it: ISharedAdminprofile) => filterRelated<IPreviewDestinationRestrictor>(it.organization_id || adminprofile.organization_id, 'destination_restrictor', 'adminprofile', props.destination_restrictors, true),
  invoice: (it: ISharedAdminprofile) => filterRelated<IPreviewInvoice>(it.organization_id || adminprofile.organization_id, 'invoice', 'adminprofile', props.invoices, true),
  client: (it: ISharedAdminprofile) => filterRelated<IPreviewClient>(it.organization_id || adminprofile.organization_id, 'client', 'adminprofile', props.clients, true),
  skill: (it: ISharedAdminprofile) => filterRelated<IPreviewSkill>(it.organization_id || adminprofile.organization_id, 'skill', 'adminprofile', props.skills, true),
  permissions_id: EnumOptions('ADMINPROFILE_PERMISSION_ID', AdminprofilePermissionIdEnum, t),
});

export const AdminprofileComponents: IForm['components'] = {};
export const AdminprofileAdditionalParams: (auth: IAuth, props: IAdminprofileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IAdminprofileFormOptionsProps) => ({
});

export const AdminprofileEditForm = (adminprofile: ISharedAdminprofile, props: IAdminprofileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `adminprofile_edit_${adminprofile.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === adminprofile.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'permissions' }
  ]
});

export const AdminprofileAddForm = (adminprofile: ISharedCreateAdminprofile, props: IAdminprofileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'adminprofile_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'permissions' }
  ]
});

export const AdminprofileAddInitialValues: ISharedCreateAdminprofile = {} as ISharedCreateAdminprofile;

export const AdminprofileEditInitialValues = (adminprofile: ISharedAdminprofile): ISharedUpdateAdminprofile => ({
  title: adminprofile.title,
  permissions: adminprofile.permissions
});

export const onAdminprofileFormSaved = (t: TFunction, action: 'edit' | 'add', adminprofile: ISharedAdminprofile, navigate: NavigateFunction): void => {
  navigate(onFormSaved('adminprofiles'));
};
