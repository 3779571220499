import { IPreviewPrepaidCredit } from 'atlas-shared';

export enum PrepaidCreditActions {
  SET_STATE = 'PREPAID_CREDIT_SET_STATE',
  ADD_STATE = 'PREPAID_CREDIT_ADD_STATE',
  REMOVE_STATE = 'PREPAID_CREDIT_REMOVE_STATE',
  GET_STATE = 'PREPAID_CREDIT_GET_STATE',
  UPDATE_STATE = 'PREPAID_CREDIT_UPDATE_STATE',
  LOADING = 'PREPAID_CREDIT_LOADING',
}

export interface IPrepaidCreditLoading {
  type: PrepaidCreditActions.LOADING;
}

export interface IPrepaidCreditSetAction {
  type: PrepaidCreditActions.SET_STATE;
  payload: Array<IPreviewPrepaidCredit>;
}

export interface IPrepaidCreditUpdateAction {
  type: PrepaidCreditActions.UPDATE_STATE;
  payload: IPreviewPrepaidCredit;
}

export interface IPrepaidCreditAddAction {
  type: PrepaidCreditActions.ADD_STATE;
  payload: IPreviewPrepaidCredit;
}

export interface IPrepaidCreditRemoveAction {
  type: PrepaidCreditActions.REMOVE_STATE;
  payload: IPreviewPrepaidCredit['id'];
}

export interface IPrepaidCreditStore {
  loaded: boolean;
  loading: boolean;
  prepaid_credits: Array<IPreviewPrepaidCredit>;
  dict: Record<IPreviewPrepaidCredit['id'], IPreviewPrepaidCredit>;
}

export type TPrepaidCreditActionTypes = IPrepaidCreditSetAction | IPrepaidCreditUpdateAction | IPrepaidCreditAddAction | IPrepaidCreditRemoveAction | IPrepaidCreditLoading;
