import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  PauseAddForm,
  PauseAddInitialValues,
  PauseComponents,
  PauseAdditionalParams,
  PauseFormOptions,
  onPauseFormSaved,
  IPauseFormOptionsProps,
  AlertError,
  queryParam
} from '@Utils';
import { AtlasForm, IFormOptions } from 'atlas-form';
import { CrudEnum, ISharedCreatePause, PermissionEnum } from 'atlas-shared';
import { createPause, fetchPause, getPauseCreateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withAdd, IWithAddProps } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithAddProps<ISharedCreatePause> {}

const PauseFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned] = useState<Partial<ISharedCreatePause>>();

  const param_props: IPauseFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations
  };
  const formLayout = useMemo(() => PauseAddForm(values, param_props, t), [values, organizations, t, abilities]);
  const options: IFormOptions = useMemo(() => PauseFormOptions(values || PauseAddInitialValues, param_props, t), [values, organizations, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreatePause, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues]);
  const onFinish = useCallback((values: ISharedCreatePause) => save(() => createPause(values).then(
    res => onSaved ? onSaved(res, navigate) : onPauseFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [save, navigate, t]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchPause(+id)
        .then(({
          organization_id,
          title,
          global,
          work_time,
          invoiceable,
          description
        }) => {
          setCloned({
            organization_id,
            title,
            global,
            work_time,
            invoiceable,
            description
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Pause, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={PauseComponents}
        additionalParams={PauseAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...PauseAddInitialValues, ...initialValues }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('PAUSE')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const PauseFormAddView = React.memo(withAdd(PauseFormAdd, getPauseCreateSchema, PauseAddInitialValues));
