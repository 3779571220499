import {
  IStatsWidgetAddAction,
  IStatsWidgetLoading,
  IStatsWidgetRemoveAction,
  IStatsWidgetSetAction,
  IStatsWidgetUpdateAction
} from './abstract/stats_widget.types.abstract';
import { IStatsWidgetDataPayload } from 'atlas-shared/dist';

export * from './abstract/stats_widget.types.abstract';

export enum StatsWidgetActions {
  SET_STATE = 'STATS_WIDGET_SET_STATE',
  ADD_STATE = 'STATS_WIDGET_ADD_STATE',
  REMOVE_STATE = 'STATS_WIDGET_REMOVE_STATE',
  GET_STATE = 'STATS_WIDGET_GET_STATE',
  UPDATE_STATE = 'STATS_WIDGET_UPDATE_STATE',
  LOADING = 'STATS_WIDGET_LOADING',
  SET_DATA = 'STATS_WIDGET_SET_DATA',
}

export interface IStatsWidgetSetDataAction {
  type: StatsWidgetActions.SET_DATA;
  payload: IStatsWidgetDataPayload;
}

export type TStatsWidgetActionTypes = IStatsWidgetSetAction | IStatsWidgetUpdateAction | IStatsWidgetAddAction | IStatsWidgetRemoveAction | IStatsWidgetLoading | IStatsWidgetSetDataAction;
