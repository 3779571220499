import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchTags, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useTags = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const tags = useSelector((store: IReducerStore) => store.tags);

  useEffect(() => {
    if (!initiated && autoload && !tags.loaded && !tags.loading) {
      initiated = true;
      dispatch(actionFetchTags());
    }
  }, [autoload, tags.loaded, tags.loading, dispatch]);

  return tags;
};
