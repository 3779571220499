import { RestRequest } from '@Utils';
import { IPreviewTimeInterval, ISharedTimeInterval, ISharedCreateTimeInterval, ISharedPatchTimeInterval } from 'atlas-shared';

export const fetchTimeIntervals = async (): Promise<Array<IPreviewTimeInterval>> => {
  return RestRequest.get<Array<IPreviewTimeInterval>>('time_interval', { socket_id: global.socketId });
};

export const fetchTimeInterval = async (id: IPreviewTimeInterval['id']): Promise<ISharedTimeInterval> => {
  return RestRequest.get<ISharedTimeInterval>(`time_interval/${id}`, { socket_id: global.socketId });
};

export const createTimeInterval = (payload: ISharedCreateTimeInterval) => {
  return RestRequest.post<any, ISharedTimeInterval>('time_interval', payload, { socket_id: global.socketId });
};

export const patchTimeInterval = (id: IPreviewTimeInterval['id'], payload: ISharedPatchTimeInterval) => {
  return RestRequest.patch<any, ISharedTimeInterval>(`time_interval/${id}`, payload, { socket_id: global.socketId });
};

export const deleteTimeInterval = (id: IPreviewTimeInterval['id']) => {
  return RestRequest.delete(`time_interval/${id}`, { socket_id: global.socketId });
};

export const getTimeIntervalUpdateSchema = (): Promise<any> => {
  return RestRequest.get<any>('time_interval/update/schema');
};

export const getTimeIntervalCreateSchema = (): Promise<any> => {
  return RestRequest.get<any>('time_interval/create/schema');
};

export const getTimeIntervalPatchSchema = (): Promise<any> => {
  return RestRequest.get<any>('time_interval/patch/schema');
};
