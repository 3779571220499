import React from 'react';
import { IPreviewConversation } from 'atlas-shared';

interface IProps {
  conversation: IPreviewConversation;
  shortened: boolean;
}

export const ConversationId = React.memo(({ conversation }: IProps) => {
  return <div className={'id-wrapper'}>
    <small className={'id'}>
      {`#${conversation.id}`}
    </small>
  </div>;
});
