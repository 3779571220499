import { IPreviewVoiceInternalCall } from 'atlas-shared';
import { IVoiceInternalCallStore, VoiceInternalCallActions, TVoiceInternalCallActionTypes } from '@Store';

export const VoiceInternalCallReducer = (state: IVoiceInternalCallStore = {
  loaded: false,
  loading: false,
  voice_internal_calls: [],
  dict: {}
}, action: TVoiceInternalCallActionTypes): IVoiceInternalCallStore => {
  switch (action.type) {
  case VoiceInternalCallActions.LOADING:
    return { ...state, loading: true };
  case VoiceInternalCallActions.SET_STATE:
    const dict: IVoiceInternalCallStore['dict'] = {};

    action.payload.forEach((voice_internal_call: IPreviewVoiceInternalCall) => dict[voice_internal_call.id] = voice_internal_call);

    return { ...state, loaded: true, loading: false, voice_internal_calls: action.payload, dict };
  case VoiceInternalCallActions.ADD_STATE:
    return { ...state, voice_internal_calls: [...state.voice_internal_calls, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case VoiceInternalCallActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, voice_internal_calls: state.voice_internal_calls.filter((item: IPreviewVoiceInternalCall) => item.id !== action.payload), dict };
  }
  case VoiceInternalCallActions.UPDATE_STATE:
    const voice_internal_calls = [...state.voice_internal_calls];
    const index = state.voice_internal_calls.findIndex((voice_internal_call: IPreviewVoiceInternalCall) => voice_internal_call.id === action.payload.id);

    if (!~index)
      voice_internal_calls.push(action.payload);
    else
      voice_internal_calls[index] = action.payload;

    return { ...state, voice_internal_calls, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
