import React, { useEffect, useState } from 'react';
import { InboxIcon } from '@Assets/icons';
import { useTranslation } from 'react-i18next';
import { formatDate, getFullName, ellipsis } from '@Utils';
import { Timeline } from 'antd';
import {
  Undefinable,
  ISharedCustomerJourney,
  IPreviewUser,
  Nullable,
  IPreviewQueue,
  ISharedConversation,
  IPreviewContactCustomEvent
} from 'atlas-shared';
import { useQueues, useUsers } from '@Hooks';
import { Avatar, IconText, ChannelsIcon } from '@Components';

interface IProps {
  journey: ISharedCustomerJourney;
  setSecondaryConversationId: (conversation_id: ISharedConversation['id']) => void;
  setEventId: (eventId: IPreviewContactCustomEvent['id'])=> void;
}

export const CustomerJourneyListItem = React.memo(({ journey, setSecondaryConversationId, setEventId }: IProps) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(undefined as Undefinable<IPreviewUser>);
  const [queue, setQueue] = useState<Nullable<IPreviewQueue>>(null);
  const users = useUsers();
  const queues = useQueues();

  useEffect(() => {
    setUser(journey.user_id ? users.users.find((agent: IPreviewUser) => agent.id === journey.user_id ) : undefined);
    setQueue(journey.queue_id ? queues.queues.find(queue => queue.id === journey.queue_id) || null : null);
  }, [users, queues, journey]);

  const title = journey.title || '';

  return (
    <Timeline.Item key={1}
      className={'customer-journey-item'}
      dot={<ChannelsIcon
        channels={journey.channels || []}
        size={journey.channels?.length || 0 > 1 ? 8 : 14}
        padding={0}
      />}>
      <div className={'content'} >
        <div className={'date'} >
          {formatDate(journey.action_at, 'MMM DD, YYYY HH:mm')}
        </div>
        <div className={'body'} >
          <div className={'row'} >
            <span className={'id-text'}>
              {`#${journey.display_id || journey.id}`}
            </span>
            {journey.status && <span className={'status-text'}>
              {t(`STATUS_${journey.status}`)}
            </span>}
          </div>
          <div className={'row'} >
            <span className={'title'}>
              {ellipsis(title, 100)}
            </span>
          </div>
          {
            journey.disposition_note &&
            <div className={'row'} >
              <div className={'disposition'} >
                <div className={'text'} >
                  {journey.disposition_note}
                </div>
                <div className={'date'} >
                  {journey.action_at && `${formatDate(journey.action_at, 'MMM DD, YYYY')} ${user ? t('BY') + ' ' + getFullName(user) : ''}`}
                </div>
              </div>
            </div>
          }
          <div className={'row'} >
            {user && <Avatar user={user} size={12}/>}
            {queue && <div className={'queue-title-wrp'}>
              <IconText icon={InboxIcon} iconProps={{ size: 12 }} text={queue.title}/>
            </div>}
            <div className={'view-btn'} onClick={() => journey.type === 'conversation' ? setSecondaryConversationId(journey.id) : setEventId(journey.id)}>
              {`${t(`VIEW_${journey.type.toUpperCase()}`)}`}
            </div>
          </div>
        </div>
      </div>
    </Timeline.Item>
  );
});
