import { ListActions } from '@Store';
import { IPreviewList } from 'atlas-shared';
import { fetchLists } from '@Api';

export const actionFetchLists = () => {
  return async (dispatch) => {
    dispatch(actionLoadingLists());
    const lists = await fetchLists();

    dispatch(actionSetLists(lists));
    return lists;
  };
};

export const actionLoadingLists = () => ({
  type: ListActions.LOADING,
  payload: []
});

export const actionSetLists = (payload: Array<IPreviewList>) => ({
  type: ListActions.SET_STATE,
  payload: payload
});

export const actionAddList = (payload: IPreviewList) => ({
  type: ListActions.ADD_STATE,
  payload: payload
});

export const actionUpdateList = (payload: IPreviewList) => ({
  type: ListActions.UPDATE_STATE,
  payload: payload
});
