import React from 'react';
import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';
import { IChannelIconCircleProps } from '@Assets/icons/channels';

export const EmergencyIcon = (props: ISvgIconProps) => {
  return <SvgIcon
    stroke={2}
    strokeColor={'var(--red-main)'}
    {...props}
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </SvgIcon>;
};

export const EmergencyIconCircled = ({ size, padding, className, t }: IChannelIconCircleProps) => <EmergencyIcon
  stroke={2}
  strokeColor={'var(--red-main)'}
  className={`${className ? `${className} ` : ''}circle-icon`}
  size={size || 14}
  circle={{ style: { background: 'var(--red-light)', padding: padding || 2 } }}
  title={t('EMERGENCY')}
/>;
