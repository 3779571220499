import { FormElementTypes, IForm, IFormOptions } from 'atlas-form';
import {
  IUserFormOptionsProps,
  UserEditInitialValues as UserEditInitialValuesBase,
  UserFormOptions as UserFormOptionsBase,
  UserAdditionalParams as UserAdditionalParamsBase
} from './abstract/user.form.abstract';
import { TFunction } from 'i18next';

import {
  ChannelEnum,
  CrudEnum, EnumValues,
  ISharedCreateUser,
  ISharedUpdateUser,
  ISharedUser,
  ISharedUserOrganizationProperty,
  PermissionEnum,
  RoleEnum,
  IPreviewSkill
} from 'atlas-shared';
import { clone, nestedArray, entitiesToOptions } from '@Utils';
import { IAuth } from '@Store';

export * from './abstract/user.form.abstract';

export const UserEditInitialValues = (user: ISharedUser): ISharedUpdateUser => ({
  ...clone(UserEditInitialValuesBase(user)),
  __organizations: user.__organizations.map(uo => ({
    organization_id: uo.organization_id,
    adminprofile_id: uo.adminprofile_id,
    superadmin_profile_id: uo.superadmin_profile_id,
    role: uo.role,
  }))
});

export const UserFormOptions = (user: ISharedCreateUser | ISharedUser, props: IUserFormOptionsProps, t: TFunction): IFormOptions => {
  const skill_options = (_) => {
    const organizations = [
      ...((user as ISharedUser).__agent_organization_ids || []),
      ...(_?.__organizations || []).map(({ organization_id }) => organization_id)
    ];

    return entitiesToOptions<IPreviewSkill>(props.skills, 'skill', opt => organizations.includes(opt.organization_id));
  };

  return {
    ...UserFormOptionsBase(user, props, t),
    __organizations_organization_id: (_) => entitiesToOptions(nestedArray<any>(props.organizations.filter(organization => props.abilities.canImplicit(organization.id, CrudEnum.Insert, PermissionEnum.User)), 'organization_id', false)),
    __organizations_role: (_, organization_level: ISharedUserOrganizationProperty) => {
      const options = [
        { key: RoleEnum.Agent, title: t('AGENT') },
        { key: RoleEnum.Admin, title: t('ADMIN') },
        { key: RoleEnum.Admin + RoleEnum.Agent, title: t('ADMIN_AGENT') },
      ];

      if (organization_level.organization_id === 1)
        options.push({ key: RoleEnum.Superadmin, title: t('SUPERADMIN') });

      return options;
    },
    __organizations_adminprofile_id: (_, organization_level: ISharedUserOrganizationProperty) => {
      return organization_level.organization_id ? props.adminprofiles.filter(it => !it.deleted_at && it.organization_id === organization_level.organization_id).map(it => ({ key: it.id, title: it.title })) : [];
    },
    skills_level_one: skill_options,
    skills_level_two: skill_options,
    skills_level_three: skill_options,
  };
};

export const UserAdditionalParams: (auth: IAuth, props: IUserFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IUserFormOptionsProps) => ({
  ...UserAdditionalParamsBase(auth, props),
  password: { nullable: false },
  __organizations_organization_id: { ui_type: FormElementTypes.TREE },
  two_factor_auth: {
    help: 'TWO_FACTOR_AUTH_HELP',
    postFixText: 'TWO_FACTOR_AUTH_ENABLE_HELP'
  },
  voice_settings_chanspy: {
    hideHelp: true
  },
  voice_settings_chanspied_on: {
    hideHelp: true
  },
  settings_stats_reports: {
    hidden: true
  },
  settings_auto_accept: {
    labelWidth: '120px',
    help: 'AUTO_ACCEPT_HELP'
  },
  settings_auto_accept_V: {
    label: 'VOICE'
  },
  settings_auto_accept_M: {
    label: 'MAIL'
  },
  settings_auto_accept_F: {
    label: 'FORM'
  },
  settings_hide_stats_preview: {
    labelWidth: '120px',
  }
});

EnumValues(ChannelEnum).forEach(channel => {
  UserAdditionalParams[`settings_auto_accept_${channel}`] = {
    label: `CHANNEL_${channel}`,
    labelWidth: '50px'
  };
  UserAdditionalParams[`settings_auto_accept_${channel}_delay`] = {
    postFixText: 'SECONDS'
  };
  UserAdditionalParams[`settings_auto_accept_${channel}_queues`] = {
    postFixTextNull: 'ENABLE_TO_SELECT_SPECIFIC_QUEUES'
  };
});

export const UserAddInitialValues: ISharedCreateUser = {
  mail_capacity: 5
} as ISharedCreateUser;
