import React, { useCallback, useMemo } from 'react';
import { IPreviewMailMessage, ISharedMailMessageAttachmentProperty } from 'atlas-shared';
import { AttachmentIcon, DownloadIcon, ImageIcon } from '@Assets/icons';
import { Tag } from 'antd';
import { TFunction } from 'i18next';
import { Icon, IconText } from '@Components';
import { actionDocsPreview } from '@Store';
import { fetchDownloadMailMessageAttachment } from '@Api';
import { AlertError, preventDefault } from '@Utils';

interface IProps {
  dispatch: any;
  organization_id: IPreviewMailMessage['organization_id'];
  mail_message_id: IPreviewMailMessage['id'];
  attachment: ISharedMailMessageAttachmentProperty;
  attachments: Array<ISharedMailMessageAttachmentProperty>;
  index: number;
  t: TFunction;
  stringRendered?: boolean;
}

export const ConversationMessageMailAttachment = React.memo(({ dispatch, organization_id, mail_message_id, attachment, attachments, index, t, stringRendered = false }: IProps) => {

  const error = useMemo(() => t('FILE_NOT_FOUND', {
    replace: {
      filename: attachment.filename
    }
  }), [attachment]);

  const onClick = useCallback(() => dispatch(actionDocsPreview({
    index,
    docs: attachments.map(attachment => ({ uri: `mail_message/p/${organization_id}/${mail_message_id}/attachment/${attachment.unique_filename || attachment.filename}`, filename: attachment.filename }))
  })), [attachments, index]);

  const onDownload = () => {
    return fetchDownloadMailMessageAttachment(organization_id, mail_message_id, attachment.unique_filename || attachment.filename, attachment.filename, () => {
      AlertError(t, {
        content: error,
        title: t('NOT_FOUND')
      });
    });
  };

  const icon = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(attachment.contentType) ? ImageIcon : AttachmentIcon;

  const url = `javascript:fetchDownloadAttachment(${organization_id}, ${mail_message_id}, '${attachment.unique_filename || attachment.filename}', '${attachment.filename}', e => alert('${error}'))`;

  if (stringRendered)
    return (
      <a href={url}>
        <Tag className={'clickable'}>
          <IconText icon={icon} text={attachment.filename || attachment.cid} />
        </Tag>
      </a>
    );

  return <Tag className={'clickable attachment'} onClick={onClick}>
    <IconText icon={icon} text={attachment.filename || attachment.cid} />
    <Icon icon={DownloadIcon} className='download' onClick={preventDefault(onDownload)} />
  </Tag>;
});
