import { ISharedConversation, TabSizeEnum, ISharedFullConversation } from 'atlas-shared';
import { createTab, patchTab } from '@Api';
import { actionSetFocusedConversation, actionSetHandlingConversation, ITabStore } from '@Store';

export * from './abstract/tab.actions.abstract';

export const actionRequestTab = (conversation: ISharedFullConversation, organization_id: ISharedConversation['organization_id'], size: TabSizeEnum, tabs: ITabStore, resolve: (r: boolean) => void) => {
  return (dispatch, getState) => {
    const agentprofile_id = getState().auth.user_status.agentprofile_id;
    const userId = getState().auth.user.id;
    const tab = tabs.tabs.find(tab => tab.conversation_id === conversation.id && tab.agentprofile_id === agentprofile_id);
    const successResolve = () => {
      dispatch(actionSetFocusedConversation(conversation.id));
      dispatch(actionSetHandlingConversation(conversation.id));
      return resolve(true);
    };

    if (tab) {
      if (tab.size !== size)
        patchTab(tab.id, { size }).finally(() => successResolve);
      else
        successResolve();
    }
    else if (conversation.id && organization_id) {
      createTab({
        organization_id,
        conversation_id: conversation.id,
        main_channel: conversation.main_channel,
        order: Math.max(...tabs.tabs.map(tab => tab.agentprofile_id === agentprofile_id ? tab.order : -1), -1) + 1,
        size,
        user_id: userId,
        agentprofile_id
      })
        .finally(successResolve);
    }
    else 
      resolve(true);
  };
};
