import { journeyAction } from './base';

import { JourneyActionCloseConversation as JourneyActionCloseConversationBase } from 'atlas-shared';
import { FormElementTypes } from 'atlas-form';

export const JourneyActionCloseConversation = journeyAction({
  ...JourneyActionCloseConversationBase,
  additionalParams: {
    properties_disposition: { ui_type: FormElementTypes.TREE },
    properties_disposition_note: { ui_type: FormElementTypes.TEXT },
  },
  icon: 'Close'
});
