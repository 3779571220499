import { IPreviewConversationFilter } from 'atlas-shared';

export enum ConversationFilterActions {
  SET_STATE = 'CONVERSATION_FILTER_SET_STATE',
  ADD_STATE = 'CONVERSATION_FILTER_ADD_STATE',
  REMOVE_STATE = 'CONVERSATION_FILTER_REMOVE_STATE',
  GET_STATE = 'CONVERSATION_FILTER_GET_STATE',
  UPDATE_STATE = 'CONVERSATION_FILTER_UPDATE_STATE',
  LOADING = 'CONVERSATION_FILTER_LOADING',
}

export interface IConversationFilterLoading {
  type: ConversationFilterActions.LOADING;
}

export interface IConversationFilterSetAction {
  type: ConversationFilterActions.SET_STATE;
  payload: Array<IPreviewConversationFilter>;
}

export interface IConversationFilterUpdateAction {
  type: ConversationFilterActions.UPDATE_STATE;
  payload: IPreviewConversationFilter;
}

export interface IConversationFilterAddAction {
  type: ConversationFilterActions.ADD_STATE;
  payload: IPreviewConversationFilter;
}

export interface IConversationFilterRemoveAction {
  type: ConversationFilterActions.REMOVE_STATE;
  payload: IPreviewConversationFilter['id'];
}

export interface IConversationFilterStore {
  loaded: boolean;
  loading: boolean;
  conversation_filters: Array<IPreviewConversationFilter>;
  dict: Record<IPreviewConversationFilter['id'], IPreviewConversationFilter>;
}

export type TConversationFilterActionTypes = IConversationFilterSetAction | IConversationFilterUpdateAction | IConversationFilterAddAction | IConversationFilterRemoveAction | IConversationFilterLoading;
