import React from 'react';
import { deleteStatsReport } from '@Api';
import { IAbilities, IAuth, IOrganizationStore, IUserStore } from '@Store';
import { CrudEnum, IPreviewStatsReport, PermissionEnum, TStatsReportSortableListCol, } from 'atlas-shared';
import { getFullName, tableColumnDatetimeSorter, tableColumnBooleanRender, tableColumnStringSorter, timeSince } from '@Utils';
import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { ColumnsType } from 'antd/lib/table/interface';
import { listAuditButton, listDeleteButton, listEditButton, GetColumnSearchProps, GetColumnSearchLikeProps, GetColumnSearchEqualProps } from '../components';
import { IWithListProps } from '@Hocs/with.list';

export const StatsReportListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, users: IUserStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewStatsReport) => React.ReactElement> = [], filters: IWithListProps<IPreviewStatsReport, TStatsReportSortableListCol>['filters'], sorter: IWithListProps<IPreviewStatsReport, TStatsReportSortableListCol>['sorter']): ColumnsType<IPreviewStatsReport> => {
  return [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a: IPreviewStatsReport, b: IPreviewStatsReport) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'id')?.order || null,
      filteredValue: filters.id || null,
      ...GetColumnSearchEqualProps(t, 'id', t('ID')),
      className: 'table-cell-id',
    },
    auth.user.__admin_organization_ids.length > 1 && {
      title: t('ORGANIZATION'),
      dataIndex: 'organization_id',
      key: 'organization_id',
      render: (organization_id: IPreviewStatsReport['organization_id'], stats_report: IPreviewStatsReport) => organizations.dict?.[organization_id]?.title || t('ORGANIZATION_NOT_FOUND', { replace: { organization_id } }),
      sorter: (a: IPreviewStatsReport, b: IPreviewStatsReport) => (organizations.dict?.[a.organization_id]?.title || '').localeCompare(organizations.dict?.[b.organization_id]?.title || ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'organization_id')?.order || null,
      filteredValue: filters.organization_id || null,
      ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
      className: 'table-cell-organization_id',
    },
    {
      title: t('USER'),
      dataIndex: 'user_id',
      key: 'user_id',
      render: (user_id: IPreviewStatsReport['user_id'], stats_report: IPreviewStatsReport) => user_id === -1 ? t('SYS_USER') : users.dict?.[user_id] ? getFullName(users.dict[user_id]) : t('USER_NOT_FOUND', { replace: { user_id } }),
      sorter: (a: IPreviewStatsReport, b: IPreviewStatsReport) => (users.dict?.[a.user_id] ? a.user_id === -1 ? t('SYS_USER') : getFullName(users.dict?.[a.user_id]) : '').localeCompare(users.dict?.[b.user_id] ? getFullName(users.dict?.[b.user_id]) : ''),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'user_id')?.order || null,
      filteredValue: filters.user_id || null,
      ...GetColumnSearchProps(t, 'user_id', t('USER'), users.users),
      className: 'table-cell-user_id',
    },
    {
      title: t('TITLE'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a: IPreviewStatsReport, b: IPreviewStatsReport) => tableColumnStringSorter(a.title, b.title),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'title')?.order || null,
      filteredValue: filters.title || null,
      ...GetColumnSearchLikeProps(t, 'title', t('TITLE')),
      className: 'table-cell-title',
    },
    {
      title: t('IS_DASHBOARD'),
      dataIndex: 'is_dashboard',
      key: 'is_dashboard',
      render: (is_dashboard: IPreviewStatsReport['is_dashboard'], stats_report: IPreviewStatsReport) => tableColumnBooleanRender(t, is_dashboard),
      sorter: (a: IPreviewStatsReport, b: IPreviewStatsReport) => (a.is_dashboard ? 1 : 0) > (b.is_dashboard ? 1 : 0) ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'is_dashboard')?.order || null,
      filteredValue: filters.is_dashboard || null,
      ...GetColumnSearchProps(t, 'is_dashboard', t('IS_DASHBOARD'), [{ id: true, title: t('YES') }, { id: false, title: t('NO') }]),
      className: 'table-cell-is_dashboard',
    },
    {
      title: t('CREATED_AT'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: IPreviewStatsReport['created_at'], stats_report: IPreviewStatsReport) => <div className='date-column'>{created_at ? timeSince(t, new Date(created_at), true) : ''}</div>,
      sorter: (a: IPreviewStatsReport, b: IPreviewStatsReport) => tableColumnDatetimeSorter(a.created_at, b.created_at),
      sortDirections: ['descend', 'ascend'],
      sortOrder: sorter.find(s => s.column === 'created_at')?.order || null,
      className: 'table-cell-created_at',
    },
    
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (_: any, row: IPreviewStatsReport) => (
        <div className={'table-operation-wrp'}>
          {buttons.map(button => button(row))}
          {abilities.can(row.organization_id, CrudEnum.Read, PermissionEnum.Audit, row) && listAuditButton(t, navigate, 'stats_report', row)}
          {abilities.can(row.organization_id, CrudEnum.Update, PermissionEnum.StatsReport, row) && listEditButton(t, navigate, 'stats_report', row)}
          {abilities.can(row.organization_id, CrudEnum.Delete, PermissionEnum.StatsReport, row) && listDeleteButton(t, row, deleteStatsReport)}
        </div>
      )
    }
  ].filter(Boolean) as ColumnsType<IPreviewStatsReport>;

};
