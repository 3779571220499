import React from 'react';
import { Popover, PopoverProps } from 'antd';
import { Button } from '../../components/misc';

interface IProps {
  popoverProps: PopoverProps;
  children: any;
  buttonText: string;
}

const FormPopover = (props: IProps) => {
  const { popoverProps, children, buttonText } = props;

  return (
    <Popover {...popoverProps} placement='bottomLeft' content={children} trigger='click'>
      <Button>{buttonText}</Button>
    </Popover>
  );
};

export default React.memo(FormPopover);
