import { useNavigate } from 'react-router-dom';
import './conversation.list.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IPreviewConversation, ISharedConversationFilter, default_conversation_filters, ISharedAgentprofile, Undefinable } from 'atlas-shared';
import { useTranslation } from 'react-i18next';
import {
  useAuth,
  useConversationFilters,
  useSchema,
  useConversationsPagination,
  useHideDashboardLeft
} from '@Hooks';
import { getConversationFilterPatchSchema } from '@Api';
import { ConversationFilterSort, ConversationFilter, ScrollView, NoResult, ConversationFilterReload } from '@Components';
import { ConversationListRow } from './conversation.list.row';
import { ConversationListLeftCountBar } from './conversation.list.left-count-bar';
import { ConversationListSelectedBar } from './conversation.list.selected-bar';
import { ConversationListLeftSkeleton } from './conversation.list.left.skeleton';
import { isAgent } from '@Utils';

interface IProps {
  shortened: boolean;
  current: number;
}

export const ConversationList = React.memo(({ shortened, current }: IProps) => {
  const navigate = useNavigate();
  const [checkedList, setCheckedList] = useState<Array<IPreviewConversation>>([]);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const { t } = useTranslation();
  const { conversations, loadMore, isLoading } = useConversationsPagination();
  const [selectedFilter, setSelectedFilter] = useState<Undefinable<ISharedConversationFilter>>();
  const [chosenFilters, setChosenFilters] = useState<number>(0);
  const auth = useAuth();
  const conversation_filters_store = useConversationFilters();
  const conversation_filters = useMemo(() => conversation_filters_store.conversation_filters.filter(cf => cf.agentprofile_id === auth.user_status.agentprofile_id), [conversation_filters_store, auth.user_status.agentprofile_id]);
  const [conversationFilterSchema, _, conversationFilterJsonSchema] = useSchema(getConversationFilterPatchSchema);
  const hideDashboardLeft = useHideDashboardLeft();

  const default_filters = useMemo(() => {
    return default_conversation_filters(auth.user.id, auth.user_status.agentprofile_id as ISharedAgentprofile['id'], isAgent(auth.user)).map(f => {
      f.title = t(f.title);
      return f;
    });
  }, [auth.user_status.agentprofile_id]);

  const getSelectedFilter = useCallback((conversation_filter_id: ISharedConversationFilter['id']) => {
    return (conversation_filter_id > 0 ? conversation_filters_store.dict[conversation_filter_id] : conversation_filter_id < 0 ? (default_filters.find(f => (f as any).id === conversation_filter_id) as any) : undefined) || undefined;
  }, [conversation_filters_store, default_filters]);

  useEffect(() => {

    const conversation_filter_id = auth.user_status.conversation_filter_id || -1;

    if (conversation_filter_id === selectedFilter?.id)
      return;

    setSelectedFilter(getSelectedFilter(conversation_filter_id));

  }, [auth.user_status.conversation_filter_id, conversation_filters_store, conversation_filters, selectedFilter?.id, default_filters]);

  useEffect(() => {
    const selected = selectedFilter ? getSelectedFilter(selectedFilter.id) as any as ISharedConversationFilter : undefined;
    const chosen = selected ? Object.values(selected.filters[0]).filter(section => Array.isArray(section) ? section.length > 0 : !!section).length : 0;

    if (chosen !== chosenFilters)
      setChosenFilters(chosen);

    if (JSON.stringify(selected || {}) !== JSON.stringify(selectedFilter || {}))
      setSelectedFilter(selected);
  }, [selectedFilter, conversation_filters_store]);

  const allChecked = conversations.conversations.length === checkedList.length;

  useEffect(() => {
    setIndeterminate(checkedList.length > 0 && conversations.conversations.length !== checkedList.length);
  }, [checkedList]);

  return (
    <div className={`inbox-left${shortened ? ' short' : ''}${ hideDashboardLeft ? ' hidden' : '' }`}>

      <div className={'inbox-left-header'}>
        <ConversationFilter
          shortened={shortened}
          conversation_filters={conversation_filters}
          auth={auth}
          chosenFilters={chosenFilters}
          selected={selectedFilter}
          schema={conversationFilterSchema}
          default_filters={default_filters}
        />
        {conversations.conversations.length > 0 && selectedFilter && <ConversationListLeftCountBar
          conversation_filter_id={selectedFilter.id}
          agentprofile_id={auth.user_status.agentprofile_id}
          shortened={shortened}
          conversations={conversations.conversations}
          checked={allChecked}
          indeterminate={indeterminate}
          onChange={() => {
            setCheckedList(allChecked ? [] : conversations.conversations);
            setIndeterminate(false);
          }}
        />}
        <ConversationFilterSort
          auth={auth}
          selected={selectedFilter}
          schema={conversationFilterJsonSchema}
          default_filters={default_filters}
        />
        <ConversationFilterReload />
      </div>
      <ConversationListSelectedBar
        t={t}
        shortened={shortened}
        selected={checkedList}
        setSelected={setCheckedList}
      />
      <ScrollView className={'inbox-left-list'} onScrollEnd={() => loadMore()}>
        {!isLoading && !conversations.conversations.length && <NoResult description={t('NO_CONVERSATION_FOUND_ON_FILTER')} />}
        {
          conversations.conversations && conversations.conversations.map((conversation: IPreviewConversation) => (
            <ConversationListRow
              auth={auth}
              t={t}
              onClick={() => navigate(`/dashboard/conversation/${conversation.organization_id}/${conversation.id}`)}
              shortened={shortened}
              key={`conversation_${conversation.id}`}
              conversation={conversation}
              checked={checkedList}
              selected={current === conversation.id}
              selectedFilter={selectedFilter}
              onCheck={(checked) => {
                const list = checked ? [...checkedList, conversation] : checkedList.filter(selected_conversation => selected_conversation.id !== conversation.id);

                setCheckedList(list);
              }}
            />
          ))
        }
        {isLoading && <ConversationListLeftSkeleton long={!conversations.conversations.length} />}
      </ScrollView>
    </div>
  );
});
