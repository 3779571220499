import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  VoiceAsteriskDowngradeAddForm,
  VoiceAsteriskDowngradeAddInitialValues,
  VoiceAsteriskDowngradeComponents,
  VoiceAsteriskDowngradeAdditionalParams,
  VoiceAsteriskDowngradeFormOptions,
  onVoiceAsteriskDowngradeFormSaved,
  IVoiceAsteriskDowngradeFormOptionsProps,
  AlertError,
  queryParam
} from '@Utils';
import { AtlasForm, IFormOptions } from 'atlas-form';
import { CrudEnum, ISharedCreateVoiceAsteriskDowngrade, PermissionEnum } from 'atlas-shared';
import { createVoiceAsteriskDowngrade, fetchVoiceAsteriskDowngrade, getVoiceAsteriskDowngradeCreateSchema } from '@Api';
import { RuleProtected } from '@Containers';
import { withAdd, IWithAddProps } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations } from '@Hooks';
import { AppSpinner } from '@Components';
import { useAppDispatch } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateVoiceAsteriskDowngrade> {}

const VoiceAsteriskDowngradeFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false }: IProps) => {
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned] = useState<Partial<ISharedCreateVoiceAsteriskDowngrade>>();

  const param_props: IVoiceAsteriskDowngradeFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations
  };
  const formLayout = useMemo(() => VoiceAsteriskDowngradeAddForm(values, param_props, t), [values, organizations, t, abilities]);
  const options: IFormOptions = useMemo(() => VoiceAsteriskDowngradeFormOptions(values || VoiceAsteriskDowngradeAddInitialValues, param_props, t), [values, organizations, t, abilities]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateVoiceAsteriskDowngrade, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues]);
  const onFinish = useCallback((values: ISharedCreateVoiceAsteriskDowngrade) => save(() => createVoiceAsteriskDowngrade(values).then(
    res => onSaved ? onSaved(res, navigate) : onVoiceAsteriskDowngradeFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err
      });
    }
  )), [save, navigate, t]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchVoiceAsteriskDowngrade(+id)
        .then(({
          title,
          host,
          internal_host,
          port,
          tls,
          from_domain,
          rest,
          description
        }) => {
          setCloned({
            title,
            host,
            internal_host,
            port,
            tls,
            from_domain,
            rest,
            description
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(null, CrudEnum.Insert, PermissionEnum.VoiceAsterisk, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={VoiceAsteriskDowngradeComponents}
        additionalParams={VoiceAsteriskDowngradeAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...VoiceAsteriskDowngradeAddInitialValues, ...initialValues }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('VOICE_ASTERISK_DOWNGRADE')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const VoiceAsteriskDowngradeFormAddView = React.memo(withAdd(VoiceAsteriskDowngradeFormAdd, getVoiceAsteriskDowngradeCreateSchema, VoiceAsteriskDowngradeAddInitialValues));
