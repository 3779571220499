import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import React from 'react';
import { IWithListProps } from '@Hocs/with.list';
import { ColumnsType } from 'antd/lib/table/interface';

export * from './abstract/voice_asterisk_operational_hours.list.column.abstract';
import { VoiceAsteriskOperationalHoursListColumns as VoiceAsteriskOperationalHoursListColumnsBase } from './abstract/voice_asterisk_operational_hours.list.column.abstract';
import {
  IPreviewVoiceAsteriskOperationalHours,
  TVoiceAsteriskOperationalHoursSortableListCol
} from 'atlas-shared/dist';
import { printableTime } from '@Utils/date';

export const VoiceAsteriskOperationalHoursListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewVoiceAsteriskOperationalHours) => React.ReactElement> = [], filters: IWithListProps<IPreviewVoiceAsteriskOperationalHours, TVoiceAsteriskOperationalHoursSortableListCol>['filters'], sorter: IWithListProps<IPreviewVoiceAsteriskOperationalHours, TVoiceAsteriskOperationalHoursSortableListCol>['sorter']): ColumnsType<IPreviewVoiceAsteriskOperationalHours> => {
  const columns = [...VoiceAsteriskOperationalHoursListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, buttons, filters, sorter)];

  columns.splice(2, 0,
    {
      title: t('FROM'),
      dataIndex: 'from',
      key: 'from',
      render: (from: IPreviewVoiceAsteriskOperationalHours['from']) => <div className='date-column'>{printableTime(from.hours, from.minutes)} <small>{t('UTC')}</small></div>,
    },
    {
      title: t('TO'),
      dataIndex: 'to',
      key: 'to',
      render: (to: IPreviewVoiceAsteriskOperationalHours['to']) => <div className='date-column'>{printableTime(to.hours, to.minutes)} <small>{t('UTC')}</small></div>,
    },
  );

  return columns;

};
