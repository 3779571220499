import React, { useEffect, useState } from 'react';
import { EyeIcon } from '@Assets/icons';
import {
  IPreviewConversationWatcher,
  Undefinable
} from 'atlas-shared';
import { useUsers } from '@Hooks';
import { Icon, Avatar } from '@Components';

interface IProps {
  conversation_watchers: Undefinable<Array<IPreviewConversationWatcher>>;
  size?: number;
  className?: string;
}

export const ConversationWatchers = React.memo(({ conversation_watchers = [], className, size }: IProps) => {
  const users = useUsers();
  const [watchers, setWatchers] = useState<Array<IPreviewConversationWatcher>>([]);

  useEffect(() => {
    const w = conversation_watchers.filter((w, i) => i === conversation_watchers.findIndex(w2 => w2.user_id === w.user_id));

    setWatchers(w);
  }, [conversation_watchers]);

  return (
    !watchers.length ? <></> : <div className={className}>
      <Icon icon={EyeIcon} iconProps={{ size: 11, style: { marginRight: 5 } }} />
      { watchers.slice(0, watchers.length > 2 ? 1 : 2).map(conversation_watcher => <Avatar key={`watcher-${conversation_watcher.user_id}`} size={size || 14} user={users.dict[conversation_watcher.user_id]} />) }
      {
        watchers.length > 2 &&
        <figure className='circle' style={{ width: 14, height: 14, fontSize: 8 }}>+{watchers.length - 1}</figure>
      }
    </div>
  );
});
