import './audio-recorder.scss';
import Recorder from './recorder';
import React, { useCallback, useState } from 'react';
import { Nullable } from 'atlas-shared';
import { Icon, IconText, Timer } from '@Components';
import { AudioPlayer, secondsToTime, timeDiff, Today } from '@Utils';
import { useTranslation } from 'react-i18next';
import { HttpRequestHeader } from 'antd/lib/upload/interface';
import { MicIcon, MicOffIcon, RefreshIcon, UploadIcon, WarningIcon } from '@Assets';

interface IProps {
  action: string;
  headers: HttpRequestHeader;
  uploadElm: React.ReactElement;
}

export const AudioRecorder = ({ action, headers, uploadElm }: IProps) => {
  const { t } = useTranslation();
  const [stream, setStream] = useState<MediaStream>();
  const [recorder, setRecorder] = useState<any>();
  const [blob, setBlob] = useState<Blob>();
  const [blobUrl, setBlobUrl] = useState<string>();
  const [startedAt, setStartedAt] = useState<Nullable<Date>>(null);
  const [stoppedAt, setStoppedAt] = useState<Nullable<Date>>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [uploadDone, setUploadDone] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string>();

  const start = useCallback(() => {
    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
    }
    else {
      return navigator.mediaDevices.getUserMedia({ audio: true }/*of type MediaStreamConstraints*/)
        .then(stream => {

          setStream(stream);
          setIsRecording(true);
          setStartedAt(new Date());
          setStoppedAt(null);

          let audioContext = new AudioContext();
          let input = audioContext.createMediaStreamSource(stream);
          let rec = new Recorder(input, { numChannels:1 });

          setRecorder(rec);

          // @ts-ignore
          rec.record();
        });

      /* errors are not handled in the API because if its handled and the promise is chained, the .then after the catch will be executed*/
    }
  }, []);

  const stop = useCallback(() => {
    return new Promise(resolve => {
      recorder.stop();
      recorder.exportWAV(blob => {
        setBlob(blob);
        setBlobUrl(URL.createObjectURL(blob));
      });
      stream!.getTracks()
        .forEach(track => track.stop());
      setStoppedAt(new Date());
      setIsRecording(false);
    });
  }, [recorder, stream]);
  
  const reset = useCallback(() => {
    setBlob(undefined);
    setBlobUrl(undefined);
    setStartedAt(null);
    setStoppedAt(null);
    setIsRecording(false);
    setUploadDone(false);
    setUploadError(undefined);
  }, []);
  
  const upload = useCallback(() => {
    if (!blob)
      return;

    let formData = new FormData();

    formData.append('filename', blob, `recording-${Today()}.wav`);
    fetch(action, { method: 'POST', body: formData, headers: new Headers(headers) })
      .catch(err => setUploadError(err.toString()))
      .finally(() => setUploadDone(true));
  }, [action, blob]);

  return <div className='audio-recorder'>
    {!isRecording && !blob && (uploadElm || <></>)}
    {!uploadDone && !isRecording && blobUrl && <AudioPlayer t={t} src={blobUrl} size='s'/>}
    {!blob && <span onClick={() => isRecording ? stop() : start()} >
      <Icon icon={isRecording ? MicOffIcon : MicIcon} className='round-btn record' tooltip={{
        title: t(isRecording ? 'STOP_RECORDING' : 'RECORD_VOICE')
      }} />
    </span>}
    {!uploadDone && <span className='timer'>{startedAt && (stoppedAt ? secondsToTime(timeDiff(startedAt, stoppedAt)) : <Timer base={startedAt.getTime()} ignoreZeros={true}/>)}</span>}
    {blobUrl && !uploadDone && <>
      <Icon icon={UploadIcon} onClick={() => upload()} className='round-btn upload' tooltip={{ title: t('UPLOAD_RECORDING') }} />
      <IconText icon={WarningIcon} text={t('RECORDING_NEEDS_TO_BE_UPLOADED')} className={'upload-info'} iconProps={{ size: 18 }} />
    </>}
    {uploadDone && !uploadError && <span className='success message'>{t('RECORDING_UPLOAD_SUCCESSFULL')}!</span>}
    {uploadError && <span className='fail message'>{t('RECORDING_UPLOAD_ERROR')}: {uploadError}</span>}
    {blobUrl && <Icon icon={RefreshIcon} onClick={() => reset()} className='round-btn refresh' tooltip={{ title: t('RESTART') }} />}
  </div>;
};
