import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const ChevronDownIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="6 9 12 15 18 9"></polyline>
  </SvgIcon>;
};

export const ChevronUpIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="18 15 12 9 6 15"></polyline>
  </SvgIcon>;
};

export const ChevronRightIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="9 18 15 12 9 6"></polyline>
  </SvgIcon>;
};

export const ChevronLeftIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="15 18 9 12 15 6"></polyline>
  </SvgIcon>;
};
