import { ISvgIconProps } from './interface';
import { SvgIcon } from './SvgIcon';

export const ArrowRightIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline>
  </SvgIcon>;
};

export const ArrowLeftIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>
  </SvgIcon>;
};

export const ArrowRightCircleIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 16 16 12 12 8"></polyline>
    <line x1="8" y1="12" x2="16" y2="12"></line>
  </SvgIcon>;
};

export const ArrowUpIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="12" y1="19" x2="12" y2="5"></line>
    <polyline points="5 12 12 5 19 12"></polyline>
  </SvgIcon>;
};

export const ArrowDownIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <polyline points="19 12 12 19 5 12"></polyline>
  </SvgIcon>;
};

export const ArrowCornerDownIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="15 10 20 15 15 20"></polyline>
    <path d="M4 4v7a4 4 0 0 0 4 4h12"></path>
  </SvgIcon>;
};

export const ArrowDownLeftIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="17" y1="7" x2="7" y2="17"></line>
    <polyline points="17 17 7 17 7 7"></polyline>
  </SvgIcon>;
};

export const ArrowUpRightIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <line x1="7" y1="17" x2="17" y2="7"></line>
    <polyline points="7 7 17 7 17 17"></polyline>
  </SvgIcon>;
};

export const ArrowInternalIcon = (props: ISvgIconProps) => {
  return <SvgIcon {...props}>
    <polyline points="4 14 10 14 10 20"></polyline>
    <polyline points="20 10 14 10 14 4"></polyline>
    <line x1="14" y1="10" x2="21" y2="3"></line>
    <line x1="3" y1="21" x2="10" y2="14"></line>
  </SvgIcon>;
};
