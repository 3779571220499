import { Icon } from '@Components';
import { RefreshIcon } from '@Assets';
import React from 'react';
import { actionSetResetConversationList, useAppDispatch } from '@Store';

export const ConversationFilterReload = () => {
  const dispatch = useAppDispatch();

  return <div className='conversation-reload'>
    <Icon icon={RefreshIcon} onClick={e => dispatch(actionSetResetConversationList())} />
  </div>;
};
