import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import React from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { JourneyListColumns as JourneyListColumnsBase } from '@Utils/list/abstract/journey.list.column.abstract';
import { ChannelsIcon, Icon } from '@Components';
import { IPreviewJourney, ISharedJourney, TJourneySortableListCol } from 'atlas-shared';
import { IWithListProps } from '@Hocs/with.list';
import { JourneyAddIcon, JourneyListIcon } from '@Assets';

export * from './abstract/journey.list.column.abstract';

export const JourneyListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewJourney) => React.ReactElement> = [], filters: IWithListProps<IPreviewJourney, TJourneySortableListCol>['filters'], sorter: IWithListProps<IPreviewJourney, TJourneySortableListCol>['sorter']): ColumnsType<IPreviewJourney> => {
  return JourneyListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    ...buttons,
    (row: IPreviewJourney) => <Icon icon={JourneyAddIcon} tooltip={{ title: t('CREATE_NEW_JOURNEY_DRAFT') }} onClick={() => navigate(`/admin/journey_draft/add?iv=${JSON.stringify({ organization_id: row.organization_id, journey_id: row.id })}`)} />,
    (row: IPreviewJourney) => <Icon icon={JourneyListIcon} tooltip={{ title: t('SHOW_JOURNEY_DRAFTS') }} onClick={() => navigate(`/admin/journey_drafts?f={"journey_id":[[${row.id}]]}`)} />,
  ], filters, sorter).map(col => {
    if (col.key === 'channels')
      col.render = (channels: ISharedJourney['channels']) => <ChannelsIcon channels={channels} size={14} padding={6} width={62} max={10} />;

    return col;
  });
};
