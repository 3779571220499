import { IPreviewFormWebsite } from 'atlas-shared';
import { IFormWebsiteStore, FormWebsiteActions, TFormWebsiteActionTypes } from '@Store';

export const FormWebsiteReducer = (state: IFormWebsiteStore = {
  loaded: false,
  loading: false,
  form_websites: [],
  dict: {}
}, action: TFormWebsiteActionTypes): IFormWebsiteStore => {
  switch (action.type) {
  case FormWebsiteActions.LOADING:
    return { ...state, loading: true };
  case FormWebsiteActions.SET_STATE:
    const dict: IFormWebsiteStore['dict'] = {};

    action.payload.forEach((form_website: IPreviewFormWebsite) => dict[form_website.id] = form_website);

    return { ...state, loaded: true, loading: false, form_websites: action.payload, dict };
  case FormWebsiteActions.ADD_STATE:
    return { ...state, form_websites: [...state.form_websites, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case FormWebsiteActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, form_websites: state.form_websites.filter((item: IPreviewFormWebsite) => item.id !== action.payload), dict };
  }
  case FormWebsiteActions.UPDATE_STATE:
    const form_websites = [...state.form_websites];
    const index = state.form_websites.findIndex((form_website: IPreviewFormWebsite) => form_website.id === action.payload.id);

    if (!~index)
      form_websites.push(action.payload);
    else
      form_websites[index] = action.payload;

    return { ...state, form_websites, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
