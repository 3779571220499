import { PhonenumberSetupCostParentActions } from '@Store';
import { IPreviewPhonenumberSetupCostParent } from 'atlas-shared';
import { fetchPhonenumberSetupCostParents } from '@Api';

export const actionFetchPhonenumberSetupCostParents = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPhonenumberSetupCostParents());
    const phonenumber_setup_cost_parents = await fetchPhonenumberSetupCostParents();

    dispatch(actionSetPhonenumberSetupCostParents(phonenumber_setup_cost_parents));
    return phonenumber_setup_cost_parents;
  };
};

export const actionLoadingPhonenumberSetupCostParents = () => ({
  type: PhonenumberSetupCostParentActions.LOADING,
  payload: []
});

export const actionSetPhonenumberSetupCostParents = (payload: Array<IPreviewPhonenumberSetupCostParent>) => ({
  type: PhonenumberSetupCostParentActions.SET_STATE,
  payload: payload
});

export const actionAddPhonenumberSetupCostParent = (payload: IPreviewPhonenumberSetupCostParent) => ({
  type: PhonenumberSetupCostParentActions.ADD_STATE,
  payload: payload
});

export const actionUpdatePhonenumberSetupCostParent = (payload: IPreviewPhonenumberSetupCostParent) => ({
  type: PhonenumberSetupCostParentActions.UPDATE_STATE,
  payload: payload
});

export const actionRemovePhonenumberSetupCostParent = (id: IPreviewPhonenumberSetupCostParent['id']) => ({
  type: PhonenumberSetupCostParentActions.REMOVE_STATE,
  payload: id
});
