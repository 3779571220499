import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  ChannelEnum, Nullable
} from 'atlas-shared';
import { ConversationList, ConversationView, CreateConversation } from '@Components';

interface IProps {
  create_conversation?: boolean;
}

export const DashboardLayout = React.memo(({ create_conversation = false }: IProps) => {

  const { id, organization_id } = useParams<'id' | 'organization_id'>();

  let [searchParams] = useSearchParams();
  const contact_id: Nullable<string> = searchParams.get('contact_id');

  return (
    <div className={`inbox-container${id ? ' with-id' : ''}`}>
      <ConversationList shortened={Boolean(id) || create_conversation} current={Number(id)}/>
      {id && organization_id && <ConversationView organization_id={+organization_id} conversation_id={+id}/>}
      {!id && create_conversation && <CreateConversation
        channel={(searchParams.get('channel') as ChannelEnum) || undefined}
        queue_id={searchParams.get('queue_id') || undefined}
        organization_id={searchParams.get('organization_id') || undefined}
        contact_id={contact_id ? +contact_id : undefined}
      />}
    </div>
  );
});
