import './voice-internals.scss';
import React from 'react';
import { useAuth, useVoiceChanspies, useVoiceInternalCalls } from '@Hooks';
import { VoiceInternal } from './voice-internal';
import { VoiceCallTableEnum } from 'atlas-shared/dist';
import { useTranslation } from 'react-i18next';
import { getSipUsersWrapper } from 'SIP';

export const VoiceInternals = () => {
  const auth = useAuth();
  const voice_internal_calls = useVoiceInternalCalls();
  const voice_chanspies = useVoiceChanspies();
  const { t } = useTranslation();
  const sipUsersWrapper = getSipUsersWrapper();

  return <div className='voice-internals'>
    {voice_internal_calls.voice_internal_calls.filter(call => !call.end_time && !(!call.answer_time && call.callee_user_id === auth.user.id)).map(call => <VoiceInternal auth={auth} t={t} call={call} type={VoiceCallTableEnum.VoiceInternalCall} sipUsersWrapper={sipUsersWrapper} />)}
    {voice_chanspies.voice_chanspies.filter(call => !call.end_time).map(call => <VoiceInternal auth={auth} t={t} call={call} type={VoiceCallTableEnum.VoiceChanSpy} sipUsersWrapper={sipUsersWrapper} />)}
  </div>;
};
