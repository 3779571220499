import { IPreviewSession } from 'atlas-shared';

export enum SessionActions {
  SET_STATE = 'SESSION_SET_STATE',
  ADD_STATE = 'SESSION_ADD_STATE',
  REMOVE_STATE = 'SESSION_REMOVE_STATE',
  GET_STATE = 'SESSION_GET_STATE',
  UPDATE_STATE = 'SESSION_UPDATE_STATE',
  LOADING = 'SESSION_LOADING',
}

export interface ISessionLoading {
  type: SessionActions.LOADING;
}

export interface ISessionSetAction {
  type: SessionActions.SET_STATE;
  payload: Array<IPreviewSession>;
}

export interface ISessionUpdateAction {
  type: SessionActions.UPDATE_STATE;
  payload: IPreviewSession;
}

export interface ISessionAddAction {
  type: SessionActions.ADD_STATE;
  payload: IPreviewSession;
}

export interface ISessionRemoveAction {
  type: SessionActions.REMOVE_STATE;
  payload: IPreviewSession['id'];
}

export interface ISessionStore {
  loaded: boolean;
  loading: boolean;
  sessions: Array<IPreviewSession>;
  dict: Record<IPreviewSession['id'], IPreviewSession>;
}

export type TSessionActionTypes = ISessionSetAction | ISessionUpdateAction | ISessionAddAction | ISessionRemoveAction | ISessionLoading;
