import { patchNotificationUser } from '@Api';
import { Icon, NoResult, Notification, ScrollView } from '@Components';
import '../header.popover.scss';
import './header.notifications.popover.scss';
import { BellIcon, SettingsIcon } from '@Assets';
import { HeaderNotificationsSettings } from './header.notifications.settings';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useNotificationUsers } from '@Hooks';
import { sortByProperty } from '@Utils';
import { ISharedNotificationUser, NotificationSubjectEnum } from 'atlas-shared';
import { Badge, Popover } from 'antd';

export const HeaderNotificationsPopover = React.memo(() => {
  const { t } = useTranslation();
  const auth = useAuth();
  const notification_users = useNotificationUsers();
  const [notificationUsers, setNotificationUsers] = useState<Array<ISharedNotificationUser>>([]);
  const [unread, setUnread] = useState<number>(0);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  useEffect(() => {
    const items = notification_users.notification_users.filter(notification_user => !notification_user.deleted_at && notification_user.notification && ![NotificationSubjectEnum.NewAssignment, NotificationSubjectEnum.NewInternal].includes(notification_user.notification.subject)) as Array<ISharedNotificationUser>;

    setNotificationUsers(sortByProperty(items));
    setUnread(items.filter(notification_user => !notification_user.seen_at).length);
  }, [notification_users.notification_users]);
  
  return <Popover placement='bottomLeft' content={<div className={'header-popover header-notifications-popover'} style={{ width: 320 }}>
    <div className={'header'}>
      <div className={'title'}>
        <span className={'name'}>
          {t('NOTIFICATIONS')}
        </span>
      </div>
      <div className={'right'} style={{ marginBottom: 'unset' }}>
        {unread > 0 && <div style={{ color: 'var(--COLOR-FFFFFF)', fontSize: 10, marginRight: 10 }} className='clickable' onClick={() => {
          notificationUsers.forEach(notification_user => !notification_user.seen_at && patchNotificationUser(notification_user.organization_id, notification_user.id, { seen_at: new Date().toISOString() }));
        }}>{t('MARK_ALL_AS_SEEN')}</div>}
        { <Icon icon={SettingsIcon} iconProps={{ size: 15, style:{ color: 'var(--COLOR-FFFFFF)', marginRight: 10 } }} onClick={() => setShowSettings(!showSettings)} /> }
      </div>
    </div>
    <div className={'list-wrapper'} style={{ maxHeight: 700 }}>
      <ScrollView>
        {!showSettings && !notificationUsers.length && <NoResult description={t('NO_NOTIFICATIONS')} />}
        {!showSettings && notificationUsers?.map(notification_user => <Notification auth={auth} header key={notification_user.notification?.id} notification_user={notification_user as ISharedNotificationUser} />)}
        {showSettings && <HeaderNotificationsSettings auth={auth} t={t} />}
      </ScrollView>
    </div>
  </div>} trigger='click'>
    <Badge count={unread} className={'ant-badge'}>
      <Icon icon={BellIcon} iconProps={{ size: 16 }} />
    </Badge>
  </Popover>;
});
