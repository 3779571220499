import React, { MouseEvent, PropsWithChildren, useState } from 'react';
import AntButton, { ButtonProps } from 'antd/es/button/button';

export type TButtonProps = Omit<PropsWithChildren<ButtonProps>, 'onClick'> & {onClick?: (e?: MouseEvent<HTMLElement>) => any};

export const Button = React.memo((props: TButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  return <AntButton {...props} loading={props.loading || loading} onClick={(e) => {

    if (!props.onClick)
      return;

    setLoading(true);
    const response = props.onClick?.(e);

    if (response?.finally)
      response.finally(() => setLoading(false));
    else
      setLoading(false);
  }}>{props.children}</AntButton>;
});
