import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { actionFetchConversationFilters, useAppDispatch, IReducerStore } from '@Store';

let initiated = false;

export const useConversationFilters = (autoload: boolean = true) => {
  const dispatch = useAppDispatch();
  const conversation_filters = useSelector((store: IReducerStore) => store.conversation_filters);

  useEffect(() => {
    if (!initiated && autoload && !conversation_filters.loaded && !conversation_filters.loading) {
      initiated = true;
      dispatch(actionFetchConversationFilters());
    }
  }, [autoload, conversation_filters.loaded, conversation_filters.loading, dispatch]);

  return conversation_filters;
};
