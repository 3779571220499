import { IFormComponent } from '@Modules/atlas-form-core';
import { EnumValues, StatsWidgetTypeEnum } from 'atlas-shared/dist';
import { useTranslation } from 'react-i18next';

import { Icon } from '@Components/misc';
import './widget-type.form.component.scss';
import { WidgetTypeIcons } from '@Utils';

interface IProps {
  value: any;
  onChange: (interval?: string)=> void;
  disabled?: boolean;
  // onChange: (data: {type: 'always'} | {type: 'custom', time_range:FromTo, week_days_range: FromTo, month_days_range: FromTo, month_range: FromTo})=> void
}

export const FormComponentWidgetType: IFormComponent = ({ value, onChange, disabled }: IProps) => {
  const types = EnumValues(StatsWidgetTypeEnum);
  const { t } = useTranslation();

  return <div className={`form-component-widget-types${disabled ? ' custom-form-component-disabled' : ''}`}>
    {
      types.map(type => type === 'polar' ? <></> : <div className={['widget-type', type === value ? 'selected' : undefined].join(' ')} onClick={() => onChange(type)}>
        <Icon icon={WidgetTypeIcons[type] || WidgetTypeIcons[StatsWidgetTypeEnum.Table]} iconProps={{ size: 28 }} />
        <div className='type'>{t(`STATS_WIDGET_TYPE_${type.toUpperCase()}`)}</div>
      </div>)
    }
  </div>;
};
