import { PhonenumberPurchaseActions } from '@Store';
import { IPreviewPhonenumberPurchase } from 'atlas-shared';
import { fetchPhonenumberPurchases } from '@Api';

export const actionFetchPhonenumberPurchases = () => {
  return async (dispatch) => {
    dispatch(actionLoadingPhonenumberPurchases());
    const phonenumber_purchases = await fetchPhonenumberPurchases();

    dispatch(actionSetPhonenumberPurchases(phonenumber_purchases));
    return phonenumber_purchases;
  };
};

export const actionLoadingPhonenumberPurchases = () => ({
  type: PhonenumberPurchaseActions.LOADING,
  payload: []
});

export const actionSetPhonenumberPurchases = (payload: Array<IPreviewPhonenumberPurchase>) => ({
  type: PhonenumberPurchaseActions.SET_STATE,
  payload: payload
});

export const actionAddPhonenumberPurchase = (payload: IPreviewPhonenumberPurchase) => ({
  type: PhonenumberPurchaseActions.ADD_STATE,
  payload: payload
});

export const actionUpdatePhonenumberPurchase = (payload: IPreviewPhonenumberPurchase) => ({
  type: PhonenumberPurchaseActions.UPDATE_STATE,
  payload: payload
});

export const actionRemovePhonenumberPurchase = (id: IPreviewPhonenumberPurchase['id']) => ({
  type: PhonenumberPurchaseActions.REMOVE_STATE,
  payload: id
});
