import { AbilitiesActions, TAbilitiesActionTypes, IAbilities } from '@Store';
import { Nullable } from 'atlas-shared';

export const AbilitiesReducer = (state: Nullable<IAbilities> = null, action: TAbilitiesActionTypes): Nullable<IAbilities> => {
  switch (action.type) {
  case AbilitiesActions.SET_STATE:
    return action.payload;
  case AbilitiesActions.REMOVE_STATE:
    return null;
  default:
    return state;
  }
};
