import { NavigateFunction } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions
} from 'atlas-form';
import { IAuth, IAbilities } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateListFile, ISharedUpdateListFile, ISharedListFile, IPreviewOrganization, IPreviewList, IPreviewField, } from 'atlas-shared';
import { TFunction } from 'i18next';
import { RestRequest, nestedArray, entitiesToOptions, filterRelated, onFormSaved } from '@Utils';
import { downloadListFileFilename } from '@Api';

export interface IListFileFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  lists: Array<IPreviewList>;
  fields: Array<IPreviewField>;
}

export const ListFileFormOptions = (list_file: ISharedCreateListFile | ISharedListFile, props: IListFileFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.List, {})), 'organization_id', false), 'organization'),
  write_lists: (it: ISharedListFile) => filterRelated<IPreviewList>(it.organization_id || list_file.organization_id, 'list', 'list_file', props.lists, true),
  clean_lists: (it: ISharedListFile) => filterRelated<IPreviewList>(it.organization_id || list_file.organization_id, 'list', 'list_file', props.lists, true),
  fields: (it: ISharedListFile) => filterRelated<IPreviewField>(it.organization_id || list_file.organization_id, 'field', 'list_file', props.fields, true),
  
});

export const ListFileComponents: IForm['components'] = {};
export const ListFileAdditionalParams: (auth: IAuth, props: IListFileFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IListFileFormOptionsProps) => ({
  write_lists: {
    onModalAdd: {
      key: 'queue-write_lists-add',
      path: '@Components/page-views/list',
      component: 'ListFormAddView',
      item_text: 'LIST'
    }
  }, 
  clean_lists: {
    onModalAdd: {
      key: 'queue-clean_lists-add',
      path: '@Components/page-views/list',
      component: 'ListFormAddView',
      item_text: 'LIST'
    }
  }, 
  fields: {
    onModalAdd: {
      key: 'queue-fields-add',
      path: '@Components/page-views/field',
      component: 'FieldFormAddView',
      item_text: 'FIELD'
    }
  }, 
  filename: (it, b, c, d, list_file: ISharedListFile) => ({
    ui_type: FormElementTypes.FILE,
    nullable: false,
    label: 'FILENAME',
    uploadProps: {
      name: 'filename',
      accept: '.ods,.xls,.xlsx',
      action: RestRequest.getFullSrc(`/list_file/${list_file.id}/filename`),
      headers: RestRequest.getHeaders(),
      onValueChange: (onChange) => {
        return (file) => {
          // if (file.file.status === 'done')
          //   onChange(file.file.name);
        };
      },
      defaultFileList: list_file.original_filename ? [
        {
          uid: 'list_file',
          name: list_file.original_filename,
          status: 'done'
        }
      ] : [],
      showUploadList: {
        showRemoveIcon: false
      },
      onDownload: file => {
        downloadListFileFilename(list_file.id, file.name);
      }
    }
  })
});

export const ListFileEditForm = (list_file: ISharedListFile, props: IListFileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `list_file_edit_${list_file.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === list_file.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false },
    { ref: 'title' },
    { ref: 'write_lists' },
    { ref: 'clean_lists' },
    { ref: 'filename' },
    { ref: 'fields' }
  ]
});

export const ListFileAddForm = (list_file: ISharedCreateListFile, props: IListFileFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'list_file_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE },
    { ref: 'title' },
    { ref: 'write_lists' },
    { ref: 'clean_lists' }
  ]
});

export const ListFileAddInitialValues: ISharedCreateListFile = {} as ISharedCreateListFile;

export const ListFileEditInitialValues = (list_file: ISharedListFile): ISharedUpdateListFile => ({
  title: list_file.title,
  write_lists: list_file.write_lists,
  clean_lists: list_file.clean_lists,
  fields: list_file.fields
});

export const onListFileFormSaved = (t: TFunction, action: 'edit' | 'add', list_file: ISharedListFile, navigate: NavigateFunction): void => {
  navigate(onFormSaved('list_files'));
};
