import { IPreviewUserStatus } from 'atlas-shared';
import { IUserStatusStore, UserStatusActions, TUserStatusActionTypes } from '@Store';

export const UserStatusReducer = (state: IUserStatusStore = {
  loaded: false,
  loading: false,
  user_statuses: [],
  dict: {}
}, action: TUserStatusActionTypes): IUserStatusStore => {
  switch (action.type) {
  case UserStatusActions.LOADING:
    return { ...state, loading: true };
  case UserStatusActions.SET_STATE:
    const dict: IUserStatusStore['dict'] = {};

    action.payload.forEach((user_status: IPreviewUserStatus) => dict[user_status.id] = user_status);

    return { ...state, loaded: true, loading: false, user_statuses: action.payload, dict };
  case UserStatusActions.ADD_STATE:
    return { ...state, user_statuses: [...state.user_statuses, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case UserStatusActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, user_statuses: state.user_statuses.filter((item: IPreviewUserStatus) => item.id !== action.payload), dict };
  }
  case UserStatusActions.UPDATE_STATE:
    const user_statuses = [...state.user_statuses];
    const index = state.user_statuses.findIndex((user_status: IPreviewUserStatus) => user_status.id === action.payload.id);

    if (!~index)
      user_statuses.push(action.payload);
    else
      user_statuses[index] = action.payload;

    return { ...state, user_statuses, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
