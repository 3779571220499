import { TFunction } from 'i18next';
import { IAbilities, IAuth, IOrganizationStore } from '@Store';
import { UserListColumns as UserListColumnsBase } from './abstract/user.list.column.abstract';
import { KeyIcon, LogoutIcon, TwoFAIcon, UsersIcon } from '@Assets/icons';
import { Icon } from '@Components';
import { GetColumnSearchProps } from './components';
import React from 'react';
import { personifyUser, resetTwoFactorAuth, userForceLogout } from '@Api';
import { IPreviewUser, CrudEnum, PermissionEnum, LoginStrategyEnum, ISharedUser, TUserSortableListCol, IPreviewOrganization } from 'atlas-shared';
import { NavigateFunction } from 'react-router';
import { AlertError } from '@Utils/popup';
import { IWithListProps } from '@Hocs/with.list';

export const UserListColumns = (t: TFunction, navigate: NavigateFunction, showDeleted: boolean, organizations: IOrganizationStore, abilities: IAbilities, auth: IAuth, buttons: Array<(row: IPreviewUser) => React.ReactElement> = [], filters: IWithListProps<IPreviewUser, keyof IPreviewUser>['filters'], sorter: IWithListProps<IPreviewUser, TUserSortableListCol>['sorter']) => {
  const cols = UserListColumnsBase(t, navigate, showDeleted, organizations, abilities, auth, [
    ...buttons,
    (row: IPreviewUser) => row.id !== auth.user.id && abilities.can(null, CrudEnum.Update, PermissionEnum.User, row) ? <Icon icon={UsersIcon} tooltip={{ title: t('PERSONIFY_USER') }} iconProps={{ size: 14 }} onClick={() => {
      personifyUser(row.id)
        .then(personified => {
          localStorage.setItem('atlas-auth-personified', JSON.stringify(personified.tokens));
          window.location.pathname = '/';
        })
        .catch(error => {
          AlertError(t, { content: t('UNABLE_TO_PERSONIFY') });
        });
    }} /> : <></>,
    (row: IPreviewUser) => row.id !== auth.user.id && abilities.can(null, CrudEnum.Update, PermissionEnum.User, row) ? <Icon icon={LogoutIcon} tooltip={{ title: t('FORCE_LOGOUT') }} iconProps={{ size: 14 }} onClick={() => userForceLogout(row.id) } /> : <></>,
    (row: IPreviewUser) => row.login_strategy === LoginStrategyEnum.Local && row.id !== auth.user.id && abilities.can(null, CrudEnum.Update, PermissionEnum.User, row) ? <Icon icon={KeyIcon} tooltip={{ title: t('RESET_PASSWORD') }} iconProps={{ size: 14 }} onClick={() => { navigate(`/admin/user/${row.id}/edit/password`); }} /> : <></>,
    (row: IPreviewUser) => row.has2fa && row.id !== auth.user.id && abilities.can(null, CrudEnum.Update, PermissionEnum.User, row) ? <Icon icon={TwoFAIcon} tooltip={{ title: t('RESET_2FA') }} iconProps={{ size: 14 }} onClick={() => {
      resetTwoFactorAuth(row.id)
        .catch(error => {
          AlertError(t, { content: t('SOMETHING_WENT_WRING') });
        });
    }} /> : <></>
  ], filters, sorter);

  const username_index = cols.findIndex(c => c.key === 'username');

  cols[username_index].render = (username: ISharedUser['username']) => username.startsWith('UNIQUE_') ? username.split('___').splice(1, 10).join('___') : username;

  cols.splice(2, 0, {
    title: t('ORGANIZATIONS'),
    dataIndex: '__organizations',
    key: '__organizations',
    render: (__organizations) => __organizations.map(o => organizations.dict?.[o.organization_id]?.title).join(', '),
    sortDirections: ['descend', 'ascend'],
    filteredValue: filters.__organizations || null,
    ...GetColumnSearchProps(t, 'organization_id', t('ORGANIZATION'), organizations.organizations),
    onFilter: (value, record) => record.__organizations.some(o => (value as unknown as Array<IPreviewOrganization['id']>).includes(o.organization_id))
  });

  return cols;
};
