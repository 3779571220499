import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { IPreviewForm, ISharedFullConversation } from 'atlas-shared';
import { ScrollView } from '@Components';
import { AtlasForm, FormLayoutTypes } from 'atlas-form';
import { fromJson } from 'json-joi-converter';
import { patchConversation } from '@Api';
import { useTranslation } from 'react-i18next';
import { AlertError } from '@Utils';

interface IProps {
  conversation: ISharedFullConversation;
  form: IPreviewForm;
  editConversationVisible: boolean;
  setEditConversationVisible: Dispatch<SetStateAction<boolean>>;
  initialValues: Record<string, any>;
}

export const ConversationForm = ({ form, conversation, initialValues: _initialValues, setEditConversationVisible }: IProps) => {

  const { t } = useTranslation();
  const initialValues = useMemo(() => _initialValues, [_initialValues]);

  return <ScrollView>
    <div className={'conversation-form'}>
      <h2>
        {form.display_title || t('Conversation')}
      </h2>
      {initialValues && <AtlasForm
        formLayout={{
          id: 'conversation',
          ui_layout: FormLayoutTypes.VERTICAL,
          noPadding: true,
          elements: form.__layout as any
        }}
        form={fromJson({
          type: 'object',
          properties: form.__validation_on_save
        })}
        initialValues={initialValues}
        onFinish={(data: ISharedFullConversation['data']) => {
          patchConversation(conversation.organization_id, conversation.id, { data }).then(res => {
            setEditConversationVisible(false);
          }).catch(err => {
            AlertError(t, {
              title: t('ERROR'),
              content: t(err.substr(7, err.length - 7))
            });
          });
        }}
        onCancel={() => {
          setEditConversationVisible(false);
        }}
      />}
    </div>
  </ScrollView>;
};
