import { IAuth } from '@Store';
import { FormElementTypes, FormLayoutTypes, IForm } from 'atlas-form';
import {
  IPhonenumberMonthlyCostParentFormOptionsProps,
  PhonenumberMonthlyCostParentAdditionalParams as PhonenumberMonthlyCostParentAdditionalParamsBase
} from './abstract/phonenumber_monthly_cost_parent.form.abstract';
import { CountryFlagTitle } from '@Utils/phone';

export * from './abstract/phonenumber_monthly_cost_parent.form.abstract';

export const PhonenumberMonthlyCostParentAdditionalParams: (auth: IAuth, props: IPhonenumberMonthlyCostParentFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IPhonenumberMonthlyCostParentFormOptionsProps) => ({
  ...PhonenumberMonthlyCostParentAdditionalParamsBase(auth, props),
  phonenumber_monthly_cost_list: {
    ui_type: FormElementTypes.SECTION,
    child_params: {
      ui_type: FormElementTypes.SECTION,
      ui_layout: FormLayoutTypes.INLINE,
      labelRenderer: label => <CountryFlagTitle t={props.t} country={label as string} width={21} />
    }
  }
});
