import {
  Info,
  Invitation,
  InvitationAcceptOptions,
  Inviter,
  InviterInviteOptions,
  InviterOptions,
  Message,
  Messager,
  Referral,
  Registerer,
  RegistererOptions,
  RegistererRegisterOptions,
  RegistererState,
  RegistererUnregisterOptions,
  RequestPendingError,
  Session,
  SessionInviteOptions,
  SessionState,
  UserAgent,
  UserAgentOptions,
  UserAgentState
} from 'sip.js';
import { ISipDelegate } from './delegate';
import { ISipOptions } from './options';
import {
  defaultMediaStreamFactory, defaultPeerConnectionConfiguration,
  SessionDescriptionHandlerConfiguration,
  SessionDescriptionHandlerOptions,
  Transport
} from 'sip.js/lib/platform/web';
import { SessionDescriptionHandler } from './session-description-handler';
import { Logger } from 'sip.js/lib/core';
import {
  IPreviewVoiceCall,
  IPreviewVoiceChanspy,
  IPreviewVoiceInternalCall,
  ISharedVoiceCall,
  Undefinable,
  Nullable,
  IPreviewNotification
  , IPreviewVoiceAsterisk } from 'atlas-shared';
import { IVoiceSessionObject, TVoiceSessions } from './interface';

/**
 * A simple SIP user class.
 * @remarks
 * While this class is completely functional for simple use cases, it is not intended
 * to provide an interface which is suitable for most (must less all) applications.
 * While this class has many limitations (for example, it only handles a single concurrent session),
 * it is, however, intended to serve as a simple example of using the SIP.js API.
 * @public
 */
export class SipUser {
  /** Delegate. */
  public delegate: ISipDelegate | undefined;

  private attemptingReconnection = false;

  private connectRequested = false;

  private logger: Logger;

  public options: ISipOptions;

  private registerer: Registerer | undefined = undefined;

  private registerRequested = false;

  private session: Session | undefined = undefined;

  private _sessions: TVoiceSessions = {};

  private userAgent: UserAgent;

  private registererState: RegistererState | undefined = undefined;

  /**
   * Constructs a new instance of the `SimpleUser` class.
   * @param server - SIP WebSocket Server URL.
   * @param options - Options bucket. See {@link AgentOptions} for details.
   */
  constructor(server: string, options: ISipOptions = {}) {
    // Delegate
    this.delegate = options.delegate;

    // Copy options
    this.options = { ...options };

    // UserAgentOptions
    const userAgentOptions: UserAgentOptions = {
      ...options.userAgentOptions,
      sessionDescriptionHandlerFactory: (session: Session, options?: Record<string, any>) => {

        const iceGatheringTimeout = options?.iceGatheringTimeout !== undefined ? options?.iceGatheringTimeout : 5000;

        // merge passed factory options into default session description configuration
        const sessionDescriptionHandlerConfiguration: SessionDescriptionHandlerConfiguration = {
          iceGatheringTimeout,
          peerConnectionConfiguration: {
            ...defaultPeerConnectionConfiguration(),
            ...options?.peerConnectionConfiguration
          }
        };

        const logger = session.userAgent.getLogger('sip.SessionDescriptionHandler');

        return new SessionDescriptionHandler(logger, defaultMediaStreamFactory(), sessionDescriptionHandlerConfiguration);
      }
    };

    // Transport
    if (!userAgentOptions.transportConstructor) {
      userAgentOptions.transportConstructor = Transport;
    }

    // TransportOptions
    if (!userAgentOptions.transportOptions) {
      userAgentOptions.transportOptions = {
        server
      };
    }

    // URI
    if (!userAgentOptions.uri) {
      // If an AOR was provided, convert it to a URI
      if (options.aor) {
        const uri = UserAgent.makeURI(options.aor);

        if (!uri) {
          throw new Error(`Failed to create valid URI from ${options.aor}`);
        }

        userAgentOptions.uri = uri;
      }
    }

    // UserAgent
    this.userAgent = new UserAgent(userAgentOptions);

    // UserAgent's delegate
    this.userAgent.delegate = {
      // Handle connection with server established
      onConnect: (): void => {
        this.logger.log(`[${this.id}] Connected`);
        if (this.delegate && this.delegate.onServerConnect) {
          this.delegate.onServerConnect();
        }

        if (this.registerer && this.registerRequested) {
          this.logger.log(`[${this.id}] Registering...`);
          this.registerer.register().catch((e: Error) => {
            this.logger.error(`[${this.id}] Error occurred registering after connection with server was obtained.`);
            this.logger.error(e.toString());
          });
        }
      },
      // Handle connection with server lost
      onDisconnect: (error?: Error): void => {
        this.logger.log(`[${this.id}] Disconnected`);
        if (this.delegate && this.delegate.onServerDisconnect) {
          this.delegate.onServerDisconnect(error);
        }

        Object.entries(this.sessions).forEach(([key, session]) => {
          this.logger.log(`[${session.session.id}] Hanging up...`);
          this.hangup(session) // cleanup hung calls
            .catch((e: Error) => {
              this.logger.error(`[${session.session.id}] Error occurred hanging up call after connection with server was lost.`);
              this.logger.error(e.toString());
            });
        });

        if (this.registerer) {
          this.logger.log(`[${this.id}] Unregistering...`);
          this.registerer
            .unregister() // cleanup invalid registrations
            .catch((e: Error) => {
              this.logger.error(`[${this.id}] Error occurred unregistering after connection with server was lost.`);
              this.logger.error(e.toString());
            });
        }

        // Only attempt to reconnect if network/server dropped the connection.
        if (error) {
          this.attemptReconnection();
        }
      },
      // Handle incoming invitations
      onInvite: (invitation: Invitation): void => {
        this.logger.log(`[${this.id}] Received INVITE`);

        // Guard against a pre-existing session. This implementation only supports one session at a time.
        // However an incoming INVITE request may be received at any time and/or while in the process
        // of sending an outgoing INVITE request. So we reject any incoming INVITE in those cases.
        if (this.session) {
          this.logger.warn(`[${this.id}] Session already in progress, rejecting INVITE...`);
          invitation
            .reject()
            .then(() => {
              this.logger.log(`[${this.id}] Rejected INVITE`);
            })
            .catch((error: Error) => {
              this.logger.error(`[${this.id}] Failed to reject INVITE`);
              this.logger.error(error.toString());
            });
          return;
        }

        // Use our configured constraints as options for any Inviter created as result of a REFER
        const referralInviterOptions: InviterOptions = {
          sessionDescriptionHandlerOptions: { constraints: this.constraints }
        };

        // Initialize our session
        this.initSession(invitation, referralInviterOptions);

        // Delegate
        if (this.delegate && this.delegate.onCallReceived) {
          this.delegate.onCallReceived(invitation);
        } else {
          this.logger.warn(`[${this.id}] No handler available, rejecting INVITE...`);
          invitation
            .reject()
            .then(() => {
              this.logger.log(`[${this.id}] Rejected INVITE`);
            })
            .catch((error: Error) => {
              this.logger.error(`[${this.id}] Failed to reject INVITE`);
              this.logger.error(error.toString());
            });
        }
      },
      // Handle incoming messages
      onMessage: (message: Message): void => {
        message.accept().then(() => {
          if (this.delegate && this.delegate.onMessageReceived) {
            this.delegate.onMessageReceived(message.request.body);
          }
        });
      },
      onRefer: (referral) => {
        // Determine if you should accept or reject the referral
        referral.accept().then(() => {
          referral.makeInviter().invite();
        });
      }
    };

    // Use the SIP.js logger
    this.logger = this.userAgent.getLogger('sip.SimpleUser');

    // Monitor network connectivity and attempt reconnection when we come online
    window.addEventListener('online', () => {
      this.logger.log(`[${this.id}] Online`);
      this.attemptReconnection();
    });
  }

  /**
   * Instance identifier.
   * @internal
   */
  get id(): string {
    return (this.options.userAgentOptions && this.options.userAgentOptions.displayName) || 'Anonymous';
  }

  /** The local media stream. Undefined if call not answered. */
  localMediaStream(session: Session): MediaStream | undefined {
    const sdh = session.sessionDescriptionHandler;

    if (!sdh) {
      return undefined;
    }

    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error('Session description handler not instance of web SessionDescriptionHandler');
    }

    return sdh.localMediaStream;
  }

  /** The remote media stream. Undefined if call not answered. */
  getRemoteMediaStream(session: Session): MediaStream | undefined {
    const sdh = session?.sessionDescriptionHandler;

    if (!sdh) {
      return undefined;
    }

    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error('Session description handler not instance of web SessionDescriptionHandler');
    }

    return sdh.remoteMediaStream;
  }

  // /**
  //  * The local audio track, if available.
  //  * @deprecated Use localMediaStream and get track from the stream.
  //  */
  // get localAudioTrack(): MediaStreamTrack | undefined {
  //   return this.localMediaStream?.getTracks().find((track) => track.kind === 'audio');
  // }
  //
  // /**
  //  * The local video track, if available.
  //  * @deprecated Use localMediaStream and get track from the stream.
  //  */
  // get localVideoTrack(): MediaStreamTrack | undefined {
  //   return this.localMediaStream?.getTracks().find((track) => track.kind === 'video');
  // }
  //
  // /**
  //  * The remote audio track, if available.
  //  * @deprecated Use remoteMediaStream and get track from the stream.
  //  */
  // get remoteAudioTrack(): MediaStreamTrack | undefined {
  //   return this.remoteMediaStream?.getTracks().find((track) => track.kind === 'audio');
  // }
  //
  // /**
  //  * The remote video track, if available.
  //  * @deprecated Use remoteMediaStream and get track from the stream.
  //  */
  // get remoteVideoTrack(): MediaStreamTrack | undefined {
  //   return this.remoteMediaStream?.getTracks().find((track) => track.kind === 'video');
  // }

  /**
   * Connect.
   * @remarks
   * Start the UserAgent's WebSocket Transport.
   */
  public connect(): Promise<void> {
    this.logger.log(`[${this.id}] Connecting UserAgent...`);
    this.connectRequested = true;
    if (this.userAgent.state !== UserAgentState.Started) {
      return this.userAgent.start();
    }

    return this.userAgent.reconnect();
  }

  /**
   * Disconnect.
   * @remarks
   * Stop the UserAgent's WebSocket Transport.
   */
  public disconnect(): Promise<void> {
    this.logger.log(`[${this.id}] Disconnecting UserAgent...`);
    this.connectRequested = false;
    return this.userAgent.stop();
  }

  /**
   * Return true if connected.
   */
  public isConnected(): boolean {
    return this.userAgent.isConnected();
  }

  /**
   * Start receiving incoming calls.
   * @remarks
   * Send a REGISTER request for the UserAgent's AOR.
   * Resolves when the REGISTER request is sent, otherwise rejects.
   */
  public register(
    registererOptions?: RegistererOptions,
    registererRegisterOptions?: RegistererRegisterOptions
  ): Promise<void> {
    this.logger.log(`[${this.id}] Registering UserAgent...`);
    this.registerRequested = true;

    if (!this.registerer) {
      this.registerer = new Registerer(this.userAgent, registererOptions);
      this.registerer.stateChange.addListener((state: RegistererState) => {

        this.registererState = state;

        if (this.delegate && this.delegate.onRegisterStateChange)
          this.delegate.onRegisterStateChange(this.registererState);

        switch (state) {
        case RegistererState.Initial:
          break;
        case RegistererState.Registered:
          if (this.delegate && this.delegate.onRegistered) {
            this.delegate.onRegistered();
          }

          break;
        case RegistererState.Unregistered:
          if (this.delegate && this.delegate.onUnregistered) {
            this.delegate.onUnregistered();
          }

          break;
        case RegistererState.Terminated:
          this.registerer = undefined;
          break;
        default:
          throw new Error('Unknown registerer state.');
        }
      });
    }

    return this.registerer.register(registererRegisterOptions).then(() => {
      return;
    });
  }

  /**
   * Stop receiving incoming calls.
   * @remarks
   * Send an un-REGISTER request for the UserAgent's AOR.
   * Resolves when the un-REGISTER request is sent, otherwise rejects.
   */
  public unregister(registererUnregisterOptions?: RegistererUnregisterOptions): Promise<void> {
    this.logger.log(`[${this.id}] Unregistering UserAgent...`);
    this.registerRequested = false;

    if (!this.registerer) {
      return Promise.resolve();
    }

    return this.registerer.unregister(registererUnregisterOptions).then(() => {
      return;
    });
  }

  /**
   * Make an outgoing call.
   * @remarks
   * Send an INVITE request to create a new Session.
   * Resolves when the INVITE request is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if Session is established.
   * @param destination - The target destination to call. A SIP address to send the INVITE to.
   * @param inviterOptions - Optional options for Inviter constructor.
   * @param inviterInviteOptions - Optional options for Inviter.invite().
   */
  public call(
    destination: string,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions
  ): Promise<void> {
    this.logger.log(`[${this.id}] Beginning Session...`);

    this.holdAll();

    const target = UserAgent.makeURI(destination);

    if (!target) {
      return Promise.reject(new Error(`Failed to create a valid URI from "${destination}"`));
    }

    // Use our configured constraints as InviterOptions if none provided
    if (!inviterOptions) {
      inviterOptions = {};
    }

    if (!inviterOptions.sessionDescriptionHandlerOptions) {
      inviterOptions.sessionDescriptionHandlerOptions = {};
    }

    if (!inviterOptions.sessionDescriptionHandlerOptions.constraints) {
      inviterOptions.sessionDescriptionHandlerOptions.constraints = this.constraints;
    }

    // Create a new Inviter for the outgoing Session
    const inviter = new Inviter(this.userAgent, target, inviterOptions);

    // Send INVITE
    return this.sendInvite(inviter, inviterOptions, inviterInviteOptions).then(() => {
      return;
    });
  }

  private holdAll() {
    for (const key in this.sessions)
      this.setHold(this.sessions[key], true, null);
  }

  public muteAll(mute: boolean) {
    for (const key in this.sessions)
      this.setHold(this.sessions[key], null, mute);
  }

  /**
   * Hangup a call.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the current Session.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when call is ended.
   */
  public hangup(session: IVoiceSessionObject): Promise<void> {
    this.logger.log(`[${this.id}] Hangup...`);
    return this.terminate(session.session);
  }

  /**
   * Answer an incoming call.
   * @remarks
   * Accept an incoming INVITE request creating a new Session.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if and when call is established.
   * @param invitationAcceptOptions - Optional options for Inviter.accept().
   */
  public answer(invitationAcceptOptions?: InvitationAcceptOptions): Promise<void> {
    this.logger.log(`[${this.id}] Accepting Invitation...`);

    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    if (!(this.session instanceof Invitation)) {
      return Promise.reject(new Error('Session not instance of Invitation.'));
    }

    // Use our configured constraints as InvitationAcceptOptions if none provided
    if (!invitationAcceptOptions) {
      invitationAcceptOptions = {};
    }

    if (!invitationAcceptOptions.sessionDescriptionHandlerOptions) {
      invitationAcceptOptions.sessionDescriptionHandlerOptions = {};
    }

    if (!invitationAcceptOptions.sessionDescriptionHandlerOptions.constraints) {
      invitationAcceptOptions.sessionDescriptionHandlerOptions.constraints = this.constraints;
    }

    return this.session.accept(invitationAcceptOptions);
  }

  /**
   * Decline an incoming call.
   * @remarks
   * Reject an incoming INVITE request.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when call is ended.
   */
  public decline(): Promise<void> {
    this.logger.log(`[${this.id}] rejecting Invitation...`);

    if (!this.session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    if (!(this.session instanceof Invitation)) {
      return Promise.reject(new Error('Session not instance of Invitation.'));
    }

    return this.session.reject();
  }

  /**
   * Hold call
   * @remarks
   * Send a re-INVITE with new offer indicating "hold".
   * Resolves when the re-INVITE request is sent, otherwise rejects.
   * Use `onCallHold` delegate method to determine if request is accepted or rejected.
   * See: https://tools.ietf.org/html/rfc6337
   */
  public hold(session: IVoiceSessionObject): Promise<void> {
    this.logger.log(`[${this.id}] holding session...`);
    return this.setHold(session, true, null);
  }

  /**
   * Unhold call.
   * @remarks
   * Send a re-INVITE with new offer indicating "unhold".
   * Resolves when the re-INVITE request is sent, otherwise rejects.
   * Use `onCallHold` delegate method to determine if request is accepted or rejected.
   * See: https://tools.ietf.org/html/rfc6337
   */
  public unhold(session: IVoiceSessionObject): Promise<void> {
    this.logger.log(`[${this.id}] unholding session...`);
    return this.setHold(session, false, null);
  }

  /**
   * Hold state.
   * @remarks
   * True if session media is on hold.
   */
  public isHeld(session: IVoiceSessionObject): boolean {
    return session.held;
  }

  /**
   * Mute call.
   * @remarks
   * Disable sender's media tracks.
   */
  public mute(session: IVoiceSessionObject): void {
    this.logger.log(`[${this.id}] disabling media tracks...`);
    this.setHold(session, null, true);
  }

  /**
   * Unmute call.
   * @remarks
   * Enable sender's media tracks.
   */
  public unmute(session: IVoiceSessionObject): void {
    this.logger.log(`[${this.id}] enabling media tracks...`);
    this.setHold(session, null, false);
  }

  /**
   * Mute state.
   * @remarks
   * True if sender's media track is disabled.
   */
  public isMuted(session: IVoiceSessionObject): boolean {
    return session.muted;
  }

  /**
   * Send DTMF.
   * @remarks
   * Send an INFO request with content type application/dtmf-relay.
   * @param tone - Tone to send.
   */
  public sendDTMF(session: IVoiceSessionObject, tone: string): Promise<void> {
    this.logger.log(`[${this.id}] sending DTMF...`);

    // As RFC 6086 states, sending DTMF via INFO is not standardized...
    //
    // Companies have been using INFO messages in order to transport
    // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
    // proprietary and have not been standardized.
    // https://tools.ietf.org/html/rfc6086#section-2
    //
    // It is however widely supported based on this draft:
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00

    // Validate tone
    if (!/^[0-9A-D#*,]$/.exec(tone)) {
      return Promise.reject(new Error('Invalid DTMF tone.'));
    }

    // The UA MUST populate the "application/dtmf-relay" body, as defined
    // earlier, with the button pressed and the duration it was pressed
    // for.  Technically, this actually requires the INFO to be generated
    // when the user *releases* the button, however if the user has still
    // not released a button after 5 seconds, which is the maximum duration
    // supported by this mechanism, the UA should generate the INFO at that
    // time.
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3
    this.logger.log(`[${this.id}] Sending DTMF tone: ${tone}`);
    const dtmf = tone;
    const duration = 100;
    const body = {
      contentDisposition: 'render',
      contentType: 'application/dtmf-relay',
      content: 'Signal=' + dtmf + '\r\nDuration=' + duration
    };
    const requestOptions = { body };

    return session.session.info({ requestOptions }).then(() => {
      return;
    });
  }

  /**
   * Send a message.
   * @remarks
   * Send a MESSAGE request.
   * @param destination - The target destination for the message. A SIP address to send the MESSAGE to.
   */
  public message(destination: string, message: string): Promise<void> {
    this.logger.log(`[${this.id}] sending message...`);

    const target = UserAgent.makeURI(destination);

    if (!target) {
      return Promise.reject(new Error(`Failed to create a valid URI from "${destination}"`));
    }

    return new Messager(this.userAgent, target, message).message();
  }

  /** Media constraints. */
  private get constraints(): { audio: boolean; video: boolean } {
    let constraints = { audio: true, video: false }; // default to audio only calls

    if (this.options.media?.constraints) {
      constraints = { ...this.options.media.constraints };
    }

    return constraints;
  }

  /**
   * Attempt reconnection up to `maxReconnectionAttempts` times.
   * @param reconnectionAttempt - Current attempt number.
   */
  private attemptReconnection(reconnectionAttempt = 1): void {
    const reconnectionAttempts = this.options.reconnectionAttempts || 3;
    const reconnectionDelay = this.options.reconnectionDelay || 4;

    if (!this.connectRequested) {
      this.logger.log(`[${this.id}] Reconnection not currently desired`);
      return; // If intentionally disconnected, don't reconnect.
    }

    if (this.attemptingReconnection) {
      this.logger.log(`[${this.id}] Reconnection attempt already in progress`);
    }

    if (reconnectionAttempt > reconnectionAttempts) {
      this.logger.log(`[${this.id}] Reconnection maximum attempts reached`);
      return;
    }

    if (reconnectionAttempt === 1) {
      this.logger.log(`[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying`);
    } else {
      this.logger.log(
        `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - trying in ${reconnectionDelay} seconds`
      );
    }

    this.attemptingReconnection = true;

    setTimeout(
      () => {
        if (!this.connectRequested) {
          this.logger.log(
            `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - aborted`
          );
          this.attemptingReconnection = false;
          return; // If intentionally disconnected, don't reconnect.
        }

        this.userAgent
          .reconnect()
          .then(() => {
            this.logger.log(
              `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - succeeded`
            );
            this.attemptingReconnection = false;
          })
          .catch((error: Error) => {
            this.logger.log(
              `[${this.id}] Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - failed`
            );
            this.logger.error(error.message);
            this.attemptingReconnection = false;
            this.attemptReconnection(++reconnectionAttempt);
          });
      },
      reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000
    );
  }

  /** Helper function to remove media from html elements. */
  private cleanupMedia(): void {
    if (this.options.media) {
      if (this.options.media.local) {
        if (this.options.media.local.video) {
          this.options.media.local.video.srcObject = null;
          this.options.media.local.video.pause();
        }
      }

      if (this.options.media.remote) {
        if (this.options.media.remote.audio) {
          this.options.media.remote.audio.srcObject = null;
          this.options.media.remote.audio.pause();
        }

        if (this.options.media.remote.video) {
          this.options.media.remote.video.srcObject = null;
          this.options.media.remote.video.pause();
        }
      }
    }
  }

  /** Helper function to enable/disable media tracks. */
  private enableReceiverTracks(session: Session, enable: boolean): void {
    if (!session) {
      throw new Error('Session does not exist.');
    }

    const sessionDescriptionHandler = session.sessionDescriptionHandler;

    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error('Session\'s session description handler not instance of SessionDescriptionHandler.');
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;

    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }

    peerConnection.getReceivers().forEach((receiver) => {
      if (receiver.track) {
        receiver.track.enabled = enable;
      }
    });
  }

  /** Helper function to enable/disable media tracks. */
  private enableSenderTracks(session: Session, enable: boolean): void {
    if (!session) {
      throw new Error('Session does not exist.');
    }

    const sessionDescriptionHandler = session.sessionDescriptionHandler;

    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error('Session\'s session description handler not instance of SessionDescriptionHandler.');
    }

    const peerConnection = sessionDescriptionHandler.peerConnection;

    if (!peerConnection) {
      throw new Error('Peer connection closed.');
    }

    peerConnection.getSenders().forEach((sender) => {
      if (sender.track) {
        sender.track.enabled = enable;
      }
    });
  }

  /**
   * Setup session delegate and state change handler.
   * @param session - Session to setup
   * @param referralInviterOptions - Options for any Inviter created as result of a REFER.
   */
  private initSession(session: Session, referralInviterOptions?: InviterOptions): void {
    // Set session
    this.setSession(session);

    // Call session created callback
    if (this.delegate && this.delegate.onCallCreated) {
      this.delegate.onCallCreated(session);
    }

    const that = this;

    // Setup session state change handler
    session.stateChange.addListener((state: SessionState) => {

      this.logger.log(`[${this.id}] session state changed to ${state}`);
      switch (state) {
      case SessionState.Initial:
        break;
      case SessionState.Establishing:
        break;
      case SessionState.Established:
        this.setupLocalMedia(session);
        this.setupRemoteMedia(session);
        if (this.delegate && this.delegate.onCallAnswered) {
          this.delegate.onCallAnswered(session as Invitation);
        }

        break;
      case SessionState.Terminating:
        // fall through
      case SessionState.Terminated:
        that.removeSession(session);
        this.cleanupMedia();
        if (this.delegate && this.delegate.onCallHangup) {
          this.delegate.onCallHangup(session);
        }

        break;
      default:
        throw new Error('Unknown session state.');
      }
    });

    // Setup delegate
    session.delegate = {
      onInfo: (info: Info): void => {
        // As RFC 6086 states, sending DTMF via INFO is not standardized...
        //
        // Companies have been using INFO messages in order to transport
        // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
        // proprietary and have not been standardized.
        // https://tools.ietf.org/html/rfc6086#section-2
        //
        // It is however widely supported based on this draft:
        // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00

        // FIXME: TODO: We should reject correctly...
        //
        // If a UA receives an INFO request associated with an Info Package that
        // the UA has not indicated willingness to receive, the UA MUST send a
        // 469 (Bad Info Package) response (see Section 11.6), which contains a
        // Recv-Info header field with Info Packages for which the UA is willing
        // to receive INFO requests.
        // https://tools.ietf.org/html/rfc6086#section-4.2.2

        // No delegate
        if (this.delegate?.onCallDTMFReceived === undefined) {
          info.reject();
          return;
        }

        // Invalid content type
        const contentType = info.request.getHeader('content-type');

        if (!contentType || !/^application\/dtmf-relay/i.exec(contentType)) {
          info.reject();
          return;
        }

        // Invalid body
        const body = info.request.body.split('\r\n', 2);

        if (body.length !== 2) {
          info.reject();
          return;
        }

        // Invalid tone
        let tone: string | undefined;
        const toneRegExp = /^(Signal\s*?=\s*?)([0-9A-D#*]{1})(\s)?.*/;

        if (toneRegExp.test(body[0])) {
          tone = body[0].replace(toneRegExp, '$2');
        }

        if (!tone) {
          info.reject();
          return;
        }

        // Invalid duration
        let duration: number | undefined;
        const durationRegExp = /^(Duration\s?=\s?)([0-9]{1,4})(\s)?.*/;

        if (durationRegExp.test(body[1])) {
          duration = parseInt(body[1].replace(durationRegExp, '$2'), 10);
        }

        if (!duration) {
          info.reject();
          return;
        }

        info
          .accept()
          .then(() => {
            if (this.delegate && this.delegate.onCallDTMFReceived) {
              if (!tone || !duration) {
                throw new Error('Tone or duration undefined.');
              }

              this.delegate.onCallDTMFReceived(tone, duration);
            }
          })
          .catch((error: Error) => {
            this.logger.error(error.message);
          });
      },
      onRefer: (referral: Referral): void => {
        referral
          .accept()
          .then(() => this.sendInvite(referral.makeInviter(referralInviterOptions), referralInviterOptions))
          .catch((error: Error) => {
            this.logger.error(error.message);
          });
      }
    };
  }

  /** Helper function to init send then send invite. */
  private sendInvite(
    inviter: Inviter,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions
  ): Promise<void> {
    // Initialize our session
    this.initSession(inviter, inviterOptions);

    // Send the INVITE
    return inviter.invite(inviterInviteOptions).then(() => {
      this.logger.log(`[${this.id}] sent INVITE`);
    });
  }

  private setHold(session: IVoiceSessionObject, _hold: boolean | null, _mute: boolean | null): Promise<void> {
    if (!session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    const hold = _hold !== null ? _hold : session.held;
    const mute = _mute !== null ? _mute : session.muted;
    const holdChanged = session.held !== hold;
    const muteChanged = session.muted !== mute;

    // Just resolve if we are already in correct state
    if (!holdChanged && !muteChanged) {
      return Promise.resolve();
    }

    const sessionDescriptionHandler = session.session.sessionDescriptionHandler;

    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
      throw new Error('Session\'s session description handler not instance of SessionDescriptionHandler.');
    }

    const options: SessionInviteOptions = {
      requestDelegate: {
        onAccept: (): void => {
          session.held = hold;
          session.muted = mute;
          if (holdChanged)
            this.enableReceiverTracks(session.session, !hold);

          this.enableSenderTracks(session.session, !hold && !mute);
          if (this.delegate && this.delegate.onCallHold)
            this.delegate.onCallHold(session.session, hold);
        },
        onReject: (): void => {
          this.logger.warn(`[${this.id}] re-invite request was rejected`);
          if (holdChanged)
            this.enableReceiverTracks(session.session, !hold);

          this.enableSenderTracks(session.session, !hold && !mute);
          if (this.delegate && this.delegate.onCallHold) {
            this.delegate.onCallHold(session.session, hold);
          }
        }
      }
    };

    // Session properties used to pass options to the SessionDescriptionHandler:
    //
    // 1) Session.sessionDescriptionHandlerOptions
    //    SDH options for the initial INVITE transaction.
    //    - Used in all cases when handling the initial INVITE transaction as either UAC or UAS.
    //    - May be set directly at anytime.
    //    - May optionally be set via constructor option.
    //    - May optionally be set via options passed to Inviter.invite() or Invitation.accept().
    //
    // 2) Session.sessionDescriptionHandlerOptionsReInvite
    //    SDH options for re-INVITE transactions.
    //    - Used in all cases when handling a re-INVITE transaction as either UAC or UAS.
    //    - May be set directly at anytime.
    //    - May optionally be set via constructor option.
    //    - May optionally be set via options passed to Session.invite().

    const sessionDescriptionHandlerOptions = session.session.sessionDescriptionHandlerOptionsReInvite as SessionDescriptionHandlerOptions;

    sessionDescriptionHandlerOptions.hold = hold;
    session.session.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions;

    // Send re-INVITE
    return session.session
      .invite(options)
      .then(() => {
        // preemptively enable/disable tracks
        if (holdChanged)
          this.enableReceiverTracks(session.session, !hold);

        this.enableSenderTracks(session.session, !hold && !mute);
      })
      .catch((error: Error) => {
        if (error instanceof RequestPendingError) {
          this.logger.error(`[${this.id}] A hold request is already in progress.`);
        }

        throw error;
      });
  }

  /** Helper function to attach local media to html elements. */
  private setupLocalMedia(session: Session): void {
    if (!this.hasSession()) {
      throw new Error('Session does not exist.');
    }

    const mediaElement = this.options.media?.local?.video;

    if (mediaElement) {
      const localStream = this.localMediaStream(session);

      if (!localStream) {
        throw new Error('Local media stream undefiend.');
      }

      mediaElement.srcObject = localStream;
      mediaElement.volume = 0;
      mediaElement.play().catch((error: Error) => {
        this.logger.error(`[${this.id}] Failed to play local media`);
        this.logger.error(error.message);
      });
    }
  }

  /** Helper function to attach remote media to html elements. */
  private setupRemoteMedia(session: Session): void {

    //const mediaElement = this.options.media?.remote?.video || this.options.media?.remote?.audio;
    const mediaElement = document.getElementById(`remoteAudio-${this.getKey(session)}`) as HTMLAudioElement;

    if (mediaElement) {
      const remoteStream = this.getRemoteMediaStream(session);

      if (!remoteStream) {
        throw new Error('Remote media stream undefiend.');
      }

      mediaElement.autoplay = true; // Safari hack, because you cannot call .play() from a non user action
      mediaElement.srcObject = remoteStream;
      mediaElement.play().catch((error: Error) => {
        this.logger.error(`[${this.id}] Failed to play remote media`);
        this.logger.error(error.message);
      });
      remoteStream.onaddtrack = (): void => {
        this.logger.log(`[${this.id}] Remote media onaddtrack`);
        mediaElement.load(); // Safari hack, as it doesn't work otheriwse
        mediaElement.play().catch((error: Error) => {
          this.logger.error(`[${this.id}] Failed to play remote media`);
          this.logger.error(error.message);
        });
      };
    }
  }

  /**
   * End a session.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the current Session.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallTerminated` delegate method to determine if and when Session is terminated.
   */
  private terminate(session: Session): Promise<void> {
    this.logger.log(`[${this.id}] Terminating...`);

    if (!session) {
      return Promise.reject(new Error('Session does not exist.'));
    }

    switch (session.state) {
    case SessionState.Initial:
      if (session instanceof Inviter) {
        return session.cancel().then(() => {
          this.logger.log(`[${this.id}] Inviter never sent INVITE (canceled)`);
        });
      } else if (session instanceof Invitation) {
        return session.reject().then(() => {
          this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
        });
      } else {
        throw new Error('Unknown session type.');
      }
    case SessionState.Establishing:
      if (session instanceof Inviter) {
        return session.cancel().then(() => {
          this.logger.log(`[${this.id}] Inviter canceled (sent CANCEL)`);
        });
      } else if (session instanceof Invitation) {
        return session.reject().then(() => {
          this.logger.log(`[${this.id}] Invitation rejected (sent 480)`);
        });
      } else {
        throw new Error('Unknown session type.');
      }
    case SessionState.Established:
      return session.bye().then(() => {
        this.logger.log(`[${this.id}] Session ended (sent BYE)`);
      });
    case SessionState.Terminating:
      break;
    case SessionState.Terminated:
      break;
    default:
      throw new Error('Unknown state');
    }

    this.logger.log(`[${this.id}] Terminating in state ${session.state}, no action taken`);
    return Promise.resolve();
  }

  getUniqueId(session: Invitation | Session): Undefinable<ISharedVoiceCall['unique_id']> {
    // @ts-ignore
    const fromIncomingInvite = session?.incomingInviteRequest?.message.headers?.['X-Uniqueid']?.[0].raw;

    if (fromIncomingInvite)
      return fromIncomingInvite;

    // @ts-ignore
    const fromOutgoingInvite = session?.outgoingRequestMessage?.extraHeaders?.find(h => h.startsWith('X-Uniqueid'));

    if (fromOutgoingInvite)
      return fromOutgoingInvite.replace('X-Uniqueid: ', '');

    return undefined;
  }

  isEmergencyNumber(session: Invitation | Session, voice_asterisk: IPreviewVoiceAsterisk): false | string {
    //@ts-ignore
    const number = session.outgoingRequestMessage?.toURI?.raw?.user?.split('-') || [];

    return number.includes('emergency') ? number[number.length - 1] : undefined;
  }

  getCallId(session: Invitation | Session): ISharedVoiceCall['asterisk_call_id'] {
    // @ts-ignore
    return session?.outgoingRequestMessage?.callId || null;
  }

  get sessions() {
    return this._sessions;
  }

  private setSession(session: Invitation | Session) {
    this._sessions[this.getKey(session)] = { session, held: false, muted: false };
    return this;
  }

  getKey(session: Invitation | Session): string {
    return this.getUniqueId(session) || this.getCallId(session) || 'undefined';
  }

  private removeSession(session: Invitation | Session) {
    delete this._sessions[this.getKey(session)];
    return this;
  }

  getByUniqueId(unique_id: ISharedVoiceCall['unique_id']): IVoiceSessionObject {
    return this._sessions[unique_id];
  }

  hasSession(): boolean {
    return this.countSessions() > 0;
  }

  countSessions(): number {
    return Object.keys(this._sessions).length;
  }

  getSessionBySharedVoiceCall(voice_call: ISharedVoiceCall | IPreviewVoiceCall): Undefinable<IVoiceSessionObject> {
    if (voice_call.asterisk_call_id && this._sessions[voice_call.asterisk_call_id])
      return this._sessions[voice_call.asterisk_call_id];

    if (this._sessions[voice_call.unique_id])
      return this._sessions[voice_call.unique_id];

    let transferred = (voice_call.transferred_unique_ids || []).find(unique_id => !!this._sessions[unique_id]);

    if (transferred)
      return this._sessions[transferred];

    return undefined;
  }

  getSessionByPreviewNotification(notification: IPreviewNotification): Undefinable<IVoiceSessionObject> {
    if (notification?.payload?.uid && this._sessions[notification?.payload?.uid])
      return this._sessions[notification?.payload?.uid];

    let transferred = (notification?.payload?.tcuid || []).find(unique_id => !!this._sessions[unique_id]);

    if (transferred)
      return this._sessions[transferred];

    return undefined;
  }

  getEchoTestSessions(): Array<IVoiceSessionObject> {
    return Object.values(this._sessions)
      // @ts-ignore
      .filter(session => session.session.outgoingRequestMessage.to.uri.normal.user === 'echotest');
  }

  getSessionBySharedVoiceInternalCall(call: IPreviewVoiceInternalCall | IPreviewVoiceChanspy): IVoiceSessionObject {
    if (call.asterisk_call_id && this._sessions[call.asterisk_call_id])
      return this._sessions[call.asterisk_call_id];

    return this._sessions[call.unique_id];
  }

  getRegisterState() {
    return this.registererState;
  }

  getUserAgent() {
    return UserAgent;
  }

  setMicrophone(deviceId: Nullable<string>) {
    // @ts-ignore
    this.options.media.constraints.audio = deviceId ? { deviceId } : true;
    Object.values(this.sessions).forEach(session => {
      (session.session.sessionDescriptionHandler as SessionDescriptionHandler).setMicrophone(deviceId);
    });
  }

  setMicrophoneMute(mute: boolean) {

    Object.values(this.sessions).forEach(session => {
      if (!mute)
        this.mute(session);
      else
        this.unmute(session);
    });

  }
}
