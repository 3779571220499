import { getStatsWidgetTableMetricStyles, IMetricDataDicts } from '@Utils';
import React from 'react';
import { StatsWidgetMetricTrend } from './stats_widget.metric.trend';
import { TFunction } from 'i18next';
import {
  AlignmentEnum,
  IMetric, IPreviewOrganization, IPreviewStatsCustomMetric,
  IPreviewStatsWidget,
  IPreviewStatsWidgetSettingsMetricProperty
} from 'atlas-shared/dist';
import { StatsWidgetMetricDataValue } from './stats_widget.metric.data.value';
import ReactDOMServer from 'react-dom/server';
import { Tooltip } from 'antd';

interface IProps {
  t: TFunction;
  organization: IPreviewOrganization;
  stats_widget: IPreviewStatsWidget;
  metric_settings: IPreviewStatsWidgetSettingsMetricProperty;
  metric: IMetric | IPreviewStatsCustomMetric;
  trend_value?: number;
  trend_comparison?: number;
  trend_period?: [string, string];
  value: number;
  metric_data_dicts: IMetricDataDicts;
  fitText?: number;
  defaultAlignment?: AlignmentEnum;
  exclude_styles?: boolean;
}
export const StatsWidgetMetricData = React.memo(({ t, stats_widget, metric, metric_settings, trend_value, trend_comparison, trend_period, value, metric_data_dicts, fitText = 0, defaultAlignment, organization, exclude_styles = false }: IProps) => {
  const { trend_classes, trend_elm, trend_data, trend_tooltip } = (metric as IMetric)?.live ? { trend_classes: [], trend_elm: <></>, trend_data: undefined, trend_tooltip: undefined } : StatsWidgetMetricTrend({ t, stats_widget, metric, metric_settings, trend_value, trend_comparison, trend_period, value, metric_data_dicts, organization });
  const { rendered } = StatsWidgetMetricDataValue({ t, metric_data_dicts, metric_settings, value, organization });
  const ln: number = fitText ? ReactDOMServer.renderToStaticMarkup(rendered).replace(/<[^>]*>|\s/igm, '').length : 0;
  const trend_ln: number = fitText && trend_data ? ReactDOMServer.renderToStaticMarkup(trend_elm).replace(/<[^>]*>|\s/igm, '').length : 0;
  const fontSize = fitText ? Math.min(fitText / ln * ((trend_ln ? .6 : 1) + (ln * .025)), fitText * .7) : 0;
  const trendFontSize = fitText && trend_ln ? Math.min(fitText / trend_ln * .5, fitText * .4) : 0;

  return <div className='metric' style={exclude_styles ? {} : getStatsWidgetTableMetricStyles(metric_settings, value, trend_value, trend_comparison, stats_widget)}>
    <Tooltip title={trend_tooltip}>
      <div className={[...trend_classes, 'alignment'].join(' ')} style={{ justifyContent: metric_settings.alignment ? metric_settings.alignment === AlignmentEnum.Center ? 'center' : metric_settings.alignment === AlignmentEnum.Right ? 'end' : 'start' : defaultAlignment }}>
        {fontSize ? <div className='font-size data' style={{ fontSize, lineHeight: `${fontSize}px` }}>{rendered}</div> : rendered}
        {trendFontSize ? <div className='font-size trend' style={{ fontSize: trendFontSize, lineHeight: `${trendFontSize}px`, height: `${trendFontSize}px` }}>{trend_elm}</div> : trend_elm}
      </div>
    </Tooltip>
  </div>;
});
