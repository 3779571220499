import { IPreviewConversation } from 'atlas-shared';
import { IConversationStore, ConversationActions, TConversationActionTypes } from '@Store';

export const ConversationReducer = (state: IConversationStore = {
  loaded: false,
  loading: false,
  conversations: [],
  dict: {}
} as any, action: TConversationActionTypes): IConversationStore => {
  switch (action.type) {
  case ConversationActions.LOADING:
    return { ...state, loading: true };
  case ConversationActions.SET_STATE:
    const dict: IConversationStore['dict'] = {};

    action.payload.forEach((conversation: IPreviewConversation) => dict[conversation.id] = conversation);

    return { ...state, loaded: true, loading: false, conversations: action.payload, dict };
  case ConversationActions.ADD_STATE:
    return { ...state, conversations: [...state.conversations, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case ConversationActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, conversations: state.conversations.filter((item: IPreviewConversation) => item.id !== action.payload), dict };
  }
  case ConversationActions.UPDATE_STATE:
    const conversations = [...state.conversations];
    const index = state.conversations.findIndex((conversation: IPreviewConversation) => conversation.id === action.payload.id);

    if (!~index)
      conversations.push(action.payload);
    else
      conversations[index] = action.payload;

    return { ...state, conversations, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
