import { TFunction } from 'i18next';
import { IFormOptions } from '@Modules/atlas-form-core';
import { IVoiceAsteriskFormOptionsProps, VoiceAsteriskFormOptions as VoiceAsteriskFormOptionsBase } from './abstract/voice_asterisk.form.abstract';
import { Countries, ISharedCreateVoiceAsterisk, ISharedVoiceAsterisk } from 'atlas-shared/dist';

export * from './abstract/voice_asterisk.form.abstract';

export const VoiceAsteriskFormOptions = (voice_asterisk: ISharedCreateVoiceAsterisk | ISharedVoiceAsterisk, props: IVoiceAsteriskFormOptionsProps, t: TFunction): IFormOptions => ({
  ...VoiceAsteriskFormOptionsBase(voice_asterisk, props, t),
  iso2: Countries.map(([ISO2]) => ({
    title: t(`COUNTRY_${ISO2}`),
    key: ISO2
  })),

});
