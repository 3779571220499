import { IPreviewOperationalHours } from 'atlas-shared';
import { IOperationalHoursStore, OperationalHoursActions, TOperationalHoursActionTypes } from '@Store';

export const OperationalHoursReducer = (state: IOperationalHoursStore = {
  loaded: false,
  loading: false,
  operational_hourses: [],
  dict: {}
}, action: TOperationalHoursActionTypes): IOperationalHoursStore => {
  switch (action.type) {
  case OperationalHoursActions.LOADING:
    return { ...state, loading: true };
  case OperationalHoursActions.SET_STATE:
    const dict: IOperationalHoursStore['dict'] = {};

    action.payload.forEach((operational_hours: IPreviewOperationalHours) => dict[operational_hours.id] = operational_hours);

    return { ...state, loaded: true, loading: false, operational_hourses: action.payload, dict };
  case OperationalHoursActions.ADD_STATE:
    return { ...state, operational_hourses: [...state.operational_hourses, action.payload], dict: { ...state.dict, [action.payload.id]: action.payload } };
  case OperationalHoursActions.REMOVE_STATE: {

    const { [action.payload]: _deleted, ...dict } = state.dict;

    return { ...state, operational_hourses: state.operational_hourses.filter((item: IPreviewOperationalHours) => item.id !== action.payload), dict };
  }
  case OperationalHoursActions.UPDATE_STATE:
    const operational_hourses = [...state.operational_hourses];
    const index = state.operational_hourses.findIndex((operational_hours: IPreviewOperationalHours) => operational_hours.id === action.payload.id);

    if (!~index)
      operational_hourses.push(action.payload);
    else
      operational_hourses[index] = action.payload;

    return { ...state, operational_hourses, dict: { ...state.dict, [action.payload.id]: action.payload } };
  default:
    return state;
  }
};
